import React, { useEffect, useState } from 'react';
import { AnnouncementsActionsContent } from './AnnouncementsActions.view';
import { NotificationTranslations } from './AnnouncementsActions.type';
import { SendDataProps } from './AnnouncementsActions.type';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { AnnouncementsActionsState } from 'states/component/Announcements';
import CustomerAPI from 'api/Customer';
import SystemNotification from 'api/SystemNotification';
import { useQuery } from '@tanstack/react-query';
import { CustomerCodebook } from 'models/Customer.type';
import { SystemNotificationModel } from 'models/SystemNotification.type';
import { Success } from '../Popup';
import { useTranslation } from 'react-i18next';
import { ShowAnnouncementsActionsModal } from 'states/global/Modal';
import Translations from 'api/Translations';
import { TranslationsModel } from 'models/Translations.type';
import { DateTime } from 'luxon';
import { CRUD } from 'variables';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

export let DEFAULT_FORM_VALUES: SendDataProps = {
    message: '',
    dateFrom: DateTime.now().valueOf(),
    dateTo: DateTime.now().plus({ days: 10 }).valueOf(),
    active: true,
    allCustomers: true,
    customer: [],
    translation: []
};

const customerAPI = new CustomerAPI();
const translationAPI = new Translations();

const AnnouncementsActions: React.FC = (): JSX.Element => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<SendDataProps>(DEFAULT_FORM_VALUES);
    const announcementsActionsState = useRecoilValue(AnnouncementsActionsState);
    const systemNotificationAPI = new SystemNotification();
    const { t: translate } = useTranslation();
    const showAnnouncementsActionsModal = useSetRecoilState(ShowAnnouncementsActionsModal);
    const resetAnnouncementsActionState = useResetRecoilState(AnnouncementsActionsState);
    const { fromTimezoneToUTC, fromUTCToTimezone, dateTimeFormat } = useConverter();
    const refreshTable = useSetRecoilState(RefreshTable('announcements-RefreshTableAtom'));

    const { isLoading: customersLoading, data: customersData } = useQuery<boolean, Error, CustomerCodebook[]>(
        ['CustomerRequest'],
        () => customerAPI.getCodebook({ apiProject: undefined }),
        {
            refetchInterval: false,
            retry: false
        }
    );

    const { isLoading: notificationLoading, data: notificationData } = useQuery<
        boolean,
        Error,
        SystemNotificationModel
    >(
        ['NotificationRequest'],
        () =>
            systemNotificationAPI.get({
                extendUrl: `${announcementsActionsState.id}`
            }),
        {
            refetchInterval: false,
            retry: false,
            enabled: announcementsActionsState.id !== undefined,
            onSuccess: (data: SystemNotificationModel) => {
                let dataCopy = { ...data.systemNotification };
                dataCopy.dateFrom = DateTime.fromISO(dataCopy.dateFrom);
                dataCopy.dateTo = DateTime.fromISO(dataCopy.dateTo);
            }
        }
    );

    useEffect(() => {
        if (announcementsActionsState.id) {
            if (notificationData !== undefined) {
                let dataCopy = { ...notificationData.systemNotification };

                dataCopy.dateFrom = DateTime.fromFormat(
                    fromUTCToTimezone(dataCopy.dateFrom, false),
                    dateTimeFormat('dateTime', false)
                ).valueOf();
                dataCopy.dateTo = DateTime.fromFormat(
                    fromUTCToTimezone(dataCopy.dateTo, false),
                    dateTimeFormat('dateTime', false)
                ).valueOf();
                setFormValues(dataCopy);
            }
        }
    }, [announcementsActionsState.id, notificationData]);

    const getTranslations = async (message: string): Promise<NotificationTranslations[]> => {
        let translations: NotificationTranslations[] = [];
        const translationsXHR: TranslationsModel = await translationAPI.post({
            extendUrl: 'for-internal-languages/',
            data: { text: message }
        });
        for (const key in translationsXHR.data) {
            translations.push({
                column: 'message',
                locale: key,
                content: translationsXHR.data[key]
            });
        }
        return translations;
    };

    const prepareDataToSend = async (formData: SendDataProps): Promise<SendDataProps> => {
        formData.customer.forEach((data) => {
            delete data['name'];
            delete data['region'];
            delete data['hostname'];
            delete data['selected'];
        });
        formData.translation = await getTranslations(formData.message);

        formData.dateFrom = fromTimezoneToUTC(DateTime.fromMillis(formData.dateFrom));
        formData.dateTo = fromTimezoneToUTC(DateTime.fromMillis(formData.dateTo));

        return formData;
    };

    const sendData = async (formData: SendDataProps): Promise<void> => {
        setIsProcessing(true);
        try {
            formData = await prepareDataToSend(formData);
            if (announcementsActionsState.action === CRUD.EDIT) {
                await systemNotificationAPI.patch<SystemNotification, SendDataProps>({
                    extendUrl: `${announcementsActionsState.id as number}`,

                    data: formData
                });
                Success({ text: `${translate('t.notification')} ${translate('p.has_been_added')}` });
            } else {
                await systemNotificationAPI.post({ data: formData });
                Success({ text: `${translate('t.notification')} ${translate('p.has_been_edited')}` });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        refreshTable(true);
        setIsProcessing(false);
        resetAnnouncementsActionState();
        showAnnouncementsActionsModal(false);
        setFormValues(DEFAULT_FORM_VALUES);
    };

    return (
        <AnnouncementsActionsContent
            data-testid={'AnnouncementsActions-testid'}
            sendData={sendData}
            formValues={formValues}
            isProcessing={isProcessing}
            customersData={customersData}
            customersLoading={customersLoading}
            notificationLoading={notificationLoading}
            setFormValues={setFormValues}
        />
    );
};

export default Wrapper(AnnouncementsActions);
