export const snakeToCamel = (str: string): string =>
    str
        .toLowerCase()
        .replace(/([-][a-z])/g, (group) => group.toUpperCase().replace('-', ''))
        .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

export const camelToSnake = (str: string): string => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const dotToCamel = (str: string): string =>
    str.replace(/([.][a-z])/g, (group) => group.toUpperCase().replace('.', ''));

export const firstCapital = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
