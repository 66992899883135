import PortalApi from 'helpers/api/PortalApiClient';
import { DefaultVehicles, DefaultVehiclesWithPaginator, RuleModel, RuleModelWheel } from '../models/Rule.type';

export default class Rule extends PortalApi<RuleModel> {
    route = 'rule';

    getDefault = () => {
        return this.get({ extendUrl: 'default' });
    };

    getWheel = (wheelId: number) => {
        return this.get<RuleModelWheel>({ extendUrl: `wheel/${wheelId}` });
    };

    getDefaultVehicles = (params: DefaultVehicles) => {
        return this.getTable<DefaultVehiclesWithPaginator>({ extendUrl: 'default/list-vehicles', criteria: params });
    };
}
