export type ReportingModel = {};

export type AvailableQuicksightReports = {
    reportingId: number;
    language: string;
    name: string;
    reporting: {
        id: number;
        name: string;
        shortName: string;
        description: string;
        active: boolean;
        standard: boolean;
        type: ReportType;
    };
}[];

export type GenerateUrlProps = {
    reportingId: number;
};

export type AvailableToAssing = {
    id: number;
    name: string;
    description: string;
    active: boolean;
    standard?: boolean;
};

export type AssignedToRoles = {
    reportingId: number;
    roleId: number;
};

export type AssignRemoveRoleProps = {
    roleId: number;
    reportingId: number;
};

export type GenerateUrlResponse = {
    data: {
        embeddedUrl: string;
    };
};

export enum ReportType {
    MDD = 'mdd',
    QUICKSIGHT = 'quicksight'
}
