import React, { useEffect, useRef, useState } from 'react';
import { VehicleDetailsMapProps } from './VehicleDetailsMap.type';
import { VehicleDetailsMapContent as VehicleDetailsMapView } from './VehicleDetailsMap.view';
import { useSetRecoilState } from 'recoil';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import { Wrapper } from 'helpers/wrapper';
import VehicleApi from 'api/Vehicle';
import { useQuery } from '@tanstack/react-query';

const Vehicle = new VehicleApi();

const VehicleDetailsMapContent: React.FC<VehicleDetailsMapProps> = ({ vehicleId }): JSX.Element => {
    const setMapAction = useSetRecoilState(MapAction);
    const vehicleIntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const [redrawAllVehicles, setRedrawAllVehicles] = useState<boolean>(false);
    const [mapHeight, setMapHeight] = useState(document.documentElement.clientWidth < 600 ? 250 : 350);

    const { refetch: vehiclePosition } = useQuery(
        ['vehiclePosition-VehicleDetailsMapContent'],
        () => Vehicle.getLastPosition(vehicleId),
        {
            enabled: false,
            onSuccess: (dataOnSuccess) => {
                setMapAction({
                    action: MapActionsEnum.DRAW_VEHICLES_POSITION,
                    params: {
                        vehicles: dataOnSuccess,
                        redrawAllVehicles
                    }
                });
            }
        }
    );

    const loadVehiclesPosition = (redrawAllVehicles: boolean): void => {
        setRedrawAllVehicles(redrawAllVehicles);
        vehiclePosition();
    };

    const handleMapHeight = () => {
        setMapHeight(innerWidth < 600 ? 250 : 350);
    };

    const loadVehiclesPeriodicaly = (): void => {
        loadVehiclesPosition(false);
        const interval = setInterval(() => {
            !document.hidden && loadVehiclesPosition(false);
        }, 5000);
        vehicleIntervalRef.current = interval;
    };

    useEffect(() => {
        loadVehiclesPeriodicaly();
        window.addEventListener('resize', handleMapHeight);
        return () => {
            clearInterval(vehicleIntervalRef.current as NodeJS.Timeout);
            window.removeEventListener('resize', handleMapHeight);
            vehicleIntervalRef.current = null;
        };
    }, []);

    return (
        <VehicleDetailsMapView
            vehicleId={vehicleId}
            mapHeight={mapHeight}
            data-testid={'VehicleDetailsMapContent-view'}
        />
    );
};

export default Wrapper(VehicleDetailsMapContent);
