import { HeaderProps, Renderer } from 'react-table';
import { RecoilState, SetterOrUpdater, atom } from 'recoil';
import { columnVisibilityType } from '../UiTableInstance/UiTableInstance.type';

let queryFnAtoms = {};
let tableFnAtoms = {};
let tableFiltersAtoms = {};

export type TableFiltersType = {
    [key: string]: {
        value: string | number;
        humanValue: string;
    };
};

export const TableFilters = (key?: string): RecoilState<TableFiltersType> => {
    if (key && !tableFiltersAtoms[key]) {
        tableFiltersAtoms[key] = atom<TableFiltersType>({
            key: key,
            default: {}
        });
    } else if (!key && !tableFiltersAtoms['TableFilters']) {
        tableFiltersAtoms['TableFilters'] = atom<TableFiltersType>({
            key: 'TableFilters',
            default: {}
        });
    }

    return tableFiltersAtoms[key ?? 'TableFilters'];
};

export type ColumnNameIdType = {
    id: string;
    name?: Renderer<HeaderProps<object>>;
};

export const ColumnNamesIds = atom<ColumnNameIdType[]>({
    key: 'ColumnNamesIds',
    default: []
});

export const ColumnVisibility = atom<columnVisibilityType>({
    key: 'ColumnVisibility',
    default: {}
});

type TableFnType = Record<string, unknown>;

export const TableFn = (key?: string): RecoilState<TableFnType> => {
    if (key && !tableFnAtoms[key]) {
        tableFnAtoms[key] = atom<TableFnType>({
            key: key,
            default: {}
        });
    } else if (!key && !tableFnAtoms['TableFn']) {
        tableFnAtoms['TableFn'] = atom<TableFnType>({
            key: 'TableFn',
            default: {}
        });
    }

    return tableFnAtoms[key ?? 'TableFn'];
};

export const QueryFn = (key?: string): RecoilState<TableFnType> => {
    if (key && !queryFnAtoms[key]) {
        queryFnAtoms[key] = atom<TableFnType>({
            key: key,
            default: {}
        });
    } else if (!key && !queryFnAtoms['QueryFn']) {
        queryFnAtoms['QueryFn'] = atom<TableFnType>({
            key: 'QueryFn',
            default: {}
        });
    }

    return queryFnAtoms[key ?? 'QueryFn'];
};

export const SelectAll = atom<boolean>({
    key: 'SelectAll',
    default: false
});

export enum SelectedRowEnum {
    SET_SELECT_ALL = 'SET_SELECT_ALL',
    SET_SELECT = 'SET_SELECT'
}

type toggleSelectRow = {
    type: SelectedRowEnum.SET_SELECT_ALL;
    payload: boolean;
};

type selectRow = {
    type: SelectedRowEnum.SET_SELECT;
    payload: {
        rowData: Record<string, unknown>;
        added: boolean;
    };
};

type SelectedRowAction = toggleSelectRow | selectRow;

type SelectedRowProps = {
    selectedAll: boolean;
    selectedRows: Record<string, unknown>[];
};

export const SelectRow = atom<SelectedRowProps>({
    key: 'SelectRow',
    default: {
        selectedAll: false,
        selectedRows: []
    }
});

export const ShowScroll = atom<boolean>({
    key: 'ShowScrollAtom',
    default: true
});

export const FilterBtnStates = atom<{ anchorEl: HTMLButtonElement | null; open: boolean }>({
    key: 'FilterBtnStatesAtom',
    default: { anchorEl: null, open: false }
});

export const SelectedRowDispatcher =
    (previousState: SelectedRowProps, setState: SetterOrUpdater<SelectedRowProps>) => (action: SelectedRowAction) => {
        switch (action.type) {
            case SelectedRowEnum.SET_SELECT_ALL:
                return setState({
                    ...previousState,
                    selectedRows: [],
                    selectedAll: action.payload
                });
            case SelectedRowEnum.SET_SELECT: {
                let copySelectedRows = [...previousState.selectedRows];
                if (action.payload.added) {
                    copySelectedRows.push(action.payload.rowData);
                } else {
                    copySelectedRows = copySelectedRows.filter((row) => row.id !== action.payload.rowData.id);
                }
                return setState({
                    ...previousState,
                    selectedRows: copySelectedRows
                });
            }
            default:
                return setState(previousState);
        }
    };
