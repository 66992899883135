import styled from 'styled-components';
import { Grid } from '@mui/material';
import UiButton from 'components/Ui/Components/UiButton';

export const Container = styled(Grid)`
    &.MuiGrid-root {
        margin-left: 0;
        padding-bottom: 16px;
    }
`;

export const FirstItem = styled(Grid)`
    &.MuiGrid-root {
        padding-left: 0;
    }
`;

export const MiddleItem = styled(Grid)`
    &.MuiGrid-root {
        display: flex;
        align-items: center;
    }
`;

export const LastItem = styled(Grid)`
    &.MuiGrid-root {
        display: flex;
        flex-direction: row-reverse;
    }
`;

export const Button = styled(UiButton)`
    &.MuiButton-root {
        margin-right: 0;
    }
`;

export const ButtonHolder = styled.div`
    display: flex;
`;
