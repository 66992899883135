import styled from 'styled-components';
import { Alert, Box } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const SystemNotificationMessageContainer = styled.div<{ hide: boolean }>`
    ${(props) => props.hide && 'display: none'};
    margin-bottom: ${applyStyleByMode({ styleJade: '7px' })};
    font-weight: ${applyStyleByMode({ styleJade: '100' })};
`;

export const WarningMessage = styled(Alert)`
    &.MuiAlert-standardWarning {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 3px 7px;
        min-height: 30px;
        font-weight: ${applyStyleByMode({ styleJade: '100' })};
        font-size: ${applyStyleByMode({ styleJade: '0.86rem', styleOld: '1.05rem' })};
        margin-bottom: 1px;
        box-shadow: 0 1px 2px #cecece;
    }
    & .MuiAlert-message {
        padding: 0;
        width: calc(100% - 50px);
    }
    & .MuiAlert-icon {
        padding: 0;
    }
`;

export const WarningMessageCover = styled(Box)`
    font-weight: ${applyStyleByMode({ styleJade: '100' })};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 30px;
`;
