import React, { useEffect } from 'react';
import { CustomersGlobalAdminContent } from './CustomersGlobalAdmin.view';
import { CustomersGlobalAdminProps } from './CustomersGlobalAdmin.type';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const CustomersGlobalAdmin: React.FC<CustomersGlobalAdminProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'administration-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <CustomersGlobalAdminContent data-testid='CustomersGlobalAdmin-testid' />;
};

export default Wrapper(CustomersGlobalAdmin);
