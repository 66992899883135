import React from 'react';
import { SensorManagementViewContent } from './SensorManagement.style';
import { SensorManagementViewProps } from './SensorManagement.type';
import SensorManagementRow from './SensorManagementRow';

export const SensorManagementContent = ({
    attachedSensors,
    isLoading,
    switchPositions
}: SensorManagementViewProps): JSX.Element => {
    return (
        <SensorManagementViewContent data-testid='SensorManagementContent'>
            {!isLoading &&
                attachedSensors.map((attachedSensor) => (
                    <SensorManagementRow
                        key={attachedSensor.sensor.id}
                        attachedSensor={attachedSensor}
                        switchPositions={switchPositions}
                    />
                ))}
            <SensorManagementRow switchPositions={switchPositions} />
        </SensorManagementViewContent>
    );
};
