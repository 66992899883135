import styled from 'styled-components';
import { Grid } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { TableContainer } from '@mui/material';

export const SoftwarePackagesContainer = styled.div`
    @keyframes moveToRight {
        0% {
            transform: translateX(-100px);
        }
        100% {
            transform: translateX(0px);
        }
    }
    min-height: 607px;
    animation-name: moveToRight;
    animation-duration: 150ms;
`;

export const GridContainer = styled(Grid)`
    border-radius: 20px;
`;

export const CustomTableCell = styled(TableCell)`
    &&.MuiTableCell-root {
        font-size: 13px !important;
    }
`;

export const CustomTableContainer = styled(TableContainer)<{ $isLoading: boolean }>`
    opacity: ${(props) => (props.$isLoading ? '0.5' : '1')};
`;
