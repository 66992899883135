import { Box, Typography } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { WHITE } from 'components/Ui/colors';

export const WheelAlertsLogsViewContent = styleMui(Box)({
    paddingTop: 8
});

export const AlertLabel = styleMui(Typography)({
    background: WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 20px'
});

export const AlertLabelCover = styleMui(Box)({
    position: 'relative',
    height: 1,
    background: '#9d9fa2'
});
