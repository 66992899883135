import React from 'react';
import { AlertContent, AlertLabel, AlertSettingLevelViewContent } from './AlertSettingLevel.style';
import { AlertSettingLevelProps } from './AlertSettingLevel.type';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';

const AlertSettingLevelContent: React.FC<AlertSettingLevelProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();

    return (
        <AlertSettingLevelViewContent
            xs={12}
            md
            lg
            data-testid='AlertSettingLevelContent'
            level={props.level}
            theme={theme}
        >
            <AlertLabel theme={theme}>
                <Typography variant='subtitle1' gutterBottom>
                    {props.level === 0 ? translate('t.optimal') : `${translate('t.level')} ${props.level % 10}`}
                </Typography>
            </AlertLabel>
            <AlertContent>{props.alertInput}</AlertContent>
        </AlertSettingLevelViewContent>
    );
};

export default AlertSettingLevelContent;
