import React from 'react';
import { CustomerReportContent } from './CustomerReport.view';
import { CustomerReportProps } from './CustomerReport.type';
import { Wrapper } from 'helpers/wrapper';

const CustomerReport: React.FC<CustomerReportProps> = (): JSX.Element => {
    return <CustomerReportContent data-testid={'CustomerReport-testid'} />;
};

export default Wrapper(CustomerReport);
