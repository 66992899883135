import styled from 'styled-components';

export const AreaDetailsViewContent = styled.div`
    border: 1px solid #e7e7e7;
    & tbody {
        display: table;
        width: 100%;
    }

    & td:nth-child(2) {
        border-right: 1px solid #e0e0e0;
    }
`;
