import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { WHITE } from '../../../../Ui/colors';
import { Typography } from '@mui/material';

export const PercentageTyreGraphContent = styled.div`
    padding: 15px 0px !important;
    ${applyStyleByMode({
        styleOld: `
            && .MuiCardContent-root {
                
                overflow-x: auto;
                min-height: 80px;
            }
            && .MuiCardActions-root {
                
            }
        `
    })}

    .main {
        padding-top: 0px !important;
    }

    .sub {
        font-weight: 300;
        font-weight: lighter;
    }

    .right-title {
        float: right;
        padding-right: 16px !important;
    }

    .left-title {
        float: left;
        padding-left: 16px !important;
    }
`;

export const Graph = styled.div`
    margin: 5px 15px;
    height: 40px;

    .left-position {
        float: left;
    }

    .right-position {
        float: right;
    }
`;

export const GraphTitle = styled.div`
    font-weight: 600;
    font-weight: bold;
    padding: 12px 10px;
    color: ${WHITE};
`;

export const TitleContainer = styled.div`
    font-weight: 600;
    font-weight: bold;
    padding-left: 16px !important;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: left;
`;

export const SubTitle = styled.span`
    font-weight: 600;
    font-weight: bold;

    padding-bottom: 10px;
    padding-top: 10px;
`;

export const SubTitleContainer = styled.div`
    width: 100%;
    padding-bottom: 30px;
`;

export const DataText = styled(Typography)`
    &.MuiTypography-root {
        padding-top: 5px;
    }
`;
