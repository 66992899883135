import React, { useEffect } from 'react';
import { ChangesPasswordContent } from './ChangesPassword.view';
import { ChangesPasswordProps, InicialvaluesForm } from './ChangesPassword.type';
import { useMutation } from '@tanstack/react-query';
import RecoverPassword from 'api/RecoverPassword';
import Account from 'api/Account';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { authToken } from 'helpers/authentication';
import { Wrapper } from 'helpers/wrapper';

const recoveryPasswordApi = new RecoverPassword();
const accountApi = new Account();

const valuesInitForm: InicialvaluesForm = {
    password: '',
    confirmPassword: '',
    currentPassword: ''
};

const ChangesPassword: React.FC<ChangesPasswordProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const { hash }: any = useParams();

    const { mutate: updatePasswordMutate, isLoading } = useMutation(recoveryPasswordApi.updatePassword, {
        onSuccess: () => {
            Success({
                text: translate('p.password_recovered_successfully')
            });
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    });

    const { mutate: accountChangePasswordMutate, isLoading: accountChangePasswordLoading } = useMutation(
        accountApi.changePassword,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.password_changed_successfully')
                });
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            }
        }
    );

    const updatePassword = (newPassword: string, currentPassword: string): void => {
        return hash
            ? updatePasswordMutate({ hash: hash, newPassword: newPassword })
            : accountChangePasswordMutate({ newPassword: newPassword, currentPassword: currentPassword });
    };

    useEffect(() => {
        if (!hash && authToken() === '') {
            navigate('/login');
        }
    }, []);

    return (
        <ChangesPasswordContent
            data-testid={'ChangesPassword-testid'}
            valuesInitForm={valuesInitForm}
            isLoading={isLoading || accountChangePasswordLoading}
            updatePassword={updatePassword}
        />
    );
};

export default Wrapper(ChangesPassword);
