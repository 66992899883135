import PortalApiClient from 'helpers/api/PortalApiClient';
import {
    CustomerByIdResponse,
    CustomerCodebook,
    CustomerEdit,
    CustomerExtendedModel,
    CustomerTableModel,
    LogoData,
    CustomerModel,
    editLogoProps,
    editLogoData,
    editCheckProps,
    editCheckData,
    CustomerFormToSend,
    CustomerForm
} from '../models/Customer.type';
import { getParams } from 'helpers';
import { WithRequiredProperty } from 'helpers/api/type';

export default class Customer extends PortalApiClient<
    CustomerTableModel | CustomerCodebook | LogoData | CustomerEdit | CustomerForm | CustomerByIdResponse
> {
    route = 'customer';

    getCodeBook(): Promise<CustomerCodebook[] | CustomerByIdResponse> {
        return this.getCodebook({ apiProject: undefined });
    }

    getCustomersByRegion = async (props): Promise<CustomerTableModel> => {
        return await this.getByCriteria({
            apiProject: undefined,
            criteria: getParams({
                queryPageIndex: props.page,
                queryPageSize: 10,
                queryPageSortBy: [{ id: 'name', desc: false }],
                queryPageFilter: [{ id: 'region', value: { name: 'customerRegion', value: `${props.region}` } }]
            })
        });
    };

    getCustomersByName = async (props): Promise<CustomerTableModel> => {
        return await this.getByCriteria({
            apiProject: undefined,
            criteria: getParams({
                queryPageIndex: props.page,
                queryPageSize: 10,
                queryPageFilter: [{ id: 'name', value: { name: 'customerName', value: `${props.name}` } }],
                queryPageSortBy: [{ id: 'name', desc: false }]
            })
        });
    };

    getAllAsCodebook = async (): Promise<CustomerCodebook[]> => {
        return await this.getCodebook({ apiProject: undefined });
    };

    getAvailableAsCodebook = async (): Promise<CustomerCodebook[]> => {
        return await this.getCodebook({ apiProject: undefined, extendUrl: 'available-codebook' }, false);
    };

    getCustomerTable = async (props): Promise<CustomerTableModel> => {
        return await this.getTable({ criteria: getParams(props), apiProject: undefined });
    };

    getCustomerById = async (id?: number): Promise<CustomerByIdResponse> => {
        return await this.get({ extendUrl: `${id}`, apiProject: undefined });
    };

    getLogo = async (id: number): Promise<string> => {
        return await this.getFile({ version: 'v2', extendUrl: `${id}/logo`, apiProject: undefined });
    };

    editCustomer = async (
        data: WithRequiredProperty<Partial<CustomerFormToSend>, 'id'>
    ): Promise<WithRequiredProperty<Partial<CustomerFormToSend>, 'id'>> => {
        let patchData = {
            id: data.id,
            name: data.name
        };

        const extendedData = {
            enabled: data.enabled,
            externalTemperatureEnabled: data.externalTemperatureEnabled,
            activeTerminatedAt: data.activeTerminatedAt,
            internalTestSite: data.internalTestSite,
            hubsMaintenanceConnection: data.hubsMaintenanceConnection == true ? '1' : '0',
            deviceSerialConnectionType: !data.deviceSerialConnectionTypeId
                ? null
                : { id: data.deviceSerialConnectionTypeId },
            timezone: {
                id: data.timezoneId ?? 0
            },
            hasExternalSystem: data.hasExternalSystems,
            jiraOrganisationId: data.jiraOrganisationId || null,
            itrackStockPrimaryDeliveryLocationId: data.itrackStockPrimaryDeliveryLocationId || null
        };

        if (data.enabled !== undefined) {
            patchData = Object.assign(patchData, extendedData);
        }

        if (data.reEnableCustomer) {
            patchData = Object.assign(patchData, { enabled: true });
        }

        return await this.patch({
            extendUrl: `${data.id}`,
            apiProject: undefined,
            data: patchData
        });
    };

    editLogo = async (props: editLogoProps): Promise<CustomerModel> => {
        return await this.patch<CustomerModel, editLogoData>({
            extendUrl: `${props.id}/logo`,
            apiProject: undefined,
            data: { logo: props.data }
        });
    };

    editCustomerSettings = async (data: CustomerExtendedModel): Promise<any> => {
        return await this.patchById({ id: data.id, apiProject: undefined, data: data });
    };

    editCheck = async (props: editCheckProps): Promise<CustomerModel> => {
        return await this.patchById<CustomerModel, editCheckData>({
            id: props.id,
            apiProject: undefined,
            data: { externalTemperatureEnabled: props.externalTemperatureEnabled }
        });
    };

    removeCustomer = async (id: number): Promise<Object> => {
        return await this.delete({ id: id, apiProject: undefined });
    };
}
