import React, { useEffect } from 'react';
import { WheelDropDownContent } from './WheelDropDown.view';
import { WheelDropDownProps } from './WheelDropDown.type';
import Wheel from '../../../api/Wheel';
import WheelModel, { WheelCodebook } from '../../../models/Wheel.type';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';

const wheelApi = new Wheel();

const WheelDropDown: React.FC<WheelDropDownProps> = ({
    value,
    error,
    getValue,
    defaultValue,
    vehicleId,
    nameAsId,
    disabled,
    testid,
    customLabel
}): JSX.Element => {
    const {
        data: wheelDataXHR,
        isFetching: wheelLoading,
        refetch: wheelRefetch
    } = useQuery<WheelCodebook, Error>([`WheelList-${vehicleId}`], () => wheelApi.getCodeBook(vehicleId), {
        refetchOnWindowFocus: false,
        enabled: false
    });

    const orderingWheels = (data: WheelModel[]) => {
        return data.sort((a, b) => {
            if (a.customPosition > b.customPosition) return 1;

            if (a.customPosition < b.customPosition) return -1;

            return 0;
        });
    };

    useEffect(() => {
        wheelRefetch();
    }, [vehicleId]);

    return (
        <WheelDropDownContent
            testid={testid}
            data-testid={testid || 'WheelDropDown-testid'}
            wheels={vehicleId && !isNaN(vehicleId) ? orderingWheels(wheelDataXHR?.codeBook ?? []) : []}
            wheelLoading={wheelLoading}
            value={value}
            error={error}
            getValue={getValue}
            defaultValue={defaultValue}
            nameAsId={nameAsId}
            disabled={disabled}
            customLabel={customLabel}
        />
    );
};

export default Wrapper(WheelDropDown);
