import styled from 'styled-components';
import { Badge, Tab, Tabs } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const VehicleDetailsViewContent = styled.div`
    width: 100%;
    ${applyStyleByMode({
        styleOld: `
            border: 1px solid #ddd;
            height: 705px;
            & .MuiTypography-root {
                height: 652px;
            }
        `,
        styleJade: `
            & .MuiTabs-root {
                border-bottom: 1px solid #c5c5c5 !important;
                min-height: 35px;
            }
        `
    })}
`;

export const VehicleDetailsTabs = styled(Tabs)`
    & .MuiTab-root {
        ${applyStyleByMode({
            styleOld: `
                min-width: 17% !important;
                text-transform: none;
                font-size: 13px;
                font-weight: 600;
                border-bottom: 1px solid #ddd;
                & .MuiTab-wrapper {
                    display: flex;
                    flex-flow: column wrap;
                    width: 100% !important;
                }
                & .MuiTab-labelIcon {
                    min-height: auto;
                }
                & .MuiTabs-scroller {
                    overflow-x: auto !important;
                }
            `
        })}
    }
`;

export const AlertsTab = styled(Tab)<{ $hide?: boolean; $badge?: boolean }>`
    &.MuiButtonBase-root {
        ${(props) => (props.$hide ? 'display: none !important;' : ' display: flex')}
        flex-flow: row wrap;
        flex-direction: row-reverse !important;
        ${({ $badge }) => {
            return applyStyleByMode({
                styleJade: `
                    &.MuiTab-root {
                        padding: ${$badge ? '0px 28px 0px 15px' : '0px 15px'};
                    }

                    & .MuiTab-wrapper {
                        flex-direction: row;
                        align-items: end;
                        svg {
                            margin-right: 3px;
                        }
                    }
                `
            });
        }}
    }
`;

export const IconLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const TabAlertIcon = styled.span`
    margin-left: 4px;
`;

export const GpsLastRecord = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LastGpsMeasuredAt = styled.b`
    margin-left: 4px;
`;

export const DeviceText = styled.p`
    margin: 2px 0 0 5px;
`;

export const UiBadge = styled(Badge)<{ $color?: string }>`
    .MuiBadge-badge {
        min-width: 13px !important;
        height: 17px;
        background: ${({ $color }) => $color || '#0091d9'} !important;
        color: #fff !important;
        ${applyStyleByMode({
            styleOld: `
                right: -6px !important;
                top: -2px !important;
            `,
            styleJade: `
                right: -10px !important;
                top: 2px !important;
                padding: 0px 5px;
                border-radius: 4px;
            `
        })}
    }
`;
