import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const MapDataLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    height: 25px;
`;

export const DataLoaderLabel = styled.span`
    margin: 0 5px;
`;

export const DataLoaderProgress = styled(CircularProgress)`
    height: 20px !important;
    width: 20px !important;
`;
