import React, { useEffect } from 'react';
import { RoutesProps } from './Routes.type';
import RoutesList from '../../components/Tracking/RoutesList/RoutesList';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Routes: React.FC<RoutesProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tracking-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <RoutesList data-testid={'Routes-testid'} />;
};

export default Wrapper(Routes);
