import React, { useMemo } from 'react';
import { VehicleCounterActionTableProps } from '../PredictedGraph.type';
import UiTable from 'components/Ui/UiTable/UiTable';
import { FilterOption } from 'components/Ui/UiTableHeader/UiTableHeader.type';
// Chacer after unifying Juan's assignment pending
import { useTranslation } from 'react-i18next';
import VehicleCounterActions from 'api/VehicleCounterActions';
import { VehicleCounterActionsType } from 'models/VehicleCounterActions.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Column } from 'models/Table.type';
import { VehicleCounterActionsTime } from '../PredictedGraph.style';

const vehicleCounterActionsAPI = new VehicleCounterActions();

const VehicleCounterActionTable = ({ vehicleId, counterActionsOptions }: VehicleCounterActionTableProps) => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();

    // Chacer after unifying Juan's assignment
    const columns: Column<VehicleCounterActionsType>[] = useMemo(() => {
        return [
            {
                accessor: (row: VehicleCounterActionsType) => row.counterActionIds,
                Header: translate('t.counter_actions'),
                disableFilters: true,
                disableSortBy: true,
                width: 100,
                Cell: (props: { row: { original: VehicleCounterActionsType } }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {props.row?.original?.counterActionIds.map((id) => {
                            let option = counterActionsOptions?.find((counterAction) => counterAction.id === id);
                            return <span key={option?.id || 0}>{option?.name}</span>;
                        })}
                    </div>
                )
            },
            {
                accessor: 'expiresAt',
                Header: translate('t.expires_at'),
                disableFilters: true,
                disableSortBy: true,
                width: 120,
                Cell: (props: { row: { original: VehicleCounterActionsType } }) => {
                    const time = fromUTCtoUserTimezone({
                        date: props.row.original.expiresAt,
                        format: 'dateTime',
                        displaySeconds: false
                    }).split(' ');

                    return (
                        <VehicleCounterActionsTime>
                            <span>{time[1]}</span>
                            <span>{time[0]}</span>
                        </VehicleCounterActionsTime>
                    );
                }
            },
            {
                accessor: 'createdAt',
                Header: translate('t.created_at'),
                disableFilters: true,
                disableSortBy: true,
                width: 120,
                Cell: (props: { row: { original: VehicleCounterActionsType } }) => {
                    const time = fromUTCtoUserTimezone({
                        date: props.row.original.createdAt,
                        format: 'dateTime',
                        displaySeconds: false
                    }).split(' ');

                    return (
                        <VehicleCounterActionsTime>
                            <span>{time[1]}</span>
                            <span>{time[0]}</span>
                        </VehicleCounterActionsTime>
                    );
                }
            }
        ];
    }, [counterActionsOptions]);

    return (
        <div>
            <UiTable
                columns={columns}
                useColumnFiltering
                fetchFn={vehicleCounterActionsAPI.getVehicleCounterAction}
                queryKey='VehicleCounterActionTable'
                additionalProps={{
                    vehicleId: vehicleId
                }}
                title={translate('t.counter_actions')}
                refresh={60000}
                bodyHeight={345}
            />
        </div>
    );
};

export default VehicleCounterActionTable;
