import styled from 'styled-components';
import Button from 'components/Ui/Components/UiButton';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const CurrentTimeContent = styled.div`
    text-align: center;
`;

export const ButtonForm = styled(Button)`
    ${() => {
        const {
            palette: { common }
        } = useTheme() as { palette: { common: CommonTypeTheme } };
        return `font-size: ${common.textSize} !important;`;
    }}
`;

export const ButtonOpenPopUp = styled(Button)`
    ${() => {
        const {
            palette: { common }
        } = useTheme() as { palette: { common: CommonTypeTheme } };
        return `font-size: ${common.textSize} !important;`;
    }}
    margin: 0px !important;
    padding: 0px !important;
`;
