import React, { useEffect, useCallback } from 'react';
import { TyreLifespanComparisonContent } from './TyreLifespanComparison.view';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TyreLifespanComparisonProps } from './TyreLifespanComparison.type';
import { useQuery } from '@tanstack/react-query';
import Tyre from '../../api/Tyre';
import { DateTime, Interval, Settings } from 'luxon';
import { BesRankTimeline, BesRankTicks } from '../SeverityRank/atom';
import { TyreLifeSpanDataAtom, TyreLifespanDataDefaultValue } from './TyreLifespanComparison.atom';
import { Wrapper } from 'helpers/wrapper';

const tyreApi = new Tyre();

const TyreLifespanComparison: React.FC<TyreLifespanComparisonProps> = (): JSX.Element => {
    const [firstTyreData, setFirstTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-1'));
    const [secondTyreData, setSecondTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-2'));
    const setRankTimeline = useSetRecoilState(BesRankTimeline);
    const setRankTicks = useSetRecoilState(BesRankTicks);

    const generateBESTimeline = useCallback((): void => {
        let timeline = {};
        Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                const timeKey = element.valueOf() / 1000;
                timeline[timeKey] = { timeKey };
            });

        setRankTimeline(timeline);
        const ticks = Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .filter((element) => {
                return element.weekday === 1;
            })
            .map((element) => element.valueOf() / 1000);

        setRankTicks(ticks);
    }, [Settings.defaultZone]);

    const { refetch: refetchTyreDetailsFirst } = useQuery(
        [`TyreData-1-${firstTyreData.tyreId}`],
        () => tyreApi.getTyreLifeDetail(firstTyreData.tyreId),
        {
            enabled: false,
            retry: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => {
                setFirstTyreData({
                    ...firstTyreData,
                    tyreSerialNumber: dataOnSuccess.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                    customPosition: dataOnSuccess.tyreLifeDetail.tyre.customPosition ?? NaN,
                    vehicleId: dataOnSuccess.tyreLifeDetail.tyre.vehicleId
                });
                generateBESTimeline();
            },
            onError: () => {
                setFirstTyreData(TyreLifespanDataDefaultValue);
            }
        }
    );

    const { refetch: refetchTyreDetailsSecond } = useQuery(
        [`TyreData-2-${secondTyreData.tyreId}`],
        () => tyreApi.getTyreLifeDetail(secondTyreData.tyreId),
        {
            enabled: false,
            retry: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => {
                setSecondTyreData({
                    ...secondTyreData,
                    tyreSerialNumber: dataOnSuccess.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                    customPosition: dataOnSuccess.tyreLifeDetail.tyre.customPosition ?? NaN,
                    vehicleId: dataOnSuccess.tyreLifeDetail.tyre.vehicleId
                });
                generateBESTimeline();
            },
            onError: () => {
                setSecondTyreData(TyreLifespanDataDefaultValue);
            }
        }
    );

    useEffect(() => {
        return () => {
            setSecondTyreData(TyreLifespanDataDefaultValue);
            setFirstTyreData(TyreLifespanDataDefaultValue);
        };
    }, []);

    useEffect(() => {
        !isNaN(firstTyreData.tyreId) && refetchTyreDetailsFirst();
    }, [firstTyreData]);

    useEffect(() => {
        !isNaN(secondTyreData.tyreId) && refetchTyreDetailsSecond();
    }, [secondTyreData]);

    return (
        <>
            <TyreLifespanComparisonContent data-testid={'TyreLifespanComparison-testid'} />
        </>
    );
};

export default Wrapper(TyreLifespanComparison);
