import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { GlobalAdminViewProps } from './GlobalAdmin.type';
import { GlobalAdminContainer } from './GlobalAdmin.style';
import UiTable from '../../Ui/Components/UiTable2';
import Widget from '../../Ui/Components/UiWidget';
import Customer from '../../../api/Customer';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

export const GlobalAdminContent: React.FC<GlobalAdminViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const customerApi = new Customer();
    const ThemeMode = useRecoilValue(Theme);
    const hidden: string[] = ['updatedAtFrom', 'updatedAtTo', 'registeredAtFrom', 'registeredAtTo', 'logo'];

    return (
        <GlobalAdminContainer data-testid='GlobalAdminContainer-testid'>
            <Widget
                data-testid='GlobalAdminContent-Widget'
                avatar={
                    <UiIcon
                        data-testid='GlobalAdminContent-UiIcon'
                        icon={faUsers}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.customers')}
                loading={false}
                content={
                    <UiTable
                        data-testid='GlobalAdminContent-UiTable'
                        columns={props.columns}
                        fetchFn={customerApi.getCustomerTable}
                        hiddenColumns={hidden}
                        defaultSortBy={{
                            id: 'name',
                            desc: false
                        }}
                        queryKey={'globalAdminCustomers'}
                        refresh={false}
                        defaultFilter={{
                            id: 'enabled',
                            value: { name: translate('t.enabled'), value: 'true' }
                        }}
                    />
                }
            ></Widget>
        </GlobalAdminContainer>
    );
};
