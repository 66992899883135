import React from 'react';
import { CustomCheckBox, CustomLoading } from './UiCheckboxForTable.style';
import { UiCheckboxForTableProps } from './UiCheckboxForTable.type';
import { SIZE_TEXT_VARIANT } from 'components/Ui/variables';

const UiCheckboxForTable: React.FC<UiCheckboxForTableProps> = ({
    name,
    checked,
    disabled,
    checkboxLoading,
    color,
    testid,
    onChange
}): JSX.Element => {
    if (checkboxLoading) {
        return <CustomLoading size={SIZE_TEXT_VARIANT(10)} data-testid='UiCheckboxForTable-CustomLoading' />;
    }

    return (
        <CustomCheckBox
            data-testid={`UiCheckboxForTable-CheckBox-${testid}`}
            name={name}
            checked={checked}
            disabled={disabled}
            color={color}
            onChange={onChange}
        />
    );
};

export default UiCheckboxForTable;
