import React from 'react';
import { TyreStateAtomType, TyreStateType } from './TyreManager.atom';
import { CRUD } from 'variables';
const getState = (originalValue: TyreStateType, newValue: TyreStateType): CRUD.MOUNT | CRUD.REPLACE | CRUD.UNMOUNT => {
    if (!originalValue.id) return CRUD.MOUNT;
    if (originalValue.id === newValue.id) return CRUD.UNMOUNT;
    return CRUD.REPLACE;
};

export const TyreStateDispatcher =
    (state: TyreStateAtomType, setState: React.Dispatch<TyreStateAtomType>) =>
    (payload: TyreStateType): void => {
        setState({
            ...state,
            newTyre: payload,
            state: getState(state.originalTyre, payload)
        });
    };
