import React, { useMemo, useState } from 'react';
import { MapLegendInfoPopoverProps } from './MapLegendInfoPopover.type';
import MapLegendInfoPopoverView from './MapLegendInfoPopover.view';

const MapLegendInfoPopover: React.FC<MapLegendInfoPopoverProps> = ({ message }) => {
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const [unreadMessage, setUnreadMessage] = useState<boolean>(true);
    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const handleMouseEnter = (event: React.MouseEvent<SVGSVGElement>): void => {
        setUnreadMessage(false);
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = (): void => {
        setAnchorEl(null);
    };

    return (
        <MapLegendInfoPopoverView
            message={message}
            anchorEl={anchorEl}
            open={open}
            unreadMessage={unreadMessage}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
        />
    );
};

export default MapLegendInfoPopover;
