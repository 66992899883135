import React from 'react';
import { FilterContainer, FilterSelectOption } from './VehicleFilters.style';
import { Select } from '@mui/material';
import { useRecoilState } from 'recoil';
import { VehicleFiltersAtom } from './VehicleFilters.atom';
import { useTranslation } from 'react-i18next';

export const Sorting: React.FC = (): JSX.Element => {
    const [vehicleFiltersAtom, setVehicleFiltersAtom] = useRecoilState(VehicleFiltersAtom);
    const { t: translate } = useTranslation();

    return (
        <FilterContainer data-testid='ButtonToFilterBySortBy'>
            <Select
                variant='outlined'
                size='small'
                value={vehicleFiltersAtom.sortBy}
                onChange={(event) => {
                    setVehicleFiltersAtom({
                        ...vehicleFiltersAtom,
                        sortBy: event.target.value as string
                    });
                    localStorage.setItem('vehicleStatusSort', event.target.value as string);
                }}
                data-testid='VehicleStatus-SortBy-Select'
            >
                <FilterSelectOption value='severity_alert:desc' data-testid='VehicleStatus-SortBy-Severity-Button'>
                    <span>{translate('t.severity')}</span>
                </FilterSelectOption>
                <FilterSelectOption value='severity+name' data-testid='VehicleStatus-SortBy-SeverityName-Button'>
                    <span>
                        {translate('t.severity')} + {translate('t.alphabetically')}
                    </span>
                </FilterSelectOption>
                <FilterSelectOption value='name:asc' data-testid='VehicleStatus-SortBy-Alphabetically-Button'>
                    <span>{translate('t.alphabetically')}</span>
                </FilterSelectOption>
            </Select>
        </FilterContainer>
    );
};
