import { RecoilState, atom } from 'recoil';

let refreshAtoms = {};

export const RefreshTable = (key?: string): RecoilState<boolean> => {
    if (key && !refreshAtoms[key]) {
        refreshAtoms[key] = atom<boolean>({
            key: key,
            default: false
        });
    } else if (!key && !refreshAtoms['RefreshTableAtom']) {
        refreshAtoms['RefreshTableAtom'] = atom<boolean>({
            key: 'RefreshTableAtom',
            default: false
        });
    }

    return refreshAtoms[key ?? 'RefreshTableAtom'];
};
