import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useConverter from 'components/CustomHooks/Converter/Converter';
import Device from 'api/Device';
import NewHubsExtendedRow from 'components/Hub/NewHubsExtendedRow';
import { AllHubsTableData } from 'models/Device.type';
import DeviceRevision from 'api/DeviceRevision';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeviceRevisionCodeBook } from 'models/DeviceRevision.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { DARK_STEEL_GREY, BACKGROUND_BLUE, LINK, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_GREY_3, SUCCESS, ERROR } from 'components/Ui/colors';
import { TextField, IconButton } from '@mui/material';
import DeviceCommand from 'api/DeviceCommand';
import UiButton from 'components/Ui/Components/UiButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UiMenu from 'components/Ui/Components/UiMenu';
import { faDesktop } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { NoRevisionLabel } from './NewHubs.style';
import UiTable from 'components/Ui/UiTable/UiTable';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import { Wrapper } from 'helpers/wrapper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { applyStyleByMode } from 'helpers';
import { DateTime } from 'luxon';
import { UserInfo } from 'states/global/User';

const hubAPI = new Device();
const deviceRevisionAPI = new DeviceRevision();
const deviceCommandAPI = new DeviceCommand();

const testStatusColors = [COLOR_GREY_3, SUCCESS, ERROR];

const PERIODS_MINUTES: number[] = [60000, 120000, 300000, 900000, 1800000];

const NewHubs: React.FC = (): JSX.Element => {
    const [refreshTime, setRefreshTime] = useState<number>(
        Number(localStorage.getItem('new-hubs-refetch-interval')) || 120000
    );
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone, dateTimeFormat } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const userInfo = useRecoilValue(UserInfo);
    const list = useMemo(() => {
        return PERIODS_MINUTES.map((minutes) => {
            return {
                title: `${minutes / 60000}m`,
                value: minutes,
                action: setRefreshTime
            };
        });
    }, []);

    const mutation = useMutation<unknown, unknown, number>({
        mutationFn: (id: number) =>
            deviceCommandAPI.post({
                data: {
                    commandName: 'self_diagnostics',
                    value: null,
                    deviceIds: [id]
                }
            })
    });

    let { data } = useQuery(
        ['GetDeviceRevisionCodebook'],
        async () => {
            const data = await deviceRevisionAPI.get<DeviceRevisionCodeBook>({});
            return { codeBook: data.codeBook.sort((a, b) => b.id - a.id) };
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    const columns = useMemo(
        () => [
            {
                Header: `${translate('t.box')} ID`,
                accessor: 'id',
                Cell: (props: { value: number; row: { original: AllHubsTableData } }) => (
                    <UiLink
                        testId='box-id'
                        $padding='0'
                        content={`${props.value}`}
                        url={`/device/${props.row.original.id}`}
                        color={applyStyleByMode({
                            styleOld: LINK,
                            theme: ThemeMode?.mode,
                            light: DARK_STEEL_GREY,
                            dark: BACKGROUND_BLUE
                        })}
                    />
                )
            },
            {
                Header: translate('t.serial_number'),
                accessor: 'serialNumber'
            },
            {
                Header: translate('t.hub_revision'),
                accessor: 'deviceRevision',
                Cell: (props: { value: string; row: { original: AllHubsTableData } }) =>
                    props.row.original?.deviceRevision?.name || (
                        <NoRevisionLabel>{translate('t.no_revision')}</NoRevisionLabel>
                    )
            },
            {
                Header: translate('t.latest_test_status'),
                accessor: 'testStatus',
                disableFilters: true,
                disableSortBy: true,
                Cell: (props: { value: number; row: { original: AllHubsTableData } }) => (
                    <IconButton
                        data-testid='rerun-test-button'
                        onClick={() => {
                            mutation.mutate(props.row.original.id);
                        }}
                        title={translate('t.rerun_test')}
                    >
                        <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: testStatusColors[props.value] }} />
                    </IconButton>
                )
            },
            {
                Header: translate('t.battery_voltage'),
                accessor: 'deviceInfo.battery'
            },
            {
                Header: translate('t.date_of_last_test'),
                accessor: 'lastTestAt',
                disableSortBy: true,
                disableFilters: true,
                Cell: (props: { value: string | null; row: { original: AllHubsTableData } }) => {
                    return fromUTCtoUserTimezone({
                        date: props.value,
                        format: 'dateTime',
                        displaySeconds: false,
                        displayIfEmpty: ''
                    });
                }
            },
            {
                Header: 'UUID',
                accessor: 'uuid'
            },
            {
                Header: translate('t.first_connection'),
                accessor: 'createdAt',
                Cell: (props: { value: string }) =>
                    fromUTCtoUserTimezone({
                        date: props.value,
                        format: 'dateTime'
                    }),
                Filter: ({ setFilter, state, applyFiltersFlag, column }) => {
                    const { cellValue: dateFromValue, setFilterValue: setFilterFromValue } = useTableFilter({
                        filterContent: {
                            name: `${column.Header} ${translate('t.from')}`,
                            value: state.filters.filter((filter) => filter.id === 'createdAtFrom')[0]?.value.value,
                            humanValue: state.filters
                                .filter((filter) => filter.id === 'createdAtFrom')[0]
                                ?.value?.value?.toFormat(dateTimeFormat('date', false))
                        },
                        setFilter: setFilter,
                        applyFiltersFlag: applyFiltersFlag,
                        filterId: 'createdAtFrom',
                        valueType: 'date'
                    });
                    const { cellValue: dateToValue, setFilterValue: setFilterToValue } = useTableFilter({
                        filterContent: {
                            name: `${column.Header} ${translate('t.to')}`,
                            value: state.filters.filter((filter) => filter.id === 'createdAtTo')[0]?.value.value,
                            humanValue: state.filters
                                .filter((filter) => filter.id === 'createdAtTo')[0]
                                ?.value?.value?.toFormat(dateTimeFormat('date', false))
                        },
                        setFilter: setFilter,
                        applyFiltersFlag: applyFiltersFlag,
                        filterId: 'createdAtTo',
                        valueType: 'date'
                    });

                    return (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                                format={dateTimeFormat('date', false)}
                                label={`${column.Header} ${translate('t.from')}`}
                                value={dateFromValue?.value || null}
                                onChange={(newValue: DateTime): void => {
                                    setFilterFromValue(newValue);
                                }}
                                slots={{ textField: TextField }}
                                slotProps={{
                                    textField: (params) => ({
                                        'data-testid': `UiDatePickerFilter-New-Hubs-textField`,
                                        margin: 'normal',
                                        id: `UiDatePickerFilter-updated-from-New-Hubs`,
                                        size: 'small',
                                        variant: 'outlined',
                                        sx: { marginTop: '5px' },

                                        InputLabelProps: { shrink: true },
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder:
                                                userInfo.user?.userSetting.dateFormat?.toLocaleUpperCase() ||
                                                'YYYY-MM-DD',
                                            style: { padding: '10.5px' }
                                        }
                                    })
                                }}
                            />

                            <DatePicker
                                format={dateTimeFormat('date', false)}
                                label={`${column.Header} ${translate('t.to')}`}
                                value={dateToValue?.value || null}
                                onChange={(newValue: DateTime): void => {
                                    setFilterToValue(newValue);
                                }}
                                slots={{ textField: TextField }}
                                slotProps={{
                                    textField: (params) => ({
                                        'data-testid': `UiDatePickerFilter-New-Hubs-textField`,
                                        margin: 'normal',
                                        id: `UiDatePickerFilter-updated-from-New-Hubs`,
                                        size: 'small',
                                        variant: 'outlined',
                                        sx: { marginTop: '5px' },

                                        InputLabelProps: { shrink: true },
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder:
                                                userInfo.user?.userSetting.dateFormat?.toLocaleUpperCase() ||
                                                'YYYY-MM-DD',
                                            style: { padding: '10.5px' }
                                        }
                                    })
                                }}
                            />
                        </LocalizationProvider>
                    );
                }
            },
            {
                Header: translate('t.last_time_transmitted'),
                accessor: 'deviceInfo.lastTransmission',
                Cell: (props: { value: string }) =>
                    fromUTCtoUserTimezone({
                        date: props.value,
                        format: 'dateTime'
                    }),
                Filter: ({ setFilter, state, applyFiltersFlag, column }) => {
                    const { cellValue: dateFromValue, setFilterValue: setFilterFromValue } = useTableFilter({
                        filterContent: {
                            name: `${column.Header} ${translate('t.from')}`,
                            value: state.filters.filter((filter) => filter.id === 'lastTransmissionFrom')[0]?.value
                                .value,
                            humanValue: state.filters
                                .filter((filter) => filter.id === 'lastTransmissionFrom')[0]
                                ?.value?.value?.toFormat(dateTimeFormat('date', false))
                        },
                        setFilter: setFilter,
                        applyFiltersFlag: applyFiltersFlag,
                        filterId: 'lastTransmissionFrom',
                        valueType: 'date'
                    });
                    const { cellValue: dateToValue, setFilterValue: setFilterToValue } = useTableFilter({
                        filterContent: {
                            name: `${column.Header} ${translate('t.to')}`,
                            value: state.filters.filter((filter) => filter.id === 'lastTransmissionTo')[0]?.value.value,
                            humanValue: state.filters
                                .filter((filter) => filter.id === 'lastTransmissionTo')[0]
                                ?.value?.value?.toFormat(dateTimeFormat('date', false))
                        },
                        setFilter: setFilter,
                        applyFiltersFlag: applyFiltersFlag,
                        filterId: 'lastTransmissionTo',
                        valueType: 'date'
                    });

                    return (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                                format={dateTimeFormat('date', false)}
                                label={`${translate('t.last_time_transmitted')} ${translate('t.from')}`}
                                value={dateFromValue?.value || null}
                                onChange={(newValue: DateTime): void => setFilterFromValue(newValue)}
                                slots={{ textField: TextField }}
                                slotProps={{
                                    textField: (params) => ({
                                        'data-testid': `UiDatePickerFilter-New-Hubs-textField`,
                                        margin: 'normal',
                                        id: `UiDatePickerFilter-updated-from-New-Hubs`,
                                        size: 'small',
                                        variant: 'outlined',
                                        sx: { marginTop: '5px' },

                                        InputLabelProps: { shrink: true },
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder:
                                                userInfo.user?.userSetting.dateFormat?.toLocaleUpperCase() ||
                                                'YYYY-MM-DD',
                                            style: { padding: '10.5px' }
                                        }
                                    })
                                }}
                            />

                            <DatePicker
                                format={dateTimeFormat('date', false)}
                                label={`${translate('t.last_time_transmitted')} ${translate('t.to')}`}
                                value={dateToValue?.value || null}
                                onChange={(newValue: DateTime): void => setFilterToValue(newValue)}
                                slots={{ textField: TextField }}
                                slotProps={{
                                    textField: (params) => ({
                                        'data-testid': `UiDatePickerFilter-New-Hubs-textField`,
                                        margin: 'normal',
                                        id: `UiDatePickerFilter-updated-from-New-Hubs`,
                                        size: 'small',
                                        variant: 'outlined',
                                        sx: { marginTop: '5px' },

                                        InputLabelProps: { shrink: true },
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder:
                                                userInfo.user?.userSetting.dateFormat?.toLocaleUpperCase() ||
                                                'YYYY-MM-DD',
                                            style: { padding: '10.5px' }
                                        }
                                    })
                                }}
                            />
                        </LocalizationProvider>
                    );
                }
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'createdAtFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'createdAtTo'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'lastTransmissionFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'lastTransmissionTo'
            }
        ],
        []
    );

    const expandableContent = (row: AllHubsTableData): JSX.Element => (
        <NewHubsExtendedRow hubData={row} revisionCodebook={data} />
    );

    return (
        <UiTable
            fetchFn={hubAPI.getNewHubsTable}
            refresh={refreshTime}
            title={translate('t.new_hubs')}
            closeButton={true}
            avatar={
                <UiIcon
                    icon={faDesktop}
                    size='lg'
                    fixedWidth
                    color={applyStyleByMode({
                        styleOld: '#fff',
                        theme: ThemeMode?.mode,
                        light: undefined,
                        dark: WHITE
                    })}
                />
            }
            columns={columns}
            hiddenColumns={['createdAtFrom', 'createdAtTo', 'lastTransmissionFrom', 'lastTransmissionTo']}
            queryKey={'NewHubs'}
            isExpandable={true}
            expandableContent={expandableContent}
            defaultSortBy={{
                id: 'id',
                desc: true
            }}
            rightActionBtns={() => (
                <>
                    {translate('t.refresh_time')}:
                    <UiMenu
                        rootEl={
                            <UiButton color='secondary' variant='text'>
                                {refreshTime / 60000}m <ExpandMoreIcon />
                            </UiButton>
                        }
                        items={list}
                    />
                </>
            )}
        />
    );
};

export default Wrapper(NewHubs);
