import Button from '@mui/material/Button';
import styled from 'styled-components';
import { PRIMARY } from '../../colors';
import CustomButton from '../UiButton';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const UiIconPickerContainer = styled.div``;

export const IconPickerButton = styled(CustomButton)`
    &&.MuiButton-root {
        height: 66px;
        margin: 0;
        img {
            padding-right: 10px;
            margin: auto;
        }
        .selected-icon-cover {
            height: 45px;
            width: 60px;
            display: flex;
        }
        .picker-expand-more {
            padding-left: 5px;
            display: flex;
            height: 45px;
            align-items: center;
            border-left: solid thin #bfbfbf;
        }
        :hover {
            border-color: ${PRIMARY};
        }
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                styleJade: `
                    background: transparent;
                `,
                light: `
                    border: thin solid #bfbfbf;
                    color: ${PRIMARY};
                `,
                dark: `
                   border: thin solid #ffffff3b;
                   color: #bfbfbf;
                `
            });
        }}
    }
`;

export const IconCover = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const IconButton = styled(Button)`
    &.MuiButton-root {
        margin: 2px;
    }
`;
