import React from 'react';
import {
    CustomHeaderCell,
    CustomHeaderRow,
    CustomTableRow,
    EmptyTable,
    Table,
    UiSimpleTableContentView,
    RefreshButton,
    RefreshButtonContainer
} from './UiSimpleTable.style';
import { Order, UiSimpleTableViewProps } from './UiSimpleTable.type';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { RefreshSimpleTable } from './UiSimpleTable.atom';
import { SIZE_BUTTON } from 'components/Ui/variables';

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof number | string>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const isNaN = Number.isNaN(Number(a[orderBy]));
    let { compareA, compareB } = isNaN
        ? {
              compareA: a[orderBy]
                  ? typeof a[orderBy] == 'object' && a[orderBy]['name']
                      ? a[orderBy]['name']
                      : a[orderBy]
                  : '',
              compareB: b[orderBy]
                  ? typeof b[orderBy] == 'object' && b[orderBy]['name']
                      ? b[orderBy]['name']
                      : b[orderBy]
                  : ''
          }
        : { compareA: Number(a[orderBy]), compareB: Number(b[orderBy]) };

    if (compareB < compareA) {
        return -1;
    }
    if (compareB > compareA) {
        return 1;
    }
    return 0;
}

export const UiSimpleTableContent: React.FC<UiSimpleTableViewProps> = (props): JSX.Element => {
    const setRefreshTableAtom = useSetRecoilState(RefreshSimpleTable);
    const { t: translate } = useTranslation();
    let dataToDisplay =
        props.parseData && props.parseData instanceof Function ? props.parseData(props.data) : props.data;
    const sliceStart = props.usePaginator ? props.page * props.rowsPerPage : 0;
    const sliceEnd = props.usePaginator ? props.page * props.rowsPerPage + props.rowsPerPage : 20;
    return (
        <UiSimpleTableContentView data-testid='UiSimpleTableContent' id='UiSimpleTableContent' style={props.style}>
            {props.refreshButton && (
                <RefreshButtonContainer>
                    <IconButton
                        data-testid={`Refresh-${props.queryKey}JobsTableContent-Button`}
                        onClick={() => setRefreshTableAtom(true)}
                        size={SIZE_BUTTON}
                    >
                        <RefreshButton $spin={props.isLoading} />
                    </IconButton>
                </RefreshButtonContainer>
            )}
            <TableContainer>
                <Table size='small' data-testid='simple-table' className={`${props.isLoading && 'loading'}`}>
                    <TableHead>
                        <CustomHeaderRow>
                            {props.columns.map(
                                (column, index) =>
                                    !column.hidden && (
                                        <CustomHeaderCell
                                            key={index}
                                            sortDirection={props.orderBy === column.accessor ? props.order : false}
                                        >
                                            <TableSortLabel
                                                disabled={column.disableSortBy}
                                                active={props.orderBy === column.accessor}
                                                direction={props.orderBy === column.accessor ? props.order : 'asc'}
                                                onClick={() => props.handleSort(column.accessor)}
                                            >
                                                {column.header}
                                            </TableSortLabel>
                                        </CustomHeaderCell>
                                    )
                            )}
                        </CustomHeaderRow>
                    </TableHead>
                    <TableBody>
                        {dataToDisplay.length === 0 ? (
                            <CustomTableRow>
                                <EmptyTable colSpan={props.columns.length}>
                                    <p>{translate('t.there_no_data')}</p>
                                </EmptyTable>
                            </CustomTableRow>
                        ) : (
                            stableSort(dataToDisplay, getComparator(props.order, props.orderBy))
                                .slice(sliceStart, sliceEnd)
                                .map((row, index) => (
                                    <CustomTableRow key={`row_${index}`}>
                                        {props.columns.map(
                                            (column, index) =>
                                                !column.hidden && (
                                                    <TableCell key={`column_${index}`}>
                                                        <>
                                                            {props.allprops
                                                                ? column.parseData
                                                                    ? column.parseData(row)
                                                                    : row
                                                                : column.parseData
                                                                ? column.parseData(row[column.accessor])
                                                                : row[column.accessor]}
                                                        </>
                                                    </TableCell>
                                                )
                                        )}
                                    </CustomTableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.usePaginator && (
                <Table>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                colSpan={3}
                                count={dataToDisplay.length}
                                rowsPerPage={props.rowsPerPage}
                                page={props.page}
                                onPageChange={props.handleChangePage}
                                onRowsPerPageChange={props.handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            )}
        </UiSimpleTableContentView>
    );
};
