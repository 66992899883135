import ReactGA from 'react-ga4';

export const GaPageTitle = {
    '/profile': 'User Profile',
    '/dashboard': 'Dashboard',
    '/vehicle-status': 'Vehicles Status',
    '/map': 'Tracking - Map',
    '/areas': 'Tracking - Areas',
    '/speed-incidents': 'Tracking - Speed Incidents',
    '/routes': 'Tracking - ',
    '/heatpath': 'Tracking - ',
    '/execessive-acceleration': 'Tracking - Excessive Acceleration',
    '/speedheatmap': 'Tracking - ',
    '/tyres': 'Tyres - Tyres',
    '/sensors': 'Tyres - Sensors',
    '/pressure-temperature': 'Tyres - Temperature & Pressure',
    '/set-alerts': 'Tyres - New Alerts',
    '/vehicles': 'Vehicles - Vehicles',
    '/vehicle-group': 'Vehicles - Vehicles Groups',
    '/wheels-tpms': 'Data Exports - Wheels TPMS',
    '/notification-temp-and-press': 'Data Exports - Temperature & Pressure',
    '/notification-temperature': 'Data Exports - Temperature',
    '/track-log': 'Data Exports - Wheel TPMS',
    '/over-speeding': 'Data Exports - Over-speeding',
    '/area-entries': 'Data Exports - Areas Entries',
    '/temp-press-alerts-levels': 'Data Exports - Temp & Press Alert Levels',
    '/accelerometer': 'Data Exports - Accelerometer',
    '/exported-reports': 'Data Exports - Exported reports',
    '/users': 'Admin - Users',
    '/site': 'Admin - Site',
    '/notifications': 'Admin - Settings',
    '/hubs': 'Admin - Devices',
    '/report-permissions': 'Admin - Report Permissions',
    '/tablet-activation': 'Admin - Tablet Activation',
    '/user-action': 'Admin - User Action',
    '/admin-customers': 'Global Admin - Customers',
    '/permissions': 'Global Admin - Permissions',
    '/admin-hub-notify': 'Global Admin - Hub Notify',
    '/admin-hubs': 'Global Admin - Hubs',
    '/admin-new-hubs': 'Global Admin - New Hubs',
    '/admin-customer-report': 'Global Admin - Customer Report',
    '/announcements': 'Global Admin - Announcements',
    '/device/': 'Device details',
    '/vehicle//statistics': 'Statistics',
    '/vehicle/detail/': 'Vehicle',
    '/profile/': 'View User Details',
    '/quicksight-reports': 'Reports',
    '/lifespan-report': 'Tyre Lifespan report',
    '/lifespan-comparison': 'Tyre Lifespan Comparison report',
    '/sensor-faulty-reports': 'Faulty Sensor Reports',
    '/temperature-exposure': 'Wheel Temperature Levels'
};

export const getTrafficTypeFromEmail = (email) => {
    // Convert the email to lowercase for case-insensitive matching
    const lowerEmail = email.toLowerCase();

    // Check if the email contains any of the specified words
    if (
        lowerEmail.includes('bsem') ||
        lowerEmail.includes('bfusa') ||
        lowerEmail.includes('bsorl') ||
        lowerEmail.includes('bridgestone')
    ) {
        return 'internal-bridgestone';
    } else if (lowerEmail.includes('atmstechnology')) {
        return 'internal-atms';
    } else {
        // Return 'external' as the default value
        return 'external';
    }
};

const G_KEY = process.env.REACT_APP_GA_KEY || 'none';

let isGAInitialized = false;

if (!isGAInitialized) {
    ReactGA.initialize(G_KEY ?? 'NO-KEY');
    isGAInitialized = true;
}

export default ReactGA;
