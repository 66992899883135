import React from 'react';
import { RegionCustomerSelectorContainer } from './RegionCustomerSelector.style';
import { RegionCustomerSelectorViewProps } from './RegionCustomerSelector.type';
import { CustomerCodebook } from 'models/Customer.type';
import { Autocomplete, TextField } from '@mui/material';
import CustomerSelectorCheckbox from '../CustomerSelectorCheckbox/CustomerSelectorCheckbox';
import { useTranslation } from 'react-i18next';

export const RegionCustomerSelectorContent: React.FC<RegionCustomerSelectorViewProps> = ({
    selectedCustomers,
    onChange,
    divRef,
    handleOpenSelector,
    spaceToBottom,
    removeSelectedCustomers,
    customerData,
    customersDataLoading,
    disabled
}) => {
    const { t: translate } = useTranslation();

    return (
        <>
            <RegionCustomerSelectorContainer>
                <Autocomplete
                    multiple
                    size='small'
                    fullWidth
                    disabled={disabled}
                    value={selectedCustomers}
                    getOptionLabel={(option: CustomerCodebook) => option.name ?? ''}
                    options={[]}
                    id='customer'
                    onChange={(e, value) => {
                        removeSelectedCustomers(value);
                        onChange(e, value);
                    }}
                    clearIcon={false}
                    renderInput={(params) => (
                        <TextField
                            ref={divRef}
                            {...params}
                            onClick={() => {
                                if (!disabled) {
                                    handleOpenSelector();
                                }
                                return false;
                            }}
                            variant='outlined'
                            label={translate('t.customers')}
                            key={params.id}
                            name={params.id}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    )}
                    open={false}
                />
            </RegionCustomerSelectorContainer>

            <CustomerSelectorCheckbox
                spaceToBottom={spaceToBottom}
                selectedCustomers={selectedCustomers}
                onChange={onChange}
                customerData={customerData}
                customersDataLoading={customersDataLoading}
            />
        </>
    );
};
