import { Paper } from '@mui/material';
import styled from 'styled-components';

export const ChangesPasswordViewContent = styled.div`
    padding-top: 4%;

    .MuiPaper-elevation1 {
        box-shadow: 0 0 15px 5px rgba(0, 0, 0, 10%) !important;
    }
    .logo-login {
        width: 250px;
        display: block;
        margin: 0 auto;
    }
    & .MuiTextField-root {
        width: 100%;
        margin: 3px 0;
    }
    & .MuiButton-root {
        width: 100%;
        margin: 20px 0;
    }
`;

export const ChangesPasswordPaper = styled(Paper)`
    width: 420px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 15px 20px;
`;
