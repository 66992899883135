import PortalApiClient from 'helpers/api/PortalApiClient';
import { AccelerometerEventModel, AccelerometerEventMap } from '../models/AccelerometerEvent.type';
import { GetExcessiveAccParams } from '../models/Vehicle.type';

export default class AccelerometerEvent extends PortalApiClient<AccelerometerEventModel> {
    route = 'accelerometer-event';

    getAccelerometerEvent = (params: GetExcessiveAccParams): Promise<AccelerometerEventMap> => {
        params.vehicle == 0 && delete params['vehicle'];
        if (params.loaded === -1) {
            delete params['loaded'];
        }
        if (params.vehicleOperator === null) {
            delete params['vehicleOperator'];
        }

        return this.getByCriteria({
            criteria: params
        });
    };
}
