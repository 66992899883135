import { VehicleList } from '../../models/Vehicle.type';
import { DateTime } from 'luxon';

export type TrackingMapTopBarProps = {};

export type TrackingMapTopBarViewProps = {
    setSelectedVehicles: (VehicleList) => void;
    vehicleList: VehicleList;
    date: DateFromData;
    setDate: React.Dispatch<DateFromAction>;
    showJourneyWarning: boolean;
    showPeriodWarning: boolean;
    datePeriodIsValid: boolean;
    setShowJourneyWarning: (boolean) => void;
    setShowPeriodWarning: (boolean) => void;
    selectedVehicles: VehicleList;
    vehicleGroup?: number;
    setVehicleGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type DateFromData = {
    selectedDateFrom?: DateTime;
    selectedDateTo?: DateTime;
    isApplied?: boolean;
};

export enum DateFromActionsEnum {
    SET_SELECTED_DATE_TO = 'SET_SELECTED_DATE_TO',
    SET_APPLIED_DATE_FROM = 'SET_APPLIED_DATE_FROM',
    RESET = 'RESET',
    RESET_APPLY = 'RESET_APPLY',
    SET_SELECTED_DATE = 'SET_SELECTED_DATE'
}

type SetDateFrom = {
    type: DateFromActionsEnum.SET_SELECTED_DATE_TO;
    payload: DateTime;
};

type SetDate = {
    type: DateFromActionsEnum.SET_SELECTED_DATE;
    payload: {
        from: DateTime;
        to: DateTime;
    };
};

type ResetDateFrom = {
    type: DateFromActionsEnum.RESET | DateFromActionsEnum.RESET_APPLY | DateFromActionsEnum.SET_APPLIED_DATE_FROM;
};

export type DateFromAction = SetDateFrom | ResetDateFrom | SetDate;
