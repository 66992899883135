import React from 'react';
import * as type from './UiCheckbox.type';
import * as view from './UiCheckbox.view';
// import module

const UiCheckbox: React.FC<type.UiCheckboxProps> = (props) => {
    return (
        <view.UiCheckboxContent
            {...props}
            skinlabel={props.skinlabel ? 1 : 0}
            data-testid={props.testid || 'UiCheckbox-testid'}
        />
    );
};

export default UiCheckbox;
