import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ReportPermissionsTableProps } from './ReportPermissionsTable.type';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, CustomTableRow } from '../../User/MinesPermissions/MinesPermissions.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { NameDescription, ReportName, ReportTable, TooltipText } from './ReportPermissionsTable.style';
import InfoIcon from '@mui/icons-material/Info';
import { applyStyleByMode } from 'helpers';
import UiIcon from '../../Ui/Components/UiIcon/UiIcon';
import { Theme } from '../../../states/global/Theme';
import { BACKGROUND_BLUE } from '../../Ui/colors';

const ReportPermissionsTableContent: React.FC<ReportPermissionsTableProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const [reportId, setReportId] = useState<number | null>(null);

    useEffect(() => {
        if (!props.assignToRoleLoading && !props.removeFromRoleLoading) {
            setReportId(null);
        }
    }, [props.assignToRoleLoading, props.removeFromRoleLoading]);

    return (
        <TableContainer data-testid='ReportPermissionsTableContainer'>
            <ReportTable stickyHeader size='small' data-testid='ReportPermissionsTable'>
                <TableHead data-testid='ReportPermissionsTableHeader'>
                    <TableRow data-testid='ReportPermissionsTableHeaderRow'>
                        <TableCell data-testid='ReportPermissionsTableId'>ID</TableCell>
                        <TableCell data-testid='ReportPermissionsTableReport'>{translate('t.report')}</TableCell>
                        <TableCell data-testid='ReportPermissionsTableStandartReport'>
                            {translate('t.standart_report')}
                        </TableCell>
                        <TableCell data-testid='ReportPermissionsTableAction'>{translate('t.action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-testid='ReportPermissionsTableBody'>
                    {props.data?.map((d, index) => {
                        return (
                            <CustomTableRow key={index} $assigned={false} data-testid={`Row${index}`}>
                                <TableCell data-testid={`Row${index}CellId`}>{d.id}</TableCell>
                                <TableCell data-testid={`Row${index}CellDescription`}>
                                    <ReportName>
                                        <Tooltip
                                            title={<TooltipText>{d.description}</TooltipText>}
                                            aria-label={d.description}
                                            arrow
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                        <NameDescription>{d.name}</NameDescription>
                                    </ReportName>
                                </TableCell>
                                <TableCell data-testid={`Row${index}CellStandard`}>
                                    <UiIcon
                                        icon={['fas', d.standard ? 'circle-check' : 'circle-xmark']}
                                        size='lg'
                                        fixedWidth
                                        transform='right-2'
                                        color={applyStyleByMode({
                                            styleOld: '#777',
                                            theme: ThemeMode?.mode,
                                            light: d.standard ? BACKGROUND_BLUE : '#c0c0c0',
                                            dark: d.standard ? BACKGROUND_BLUE : '#c0c0c0'
                                        })}
                                        data-testid={`Row${index}CellStandardIcon`}
                                    />
                                </TableCell>
                                <TableCell
                                    data-testid={`Row${index}Cell${props.notAssignedTable ? 'Assign' : 'Remove'}`}
                                >
                                    <Button
                                        skin={props.notAssignedTable ? 'success' : 'error'}
                                        color={props.notAssignedTable ? 'success' : 'error'}
                                        variant='contained'
                                        onClick={() => {
                                            setReportId(d.id);
                                            props.buttonAction(d.id);
                                        }}
                                        title={
                                            props.notAssignedTable
                                                ? translate('t.assign_to_role')
                                                : translate('t.remove_from_role')
                                        }
                                        loading={
                                            props.notAssignedTable
                                                ? reportId === d.id && props.assignToRoleLoading
                                                : reportId === d.id && props.removeFromRoleLoading
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={props.notAssignedTable ? faArrowRight : faTrash}
                                            fontSize={'small'}
                                        />
                                    </Button>
                                </TableCell>
                            </CustomTableRow>
                        );
                    })}
                </TableBody>
            </ReportTable>
        </TableContainer>
    );
};

export default ReportPermissionsTableContent;
