import PortalApi from 'helpers/api/PortalApiClient';
import { AreaEntriesPreview, AreaEventModel } from '../models/AreaEvent.type';

export default class AreaEvent extends PortalApi<AreaEventModel> {
    route = 'area-event';

    getAreaEntriesReports = async (props): Promise<AreaEntriesPreview> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }

        if (props.additionalProps.areaId > 0) {
            criteria['areaId'] = props.additionalProps.areaId;
        }

        return this.getByCriteria({
            extendUrl: 'preview',
            criteria: criteria
        });
    };

    getAreaEntriesReportsCsv = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string,
        areaId?: number
    ): Promise<void> => {
        let criteria = {
            measuredFrom: measuredFrom,
            measuredTo: measuredTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }

        if (areaId && areaId > 0) {
            criteria['areaId'] = areaId;
        }

        return this.getByCriteria({
            extendUrl: 'export',

            criteria: criteria
        });
    };
}
