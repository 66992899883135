import React from 'react';
import { AlertSettingsViewContent } from './AlertSettings.style';
import { AlertSettingsViewProps } from './AlertSettings.type';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import AlertsPressureSettings from '../AlertsPressureSettings';
import AlertTemperatureSettings from '../AlertTemperatureSettings';

export const AlertSettingsContent: React.FC<AlertSettingsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <AlertSettingsViewContent data-testid='AlertSettingsContent' elevation={props.elevation || 0}>
            <Grid container>
                {props.wheelId && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant='subtitle1' gutterBottom>
                            {translate('t.alert_settings')}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                    <AlertsPressureSettings />
                    <AlertTemperatureSettings ruleType={props.ruleType} />
                </Grid>
            </Grid>
        </AlertSettingsViewContent>
    );
};
