import React, { useState } from 'react';
import { LoadDataProps, PopoverProps } from './TablePopover.type';
import { PopoverContent } from './TablePopover.view';
import Sensors from '../../api/Sensors';
import { SystemTotalDetailGet, Sensor } from '../../models/Sensors.type';
import { Wrapper } from 'helpers/wrapper';
// import module

const TablePopover: React.FC<PopoverProps> = (props): JSX.Element => {
    const sensorsApi = new Sensors();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<Sensor[]>([]);

    const loadDataXHR = async (props: LoadDataProps): Promise<void> => {
        try {
            setIsLoading(true);
            const dataXHR: SystemTotalDetailGet = await sensorsApi.getSystemTotalsDetail(
                props.dataType,
                props.stateType,
                props.transmitting
            );
            setData(dataXHR.systemTotalsDetail);
            setIsLoading(false);
        } catch (e) {
            false;
        }
    };

    const handleClick = (props: LoadDataProps, event: React.MouseEvent): void => {
        setAnchorEl(event.currentTarget);
        loadDataXHR({ ...props });
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <PopoverContent
            data-testid='Popover-testid'
            anchorEl={anchorEl}
            data={data}
            isLoading={isLoading}
            handleClick={handleClick}
            handleClose={handleClose}
            {...props}
        />
    );
};

export default Wrapper(TablePopover);
