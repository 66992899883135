import styled from 'styled-components';

export const AddUserFormViewContent = styled.div`
    margin-top: 30px;
    & .MuiGrid-item {
        padding: 10px !important;
    }
`;

export const ContainerReuiredPassword = styled.div`
    width: 100%;
    display: inline-grid;
    min-width: 120px;
`;
