import React from 'react';
import { useRecoilValue } from 'recoil';
import { SpeedIncidentsLegendContainer } from './SpeedIncidentsLegend.style';
import { SpeedIncidentsLegendProps } from './SpeedIncidentsLegend.type';
import { getImgSource } from 'helpers/image';
import { MapActionData } from 'states/global/Map';
import { useTranslation } from 'react-i18next';
import MapLegendInfoPopover from 'components/Maps/MapLegendInfoPopover';

const SpeedIncidentsLegendContent: React.FC<SpeedIncidentsLegendProps> = (): JSX.Element => {
    const mapActionData = useRecoilValue(MapActionData);
    const { t: translate } = useTranslation();

    return (
        <SpeedIncidentsLegendContainer data-testid='SpeedIncidentsLegendContent'>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <img width={20} src={getImgSource('speedPointLoaded')} alt='Speed Point Loaded Icon' />
                        </td>
                        <td>
                            {translate('t.loaded')} ({mapActionData.loadedCounts?.loaded ?? 0})
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img width={20} src={getImgSource('speedPointUnloaded')} alt='Speed Point Unloaded Icon' />
                        </td>
                        <td>
                            {translate('t.unloaded')} ({mapActionData.loadedCounts?.unloaded ?? 0})
                        </td>
                    </tr>
                </tbody>
            </table>
            {(mapActionData.loadedCounts?.total || 0) > 10000 && (
                <MapLegendInfoPopover
                    message={translate('p.displaying_results', { totalResults: mapActionData.loadedCountsExs?.total })}
                />
            )}
        </SpeedIncidentsLegendContainer>
    );
};

export default SpeedIncidentsLegendContent;
