import { Box, TableContainer, styled } from '@mui/material';
import UiButton from '../Components/UiButton/UiButton';

export const UiTablePrintWrapperViewContent = styled(Box)``;

export const ExportButton = styled(UiButton)({
    margin: '0 !important'
});

export const ContainerToPrint = styled(TableContainer)<{
    $maxHeight?: number;
    $minHeight?: number;
    $autoMaxHeight?: boolean;
}>`
    @media print {
        @page {
            size: A3 landscape;
        }
    }

    ${({ $minHeight, $maxHeight }) => {
        return `
                height: auto;
                min-height: ${$minHeight || ($maxHeight ? $maxHeight - 60 : 380)}px;
                max-height: calc(100vh - 270px);
                overflow: auto;
                display: grid;
            `;
    }}

    ${({ $autoMaxHeight }) =>
        $autoMaxHeight
            ? `
             max-height: auto;
        
            `
            : 'max-height: calc(100vh - 270px);'}

    ${({ $maxHeight }) =>
        $maxHeight &&
        `
        max-height: ${$maxHeight}px;
        & .main-table-body {
            display: grid;
            overflow-y: auto;
            overflow-x: hidden;
            // max-height: ${$maxHeight - 100}px;
        }
    `}

    &.loading {
        opacity: 0.5;
    }
`;
