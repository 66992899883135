import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { SystemNotificationMessageContent } from './SystemNotificationMessage.view';
import { useQuery } from '@tanstack/react-query';
import { MessageTable, SystemNotificationTableModel } from '../../models/SystemNotification.type';
import SystemNotification from '../../api/SystemNotification';
import { useRecoilValue } from 'recoil';
import { UserInfo } from '../../states/global/User';
import { DateTime, Interval } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const SystemNotificationMessage: React.FC = (): JSX.Element => {
    const systemNotificationAPI = useMemo(() => new SystemNotification(), []);
    const { user } = useRecoilValue(UserInfo);
    const [displayedNotification, setDisplayedNotification] = useState<MessageTable[] | null>(null);

    const customerId: number | undefined = user?.customer?.id;

    const { data, isRefetching } = useQuery<SystemNotificationTableModel>(
        ['SystemNotification', customerId],
        () =>
            systemNotificationAPI.getByCriteria({
                criteria: {
                    limit: 200,
                    customerId: customerId ?? NaN
                }
            }),
        {
            refetchInterval: 30000,
            enabled: Boolean(customerId)
        }
    );

    useEffect(() => {
        if (data && !isRefetching) {
            getMessageToDisplay();
        }
    }, [data, isRefetching]);

    const getMessageToDisplay = useCallback((): void => {
        const currentTime = DateTime.now();
        const notifications =
            data?.items.filter((item) => {
                const interval = Interval.fromDateTimes(DateTime.fromISO(item.dateFrom), DateTime.fromISO(item.dateTo));
                return item.active && interval.contains(currentTime) && checkClosedNotification(item);
            }) || [];
        setDisplayedNotification(notifications);
    }, [data]);

    const checkClosedNotification = useCallback(
        (item: MessageTable): boolean => {
            const localStorageString = localStorage.getItem('closedNotification');
            const closedNotifications = localStorageString ? JSON.parse(localStorageString) : [];

            const userClosedNotifications = closedNotifications.find(
                (notification: any) => notification.userId === user?.id
            );

            if (!userClosedNotifications) return true;

            return (
                Array.isArray(userClosedNotifications?.displayedNotification) &&
                !userClosedNotifications?.displayedNotification.some(
                    (notification: MessageTable) => notification.id === item.id
                )
            );
        },
        [user?.id]
    );

    const handleCloseNotification = useCallback(
        (notification: MessageTable): void => {
            const localStorageString = localStorage.getItem('closedNotification');
            const closedNotifications = localStorageString ? JSON.parse(localStorageString) : [];

            const userClosedNotificationsIndex = closedNotifications.findIndex(
                (notification: any) => notification.userId === user?.id
            );

            if (userClosedNotificationsIndex > -1) {
                const userClosedNotifications = closedNotifications[userClosedNotificationsIndex];
                userClosedNotifications.displayedNotification = [
                    ...userClosedNotifications.displayedNotification,
                    notification
                ];
                closedNotifications[userClosedNotificationsIndex] = userClosedNotifications;
            } else {
                closedNotifications.push({
                    userId: user?.id,
                    displayedNotification: [notification]
                });
            }

            localStorage.setItem('closedNotification', JSON.stringify(closedNotifications));
            setDisplayedNotification(
                (current) => current?.filter((currentNotification) => currentNotification.id !== notification.id) || []
            );
        },
        [user?.id]
    );

    const createMessage = useCallback(
        (notification: MessageTable): string => {
            const { userSetting } = user || {};
            if (userSetting?.language !== 'en_GB') {
                const translation = notification.translation.find((trans) => trans.locale === userSetting?.language);
                if (translation) return translation.content;
            }
            return notification.message;
        },
        [user]
    );

    return (
        <SystemNotificationMessageContent
            data-testid='SystemNotificationMessage-testid'
            createMessage={createMessage}
            handleCloseNotification={handleCloseNotification}
            displayedNotification={displayedNotification}
        />
    );
};

export default Wrapper(SystemNotificationMessage);
