import React from 'react';
import {
    UiMapComponentChildrenContent,
    UiMapComponentContent as UiMapComponentContentEl
} from './UiMapComponent.style';
import { UiMapComponentProps } from './UiMapComponent.type';

const UiMapComponentContent: React.FC<UiMapComponentProps> = (props): JSX.Element => (
    <UiMapComponentContentEl right={props.right} top={props.top} data-testid='UiMapComponentContent'>
        <UiMapComponentChildrenContent>{props.children}</UiMapComponentChildrenContent>
    </UiMapComponentContentEl>
);

export default UiMapComponentContent;
