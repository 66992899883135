import React, { useEffect, useReducer } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as view from './UiVehicleMultiselect.view';
import {
    VehicleAction,
    UiVehicleMultiselectProps,
    VehiclesData,
    VehicleActionsEnum
} from './UiVehicleMultiselect.type';
import VehicleApi from '../../../../api/Vehicle';
import { Wrapper } from 'helpers/wrapper';

const Vehicle = new VehicleApi();

const VehicleReducer = (state: VehiclesData, action: VehicleAction): VehiclesData => {
    switch (action.type) {
        case VehicleActionsEnum.SET_VEHICLES:
            return { ...state, vehicles: action.payload };
        case VehicleActionsEnum.SET_SELECTED_VEHICLES:
            return { ...state, selectedVehicles: action.payload };
        default:
            throw 'Unknown action in Vehicle reducer';
    }
};

const UiVehicleMultiselect: React.FC<UiVehicleMultiselectProps> = (props): JSX.Element => {
    const [vehicles, setVehicles] = useReducer(VehicleReducer, {
        vehicles: [],
        selectedVehicles: []
    });

    const { refetch: loadVehicleList } = useQuery(
        ['loadVehicleListQuery-vehicleMultiSelect'],
        () => Vehicle.getList(),
        {
            enabled: false,
            onSuccess: (dataOnSuccess) => setVehicles({ payload: dataOnSuccess, type: VehicleActionsEnum.SET_VEHICLES })
        }
    );

    useEffect(() => {
        props.onSelectedVehiclesChange && props.onSelectedVehiclesChange(vehicles.selectedVehicles);
    }, [vehicles.selectedVehicles]);

    useEffect(() => {
        loadVehicleList();
    }, []);

    return (
        <view.UiVehicleMultiselectContent
            vehicles={vehicles}
            customeLabel={props.customeLabel}
            setVehicles={setVehicles}
            maxAllowedVehicles={props.maxAllowedVehicles}
            data-testid={'UiVehicleMultiselect-testid'}
        />
    );
};

export default Wrapper(UiVehicleMultiselect);
