import React, { useEffect } from 'react';
import { ProfileProps } from './Profile.type';
import { ProfileContent } from './Profile.view';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Profile: React.FC<ProfileProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'user-profile-and-notifications-preferences' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <ProfileContent data-testid='Profile-testid' />;
};

export default Wrapper(Profile);
