import styled from 'styled-components';
import { Button, Grid } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';

export const DialogContainer = styled.div`
    && .MuiDialogActions-root {
        margin-top: 20px;
    }
`;

export const AddTyreViewContent = styled(MuiDialogContent)`
    &.MuiDialogContent-root {
        padding-top: 20px !important;
    }
`;

export const ActionButton = styled(Button)`
    display: none;
`;

export const DateGrid = styled(Grid)`
    & .MuiFormControl-root {
        width: 107% !important;
    }
`;
