import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { AlertFontSize } from '../../Ui/Theme';

export const UsersSuscribedNotificationsContainer = styled.div`
    ${applyStyleByMode({
        styleJade: 'display: inline-flex;'
    })}
    .MuiIconButton-root {
        font-size: ${AlertFontSize} !important;
        font-weith: bolth;
        text-decoration-line: underline;
    }
    svg {
        margin-left: 5px;
        margin-right: 2px;
    }
`;

export const IconButtonContent = styled.div`
    width: 45px;
`;
