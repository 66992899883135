import { atom } from 'recoil';
import { DateTime } from 'luxon';

type DateRangeData = {
    dateFrom: DateTime;
    dateTo: DateTime;
    appliedDateFrom: DateTime;
    appliedDateTo: DateTime;
    isValidRange: boolean;
};

export const TemperatureExposureDate = atom<DateRangeData>({
    key: 'TemperatureExposureDate',
    default: {
        dateFrom: DateTime.local().minus({ months: 3 }),
        dateTo: DateTime.local(),
        appliedDateFrom: DateTime.local().minus({ months: 3 }),
        appliedDateTo: DateTime.local(),
        isValidRange: true
    }
});
