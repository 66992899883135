import React from 'react';
import { ImageCell } from './LogoGlobalAdmin.style';
import { LogoGlobalAdminViewProps } from './LogoGlobalAdmin.type';
import { CircularProgress } from '@mui/material';

export const LogoGlobalAdminContent: React.FC<LogoGlobalAdminViewProps> = (props): JSX.Element => {
    return props.photoDataLoading ? (
        <CircularProgress data-testid={'LogoGlobalAdminContent-CircularProgress-testid'} size='1.75em' />
    ) : props.photo ? (
        <ImageCell
            data-testid='LogoGlobalAdminContent-ImageCell-testid'
            width={props.width}
            $maxWidth={props.maxWidth}
            $maxHeight={props.maxHeight}
            src={props.photo}
        />
    ) : (
        <></>
    );
};
