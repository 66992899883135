import styled from 'styled-components';
import { Table, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { BACKGROUND_TABLE, BACKGROUND_TABLE_DARK, WHITE } from 'components/Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const SystemTotalsContainer = styled.div``;

export const TableCustom = styled(Table)`
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize} !important;`;
    }}  
   
    & tbody td:first-child {
        font-weight: bold;
        text-align: center;
    }
    & tbody #default {
        text-align: left !important;
        padding-left: 10px !important;
    }
    & tr td{
        padding: 8px 10px !important;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleJade: `font-size: ${common.textSize} !important;`
            });
        }}
        vertical-align: middle !important;
        border-left: 1px solid rgba(224, 224, 224, 1); !important;
    }
`;

export const TableLegend = styled(Table)`
    font-size: 14px;
`;

export const SimpleCell = styled(TableCell)``;

export const GraphCell = styled(TableCell)<{ customColor?: string }>`
    width: 60px !important;
    ${(props) => props.customColor && `color: ${props.customColor}`} !important;
`;

export const CustomRow = styled(TableRow)`
    & .MuiTableCell-root {
        text-align: center;

        ${() => {
            const theme = useTheme();
            return applyStyleByMode({
                theme: theme.palette.mode,
                light: `
                    background-color: ${BACKGROUND_TABLE};
                    border: 1px solid ${WHITE}
                `,
                dark: `
                    background-color: ${BACKGROUND_TABLE_DARK};
                    border: 1px solid ${theme.palette.background.default}
                `
            });
        }}
    }
`;

export const CustomTableHead = styled(TableHead)`
    & .MuiTableCell-head {
        background-color: ${() => (useTheme().palette.mode === ThemeMode.light ? BACKGROUND_TABLE : 'transparent')};
    }
`;

export const HeaderTable = styled(TableCell)<{ $minWidth?: number }>`
    min-width: ${({ $minWidth }) => `${$minWidth}px` ?? '0px'};
    padding: 0 !important;
    padding-bottom: 5px !important;
    font-weight: bold !important;
    text-align: center !important;
    line-height: 25px;
    ${applyStyleByMode({
        styleJade: `
            text-align: center;
        `,
        styleOld: `
            background-color: ${BACKGROUND_TABLE} !important;
            font-weight: 600 !important;
            text-align: center !important;
        `
    })}
`;

export const ProgressBarWrapper = styled.div`
    height: 100px;
`;
