import React from 'react';
import { useRecoilState } from 'recoil';
import { VehicleFiltersAtom } from './VehicleFilters.atom';
import { FilterContainer, FilterOptionText, FilterSelectOption } from './VehicleFilters.style';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import UiIcon from '../../../../Ui/Components/UiIcon/UiIcon';

export const Filtering: React.FC = (): JSX.Element => {
    const [vehicleFiltersAtom, setVehicleFiltersAtom] = useRecoilState(VehicleFiltersAtom);
    const { t: translate } = useTranslation();

    return (
        <FilterContainer>
            <FormControl data-testid='SelectToFilterByVehicleGroup' variant='outlined' size='small'>
                <InputLabel htmlFor='filter-by'>{`${translate('t.alert_level')}  `}</InputLabel>
                <Select
                    variant='outlined'
                    size='small'
                    value={vehicleFiltersAtom.filterBy}
                    style={{ width: '140px' }}
                    inputProps={{
                        name: 'level',
                        id: 'filter-by'
                    }}
                    onChange={(event) => {
                        setVehicleFiltersAtom({ ...vehicleFiltersAtom, filterBy: event.target.value as number });
                    }}
                    data-testid='VehicleStatus-SelectAlertLevelFilter-Select'
                    input={<OutlinedInput notched label={translate('t.alert_level')} />}
                >
                    <FilterSelectOption value={0} data-testid='VehicleStatus-Filter-LevelAll-Button'>
                        <FilterOptionText all>{translate('t.all')}</FilterOptionText>
                    </FilterSelectOption>
                    <FilterSelectOption value={1} data-testid='VehicleStatus-Filter-Level1-Button'>
                        <UiIcon icon={['fas', 'exclamation-triangle']} size='lg' fixedWidth color='#c1c121' />
                        <FilterOptionText> 1 </FilterOptionText>
                    </FilterSelectOption>
                    <FilterSelectOption value={2} data-testid='VehicleStatus-Filter-Level2-Button'>
                        <UiIcon icon={['fas', 'exclamation-triangle']} size='lg' fixedWidth color='#ea9800' />
                        <FilterOptionText> 2 </FilterOptionText>
                    </FilterSelectOption>
                    <FilterSelectOption value={3} data-testid='VehicleStatus-Filter-Level3-Button'>
                        <UiIcon icon={['fas', 'exclamation-triangle']} size='lg' fixedWidth color='red' />
                        <FilterOptionText> 3 </FilterOptionText>
                    </FilterSelectOption>
                </Select>
            </FormControl>
        </FilterContainer>
    );
};
