import React from 'react';
import { LegendHeaderIcon } from './BugReporter.style';
import { BugReporterViewProps } from './BugReporter.type';
import { useTranslation } from 'react-i18next';
import UiLegend from '../../Ui/Components/UiLegend';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons';
import { BugReporterFormik } from './BugReporter.formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonIcon } from '../../TopBar/TopBar.style';
import { ListItemIcon, ListItemText, Tooltip } from '@mui/material';

import { HeadsetMic } from '@mui/icons-material';
import { MenuItem } from '../../MainMenu/MainMenu.style';
import { MenuItem as MenuItemPro } from 'react-pro-sidebar';

export const BugReporterContent: React.FC<BugReporterViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const getButtonNavigation = (): JSX.Element => {
        return props.mobileMode ? (
            <MenuItem style={{ cursor: 'pointer' }}>
                <ListItemIcon>
                    <HeadsetMic />
                </ListItemIcon>
                <ListItemText primary={translate('t.request_support')} />
            </MenuItem>
        ) : (
            <Tooltip
                title={props.collapsedSidebar ? translate('t.request_support') : ''}
                placement='bottom'
                arrow
                disableInteractive
            >
                <div data-testid={`Bugreporter-tooltip-div`}>
                    <MenuItemPro data-testid='OpenModalBugRepor-Button' icon={<HeadsetMic />}>
                        {translate('t.request_support')}
                    </MenuItemPro>
                </div>
            </Tooltip>
        );
    };

    const getButtonTopBar = (): JSX.Element => {
        return (
            <ButtonIcon data-testid='BugRepor-Button-Jade'>
                <FontAwesomeIcon icon={faLifeRing} />
            </ButtonIcon>
        );
    };

    return (
        <UiLegend
            title={translate('t.request_support')}
            icon={<LegendHeaderIcon icon={faLifeRing} />}
            root={props.isNavigationButton ? getButtonNavigation() : getButtonTopBar()}
            width={800}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            content={<BugReporterFormik {...props} />}
            close={props.close}
        />
    );
};
