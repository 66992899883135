import { atom } from 'recoil';
import { Modal2Props } from '../../components/StatedModal/Modal/Modal.type';
import React from 'react';

export const ShowModal = atom<boolean>({
    key: 'ShowModal',
    default: false
});

export const ShowVehicleModal = atom<boolean>({
    key: 'ShowVehicleModal',
    default: false
});

export const ShowPermissionModal = atom<boolean>({
    key: 'ShowPermissionModal',
    default: false
});

export const ShowFaultySensorReporterModal = atom<boolean>({
    key: 'ShowFaultySensorReporterModal',
    default: false
});

export const ShowVehicleTypesAddModal = atom<boolean>({
    key: 'ShowVehicleTypesAddModal',
    default: false
});

export const ShowSaveRouteModal = atom<boolean>({
    key: 'ShowSaveRouteModal',
    default: false
});

export const ShowAddAreaModal = atom<boolean>({
    key: 'ShowAddAreaModal',
    default: false
});

export const ShowAnnouncementsActionsModal = atom<boolean>({
    key: 'ShowAddSystemNotification',
    default: false
});

export const ShowConfirmationPopupModal = atom<boolean>({
    key: 'ShowConfirmationPopupModal',
    default: false
});

export const ShowConfirmFastLeakAlertsModal = atom<boolean>({
    key: 'ShowConfirmFastLeakAlertsModal',
    default: false
});

export const ShowResolveAlertsByVehicleModal = atom<boolean>({
    key: 'ShowResolveAlertsByVehicleModal',
    default: false
});

const modalAtomDefaultProps = {
    leftTitle: '',
    rightTitle: '',
    content: <>The content</>,
    width: 400,
    height: 400,
    onOpen: () => {},
    onClose: () => {},
    id: 'ID21',
    widthUnit: 'px',
    heightUnit: 'px',
    isOpen: false
};

export const ModalAtom = atom<Modal2Props>({
    key: 'Modal',
    default: modalAtomDefaultProps
});

export enum ModalActionTypesEnum {
    TOGGLE_MODAL,
    CLOSE_MODAL,
    OPEN_MODAL,
    UPDATE_MODAL_STATE,
    SET_MODAL_STATE
}

type EmptyPayload = {
    type: ModalActionTypesEnum.TOGGLE_MODAL | ModalActionTypesEnum.CLOSE_MODAL | ModalActionTypesEnum.OPEN_MODAL;
};

type ContentPayload = {
    type: ModalActionTypesEnum.UPDATE_MODAL_STATE | ModalActionTypesEnum.SET_MODAL_STATE;
    ModalPropsPayload: Modal2Props;
};

export type ModalAction = EmptyPayload | ContentPayload;

export const ModalDispatcher = (previousState: Modal2Props, setState) => (action: ModalAction) => {
    switch (action.type) {
        case ModalActionTypesEnum.TOGGLE_MODAL:
            return setState({
                ...previousState,
                isOpen: !previousState.isOpen
            });
        case ModalActionTypesEnum.CLOSE_MODAL:
            return setState({
                ...previousState,
                isOpen: false
            });
        case ModalActionTypesEnum.OPEN_MODAL:
            return setState({
                ...previousState,
                isOpen: true
            });
        case ModalActionTypesEnum.UPDATE_MODAL_STATE:
            if (action.ModalPropsPayload) {
                if (action.ModalPropsPayload.content) {
                    action.ModalPropsPayload.customContent = undefined;
                }
                if (action.ModalPropsPayload.customContent) {
                    action.ModalPropsPayload.content = undefined;
                }
            }
            return setState({
                ...previousState,
                ...action.ModalPropsPayload
            });
        case ModalActionTypesEnum.SET_MODAL_STATE:
            if (action.ModalPropsPayload) {
                if (action.ModalPropsPayload.content) {
                    action.ModalPropsPayload.customContent = undefined;
                }
                if (action.ModalPropsPayload.customContent) {
                    action.ModalPropsPayload.content = undefined;
                }
            }
            return setState({
                ...modalAtomDefaultProps,
                ...action.ModalPropsPayload
            });
        default:
            return setState(previousState);
    }
};
