import { atom } from 'recoil';
import { WheelsType } from '../SeverityRank/atom';

export const VehicleWheels = atom<WheelsType>({
    key: 'VehicleWheels',
    default: {
        isLoading: false,
        wheels: []
    }
});
