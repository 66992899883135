import { TextField } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const ContainerFormChangePassword = styled.div`
    min-height: 137px;
    max-width: 25%;
    margin: 0 auto;
    padding-top: 35px;

    @media (max-width: 1440px) {
        max-width: 55%;
    }

    @media (max-width: 769px) {
        max-width: 95%;
    }
`;

export const ChangePasswordFormContent = styled.div``;

export const CustomTextField = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTextField-root {
                margin: 8px;
                margin-left: 0px;
                && label {
                    padding: 0 5px 0 0;
                    background: #fff;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.9);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: #3a3633;
                }
                & svg {
                    fill: #999999;
                }
            }
        `
    })}
`;

export const ContainerButton = styled.div`
    float: right;
    padding-right: 10px;
`;
