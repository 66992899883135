import React from 'react';
import { HubsAdministrationViewContent } from './HubsAdministration.style';
import { HubsAdministrationViewProps } from './HubsAdministration.type';
import AdministrationTable from '../../components/Hub/AdministrationTable/AdministrationTable';

export const HubsAdministrationContent: React.FC<HubsAdministrationViewProps> = (): JSX.Element => {
    return (
        <HubsAdministrationViewContent data-testid='HubsAdministrationContent'>
            <AdministrationTable />
        </HubsAdministrationViewContent>
    );
};
