import React from 'react';
import { atom } from 'recoil';

type ConfirmationPopupProps = {
    icon?: JSX.Element;
    title: string;
    body: JSX.Element;
    buttons: JSX.Element;
};

export const ConfirmationPopupState = atom<ConfirmationPopupProps>({
    key: 'ConfirmationPopupState',
    default: {
        title: '',
        body: <></>,
        buttons: <></>
    }
});
