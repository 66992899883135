import React, { useEffect } from 'react';
import { Wrapper } from 'helpers/wrapper';
import { WheelTPMSContainer } from './WheelTPMS.style';
import UiWidget from '../../components/Ui/Components/UiWidget';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import DataExport from '../../components/Mixs/DataExport';
import SensorLog from '../../api/SensorLog';
import { TopBarAtom } from '../../components/Mixs/DataExport/TopBar/TopBar.atom';
import { useMutation } from '@tanstack/react-query';
import { Success } from '../../components/Popup';
import WheelPositionSelect from '../../components/Mixs/DataExport/WheelPositionSelect';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import WheelGranularitySelect from 'components/Mixs/DataExport/WheelGranularitySelect';

const sensorLogAPI = new SensorLog();

const WheelTPMS: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit, displayUserUnits } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    const mutation = useMutation<unknown, unknown, void>(
        () =>
            sensorLogAPI.getWheelTPMSCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO(),
                topBarAtom.wheelPosition,
                topBarAtom.granularity
            ),
        {
            onSuccess: () => {
                Success({ text: translate('p.report_success_message') });
            }
        }
    );

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicleName',
            parseData: (props) => props
        },
        {
            header: `${translate('t.date')}`,
            accessor: 'measuredAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: translate('t.custom_pos'),
            accessor: 'customPosition'
        },
        {
            header: translate('t.sensor_serial_number'),
            accessor: 'serialNumberHex'
        },
        {
            header: `${translate('t.pressure')} (${displayUserUnits.pressure})`,
            accessor: 'hotPressure',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.pressure,
                    value: props,
                    fixed: 1,
                    displayUnits: false,
                    displayIfEmpty: ''
                })
        },
        {
            header: `${translate('t.cold_pressure')} (${displayUserUnits.pressure})`,
            accessor: 'coldPressure',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.pressure,
                    value: props,
                    fixed: 1,
                    displayUnits: false,
                    displayIfEmpty: ''
                })
        },
        {
            header: `${translate('t.optimal')} ${translate('t.pressure')} (${displayUserUnits.pressure})`,
            accessor: 'optimalPressure',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.pressure,
                    value: +props,
                    fixed: 1,
                    displayUnits: false,
                    displayIfEmpty: ''
                })
        },
        {
            header: `${translate('t.temperature')} (${displayUserUnits.temperature})`,
            accessor: 'temperature',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.temperature,
                    value: props,
                    fixed: 1,
                    displayUnits: false,
                    displayIfEmpty: ''
                })
        }
    ];

    useEffect(() => {
        setPageHelpButton({ article: 'data-exports-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <WheelTPMSContainer data-testid='WheelTPSM-testid'>
            <UiWidget
                title={translate('t.wheels_tpms')}
                id='wheel-tpms-widget'
                avatar={
                    <UiIcon
                        icon={faTruck}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={sensorLogAPI.getTempPressWheelReportPreview}
                        downloadFn={mutation.mutate}
                        queryKey='WheelTPMSReports'
                        additionalElement={
                            <>
                                <WheelPositionSelect /> <WheelGranularitySelect />
                            </>
                        }
                        showIntervalWarning
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            wheelPosition: topBarAtom.wheelPosition,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO(),
                            granularity: topBarAtom.granularity
                        }}
                    />
                }
            />
        </WheelTPMSContainer>
    );
};

export default Wrapper(WheelTPMS);
