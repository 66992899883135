import { atom } from 'recoil';

export type RedirectParamsType = {
    redirectTo?: string;
    customerId?: number;
    params?: {
        fastLeak?: string;
        leakDateFrom: string;
        leakDateTo: string;
        tab: string;
    };
};

export const RedirectParams = atom<RedirectParamsType>({
    key: 'RedirectParams',
    default: {}
});
