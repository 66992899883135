import { Paper, useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';
import {
    BACKGROUND_BLUE,
    BACKGROUND_GREY,
    BACKGROUND_PAPER_DARK_MODE,
    DARK_STEEL_GREY,
    PRIMARY,
    PRIMARY_SEMI_LIGHT,
    SECONDARY,
    WHITE
} from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import useCommon from 'helpers/themeCommon/useCommon';

export const UiPopperContainer = styled.div``;

export const ContainerPopper = styled(Paper)`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
        rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    background: ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_PAPER_DARK_MODE : WHITE)};
`;

export const HeaderUiPopper = styled.div`
    color: ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : DARK_STEEL_GREY)};

    padding: 0px 0px 4px 0px !important;
    border-bottom: solid 1px ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_GREY : SECONDARY)};
    line-height: 2;
    min-height: 25px;
    width: 100% !important;
    display: inline-flex !important;
    font-weight: 600;
    align-items: center;
    margin-bottom: 15px;

    p {
        font-size: ${() => useCommon().titleSize};
        width: 100%;
        display: flex;
        align-items: center;
    }

    button {
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `color: ${PRIMARY_SEMI_LIGHT};`,
                dark: `color: ${SECONDARY};`
            })}
        font-size: 0.875rem;
        margin-left: auto;
    }
    button:hover {
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `
                background: rgba(0, 0, 0, 0.1);
                color: ${PRIMARY};
            `,
                dark: `
                background: rgba(0, 0, 0, 0.3);
                color: ${WHITE};
            `
            })}
        transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
    }
`;
