import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { ErrorProps, ErrorsProps, GeneralPropsView, SuccessProps, WarningProps, ErrorContentType } from './Popup.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
    ContainerTitleNotify,
    ErrorButton,
    ErrorCollapse,
    ErrorExclamation,
    PopupError,
    PopupSuccess,
    PopupWarning,
    TitleNotify
} from './Popup.style';

export const ErrContent: React.FC<ErrorContentType & ErrorProps> = (
    props: React.PropsWithChildren<ErrorContentType & ErrorProps>
): JSX.Element => (
    <PopupError>
        <ErrorExclamation>
            <FontAwesomeIcon icon={['fas', 'circle-xmark']} size='lg' />
        </ErrorExclamation>
        <p>
            Sorry something went wrong. If problem persist please contact the support team or report a bug in the upper
            right corner.
            <ErrorButton
                expanded={props.expanded.toString()}
                onClick={props.updateExpand}
                aria-expanded={props.expanded}
            >
                <ExpandMoreIcon />
            </ErrorButton>
        </p>
        <ErrorCollapse in={props.expanded} timeout='auto' unmountOnExit>
            <table>
                <tbody>
                    <tr>
                        <td>Error: </td>
                        <td>
                            {props.status} {props.message}
                        </td>
                    </tr>
                    <tr>
                        <td>URL: </td>
                        <td>{props.url}</td>
                    </tr>
                </tbody>
            </table>
        </ErrorCollapse>
    </PopupError>
);

export const SuccessContent: React.FC<SuccessProps> = (props: React.PropsWithChildren<SuccessProps>): JSX.Element => (
    <PopupSuccess>
        <ErrorExclamation>
            <FontAwesomeIcon icon={['fas', 'bell-on']} size='lg' />
        </ErrorExclamation>
        <ContainerTitleNotify>
            <p>{props.text}</p>
        </ContainerTitleNotify>
    </PopupSuccess>
);

export const ErrorContent: React.FC<ErrorsProps> = (props: React.PropsWithChildren<ErrorsProps>): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <PopupError>
            <ErrorExclamation>
                <FontAwesomeIcon icon={['fas', 'circle-xmark']} size='lg' />
            </ErrorExclamation>
            <p>
                {props.multipleMessage && typeof props.text !== 'string' ? (
                    <>
                        <ContainerTitleNotify>
                            <TitleNotify>
                                <b>{translate('t.the_following_errors_were_found')}</b>:
                            </TitleNotify>
                        </ContainerTitleNotify>
                        {props.text.map((title) => (
                            <>
                                {title.property}

                                <ul>
                                    {title.messages.map((message, i) => (
                                        <li key={i}>{message}</li>
                                    ))}
                                </ul>
                            </>
                        ))}
                    </>
                ) : (
                    <ContainerTitleNotify>
                        <TitleNotify>
                            {' '}
                            <>{props.text}</>{' '}
                        </TitleNotify>
                    </ContainerTitleNotify>
                )}
            </p>
        </PopupError>
    );
};

export const WarningContent: React.FC<WarningProps> = (props: React.PropsWithChildren<WarningProps>): JSX.Element => (
    <PopupWarning>
        <ErrorExclamation>
            <FontAwesomeIcon icon={['fas', 'circle-exclamation']} size='lg' />
        </ErrorExclamation>
        <ContainerTitleNotify>
            <p>{props.text}</p>
        </ContainerTitleNotify>
    </PopupWarning>
);

export const GeneralContent: React.FC<GeneralPropsView> = ({ content }): JSX.Element => <>{content}</>;
