import React from 'react';
import { useRecoilValue } from 'recoil';
import { WidgetStatisticsSpeedProps } from './WidgetStatisticsSpeed.type';
import { useTranslation } from 'react-i18next';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import TimelineIcon from '@mui/icons-material/Timeline';
import { applyStyleByMode } from 'helpers';
import { BACKGROUND_DARK_MODE } from 'components/Ui/colors';
import StatisticsSpeed from '../StatisticsSpeed/StatisticsSpeed';
import { Theme, ThemeMode } from 'states/global/Theme';
import { UserInfo } from 'states/global/User';

const WidgetStatisticsSpeed: React.FC<WidgetStatisticsSpeedProps> = ({
    vehicleId,
    show,
    dateTimePicker,
    timeline,
    headerAction,
    onHoverGraph,
    setDateTimePicker,
    zoomInExternal,
    enableFastLeak
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeModeValue = useRecoilValue(Theme);
    const lineColor: string = ThemeModeValue?.mode === ThemeMode.light ? '#000' : '#16a085';
    const userInfo = useRecoilValue(UserInfo);

    if (!show) return <></>;

    return (
        <Widget
            avatar={<TimelineIcon />}
            title={<>{translate('t.speed')}</>}
            applyPadding={false}
            headerColor={applyStyleByMode({
                theme: ThemeModeValue?.mode,
                light: '#e9e9e9;',
                dark: BACKGROUND_DARK_MODE
            })}
            id={`speed-chart-${userInfo?.user?.customer?.name || 12}-${vehicleId}`}
            sx={{ marginTop: 1 }}
            headerAction={headerAction}
        >
            <StatisticsSpeed
                onHoverGraph={onHoverGraph}
                timeline={timeline}
                dateTimePicker={dateTimePicker}
                setDateTimePicker={setDateTimePicker}
                lineColor={lineColor}
                selectedVehicle={vehicleId}
                zoomInExternal={zoomInExternal}
                enableFastLeak={enableFastLeak}
            />
        </Widget>
    );
};

export default WidgetStatisticsSpeed;
