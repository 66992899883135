import React, { useEffect } from 'react';
import { BesSevereDrivingContent } from './BesSevereDriving.view';
import { BesSevereDrivingProps } from './BesSevereDriving.type';
import { IsBesMap, MapAction } from '../../states/global/Map';
import { useSetRecoilState } from 'recoil';
import { MapActionsEnum } from '../../components/Map/Map.type';
import { Wrapper } from 'helpers/wrapper';

const BesSevereDriving: React.FC<BesSevereDrivingProps> = (): JSX.Element => {
    const setMapAction = useSetRecoilState(MapAction);
    const setIsBesMap = useSetRecoilState(IsBesMap);

    useEffect(() => {
        setIsBesMap(true);
        setTimeout(() => {
            setMapAction({
                action: MapActionsEnum.GET_BES_SEVERE_DRIVING_DATA
            });
        }, 500);
        return () => {
            setIsBesMap(false);
        };
    }, []);

    return <BesSevereDrivingContent data-testid={'BesSevereDriving-testid'} />;
};

export default Wrapper(BesSevereDriving);
