import PortalApi from 'helpers/api/PortalApiClient';
import { LinkHashModel } from '../models/LinkHash.type';

export default class LinkHash extends PortalApi<LinkHashModel> {
    route = 'link-hash';

    getByHash = async (hash: string): Promise<LinkHashModel> => {
        return await this.get<LinkHashModel>({ extendUrl: hash });
    };
}
