import { Typography } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import LoginPage from '../../assets/images/LoginPageITrack-05.jpeg';

export const ContainerVideoLogin = styled.div`
    @media (max-width: 1280px) {
        display: none;
    }
`;

export const LoginContentStyle = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    @media (max-width: 1280px) {
        position: absolute;
        display: block;
        background-image: url('${LoginPage}');
        background-size: cover;
        background-repeat: no-repeat, repeat;
        background-position: center;
    }

    .MuiCardContent-root {
        padding: 0 !important;
    }

    .card-login {
        width: 500px;
        position: absolute;
        left: 290px;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2% 2% 1% 2%;
    }

    @media (min-width: 320px) and (max-width: 768px) {
        .card-login {
            left: 50%;
            width: 90%;
            max-width: 450px;
        }
    }

    @media (min-width: 769px) and (max-width: 900px) {
        .card-login {
            left: 50%;
        }
    }

    .logo-login {
        width: 70%;
        display: block;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .image-login {
        width: 100%;
        height: 100%;
    }

    .MuiPaper-elevation1 {
        box-shadow: 0 0 20px 10px rgba(0, 0, 0, 15%) !important;
    }

    & .MuiTextField-root {
        width: 100%;
        margin: 3px 0;
    }

    & .MuiButton-root {
        width: 100%;
        margin: 20px 0;
    }

    ${() => {
        return applyStyleByMode({
            styleJade: `
                && label {
                    padding: 0 5px 0 0;
                    background: transparent;
                } 
                & .MuiInputLabel-shrink {
                    transform: translate(10px,-10px) scale(0.85);
                }
            `
        });
    }}
`;

export const LoginTitle = styled(Typography)`
    margin-top: 15px;
`;

export const BackgroundVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const BlurLayer = styled.div`
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;
