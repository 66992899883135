import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';

export const StatisticsButtonContainer = styled.div``;

export const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;

    ${applyStyleByMode({
        styleOld: `
            background-color: #fbfbfb;
            border: 1px solid #ddd;
        `
    })}
`;

export const ContainerButtonBES = styled.div`
    ${applyStyleByMode({
        styleJade: 'margin-top: 5px;'
    })}
`;

export const StatisticsBtn = styled(UiButton)`
    & .muibutton-root: {
        margin: 5px;
    }
`;

export const BESBtn = styled(StatisticsBtn)`
    & .muibutton-root: {
        text-align: center;
    }
`;
