import {
    IinicialStateTempPressTable,
    TableViewTypeActionsEnum,
    TempPressActionType,
    TempePressTableActionsEnum
} from './TemperaturePressureTable.type';

export const InicialStateTempPressTable = {
    filterVehicleName: '',
    filterAlertLevel: 0,
    tableViewType: localStorage.getItem('temp-and-pressure-view') || TableViewTypeActionsEnum.COLD,
    refresh: false
} as IinicialStateTempPressTable;

export const TempPressTableReducer = (state: IinicialStateTempPressTable, action: TempPressActionType) => {
    switch (action.type) {
        case TempePressTableActionsEnum.SET_FILTER_BY_VEHICLE_NAME:
            return {
                ...state,
                filterVehicleName: action.payload
            };
        case TempePressTableActionsEnum.SET_FILTER_ALERT_LEVEL:
            return {
                ...state,
                filterAlertLevel: action.payload
            };
        case TempePressTableActionsEnum.SET_TABLE_VIEW_TYPE: {
            localStorage.setItem('temp-and-pressure-view', action.payload);
            return {
                ...state,
                tableViewType: action.payload
            };
        }
        case TempePressTableActionsEnum.SET_REFRESH:
            return {
                ...state,
                refresh: action.payload
            };
        case TempePressTableActionsEnum.SET_FILTER_VEHICLE_GROUP:
            return {
                ...state,
                filterVehicleGroup: action.payload
            };
        default:
            return state;
    }
};
