import React from 'react';
import { DropdownUpdateContent } from './DropdownUpdate.view';
import { DropdownTypesEnum, DropdownUpdateProps, InitialValues } from './DropdownUpdate.type';
import Sensors from '../../../api/Sensors';
import { Success } from '../../Popup/Popup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';
import SensorType from '../../../api/SensorType';
import SensorStatus from '../../../api/SensorStatus';
import { Wrapper } from 'helpers/wrapper';

const sensorsApi = new Sensors();
const sensorTypeApi = new SensorType();
const sensorStatusApi = new SensorStatus();

const DropdownUpdate: React.FC<DropdownUpdateProps> = ({
    sensorId,
    showComment,
    valueId,
    type,
    testid
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('firstSensorPage-RefreshTableAtom'));

    const initialValues: InitialValues = {
        id: valueId ?? null
    };

    const { data: sensorStatusListData, isLoading: sensorStatusListLoading } = useQuery<optionsCodeBook[]>(
        ['sensorStatusList'],
        sensorStatusApi.getSensorsStatusCodebook,
        {
            refetchOnWindowFocus: false
        }
    );

    const { data: sensorTypeListData, isLoading: sensorTypeListLoading } = useQuery<optionsCodeBook[]>(
        ['SensorTypeList'],
        sensorTypeApi.getSensorsTypeCodebook,
        {
            refetchOnWindowFocus: false
        }
    );

    const { mutate: updateSensorTypeMutate, isLoading: updateSensorTypeLoading } = useMutation(
        sensorsApi.patchSensorType,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.updated_sensor_type')
                });
                setRefreshTableAtom(true);
            }
        }
    );

    const { mutate: updateSensorStatusMutate, isLoading: updateSensorStatusLoading } = useMutation(
        sensorsApi.patchSensorStatus,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.updated_sensor_status')
                });
                setRefreshTableAtom(true);
            }
        }
    );

    const handleSubmitType = (values: InitialValues): void => {
        updateSensorTypeMutate({ id: sensorId, data: { sensorTypeId: values.id } });
    };

    const handleSubmitStatus = (values: InitialValues): void => {
        const name: string = sensorStatusListData
            ? (sensorStatusListData.find((value) => value.id == values.id)?.name as string)
            : ' ';

        updateSensorStatusMutate({ id: sensorId, name: name.toLowerCase() });
    };

    return (
        <DropdownUpdateContent
            data-testid={testid || 'DropdownUpdate-testid'}
            type={type}
            list={
                type === DropdownTypesEnum.SENSOR_TYPE
                    ? sensorTypeListData
                    : sensorStatusListData
                    ? sensorStatusListData.filter((value) => value.name == 'Active' || value.name == 'Inactive')
                    : []
            }
            showComment={showComment}
            initialValues={initialValues}
            loading={type === DropdownTypesEnum.SENSOR_TYPE ? updateSensorTypeLoading : updateSensorStatusLoading}
            listLoading={type === DropdownTypesEnum.SENSOR_TYPE ? sensorTypeListLoading : sensorStatusListLoading}
            handleSubmit={type === DropdownTypesEnum.SENSOR_TYPE ? handleSubmitType : handleSubmitStatus}
            valueId={valueId}
            testid={testid}
        />
    );
};

export default Wrapper(DropdownUpdate);
