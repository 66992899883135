import React from 'react';
import { ActionItem, Icon } from '../../../components/Vehicle/VehicleDetailsActions/VehicleDetailsActions.style';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-solid-svg-icons';
import { MenuItem, Typography } from '@mui/material';
import { TyreDetail } from '../../../models/Wheel.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HasInternalSensorAtom, VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { useTranslation } from 'react-i18next';
import { FaultySensorReporterState } from '../../../states/component/FaultySensorReporter';
import { SelectedSensorAtom } from '../../../components/Tyre/TyreDetails/SelectedSensor.atom';
import { VehicleModel } from 'models/Vehicle.type';

/*
    Temporal component.
    Will be removed when Faulty Reported will be refactored.
 */
const SensorReadings = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const data: TyreDetail | undefined = vehicleDetailsWidgetAtom.tyreDetails?.filter(
        (tyre) => tyre.wheel.position === vehicleDetailsActionMenuAtom.data?.wheel.customPosition
    )[0] as TyreDetail;
    const hasInternalSensor = useRecoilValue(HasInternalSensorAtom());
    const selectedSensor = useRecoilValue(SelectedSensorAtom(vehicleDetailsActionMenuAtom.data?.wheel.position || 0));
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const [actionMenuState, setActionMenuState] = useRecoilState(VehicleDetailsActionMenuAtom);

    return (
        <MenuItem
            onClick={() => {
                setActionMenuState({
                    ...actionMenuState,
                    isOpen: false
                });
                setFaultySensorReporterState({
                    vehicleId: (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id || 0,
                    vehicleName: (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name || '',
                    sensorId: selectedSensor?.sensor.id || 0,
                    sensorName: selectedSensor?.sensor.serialNumberHex || '',
                    showOnlyGraph: true,
                    wheelPosition: data?.wheel.position,
                    internalOnVehicle: hasInternalSensor,
                    isExternal: selectedSensor?.sensor?.sensorType?.name === 'External',
                    reportDateTo: selectedSensor?.sensorLog?.measuredAt,
                    allowReportLeakAlert: true
                });
            }}
            data-testid={`Pos-${data?.wheel.position}-SensorReadings-Button`}
        >
            <ActionItem>
                <Icon icon={faMagnifyingGlassPlus} />
                <Typography>{translate('t.sensor_readings')}</Typography>
            </ActionItem>
        </MenuItem>
    );
};

export default SensorReadings;
