import { atom } from 'recoil';

type ConfigurationPostion = {
    position: number[];
    color?: string;
};

export const AlertConfigurationPosition = atom<ConfigurationPostion>({
    key: 'AlertConfigurationPosition',
    default: {
        position: [],
        color: 'red'
    }
});
