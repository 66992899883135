import { UserModel } from 'models/User.type';
import { CustomerCodebook } from '../../../models/Customer.type';
import { RegionModelExtended } from '../../../models/Region.type';
import { RoleCodeBook } from '../../../models/Role.type';

export enum UserStatusEnum {
    ALL = 'all',
    ENABLE = 'active',
    DISABLE = 'inactive'
}

export type PassProps = {
    customers?: CustomerCodebook[];
};

export type UserTableProps = {} & PassProps;

export type UserTableViewProps = {
    region?: RegionModelExtended[];
    role?: RoleCodeBook[];
    editPermissions: (data: UserModel) => void;
    showPermissionModal: boolean;
    setShowPermissionModal: (value: boolean) => void;
} & PassProps;

export type UserStatus = {
    label: string;
    value: UserStatusEnum;
};
