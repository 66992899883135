import styled from 'styled-components';
import { ERROR } from '../../Ui/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const DragAndDropContainer = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            styleJade: `
                font-size: ${common.textSize};
                color: ${useTheme().palette.text.primary};
            `
        });
    }}
`;

export const Content = styled.div`
    text-align: center;
    border: dashed rgb(200, 200, 200);
    padding: 30px 10px;
`;

export const RemoveFileButton = styled.span`
    color: #a90329;
    float: right;
    cursor: pointer;
    margin-left: 1px;
    margin-top: -4px;
    vertical-align: middle;
    font-size: 1.3em;
    font-weight: bold;
`;

export const UploadedFilesView = styled.div`
    display: inline-block;
    margin-left: 5px;
`;

export const FileName = styled.span`
    padding: 0 5px;
`;

export const ErrorMessage = styled.p`
    margin: 5px 0;
    height: 19px;
    color: ${ERROR};
`;

export const DropFileImage = styled(FontAwesomeIcon)`
    font-size: 2em;
`;

export const AttachmentsSize = styled.p`
    margin: 5px 0;
`;

export const AttachedFiles = styled.div`
    margin: 5px 0;
`;
