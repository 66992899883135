import React, { useEffect, useState } from 'react';
import { NotTransmittingPopoverProps, dataNotTransmitting } from './NotTransmittingPopover.type';
import { NotTransmittingPopoverContent } from './NotTransmittingPopover.view';
import { SensorsWithPaginator } from '../../models/Sensors.type';
import { VehicleNotTransmittingResponse } from '../../models/Vehicle.type';
import Sensors from '../../api/Sensors';
import Vehicle from '../../api/Vehicle';
import { Wrapper } from 'helpers/wrapper';
// import module

const NotTransmittingPopover: React.FC<NotTransmittingPopoverProps> = (props): JSX.Element => {
    const sensorsApi = new Sensors();
    const vehicleApi = new Vehicle();
    const [value, setValue] = useState<number>(0);
    const [data, setData] = useState<dataNotTransmitting>({
        sensors: [],
        vehicles: []
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadDataXHR = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const dataSensor: SensorsWithPaginator = await sensorsApi.getNotTransmitting(1, 100);
            const datavehicle: VehicleNotTransmittingResponse = await vehicleApi.getNotTransmitting(1, 100);

            setData({ sensors: dataSensor.items, vehicles: datavehicle.items });
            setIsLoading(false);
        } catch (e) {
            false;
        }
    };

    const handleChange = (newValue: number): void => {
        setValue(newValue);
    };

    useEffect(() => {
        loadDataXHR();
    }, []);

    return (
        <NotTransmittingPopoverContent
            data-testid='NotTransmittingPopover-testid'
            data={data}
            isLoading={isLoading}
            {...props}
            value={value}
            handleChange={handleChange}
        />
    );
};

export default Wrapper(NotTransmittingPopover);
