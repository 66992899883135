import React, { useCallback } from 'react';
import * as style from './Map.style';
import { CustomLayers, MapActionsEnum, MapViewProps } from './Map.type';
import VehicleMapPopup from '../VehicleMapPopup';
import { LoadingMapdata, MapAction } from '../../states/global/Map';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SpeedIncidentPopUp from '../Maps/SpeedIncidentPopUp/SpeedIncidentPopUp';
import MapPopUp from '../Maps/MapPopUp/MapPopUp';
import { AlertMsg } from './Map.style';
import CloseIcon from '@mui/icons-material/Close';
import { Fade } from '@mui/material';
import SlopeInfoPopup from 'components/Maps/SlopeInfoPopup';

/* Console Warning = "Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true" */

const MapContent: React.FC<MapViewProps> = ({
    mapElement,
    showPopup,
    layoutType,
    warningMsg,
    disableVehiclePopup
}): JSX.Element => {
    const loadingMapData = useRecoilValue(LoadingMapdata);
    const setMapAction = useSetRecoilState(MapAction);

    const getContent = useCallback(() => {
        let content = <></>;

        switch (layoutType) {
            case CustomLayers.VEHICLE:
                content = <VehicleMapPopup />;
                break;
            case CustomLayers.SPEED_INCIDENTS:
                content = <SpeedIncidentPopUp />;
                break;
        }

        return content;
    }, [layoutType]);

    return (
        <style.MapContent data-testid='MapContent' ref={mapElement}>
            {!disableVehiclePopup && (
                <MapPopUp showPopup={showPopup} content={getContent()} data-testid={'MapContent-MapPopUp'} />
            )}
            <style.InfoPopup id='map-info-popup' data-testid={'Info-PopUp'}>
                <SlopeInfoPopup />
            </style.InfoPopup>
            {loadingMapData && (
                <style.Loader>
                    <style.HeatmapLoader />
                </style.Loader>
            )}
            {warningMsg.length ? (
                <Fade in={!!warningMsg.length} data-testid={'MapContent-Fade'}>
                    <AlertMsg severity='warning'>
                        <strong>{warningMsg}</strong>
                        <style.CloseMsgBtn
                            data-testid={'MapContent-CloseMsgBtn'}
                            color='primary'
                            size='small'
                            onClick={() => {
                                setMapAction({ action: MapActionsEnum.HIDE_MAP_MESSAGE });
                            }}
                        >
                            <CloseIcon />
                        </style.CloseMsgBtn>
                    </AlertMsg>
                </Fade>
            ) : null}
        </style.MapContent>
    );
};

export default MapContent;
