import { UserModel } from 'models/User.type';
import { RoleCodeBook } from '../../../models/Role.type';
import { Column } from 'models/Table.type';

export type UsersAdministrationProps = {
    role?: RoleCodeBook[];
};

export type UsersAdministrationViewProps = {
    columns: Column<UserModel>[];
    handleModalAdd: () => void;
    showPermissionModal: boolean;
    setShowPermissionModal: (value: boolean) => void;
};

export enum UserEnabledEnum {
    ALL = 'all',
    ENABLED = 'active',
    DISABLED = 'inactive'
}

export enum UserLockedEnum {
    LOCKED = '1',
    UNLOCKED = '0'
}

export type AdminUserStatus = {
    label: string;
    value: UserEnabledEnum | UserLockedEnum;
};
