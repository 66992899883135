import PortalApiClient from 'helpers/api/PortalApiClient';
import * as type from '../models/Sensors.type';
export default class SensorMovement extends PortalApiClient<type.Sensor> {
    route = 'sensor-movement';

    getSensorsFittingReason = async (): Promise<type.SensorsWithPaginator> => {
        return await this.get({ extendUrl: 'fitting-reason' });
    };

    getSensorsUnmountReason = async (): Promise<type.SensorsWithPaginator> => {
        return await this.get({ extendUrl: 'removal-reason' });
    };
}
