import React from 'react';
import { useRecoilValue } from 'recoil';
import { Footer, ReportIssuePaper, ReportIssueViewContent } from './ReportIssue.style';
import { ReportIssueViewProps } from './ReportIssue.type';
import { Theme, ThemeMode } from 'states/global/Theme';
import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { BugReporterFormik } from 'components/Mixs/BugReporter/BugReporter.formik';
import ChooseLanguageButton from 'components/TopBar/ChooseLanguageButton/ChooseLanguageButton';
import { getImgSource } from 'helpers/image';
import { FooterText } from 'variables';

export const ReportIssueContent: React.FC<ReportIssueViewProps> = ({
    setError,
    getUploadedAttachments,
    handleRaiseRequest,
    hasAttachmentError,
    isProcessing
}): JSX.Element => {
    const theme = useRecoilValue(Theme);
    const Year = DateTime.now().year;
    const { t: translate } = useTranslation();

    return (
        <ReportIssueViewContent data-testid='ReportIssueContent'>
            <ReportIssuePaper>
                <img
                    src={theme.mode === ThemeMode.light ? getImgSource('logo') : getImgSource('logolight')}
                    className='logo'
                    alt='logo'
                    data-testid='error-boundary-bug-reporter-logo'
                />
                <Grid container alignItems='center' style={{ marginBottom: '20px' }}>
                    <Grid xs={9} md={9}>
                        <Typography variant='h4' fontWeight='bold' align='justify'>
                            {translate('t.request_support')}
                        </Typography>
                    </Grid>
                    <Grid xs={3} md={3} textAlign='right'>
                        <ChooseLanguageButton />
                    </Grid>
                </Grid>

                <BugReporterFormik
                    hasAttachmentError={hasAttachmentError}
                    setError={setError}
                    isProcessing={isProcessing}
                    mobileMode={false}
                    getUploadedAttachments={getUploadedAttachments}
                    handleRaiseRequest={handleRaiseRequest}
                    data-testid='error-boundary-bug-reporter-form'
                />
            </ReportIssuePaper>
            <Footer>
                {FooterText} - {Year}
            </Footer>
        </ReportIssueViewContent>
    );
};
