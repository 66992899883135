import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../states/global/Theme';
import { DARK_STEEL_GREY } from '../Ui/colors';

export const GraphContent = styled.div<{ height?: number }>`
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    ${(props) =>
        applyStyleByMode({
            styleJade: `
            height: auto;
            min-height: ${props.height ? `${props.height + 16}px;` : 'none;'}
            background: ${useTheme().palette.mode === ThemeMode.light ? '#f6f6f6' : 'rgb(0 0 0 / 30%)'};
            padding-top: 10px;
            border-radius: 5px;
            `
        })};
`;

export const LegendValue = styled.span<{ color: string }>`
    color: ${(props) => props.color || '#000'};
    cursor: pointer;
`;

export const LoadingBar = styled.div<{ width?: number; height?: number; unit?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => (props.height ? `${props.height}px` : '100%')};

    ${applyStyleByMode({
        styleJade: 'background: transparent'
    })}
`;

export const LoadingProgress = styled(CircularProgress)({
    color: applyStyleByMode({
        styleJade: DARK_STEEL_GREY
    })
});

export const NoDataAlert = styled.div`
    text-align: center;
`;

export const G = styled.g``;

export const TextTick = styled.text<{ fontSize?: string }>`
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1em')};
`;
