import { VehicleList } from '../../../../models/Vehicle.type';

type PassProps = {
    customeLabel?: string;
    maxAllowedVehicles?: number;
};

export type UiVehicleMultiselectProps = {
    onSelectedVehiclesChange?: (selectedVehicles: VehiclesData['selectedVehicles']) => void;
} & PassProps;

export type UiVehicleMultiselectViewProps = {
    vehicles: VehiclesData;
    setVehicles: React.Dispatch<VehicleAction>;
} & PassProps;

export type VehiclesData = {
    selectedVehicles: VehicleList;
    vehicles: VehicleList;
};

export enum VehicleActionsEnum {
    SET_VEHICLES = 'SET_VEHICLES',
    SET_SELECTED_VEHICLES = 'SET_SELECTED_VEHICLES'
}

type AddVehicles = {
    type: VehicleActionsEnum.SET_VEHICLES;
    payload: VehiclesData['vehicles'];
};

type AddSelectedVehicle = {
    type: VehicleActionsEnum.SET_SELECTED_VEHICLES;
    payload: VehiclesData['selectedVehicles'];
};

export type VehicleAction = AddVehicles | AddSelectedVehicle;
