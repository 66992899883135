import React from 'react';
import * as style from './AccountSettingsForm.style';
import * as type from './AccountSettingsForm.type';
import { useTranslation } from 'react-i18next';
import { AccountSettingsFormik } from './AccountSettingsForm.formik';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';

export const AccountSettingsFormContent: React.FC<type.AccountSettingsFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.AccountSettingsFormContent data-testid='AccountSettingsFormContent'>
            <ContainerBodyTab>
                <TitleContentTab>{translate('t.account_settings')}</TitleContentTab>
                <style.ContainerFormAccountSettings>
                    <AccountSettingsFormik {...props} />
                </style.ContainerFormAccountSettings>
            </ContainerBodyTab>
        </style.AccountSettingsFormContent>
    );
};
