import { DateRangeData } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { atom } from 'recoil';
import { DateTime } from 'luxon';

export const TopBarRankDateTimePicker = atom<DateRangeData>({
    key: 'TopBarRankDateTimePicker',
    default: {
        dateFrom: DateTime.local().minus({ days: 30 }),
        dateTo: DateTime.local()
    }
});
