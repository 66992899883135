import styled from 'styled-components';

export const MapLegendInfoPopoverStyle = styled('div')`
    position: absolute;
    top: 6px;
    left: 154px;
`;

export const PopoverContent = styled('div')`
    padding: 6px;
    max-width: 100px;
`;
