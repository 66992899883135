import { TyreNotification } from './Notification.type';
import { Paginator } from './Paginator.type';
import { AllowedLevels } from 'components/Alert/AlertsBreakdownTableRow/AlertsBreakdownTableRow.type';
import { ModelTpmsAlerts, ModelTyreEvent } from './TpmsAlerts.type';
import { AlertTypesFilters } from '../components/AlertNotification/AlertsListTabManager/AlertsListTabManager.type';

export type NotificationEventTypeModel = {};

export type AlertsBreakdownQueryParams = {
    period: number;
    vehicleId?: number | string;
};

export type AlertsBreakDownPeriod = {
    alertsCount: number;
    notificationType: NotificationTypeEnum;
    notificationType2: NotificationType2Enum;
    notificationLevel: AllowedLevels;
};

export type AlertsBreakDownResponse = {
    alertsBreakDown: {
        previousPeriod: AlertsBreakDownPeriod[];
        lastPeriod: AlertsBreakDownPeriod[];
    };
};

export type AlertSolveTyre = {
    notificationTyre: ModelTyreEventSolve;
};

export type AlertSolveEvent = {
    notificationEvent: ModelTyreEventSolve;
};

export type LeakNotification = {
    notificationId: number;
    fileId: number;
    sensorId: number;
    dateFrom: string;
    dateTo: string;
};

export type ModelAlertSolve = { data: AlertSolveTyre | AlertSolveEvent };

export type ModelTyreEventSolve = {
    id: number;
    solved: boolean;
    solvedAt: string | null;
    solvedBy: number | null;
};

export type MqttResponse = {
    topics: string[];
    config: {
        username: string;
        password: string;
        client: string;
    };
};

export type NotificationTyre = {
    notificationEvent: ModelTpmsAlerts;
};

export type TyreNotificationPreview = {
    notificationTyrePreview: TyreNotificationPreviewModel[];
};

export type TyreNotificationPreviewModel = {
    notificationId: number;
    vehicleName: string;
    type: string;
    type2: string;
    level: number;
    measuredAt: string;
    temperature: number;
    hotPressure: number;
    coldPressure: number;
    pressureLimitFrom: number;
    pressureLimitTo: number;
    temperatureLimitFrom: number | null;
    temperatureLimitTo: number | null;
    tyreCustomPosition: number;
    sensorSerialNumber: string;
    sensorSerialNumberHex: string;
    criticalTemperature: number | null;
    criticalTemperatureAt?: string | null;
};

export type TyreEventModelResponse = {
    items: ModelTyreEvent[];
    paginator?: Paginator;
};

export type TyreEventAdditionalProps = {
    measuredFrom: string;
    measuredTo: string;
    vehicleId: number;
    tab?: 'tpms' | 'system' | 'log';
};

export type TyreNotificationsResponse = {
    tyreTotalAlerts: TyreNotification[];
};

export enum NotificationTypeEnum {
    PRESSURE = 'pressure',
    TEMPERATURE = 'temperature',
    HOT_PRESSURE = 'hot_pressure',
    TEMPERATURE_PREDICTION = 'temperature_prediction'
}

export enum NotificationType2Enum {
    LOW = 'low',
    HIGH = 'high'
}

export type GetNotificationsType = {
    vehicleId?: number;
    solved?: boolean;
    measuredFrom?: string;
    sensorId?: number;
    page?: number;
    limit?: number;
    alertFilters?: AlertTypesFilters | null;
    wheelId?: number;
    tab?: 'tpms' | 'system' | 'log';
};
