import { atom } from 'recoil';

type VehicleDetailsTyresSettings = {
    targetPressure: boolean;
    secondSensorAlerts?: boolean;
};

export const VehicleDetailsTyresAtom = atom<VehicleDetailsTyresSettings>({
    key: 'VehicleDetailsTyresAtom',
    default: {
        targetPressure: false
    }
});
