import React from 'react';
import { RemovedSensorsTableViewContent } from './RemovedSensorsTable.style';
import { RemovedSensorsTableViewProps } from './RemovedSensorsTable.type';
import UiTable from '../../Ui/Components/UiTable2/UiTable';
import Sensors from '../../../api/Sensors';

const sensorsApi = new Sensors();

export const RemovedSensorsTableContent: React.FC<RemovedSensorsTableViewProps> = (props): JSX.Element => {
    const hidden: string[] = [
        'createdAtFrom',
        'createdAtTo',
        'removedAtFrom',
        'removedAtTo',
        'measuredAtFrom',
        'measuredAtTo',
        'sensorBatteryVoltageFrom',
        'sensorBatteryVoltageTo'
    ];

    return (
        <RemovedSensorsTableViewContent data-testid='RemovedSensorsTableContent'>
            <UiTable
                columns={props.columns}
                fetchFn={sensorsApi.getRemovedSensors}
                exportFn={sensorsApi.getRemovedSensorsExport}
                exportName={'RemovedSensors'}
                hiddenColumns={hidden}
                refresh={false}
                queryKey={'removedSensorsPage'}
            />
        </RemovedSensorsTableViewContent>
    );
};
