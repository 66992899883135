import PortalApi from 'helpers/api/PortalApiClient';
import {
    AccelerometerLogModel,
    AccelerometerLogReportTable,
    VibrationHeatmapResponse,
    VibrationZHeatmapResponse
} from '../models/AccelerometerLog.type';
import { BasicHeatmapProps } from '../models/TrackLog.type';

export default class AccelerometerLog extends PortalApi<AccelerometerLogModel> {
    route = 'accelerometer-log';

    getAccelerometerLogReports = async (props): Promise<AccelerometerLogReportTable[]> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicle'] = props.additionalProps.vehicleId;
        }
        return await this.getByCriteria({ extendUrl: 'preview', criteria });
    };

    getAccelerometerLogReportsCsv = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string
    ): Promise<AccelerometerLogReportTable> => {
        let criteria = {
            measuredFrom: measuredFrom,
            measuredTo: measuredTo
        };

        if (vehicleId > 0) {
            criteria['vehicle'] = vehicleId;
        }
        return await this.getByCriteria({ extendUrl: 'report', criteria: criteria });
    };

    getVibrationHeatmap = async (params: BasicHeatmapProps): Promise<VibrationHeatmapResponse> => {
        const heatmapParams: BasicHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };

        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds;
        }
        return await this.getByCriteria({
            extendUrl: 'vibration-heatmap',

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    getVibrationZHeatmap = async (params: BasicHeatmapProps): Promise<VibrationZHeatmapResponse> => {
        const heatmapParams: BasicHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };

        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds;
        }
        return await this.getByCriteria({
            extendUrl: 'vibration-z-heatmap',

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    //HEATMAP_API_AUTOMATIC_GENERATOR
}
