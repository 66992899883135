import { SpeedIncidentActions, SpeedIncidentActionType, SpeedIncidentState } from './HeatMapSpeedIncident.type';

export const SpeedIncidentReducer = (state: SpeedIncidentState, action: SpeedIncidentActionType) => {
    switch (action.type) {
        case SpeedIncidentActions.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            };
        case SpeedIncidentActions.SET_VEHICLES_IDS:
            return {
                ...state,
                vehiclesIds: action.payload
            };
        case SpeedIncidentActions.SET_LOADED_STATUS: {
            return {
                ...state,
                loaded: action.payload
            };
        }
        case SpeedIncidentActions.SET_OPERATOR: {
            return {
                ...state,
                operator: action.payload
            };
        }
        default:
            return state;
    }
};
