import styled from 'styled-components';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const EasyButton = styled(IconButton)<{ $active: boolean }>`
    ${(props) =>
        props.$active &&
        `
        color: ${useTheme().palette.primary.main} !important;
    `}
`;
