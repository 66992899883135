import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const RequestProgress = styled(CircularProgress)`
    & {
        width: 26px !important;
        height: 26px !important;
        margin-right: 15px !important;
    }

    ${applyStyleByMode({
        styleOld: '& { color: #4f714f !important;}'
    })}
`;

export const EmailNotificationCover = styled(Alert)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiAlert-root {
                padding: 0 10px;
                position: absolute;
                left: 10px;
            }
        `
    })}
`;

export const EmailNotification = styled.span`
    ${applyStyleByMode({
        styleOld: 'font-size: 1rem;'
    })}
`;
