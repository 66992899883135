import PortalApiClient from 'helpers/api/PortalApiClient';
import { CustomerReportModel, CustomerReportTableResponse } from '../models/CustomerReport.type';
import { getParams } from 'helpers';

export default class CustomerReport extends PortalApiClient<CustomerReportModel | CustomerReportTableResponse> {
    route = 'customer-report';

    getCustomerReportTable = async (props): Promise<CustomerReportTableResponse> => {
        return await this.getTable({ criteria: getParams(props), version: 'v2' });
    };

    editCheck = async (props): Promise<CustomerReportModel[]> => {
        return await this.patch({ data: props });
    };
}
