import React from 'react';
import TextField from '@mui/material/TextField';
import {
    CustomAutocomplete,
    UiVehicleMultiselectContent as UiVehicleMultiselectContentEl
} from './UiVehicleMultiselect.style';
import { UiVehicleMultiselectViewProps, VehicleActionsEnum } from './UiVehicleMultiselect.type';
import { useTranslation } from 'react-i18next';
import { VehicleForCodeBook, VehicleList } from '../../../../models/Vehicle.type';

export const UiVehicleMultiselectContent: React.FC<UiVehicleMultiselectViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <UiVehicleMultiselectContentEl data-testid='UiVehicleMultiselectContent'>
            <CustomAutocomplete
                id={'vehicles'}
                size={'small'}
                multiple
                options={props.vehicles.vehicles}
                getOptionDisabled={() => props.vehicles.selectedVehicles.length >= (props.maxAllowedVehicles || 3)}
                freeSolo={props.vehicles.selectedVehicles.length > 2}
                getOptionLabel={(option) => {
                    const vehicle = option as VehicleForCodeBook;
                    return vehicle.name;
                }}
                isOptionEqualToValue={(option, value) => {
                    const vehicleOption = option as VehicleForCodeBook;
                    const vehicleValue = value as VehicleForCodeBook;
                    return vehicleOption.id === vehicleValue.id;
                }}
                onChange={(a, b) => {
                    props.setVehicles({ payload: b as VehicleList, type: VehicleActionsEnum.SET_SELECTED_VEHICLES });
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={
                            !props.vehicles.selectedVehicles.length
                                ? props.customeLabel || translate('t.vehicles')
                                : translate('t.vehicles')
                        }
                        variant='outlined'
                        InputLabelProps={{
                            shrink: true
                        }}
                        data-testid='VehicleMultiselectInput'
                    />
                )}
            />
        </UiVehicleMultiselectContentEl>
    );
};
