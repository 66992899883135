import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TyreStateAtom } from '../TyreManager.atom';
import { CRUD } from 'variables';
import { Alert } from '@mui/material';
import React, { useMemo } from 'react';
import { WarningBox } from '../TyreManager.style';

const WarningMessage = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const tyreState = useRecoilValue(TyreStateAtom);

    const showMsg = useMemo(() => {
        return (
            (tyreState.state === CRUD.REPLACE && tyreState.newTyre.vehicleName) ||
            (tyreState.originalTyre.vehicleName !== tyreState.newTyre.vehicleName && tyreState.newTyre.vehicleName)
        );
    }, [tyreState.state, tyreState.originalTyre.vehicleName, tyreState.newTyre.vehicleName]);

    return (
        <WarningBox $isReplace={tyreState.state === CRUD.REPLACE}>
            {showMsg && (
                <Alert severity='warning'>
                    {translate('p.tyre_already_mounted_replace_warning', {
                        tyre: tyreState.newTyre.tyreSerialNumber,
                        vehicle2: tyreState.originalTyre.vehicleName,
                        position2: tyreState.originalTyre.customPosition,
                        vehicle1: tyreState.newTyre.vehicleName,
                        position1: tyreState.newTyre.customPosition,
                        action: translate(`t.${tyreState.state}`)
                    })}
                </Alert>
            )}
        </WarningBox>
    );
};

export default WarningMessage;
