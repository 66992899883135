import React, { useEffect } from 'react';
import { TemperatureExposureTyreModalContent } from './TemperatureExposureTyreModal.view';
import { TemperatureExposureTyreModalProps } from './TemperatureExposureTyreModal.type';
import SensorLogApi from '../../../api/SensorLog';
import { useQuery } from '@tanstack/react-query';
import useConverter from '../../CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import { TemperatureExposureDate } from '../../Mixs/TemperatureExposureTopbar/TemperatureExposureTopbar.atom';
import { Wrapper } from 'helpers/wrapper';

const SensorLog = new SensorLogApi();

const TemperatureExposureTyreModal: React.FC<TemperatureExposureTyreModalProps> = (props): JSX.Element => {
    const dateRange = useRecoilValue(TemperatureExposureDate);
    const { fromTimezoneToUTC } = useConverter();

    const { data, isLoading, isFetching, refetch } = useQuery(
        ['temperatureExposureTyreReport'],
        () => {
            if (props.tyreId) {
                return SensorLog.getTemperatureExposureByTyre({
                    tyreId: props.tyreId,
                    dateFrom: fromTimezoneToUTC(dateRange.appliedDateFrom),
                    dateTo: fromTimezoneToUTC(dateRange.appliedDateTo)
                });
            }
        },
        { enabled: false }
    );

    useEffect(() => {
        refetch();
    }, [dateRange.appliedDateFrom, dateRange.appliedDateTo]);

    return (
        <TemperatureExposureTyreModalContent
            isLoading={isLoading || isFetching}
            data={data}
            data-testid={'TemperatureExposureTyreModal-testid'}
        />
    );
};

export default Wrapper(TemperatureExposureTyreModal);
