import React from 'react';
import { AlertTemperatureSettingsContent } from './AlertTemperatureSettings.view';
import { AlertTemperatureSettingsProps } from './AlertTemperatureSettings.type';
import { Wrapper } from 'helpers/wrapper';

const AlertTemperatureSettings: React.FC<AlertTemperatureSettingsProps> = (props): JSX.Element => {
    return <AlertTemperatureSettingsContent data-testid={'AlertTemperatureSettings-testid'} {...props} />;
};

export default Wrapper(AlertTemperatureSettings);
