import styled from 'styled-components';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import CircularProgress from '@mui/material/CircularProgress';
import { DARK_STEEL_GREY } from '../../Ui/colors';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

export const DeviceBatteryVoltageGraphContainer = styled.div``;

export const Button = styled(UiButton)`
    border: none !important;
    background-color: transparent !important;
    color: ${DARK_STEEL_GREY} !important;

    &:active {
        border: none !important;
        background-color: transparent !important;
        color: ${DARK_STEEL_GREY} !important;
    }

    &:hover {
        border: none !important;
        background-color: transparent !important;
        color: ${DARK_STEEL_GREY} !important;
    }
`;

export const LoadingContent = styled(CircularProgress)`
    margin-top: 10px !important;
`;

export const CustomList = styled(List)`
    display: inline-flex !important;
    margin-bottom: 15px;
`;

export const CustomListItem = styled(ListItemButton)`
    width: 40px;
`;
