import React, { useReducer } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { HeatMapSpeedIncidentViewContent, LoadedStatus } from './HeatMapSpeedIncident.style';
import { SpeedIncidentActions, SpeedIncidentState } from './HeatMapSpeedIncident.type';
import { MapActionsEnum } from '../../Map/Map.type';
import { useTranslation } from 'react-i18next';
import VehicleMultiselect from '../../Ui/Components/UiVehicleMultiselect';
import Button from '../../Ui/Components/UiButton';
import { LoadedStatusAllowed, MapAction, MapActionData } from '../../../states/global/Map';
import { SpeedIncidentReducer } from './HeatMapSpeedIncident.reducer';
import { DateTime } from 'luxon';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';
import { MenuItem } from '@mui/material';
import { UserInfo } from '../../../states/global/User';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import SpeedEventApi from 'api/SpeedEvent';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

const SpeedEvent = new SpeedEventApi();

//const VehicleLoadingLog = new VehicleLoadingLogApi();

const HeatMapSpeedIncidentContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const SpeedIncitentInitalState: SpeedIncidentState = {
        dateRange: {
            dateFrom: DateTime.local().minus({ days: 7 }),
            dateTo: DateTime.local(),
            isValidRange: true
        },
        vehiclesIds: null,
        operator: null,
        loaded: -1
    };

    const [state, dispatch] = useReducer(SpeedIncidentReducer, SpeedIncitentInitalState);
    const setMapAction = useSetRecoilState(MapAction);
    const setMapActionData = useSetRecoilState(MapActionData);
    const userInfo = useRecoilValue(UserInfo);
    const loadedStatusAllowed = useRecoilValue(LoadedStatusAllowed);

    const updateSpeedIncidentsMap = () => {
        setMapActionData((currentMapActionData) => {
            return {
                ...currentMapActionData,
                ...{
                    selectedVehicles: state.vehiclesIds || undefined,
                    heatmapDateRange: state.dateRange || undefined,
                    loaded: state.loaded,
                    enableLoaded: !!loadedStatusAllowed,
                    vehicleOperator: state.operator
                }
            };
        });
        setMapAction({
            action: MapActionsEnum.DRAW_SPEED_INCIDENTS
        });
    };

    // Do not remove, will be used in the future.
    // const filters = useMemo(() => {
    //     let filters: FilterProps[] = [
    //         {
    //             id: 'speedEventMeasuredFrom',
    //             value: {
    //                 value: fromTimezoneToUTC(state.dateRange?.dateFrom),
    //                 name: 'speedEventMeasuredFrom'
    //             }
    //         },
    //         {
    //             id: 'speedEventMeasuredTo',
    //             value: {
    //                 value: fromTimezoneToUTC(state.dateRange?.dateTo),
    //                 name: 'speedEventMeasuredTo'
    //             }
    //         },
    //         {
    //             id: 'speedEventLoaded',
    //             value: {
    //                 value: state.loaded === -1 ? undefined : state.loaded.toString(),
    //                 name: 'speedEventLoaded'
    //             }
    //         }
    //     ];

    //     if (state.vehiclesIds?.length) {
    //         filters = [
    //             ...filters,
    //             {
    //                 id: 'speedEventVehicle',
    //                 value: {
    //                     value: state.vehiclesIds,
    //                     name: 'speedEventVehicle'
    //                 }
    //             }
    //         ];
    //     }
    //     if (state.operator) {
    //         filters = [
    //             ...filters,
    //             {
    //                 id: 'speedEventVehicleOperator',
    //                 value: {
    //                     value: state.operator.toString(),
    //                     name: 'speedEventVehicleOperator'
    //                 }
    //             }
    //         ];
    //     }
    //     return filters;
    // }, [state.dateRange?.dateFrom, state.dateRange?.dateTo, state.vehiclesIds, state.operator, state.loaded]);

    // eslint-disable-next-line
    return (
        <HeatMapSpeedIncidentViewContent data-testid='HeatMapSpeedIncidentContent'>
            <UiDateRangePicker2
                data-testid={'HeatMapSpeedIncidentContent-UiDateRangePicker'}
                adjustToPeriod={{ period: 7, periodType: PeriodTypeEnum.DAYS }}
                dateFrom={state.dateRange?.dateFrom}
                dateTo={state.dateRange?.dateTo}
                dateFromLabel={translate('t.date_from')}
                dateToLabel={translate('t.date_to')}
                onDateChange={(dateRange) => {
                    if (dateRange)
                        dispatch({
                            type: SpeedIncidentActions.SET_DATE_RANGE,
                            payload: { dateFrom: dateRange[0], dateTo: dateRange[1] }
                        });
                }}
            />
            {/* Do not remove, will be used in the future. */}
            {/*<LazyloadingAutocomplete
                data-testid={'HeatmapSpeedIncidentsVehicle-LazyloadingAutocomplete'}
                id={'speed-incidents-vehicle-lazyloading'}
                queryId='speed-incidents-vehicle-lazyloading'
                optionKey='name'
                optionValue='id'
                size='small'
                clearButton
                customMargin='0 0 0 4px'
                query={SpeedEvent.getTable}
                extendUrl='incidents/vehicles'
                label={translate('t.vehicles')}
                defaultFilter={filters}
                onValueChange={(value, label, origItem) => {
                    dispatch({
                        type: SpeedIncidentActions.SET_VEHICLES_IDS,
                        payload: value ? [value] : null
                    });
                }}
            />*/}

            <VehicleMultiselect
                data-testid={'HeatMapSpeedIncidentContent-VehicleMultiselect'}
                onSelectedVehiclesChange={(selectedVehicles) => {
                    dispatch({
                        type: SpeedIncidentActions.SET_VEHICLES_IDS,
                        payload: selectedVehicles.map((vehicle) => vehicle.id)
                    });
                }}
            />

            {userInfo.user?.customer.id === 155 && (
                <>
                    <LoadedStatus
                        data-testid={'excessive-acc-status-select'}
                        id='speed-incident-status-select'
                        select
                        label={translate('t.loaded_state')}
                        size={'small'}
                        value={state.loaded}
                        onChange={(event) => {
                            dispatch({
                                type: SpeedIncidentActions.SET_LOADED_STATUS,
                                payload: Number(event.target.value)
                            });
                        }}
                    >
                        <MenuItem value={-1}>{translate('t.all')}</MenuItem>
                        <MenuItem value={1}>{translate('t.loaded')}</MenuItem>
                        <MenuItem value={0}>{translate('t.unloaded')}</MenuItem>
                    </LoadedStatus>
                    <LazyloadingAutocomplete
                        data-testid={'HeatmapSpeedIncidents-LazyloadingAutocomplete'}
                        id={'speed-incidents-operator-lazyloading'}
                        queryId='speed-incidents-operator-lazyloading'
                        optionKey='firstNameLastName'
                        optionValue='id'
                        size='small'
                        clearButton
                        customMargin='0 0 0 4px'
                        query={SpeedEvent.getTable}
                        extendUrl='incidents/vehicle-operators'
                        label={translate('t.operator')}
                        //defaultFilter={filters}
                        onValueChange={(value) => {
                            dispatch({
                                type: SpeedIncidentActions.SET_OPERATOR,
                                payload: value || null
                            });
                        }}
                        getOptionLabel={(value) => {
                            return {
                                text: `${value.firstName} ${value.lastName}`,
                                html: undefined
                            };
                        }}
                    />
                </>
            )}
            <Button
                data-testid={'HeatMapSpeedIncidentContent-Button-apply'}
                skin='success'
                color='primary'
                variant='contained'
                disabled={typeof state.dateRange?.isValidRange === 'undefined' ? false : !state.dateRange?.isValidRange}
                onClick={updateSpeedIncidentsMap}
            >
                {translate('t.apply')}
            </Button>
        </HeatMapSpeedIncidentViewContent>
    );
};

export default HeatMapSpeedIncidentContent;
