import PortalApi from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import {
    MountTyreProps,
    ReplaceTyreProps,
    TyreAdd,
    TyreData,
    TyreModel,
    TyreTempPressReport,
    TyreUpdateComment,
    TyreWithPaginator,
    TyreLifeHistoryResponse,
    TyreLifeDetailResponse,
    UnmountTyreProps
} from '../models/Tyre.type';
import { Filterlevel } from 'components/AlertNotification/AlertsList/AlertsList.type';
import { DefaultRequestPropsType, Paginator } from 'helpers/api/type';

export default class Tyre extends PortalApi<TyreModel> {
    route = 'tyre';

    getAll = async (props): Promise<TyreWithPaginator> => {
        return await this.getTable({ criteria: getParams(props) });
    };

    removeTyre = async (id: number): Promise<unknown> => {
        return await this.delete({ id });
    };

    addTyre = async (data: TyreAdd): Promise<TyreData> => {
        return await this.post({ data: { ...data, comment: data.comment == '' ? null : data.comment } });
    };

    updateTyreComment = async (data: TyreUpdateComment): Promise<TyreData> => {
        return await this.patchById({ data: data.updateData, id: data.tyreId });
    };

    getTempPressReport = async (): Promise<TyreTempPressReport[]> => {
        return await this.get({ extendUrl: 'temp-press-report', version: 'v2' });
    };

    getExport = async (props): Promise<string> => {
        return await this.get({ extendUrl: 'export', data: getParams(props) });
    };

    getUniqueSerialNumber = async (serialNumber: string): Promise<boolean> => {
        const isUnique = await this.get({ extendUrl: `serial-number/${serialNumber}` });
        return !isUnique;
    };

    mountTyre = async (data: MountTyreProps): Promise<TyreModel> => {
        return await this.patch<TyreModel, Omit<MountTyreProps, 'tyreId'>>({
            data: { ...data },
            extendUrl: `${data.tyreId}/mount`
        });
    };

    unmountTyre = async (data: UnmountTyreProps): Promise<TyreModel> => {
        return await this.patch<TyreModel, Omit<UnmountTyreProps, 'tyreId'>>({
            data: { ...data },
            extendUrl: `${data.tyreId}/unmount`
        });
    };

    replaceTyre = async (data: ReplaceTyreProps): Promise<TyreModel> => {
        return await this.patch<TyreModel, Omit<ReplaceTyreProps, 'tyreId'>>({
            data: { ...data },
            extendUrl: `${data.tyreId}/replace`
        });
    };

    getTyreLifeHistory = async (tyreId: number, level?: Filterlevel[]): Promise<TyreLifeHistoryResponse> => {
        let params: { level: Filterlevel[] } | {} = {};
        if (level) {
            params['level'] = level;
        }

        return await this.getByCriteria({
            extendUrl: `${tyreId}/life-history`,
            criteria: params,
            version: 'v2'
        });
    };

    getTyreLifeDetail = async (tyreId: number): Promise<TyreLifeDetailResponse> => {
        return await this.get({ extendUrl: `${tyreId}/life/detail` });
    };

    getTyreLazyDropdown = async <T extends unknown>(
        props: DefaultRequestPropsType
    ): Promise<Paginator<T, TyreModel>> => {
        if (props.criteria) props.criteria.idString = props.criteria.id;
        delete props.criteria?.id;

        return await this.getTable(props);
    };

    getLastTyreInspected = async (props): Promise<Paginator<any, TyreModel>> => {
        return await this.getTable({
            criteria: getParams({
                queryPageIndex: 0,
                queryPageSize: 1,
                queryPageSortBy: [{ id: 'inspectedAt', desc: true }],
                queryPageFilter: [
                    { id: 'vehicleId', value: { name: 'vehicleId', value: `${props.vehicleId}` } },
                    { id: 'customPosition', value: { name: 'customPosition', value: `${props.customPosition}` } }
                ]
            })
        });
    };
}
