import React, { useMemo } from 'react';
import { Table } from '@mui/material';
import {
    LeftActionBar,
    RightActionBar,
    TableCover,
    TableLoader,
    TableTopbar,
    UiTableMainViewContent,
    UiTableViewContent
} from './UiTable.style';
import Widget from 'components/Ui/Components/UiWidget';
import UiTableExportDropdownContent from '../UiTableExportDropdown/UiTableExportDropdown';
import UiTableHideColumns from '../UiTableHideColumns/UiTableHideColumns';
import UiTableRefreshBtn from '../UiTableRefreshBtn/UiTableRefreshBtn';
import UiTableAction from '../UiTableAction/UiTableAction';
import UiTableFooter from '../UiTableFooter/UiTableFooter';
import UiTableSkeletonLoader from '../UiTableSkeletonLoader/UiTableSkeletonLoader';
import UiTablePrintWrapper from '../UiTablePrintWrapper/UiTablePrintWrapper';
import UiTableFilterColumnsBtn from '../UiTableFilterColumnsBtn/UiTableFilterColumnsBtn';
import UiTableInstance from '../UiTableInstance/UiTableInstance';
import UiTableChipBar from '../UiTableChipBar/UiTableChipBar';

export const UiTableContent = <K extends ({ ...args }) => ReturnType<K>>(props): JSX.Element => {
    const skeletonLoader = useMemo(() => <UiTableSkeletonLoader />, []);

    return (
        <UiTableViewContent data-testid='UiTableContent'>
            <Widget title={props.title || 'table title'} avatar={props.avatar}>
                {props.isLoading && skeletonLoader}
                {!props.isLoading && (
                    <>
                        <TableTopbar>
                            <LeftActionBar>
                                {props.exportFn && (
                                    <UiTableExportDropdownContent
                                        exportFn={props.exportFn}
                                        queryKey={props.queryKey}
                                        exportName={props.exportName}
                                    />
                                )}
                                <UiTableAction actionBtns={props.leftActionBtns} queryKey={props.queryKey} />
                                {!props.useColumnFiltering && <UiTableChipBar queryKey={props.queryKey} />}
                            </LeftActionBar>
                            <RightActionBar>
                                <UiTableAction actionBtns={props.rightActionBtns} queryKey={props.queryKey} />
                                <UiTableRefreshBtn queryKey={props.queryKey} />
                                {!props.useColumnFiltering && <UiTableFilterColumnsBtn queryKey={props.queryKey} />}
                                <UiTableHideColumns queryKey={props.queryKey} />
                            </RightActionBar>
                        </TableTopbar>
                        <TableCover>
                            {props.isLoadingFetching && <TableLoader data-test-id={`${props.queryKey}TableLoader`} />}
                            <UiTableMainViewContent data-testid='UiTableInstanceContent'>
                                <UiTablePrintWrapper
                                    queryKey={props.queryKey}
                                    exportName={props.exportName}
                                    minHeight={props.minHeight}
                                    maxHeight={props.maxHeight}
                                >
                                    {props.data && (
                                        <UiTableInstance
                                            {...props}
                                            data={props.data}
                                            pageCount={props.pageCount}
                                            paginator={props?.paginator}
                                            isLoadingFetching={props.isLoadingFetching}
                                            isLoading={props.isLoading}
                                            data-testid='UiTable-testid'
                                        />
                                    )}
                                </UiTablePrintWrapper>
                            </UiTableMainViewContent>
                            <Table>
                                <UiTableFooter key={`UiTableFooter-${props.queryKey}`} queryKey={props.queryKey} />
                            </Table>
                        </TableCover>
                    </>
                )}
            </Widget>
        </UiTableViewContent>
    );
};
