import { SensorStatus } from './SensorStatus.type';

export enum Status {
    FAULT = 'fault',
    SUSPICIOUS = 'suspicious',
    ACTIVE = 'active',
    REMOVED = 'removed',
    INACTIVE = 'inactive',
    TEST = 'test'
}

export type SensorInfo = {
    id: number;
    status: `${Status}`;
    measuredAt: string;
    sensorStatus: SensorStatus | null;
};
