import React from 'react';
import { ModalContent } from './Modal.view';
import { Modal2Props } from './Modal.type';
import { ModalAtom } from '../../../states/global/Modal';
import { useRecoilValue } from 'recoil';

const Modal: React.FC<Modal2Props> = (): JSX.Element => {
    const modalState = useRecoilValue<Modal2Props>(ModalAtom);

    return <ModalContent {...modalState} data-testid='Modal2-testid' />;
};

export default Modal;
