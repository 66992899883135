import { atom } from 'recoil';
import { DateTime } from 'luxon';
import { DatePickerAction } from '../../pages/SeverityRank/atom';

export type DateTimePickerType = {
    current: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
    original: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
    action: DatePickerAction;
};

export type TimelineType = {
    timeline: { [id: number]: { timeKey: number } };
    granularity: number;
};

export const DateTimePicker = atom<DateTimePickerType>({
    key: 'StatisticsDateTimePicker',
    default: {
        current: {
            dateFrom: DateTime.now().minus({ days: 1 }),
            dateTo: DateTime.now()
        },
        original: {
            dateFrom: DateTime.now().minus({ days: 1 }),
            dateTo: DateTime.now()
        },
        action: DatePickerAction.APPLY
    }
});

export const SelectedVehicle = atom<{
    id: number;
    name: string;
    icon: string;
    distribution?: string;
}>({
    key: 'StatisticsSelectedVehicle',
    default: {
        id: 0,
        name: '',
        icon: '',
        distribution: '2-4'
    }
});

export const SyncXGraph = atom<number>({
    key: 'SyncXGraph',
    default: 0
});

export const AllHubsReading = atom<boolean | undefined>({
    key: 'StatisticsAllHubsReading',
    default: undefined
});

export const CollapseAll = atom<boolean>({
    key: 'StatisticsCollapseAl',
    default: false
});

export const Timeline = atom<TimelineType>({
    key: 'StatisticsTimeline',
    default: {
        timeline: {},
        granularity: 120
    }
});

export const CurrentTab = atom<number>({
    key: 'StatisticsCurrentTab',
    default: 0
});

export const Collapse = atom<{
    all: boolean;
    secondary: boolean;
    last: string;
}>({
    key: 'StatisticsCollapse',
    default: {
        all: false,
        secondary: true,
        last: ''
    }
});
