import PortalApi from 'helpers/api/PortalApiClient';
import { VehicleCounterActionsModel, VehicleCounterActionsTableModel } from 'models/VehicleCounterActions.type';
import { getParams } from 'helpers';

export default class VehicleCounterActions extends PortalApi<VehicleCounterActionsModel> {
    route = 'vehicle-counter-action';

    getVehicleCounterAction = (props): Promise<VehicleCounterActionsTableModel> => {
        return this.getTable({
            criteria: getParams(props),
            extendUrl: `vehicle/${props.additionalProps.vehicleId}`
        });
    };
}
