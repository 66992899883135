import React from 'react';
import { DropdownUpdateContainer, DropdownUpdateViewContent, ShowDropdownUpdate } from './DropdownUpdate.style';
import { DropdownUpdateViewProps } from './DropdownUpdate.type';
import { useTranslation } from 'react-i18next';
import UiLegend from '../../Ui/Components/UiLegend/UiLegend';
import { DropDownUpdateFormik } from './DropDownUpdate.formik';

export const DropdownUpdateContent: React.FC<DropdownUpdateViewProps> = ({
    initialValues,
    showComment,
    loading,
    handleSubmit,
    type,
    list,
    listLoading,
    valueId,
    testid
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <DropdownUpdateViewContent data-testid={`${testid || 'DropdownUpdate'}-View`}>
            <UiLegend
                title={translate(`t.select_${type}`)}
                width={350}
                mode='light'
                content={
                    <DropdownUpdateContainer>
                        <DropDownUpdateFormik
                            initialValues={initialValues}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            listLoading={listLoading}
                            list={list}
                            type={type}
                            testid={testid}
                            valueId={valueId}
                        />
                    </DropdownUpdateContainer>
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                root={
                    <ShowDropdownUpdate data-testid={`${testid || 'DropdownUpdateView'}-Show`}>
                        {' '}
                        {showComment ?? '---'}{' '}
                    </ShowDropdownUpdate>
                }
            />
        </DropdownUpdateViewContent>
    );
};
