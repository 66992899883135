import PortalApi from 'helpers/api/PortalApiClient';
import { VehicleLoadingLogEnabled, VehicleLoadingLogModel } from '../models/VehicleLoadingLog.type';

export default class VehicleLoadingLog extends PortalApi<VehicleLoadingLogModel> {
    route = 'vehicle-loading-log';

    getEnabled = (): Promise<VehicleLoadingLogEnabled> => {
        return this.get({ extendUrl: 'enabled' });
    };
}
