import { Grid, Paper, Theme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';

export const SetAlertsFilterWidgetContainer = styled(Paper)``;

export const SelectFilterContent = styled(Grid)((props: { theme: Theme }) => ({
    borderRadius: '4px',
    background: props.theme.palette.mode === ThemeMode.dark ? '#1d2630' : '#fff'
}));
