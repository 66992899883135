import React, { useState, useEffect, useRef } from 'react';
import { VehicleSchemaContainerContent } from './VehicleSchemaContainer.style';
import { VehicleSchemaContainerProps, VehicleSchemaTyre } from './VehicleSchemaContainer.type';
import VehicleDetailsSchema from '../../../../Vehicle/VehicleDetailsSchema/VehicleDetailsSchema';
import { useSetRecoilState } from 'recoil';
import { VehicleDetailsWidgetAtom } from '../../../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { ConfigurationV2 } from 'models/VehicleConfiguration.type';
import { Wrapper } from 'helpers/wrapper';

const VehicleSchemaContainer: React.FC<VehicleSchemaContainerProps> = (props): JSX.Element => {
    const vehicleSchemaRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(true);
    const setVehicleDetailsSchema = useSetRecoilState(
        VehicleDetailsWidgetAtom<VehicleSchemaTyre>(`VehicleSchemaAtom-${props.idVehicle}`)
    );

    useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        });

        vehicleSchemaRef.current && observer.observe(vehicleSchemaRef.current);

        return () => {
            vehicleSchemaRef.current && observer.unobserve(vehicleSchemaRef.current);
        };
    }, []);

    setVehicleDetailsSchema({
        vehicleDetails: {
            vehicleConfigurationV2: props.additionalData.movement.wheel.vehicle.vehicleConfigurationV2
        } as { vehicleConfigurationV2: ConfigurationV2 | { distribution: string } },
        tyreDetails: [
            {
                wheel: {
                    ...props.additionalData.movement.wheel,
                    position: props.additionalData.movement.wheel.customPosition
                },
                attachedSensors: [{ sensorAlert: 1, isTyreLifespan: true }]
            }
        ]
    });

    return (
        <VehicleSchemaContainerContent
            data-testid={'VehicleSchemaContainerContent-testid'}
            ref={vehicleSchemaRef}
            id={`${props.idVehicle}`}
        >
            {visible && (
                <VehicleDetailsSchema
                    noBorder={true}
                    vehicleAtomId={props.idVehicle}
                    data-testid={`VehicleSchemaContainer-VehicleDetailsSchema-${props.idVehicle}`}
                />
            )}
        </VehicleSchemaContainerContent>
    );
};

export default Wrapper(VehicleSchemaContainer);
