import React, { useState, useEffect, useReducer } from 'react';
import * as view from './Graph.view';
import { GraphProps, Domain, GraphAction } from './Graph.type';
import { deepCopyObj, isString, makeObjectNullable } from 'helpers';
import { AllHubsReading } from '../../states/global/Statistics';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { DatePickerAction } from '../../pages/SeverityRank/atom';
import { DataKey } from 'recharts/types/util/types';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { Wrapper } from 'helpers/wrapper';

const solveClickedLegend = (state: string[], action: { type: string; dataKey: string }) => {
    switch (action.type) {
        case 'disable':
            return [...state, action.dataKey];
        case 'enable':
            return state.filter((line) => line !== action.dataKey);
        default:
            throw new Error();
    }
};

const updateGraphData = (
    state: { data: unknown[]; origData: unknown[] },
    action: { type: string; data: unknown[] }
) => {
    switch (action.type) {
        case GraphAction.UPDATE_DATA:
            return { ...state, data: action.data };
        case GraphAction.UPDATE_ORIG_DATA:
            return { ...state, origData: action.data };
        case GraphAction.CLONE_DATA:
            return { ...state, data: state.data.slice() };
        case GraphAction.ORIG_TO_DATA:
            return { ...state, data: [...state.origData] };
        case GraphAction.FILTER_ORIG_TO_DATA: {
            const copyOrig = deepCopyObj(state.origData);
            const filtered = copyOrig.map((data) => {
                if ((data as { data: { showAlienData } } & Record<string, unknown>).showAlienData) {
                    let a = makeObjectNullable(data);
                    return a;
                }
                return data;
            });
            return { ...state, data: filtered };
        }
        default:
            throw new Error();
    }
};

const Graph: React.FC<GraphProps> = (props): JSX.Element => {
    const [xDomain, setXDomain] = useState<Domain>([`dataMin`, `dataMax`]);
    const [graphData, setGraphData] = useReducer(updateGraphData, { data: [], origData: [] });
    const [hiddenLines, setHiddenLines] = useReducer(solveClickedLegend, []);
    const [unableLoadChart, setUnableLoadChart] = useState<boolean>(false);
    const [refDomain, setRefDomain] = useState<Domain>(['', '']);
    const [showZoomBtn, setShowZoomBtn] = useState<boolean>(false);
    const [isLoading2, setIsLoading] = useState<boolean>(false);
    const allHubsReading = useRecoilValue(AllHubsReading);

    /*const {
        data,
        isLoading,
        isFetching,
        refetch
    } = useQuery(
        `graph-data-${Math.random()*1000000000000000}`,
        async () => props.fetchData && await props.fetchData({
            dateFrom: props.globalXDomain?.current.dateFrom!,
            dateTo: props.globalXDomain?.current.dateTo!
        }),
        {
            enabled: false,
            onSuccess(data) {
                
                data && setGraphData({ type: GraphAction.UPDATE_ORIG_DATA, data })
            },
            onSettled(data, error) {
              
            },
        }
    );*/

    const clickLegend = (e: Payload & { dataKey?: DataKey<unknown> }): void => {
        const isDisabledLine: boolean = hiddenLines.includes(e.dataKey as string);
        const action: string = isDisabledLine ? 'enable' : 'disable';
        setHiddenLines({ type: action, dataKey: e.dataKey as string });
    };

    const onMouseDown = (e: CategoricalChartState): void => {
        e &&
            setRefDomain((currentRefDomain) => {
                let domainCopy: Domain = [...currentRefDomain];
                domainCopy[0] = e.activeLabel as string;
                return domainCopy;
            });
    };

    const onMouseMove = (e: CategoricalChartState): void => {
        if (e) {
            const refDomainLeft = refDomain[0];

            refDomainLeft &&
                setRefDomain((currentRefDomain) => {
                    let domainCopy: Domain = [...currentRefDomain];
                    domainCopy[1] = e.activeLabel as string;
                    return domainCopy;
                });

            e.activeLabel && props.onHoverGraph && props.onHoverGraph(parseInt(e.activeLabel as string));
        }
    };

    const zoomIn = (): void => {
        let [refDomainLeft, refDomainRight]: Domain = refDomain;

        const invalidZoom: boolean = refDomainLeft === refDomainRight || !refDomainRight;
        if (invalidZoom) {
            setRefDomain(['', '']);
            //setZoomDomains([]);
            return;
        }

        if (refDomainLeft > refDomainRight) [refDomainLeft, refDomainRight] = [refDomainRight, refDomainLeft];
        setXDomain([refDomainLeft, refDomainRight]);
        setShowZoomBtn(true);
    };

    const zoomOut = (): void => {
        //setZoomDomains([]);
        setShowZoomBtn(false);
        setXDomain([`dataMin`, `dataMax`]);
    };

    useEffect(() => {
        if (xDomain) {
            if (!isString(xDomain[0])) {
                props.setGlobalXDomain &&
                    props.setGlobalXDomain({
                        ...props.globalXDomain,
                        current: {
                            dateFrom: DateTime.fromMillis(+xDomain[0] * 1000),
                            dateTo: DateTime.fromMillis(+xDomain[1] * 1000)
                        },
                        action: DatePickerAction.ZOOM_IN
                    });
            }
            setGraphData({ type: GraphAction.CLONE_DATA, data: [] });

            setRefDomain(['', '']);
        }
    }, [xDomain]);

    useEffect(() => {
        if (props.globalXDomain?.current.dateTo !== props.globalXDomain?.original.dateTo && props.zoomInExternal) {
            props.zoomInExternal();
        }
    }, [props.globalXDomain]);

    const loadAndSet2 = async (): Promise<void> => {
        setGraphData({ type: GraphAction.UPDATE_DATA, data: [] });
        setIsLoading(true);
        try {
            if (props.fetchData) {
                const data = await props.fetchData({
                    dateFrom: props.globalXDomain?.current.dateFrom ?? '',
                    dateTo: props.globalXDomain?.current.dateTo ?? ''
                });
                if (data) {
                    setGraphData({
                        type: GraphAction.UPDATE_ORIG_DATA,
                        data: Array.isArray(data) ? data : data[Object.keys(data)[0]]
                    });
                }
            }
        } catch (e) {
            if (
                (e as { isAxiosError: boolean })?.isAxiosError ||
                ((e as { toJSON })?.toJSON instanceof Function &&
                    (e as { toJSON })?.toJSON()?.message == 'Network Error')
            )
                setUnableLoadChart(true);
            // eslint-disable-next-line no-console
            console.error(e);
        }
        setIsLoading(false);
    };

    /*const loadAndSet = () => {
        setGraphData({ type: GraphAction.UPDATE_DATA, data: [] });
        //refetch();
    }; */

    useEffect(() => {
        loadAndSet2();

        //props.fetchData && loadAndSet(props.globalXDomain);
    }, [props.timeline]);

    useEffect(() => {
        if (graphData.origData.length) {
            if (!allHubsReading) {
                setGraphData({ type: GraphAction.FILTER_ORIG_TO_DATA, data: [] });
            } else {
                allHubsReading !== undefined && setGraphData({ type: GraphAction.ORIG_TO_DATA, data: [] });
            }
        }
    }, [allHubsReading, graphData.origData]);

    useEffect(() => {
        props.data && setGraphData({ type: GraphAction.UPDATE_DATA, data: props.data });

        return () => {
            setUnableLoadChart(false);
        };
    }, [props.data]);

    return (
        <view.GraphContent
            data-testid={'Graph-testid'}
            {...props}
            testId={props.testId ?? 'Graph'}
            xDomain={xDomain}
            graphData={graphData}
            events={{ clickLegend, zoomIn, zoomOut, onMouseDown, onMouseMove }}
            disabledLegend={hiddenLines}
            unableLoadChart={unableLoadChart}
            isLoading={isLoading2}
            showZoomBtn={showZoomBtn}
            refDomain={refDomain}
            globalXDomain={
                props.globalXDomain && [
                    props.globalXDomain.current.dateFrom.valueOf() / 1000,
                    props.globalXDomain.current.dateTo.valueOf() / 1000
                ]
            }
        />
    );
};

Graph.defaultProps = {
    height: 200,
    width: 1000,
    excludeFromLegend: [],
    excludeFromTooltip: [],
    showLegend: true
};

export default Wrapper(Graph);
