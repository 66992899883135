import React from 'react';
import { DashboardMapContainer } from './DashboardMap.style';
import { DashboardMapViewProps } from './DashboardMap.type';
import Widget from '../../components/Ui/Components/UiWidget';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { MapActionsEnum } from '../../components/Map/Map.type';
import { MapAction } from '../../states/global/Map';
import Map from '../Map';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

export const DashboardMapContent: React.FC<DashboardMapViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const ThemeMode = useRecoilValue(Theme);
    return (
        <DashboardMapContainer data-testid='DashboardMap2Content'>
            <Widget
                data-testid={'DashboardMapContent-Widget'}
                title={translate('t.map')}
                collapseButton={false}
                fullScreenButton={true}
                headerColor={props.headerColor || undefined}
                avatar={
                    <UiIcon
                        data-testid={'DashboardMapContent-Widget-UiIcon'}
                        icon={faLocationDot}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: props.mode === 'light' ? '#333' : '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                $contentHeight={props.height || 60}
                marginBottom={false}
                scrollbar={false}
                width={props.width || undefined}
                applyPadding={false}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit={props.unit || 'px'}
                padding={0}
                sx={props.sx}
            >
                <Map enableDrawing={props.enableDrawing} data-testid={'DashboardMapContent-Map'} />
            </Widget>
        </DashboardMapContainer>
    );
};
