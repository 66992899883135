import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { SensorButtonAroundProps, transmissionFilteringType } from './SensorButtonAround.type';
import { useTranslation } from 'react-i18next';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from '../../../states/global/Modal';
import SensorTableAround from '../SensorTableAround/SensorTableAround';

import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { CustomButton } from './SensorButtonAround.style';

const SensorButtonAround: React.FC<SensorButtonAroundProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const { dateTimeFormat, fromUTCToTimezone, fromTimezoneToUTC } = useConverter();
    const transmissionFiltering: transmissionFilteringType = useMemo(() => {
        if (props.lastTimeConnected) {
            const lastTimeConnected = DateTime.fromFormat(
                fromUTCToTimezone(props.lastTimeConnected),
                dateTimeFormat('dateTime', true)
            );

            return {
                offlineSensor: DateTime.now().diff(lastTimeConnected, 'days').days > 1,
                measuredFrom: fromTimezoneToUTC(lastTimeConnected.minus({ hours: 1 }).valueOf()),
                measuredTo: fromTimezoneToUTC(lastTimeConnected.valueOf())
            };
        }

        return {
            offlineSensor: false,
            measuredFrom: fromTimezoneToUTC(DateTime.now().minus({ hours: 1 }).valueOf()),
            measuredTo: fromTimezoneToUTC(DateTime.now().valueOf())
        };
    }, [props.lastTimeConnected]);

    const handleModalSesnorAround = () => {
        const contentModalSensorAround = {
            id: 'SensorAroundModal',
            leftTitle: `
                ${translate('t.sensors_around')}  
                (${fromUTCToTimezone(transmissionFiltering.measuredFrom || '')} - 
                ${fromUTCToTimezone(transmissionFiltering.measuredTo || '')})
            `,
            content: <SensorTableAround {...props} transmissionFiltering={transmissionFiltering} />,
            buttons: undefined,
            width: 1200,
            height: 450,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalSensorAround,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalSensorAround,
                isOpen: true
            }
        });
    };

    return (
        <CustomButton
            $applyCustomStyle={props.applyCustomStyle}
            variant='contained'
            skin='success'
            size='small'
            onClick={handleModalSesnorAround}
            type='button'
            testid='SummaryTab-SensorsAround-Button'
        >
            {translate('t.sensors_around')}
        </CustomButton>
    );
};

export default SensorButtonAround;
