import styled from 'styled-components';
import { PRIMARY, SECONDARY } from '../Ui/colors';
import UiButton from '../Ui/Components/UiButton/UiButton';

export const PopoverContainer = styled.div``;

export const CustomUiButton = styled(UiButton)`
    max-height: 20px;
    min-height: 20px;
    &&& .MuiButton-root {
        margin: 0;
        color: ${PRIMARY}
        background: ${SECONDARY}
    }
`;

export const LoadingContent = styled.div`
    text-align: center;
    width: 50px;
    height: 50px;
`;
