import React from 'react';
import { FooterContainer, FooterBody, ContainerContent, FooterContentRight, FooterContentLeft } from './Footer.style';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { FooterText } from 'variables';

export const FooterContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const Year = DateTime.now().year;

    return (
        <FooterContainer data-testid='FooterContent'>
            <FooterBody>
                <ContainerContent>
                    <FooterContentLeft>
                        <Typography variant='subtitle1' gutterBottom>
                            {FooterText} - {translate('site.copyright')}
                            {Year}
                        </Typography>
                    </FooterContentLeft>
                    <FooterContentRight>
                        <Link href='/privacy-policy' target='_blank' variant='subtitle1' underline='hover'>
                            {`${translate('t.privacy_policy')} `}
                        </Link>
                    </FooterContentRight>
                </ContainerContent>
            </FooterBody>
        </FooterContainer>
    );
};
