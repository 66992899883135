import React from 'react';
import UiWidget from 'components/Ui/Components/UiWidget';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import UiSimpleTable from 'components/Ui/Components/UiSimpleTable';
import { SECONDARY_DARK_MODE, WHITE } from '../../Ui/colors';
import { Theme } from 'states/global/Theme';
import { TabletActivationViewType } from './TabletActivation.type';
import { fetchFN } from './RequestFunctions';
import UiButton from 'components/Ui/Components/UiButton';
import useModal from 'components/CustomHooks/ModalHook';
import TabletActivationModal from './TabletActivationModal/TabletActivationModal';
import { SIZE_BUTTON } from 'components/Ui/variables';
import { QrCodeIsExpired } from './TabletActivation.atom';

const TabletActivationView = ({ columns }: TabletActivationViewType): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const { setModalState } = useModal();
    const setQrCodeIsExpired = useSetRecoilState(QrCodeIsExpired);

    return (
        <>
            <UiWidget
                id='tablet-list'
                testid='tablet-list'
                title={translate('t.tablets')}
                avatar={
                    <UiIcon
                        icon={['fas', 'tablet-android-alt']}
                        size='sm'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: SECONDARY_DARK_MODE,
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <>
                        <UiButton
                            testid='activate_tablet'
                            variant='contained'
                            skin='success'
                            size={SIZE_BUTTON}
                            onClick={() => {
                                setQrCodeIsExpired(false);
                                setModalState({
                                    isOpen: true,
                                    width: 600,
                                    height: 250,
                                    leftTitle: translate('t.tablet_activation'),
                                    replaceButtons: true,
                                    content: <TabletActivationModal />
                                });
                            }}
                        >
                            {translate('t.activate_tablet')}
                        </UiButton>
                        <UiSimpleTable
                            columns={columns}
                            fetchFn={fetchFN}
                            queryKey='Tablet-list'
                            defaultSorting={{ order: 'asc', orderBy: 'name' }}
                            usePaginator={false}
                            additionalFetchProps={{}}
                        />
                    </>
                }
            />
        </>
    );
};

export default TabletActivationView;
