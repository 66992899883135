import PortalApi from 'helpers/api/PortalApiClient';
import { MessageTable, SystemNotificationModel, SystemNotificationTableModel } from '../models/SystemNotification.type';
import { getParams } from 'helpers';

export default class SystemNotification extends PortalApi<SystemNotificationTableModel | SystemNotificationModel> {
    route = 'system-notification';

    getAll = async (props): Promise<SystemNotificationTableModel> => {
        return await this.getTable<MessageTable>({ criteria: getParams(props) });
    };
}
