import { Avatar, Card, CardActions, CardContent, Typography } from '@mui/material';
import { BACKGROUND_GREEN } from 'components/Ui/colors';
import styled from 'styled-components';
import UiButton from 'components/Ui/Components/UiButton/UiButton';

export const UserCardContainer = styled.div``;

export const CardCustom = styled(Card)`
    width: 130px;
    text-align: center;
    justifycontent: center;
`;

export const CardContentCustom = styled(CardContent)`
    padding: 8px 16px;
`;

export const AvatarCustom = styled(Avatar)`
    background-color: ${BACKGROUND_GREEN};
    width: 32px;
    height: 32px;
    font-size: 13px;
    margin: 0 auto;
    text-transform: capitalize;
`;

export const TypographyCustom = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    textoverflow: ellipsis;
    margin-top: 5px;
`;

export const CardActionsCustom = styled(CardActions)`
    padding: 0px 8px 8px 8px;
`;

export const DeleteButton = styled(UiButton)`
    &.MuiButton-root {
        line-height: 20px;
        padding: 3px 0px;
        margin: 0 auto;
    }
`;
