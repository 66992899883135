import React from 'react';
import * as view from './ButtonsToTestAlerts.view';
import * as type from './ButtonsToTestAlerts.type';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { TestAlert } from '../../../models/Util.type';
import Util from '../../../api/Util';
import { Errors, Success } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';
import * as style from './ButtonsToTestAlerts.style';

const UtilApi = new Util();

const ButtonsToTestAlerts: React.FC<type.ButtonsToTestAlertsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const { refetch: testEmailRefetch } = useQuery<TestAlert, Error, boolean, QueryKey>(
        ['TestEmailAlert'],
        () => UtilApi.getSetTestAlert('test-email'),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: false,
            retry: 0,
            onSuccess: (data) => {
                if (data) Success({ text: translate('p.test_email_alert_sent') });
            },
            onError: () => {
                Errors({ text: translate('t.server_error') });
            }
        }
    );

    const { refetch: testSMSrefetch } = useQuery<TestAlert, Error, boolean, QueryKey>(
        ['TestSMSAlert'],
        () => UtilApi.getSetTestAlert('test-sms'),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: false,
            retry: 0,
            onSuccess: (data) => {
                if (data) Success({ text: translate('p.test_sms_alert_sent') });
            },
            onError: () => {
                Errors({ text: translate('t.server_error') });
            }
        }
    );

    const { refetch: testWSrefetch } = useQuery<TestAlert, Error, boolean, QueryKey>(
        ['TestWhatsAppAlert'],
        () => UtilApi.getSetTestAlert('test-whatsapp'),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: false,
            retry: 0,
            onSuccess: (data) => {
                if (data) Success({ text: translate('p.test_whatsapp_alert_sent') });
            },
            onError: () => {
                Errors({ text: translate('t.server_error') });
            }
        }
    );

    const TestEmailAlert = (): void => {
        testEmailRefetch();
    };

    const TestSMSAlert = (): void => {
        testSMSrefetch();
    };

    const TestWhatsAppAlert = (): void => {
        testWSrefetch();
    };

    return (
        <>
            {props.accountSettings && props.isCurrentUser ? (
                <style.ButtonsToTestAlertsContent>
                    <style.FieldsetTestAlerts>
                        <legend>{translate('t.test_alerts')}</legend>
                        <style.ButtonsBox>
                            <view.ButtonsToTestAlertsContent
                                data-testid='ButtonsToTestAlerts-testid'
                                testEmailAlert={TestEmailAlert}
                                testSMSAlert={TestSMSAlert}
                                testWhatsAppAlert={TestWhatsAppAlert}
                                isCurrentUser={props.isCurrentUser}
                                disabledSMSAlert={props.disabledSMSAlert}
                                emailTestId={props.emailTestId}
                                smsTestId={props.smsTestId}
                                whatsappTestId={props.whatsappTestId}
                            />
                        </style.ButtonsBox>
                    </style.FieldsetTestAlerts>
                </style.ButtonsToTestAlertsContent>
            ) : (
                <view.ButtonsToTestAlertsContent
                    data-testid='ButtonsToTestAlerts-testid'
                    testEmailAlert={TestEmailAlert}
                    testSMSAlert={TestSMSAlert}
                    testWhatsAppAlert={TestWhatsAppAlert}
                    isCurrentUser={props.isCurrentUser}
                    disabledSMSAlert={props.disabledSMSAlert}
                    emailTestId={props.emailTestId}
                    smsTestId={props.smsTestId}
                    whatsappTestId={props.whatsappTestId}
                />
            )}
        </>
    );
};

export default ButtonsToTestAlerts;
