import React from 'react';
import { PrivacyPolicyContent } from './PrivacyPolicy.view';
import { PrivacyPolicyProps } from './PrivacyPolicy.type';
import { Wrapper } from 'helpers/wrapper';

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (): JSX.Element => {
    return <PrivacyPolicyContent data-testid={'PrivacyPolicy-testid'} />;
};

export default Wrapper(PrivacyPolicy);
