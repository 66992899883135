import Polygon from 'ol/geom/Polygon';
import { atom } from 'recoil';
import { HeatmapKeys, MapActionsEnum } from '../../components/Map/Map.type';
import { DateRangeData } from '../../components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { RouteLocation } from '../../models/Route.type';
import { AreaFeaturesResponse } from '../../models/Area.type';
import { Journey, JourneyData, LastPositionV2 } from '../../models/Vehicle.type';
import { DateTime } from 'luxon';
import { InspectionParams } from 'models/Sensors.type';
import { TracklogResponse } from 'models/ExternalSystemMddBesTrackLog.type';
import { GetSlopeInclinationDataParams, SlopeInclinationHeatmapData } from 'models/TrackLog.type';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : never;

type DateFromToVehiclesParams = {
    dateFrom?: DateTime;
    dateTo?: DateTime;
    vehicleIds: number[];
};

type DrawVehicleAtTimeParams = {
    time: number;
};

type ShowMessageParams = {
    message: string;
};

type DrawAreaParams = {
    data: { areaId: number } | AreaFeaturesResponse;
};

type VehiclePostionParams = {
    vehicles: LastPositionV2[];
    redrawAllVehicles: boolean;
};

export type DateFromToJourneyFaultyReportParams = InspectionParams & {
    vehicleId: number;
    showAllVehicle: boolean;
};

export type SlopeInclinationJourney = GetSlopeInclinationDataParams;

export type ResizeParams = {
    lastAction: MapActionType;
};

export type StatisticsLineParams = {
    vehicleId: number;
    dateFrom: string;
    dateTo: string;
    afterAction?: () => void;
};

export type RouteLineParams = {
    route: RouteLocation[];
    afterAction?: () => void;
};

export type DateFromToVehiclesMapConfig = {
    action: MapActionsEnum.LOAD_MULTIPLE_JOURNEY_LINE;
    params: DateFromToVehiclesParams;
};

export type NoParamConfig = {
    action:
        | MapActionsEnum.NO_ACTION
        | MapActionsEnum.CLEAN_DRAW_AREA
        | MapActionsEnum.DRAW_AREAS
        | MapActionsEnum.DRAW_HEATMAP
        | MapActionsEnum.CLEAN_AREAS
        | MapActionsEnum.DRAW_SPEED_INCIDENTS
        | MapActionsEnum.HIDE_MAP_MESSAGE
        | MapActionsEnum.DISASSEMBLE_MAP
        | MapActionsEnum.GET_BES_SEVERE_DRIVING_DATA
        | MapActionsEnum.SLOPE_INCLINATION_JOURNEY_UPDATE;
};

export type VehiclePositionConfig = {
    action: MapActionsEnum.DRAW_VEHICLES_POSITION;
    params: VehiclePostionParams;
};

export type ResizeMapConfig = {
    action: MapActionsEnum.RESIZE_MAP;
    params: ResizeParams;
};

export type StatisticsLineConfig = {
    action: MapActionsEnum.LOAD_JOURNEY_LINE_ON_STATISTICS;
    params: StatisticsLineParams;
};

export type RouteLineConfig = {
    action: MapActionsEnum.LOAD_JOURNEY_LINE;
    params: RouteLineParams;
};

export type DrawVehicleAtTimeConfig = {
    action: MapActionsEnum.DRAW_VEHICLE_AT_TIME;
    params: DrawVehicleAtTimeParams;
};

export type ShowMessageConfig = {
    action: MapActionsEnum.SHOW_MAP_MESSAGE;
    params: ShowMessageParams;
};

export type DrawArea = {
    action: MapActionsEnum.DRAW_AREA;
    params: DrawAreaParams;
};

export type BesSevereDrivingConfig = {
    action: MapActionsEnum.DRAW_BES_SEVERE_DRIVING;
    params: {
        zoom: number;
    };
};

export type DrawExcessiveAccConfig = {
    action: MapActionsEnum.DRAW_EXCESSIVE_ACC;
    params: DateFromToVehiclesParams;
};

export type DrawJourneyFaultyReport = {
    action: MapActionsEnum.DRAW_JOURNEY_FAULTY_REPORT;
    params: DateFromToJourneyFaultyReportParams;
};

export type DrawSlopeJourney = {
    action: MapActionsEnum.DRAW_SLOPE_JOURNEY;
    params: SlopeInclinationJourney;
};

export type MapActionType =
    | NoParamConfig
    | VehiclePositionConfig
    | ResizeMapConfig
    | StatisticsLineConfig
    | DrawVehicleAtTimeConfig
    | RouteLineConfig
    | DrawArea
    | DateFromToVehiclesMapConfig
    | DrawExcessiveAccConfig
    | ShowMessageConfig
    | DrawJourneyFaultyReport
    | BesSevereDrivingConfig
    | DrawSlopeJourney;

export const MapAction = atom<MapActionType>({
    key: 'MapAction',
    default: {
        action: MapActionsEnum.NO_ACTION
    }
});

export type MapActionDataType = {
    newArea?: ReturnType<Polygon['getCoordinates']>;
    selectedVehicles?: number[];
    heatmapDateRange?: DateRangeData;
    journey?: JourneyData[];
    clickedVehicle?: number;
    clickedSpeedIncident?: number;
    vehiclesPosition?: { [key: number]: ArrayElement<VehiclePostionParams['vehicles']> };
    vehicles?: Journey['lastPosition'][];
    zoomLevel?: number;
    vehicleIds?: number[];
    heatmapKey?: HeatmapKeys;
    heatmapLimit?: {
        min: number;
        max: number;
    };
    innerRadius?: number;
    outerRadius?: number;
    innerOpacity?: number;
    outerOpacity?: number;
    dotSize?: number;
    enableBlur?: boolean;
    besHasMapData?: boolean;
    besMapDataRequested?: boolean;
    besMapData?: TracklogResponse['externalSystemMddBesTrackLog'];
    besMapMode?: boolean;
    enableLoaded?: boolean;
    loaded?: number;
    loadedCounts?: {
        loaded: number;
        unloaded: number;
        total: number;
    };
    loadedCountsExs?: {
        loaded: {
            below: number;
            above: number;
        };
        unloaded: {
            below: number;
            above: number;
        };
        total: number;
    };
    vehicleOperator?: number | null;
    odometerSum?: number;
    satellites?: number;
    slopeAngle?: number;
    slopeSpeedAvg?: number;
    slopeColor?: string;
    useSlopeGradient?: boolean;
    slopeLevels?: [number, number, number];
    slopeData?: SlopeInclinationHeatmapData[];
};

export const MapActionData = atom<MapActionDataType>({
    key: 'MapActiondata',
    default: {}
});

export const LoadingMapdata = atom<boolean>({
    key: 'LoadingMapData',
    default: false
});

export type MapProps = {
    enableDrawing: boolean;
    enableEditingArea: boolean;
};

export const IsBesMap = atom<boolean>({
    key: 'IsBesMap',
    default: false
});

export const LoadedStatusAllowed = atom<boolean | null>({
    key: 'LoadedStatusAllowed',
    default: null
});
