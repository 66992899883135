import React from 'react';
import {
    Container,
    UITableCell,
    NoVehicleIcon,
    StatusIcon,
    StyledTableRow,
    TdSubTable,
    UiHighPressureIcon,
    UiLowPressureIcon
} from './VehicleStatsRow.style';
import { VehicleStatsRowViewProps } from './VehicleStatsRow.type';
import { TableCell, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import HubConnectionIndicator from 'components/Vehicle/HubConnectionIndicator/HubConnectionIndicator';
import HubBatteryIndicator from 'components/Vehicle/HubBatteryIndicator/HubBatteryIndicator';
import { BESRank } from './BESRank';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { faChartMixedUpCircleCurrency, faThermometerThreeQuarters } from '@fortawesome/pro-solid-svg-icons';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { getImgSource } from 'helpers/image';
import VehicleStatsCollapseButton from 'components/Vehicle/VehicleStatsCollapseButton';
import VehicleStatsCollapseTable from 'components/Vehicle/VehicleStatsCollapseTable';
import { CollapseVehicleStatsRow } from 'components/Vehicle/VehicleStatsCollapseButton/VehicleStatsCollapseButton.atom';
import { FilterPeriod } from '../../VehicleStats.atom';
import GpsIndicator from 'components/Vehicle/VehicleStatusWidget/Components/GpsIndicator/GpsIndicator';
import { DateTime } from 'luxon';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';

export const VehicleStatsRowContent: React.FC<VehicleStatsRowViewProps> = ({
    row,
    vehicleAlerts,
    alertDetailData
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const { convertType, fromServerToUserUnit } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const theme = useRecoilValue(Theme);
    const setCollapsedVehicleRow = useSetRecoilState(CollapseVehicleStatsRow);
    const period = useRecoilValue(FilterPeriod);

    const collapseRow = () => {
        setCollapsedVehicleRow((current) => (current === row.id ? 0 : row.id));
    };

    return (
        <>
            <StyledTableRow data-testid={`vehicleStats-row-${row.id}`} alertlevel={row.alert ?? 0} title={`${row.id}`}>
                <TableCell
                    className='firstTd'
                    title={translate('t.vehicle_details')}
                    data-testid={`vehicleStats-row-operated-hours-title-${row.id}`}
                >
                    <Container display='contents'>
                        <span>
                            <VehicleStatsCollapseButton testId={`expand-action-${row.id}`} vehicleId={row.id} />
                        </span>
                        {row.icon && getImgSource(`${row.icon}`) ? (
                            <img
                                alt={`${row.id}`}
                                src={getImgSource(`${row.icon}`)}
                                width={theme.easyReadingMode ? '30px' : '25px'}
                            />
                        ) : (
                            <NoVehicleIcon>
                                <FontAwesomeIcon
                                    icon={['fas', 'ban']}
                                    size='sm'
                                    color={applyStyleByMode({
                                        styleOld: '#777',
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                />
                            </NoVehicleIcon>
                        )}
                        <UiLink
                            $className='linkVehicleName'
                            color='#40FFA6'
                            content={row.name}
                            title={row.name}
                            url={`/vehicle-status?vehicle=${row.id}`}
                            testId={`VehicleStats-VehicleName-${row.id}-link`}
                        />
                    </Container>
                </TableCell>
                <TableCell
                    align='justify'
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-HubConnectionIndicator-${row.id}`}
                >
                    <Container display='inline-flex'>
                        <StatusIcon>
                            <HubConnectionIndicator
                                lastTimeConnected={row.vehicleInfo.measuredAt as string}
                                connectionType={'wifi'}
                            />
                        </StatusIcon>
                        {/* Temporary disabled for BE */}
                        {/* <StatusIcon $hide={!(row?.parkingTime > 0)}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'parking']}
                                size='sm'
                            />
                        </StatusIcon> */}
                        <StatusIcon $hide={!!row.deviceId}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'rectangle-xmark']}
                                size='sm'
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!row.maintenance}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'wrench']}
                                size='sm'
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!row.deviceId || row.vehicleInfo.battery === -1}>
                            <HubBatteryIndicator
                                battery={row.vehicleInfo.battery}
                                powerSource={row.vehicleInfo.powerSource}
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!row.shutdown}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'power-off']}
                                size='sm'
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!vehicleAlerts.temperature && row.genericTemperatureAlert}>
                            {row.genericTemperatureAlert ? (
                                <FontAwesomeIcon
                                    icon={faThermometerThreeQuarters}
                                    fixedWidth
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#FF1616'
                                    })}
                                />
                            ) : (
                                customerSettings?.temperature_prediction &&
                                row.predictionTemperature && (
                                    <span className='fa-layers fa-fw'>
                                        <FontAwesomeIcon
                                            icon={faSlashRegular}
                                            fontSize='16px'
                                            color={applyStyleByMode({
                                                styleOld: '#FF1616',
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                        <FontAwesomeIcon
                                            icon={faThermometerThreeQuarters}
                                            fixedWidth
                                            fontSize='16px'
                                            color={applyStyleByMode({
                                                styleOld: '#FF1616',
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                    </span>
                                )
                            )}
                        </StatusIcon>
                        <StatusIcon $hide={!vehicleAlerts.highPressure} $pressureTemperatureIcon $display='flex'>
                            <UiHighPressureIcon color='#FFF' />
                        </StatusIcon>
                        <StatusIcon $hide={!vehicleAlerts.lowPressure} $pressureTemperatureIcon $display='flex'>
                            <UiLowPressureIcon />
                        </StatusIcon>
                        <StatusIcon>
                            <GpsIndicator
                                powerSource={row.vehicleInfo.powerSource}
                                deviceId={null}
                                lastGpsMeasuredAt={DateTime.fromISO(row.vehicleInfo.lastGpsMeasuredAt)}
                                maintenance={Boolean(row.maintenance)}
                                outOfService={Boolean(row.outOfService)}
                                measuredAt={DateTime.fromISO(row.vehicleInfo.measuredAt)}
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!vehicleAlerts.temperaturePrediction}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={faChartMixedUpCircleCurrency}
                                size='sm'
                            />
                        </StatusIcon>
                        <StatusIcon $hide={!row.predictionTemperature}>
                            <TemperaturePredictionPeriodIcon
                                time={row.lastTemperaturePredictedAt}
                                predictionTemperature={row.predictionTemperature}
                                wheels={row.wheels}
                            />
                        </StatusIcon>
                    </Container>
                </TableCell>
                {customerSettings.mdd_bes && (
                    <TableCell title='' data-testid={`vehicleStats-row-operated-hours-BESRank-${row.id}`}>
                        {!!row.maxBesSeverityRankTotal && (
                            <BESRank maxBesSeverityRankTotal={row.maxBesSeverityRankTotal} vehicleId={row.id} />
                        )}
                    </TableCell>
                )}
                <TableCell
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-alerts-in-the-last-${row.id}`}
                    align='right'
                    title={`${alertDetailData.totalValues.current} ${translate(
                        't.alerts_in_the_last'
                    )} ${period} ${translate('t.hours')}`}
                >
                    {alertDetailData.totalValues.current}
                </TableCell>
                <TableCell
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-alerts-previously-to-${row.id}`}
                    align='right'
                    title={`${alertDetailData.totalValues.previous} ${translate(
                        't.alerts_previously_to'
                    )} ${period} ${translate('t.hours')}`}
                >
                    {alertDetailData.totalValues.previous}
                </TableCell>

                <UITableCell
                    onClick={collapseRow}
                    $align='right'
                    data-testid={`vehicleStats-row-operated-hours-distance-${row.id}`}
                >
                    {fromServerToUserUnit({
                        type: convertType.altitude,
                        value: row.odometer ?? 0,
                        displayUnits: true,
                        fixed: 1,
                        toType: convertType.distance
                    })}
                </UITableCell>
            </StyledTableRow>
            <TableRow>
                <TdSubTable colSpan={9}>
                    <VehicleStatsCollapseTable vehicleId={row.id} alertDetailData={alertDetailData} />
                </TdSubTable>
            </TableRow>
        </>
    );
};
