import React from 'react';
import { DateTimePicker } from '../../states/global/Statistics';
import { useRecoilValue } from 'recoil';
import useConverter from '../CustomHooks/Converter/Converter';
import { StatisticsSummaryProps } from './StatisticsSummary.type';
import { StatisticsSummaryContent } from './StatisticsSummary.view';
import { Wrapper } from 'helpers/wrapper';

const StatisticsSummary: React.FC<StatisticsSummaryProps> = (props): JSX.Element => {
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const { secondToDateTime } = useConverter();
    const dateDifference: string = secondToDateTime(
        statisticsDateTime.current.dateTo.diff(statisticsDateTime.current.dateFrom, 'seconds').toObject().seconds
    );

    return (
        <StatisticsSummaryContent {...props} dateDifference={dateDifference} data-testid='StatisticsSummary-testid' />
    );
};

export default Wrapper(StatisticsSummary);
