import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SeverityRankGraphViewContent } from './SeverityRankGraph.style';
import { SeverityRankGraphViewProps } from './SeverityRankGraph.type';
import { useTranslation } from 'react-i18next';
import Graph from '../../Graph';
import UiWidget from '../../Ui/Components/UiWidget';
import { BesRankTicks, RankDateTimePicker } from '../../../pages/SeverityRank/atom';
import { yDomainConfigType } from '../../StatisticsGraphs/StatisticsGraphs.type';
import { xDomainConfig } from '../../Graph/Graph.type';
import useConverter from '../../CustomHooks/Converter/Converter';
import { generateYConfig } from 'helpers';
import { CustomerSettings } from 'states/global/CustomerSettings';

let xDomainConf: (besName?: string) => xDomainConfig[] = (besName) => [
    {
        dataKey: 'severityMeasurePast1Month',
        yAxisId: '1',
        name: `t.tyre_severity_for_${besName?.toLocaleLowerCase()}`,
        showDot: false,
        stroke: '#8A4500',
        strokeWidth: 3
    },
    {
        dataKey: 'severityMeasurePast1Week',
        yAxisId: '1',
        name: 't.last_week_severity',
        showDot: false,
        stroke: '#B502B5',
        strokeWidth: 3
    },
    {
        dataKey: 'createdAtConverted',
        yAxisId: 'hiddenData',
        name: 't.date',
        showDot: false,
        hide: true
    }
];

const yDomainConf: yDomainConfigType[] = [
    {
        id: '1',
        name: 't.tyre_severity',
        isShared: true
    }
];

export const SeverityRankGraphContent: React.FC<SeverityRankGraphViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const rankTicks = useRecoilValue(BesRankTicks);
    const { fromUTCtoUserTimezone } = useConverter();
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(RankDateTimePicker);
    const customerSettings = useRecoilValue(CustomerSettings);
    const scale = {
        left: [-0.2, 1.2],
        right: [-0.2, 1.2]
    };

    const yConfig = useCallback(() => {
        return generateYConfig(yDomainConf, 0.2, scale).map((item) => ({
            ...item,
            ticks: item.ticks?.map((tick) => parseFloat(tick.toFixed(1)))
        }));
    }, [yDomainConf, scale]);

    const date = useCallback(() => {
        let fakeDate = {
            current: {
                dateFrom: undefined,
                dateTo: undefined
            },
            original: {
                dateFrom: undefined,
                dateTo: undefined
            }
        };
        fakeDate.current.dateFrom = globalDateTimePicker.current.dateFrom.startOf('day');
        fakeDate.current.dateTo = globalDateTimePicker.current.dateTo.startOf('day');
        fakeDate.original.dateFrom = globalDateTimePicker.original.dateFrom.startOf('day');
        fakeDate.original.dateTo = globalDateTimePicker.original.dateTo.startOf('day');

        return fakeDate;
    }, [globalDateTimePicker]);

    return (
        <SeverityRankGraphViewContent>
            <UiWidget
                data-testid='SeverityRankGraphContent'
                id='SeverityRankGraphWidget'
                title={`${customerSettings.mdd_bes_display_name.toUpperCase()} ${translate('t.tyre_severity')} ${
                    isNaN(props.tyreCustomPosition) ? '' : `- ${translate('t.tyre')} ${props.tyreCustomPosition}`
                }`}
                collapseButton={false}
                $contentHeight={props.newMode ? 240 : undefined}
            >
                <Graph
                    xDomainConfig={xDomainConf(customerSettings.mdd_bes_display_name.toUpperCase())}
                    yDomainConfig={yConfig()}
                    globalXDomain={date()}
                    setGlobalXDomain={setGlobalDateTimePicker}
                    fetchData={(): Promise<unknown> => {
                        return props.getRankData();
                    }}
                    yTickFormatter={(tickItem: number): string => {
                        return tickItem % 1 !== 0 ? '' : `${tickItem}`;
                    }}
                    xTicks={rankTicks}
                    xTickGap={0}
                    xTickFormatter={(tickItem: number) => {
                        return fromUTCtoUserTimezone({ date: tickItem * 1000, format: 'date', displaySeconds: false });
                    }}
                />
            </UiWidget>
        </SeverityRankGraphViewContent>
    );
};
