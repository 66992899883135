import React from 'react';
import { TemperatureExposureWheelReportContent } from './TemperatureExposureWheelReport.view';
import { TemperatureExposureWheelReportProps } from './TemperatureExposureWheelReport.type';
import { useQuery } from '@tanstack/react-query';
import SensorLogApi from '../../../api/SensorLog';
import useConverter from '../../CustomHooks/Converter/Converter';
import { TemperatureExposureDate } from '../TemperatureExposureTopbar/TemperatureExposureTopbar.atom';
import { useRecoilValue } from 'recoil';
import { Wrapper } from 'helpers/wrapper';

const SensorLog = new SensorLogApi();

const TemperatureExposureWheelReport: React.FC<TemperatureExposureWheelReportProps> = (props): JSX.Element => {
    const { fromTimezoneToUTC } = useConverter();
    const dateRange = useRecoilValue(TemperatureExposureDate);

    const { data } = useQuery(
        [`temperatureExposureWheelReport${props.position}`],
        () => {
            if (props.wheelId) {
                return SensorLog.getTemperatureExposureByWheel({
                    wheelId: props.wheelId,
                    dateFrom: fromTimezoneToUTC(dateRange.dateFrom),
                    dateTo: fromTimezoneToUTC(dateRange.dateTo)
                });
            }
        },
        { enabled: true }
    );

    return (
        <TemperatureExposureWheelReportContent
            {...props}
            data={
                data ?? {
                    temperatureCategories: {},
                    temperatureExposureReport: []
                }
            }
            data-testid='TemperatureExposureWheelReport-testid'
        />
    );
};

export default Wrapper(TemperatureExposureWheelReport);
