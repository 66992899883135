import { GridFieldFormik } from 'components/User/ProfileTabManager/ProfileTabManager.style';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CurrentTimeFormikType, InicialValuesForm } from './CurrentTime.type';
import { ButtonForm } from './CurrentTime.style';
import { getListTimeFormat, getTimezoneOffsetFormatted } from 'helpers';

export const CurrentTimeFormik: React.FC<CurrentTimeFormikType> = ({
    inicialValuesFormik,
    handleSubmit,
    timeZoneData
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidate = object({
        timeFormat: string().required(translate('p.this_field_is_required')),
        timezone: string().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={inicialValuesFormik}
            onSubmit={(values: InicialValuesForm, actions) => {
                handleSubmit(values, actions.setSubmitting);
            }}
            validationSchema={schemaValidate}
        >
            {(propsF: FormikProps<InicialValuesForm>) => {
                const { errors, setFieldValue, values, isSubmitting } = propsF;

                return (
                    <Form>
                        <Grid container justifyContent='flex-end' direction='row'>
                            <GridFieldFormik item>
                                <Autocomplete
                                    options={getListTimeFormat()}
                                    getOptionLabel={(timeFormat) => timeFormat.name}
                                    onChange={(e, timeFormat) => {
                                        setFieldValue('timeFormat', timeFormat ? timeFormat.value : null);
                                    }}
                                    defaultValue={getListTimeFormat().find(
                                        (timeFormat) => timeFormat.value === values.timeFormat ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.time_format')}
                                            variant='outlined'
                                            name='timeFormat'
                                            value={values.timeFormat}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.timeFormat && translate('p.this_field_is_required')}
                                            error={!!errors.timeFormat}
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            {values.useMineTimezoneFormik === 'false' && (
                                <GridFieldFormik item>
                                    <Autocomplete
                                        options={timeZoneData ?? []}
                                        getOptionLabel={(timezone) => {
                                            const offset = getTimezoneOffsetFormatted(timezone.timezoneName);
                                            return `${offset} ${timezone.timezoneFormatted}`;
                                        }}
                                        onChange={(e, timezone) => {
                                            setFieldValue('timezone', timezone ? timezone?.id : null);
                                        }}
                                        defaultValue={
                                            timeZoneData && !!timeZoneData.length
                                                ? timeZoneData.find((timezone) => timezone?.id === values.timezone)
                                                : null
                                        }
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.local_timezone')}
                                                variant='outlined'
                                                name='timezone'
                                                value={values.timezone}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                helperText={errors.timezone && translate('p.this_field_is_required')}
                                                error={!!errors.timezone}
                                                data-testid='LocalLimezone-CurrentTime-Autocomplete'
                                            />
                                        )}
                                    />
                                </GridFieldFormik>
                            )}

                            <Grid item>
                                <RadioGroup
                                    aria-label='showTime'
                                    name='useMineTimezone-currentTime'
                                    value={values.useMineTimezoneFormik}
                                    onChange={(e) => {
                                        setFieldValue('useMineTimezoneFormik', (e.target as HTMLInputElement).value);
                                    }}
                                >
                                    <FormControlLabel
                                        value='true'
                                        control={<Radio color='primary' />}
                                        label={translate('t.mine_time')}
                                        data-testid='CurrentTime-ShowTimesInTheTimezoneOfTheMine-Radio-true'
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    aria-label='showTime'
                                    name='useMineTimezone'
                                    value={values.useMineTimezoneFormik}
                                    onChange={(e) => {
                                        setFieldValue('useMineTimezoneFormik', (e.target as HTMLInputElement).value);
                                    }}
                                >
                                    <FormControlLabel
                                        value='false'
                                        control={<Radio color='primary' />}
                                        label={translate('t.my_time')}
                                        data-testid='CurrentTime-ShowTimesInTheTimezoneOfTheMine-Radio-false'
                                    />
                                </RadioGroup>
                            </Grid>

                            <Grid item sx={{ marginRight: '5px' }}>
                                <ButtonForm
                                    size='small'
                                    variant='contained'
                                    type='submit'
                                    disabled={isSubmitting}
                                    skin='success'
                                >
                                    {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                </ButtonForm>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
