import Menu from '@mui/material/Menu';
import styled from 'styled-components';
import { BACKGROUND_BLUE, BACKGROUND_TABLE, BACKGROUND_TABLE_DARK } from '../../colors';
import { useTheme } from '@mui/material';
import { ThemeMode } from '../../../../states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiMenuContent = styled.div`
    display: inline-block;
`;

export const UiMenuCover = styled.div`
    display: inline-block;
`;

export const CustomMenu = styled(Menu)<{ title }>`
    & .MuiList-root {
        padding-top: ${(props) => (props.title ? 0 : '8px')};
        overflow-y: visible !important;
        max-height: 1000px;

        & :hover {
            background: ${() =>
                useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_TABLE_DARK : BACKGROUND_TABLE};
        }
    }
`;

export const HeaderMenu = styled.div`
    padding: 8px 16px;

    & :hover {
        background: #000;
    }

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            color: ${useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : BACKGROUND_BLUE};
            font-size: ${common.titleSize};
            font-weight: 600;
        `;
    }}
`;

export const HeaderDivider = styled.div`
    width: 90%;
    margin: 0 auto;
    background-color: #c5c5c5;
    height: 1px;
`;
