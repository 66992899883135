import styled from 'styled-components';
import Box from '@mui/material/Box';
import { applyStyleByMode } from 'helpers';

export const SeverityRankTopbarViewContent = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
    ${applyStyleByMode({
        styleOld: 'margin-top: 10px;'
    })}
`;
