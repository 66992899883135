import { RecoilState, atom } from 'recoil';

let refreshVehicleHoverAtoms = {};

const AtomInstance = <T>(defaultValue: T, defaultKey: string, key?: string): RecoilState<T> => {
    if (key && !refreshVehicleHoverAtoms[key]) {
        refreshVehicleHoverAtoms[key] = atom<T>({
            key: key,
            default: defaultValue
        });
    } else if (!key && !refreshVehicleHoverAtoms[defaultKey]) {
        refreshVehicleHoverAtoms[defaultKey] = atom<T>({
            key: defaultKey,
            default: defaultValue
        });
    }

    return refreshVehicleHoverAtoms[key ?? defaultKey];
};

export const VehicleSchemaHoverAtom = (vehicleSchemaHoverKey?) =>
    AtomInstance<{ hoveredPosition: number; hasHover: boolean }>(
        { hoveredPosition: 0, hasHover: false },
        'VehicleSchemaHoverAtom',
        vehicleSchemaHoverKey
    );
