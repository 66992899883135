import React, { useEffect } from 'react';
import { DataExportTopBarProps } from './TopBar.type';
import { DataExportTopBarContent } from './TopBar.view';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { DropDownProps, TopBarAtom } from './TopBar.atom';
import { RefreshSimpleTable } from '../../../Ui/Components/UiSimpleTable/UiSimpleTable.atom';
import { DateTime } from 'luxon';

const TopBar: React.FC<DataExportTopBarProps> = (props): JSX.Element => {
    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const refreshTable = useSetRecoilState(RefreshSimpleTable);
    const resetTopBarAtom = useResetRecoilState(TopBarAtom);
    const dateFrom: DateTime = DateTime.local().minus({ days: 1 });

    const defaultTopBarValues: DropDownProps = {
        vehicle: {
            name: '',
            active: false,
            id: -1,
            icon: ''
        },
        dateFrom: dateFrom.set({ second: 0 }),
        dateTo: DateTime.local(),
        loaded: -1
    };

    useEffect(() => {
        refreshTable(true);
    }, [topBarAtom]);

    useEffect(() => {
        setTopBarAtom(defaultTopBarValues);
        return () => {
            resetTopBarAtom();
        };
    }, []);

    return <DataExportTopBarContent {...props} data-testid='TopBar-testid' />;
};

TopBar.defaultProps = { showDatePickers: true };

export default TopBar;
