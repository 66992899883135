import React, { useReducer } from 'react';
import ReactHowler from 'react-howler';
import { ButtonShowNotificationsContent } from './ButtonShowNotifications.view';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { ButtonShowNotificationsProps, MqttMessage, NotifcationActions } from './ButtonShowNotifications.type';
import { FloatAlertNotifications } from 'components/AlertNotification/FloatAlertNotifications/FloatAlertNotifications';
import { User } from 'states/global/User';
import TpmsAlertsQuery from 'components/Queries/TpmsAlertQuery/TpmsAlertQuery';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InicialStateNotification, NotificationReducer } from './ButtonShowNotifications.reducer';
import { getSoundSource } from 'helpers/sound';
import useMqtt from 'helpers/mqtt/useMqtt';
import NotificationEvent from 'api/NotificationEvent';
import { NotificationTyre } from 'models/NotificationEvent.type';

const notificationEventAPI = new NotificationEvent();

const ButtonShowNotifications: React.FC<ButtonShowNotificationsProps> = ({ MqttData }): JSX.Element => {
    const user = useRecoilValue(User);
    const { message } = useMqtt(
        MqttData.config.username,
        MqttData.config.password,
        MqttData.config.client,
        MqttData.topics
    );
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(NotificationReducer, InicialStateNotification);
    const customConfigReactQuery = {
        refetchOnWindowFocus: false
    };

    const setNotificationPulse = (pulse: boolean) => {
        dispatch({
            type: NotifcationActions.SET_NOTIFICATION_PULSE,
            payload: pulse
        });
    };

    useQuery(
        ['notificationTyre', state.notificationId],
        () => notificationEventAPI.get<NotificationTyre>({ extendUrl: `${state.notificationId}` }),
        {
            enabled: !!state.notificationId,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                if (Object.entries(data).length) {
                    const { notificationEvent } = data;

                    queryClient.invalidateQueries(['topBarAlerts']);
                    if (!user?.userSetting.muteNotification) {
                        FloatAlertNotifications({ notificationEvent, markAsRead });
                        dispatch({
                            type: NotifcationActions.SET_NOTIFICATION_GENERAL,
                            payload: {
                                notificationId: undefined,
                                notificationPulse: true,
                                notificationSound: true
                            }
                        });

                        setTimeout(() => {
                            dispatch({
                                type: NotifcationActions.SET_NOTIFICATION_SOUND,
                                payload: false
                            });
                        }, 3000);
                    }
                }
            }
        }
    );

    const markAsRead = async (event: React.MouseEvent, notificationId: number): Promise<void> => {
        event.stopPropagation();
        toast.dismiss(`float-alert-${notificationId}`);
        dispatch({
            type: NotifcationActions.SET_NOTIFICATION_PULSE,
            payload: false
        });
    };

    if (message) {
        const messageObject: MqttMessage = JSON.parse(message);
        if (state.notificationId !== messageObject.id) {
            dispatch({
                type: NotifcationActions.SET_NOTIFICATION_ID,
                payload: messageObject.id
            });
        }
    }

    return (
        <>
            <TpmsAlertsQuery
                keyQuery='topBarAlerts'
                currentPage={1}
                limit={150}
                solved={false}
                customConfig={customConfigReactQuery}
                tab='tpms'
            >
                {({ data, isLoading }) => (
                    <>
                        <ButtonShowNotificationsContent
                            data-testid={'ButtonShowNotifications-testid'}
                            muteNotification={user?.userSetting.muteNotification ?? false}
                            alertsData={data}
                            alertsIsLoading={isLoading}
                            notificationPulse={state.notificationPulse}
                            setNotificationPulse={setNotificationPulse}
                        />
                    </>
                )}
            </TpmsAlertsQuery>

            {state.notificationSound && <ReactHowler src={getSoundSource('alert')} playing={state.notificationSound} />}
        </>
    );
};

export default ButtonShowNotifications;
