import React from 'react';
import { AddTyreViewContent, DialogContainer } from './AddTyre.style';
import DialogActions from '../../StatedModal/DialogActions/DialogActions';
import { useRecoilState } from 'recoil';
import { AddTyreViewProps, InitialValues } from './AddTyre.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, TextField } from '@mui/material';
import DropDown from '../../Ui/Components/UiDropDown';
import UiDatePicker from '../../Ui/Components/UiDatePicker';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import WheelDropDown from '../WheelDropDown/WheelDropDown';
import UiButton from '../../Ui/Components/UiButton';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from '../../../states/global/Modal';
import { CancelButton } from '../../StatedModal/Modal/Modal.style';
import { DateTime } from 'luxon';
import { VARIANT_BUTTON } from '../../Ui/variables';

export const AddTyreContent: React.FC<AddTyreViewProps> = (props): JSX.Element => {
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({
        tyreSerialNumber: Yup.string().trim().required(translate('p.this_field_is_required')),
        vehicle: Yup.string().nullable(),
        wheel: Yup.string().nullable(),
        date: Yup.number().required(translate('p.this_field_is_required'))
    });

    return (
        <AddTyreViewContent data-testid='AddTyreContent'>
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values: InitialValues) => {
                    props.handleSubmit(values);
                }}
                validationSchema={schemaValidateForm}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, setFieldValue, values } = propsFormik;

                    const setActionData = (newData: DateTime | null) => {
                        newData && setFieldValue('date', newData.valueOf());
                    };

                    return (
                        <>
                            <Form id='tyre-action-form'>
                                <Grid container justify-content='flex-start' direction='row'>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextField
                                            id='tyreSerialNumber'
                                            data-testid={'AddTyre-tyreSerialNumber-textfield'}
                                            label={translate('t.serial_number')}
                                            value={values.tyreSerialNumber}
                                            variant='outlined'
                                            size='small'
                                            name='tyreSerialNumber'
                                            error={
                                                (!!propsFormik.errors.tyreSerialNumber &&
                                                    !!propsFormik.touched.tyreSerialNumber) ||
                                                !props.isSerialNumberUnique
                                            }
                                            onBlur={() => {
                                                props.checkUniqSerialNumber(values.tyreSerialNumber);
                                            }}
                                            type='text'
                                            fullWidth
                                            onChange={propsFormik.handleChange}
                                            inputProps={{ step: 0.1, min: 0 }}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={
                                                !props.isSerialNumberUnique
                                                    ? translate('t.tyre_serial_number_exists')
                                                    : propsFormik.errors.tyreSerialNumber
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {props.vehiclesLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <DropDown
                                                options={props.vehicles}
                                                inputName='vehicle'
                                                label={translate('t.vehicle')}
                                                testid={'AddTyre-vehicle-list-dropdown'}
                                                value={values.vehicle ?? null}
                                                error={errors.vehicle}
                                                disabled={false}
                                                getValue={(e) => {
                                                    setFieldValue('wheel', null);
                                                    setFieldValue('vehicle', e ?? null);
                                                }}
                                                defaultValue={values.vehicle ?? null}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <UiDatePicker
                                            label={translate('t.action_date')}
                                            value={DateTime.fromMillis(values.date || NaN)}
                                            onDateChange={setActionData}
                                            testId='AddTyre-action-date-picker'
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <WheelDropDown
                                            vehicleId={values.vehicle}
                                            value={values.wheel}
                                            error={errors.wheel}
                                            testid={'AddTyre-wheel-list-dropdown'}
                                            getValue={(e) => setFieldValue('wheel', e ?? null)}
                                            defaultValue={values.wheel ?? null}
                                            customLabel={translate('t.position')}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextField
                                            id='tyreComment'
                                            label={translate('t.comment')}
                                            data-testid={'AddTyre-comment-textfield'}
                                            value={values.comment}
                                            variant='outlined'
                                            size='small'
                                            name='tyreComment'
                                            type='text'
                                            fullWidth
                                            onChange={(newValue) => {
                                                setFieldValue('comment', newValue.target.value ?? '');
                                            }}
                                            inputProps={{ step: 0.1, min: 0 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                            <DialogContainer>
                                <DialogActions>
                                    <>
                                        <UiButton
                                            skin='success'
                                            form='tyre-action-form'
                                            color='primary'
                                            testid='AddTyre-add-button'
                                            variant={VARIANT_BUTTON}
                                            type='submit'
                                            disabled={
                                                !props.isSerialNumberUnique ||
                                                props.addTyreLoading ||
                                                !(propsFormik.dirty && propsFormik.isValid)
                                            }
                                        >
                                            {props.addTyreLoading ? (
                                                <CircularProgress size='1.75em' />
                                            ) : (
                                                translate('t.add')
                                            )}
                                        </UiButton>
                                        <CancelButton
                                            data-testid='AddTyre-cancel-button'
                                            onClick={() => {
                                                modalDispatch({
                                                    type: ModalActionTypesEnum.CLOSE_MODAL
                                                });
                                            }}
                                        >
                                            {translate('t.cancel')}
                                        </CancelButton>
                                    </>
                                </DialogActions>
                            </DialogContainer>
                        </>
                    );
                }}
            </Formik>
        </AddTyreViewContent>
    );
};
