import React, { useCallback, useEffect } from 'react';
import { SystemTotalsContent } from './SystemTotals.view';
import { useRecoilState } from 'recoil';
import { SystemTotalsStage } from './SystemTotals.atom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { Wrapper } from 'helpers/wrapper';

const SystemTotals = () => {
    const [systemTotalsStage, setSystemTotalsStage] = useRecoilState(SystemTotalsStage);
    const { getUserSetting } = useUserLocalStorageSettingsTable();
    const { setUserSettings } = useUserLocalStorageSettings([]);

    useEffect(() => {
        setSystemTotalsStage(getUserSetting('systemTotalsStage') ?? 0);
    }, [getUserSetting, setSystemTotalsStage]);

    const handleStageChange = useCallback((): void => {
        const newValue = (systemTotalsStage + 1) % 3;
        setUserSettings('systemTotalsStage', newValue);
        setSystemTotalsStage(newValue);
    }, [setSystemTotalsStage, setUserSettings, systemTotalsStage]);

    return <SystemTotalsContent handleStageChange={handleStageChange} />;
};

export default Wrapper(SystemTotals);
