import React from 'react';
import { DividerStyle, HeaderSubTable, TitleMainSubTable } from './VehicleStatsRow.style';
import { VehicleStatusModel, VehicleStatusRowType, VehicleStatusSubTableProps } from './VehicleStatsRow.type';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import TrackLog from '../../../../../api/TrackLog';
import UiCircularProgressContent from '../../../../Ui/Components/UiCircularProgress/UiCircularProgress';
import useConverter from '../../../../CustomHooks/Converter/Converter';
import { FilterPeriod } from '../../VehicleStats.atom';
import { useRecoilValue } from 'recoil';
import { Wrapper } from 'helpers/wrapper';

const TrackLogApi = new TrackLog();

const VehicleStatusRow: React.FC<VehicleStatusRowType> = ({ title, value }): JSX.Element => (
    <TableRow>
        <HeaderSubTable colSpan={3}>{title}:</HeaderSubTable>
        <TableCell>{value}</TableCell>
    </TableRow>
);

const VehicleStatusSubTable: React.FC<VehicleStatusSubTableProps> = ({ vehicleId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromServerToUserUnit, convertType, secondToDateTime } = useConverter();
    const period = useRecoilValue(FilterPeriod);

    const { data: VehicleStatsDetailData, error } = useQuery<VehicleStatusModel, Error>(
        [`VehicleStatsDetail${vehicleId}`],
        () => TrackLogApi.getVehicleStatusDetail({ period }, vehicleId),
        {
            refetchOnWindowFocus: false
        }
    );

    return VehicleStatsDetailData ? (
        !error ? (
            <>
                <TitleMainSubTable> {translate('t.vehicle_status')} </TitleMainSubTable>
                <DividerStyle />

                <Table size='small' aria-label='purchases'>
                    <TableBody>
                        {/* To Do */}
                        {/* It is hidden because CMD 2 was replaced by CMD 27 which still does not provide this data, it will be shown again when the Hubs are upgraded to v 2.0.1. */}
                        {false && (
                            <VehicleStatusRow
                                title={translate('t.operated_hours')}
                                value={secondToDateTime(
                                    VehicleStatsDetailData?.vehicleStatsDetail.operationTime.operatingTime ?? 0,
                                    { day: false }
                                )}
                            />
                        )}
                        <VehicleStatusRow
                            title={translate('t.average_speed')}
                            value={fromServerToUserUnit({
                                type: convertType.speed,
                                value: VehicleStatsDetailData.vehicleStatsDetail.avgSpeed ?? 0,
                                displayUnits: true,
                                fixed: 2
                            })}
                        />

                        {/* To Do */}
                        {/* Elevation doesn’t seems to be accurate enough. Until we have some better validation please hide (remove) this information. (BY MARTIN) */}
                        {false && (
                            <VehicleStatusRow
                                title={`${translate('t.ascend')} / ${translate('t.descend')}`}
                                value={`${fromServerToUserUnit({
                                    type: convertType.altitude,
                                    value: VehicleStatsDetailData?.vehicleStatsDetail.ascend ?? 0,
                                    displayUnits: true,
                                    fixed: 2
                                })} / ${fromServerToUserUnit({
                                    type: convertType.altitude,
                                    value: VehicleStatsDetailData?.vehicleStatsDetail.descend ?? 0,
                                    displayUnits: true,
                                    fixed: 2
                                })}`}
                            />
                        )}
                    </TableBody>
                </Table>
            </>
        ) : (
            <h3> {translate('t.server_error')} </h3>
        )
    ) : (
        <UiCircularProgressContent />
    );
};

export default Wrapper(VehicleStatusSubTable);
