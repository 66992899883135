import { atom } from 'recoil';
import { CRUD } from 'variables';

type AnnouncementsActionsProps = {
    action: CRUD;
    id?: number;
};

export const AnnouncementsActionsState = atom<AnnouncementsActionsProps>({
    key: 'AnnouncementsActions',
    default: {
        action: CRUD.EMPTY
    }
});
