import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import UiCheckbox from '../Ui/Components/UiCheckbox';
import { applyStyleByMode } from 'helpers';
export const AddVehicleTypeContainer = styled.div``;

export const RemoveTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const TextDiv = styled.div`
    margin: 21px 0;
`;

export const Text = styled(Typography)`
    ${applyStyleByMode({
        styleOld: `
            & .MuiTypography-root {
                font-size: 15px;
            }
        `
    })}
`;

export const CustomTextField = styled(TextField)`
    ${applyStyleByMode({
        styleOld: ` 
            &.MuiTextField-root {
                margin: 8px;
                margin-left: 0px;
                && label {
                    padding: 0 5px 0 0;
                    background: #fff;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.9);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: #3a3633;
                }
                & svg {
                    fill: #999999;
                }
            }
        `
    })}
`;

export const CustomCheckbox = styled(UiCheckbox)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiFormControlLabel-root {
                margin-left: -5px !important;
            }
            &.MuiButtonBase-root {
                padding: 0;
                margin-left: 8px;
            }
        `
    })}
`;
