import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import Done from '@mui/icons-material/Done';
import { CommentUpdateFormikProps, InitialValues } from './CommentUpdate.type';
import { ButtonDone } from './CommentUpdate.style';
import TextField from '@mui/material/TextField';

export const CommentUpdateFormik: React.FC<CommentUpdateFormikProps> = ({
    initialValues,
    handleSubmit,
    loading
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({
        comment: Yup.string().trim().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: InitialValues) => {
                handleSubmit(values);
            }}
            enableReinitialize
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InitialValues>) => {
                const { touched, errors, handleChange, values } = propsFormik;

                return (
                    <Form id='tyre-action-form'>
                        <Grid container>
                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <TextField
                                    id='comment'
                                    label={translate('t.comment')}
                                    value={values.comment}
                                    variant='outlined'
                                    size='small'
                                    name='comment'
                                    error={!!errors.comment && !!touched.comment}
                                    type='text'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.comment}
                                    data-testid='tyre-comment-textield'
                                />
                            </Grid>

                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <ButtonDone
                                    skin='success'
                                    variant={VARIANT_BUTTON}
                                    size={SIZE_BUTTON}
                                    type='submit'
                                    disabled={loading}
                                    testid='tyre-comment-save-button'
                                >
                                    {loading ? (
                                        <UiLoadingPage size='20px' $marginTop='0px' />
                                    ) : (
                                        <Done fontSize='inherit' />
                                    )}
                                </ButtonDone>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
