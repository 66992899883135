import { getParams } from 'helpers';
import PortalApiClient from 'helpers/api/PortalApiClient';
import {
    AddAlertNotificationModel,
    AlertNotificationDateModel,
    AlertNotificationModel,
    AlertNotificationTableModel,
    AlertNotificationUpdateModel,
    SnoozeSchedulerModel,
    UpdateAlertNotificationModel
} from '../models/AlertNotification.type';

export default class AlertNotification extends PortalApiClient<AlertNotificationModel> {
    route = 'alert-notification-calls-scheduler';

    getAlertNotificationTable = async (
        props,
        additionalProps: { dateFrom: string; dateTo: string }
    ): Promise<AlertNotificationTableModel> => {
        const propsParams = getParams(props);

        propsParams.dateFrom = additionalProps.dateFrom;
        propsParams.dateTo = additionalProps.dateTo;

        return await this.getTable({ criteria: propsParams, apiProject: undefined });
    };

    addAlertNotfication = async (data: AddAlertNotificationModel): Promise<AlertNotificationDateModel> => {
        return await this.post({ data: data, apiProject: undefined });
    };

    updateAlertNotfication = async (data: UpdateAlertNotificationModel): Promise<AlertNotificationUpdateModel> => {
        return await this.patchById<AlertNotificationUpdateModel, UpdateAlertNotificationModel>({
            id: data.id,
            apiProject: undefined,
            data: data
        });
    };

    updateSnoozeScheduler = async (data: SnoozeSchedulerModel): Promise<AlertNotificationUpdateModel> => {
        return await this.patchById<AlertNotificationUpdateModel, SnoozeSchedulerModel>({
            id: data.id,
            apiProject: undefined,
            data: { snoozedTo: data.snoozedTo.toFormat('HH:mm:ss') }
        });
    };

    deleteSnoozeScheduler = async (id: number): Promise<Object> => {
        return await this.delete({
            id: id,
            apiProject: undefined
        });
    };

    cancelSnoozeScheduler = async (id: number): Promise<AlertNotificationUpdateModel> => {
        return await this.patch<AlertNotificationUpdateModel, SnoozeSchedulerModel>({
            apiProject: undefined,
            extendUrl: `${id}/snooze-cancel`
        });
    };
}
