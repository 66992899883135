import React, { memo } from 'react';
import { LogoGlobalAdminContent } from './LogoGlobalAdmin.view';
import { LogoGlobalAdminProps } from './LogoGlobalAdmin.type';
import { useQuery } from '@tanstack/react-query';
import Customer from '../../../../../api/Customer';
import { useRecoilState } from 'recoil';
import { EditLogoAtom } from './LogoGlobalAdmin.atom';
import { Wrapper } from 'helpers/wrapper';

const customerApi = new Customer();

const LogoGlobalAdmin: React.FC<LogoGlobalAdminProps> = (props): JSX.Element => {
    const [editLogoAtom, setEditLogoAtom] = useRecoilState(EditLogoAtom);

    const { data, isLoading: photoDataLoading } = useQuery<string, Error>(
        ['PhotoAdminCustomer', [props.customerId]],
        () => customerApi.getLogo(props.customerId),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            keepPreviousData: true,
            retryOnMount: false,
            retry: 1,
            refetchOnMount: editLogoAtom,
            onSettled: () => {
                setEditLogoAtom(false);
            }
        }
    );

    const isBase64 = (strBase64 = ''): boolean => {
        try {
            if (!strBase64) {
                return false;
            }
            return btoa(atob(strBase64)) === strBase64;
        } catch (error) {
            return false;
        }
    };

    return (
        <LogoGlobalAdminContent
            data-testid='LogoGlobalAdmin-testid'
            photo={isBase64(data) ? `data:image/jpeg;charset=utf-8;base64,${data}` : ''}
            photoDataLoading={photoDataLoading}
            width={props.width}
            maxWidth={props.maxWidth}
            maxHeight={props.maxHeight}
        />
    );
};

export default Wrapper(memo(LogoGlobalAdmin));
