import { Box, Grid, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { AlertSettingLevelProps } from '../AlertSettingLevel/AlertSettingLevel.type';
import { getColor } from '../AlertSettingLevel/AlertSettingLevel.style';

export const AlertTemperatureSettingsViewContent = styleMui(Box)({
    marginTop: 25
});

export const LevelIndicatorContent = styleMui(Grid)({
    display: 'flex',
    marginRight: 20,
    alignItems: 'center'
});

export const LevelIndicator = styleMui(Box)((props: Pick<AlertSettingLevelProps, 'level'> & { theme: Theme }) => ({
    width: 15,
    height: 15,
    background: getColor(props.level, props.theme),
    borderRadius: '50%',
    border: 'thin solid rgba(200,200,200)',
    marginLeft: 5,
    marginBottom: '0.35rem'
}));
