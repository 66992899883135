import React from 'react';
import { useRecoilValue } from 'recoil';
import { Modal } from '@mui/material';
import { Content, Footer, Header, ModalWindow, Title } from './StatedModal.style';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { useTranslation } from 'react-i18next';
import useModal from '../../CustomHooks/ModalHook';
import { Theme } from '../../../states/global/Theme';

const StatedModal: React.FC<{ layer?: number }> = ({ layer = 0 }) => {
    const { t: translate } = useTranslation();
    const { modalState, closeModal } = useModal(layer);
    const theme = useRecoilValue(Theme);

    return (
        <div>
            <Modal
                id={`${modalState.id}-layer-${layer}`}
                open={modalState.isOpen || false}
                onClose={() => {
                    closeModal();
                    modalState.onClose && modalState.onClose();
                }}
            >
                <ModalWindow
                    height={modalState.height}
                    width={modalState.width}
                    heightUnit={modalState.heightUnit}
                    widthUnit={modalState.widthUnit}
                    data-testid={`${modalState.id}-layer-${layer}`}
                >
                    <Header $theme={theme?.mode} id='modal-header'>
                        <Title $left data-testid={`${modalState.id}-title-left-${layer}`}>
                            {modalState.leftTitle}
                        </Title>
                        <Title data-testid={`${modalState.id}-title-right-${layer}`}>{modalState.rightTitle}</Title>
                    </Header>
                    {modalState.customContent || (
                        <>
                            <Content id='modal-content' $withoutFooter={modalState.replaceButtons}>
                                {modalState.content}
                            </Content>
                            {!modalState.replaceButtons && (
                                <Footer id='modal-footer' $theme={theme?.mode}>
                                    <>
                                        {modalState.buttons && modalState.buttons}
                                        <UiButton
                                            variant='outlined'
                                            skin='success'
                                            onClick={() => {
                                                closeModal();
                                                modalState.onClose && modalState.onClose();
                                            }}
                                            testid={`${modalState.id}-CloseModal-Button`}
                                        >
                                            {modalState.customCancelTitle
                                                ? modalState.customCancelTitle
                                                : translate('t.cancel')}
                                        </UiButton>
                                    </>
                                </Footer>
                            )}
                        </>
                    )}
                    <StatedModal layer={layer + 1} />
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default StatedModal;
