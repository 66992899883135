import React, { useState } from 'react';
import * as view from './ShowWifiPassword.view';
import { ShowWifiPasswordProps } from './ShowWifiPassword.type';
import { useQuery } from '@tanstack/react-query';
import Device from '../../../api/Device';
import { WifiCredentials } from '../../../models/HubAdministration.type';
import { Wrapper } from 'helpers/wrapper';

const deviceApi = new Device();

const ShowWifiPassword: React.FC<ShowWifiPasswordProps> = (props): JSX.Element => {
    const [hidden, setHidden] = useState<boolean>(true);
    const [copiedSSID, setCopiedSSID] = useState<boolean>(false);
    const [copiedPassword, setCopiedPassword] = useState<boolean>(false);
    const [keyData, setKeyData] = useState<WifiCredentials>({
        wifiName: '',
        password: null
    });

    const handleCopy = (text, value) => {
        navigator.clipboard.writeText(text.toString() ?? ' ');

        value == 1 ? setCopiedSSID(true) : setCopiedPassword(true);

        setTimeout(() => {
            value == 1 ? setCopiedSSID(false) : setCopiedPassword(false);
        }, 900);
    };

    const handleShowPassword = () => {
        setHidden(!hidden);
    };

    const { isLoading: keyLoading } = useQuery(
        [`ShowWifiPasswordKey-${props.deviceId}`],
        () => deviceApi.getWifiCredentials({ id: props.deviceId }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (deviceDataOnSuccess) => setKeyData(deviceDataOnSuccess.items[0])
        }
    );

    return (
        <view.ShowWifiPasswordContent
            data-testid='ShowWifiPasswordContent-testid'
            keyLoading={keyLoading}
            handleCopy={handleCopy}
            handleShowPassword={handleShowPassword}
            copiedSSID={copiedSSID}
            copiedPassword={copiedPassword}
            keyData={keyData}
            hidden={hidden}
        />
    );
};

export default Wrapper(ShowWifiPassword);
