import React from 'react';
import { TemperatureExposureTyreModalViewContent } from './TemperatureExposureTyreModal.style';
import { TemperatureExposureTyreModalViewProps } from './TemperatureExposureTyreModal.type';
import TemperatureExposureReport from '../../Mixs/TemperatureExposureReport';
import TemperatureExposureTopbar from '../../Mixs/TemperatureExposureTopbar/TemperatureExposureTopbar';

export const TemperatureExposureTyreModalContent: React.FC<TemperatureExposureTyreModalViewProps> = (
    props
): JSX.Element => {
    return (
        <TemperatureExposureTyreModalViewContent data-testid='TemperatureExposureTyreModalContent'>
            <TemperatureExposureTopbar isLoading={props.isLoading} />
            <TemperatureExposureReport
                data={
                    props.data ?? {
                        temperatureCategories: {},
                        temperatureExposureReport: []
                    }
                }
            />
        </TemperatureExposureTyreModalViewContent>
    );
};
