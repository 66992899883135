import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReplaceSensorContent } from './ReplaceSensor.view';
import { InitialValues, ReplaceSensorProps } from './ReplaceSensor.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import Vehicle from '../../../api/Vehicle';
import { VehicleList } from '../../../models/Vehicle.type';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';
import SensorType from '../../../api/SensorType';
import SensorMovement from '../../../api/SensorMovement';
import Sensors from 'api/Sensors';
import { Status } from 'models/SensorInfo.type';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useRecoilState } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { Wrapper } from 'helpers/wrapper';

const vehicleApi = new Vehicle();
const sensorTypeApi = new SensorType();
const sensorMovementApi = new SensorMovement();
const sensorApi = new Sensors();

const ReplaceSensor: React.FC<ReplaceSensorProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [unmountReasonParsed, setUnmountReasonParsed] = useState<optionsCodeBook[]>();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const isFaulty = useMemo(() => {
        return (
            props.sensor.sensorInfo?.status === Status.FAULT || props.sensor.sensorInfo?.status === Status.SUSPICIOUS
        );
    }, [props.sensor.sensorInfo?.status]);
    const initialValues: InitialValues = {
        newSensorId: undefined,
        newSensorTypeId: undefined,
        unmountReason: undefined
    };

    const parseData = (data, type: number): optionsCodeBook[] => {
        if (type === 1)
            return data.codeBook.reduce((acc: optionsCodeBook[], curr) => {
                acc.push({ id: curr, name: curr });
                return acc;
            }, []);
        else
            return data.items.reduce((acc: optionsCodeBook[], curr) => {
                acc.push({ id: curr.id, name: curr.serialNumberHex });
                return acc;
            }, []);
    };

    const { data: sensorTypeListData, isLoading: sensorTypeListLoading } = useQuery<optionsCodeBook[]>(
        ['SensorTypeList'],
        sensorTypeApi.getSensorsTypeCodebook,
        {
            refetchOnWindowFocus: false
        }
    );

    const { isLoading: unmountReasonLoading } = useQuery(
        ['SensorsUnmountReasonListReplace'],
        sensorMovementApi.getSensorsUnmountReason,
        {
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => setUnmountReasonParsed(parseData(dataOnSuccess, 1))
        }
    );

    const { mutate: replaceSensorMutate, isLoading: replaceSensorLoading } = useMutation(
        sensorApi.patchReplaceSensorOnWheel,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.sensor_replaced')
                });
                props.setRefreshTableAtom(true);
                modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
            }
        }
    );

    const handleSubmit = (values: InitialValues) => {
        replaceSensorMutate({ ...values, oldSensorId: props.sensor.id });
    };

    const { data: vehicleDataXHR, isLoading: vehicleLoading } = useQuery<VehicleList, Error>(
        ['VehicleList'],
        () => vehicleApi.getList(),
        {
            refetchOnWindowFocus: false
        }
    );

    return (
        <ReplaceSensorContent
            data-testid='ReplaceSensor-testid'
            sensor={props.sensor}
            isFaulty={isFaulty}
            vehicles={vehicleDataXHR ?? []}
            vehiclesLoading={vehicleLoading}
            unmountReasonLoading={unmountReasonLoading}
            unmountReasonList={unmountReasonParsed ?? []}
            sensorType={sensorTypeListData ?? []}
            sensorTypeLoading={sensorTypeListLoading}
            initialValues={initialValues}
            refForReplaceSensor={props.refForReplaceSensor}
            replaceSensorLoading={replaceSensorLoading}
            modalDispatch={modalDispatch}
            handleSubmit={handleSubmit}
            setRefreshTableAtom={props.setRefreshTableAtom}
        />
    );
};

export default Wrapper(ReplaceSensor);
