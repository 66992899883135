import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { VehicleStatsData } from 'models/TrackLog.type';
import TrackLogApi from 'api/TrackLog';
import { FilterPeriod, SearchVehicle } from 'components/Vehicle/VehicleStats/VehicleStats.atom';
import { ColumnsModel, VehicleStatsTableProps } from './VehicleStatsTable.type';
import { VehicleStatsTableContent } from './VehicleStatsTable.view';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';
import { UserInfo } from 'states/global/User';

const sortArray = (arr: VehicleStatsData[], orderBy: 'desc' | 'asc'): VehicleStatsData[] => {
    return arr.sort((a, b) => {
        const vehicelNameA = a.name.toLowerCase();
        const vehicleNameB = b.name.toLowerCase();
        if (a.alert === b.alert) {
            return orderBy === 'asc'
                ? vehicelNameA > vehicleNameB
                    ? 1
                    : vehicleNameB > vehicelNameA
                    ? -1
                    : 0
                : vehicelNameA < vehicleNameB
                ? 1
                : vehicleNameB < vehicelNameA
                ? -1
                : 0;
        } else {
            return a.alert < b.alert ? 1 : -1;
        }
    });
};

const TrackLog = new TrackLogApi();

const getVehicleStats = async (period: number): Promise<VehicleStatsData | undefined> => {
    const { vehicleStats }: VehicleStatsData | undefined = await TrackLog.getWebtrackVehicleStats({ period });
    return vehicleStats;
};

const VehicleStatsTable: React.FC<VehicleStatsTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderDirection, setOrderDirection] = useState<'desc' | 'asc'>('asc');
    const period = useRecoilValue(FilterPeriod);
    const searchVehicle = useRecoilValue(SearchVehicle);
    const customerSettings = useRecoilValue(CustomerSettings);
    const userInfo = useRecoilValue(UserInfo);

    const { data: vehicleStatsData, refetch } = useQuery<VehicleStatsData | undefined, Error, VehicleStatsData[]>(
        ['vehiclsStatsData', period, userInfo.user?.customer.id],
        () => getVehicleStats(period),
        {
            refetchOnWindowFocus: false,
            refetchInterval: searchVehicle.length ? false : 30000,
            cacheTime: 30000,
            retry: 1,
            retryDelay: 1000,
            onSuccess: (vehicleStatsDataOnSuccess) => {
                setIsLoading(false);
                if (vehicleStatsDataOnSuccess && vehicleStatsDataOnSuccess.length) {
                    const data = sortArray(vehicleStatsDataOnSuccess, orderDirection);
                    return data;
                }
                return [];
            }
        }
    );

    const getSortedAndFilteredData = (data: VehicleStatsData[]): VehicleStatsData[] => {
        const filteredData = data.filter((d) => d.name.toLocaleLowerCase().includes(searchVehicle.toLowerCase()));
        return sortArray(filteredData, orderDirection === 'asc' ? 'asc' : 'desc');
    };

    const handleSortRequest = (): void => {
        setOrderDirection((current) => (current === 'asc' ? 'desc' : 'asc'));
    };

    const columns: ColumnsModel[] = [
        { id: 'vehicleName', label: translate('t.vehicle_name'), minWidth: 100, align: 'left' },
        {
            id: 'status',
            label: translate('t.status'),
            minWidth: 100,
            align: 'left'
        },
        {
            id: 'besRank',
            label: `Highest ${customerSettings.mdd_bes_display_name}`,
            minWidth: 50,
            align: 'left'
        },
        {
            id: 'last_alerts',
            label: `${translate('t.last_alerts')}`,
            minWidth: 30,
            align: 'right'
        },
        {
            id: 'previous_alerts',
            label: `${translate('t.previous_alerts')}`,
            minWidth: 30,
            align: 'right'
        },
        {
            id: 'distance',
            label: `${translate('t.distance')}`,
            minWidth: 10,
            align: 'right'
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        refetch();
    }, [period]);

    return (
        <VehicleStatsTableContent
            data-testid={'VehicleStatsTable-testid'}
            vehicleStatsData={getSortedAndFilteredData(vehicleStatsData || [])}
            columns={columns}
            handleSortRequest={handleSortRequest}
            orderDirection={orderDirection}
            isLoading={isLoading}
        />
    );
};

export default Wrapper(VehicleStatsTable);
