import styled from 'styled-components';
import {
    BACKGROUND_DARK_MODE,
    DARK_STEEL_GREY,
    BACKGROUND_BLUE,
    BACKGROUND_TABLE_DARK,
    PRIMARY
} from '../../Ui/colors';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { applyStyleByMode } from 'helpers';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const TrackingSummary = styled.div`
    padding-left: 0;
`;

export const CustomTableContainer = styled(TableContainer)<{ $border?: boolean }>`
    &.MuiTableContainer-root {
        ${(props) => props.$border && 'border-top: 2px solid #dfdfdf;'}
    }
`;

export const CustomTable = styled(Table)`
    tbody {
        color: ${PRIMARY};
        tr {
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return applyStyleByMode({
                    styleOld: `
                            font-size: 1.1rem;
                            tr:nth-child(odd) {
                                background: #f5f5f5;
                            }
                        `,
                    styleJade: `font-size:  ${common.textSize};`
                });
            }}
        }
    }
`;

export const CustomHead = styled(TableHead)`
    tr {
        border-bottom: 3px solid rgba(224, 224, 224, 1);
    }
    th {
        font-weight: 700;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleJade: `font-size: ${common.titleSize};`
            });
        }}
    }
    && .MuiFormControl-root {
        margin: 0 0 0 5px;
    }
`;

export const CustomTableCell = styled(TableCell)`
    &.MuiTableCell-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleJade: `font-size: ${common.textSize}; padding: 5px 10px;`
            });
        }}
        width: 23%;
    }
`;

export const Title = styled(Typography)`
    &.MuiTypography-body1 {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleJade: `font-size: ${common.textSize};`
            });
        }}
        font-weight: 600;
        padding-bottom: 10px;
    }
`;

export const CardTrackingSummary = styled(Paper)`
    .AvatarCard {
        text-align: center;
        margin-top: -28px;
        padding-bottom: 5px;
    }

    .AvatarCardImage {
        display: inline-flex;
    }

    .AvatarCardImageContent {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: `background-color: ${BACKGROUND_TABLE_DARK};`,
                light: 'background-color: #646464;'
            });
        }}
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: `border: 4px solid ${BACKGROUND_DARK_MODE};`,
                light: 'border: 4px solid #f2f2f2;'
            });
        }}
    }

    && {
        text-align: center;
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: `background: ${BACKGROUND_DARK_MODE};`,
                light: 'background-color: #f2f2f2;'
            });
        }}
        strong {
            color: ${DARK_STEEL_GREY};
            ${() => {
                return applyStyleByMode({
                    theme: useTheme().palette.mode,
                    dark: `color: ${BACKGROUND_BLUE};`,
                    light: `color: ${DARK_STEEL_GREY};`
                });
            }}
        }
        p {
            margin: 0;
        }
    }
`;
