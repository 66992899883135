import styled from 'styled-components';

export const WidgetJourneyMapContainer = styled.div``;

export const ContentMapInfo = styled.div`
    cursor: pointer;
    width: 100%;
`;

export const CircleColor = styled.span<{ color: string; border: string }>`
    position: relative;
    display: inline-flex;
    margin-right: 5px;
    top: 5px;
    background-color: ${({ color }) => color};
    border: ${({ border }) => `solid 2px ${border}`};
    height: 20px;
    width: 20px;
    border-radius: 50%;
`;
