import PortalApiClient from 'helpers/api/PortalApiClient';
import { SensorsModel } from '../models/Sensors.type';
import { optionsCodeBook } from '../components/Ui/Components/UiDropDown/UiDropDown.type';

export default class SensorType extends PortalApiClient<SensorsModel> {
    route = 'sensor-type';

    getSensorsTypeCodebook = async (): Promise<optionsCodeBook[]> => {
        return this.get({});
    };
}
