import React from 'react';
import { useRecoilValue } from 'recoil';
import { Role } from '../../states/global/User';

const RoleBasedAccess: React.FC<{ requiredRoles: string[]; children?: JSX.Element }> = (props): JSX.Element => {
    const userRole: string = useRecoilValue(Role);
    const isAllowed: boolean = props.requiredRoles.length ? props.requiredRoles.includes(userRole) : true;
    return <>{isAllowed && props.children}</>;
};

export const isRoleAllowed = (requiredRoles: string[], userRole: string) => {
    return requiredRoles.length ? requiredRoles.includes(userRole) : true;
};

export default RoleBasedAccess;
