import { Button, FormControl, Typography } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { PRIMARY } from '../../Ui/colors';

export const SensorsTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 100%;
    && .MuiCardContent-root {
        background-color: white;
        font-size: 13px !important;
        border: none !important;
        box-shadow: none;
    }

    & .MuiTableHead-root {
        ${applyStyleByMode({
            styleOld: `
            border-bottom: 1px solid #474544;
            background: #f9f9f9;
            `
        })}
    }
`;

export const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin: 4px 0 8px 0 !important;
    }
`;
export const ShowDropdownUpdate = styled(Typography)`
    text-transform: capitalize !important;
`;

export const ActionWarningButton = styled(Button)`
    margin-left: 20px;
    min-width: 20px !important;
    max-width: 20px !important;
    color: ${PRIMARY} !important;
`;

export const ContainerTextModal = styled.div`
    text-align: center;
`;

export const CustomLabel = styled.span`
    margin-right: 5px;
`;
