import React, { useState } from 'react';
import { SensorsTablesContent } from './SensorsTables.view';
import { TabNumber } from './SensorsTables.type';
import { Wrapper } from 'helpers/wrapper';

const SensorsTables: React.FC = (): JSX.Element => {
    const [currentTab, setCurrentTab] = useState<number>(0);

    const setTab = (tab: TabNumber) => {
        setCurrentTab(tab);
    };

    return <SensorsTablesContent data-testid='SensorsTables-testid' setNumberTab={setTab} currentTab={currentTab} />;
};

export default Wrapper(SensorsTables);
