import React, { useState } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { ButtonWithoutLeftMargin, GridWithMarginTop } from './NewHubsExtendedRow.style';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import Device from '../../../api/Device';
import { RevisionProps } from './NewHubsExtendedRow.type';
import { Success } from '../../Popup/Popup';
import { VARIANT_BUTTON } from '../../Ui/variables';

const deviceAPI = new Device();

export const Revision: React.FC<RevisionProps> = ({ hubData, revisionCodebook }): JSX.Element => {
    const [revisionId, setRevisionId] = useState<number>(hubData.deviceRevision?.id || 0);
    const { t: translate } = useTranslation();

    const { mutate } = useMutation<unknown, unknown, void>(
        ['UpdateDeviceRevision'],
        () => deviceAPI.patch({ extendUrl: `${hubData.id}/revision-id`, data: { revisionId: revisionId } }),
        {
            onSuccess: () => {
                Success({
                    text: translate('t.revision_changed')
                });
            }
        }
    );

    return (
        <Grid container spacing={2}>
            <GridWithMarginTop item xs={12} md={9} lg={8} marginTop={17}>
                <TextField
                    select
                    label={!revisionCodebook ? `${translate('t.loading')}...` : translate('t.revision')}
                    disabled={!revisionCodebook}
                    id='revision-select'
                    data-testid='revision-select'
                    variant='outlined'
                    size='small'
                    value={revisionId}
                    onChange={(event) => {
                        setRevisionId(Number(event.target.value));
                    }}
                >
                    <MenuItem value={0} disabled>
                        {translate('t.select_revision')}
                    </MenuItem>
                    {revisionCodebook?.codeBook.map((revision, index) => (
                        <MenuItem key={index} value={revision.id}>
                            {revision.description}
                        </MenuItem>
                    ))}
                </TextField>
            </GridWithMarginTop>
            <GridWithMarginTop
                item
                xs={12}
                md={3}
                lg={4}
                marginTop={12}
                justifyContent='right'
                sx={{ textAlign: 'right' }}
            >
                <ButtonWithoutLeftMargin
                    testid='save-revision-button'
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    disabled={revisionId === 0}
                    onClick={() => {
                        mutate();
                    }}
                >
                    {translate('t.save')}
                </ButtonWithoutLeftMargin>
            </GridWithMarginTop>
        </Grid>
    );
};
