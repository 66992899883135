export const EasyReadingModeOverride = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                height: 64,
                fontSize: '1rem',
                borderBottom: '3px solid #b5b5b5',
                '& .MuiToolbar-regular': {
                    minHeight: 64
                },
                '& .Header-logo': {
                    width: 200
                },
                '& button': {
                    fontSize: '1.5rem'
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem'
                },
                '& p': {
                    fontSize: '1rem'
                },
                '& .CollapsSidebarButton': {
                    padding: 20
                },
                '& .MuiBreadcrumbs-root': {
                    position: 'fixed',
                    top: '80px',
                    left: 270
                }
            }
        }
    },
    MuiDrawer: {
        styleOverrides: {
            root: {
                '& .MuiPaper-root': {
                    width: 240,
                    maxWidth: 240
                },
                '& .MuiListItem-root': {
                    fontSize: '1rem'
                },
                '& .MuiTypography-body1': {
                    fontSize: '1rem'
                }
            }
        }
    }
};
