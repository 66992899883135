import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Button, DialogTitle, styled as muiStyled } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { BACKGROUND_BLUE, BACKGROUND_PAPER_DARK_MODE, WHITE } from '../../Ui/colors';
import { SIZE_TEXT_VARIANT } from '../../Ui/variables';

export const ContainerDialogTitle = styled.div`
    padding: 0px 10px;
    & .MuiDialogTitle-root {
        padding: 14px 2px;
        font-size: ${SIZE_TEXT_VARIANT(2)};
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: `background: ${BACKGROUND_PAPER_DARK_MODE} !important;`
            })}
    }
`;

export const CustomDialogActions = styled(DialogActions)`
    &.MuiDialogActions-root {
        border-top: thin solid #c0c0c0;
    }
`;

export const LeftTitle = styled.span`
    float: left;
`;

export const RightTitle = styled.span`
    float: right;
`;

export const Header = muiStyled(DialogTitle)`
    ${() =>
        applyStyleByMode({
            styleJade: `font-size: ${SIZE_TEXT_VARIANT(2)};`,
            theme: useTheme().palette.mode,
            light: `background: #fff; color: #${BACKGROUND_BLUE};`,
            dark: `background: ${BACKGROUND_PAPER_DARK_MODE}; color: #${BACKGROUND_BLUE};`
        })}
    
    padding: 7px 24px;
    overflow: auto;
    border-radius: 3px 3px 0 0;
`;

export const CustomDialog = styled(Dialog)<{
    width?: number;
    height?: number;
    widthunit?: string;
    heightunit?: string;
}>`
    & .MuiDialog-paper {
        border-radius: 4px;
        width: ${(props) => props.width}${(props) => props.widthunit};
        min-width: ${(props) => props.width}${(props) => props.widthunit};

        @media (min-width: 376px) and (max-width: 425px) {
            width: calc(100vw - 31px);
            min-width: calc(100vw - 31px);
            max-height: 100vh;
        }

        @media (min-width: 0px) and (max-width: 768px) {
            width: 100vw;
            min-width: 100vw;
            max-height: 100vh;
        }
    }
    & .MuiDialog-paper {
        max-height: calc(100% - 50px);
    }
    & .MuiDialogContent-root {
        height: ${(props) => props.height}${(props) => props.heightunit};
        max-height: ${(props) => props.height}${(props) => props.heightunit};

        @media (min-width: 376px) and (max-width: 425px) {
            max-width: calc(100vw - 31px);
            height: auto;
        }

        @media (min-width: 0px) and (max-width: 375px) {
            max-width: 100vw;
            height: auto;
        }
    }
`;

export const CancelButton = styled(Button)<{ customcolor?: string }>`
    && {
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: `
                border: 1px solid #c5c5c5 !important;
                color: ${WHITE} !important;
            `
            })}
    }
`;

export const ContainerActions = styled.div`
    padding: 0px 10px;
`;
