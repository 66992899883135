import styled from 'styled-components';

export const DataExportContainer = styled.div``;

export const SelectVehicleMessage = styled.div`
    padding: 45px;
    display: flex;
    justify-content: center;
    min-height: 53vh;
`;

export const ContainerDataExportsList = styled.div`
    min-height: 53vh;
`;
