import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ImportContacts from '@mui/icons-material/ImportContacts';
import { Menu, MenuItem as MenuItemPro, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Hidden, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { MainMenuPropsView } from './MainMenu.type';
import {
    AvatarContainer,
    HelperActions,
    ListCover,
    LogoContainer,
    MainMenuContentDrawer,
    MenuCustomProperties,
    MenuItem,
    MenuItemCustomProperties,
    ProfileInfo,
    SidebarCustomProperties,
    SubMenuCustomProperties,
    SubMenuTitleCustomProperties,
    Sublist,
    UserInfoContainer,
    UserPrimaryInfo,
    UserSecondaryInfo,
    ContainerButtonConfig,
    ListButtonsSetting,
    ContainerMenuPro,
    CustomLink
} from './MainMenu.style';
import RoleBasedAccess, { isRoleAllowed } from 'helpers/security';
import { useTranslation } from 'react-i18next';
import LogoutButton from 'components/TopBar/LogoutButton';
import BugReporter from 'components/Mixs/BugReporter/BugReporter';
import { MenuOption } from './MenuOption.type';
import { Theme } from 'states/global/Theme';
import { ButtonIcon, BarGroupInfo } from 'components/TopBar/TopBar.style';
import ThemeButton from 'components/TopBar/ThemeButton/ThemeButton';
import NotificationsToggleButton from 'components/TopBar/NotificationsToggleButton/NotificationsToggleButton';
import { snakeToCamel, firstCapital } from 'helpers/converter/text';
import CollapseMenuButton from 'components/Ui/CollapseMenuButton/CollapseMenuButton';
import { getImgSource } from 'helpers/image';
import { ROLES } from 'variables';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { CustomerSettingsAll } from 'models/CustomerSettings.type';

export const MainMenuContent: React.FC<MainMenuPropsView> = ({
    showSidebarResponsive,
    loading,
    menu,
    userData,
    collapsedSidebar,
    path,
    roleUser,
    theme,
    userRole,
    languageHelp,
    showUserInfo,
    openMenuId,
    openSubMenuId,
    avatarData,
    customerAmbient,
    handleSubmenu,
    toggleSidebarResponsive,
    handleMenu,
    isDefaultOpen,
    changeAppTitle,
    showPopUpSubmenu
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const themeState = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    const checkMenuRequirements = (
        item: MenuOption,
        customerSettings: CustomerSettingsAll['settings'] & { ready_to_use_data?: boolean }
    ): boolean => {
        return (
            !item.hiddenMenu &&
            (customerSettings.mdd_bes ? true : !item.bes) &&
            (customerSettings.tablet_activation ? true : !item.tableActivation) &&
            (customerSettings.mdd_bes_driving_assistance ? true : !item.besDrivingAssistance) &&
            (customerSettings.temperature_prediction ? true : !item.temperaturePrediction) &&
            (customerSettings.heatmap_speed ? true : userRole === ROLES.ROLE_SUPER_ADMIN ? true : !item.heatmapSpeed) &&
            (customerSettings.heatmap_elevation
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapElevation) &&
            (customerSettings.heatmap_gps
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapSatellite) &&
            (customerSettings.heatmap_temperature
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapTemperature) &&
            (customerSettings.heatmap_longitudinal_acceleration
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapLongitudinalAcceleration) &&
            (customerSettings.heatmap_total_vibration
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapTotalVibration) &&
            (customerSettings.heatmap_vertical_vibration
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapVerticalVibration) &&
            (customerSettings.heatmap_slope_inclination
                ? true
                : userRole === ROLES.ROLE_SUPER_ADMIN
                ? true
                : !item.heatmapSlopeInclination)
        );
    };

    const checkHeatmapRequirements = (
        item: MenuOption,
        customerSettings: CustomerSettingsAll['settings'] & { ready_to_use_data?: boolean }
    ): boolean => {
        return customerSettings.heatmap_speed ||
            customerSettings.heatmap_elevation ||
            customerSettings.heatmap_gps ||
            customerSettings.heatmap_temperature ||
            customerSettings.heatmap_longitudinal_acceleration ||
            customerSettings.heatmap_total_vibration ||
            customerSettings.heatmap_vertical_vibration ||
            customerSettings.heatmap_slope_inclination ||
            userRole === ROLES.ROLE_SUPER_ADMIN
            ? true
            : !item.heatmap;
    };

    const generateLinksMobile = (drawer) => {
        const generateLink = (source: MenuOption[], depth) => {
            return source.map((item, key) => {
                let menu;
                if (!item.hiddenMenu && (customerSettings.mdd_bes ? true : !item.bes)) {
                    menu = item.submenu.length ? (
                        <RoleBasedAccess requiredRoles={item.requiredRoles} key={`roleBaseAcccess-${key}`}>
                            <>
                                <MenuItem
                                    className='link_general'
                                    onClick={() => handleSubmenu(item.key)}
                                    $active={path === item.path && !item.open}
                                    data-testid={`OpenSubMenu-Of-${firstCapital(snakeToCamel(item.key))}-Button`}
                                    key={`MenuItem-${key}`}
                                >
                                    <ListItemIcon key={`ListItemIcon-${key}`}> {item.icon} </ListItemIcon>
                                    <ListItemText key={`ListItemText-${key}`} primary={translate(item.title)} />
                                    {item.open ? <ExpandMore fontSize='small' /> : <ChevronRight fontSize='small' />}
                                </MenuItem>

                                <Collapse key={`Collapse-${key}`} in={item.open} timeout='auto' unmountOnExit>
                                    <Sublist key={`Sublist-${key}`} disablePadding section={depth + 1}>
                                        {generateLink(item.submenu, depth + 1)}
                                    </Sublist>
                                </Collapse>
                            </>
                        </RoleBasedAccess>
                    ) : (
                        <RoleBasedAccess requiredRoles={item.requiredRoles} key={`roleBaseAcccess-${item.title}`}>
                            <Link
                                to={item.path || ''}
                                onClick={() => drawer && toggleSidebarResponsive()}
                                data-testid={`LinkTo-${firstCapital(snakeToCamel(item.key))}-Buton`}
                                key={`${item.title}-link`}
                            >
                                <MenuItem $active={path === item.path} key={`${item.title}-MenuItem`}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={translate(item.title)} />
                                </MenuItem>
                            </Link>
                        </RoleBasedAccess>
                    );
                }

                return menu;
            });
        };
        return generateLink(menu, 0);
    };

    const mainMenuMobile = (drawer = false): JSX.Element => (
        <ListCover>
            <List component='nav' aria-labelledby='nested-list-subheader'>
                {generateLinksMobile(drawer)}
                <HelperActions>
                    <MenuItem
                        onClick={() => window.open(`https://help.atmstechnology.com/${languageHelp}`, '_blank')}
                        data-testid='knowledgeBase-Button'
                    >
                        <ListItemIcon>
                            <ImportContacts />
                        </ListItemIcon>
                        <ListItemText primary={translate('t.knowledge_base')} />
                    </MenuItem>

                    <BugReporter isNavigationButton={true} mobileMode={true} />
                </HelperActions>

                <ContainerButtonConfig>
                    <ListButtonsSetting>
                        <Hidden only={['lg', 'md']}>
                            <ButtonIcon>
                                <ThemeButton />
                            </ButtonIcon>
                            <BarGroupInfo>{customerAmbient}</BarGroupInfo>
                        </Hidden>
                        <NotificationsToggleButton />
                    </ListButtonsSetting>
                </ContainerButtonConfig>
            </List>

            <ProfileInfo>
                <Link to={'/profile'}>
                    <AvatarContainer>
                        <Avatar
                            alt='User Avatar'
                            src={avatarData && `data:image/jpeg;charset=utf-8;base64,${avatarData}`}
                        />
                    </AvatarContainer>
                    <UserInfoContainer>
                        <UserPrimaryInfo variant='body1' gutterBottom>
                            {userData ? userData.firstname + ' ' + userData?.lastname : 'Name'}
                        </UserPrimaryInfo>
                        <UserSecondaryInfo variant='body1' gutterBottom>
                            {roleUser.role ? roleUser.role.replaceAll(/ROLE_|_/g, ' ') : '---'}
                        </UserSecondaryInfo>
                    </UserInfoContainer>
                </Link>
                <LogoutButton mode='sidebar' />
            </ProfileInfo>
        </ListCover>
    );

    const generateLinks = () => {
        const createLink = (item: MenuOption, key: number, itemPrimary: boolean, subMenu = false, level: number) => {
            const openMenuItemId: string | null = level === 1 ? openMenuId : openSubMenuId;
            if (
                item.key === 'administration' &&
                roleUser.role === ROLES.ROLE_TYRE_BAY_USER &&
                !customerSettings.tablet_activation
            ) {
                return <></>;
            }
            if (subMenu) {
                return (
                    <Tooltip title={translate(item.title)} placement='right' key={`tooltip-${key}`} disableInteractive>
                        <SubMenu
                            key={`SubMenu-${key}`}
                            icon={item.icon}
                            label={translate(item.title)}
                            open={openMenuItemId === item.key || (!openMenuItemId && isDefaultOpen(level, item))}
                            onClick={() => handleMenu(item, level === 1)}
                            active={isDefaultOpen(level, item)}
                            rootStyles={
                                level === 1 && collapsedSidebar
                                    ? SubMenuCustomProperties(
                                          theme,
                                          item.submenu.filter((item) => checkMenuRequirements(item, customerSettings))
                                              .length
                                      )
                                    : undefined
                            }
                            defaultOpen={!openMenuId && isDefaultOpen(level, item)}
                            disabled={level === 1 && collapsedSidebar}
                            component={item.path && <Link to={item.path} data-testid={`MainMenu-${item.key}-Button`} />}
                            data-testid={`MainMenu-${item.key}-Button`}
                        >
                            {collapsedSidebar && level === 1 ? (
                                <MenuItemPro
                                    rootStyles={SubMenuTitleCustomProperties(theme)}
                                    key={`MenuItemPro-${key}`}
                                    icon={item.icon}
                                    disabled
                                    data-testid={`MainMenu-Link-${item.key}-Button`}
                                >
                                    {translate(item.title)}
                                </MenuItemPro>
                            ) : (
                                ''
                            )}
                            {generateLink(item.submenu, false, 2)}
                        </SubMenu>
                    </Tooltip>
                );
            }

            if (collapsedSidebar && level === 1) {
                return (
                    <Tooltip key={`tooltip-${key}`} title={translate(item.title)} placement='right' disableInteractive>
                        <SubMenu
                            key={`SubMenu-${key}`}
                            icon={item.icon}
                            label={translate(item.title)}
                            open={openMenuItemId === item.key || (!openMenuItemId && isDefaultOpen(level, item))}
                            active={isDefaultOpen(level, item)}
                            rootStyles={SubMenuCustomProperties(
                                theme,
                                item.submenu.filter((item) => (item) => checkMenuRequirements(item, customerSettings))
                                    .length,
                                true
                            )}
                            disabled
                            component={item.path && <Link to={item.path} data-testid={`MainMenu-${item.key}-Button`} />}
                        >
                            <MenuItemPro
                                rootStyles={SubMenuTitleCustomProperties(theme)}
                                key={`MenuItemPro-${key}`}
                                icon={item.icon}
                                component={
                                    item.path && (
                                        <Link to={item.path} data-testid={`MainMenu-Link-${item.key}-Button`} />
                                    )
                                }
                            >
                                {translate(item.title)}
                            </MenuItemPro>
                        </SubMenu>
                    </Tooltip>
                );
            }

            return (
                <Tooltip title={translate(item.title)} placement='right' disableInteractive>
                    <MenuItemPro
                        key={`MenuItemPro-${key}`}
                        icon={item.icon}
                        active={path === item.path}
                        onClick={() => handleMenu(item, level === 1)}
                        component={
                            item.path && (
                                <CustomLink
                                    key={`CustomLink-${key}`}
                                    to={item.path}
                                    data-testid={`MainMenu-Link-${item.key}-Button`}
                                    $hasDivider={item.hasDivider}
                                />
                            )
                        }
                    >
                        {translate(item.title)}
                    </MenuItemPro>
                </Tooltip>
            );
        };

        const generateLink = (source: MenuOption[], itemPrimary = true, level: number) => {
            return source.map((item, index) => {
                let menu;

                if (!item.keepDefaultTitle) {
                    changeAppTitle(item, path);
                }

                if (
                    checkMenuRequirements(item, customerSettings) &&
                    isRoleAllowed(item.requiredRoles, userRole) &&
                    checkHeatmapRequirements(item, customerSettings)
                ) {
                    menu = item.submenu.length
                        ? createLink(item, index, itemPrimary, true, level)
                        : createLink(item, index, itemPrimary, false, level);
                }

                return menu;
            });
        };
        return generateLink(menu, true, 1);
    };

    const generateknowledgeBase = () => {
        if (collapsedSidebar) {
            return (
                <SubMenu
                    icon={<ImportContacts />}
                    label={translate('t.knowledge_base')}
                    rootStyles={SubMenuCustomProperties(theme, 0, true)}
                    disabled
                >
                    <MenuItemPro
                        rootStyles={SubMenuTitleCustomProperties(theme)}
                        key={'knowledgeBase-title'}
                        icon={<ImportContacts />}
                        onClick={() => window.open(`https://help.atmstechnology.com/${languageHelp}`, '_blank')}
                    >
                        {translate('t.knowledge_base')}
                    </MenuItemPro>
                </SubMenu>
            );
        }

        return (
            <MenuItemPro
                icon={<ImportContacts />}
                onClick={() => window.open(`https://help.atmstechnology.com/${languageHelp}`, '_blank')}
            >
                {translate('t.knowledge_base')}
            </MenuItemPro>
        );
    };

    const mainMenu = (): JSX.Element => (
        <Sidebar
            width='220px'
            collapsedWidth='64px'
            defaultCollapsed={!matches}
            backgroundColor={theme.palette.background.paper}
            style={{ position: 'fixed', zIndex: 1101 }}
            rootStyles={SidebarCustomProperties(themeState.easyReadingMode)}
        >
            <Menu
                renderExpandIcon={({ open }) => (open ? <ExpandMore /> : <ChevronRight />)}
                rootStyles={MenuCustomProperties(theme, collapsedSidebar, themeState)}
                menuItemStyles={{
                    button: ({ level, active, open }) => {
                        return MenuItemCustomProperties(level, active, theme, !!open, themeState);
                    },
                    subMenuContent:
                        !showPopUpSubmenu && !showSidebarResponsive
                            ? {
                                  visibility: 'hidden'
                              }
                            : {}
                }}
            >
                <ContainerMenuPro>{generateLinks()}</ContainerMenuPro>
                <HelperActions $collapsedSidebar={collapsedSidebar}>
                    {generateknowledgeBase()}
                    <BugReporter isNavigationButton={true} />
                </HelperActions>
                <ProfileInfo $collapsedSidebar={collapsedSidebar}>
                    <Link to={'/profile'} data-testid='LinkToProfile-Button'>
                        <AvatarContainer $collapsedSidebar={collapsedSidebar}>
                            <Avatar
                                alt='User Avatar'
                                src={avatarData && `data:image/jpeg;charset=utf-8;base64,${avatarData}`}
                            />
                        </AvatarContainer>
                        <UserInfoContainer $collapsedSidebar={collapsedSidebar}>
                            <UserPrimaryInfo variant='body1' gutterBottom align='center'>
                                {showUserInfo}
                            </UserPrimaryInfo>
                            <UserSecondaryInfo variant='body1' gutterBottom>
                                {roleUser.role ? roleUser.role.replaceAll(/ROLE_|_/g, ' ') : '---'}
                            </UserSecondaryInfo>
                        </UserInfoContainer>
                    </Link>
                </ProfileInfo>
                <CollapseMenuButton />
            </Menu>
        </Sidebar>
    );

    if (loading) {
        return <>{loading}</>;
    }

    return (
        <>
            {!matchesMd && mainMenu()}
            {matchesMd && (
                <MainMenuContentDrawer open={showSidebarResponsive} onClose={toggleSidebarResponsive}>
                    <LogoContainer>
                        <img src={getImgSource('logo')} alt='logo itrack'></img>
                    </LogoContainer>
                    {mainMenuMobile(true)}
                </MainMenuContentDrawer>
            )}
        </>
    );
};
