import React from 'react';
import * as type from './UiLegendContentTablet.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UiLegendContentTabletContent: React.FC<type.UiLegendContentTabletProps> = (props): JSX.Element => {
    return (
        <table className='legend-table' style={{ fontSize: '13px' }}>
            <tbody>
                {props.items.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td style={{ verticalAlign: 'top' }}>
                                <FontAwesomeIcon icon={['fas', item.icon]} />
                            </td>
                            <td style={{ verticalAlign: 'top', paddingBottom: '3px' }}>{item.title}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default UiLegendContentTabletContent;
