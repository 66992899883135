import styled from 'styled-components';
import Popover from '@mui/material/Popover';

export const VehicleDetailsActionMenuViewContent = styled.div``;

export const CustomPopover = styled(Popover)<{ top: number; left: number }>`
    &.MuiPopover-root {
        ${(props) => `
            top: ${props.top}px !important;
            left: ${props.left}px !important;
        `};
    }
`;
