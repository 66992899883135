import React, { useEffect, useReducer } from 'react';
import { HeatmapPlaygroundContent } from './HeatmapPlayground.view';
import {
    HeatmapPlaygroundProps,
    PlaygroundAction,
    PlaygroundActionEnums,
    PlaygroundObject
} from './HeatmapPlayground.type';
import { useRecoilState } from 'recoil';
import { HeatmapPlaygroundAtom } from './HeatmapPlayground.state';
import { Wrapper } from 'helpers/wrapper';

const PlaygroundReducer = (state: PlaygroundObject, action: PlaygroundAction): PlaygroundObject => {
    switch (action.type) {
        case PlaygroundActionEnums.SET_BLUR_INNER_OPACITY:
            return { ...state, [action.zoom]: { ...state[action.zoom], innerOpacity: action.payload } };
        case PlaygroundActionEnums.SET_BLUR_INNER_RADIUS:
            return { ...state, [action.zoom]: { ...state[action.zoom], innerRadius: action.payload } };
        case PlaygroundActionEnums.SET_BLUR_OUTER_RADIUS:
            return { ...state, [action.zoom]: { ...state[action.zoom], outerRadius: action.payload } };
        case PlaygroundActionEnums.SET_DATA_LIMIT:
            return { ...state, [action.zoom]: { ...state[action.zoom], data: action.payload } };
        case PlaygroundActionEnums.SET_DOT_OPACITY:
            return { ...state, [action.zoom]: { ...state[action.zoom], dotSize: action.payload } };
        case PlaygroundActionEnums.SET_DOT_SIZE:
            return { ...state, [action.zoom]: { ...state[action.zoom], dotSize: action.payload } };
        case PlaygroundActionEnums.SET_ALLOW_BLUR:
            return { ...state, [action.zoom]: { ...state[action.zoom], disableBlur: action.payload } };
        case PlaygroundActionEnums.SET_ALLOW_DOT_SIZE:
            return { ...state, [action.zoom]: { ...state[action.zoom], disableDotSize: action.payload } };
        default:
            return state;
    }
};

const HeatmapPlayground: React.FC<HeatmapPlaygroundProps> = (): JSX.Element => {
    const [playground, setPlayground] = useRecoilState<PlaygroundObject>(HeatmapPlaygroundAtom);
    const [localPlayground, setLocalPlayground] = useReducer(PlaygroundReducer, playground);

    useEffect(() => {
        setPlayground(localPlayground);
    }, [localPlayground]);

    return (
        <HeatmapPlaygroundContent
            playground={localPlayground}
            setLocalPlayground={setLocalPlayground}
            data-testid={'HeatmapPlayground-testid'}
        />
    );
};

export default Wrapper(HeatmapPlayground);
