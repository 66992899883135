import React, { useCallback, useState } from 'react';
import { ReportPermissionsContent } from './ReportPermissions.view';
import { ReportPermissionsProps } from './ReportPermissions.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import RoleApi from '../../api/Role';
import { RoleCodeBook } from '../../models/Role.type';
import ReportingApi from '../../api/Reporting';
import { Wrapper } from 'helpers/wrapper';

const Role = new RoleApi();
const Reporting = new ReportingApi();

const ReportPermissions: React.FC<ReportPermissionsProps> = (): JSX.Element => {
    const [selectedRole, setSelectedRole] = useState<RoleCodeBook>({
        id: 3,
        name: 'ROLE_TECHNICAL_USER'
    });
    const { data: availableRoles } = useQuery<RoleCodeBook[], Error, { codeBook: RoleCodeBook[] }>(
        ['AllRoles'],
        () => Role.getAvailable(),
        {
            refetchOnWindowFocus: true
        }
    );
    const { data: availableAsignReports, refetch: availableAssignRefetch } = useQuery(
        ['AvailableToAssingReports'],
        () => Reporting.getAvailableToAssign(),
        {
            refetchOnWindowFocus: true
        }
    );

    const { data: assignedReports, refetch: assignedRefetch } = useQuery(
        ['AssignedReports'],
        () => Reporting.getAssignedToRoles(),
        {
            refetchOnWindowFocus: false
        }
    );

    const { mutate: removeFromRoleMutate, isLoading: removeFromRoleMutateLoading } = useMutation(
        Reporting.removeFromRole,
        {
            onSuccess: () => {
                availableAssignRefetch();
                assignedRefetch();
            }
        }
    );

    const { mutate: assignToRoleMutate, isLoading: assignToRoleMutateLoading } = useMutation(Reporting.assignToRole, {
        onSuccess: () => {
            availableAssignRefetch();
            assignedRefetch();
        }
    });

    const removeFromRole = async (reportId: number) => {
        if (selectedRole?.id) {
            removeFromRoleMutate({
                reportingId: reportId,
                roleId: selectedRole?.id
            });
        }
    };

    const assignToRole = async (reportId: number) => {
        if (selectedRole?.id) {
            assignToRoleMutate({
                reportingId: reportId,
                roleId: selectedRole?.id
            });
        }
    };

    const reportPermissions = useCallback(() => {
        const filteredReportsByRole = assignedReports?.filter((reports) => reports.roleId === selectedRole?.id);
        const assignedReportsIds = filteredReportsByRole?.map((reports) => reports.reportingId);
        const assignedReportsData = availableAsignReports?.filter((report) =>
            (assignedReportsIds || []).includes(report.id)
        );
        const availableReportsData = availableAsignReports?.filter(
            (report) => !(assignedReportsIds || []).includes(report.id)
        );
        return { assignedReportsData, availableReportsData };
    }, [availableAsignReports, assignedReports, selectedRole]);

    return (
        <ReportPermissionsContent
            availableRoles={availableRoles?.codeBook ?? []}
            availableAsignReports={availableAsignReports}
            selectedRole={selectedRole}
            assignToRoleLoading={assignToRoleMutateLoading}
            removeFromRoleLoading={removeFromRoleMutateLoading}
            reportPermissions={reportPermissions}
            setSelectedRole={setSelectedRole}
            assignToRole={assignToRole}
            removeFromRole={removeFromRole}
            data-testid={'ReportPermissions-testid'}
        />
    );
};

export default Wrapper(ReportPermissions);
