import { VehicleList } from '../../models/Vehicle.type';
import { DateTime as LuxonDateTime } from 'luxon';
import { Dispatch } from 'react';

type PassProps = {
    currentTab: number;
    isBesGraph?: boolean;
};

export type StatisticsTopBarProps = {} & PassProps;

export type DateTimeType = {
    current: {
        dateFrom: LuxonDateTime;
        dateTo: LuxonDateTime;
    };
    original: {
        dateFrom: LuxonDateTime;
        dateTo: LuxonDateTime;
    };
};

export enum ZoomAction {
    ZOOM_IN = 'ZOOM_IN',
    PREVIOUS_ZOOM = 'PREVIOUS_ZOOM',
    RESET_ZOOM = 'RESET_ZOOM'
}

export type ZoomType = { zoomLevels: number[]; lastZoom: number[] };

export type StatisticsTopBarViewProps = {
    vehicleList: VehicleList;
    datePeriodIsValid: boolean;
    showWarningMsg: boolean;
    zoom: ZoomType;
    disableButton: boolean;
    setShowWarningMsg: Dispatch<boolean>;
    resetDateTime: () => void;
    previousZoom: () => void;
} & PassProps;
