import React, { useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { DeviceBatteryVoltageGraphContent } from './DeviceBatteryVoltageGraph.view';
import { DeviceBatteryVoltageGraphProps } from './DeviceBatteryVoltageGraph.type';
import { BatteryModel } from '../../../models/HubAdministration.type';
import { DatePickerAction } from '../../../pages/SeverityRank/atom';
import { Scale } from '../../Tyre/SeverityRankGraph/SeverityRankGraph.type';
import { DateTimePicker } from '../../../states/global/Statistics';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import Device from '../../../api/Device';
import { Wrapper } from 'helpers/wrapper';

const deviceApi = new Device();

const DeviceBatteryVoltageGraph: React.FC<DeviceBatteryVoltageGraphProps> = (props): JSX.Element => {
    const [timeSelected, setTimeSelected] = useState<number>(1);
    const { fromUTCtoUserTimezone, fromTimezoneToUTC } = useConverter();
    const setGlobalDateTimePicker = useSetRecoilState(DateTimePicker);
    const resetGlobalDateTimePicker = useResetRecoilState(DateTimePicker);
    const [scale, setScale] = useState<Scale>({
        left: [],
        right: []
    });

    // preparing for granularity and timeline
    /*const timeline = useMemo(() => {
        let timeline = {};
        Interval.fromDateTimes(
            globalDateTimePicker.current.dateFrom.toUTC(),
            globalDateTimePicker.current.dateTo.toUTC()
        )
            .splitBy({ minute: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                const timeKey = element.valueOf();
                timeline[timeKey] = { timeKey };
        });

        return {
            timeline,
            granularity: 60
        }
    }, [globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo]);*/

    const handleListItemClick = (event, index) => {
        const nedData = {
            current: {
                dateFrom: DateTime.local().minus({ days: index }),
                dateTo: DateTime.local()
            },
            original: {
                dateFrom: DateTime.local().minus({ days: index }),
                dateTo: DateTime.local()
            },
            action: DatePickerAction.APPLY
        };

        setGlobalDateTimePicker(nedData);

        setTimeSelected(index);
    };

    const parseBatteryVoltage = (batteryVoltage: BatteryModel[]) => {
        const valueLimit = 10000000;
        let min = valueLimit;
        let max = -valueLimit;

        // preparing for granularity and timeline
        //const copyTimeline = deepCopyObj(timeline);

        /*for(let i = 0, length = batteryVoltage.length; i < length; i++){
            if (batteryVoltage[i].batteryVoltage < min) {
                min = batteryVoltage[i].batteryVoltage;
            }
            if (batteryVoltage[i].batteryVoltage > max) {
                max = batteryVoltage[i].batteryVoltage;
            }

            copyTimeline.timeline[batteryVoltage[i]['measuredAt']/1000] =  {...batteryVoltage[i], measuredAt: fromUTCtoUserTimezone({date: batteryVoltage[i]['measuredAt'], format: 'dateTime'}),  timeKey: batteryVoltage[i]['measuredAt']/1000}
        }*/

        const data = batteryVoltage.map((item) => {
            if (item.batteryVoltage < min) {
                min = item.batteryVoltage;
            }
            if (item.batteryVoltage > max) {
                max = item.batteryVoltage;
            }
            return {
                ...item,
                measuredAt: fromUTCtoUserTimezone({ date: item['measuredAt'], format: 'dateTime' }),
                timeKey: item['measuredAt'] / 1000
            };
        });

        if (!batteryVoltage.length) {
            min = 0;
            max = 0;
        }

        setScale({
            left: [min, max],
            right: [min, max]
        });

        //return Object.values(copyTimeline.timeline);
        return data;
    };

    const getBatteryVoltage = async (dateFrom, dateTo) => {
        const batteryVolgateData = await deviceApi.getBatteryStats({
            deviceId: props.deviceId,
            dateTimeStart: fromTimezoneToUTC(dateFrom),
            dateTimeEnd: fromTimezoneToUTC(dateTo)
        });
        return parseBatteryVoltage(batteryVolgateData.items);
    };

    useEffect(() => {
        return () => resetGlobalDateTimePicker();
    }, []);

    return (
        <DeviceBatteryVoltageGraphContent
            setTimeSelected={setTimeSelected}
            timeSelected={timeSelected}
            data-testid='DeviceBatteryVoltageGraph-testid'
            getBatteryVoltage={getBatteryVoltage}
            scale={scale}
            handleListItemClick={handleListItemClick}
        />
    );
};

export default Wrapper(DeviceBatteryVoltageGraph);
