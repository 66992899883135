import { atom, selector } from 'recoil';
import { UserModel, UserSetting } from 'models/User.type';
import Account, { parseDateTime } from '../../api/Account';
import { ROLES } from 'variables';
import Translator from 'api/Translator';
import {
    AccelerationUnits,
    AltitudeUnits,
    DistanceUnits,
    PressureUnits,
    SpeedUnits,
    TemperatureUnits,
    UserSettingsType
} from 'components/CustomHooks/Converter/Converter.type';

const apiAccount = new Account();

export const Role = atom<ROLES>({
    key: 'role',
    default: ROLES.ROLE_SUPER_ADMIN
});

export const isAdmin = selector<boolean>({
    key: 'isAdmin-atom',
    get: ({ get }) => {
        const userRole = get(UserInfo).user?.role.name;
        return [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN].includes(
            userRole || ROLES.ROLE_SUPER_ADMIN
        );
    }
});

export const isSuperAdmin = selector<boolean>({
    key: 'isSuperAdmin-atom',
    get: ({ get }) => {
        const userRole = get(UserInfo).user?.role.name;
        return ROLES.ROLE_SUPER_ADMIN === userRole;
    }
});

export const isSuperOrAdmin = selector<boolean>({
    key: 'isSuperOrAdmin-atom',
    get: ({ get }) => {
        const userRole = get(Role);
        return [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN].includes(userRole);
    }
});

export const isUserOrEngineering = selector<boolean>({
    key: 'isUserOrEngineering-atom',
    get: ({ get }) => {
        const userRole = get(Role);
        return [ROLES.ROLE_ENGINEERING, ROLES.ROLE_TECHNICAL_USER, ROLES.ROLE_BASE_USER].includes(userRole);
    }
});

export const User = atom<UserModel | null>({
    key: 'user-atom',
    default: null
});

export const Setting = atom<UserSetting | null>({
    key: 'setting-atom',
    default: null
});

export const infoRole = selector({
    key: 'infoRole',
    get: ({ get }) => ({
        role: get(Role)
    })
});

export let GlobalUserInfo: { user: UserModel | null; version?: number } = { user: null };

export const UserInfo = selector({
    key: 'UserInfo',
    get: async ({ get }) => {
        let user = get(User);
        if (window.Cypress) {
            if (!GlobalUserInfo.user) {
                try {
                    const newUserData = await apiAccount.getParsed();

                    user = newUserData;
                    GlobalUserInfo = { user };
                } catch (error) {
                    false;
                }
            }
            return GlobalUserInfo;
        }
        GlobalUserInfo = { user };

        return user ? parseDateTime({ user }) : { user };
    }
});

export const UserUnits = selector({
    key: 'UserUnits',
    get: async ({ get }): Promise<UserSettingsType> => {
        const userSettings = await get(UserInfo)?.user?.userSetting;
        return {
            acceleration: userSettings?.accelerationUnit || AccelerationUnits.MS2,
            altitude: userSettings?.altitudeUnit || AltitudeUnits.M,
            distance: userSettings?.distanceUnit || DistanceUnits.KM,
            pressure: userSettings?.pressureUnit || PressureUnits.PSI,
            speed: userSettings?.speedUnit || SpeedUnits.KMH,
            temperature: userSettings?.temperatureUnit || TemperatureUnits.CELSIUS
        };
    }
});

export const UserTimeSettings = selector({
    key: 'UserTimeSettings',
    get: async ({ get }) => {
        const userSettings = get(User)?.userSetting;
        return {
            timezone: userSettings?.timezone?.timezoneName || 'Europe/Bratislava',
            dateFormat: userSettings?.dateFormat || 'YYYY/MM/DD',
            timeFormat: userSettings?.timeFormat || 'HH:mm'
        };
    }
});

// DON NOT REMOVE/MOVE - temp fix for translation, will be removed after full migration
export const TempTranslator = selector({
    key: 'TempTranslator',
    get: async () => {
        return (lang) => {
            try {
                const Trans = new Translator(lang);
                return Trans;
            } catch (error) {
                false;
            }
        };
    }
});

export const CheckForbiddenRedirection = atom<boolean>({
    key: 'CheckForbiddenRedirection',
    default: undefined
});
