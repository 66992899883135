import React, { DragEvent, useEffect, useRef, useState } from 'react';
import { DragAndDropContent } from './DragAndDrop.view';
import { DragAndDropProps } from './DragAndDrop.type';
import { DRAG_AND_DROP_VALID_TYPES, FILE_SIZES, MAX_FILE_SIZE } from 'variables';
import { useTranslation } from 'react-i18next';

const DragAndDrop: React.FC<DragAndDropProps> = (props): JSX.Element => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [uploadSize, setUploadSize] = useState<number>(0);
    const { t: translate } = useTranslation();
    const fileInput = useRef<HTMLInputElement>(null);

    const fileDrop = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        const files: FileList = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    };

    const handleFiles = (files: FileList): void => {
        let invalidFiles: string[] = [];
        for (let i = 0; i < files.length; i++) {
            const notUploaded: boolean = uploadedFiles.filter((file) => file.name === files[i].name).length === 0;
            if (notUploaded) {
                if (DRAG_AND_DROP_VALID_TYPES.indexOf(files[i].type) !== -1) {
                    setUploadedFiles((prevState) => [...prevState, files[i]]);
                } else {
                    invalidFiles.push(files[i].name);
                }
            }
        }
        if (invalidFiles.length) {
            setErrorMessage(`${translate('p.uploaded_wrong_file')} ${invalidFiles.join(', ')}`);
        }
    };

    const showFileSize = (size: number): string => {
        if (!size) return '0 Bytes';
        const index: number = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, index)).toFixed(0)} ${FILE_SIZES[index]}`;
    };

    const removeFile = (fileName: string): void => {
        const fileIndex: number = uploadedFiles.findIndex((file) => file.name === fileName);
        if (fileIndex !== -1) {
            let files = [...uploadedFiles];
            files.splice(fileIndex, 1);
            setUploadedFiles(files);
        }
    };

    const clickUpload = (): void => {
        fileInput.current?.click();
    };

    const updateAttachments = (): void => {
        if (fileInput.current?.files?.length) {
            handleFiles(fileInput.current?.files);
        }
    };

    useEffect(() => {
        const total: number = uploadedFiles.reduce((total, file) => {
            return total + file.size;
        }, 0);
        if (total > MAX_FILE_SIZE) {
            props.setError(true);
            setErrorMessage(translate('p.attachment_limit_reached'));
        } else {
            props.setError(false);
            setErrorMessage('');
        }
        setUploadSize(total);
        props.updateAttachments(uploadedFiles);
    }, [uploadedFiles]);

    return (
        <DragAndDropContent
            data-testid='DragAndDrop-testid'
            showFileSize={showFileSize}
            removeFile={removeFile}
            fileInput={fileInput}
            updateAttachment={updateAttachments}
            fileDrop={fileDrop}
            clickUpload={clickUpload}
            uploadedFiles={uploadedFiles}
            errorMessage={errorMessage}
            uploadedSize={uploadSize}
        />
    );
};

export default DragAndDrop;
