import React, { useEffect, useState } from 'react';
import { HistoricalLogProps } from './HistoricalLog.type';
import { HistoricalLogContent } from './HistoricalLog.view';
import { DateTimePicker, SelectedVehicle } from 'states/global/Statistics';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { AutocompleteWithMargin } from 'components/User/UserPermissionsTable/UserPermissinsTable.style';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import { ModelTyreEvent } from 'models/TpmsAlerts.type';
import {
    ALERT_LEVEL_0,
    ALERT_LEVEL_0_DARK,
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK
} from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';
import { NotificationType2Enum, NotificationTypeEnum } from 'models/NotificationEvent.type';

const HistoricalLog: React.FC<HistoricalLogProps> = (props): JSX.Element => {
    const [filterTyre, setFilterTyre] = useState<number>(0);
    const [filterLevel, setFilterLevel] = useState<number>(0);
    const [filterType, setFilterType] = useState<string>('all');
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const { fromServerToUserUnit, fromUTCtoUserTimezone, convertType } = useConverter();
    const { t: translate } = useTranslation();
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('historicalLogList-RefreshTableAtom'));
    const ThemeMode = useRecoilValue(Theme);

    const filterByLevel = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setFilterLevel(event.target.value as number);
    };

    const filterByTyre = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setFilterTyre(event.target.value as number);
    };

    const filterByType = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setFilterType(event.target.value as string);
    };

    const columns: Column<ModelTyreEvent>[] = [
        {
            Header: translate('t.tyre'),
            accessor: (row) => row.wheel?.customPosition,
            id: 'wheel.customPosition',
            width: 50,
            disableSortBy: true,
            BackgroundTr: (original: ModelTyreEvent) => {
                const alertColors = {
                    light: {
                        0: ALERT_LEVEL_0,
                        1: ALERT_LEVEL_1,
                        2: ALERT_LEVEL_2,
                        3: ALERT_LEVEL_3
                    },
                    dark: {
                        0: ALERT_LEVEL_0_DARK,
                        1: ALERT_LEVEL_1_DARK,
                        2: ALERT_LEVEL_2_DARK,
                        3: ALERT_LEVEL_3_DARK
                    }
                };

                return alertColors[ThemeMode?.mode][original?.notification?.level || 0];
            },
            Cell: (props: { row: { original: ModelTyreEvent } }) => (
                <>{props.row.original?.wheel?.customPosition || '---'}</>
            ),
            Filter: ({ column, setFilter }) => {
                const optionsList = [
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                    { label: '6', value: '6' }
                ];
                return (
                    <AutocompleteWithMargin
                        options={optionsList}
                        defaultValue={optionsList.find(
                            (level) => `${level.value}` === `${column.filterValue?.value}` ?? ''
                        )}
                        getOptionLabel={(option) => {
                            return (option as { label: string })?.label + '' || '';
                        }}
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: `${(newValue as { value: unknown }).value}`
                                  };
                            setFilter(column.id, value && value.value === '' ? undefined : value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<>{column.Header}</>}
                                variant='outlined'
                                name='type'
                                value={column.filterValue?.value || null}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                );
            }
        },
        {
            Header: translate('t.date'),
            accessor: 'measuredAt',
            disableFilters: true,
            disableSortBy: true,
            width: 130,
            Cell: (props: { row: { original: ModelTyreEvent } }) => (
                <>{fromUTCtoUserTimezone({ date: props.row.original?.measuredAt || 0, format: 'dateTime' })}</>
            )
        },
        {
            Header: translate('t.sensor'),
            accessor: (row: ModelTyreEvent) => row.sensor?.serialNumberHex,
            width: 60,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: ModelTyreEvent } }) => (
                <>{props.row.original?.sensor?.serialNumberHex || '---'}</>
            )
        },
        {
            Header: translate('t.level'),
            accessor: (row: ModelTyreEvent) => row.notification?.level,
            id: 'notification.level',
            disableSortBy: true,
            width: 40,
            Cell: (props: { row: { original: ModelTyreEvent } }) => (
                <>{props.row.original?.notification?.level || '---'}</>
            ),
            Filter: ({ column, setFilter }) => {
                const optionsList = [
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' }
                ];
                return (
                    <AutocompleteWithMargin
                        options={optionsList}
                        defaultValue={optionsList.find(
                            (level) => `${level.value}` === `${column.filterValue?.value}` ?? ''
                        )}
                        getOptionLabel={(option) => {
                            return (option as { label: string })?.label + '' || '';
                        }}
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: `${(newValue as { value: unknown }).value}`
                                  };
                            setFilter(column.id, value && value.value === '' ? undefined : value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<>{column.Header}</>}
                                variant='outlined'
                                name='level'
                                value={column.filterValue?.value || null}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                );
            }
        },
        {
            Header: translate('t.type'),
            accessor: (row: ModelTyreEvent) => row.notification?.type,
            id: 'notification.type',
            disableSortBy: true,
            width: 110,
            Cell: (props: { row: { original: ModelTyreEvent } }) => {
                if (props.row.original?.notification?.type2 && props.row.original?.notification?.type) {
                    return (
                        <>{`${translate(`t.${props.row.original?.notification?.type2}`)} 
                        ${translate(`t.${props.row.original?.notification?.type}`)}`}</>
                    );
                }
                return <>---</>;
            },
            Filter: ({ column, setFilter }) => {
                const optionsList = [
                    { label: translate('t.temperature'), value: 'temperature' },
                    { label: translate('t.pressure'), value: 'pressure' }
                ];
                return (
                    <AutocompleteWithMargin
                        options={optionsList}
                        defaultValue={optionsList.find(
                            (level) => `${level.value}` === `${column.filterValue?.value}` ?? ''
                        )}
                        getOptionLabel={(option) => {
                            return (option as { label: string })?.label + '' || '';
                        }}
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: `${(newValue as { value: unknown }).value}`
                                  };
                            setFilter(column.id, value && value.value === '' ? undefined : value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<>{column.Header}</>}
                                variant='outlined'
                                name='type'
                                value={column.filterValue?.value || null}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                );
            }
        },
        {
            Header: translate('t.current'),
            accessor: (row: ModelTyreEvent) => row.tyreEventLog?.coldPressure,
            disableFilters: true,
            disableSortBy: true,
            width: 80,
            Cell: (props: { row: { original: ModelTyreEvent } }) => {
                if (props.row.original?.notification?.type === 'temperature') {
                    return (
                        <>
                            {fromServerToUserUnit({
                                type: convertType.temperature,
                                value: parseFloat(props.row.original?.tyreEventLog?.temperature) || 0,
                                displayUnits: true,
                                fixed: 1
                            })}
                        </>
                    );
                }
                if (props.row.original.notification.type === 'pressure_hot') {
                    return (
                        <>
                            {fromServerToUserUnit({
                                type: convertType.pressure,
                                value: parseFloat(props.row.original?.tyreEventLog?.hotPressure) || 0,
                                displayUnits: true,
                                fixed: 1
                            })}
                        </>
                    );
                }
                return (
                    <>
                        {fromServerToUserUnit({
                            type: convertType.pressure,
                            value: parseFloat(props.row.original?.tyreEventLog?.coldPressure) || 0,
                            displayUnits: true,
                            fixed: 1
                        })}
                    </>
                );
            }
        },
        {
            Header: translate('t.limit_crossed'),
            accessor: (row: ModelTyreEvent) => row.ruleLimit.limitTo,
            disableFilters: true,
            disableSortBy: true,
            width: 110,
            Cell: (props: { row: { original: ModelTyreEvent } }) => {
                const {
                    notification: { type, type2 },
                    ruleLimit: { limitFrom, limitTo }
                } = props.row.original;

                return type2 === NotificationType2Enum.HIGH ? (
                    limitFrom ? (
                        <>
                            {fromServerToUserUnit({
                                type:
                                    type === NotificationTypeEnum.TEMPERATURE
                                        ? convertType.temperature
                                        : convertType.pressure,
                                value: limitFrom || 0,
                                displayUnits: true,
                                fixed: 1
                            })}
                        </>
                    ) : (
                        <>-</>
                    )
                ) : limitTo ? (
                    <>
                        {fromServerToUserUnit({
                            type:
                                type === NotificationTypeEnum.TEMPERATURE
                                    ? convertType.temperature
                                    : convertType.pressure,
                            value: limitTo || 0,
                            displayUnits: true,
                            fixed: 1
                        })}
                    </>
                ) : (
                    <>-</>
                );
            }
        }
    ];

    useEffect(() => {
        setRefreshTableAtom(true);
    }, [statisticsDateTime, selectedVehicle]);

    return (
        <HistoricalLogContent
            data-testid='HistoricalLog-testid'
            filterLevel={filterLevel}
            filterByLevel={filterByLevel}
            filterTyre={filterTyre}
            filterByTyre={filterByTyre}
            filterType={filterType}
            filterByType={filterByType}
            columns={columns}
            {...props}
        />
    );
};

export default Wrapper(HistoricalLog);
