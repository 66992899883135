import { IconButton, Paper } from '@mui/material';
import { styled as styleMui } from '@mui/system';

export const AlertsAffectedVehiclesViewContent = styleMui(Paper)({
    padding: '5px 10px'
});

export const ExpandIconButton = styleMui(IconButton)((props: { expand: boolean }) => ({
    transform: props.expand ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 300ms linear',
    marginLeft: 'auto'
}));
