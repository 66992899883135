import { Grid, styled } from '@mui/material';

export const WheelPositionViewContent = styled(Grid)`
    &&& .MuiTextField-root {
        & label {
            padding: 0 5px 0 0;
            background: transparent;
        }
    }
`;
