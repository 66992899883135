import React from 'react';
import { DeviceDiagnoseContent } from './DeviceDiagnose.view';
import { DeviceDiagnoseProps } from './DeviceDiagnose.type';
import DeviceDiagnoseApi from '../../../api/DeviceDiagnose';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';

const deviceDiagnoseAPI = new DeviceDiagnoseApi();

const DeviceDiagnose: React.FC<DeviceDiagnoseProps> = (props): JSX.Element => {
    const { data, refetch, isFetching, isFetched } = useQuery(
        ['deviceDiagnoseById'],
        () =>
            deviceDiagnoseAPI.getById({
                id: props.id
            }),
        {
            refetchOnWindowFocus: false,
            enabled: true,
            retry: false,
            cacheTime: 0
        }
    );

    return (
        <DeviceDiagnoseContent
            data={data}
            isFetched={isFetched}
            refetch={refetch}
            isLoading={isFetching}
            data-testid='DeviceDiagnoseTable-testid'
        />
    );
};

export default Wrapper(DeviceDiagnose);
