import { CircularProgress, Popover } from '@mui/material';
import React from 'react';
import { PopoverViewProps } from './TablePopover.type';
import { CustomUiButton, LoadingContent, PopoverContainer } from './TablePopover.style';
import { BACKGROUND, BACKGROUND_DARK, PRIMARY_DARK } from '../Ui/colors';
import PopoverListContent from '../PopoverListContent/PopoverListContent';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
// import module

export const PopoverContent: React.FC<PopoverViewProps> = (props): JSX.Element => {
    const [openToolTip, setToolTipOpen] = React.useState(false);
    const { t: translate } = useTranslation();
    return (
        <PopoverContainer data-testid='PopoverContent'>
            <Tooltip
                open={openToolTip}
                onClose={() => {
                    setToolTipOpen(false);
                }}
                onOpen={() => {
                    setToolTipOpen(true);
                }}
                title={`${translate(`t.${props.dataType}`)}  ${translate(`t.total`)} 
                 ${props.transmitting ? translate(`t.transmitting`) : translate(`t.not_transmitting`)} 
                    - ${translate(`t.${props.stateType == 'outOfService' ? 'out_of_service' : props.stateType}`)}`}
            >
                <div>
                    <CustomUiButton
                        style={{
                            minWidth: '25px',
                            maxWidth: '25px',
                            transform: 'scale(1.1)'
                        }}
                        skin={BACKGROUND}
                        textcolor={PRIMARY_DARK}
                        color='secondary'
                        variant='contained'
                        onClick={(event) =>
                            props.handleClick(
                                {
                                    dataType: props.dataType,
                                    stateType: props.stateType,
                                    transmitting: props.transmitting
                                },
                                event
                            )
                        }
                    >
                        {props.value}
                    </CustomUiButton>
                </div>
            </Tooltip>
            <Popover
                id={'popover'}
                open={Boolean(props.anchorEl)}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                color={BACKGROUND_DARK}
                style={{
                    overflowY: 'auto'
                }}
            >
                {props.isLoading ? (
                    <LoadingContent>
                        <CircularProgress />
                    </LoadingContent>
                ) : (
                    <PopoverListContent data={props.data} />
                )}
            </Popover>
        </PopoverContainer>
    );
};
