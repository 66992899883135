import {
    AddUserActions,
    AddUserState,
    UserAvatarActionType,
    UserLoadingActionType,
    UserPreviewImgActionType
} from './AddUserForm.type';

export const AddUserInitalState: AddUserState = {
    userAvatar: null,
    previewImg: '',
    userLoading: false
};

export const AddUserReducer = (
    state: AddUserState,
    action: UserAvatarActionType | UserLoadingActionType | UserPreviewImgActionType
) => {
    switch (action.type) {
        case AddUserActions.SET_USER_AVATAR:
            return {
                ...state,
                userAvatar: action.payload
            };
        case AddUserActions.SET_USER_PREVIEW_IMG:
            return {
                ...state,
                previewImg: action.payload
            };
        case AddUserActions.SET_USER_LOADING:
            return {
                ...state,
                userLoading: action.payload
            };
        default:
            return state;
    }
};
