import { atom } from 'recoil';

type FiltersTypes = {
    sortBy: { column: string; order: 'desc' | 'asc' } | null;
};

export const TempPressFilters = atom<FiltersTypes>({
    key: 'TempPressFilters',
    default: {
        sortBy: null
    }
});

export const RefreshPressTempTable = atom<boolean>({
    key: 'RefreshPressTempTable',
    default: false
});
