import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiMapComponentContent = styled.div<{ right?: string; top?: string }>`
    position: absolute;
    z-index: 999;
    display: block;
    border: solid thick rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    ${({ right, top }) => {
        return `
            right: ${right ? right : '1.4em'};
            top: ${top ? top : '78%'};
        `;
    }}
`;

export const UiMapComponentChildrenContent = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            background: ${useTheme().palette.background.paper};
            font-size: ${common.titleSize};
            border-radius: 4px;
            padding: 15px;
        `;
    }}
`;
