import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as type from './TopBar.type';
import * as view from './TopBar.view';
import { SidebarMenu } from '../../states/global/SidebarMenu';
import { Theme } from '../../states/global/Theme';
import { Wrapper } from 'helpers/wrapper';

const TopBar: React.FC<type.TopBarProps> = ({ title, loading }) => {
    const [sidebarMenu, setSidebarMenu] = useRecoilState(SidebarMenu);
    const theme = useRecoilValue(Theme);

    const showSidebarResponsive = () => {
        setSidebarMenu({
            ...sidebarMenu,
            showMenuResponsive: true
        });
    };

    const toggleSidebar = () => {
        setSidebarMenu({
            ...sidebarMenu,
            showMenu: !sidebarMenu.showMenu
        });
    };

    return (
        <view.TopBarContent
            title={title}
            data-testid={'TopBar-testid'}
            showMenu={sidebarMenu.showMenu}
            themeMode={theme.mode}
            loading={loading}
            toggleSidebar={toggleSidebar}
            showSidebarResponsive={showSidebarResponsive}
        />
    );
};

export default Wrapper(TopBar);
