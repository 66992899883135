import styled from 'styled-components';
import { TextField } from '@mui/material';

export const ExcessiveAccViewContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const Img = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;

export const LoadedStatus = styled(TextField)`
    &.MuiTextField-root {
        width: 200px;
        margin-left: 4px;
    }
`;
