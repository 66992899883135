import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker, StaticDatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { useTheme } from '@mui/material';

export const UiDatePickerContent = styled.div<{ fullWidth?: boolean }>`
    display: inline-block;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    ${() => {
        return applyStyleByMode({
            styleJade: `
                &&& { 
                    .MuiTextField-root {
                        margin: 8px;
                        margin-left: 0px;
                        & label {
                            padding: 0 5px 0 0;
                            background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                        }
                        & .MuiInputLabel-shrink {
                            transform: translate(12px, -9px) scale(0.8);
                        }
                    }
                }
            }
        `
        });
    }}
`;

export const CustomStaticDatePicker = styled(StaticDatePicker)`
    height: 400px;
`;

export const CustomStaticDateTimePicker = styled(StaticDateTimePicker)`
    height: 400px;
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)<{ $noMargin?: boolean }>`
    &.MuiFormControl-root {
        margin: ${(props) => (props.$noMargin ? '0 !important' : '0 3px 3px 0 !important')};
    }
    @media (max-width: 426px) {
        &.MuiFormControl-root {
            margin: 0 3px 10px 0 !important;
        }
    }
    ${applyStyleByMode({
        styleOld: `
            && .MuiInputBase-input {
                padding: 8.5px 0px 8.5px 14px !important;
            }
        `
    })}
`;
