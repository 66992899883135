import { Control } from 'ol/control';
import LowPressureIcon from '../../assets/svg/pencilSolid.svg';

export class DrawControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        const pencil = document.createElement('i');
        pencil.className = 'fa fa-pencil';
        const img = document.createElement('img');
        img.src = LowPressureIcon;
        img.alt = 'Icono of Pencil';
        img.width = 11;
        button.appendChild(img);

        const element = document.createElement('div');
        element.className = 'start-draw ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('click', opt_options.onClick, false);
    }
}

export class CloseDrawControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = 'X';

        const element = document.createElement('div');
        element.className = 'end-draw ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        button.addEventListener('click', opt_options.onClick, false);
    }
}
