import React, { useEffect } from 'react';
import { NotificationSettingsProps } from './NotificationSettings.type';
import { NotificationSettingsContent } from './NotificationSettings.view';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const NotificationSettings: React.FC<NotificationSettingsProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'administration-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <NotificationSettingsContent data-testid={'NotificationSettings-testid'} />;
};

export default Wrapper(NotificationSettings);
