import { Button, TableBody, TableCell, TableRow, styled as styledMui } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
export const TableBodyEl = styledMui(TableBody)<{
    rowCount: number;
    customHeight?: number;
}>(
    (props) =>
        `
    display: block;      
    height: ${props.customHeight || props.rowCount * 50 || 500}px;
    max-height: ${props.customHeight || props.rowCount * 50 || 500}px;
    td {
        ${applyStyleByMode({
            styleOld: `
            font-size: 1em !important;
            line-height: 21px !important;
            `
        })}
    }
    `
);

export const TableCellEl = styledMui(TableCell)({
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
});

export const ActionButton = styledMui(Button)`
    min-width: 20px !important;
    max-width: 20px !important;
`;

export const ActionTableCell = styledMui(TableCell)({
    textAlign: 'center'
});

export const EmptyTable = styledMui(TableCell)`
    height: 70px;
    border-bottom: #e5e5e5 solid thin;
    text-align: center !important;
`;

export const CustomTableRow = styled(TableRow)<{ $keyRow: number }>`
    ${({ $keyRow }) => {
        return `

                ${
                    $keyRow % 2 !== 0
                        ? `background-color: ${
                              useTheme().palette.mode === ThemeMode.dark ? '#1d2630de' : 'rgba(0, 0, 0, 0.04)'
                          } !important;`
                        : ''
                }
            `;
    }}
`;

export const CustomTableRow2 = styled(TableRow)<{ $notData?: boolean; $background?: string; $keyRow: number }>`
    ${({ $notData, $background, $keyRow }) => {
        return `
                justify-content: center;
                display: ${$notData ? 'flex' : 'inline'} ;

                &:nth-child(even) {
                    background-color: ${
                        $background
                            ? $background
                            : useTheme().palette.mode === ThemeMode.dark
                            ? '#1d2630de'
                            : 'rgba(0, 0, 0, 0.04)'
                    };
                }

                ${
                    $keyRow % 2 !== 0 && !$background
                        ? `background-color: ${
                              useTheme().palette.mode === ThemeMode.dark ? '#1d2630de' : 'rgba(0, 0, 0, 0.04)'
                          } !important;`
                        : ''
                };
                ${$background ? `background-color: ${$background}` : ''};
            `;
    }}
`;
