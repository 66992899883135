import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const AnnouncementsActionsContainer = styled.div`
    font-size: 1.25rem;
`;

export const FormDiv = styled.div`
    margin-top: 16px;
    font-size: 1.25rem;
`;

export const RequestProgress = styled(CircularProgress)({
    color: '#4f714f !important',
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});

export const LoadingCover = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
