import PortalApi from 'helpers/api/PortalApiClient';
import {
    ExternalSystemMddBesTrackLogModel,
    TracklogHeatmapProps,
    TracklogHeatmapResponse,
    TracklogResponse
} from '../models/ExternalSystemMddBesTrackLog.type';

export default class ExternalSystemMddBesTrackLog extends PortalApi<ExternalSystemMddBesTrackLogModel> {
    route = 'external-system-mdd-bes-track-log';

    getTracklog = async (): Promise<TracklogResponse['externalSystemMddBesTrackLog']> => {
        return await this.get<TracklogResponse>({}).then((d) => d['externalSystemMddBesTrackLog']);
    };

    getHeatmap = async (
        props: TracklogHeatmapProps
    ): Promise<TracklogHeatmapResponse['externalSystemMddBesTrackLog']> => {
        return await this.getByCriteria<TracklogResponse>({
            extendUrl: 'heatmap',
            criteria: {
                zoomLevel: props.zoom,
                area: props.area
            }
        }).then((d) => d['externalSystemMddBesTrackLog']);
    };
}
