import { CustomerCodebook } from './Customer.type';
import { VehicleModel } from './Vehicle.type';

export enum RouteHash {
    VEHICLE_STAS_MAP = 'vehicleStatsMap'
}

export type VehicleStatsParams = {
    v: VehicleModel['id'];
    ldf: string;
    ldt: string;
    l: string;
};

export type LinkHashModel = {
    route: RouteHash;
    customerId: CustomerCodebook['id'];
    params: VehicleStatsParams;
};
