import React, { useEffect, useState } from 'react';
import { BugReporterContent } from './BugReporter.view';
import { FormikValues } from 'formik';
import { Success } from '../../Popup';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { BugReportModel } from '../../../models/BugReport.type';
import BugReport from '../../../api/BugReport';
import { BugReporterAtom } from './BugReporter.atom';
import { BugReporterProps } from './BugReporter.type';
import { useProSidebar } from 'react-pro-sidebar';
import { Wrapper } from 'helpers/wrapper';

const bugReportAPI = new BugReport();
const BugReporter: React.FC<BugReporterProps> = ({ isNavigationButton, mobileMode }): JSX.Element => {
    const [hasAttachmentError, setHasAttachmentError] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<File[]>();
    const [close, setClose] = useRecoilState(BugReporterAtom);
    const { t: translate } = useTranslation();
    const { collapsed } = useProSidebar();

    const mutation = useMutation<BugReportModel, unknown, FormData>(
        (form) =>
            bugReportAPI.post({
                apiProject: undefined,
                data: form
            }),
        {
            onSuccess: () => {
                Success({ text: translate('p.ticket_created') });
                setClose(true);
            }
        }
    );

    const setError = (error: boolean): void => {
        setHasAttachmentError(error);
    };

    const getUploadedAttachments = (files: File[]): void => {
        setAttachments(files);
    };

    const handleRaiseRequest = async (formikValues: FormikValues): Promise<void> => {
        let formData = new FormData();
        formData.append('page', formikValues.page.value);
        formData.append('title', formikValues.title);
        formData.append('message', formikValues.description);
        formData.append('priority', formikValues.jiraPriority?.jiraIssue.jiraPriorityId);
        formData.append('projectId', formikValues.jiraProject.id);
        formData.append('typeId', formikValues.jiraPriority.id);
        if (attachments?.length) {
            for (let i = 0, length = attachments.length; i < length; i++) {
                formData.append('attachments[]', attachments[i]);
            }
        }
        mutation.mutate(formData);
    };

    useEffect(() => {
        if (close) setClose(false);
    }, [close]);

    return (
        <BugReporterContent
            data-testid='BugReporter-testid'
            collapsedSidebar={collapsed}
            hasAttachmentError={hasAttachmentError}
            isProcessing={mutation.isLoading}
            close={close}
            isNavigationButton={isNavigationButton}
            mobileMode={mobileMode}
            getUploadedAttachments={getUploadedAttachments}
            setError={setError}
            handleRaiseRequest={handleRaiseRequest}
        />
    );
};

export default Wrapper(BugReporter);
