import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PeriodType, RangeActionButtonsProps } from './RangeActionButtons.type';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, ShowAlerts, StatusDataPressureGraph, Timeline } from 'states/component/FaultySensorReporter';
import { DatePickerAction } from 'pages/SeverityRank/atom';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { calculateGranularity, createTimeline } from 'helpers';
import { Fab, Grid } from '@mui/material';
import { faArrowRightFromLine, faArrowLeftFromLine } from '@fortawesome/pro-solid-svg-icons';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

const RangeActionButtons: React.FC<RangeActionButtonsProps> = ({
    resetZoom,
    showRangeButtons,
    resetZoomAction
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [dateTimePicker, setDateTimePicker] = useRecoilState(DateTimePicker);
    const statusDataPressureGraph = useRecoilValue(StatusDataPressureGraph);
    const setTimeline = useSetRecoilState(Timeline);
    const [invalidDate, setInvalidDate] = useState<boolean>(false);
    const [showAlerts, setShowAlerts] = useRecoilState(ShowAlerts);
    const [mapAction, setMapAction] = useRecoilState(MapAction);

    const changeDateRangeByHours = (props: { action: string; period: number; typePeriod: PeriodType }): void => {
        let newDateFrom = dateTimePicker.current.dateFrom;
        let newDateTo = dateTimePicker.current.dateTo;

        if (props.action === 'plus') {
            newDateFrom = newDateFrom.plus({ hours: props.period * getPeriodSelected(props.typePeriod) });
            newDateTo = newDateTo.plus({ hours: props.period * getPeriodSelected(props.typePeriod) });
        }

        if (props.action === 'minus') {
            newDateFrom = newDateFrom.minus({ hours: props.period * getPeriodSelected(props.typePeriod) });
            newDateTo = newDateTo.minus({ hours: props.period * getPeriodSelected(props.typePeriod) });
        }

        if (newDateTo.valueOf() > DateTime.now().valueOf()) {
            setInvalidDate(true);
        } else {
            setDateTimePicker({
                current: {
                    dateFrom: newDateFrom,
                    dateTo: newDateTo
                },
                original: { ...dateTimePicker.original },
                action: DatePickerAction.APPLY
            });

            const granularity = calculateGranularity(newDateFrom, newDateTo);

            setTimeline({
                timeline: createTimeline(granularity, newDateFrom, newDateTo),
                granularity
            });

            invalidDate && setInvalidDate(false);
        }
    };

    const getPeriodSelected = (periodType: PeriodType) => {
        switch (periodType) {
            case 'days':
                return 1;
            case 'weeks':
                return 7;
            case 'months':
                return 30;
            default:
                return 1;
        }
    };

    return (
        <Grid data-testid='RangeActionButtonsContent' sx={{ paddingTop: '20px' }} container>
            <Grid item sm={12} md={11}>
                <Grid container>
                    {showRangeButtons ? (
                        <>
                            <Grid item md='auto' paddingTop='5px'>
                                <UiButton
                                    skin='primary'
                                    variant='contained'
                                    testid='MinusPlus-Date-Action'
                                    sx={{ marginLeft: '0px !important' }}
                                    onClick={() => {
                                        changeDateRangeByHours({ action: 'minus', period: 24, typePeriod: 'weeks' });
                                    }}
                                    disabled={statusDataPressureGraph.loading}
                                >
                                    <FontAwesomeIcon icon={['fas', 'chevrons-left']} />
                                </UiButton>
                                <UiButton
                                    skin='primary'
                                    variant='contained'
                                    testid='Minus-Date-Action'
                                    onClick={() => {
                                        changeDateRangeByHours({ action: 'minus', period: 24, typePeriod: 'days' });
                                    }}
                                    disabled={statusDataPressureGraph.loading}
                                >
                                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                                </UiButton>
                            </Grid>
                            <Grid item md='auto'>
                                <UiDateRangePicker2
                                    adjustToPeriod={{ period: 7, periodType: PeriodTypeEnum.DAYS }}
                                    dateFrom={dateTimePicker.current.dateFrom}
                                    dateTo={dateTimePicker.current.dateTo}
                                    dateFromLabel={translate('t.date_from')}
                                    dateToLabel={translate('t.date_to')}
                                    onDateChange={(dateRange) => {
                                        if (
                                            dateRange != null &&
                                            !dateRange[1].invalid &&
                                            !dateRange[0].invalid &&
                                            dateRange[1] != '' &&
                                            dateRange[0] != ''
                                        )
                                            setDateTimePicker({
                                                current: {
                                                    dateFrom: dateRange[0],
                                                    dateTo: dateRange[1]
                                                },
                                                original: { ...dateTimePicker.original },
                                                action: DatePickerAction.APPLY
                                            });
                                    }}
                                />
                            </Grid>
                            <Grid item md='auto' paddingTop='5px'>
                                <UiButton
                                    variant='contained'
                                    testid='Add-Date-Action'
                                    skin='primary'
                                    onClick={() => {
                                        changeDateRangeByHours({ action: 'plus', period: 24, typePeriod: 'days' });
                                    }}
                                    disabled={statusDataPressureGraph.loading || invalidDate}
                                >
                                    <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                                </UiButton>
                                <UiButton
                                    variant='contained'
                                    testid='AddPlus-Date-Action'
                                    skin='primary'
                                    onClick={() => {
                                        changeDateRangeByHours({ action: 'plus', period: 24, typePeriod: 'weeks' });
                                    }}
                                    disabled={statusDataPressureGraph.loading || invalidDate}
                                >
                                    <FontAwesomeIcon icon={['fas', 'chevrons-right']} />
                                </UiButton>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                    <Grid item md='auto'>
                        <UiButton
                            variant='outlined'
                            sx={{ visibility: resetZoom ? 'visible' : 'hidden' }}
                            onClick={() => {
                                resetZoomAction();
                                invalidDate && setInvalidDate(false);
                            }}
                        >
                            {showRangeButtons ? translate('t.reset') : translate('t.reset_zoom')}
                        </UiButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{ display: { sm: 'none', md: 'block' } }} md={1}>
                <Fab
                    size='small'
                    color='primary'
                    sx={{
                        float: 'right',
                        marginRight: '5px'
                    }}
                    onClick={() => {
                        setShowAlerts((currentValue) => !currentValue);
                        setMapAction({
                            action: MapActionsEnum.RESIZE_MAP,
                            params: {
                                lastAction: mapAction
                            }
                        });
                    }}
                >
                    <FontAwesomeIcon icon={showAlerts ? faArrowRightFromLine : faArrowLeftFromLine} />
                </Fab>
            </Grid>
        </Grid>
    );
};

export default RangeActionButtons;
