import React from 'react';
import * as style from './UiFullScreenLoader.style';
import { useRecoilValue } from 'recoil';
import { UiFullScreenLoaderAtom } from './UiFullScreenLoader.atom';
import UiLoadingPage from '../UiLoadingPage/UiLoadingPage';

const UiFullScreenLoader: React.FC = () => {
    const spinnerState = useRecoilValue(UiFullScreenLoaderAtom);
    return (
        <style.SpinnerContainer open={spinnerState.open}>
            <UiLoadingPage data-testid='FullScreen-CircularProgress' />
        </style.SpinnerContainer>
    );
};

export default UiFullScreenLoader;
