import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertSettinOptimalViewContent } from './AlertSettinOptimal.style';
import { AlertSettinOptimalProps } from './AlertSettinOptimal.type';
import { CustomAlertsLevelCheckbox, PressureLevelsSelector, pressureDecimals } from '../AlertSettings/atom';
import { AlertInput } from '../AlertSettingInput/AlertSettingInput.style';
import { InputAdornment, Tooltip, useTheme } from '@mui/material';
import { AlertLevelEnum } from 'models/Alerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { UserInfo, isSuperOrAdmin } from 'states/global/User';

const AlertSettinOptimalContent: React.FC<AlertSettinOptimalProps> = (): JSX.Element => {
    const [levelState, setLevelState] = useRecoilState(PressureLevelsSelector(0));
    const setLevelLow3 = useSetRecoilState(PressureLevelsSelector(-3));
    const setLevelLow2 = useSetRecoilState(PressureLevelsSelector(-2));
    const setLevelLow1 = useSetRecoilState(PressureLevelsSelector(-1));
    const setLevelHigh1 = useSetRecoilState(PressureLevelsSelector(1));
    const setLevelHigh2 = useSetRecoilState(PressureLevelsSelector(2));
    const setLevelHigh3 = useSetRecoilState(PressureLevelsSelector(3));
    const setLevelHighHot1 = useSetRecoilState(PressureLevelsSelector(11));
    const setLevelHighHot2 = useSetRecoilState(PressureLevelsSelector(12));
    const setLevelHighHot3 = useSetRecoilState(PressureLevelsSelector(13));
    const theme = useTheme();
    const { displayUserUnits } = useConverter();
    const isAdmin = useRecoilValue(isSuperOrAdmin);
    const userInfo = useRecoilValue(UserInfo);
    const customAlerts = useRecoilValue(CustomAlertsLevelCheckbox);

    const delta = useMemo(() => {
        const fixed = pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi'];
        return 1 / Math.pow(10, fixed);
    }, [userInfo.user?.userSetting.pressureUnit]);

    const recalculateLevels = () => {
        setLevelLow3((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        setLevelLow2((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        setLevelLow1((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        setLevelHigh1((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        setLevelHigh2((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        setLevelHigh3((current) => ({
            ...current,
            action: AlertLevelEnum.RECALCULATE_VALUE
        }));
        if (!customAlerts) {
            setLevelHighHot1((current) => ({
                ...current,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
            setLevelHighHot2((current) => ({
                ...current,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
            setLevelHighHot3((current) => ({
                ...current,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
        }
    };

    return (
        <AlertSettinOptimalViewContent data-testid='AlertSettinOptimalContent'>
            <Tooltip open={levelState.valueError} title={'Value will be adjusted to 0'} arrow>
                <AlertInput
                    id='optimal-pressure-input'
                    type='number'
                    size='small'
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>{displayUserUnits.pressure}</InputAdornment>,
                        inputProps: {
                            min: 0,
                            step: delta
                        },
                        readOnly: !isAdmin
                    }}
                    theme={theme}
                    error={levelState.valueError}
                    onBlur={() => {
                        levelState.valueError &&
                            setLevelState((current) => ({
                                ...current,
                                value: 0,
                                action: AlertLevelEnum.SET_VALUE
                            }));
                    }}
                    value={levelState.value}
                    onChange={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            value: e.target.value,
                            action: AlertLevelEnum.SET_VALUE
                        }));
                        recalculateLevels();
                    }}
                />
            </Tooltip>
        </AlertSettinOptimalViewContent>
    );
};

export default AlertSettinOptimalContent;
