import React from 'react';
import { TableDetail, TitleItems } from './ExportedReportDetail.style';
import { ExportedReportTableProps } from './ExportedReportDetail.type';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const createRow = (Cells: { label: string; value: string | number }[]): JSX.Element => {
    return (
        <>
            {Cells.map((cell, key) => {
                return (
                    <TableRow key={key}>
                        <TableCell padding='checkbox'>
                            <TitleItems $bold gutterBottom={true}>
                                {cell.label}:{' '}
                            </TitleItems>
                        </TableCell>
                        <TableCell align='right' padding='checkbox'>
                            {cell.value}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const ExportedReportTable: React.FC<ExportedReportTableProps> = ({ title, cells }): JSX.Element => {
    return (
        <>
            <TitleItems align='center' variant='h5' $bold>
                {title}
            </TitleItems>
            <TableDetail>
                <TableBody>{createRow(cells)}</TableBody>
            </TableDetail>
        </>
    );
};
