import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { StatisticsDateTimePicker, SensorStatisticsContainer, ButtonWrapper } from './SensorStatistics.style';
import { SensorStatisticsViewProps } from './SensorStatistics.type';
import Graph from 'components/Graph';
import { graphTicksFromDateTime } from 'helpers';
import { DateTimePicker } from 'states/global/Statistics';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useTranslation } from 'react-i18next';
import UiDateRangePicker from 'components/Ui/Components/UiDateRangePicker2';
import { DatePickerAction } from 'pages/SeverityRank/atom';
import UiButton from 'components/Ui/Components/UiButton/UiButton';

export const SensorStatisticsContent: React.FC<SensorStatisticsViewProps> = ({
    xDomainConfig,
    yDomain2,
    getStatistic,
    csvExport,
    sensorType
}): JSX.Element => {
    const { fromUTCtoUserTimezone } = useConverter();
    const { t: translation } = useTranslation();
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);

    const RenderGraph = useCallback(() => {
        return (
            <Graph
                xDomainConfig={xDomainConfig}
                yDomainConfig={yDomain2}
                xTicks={graphTicksFromDateTime(
                    globalDateTimePicker.current.dateFrom,
                    globalDateTimePicker.current.dateTo,
                    4
                )}
                globalXDomain={globalDateTimePicker}
                setGlobalXDomain={setGlobalDateTimePicker}
                fetchData={() => {
                    return getStatistic();
                }}
                yTickFormatter={(tickItem) => {
                    return `${tickItem}`;
                }}
                xTickFormatter={(tickItem: number) => {
                    return `${fromUTCtoUserTimezone({
                        date: tickItem * 1000,
                        format: 'date',
                        displaySeconds: false
                    })}  `;
                }}
            />
        );
    }, [globalDateTimePicker]);

    return (
        <SensorStatisticsContainer data-testid={`SensorStatisticsContent-${sensorType}`}>
            <StatisticsDateTimePicker>
                <UiDateRangePicker
                    dateFrom={globalDateTimePicker.current.dateFrom}
                    dateFromLabel={translation('t.date_from')}
                    dateTo={globalDateTimePicker.current.dateTo}
                    dateToLabel={translation('t.date_to')}
                    onDateChange={(dateRange) => {
                        if (
                            dateRange !== null &&
                            !dateRange[1].invalid &&
                            !dateRange[0].invalid &&
                            dateRange[1] !== '' &&
                            dateRange[0] !== ''
                        )
                            setGlobalDateTimePicker({
                                current: {
                                    dateFrom: dateRange[0],
                                    dateTo: dateRange[1]
                                },
                                original: { ...globalDateTimePicker.original },
                                action: DatePickerAction.APPLY
                            });
                    }}
                />
            </StatisticsDateTimePicker>
            <RenderGraph />
            <ButtonWrapper>
                <UiButton
                    skin='primary'
                    variant='contained'
                    testid='export-csv-sensor-transmissions'
                    onClick={csvExport}
                >
                    CSV
                </UiButton>
            </ButtonWrapper>
        </SensorStatisticsContainer>
    );
};
