import React from 'react';
import { AnnouncementsTableContent } from './AnnouncementsTable.view';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Menu from '../Ui/Components/UiMenu/UiMenu';
import Button from '@mui/material/Button';
import { ShowAnnouncementsActionsModal } from '../../states/global/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import { AnnouncementsActionsState } from '../../states/component/Announcements';
import { UserInfo } from '../../states/global/User';
import { MessageTable, Translation } from '../../models/SystemNotification.type';
import { CRUD } from 'variables';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

export const AnnouncementsTable: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const showAnnouncementsActionsModal = useSetRecoilState(ShowAnnouncementsActionsModal);
    const { fromUTCtoUserTimezone } = useConverter();
    const setAnnouncementsActionsState = useSetRecoilState(AnnouncementsActionsState);
    const userInfo = useRecoilValue(UserInfo);

    const createMessage = (message: string, translation: Translation[]): string => {
        const language: string = userInfo.user?.userSetting.language || 'en_GB';
        for (const translationKey in translation) {
            if (translation[translationKey].locale === language) {
                return translation[translationKey].content;
            }
        }
        return message;
    };

    const columns: Column<MessageTable>[] = [
        {
            Header: translate('t.message'),
            accessor: 'message',
            disableSortBy: true,
            disableFilterBy: true,
            width: 350,
            Cell: (props: { row: { original: MessageTable } }) => (
                <>{createMessage(props.row.original.message, props.row.original.translation)}</>
            )
        },
        {
            Header: translate('t.active'),
            accessor: (row: MessageTable) => row.active,
            disableSortBy: true,
            disableFilterBy: true,
            Cell: (props: { value: string }) => <>{props.value ? translate('t.active') : translate('t.inactive')}</>
        },
        {
            Header: translate('t.all_customers'),
            accessor: 'allCustomers',
            disableSortBy: true,
            disableFilterBy: true,
            Cell: (props: { value: boolean }) => <>{props.value ? translate('t.yes') : translate('t.no')}</>
        },
        {
            Header: translate('t.date_from'),
            accessor: 'dateFrom',
            disableSortBy: true,
            disableFilterBy: true,
            Cell: (props: { value: string }) => (
                <>{fromUTCtoUserTimezone({ date: props.value, format: 'dateTime', displaySeconds: false })}</>
            )
        },
        {
            Header: translate('t.date_to'),
            accessor: 'dateTo',
            disableSortBy: true,
            disableFilterBy: true,
            Cell: (props: { value: string }) => (
                <>{fromUTCtoUserTimezone({ date: props.value, format: 'dateTime', displaySeconds: false })}</>
            )
        },
        {
            Header: translate('t.action'),
            accessor: 'id',
            disableFilterBy: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    setAnnouncementsActionsState({
                                        action: CRUD.EDIT,
                                        id: props.value
                                    });
                                    showAnnouncementsActionsModal(true);
                                }
                            },
                            {
                                title: translate('t.clone'),
                                value: props.value,
                                action: () => {
                                    setAnnouncementsActionsState({
                                        action: CRUD.CLONE,
                                        id: props.value
                                    });
                                    showAnnouncementsActionsModal(true);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                data-testid='action-button'
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: 20, maxWidth: 20 }}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        }
    ];

    return (
        <AnnouncementsTableContent
            data-testid={'AnnouncementsTable-testid'}
            columns={columns}
            customerId={userInfo.user?.customer.id ?? NaN}
        />
    );
};

export default Wrapper(AnnouncementsTable);
