import React from 'react';
import { AlertsPressureSettingsContent } from './AlertsPressureSettings.view';
import { AlertsPressureSettingsProps } from './AlertsPressureSettings.type';
import { Wrapper } from 'helpers/wrapper';

const AlertsPressureSettings: React.FC<AlertsPressureSettingsProps> = (): JSX.Element => {
    return <AlertsPressureSettingsContent data-testid={'AlertsPressureSettings-testid'} />;
};

export default Wrapper(AlertsPressureSettings);
