import React from 'react';
import { useRecoilState } from 'recoil';
import { GranularityType, TopBarAtom } from './TopBar/TopBar.atom';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { WheelPositionTextField } from './TopBar/TopBar.style';
import HelperText from '../HelperText/HelperText';

const WheelGranularitySelect: React.FC = (): JSX.Element => {
    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const { t: translate } = useTranslation();

    const createMenuOptions = (): JSX.Element[] => {
        let options: JSX.Element[] = [
            <MenuItem value={0} key={0}>
                {`${translate('t.none')} - ${translate('t.all_data')}`}
            </MenuItem>,
            <MenuItem value={1} key={1}>
                {`1 ${translate('t.minute')} ${translate('t.aggregation')}`}
            </MenuItem>,
            <MenuItem value={5} key={5}>
                {`5 ${translate('t.minutes')} ${translate('t.aggregation')}`}
            </MenuItem>,
            <MenuItem value={10} key={10}>
                {`10 ${translate('t.minutes')} ${translate('t.aggregation')}`}
            </MenuItem>,
            <MenuItem value={15} key={15}>
                {`15 ${translate('t.minutes')} ${translate('t.aggregation')}`}
            </MenuItem>,
            <MenuItem value={30} key={30}>
                {`30 ${translate('t.minutes')} ${translate('t.aggregation')}`}
            </MenuItem>,
            <MenuItem value={60} key={60}>
                {`1 ${translate('t.hour')} ${translate('t.aggregation')}`}
            </MenuItem>
        ];

        return options;
    };

    return (
        <WheelPositionTextField
            data-testid='data-export-granularity-select'
            id='wheel-select'
            select
            size='small'
            value={topBarAtom.granularity || 0}
            label={translate('t.interval')}
            onChange={(event) => {
                setTopBarAtom({ ...topBarAtom, granularity: parseInt(event.target.value) as GranularityType });
            }}
            variant='outlined'
            disabled={!(topBarAtom.vehicle.id > -1)}
            helperText={<HelperText />}
        >
            {createMenuOptions()}
        </WheelPositionTextField>
    );
};

export default WheelGranularitySelect;
