import React from 'react';
import { useRecoilValue } from 'recoil';
import { LoadingMapdata } from '../../states/global/Map';
import { useTranslation } from 'react-i18next';
import { DataLoaderLabel, MapDataLoaderContainer, DataLoaderProgress } from './MapDataLoader.style';

const MapDataLoaderContent: React.FC = (): JSX.Element => {
    const loadingMapData = useRecoilValue(LoadingMapdata);
    const { t: translate } = useTranslation();
    return (
        <MapDataLoaderContainer data-testid='MapDataLoaderContent'>
            {loadingMapData && (
                <>
                    <DataLoaderProgress />{' '}
                    <DataLoaderLabel>{translate('p.loading_data_from_the_server')}</DataLoaderLabel>
                </>
            )}
        </MapDataLoaderContainer>
    );
};

export default MapDataLoaderContent;
