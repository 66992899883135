import React from 'react';
import { VehicleStatusListProps } from './VehicleStatusList.type';
import { VehicleStatusListContent } from './VehicleStatusList.view';
import { AlertsToDisplay } from './VehicleStatusList.type';
import { AlertsOnVehicle } from 'models/Vehicle.type';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';

const checkAlertsToDisplay = (data: AlertsOnVehicle): AlertsToDisplay => {
    const alertsTypes: AlertsToDisplay = {
        lowPressure: false,
        highPressure: false,
        temperature: false,
        temperaturePrediction: false
    };
    const keys = Object.keys(alertsTypes);

    for (let i = 0; i < data.length; i++) {
        if (keys.includes(data[i].levelType)) {
            alertsTypes[data[i].levelType] = true;
        }
    }
    return alertsTypes;
};

const VehicleStatusList: React.FC<VehicleStatusListProps> = (props): JSX.Element => {
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    return (
        <VehicleStatusListContent
            data-testid='VehicleStatusList-testid'
            {...props}
            isLoadingVehicle={vehicleDetailsWidgetAtom.isLoadingVehicle ?? false}
            displayAlerts={checkAlertsToDisplay}
        />
    );
};

export default VehicleStatusList;
