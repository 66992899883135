import React from 'react';
import { LifespanReportDetailsContent } from './LifespanReportDetails.view';
import { LifespanReportDetailsProps } from './LifespanReportDetails.type';
import { Wrapper } from 'helpers/wrapper';

const LifespanReportDetails: React.FC<LifespanReportDetailsProps> = (props): JSX.Element => {
    return (
        <LifespanReportDetailsContent
            {...props}
            data-testid={'LifespanReportDetails-testid'}
            key={`LifespanReportDetails-${props.tyreId}`}
        />
    );
};

export default Wrapper(LifespanReportDetails);
