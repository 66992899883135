import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled, { css, keyframes } from 'styled-components';
import { BACKGROUND_BLUE, GRAY, PRIMARY_LIGHT, WHITE } from '../../colors';
import MaUTable from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import { TableContainer, useTheme } from '@mui/material';
import Button from '../../Components/UiButton/UiButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../../states/global/Theme';

export const UiTableViewContent = styled.div`
    @media (max-width: 415px) {
        .MuiTablePagination-selectLabel {
            display: none;
        }
        & .MuiTablePagination-actions {
            margin-left: 15px;
        }
    }
`;

export const ContainerToPrint = styled.div`
    @media print {
        @page {
            size: A3 landscape;
        }
    }
`;

export const TableContainerCustom = styled(TableContainer)<{
    $maxHeight?: number;
    $minHeight?: number;
    $autoMaxHeight?: boolean;
}>`
    ${({ $minHeight, $maxHeight }) => {
        return applyStyleByMode({
            styleJade: `
                height: auto;
                min-height: ${$minHeight || ($maxHeight ? $maxHeight - 60 : 380)}px;
                max-height: calc(100vh - 270px);
                overflow: auto;
                display: grid;
            `
        });
    }}

    ${({ $autoMaxHeight }) =>
        $autoMaxHeight
            ? `
             max-height: auto;
        
            `
            : 'max-height: calc(100vh - 270px);'}

    ${({ $maxHeight }) =>
        $maxHeight &&
        `
        max-height: ${$maxHeight}px;
        & .main-table-body {
            display: grid;
            overflow-y: auto;
            overflow-x: hidden;
            // max-height: ${$maxHeight - 100}px;
        }
    `}

    &.loading {
        opacity: 0.5;
    }
`;

export const Table = styled(MaUTable)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTable-root {
                width: 99.8%;
            }
            & .MuiTableHead-root {
                border-bottom: 1px solid #474544;
                background: #f9f9f9;
            }
            td {
                ${applyStyleByMode({
                    styleOld: `
                    font-size: 1em;
                    line-height: 21px !important;
                    `
                })}
            }
        `
    })}
`;

export const CustomTableRow = styled(TableRow)<{ $notData?: boolean; $background?: string; $keyRow?: number }>`
    ${({ $notData, $background, $keyRow }) => {
        const keyRow = $keyRow || 0;
        return `
            justify-content: center;
            display: ${$notData ? 'flex' : 'inline'} ;

            &:nth-child(even) {
                background-color: ${
                    $background
                        ? $background
                        : useTheme().palette.mode === ThemeMode.dark
                        ? '#1d2630de'
                        : 'rgba(0, 0, 0, 0.04)'
                };
            }

            ${
                keyRow % 2 !== 0 && !$background
                    ? `background-color: ${
                          useTheme().palette.mode === ThemeMode.dark ? '#1d2630de' : 'rgba(0, 0, 0, 0.04)'
                      } !important;`
                    : ''
            };
            ${$background ? `background-color: ${$background}` : ''};
        `;
    }}
`;

export const CustomHeaderRow = styled(TableRow)`
    ${applyStyleByMode({
        styleOld: 'border-top: #e3e3e3 solid thin;'
    })}
`;

export const CustomHeaderCell = styled(TableCell)`
    ${applyStyleByMode({
        styleOld: `
            font-size: 1em !important;
            line-height: 21px;
            font-weight: 600 !important;
        `
    })}
`;

export const ResizeSeparator = styled.div`
    ${applyStyleByMode({
        styleOld: `
            position: absolute;
            cursor: col-resize;
            z-index: 100;
            opacity: 0;
            height: 50%;
            top: 25%;
            transition: all linear 100ms;
            right: -2px;
            width: 3px;
            &.isResizing {
                opacity: 1;
                border: none;
                background-color: ${PRIMARY_LIGHT};
                height: calc(100% - 4px);
                top: 2px;
                right: -1px;
                width: 1px;
            }
        `
    })}
`;

export const EmptyTable = styled(TableCell)`
    ${applyStyleByMode({
        styleOld: `
            height: 70px;
            width: 900px !important;
            border-bottom: #e5e5e5 solid thin;
            background: #f7f7f7;
            text-align: center !important;
        `
    })}
`;

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
`;

export const LoadingBar = styled(CircularProgress)`
    ${() => {
        return `
            color: ${useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : BACKGROUND_BLUE} !important;
            &.MuiCircularProgress {
                color: ${useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : BACKGROUND_BLUE};
            }
        `;
    }}
`;

export const CsvButton = styled(Button)`
    height: 30px !important;
    background: ${GRAY} !important;
    color: ${WHITE} !important;
    & svg {
        color: ${WHITE};
    }
`;

export const SpinningRefreshButton = styled(RefreshIcon)<{ $spin: boolean }>`
    animation: ${(props) => props.$spin && refreshButtonAnimation()};
`;

const refreshButtonAnimation = () => css`
    ${refreshButtonSpin} 0.7s steps(120) infinite;
`;

const refreshButtonSpin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const ExportDataButton = styled(Button)`
    text-transform: capitalize;
`;
