import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FilterCategory, FiltersContainer, VehicleFiltersContentView } from './VehicleFilters.style';
import { Grid } from '@mui/material';
import { Filtering } from './Filtering';
import { Sorting } from './Sorting';
import { VehicleFiltersAtom } from './VehicleFilters.atom';
import GetVehicleGroupCodebook from 'components/Queries/VehicleGroup/VehicleGroup';
import { FilteringByVehicleGroup } from './FilteringByVehicleGroup';
import { Wrapper } from 'helpers/wrapper';

const VehicleFilters: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleFiltersAtom = useRecoilValue(VehicleFiltersAtom);

    return (
        <VehicleFiltersContentView data-testid='VehicleFiltersContent'>
            <FiltersContainer className={`${vehicleFiltersAtom.showFilters ? 'active' : ''}`}>
                <Grid container spacing={1}>
                    <Grid item textAlign='center' xs={6}>
                        <FilterCategory>{translate('t.filter_by')}</FilterCategory>
                        <Filtering />
                        <GetVehicleGroupCodebook>
                            {({ data }) => <FilteringByVehicleGroup vehicleTypes={data} />}
                        </GetVehicleGroupCodebook>
                    </Grid>
                    <Grid item textAlign='center' xs={6}>
                        <FilterCategory>{translate('t.sort_by')}</FilterCategory>
                        <Sorting />
                    </Grid>
                </Grid>
            </FiltersContainer>
        </VehicleFiltersContentView>
    );
};

export default Wrapper(VehicleFilters);
