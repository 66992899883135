import styled from 'styled-components';
import Alert from '@mui/material/Alert';
import { Autocomplete, TextField } from '@mui/material';
import UiButton from '../../../Ui/Components/UiButton';
import { applyStyleByMode } from 'helpers';

export const UiDateRangePicker2Container = styled.div`
    top: -8.5px;
    position: relative;

    && .MuiInputBase-root {
        width: 200px;
    }
`;

export const DataExportTopBarView = styled.div`
    ${applyStyleByMode({
        styleOld: `
            padding: 10px 10px 0px;
            width: 100%;
        `
    })}
`;

export const WarningMessage = styled(Alert)`
    margin: 0 0 12px 0;
`;

export const WheelPositionTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        width: 200px;
    }
`;

export const AreaTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        width: 200px;
    }
`;

export const StyledAutocomplete = styled(Autocomplete)`
    & .MuiFormControl-root {
        width: 200px;
    }
`;

export const DownloadButton = styled(UiButton)`
    height: 35px;
`;

export const WrapperAutocompleteVehicle = styled.div`
    top: -8.5px;
    position: relative;
`;
