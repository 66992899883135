import styled from 'styled-components';

export const HubsUpdaterViewContent = styled.div``;

export const PopupInfoContent = styled.div`
    margin: 10px 0;
    & .MuiPaper-root {
        margin: 10px 0;
    }
`;
