import IconButton from '@mui/material/IconButton';
import { PRIMARY, SUCCESS } from '../../Ui/colors';
import styled from 'styled-components';

export const ToggleButton = styled(IconButton)<{ $statusNotification?: boolean }>`
    @keyframes opacityChange {
        0% {
            background: ${PRIMARY};
        }
        50% {
            background: ${SUCCESS};
        }
        100% {
            background: ${PRIMARY};
        }
    }
    padding: 7px 4.5px !important;
    && .MuiCircularProgress-colorPrimary {
        color: #cdc9c6 !important;
    }
`;
