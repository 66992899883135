import React, { useEffect, useMemo, useState } from 'react';
import { GreenRadio, Label, PopupContent } from './SensorManagementPopup.style';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SensorStateAtom } from '../SensorManagementRow/SensorManagementRow.atom';
import { SensorActionPopupProps } from './SensorManagementPopup.type';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { FittingReason } from '../../../../variables';
import useModal from '../../../CustomHooks/ModalHook';
import UiButton from '../../../Ui/Components/UiButton';
import { useMutation } from '@tanstack/react-query';
import SensorsAPI from '../../../../api/Sensors';
import { FetchVehicleDetailsData } from '../../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailsActionMenuAtom } from '../../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { VARIANT_BUTTON } from 'components/Ui/variables';

const sensorsAPI = new SensorsAPI();

const AddSensor = ({ position }: SensorActionPopupProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleStatusWidgetAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const newInstallation = useMemo<boolean>(
        () => vehicleStatusWidgetAtom.data?.attachedSensors.length === 0,
        [vehicleStatusWidgetAtom.data?.attachedSensors]
    );
    const { updateModalState } = useModal(1);
    const sensorState = useRecoilValue(SensorStateAtom(position));
    const [value, setValue] = useState<FittingReason>(
        newInstallation ? FittingReason.NEW_INSTALLATION : FittingReason.FAULTY_REPLACEMENT
    );
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { mutate } = useMutation(
        ['PatchAddSensor'],
        () =>
            sensorsAPI.mountSensor({
                sensorId: sensorState.newSensor?.id || 0,
                sensorTypeId: sensorState.typeId,
                fittingReason: value,
                wheelId: vehicleStatusWidgetAtom.data?.wheel.id || 0
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            leftTitle: translate('t.sensor_adding'),
            rightTitle: `${translate('t.sensor')}: ${sensorState.newSensor?.serialNumberHex}`,
            buttons: (
                <UiButton
                    id='add-sensor'
                    skin='success'
                    size='small'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    testid={`${sensorState.newSensor?.serialNumberHex}-AddSensorButton`}
                    onClick={() => {
                        mutate();
                        setIsSaving(true);
                    }}
                    loading={isSaving}
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, [value, isSaving]);

    return (
        <>
            <PopupContent>
                <FormControl>
                    <Label>
                        {translate('t.reason_adding')} {sensorState.originalSensor?.serialNumberHex}
                    </Label>
                    <RadioGroup
                        name='add-sensor-radio-group'
                        value={value}
                        onChange={(event) => setValue(event.target.value as FittingReason)}
                    >
                        {newInstallation ? (
                            <FormControlLabel
                                value={FittingReason.NEW_INSTALLATION}
                                control={<GreenRadio />}
                                label={translate('t.new_installation')}
                            />
                        ) : (
                            <>
                                <FormControlLabel
                                    value={FittingReason.FAULTY_REPLACEMENT}
                                    control={<GreenRadio />}
                                    label={translate('t.faulty_replacement')}
                                />
                                <FormControlLabel
                                    value={FittingReason.SECONDARY_SENSOR}
                                    control={<GreenRadio />}
                                    label={translate('t.secondary_sensor')}
                                />
                            </>
                        )}
                    </RadioGroup>
                </FormControl>
            </PopupContent>
        </>
    );
};

export default AddSensor;
