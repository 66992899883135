import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';
import { Box, useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';

export const AreasTableViewContent = styled.div`
    &&&.MuiFormControl-marginNormal {
        margin-top: 16px;
        margin-bottom: 8px;
    }
`;
//Remove after moving to SPA
export const KeyboardDatePickerCompMode = styled(DatePicker)`
    &&&.MuiFormControl-marginNormal {
        margin-top: 16px !important;
        margin-bottom: 8px !important;
    }
`;

export const AreaColorBackground = styled(Box)`
    width: 30px;
    height: 30px;
    background: ${() => {
        return useTheme().palette.mode === ThemeMode.dark ? '#1d2630' : '#fff';
    }};
`;

export const AreaColorIcon = styled.div<{ backgroundColor: string; borderColor: string }>`
    width: 30px;
    height: 30px;
    background: ${(props) => props.backgroundColor};
    border: 2px solid ${(props) => props.borderColor};
`;

export const AreaName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: underline;
    cursor: pointer;
`;
