import React from 'react';
import { CustomTableCell, SoftwarePackagesContainer, CustomTableContainer } from './SoftwarePackages.style';
import { Grid } from '@mui/material';
import { SoftwarePackagesViewProps } from './SoftwarePackages.type';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { HubAdministrationModelResponse } from 'models/HubAdministration.type';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

export const SoftwarePackagesContent: React.FC<SoftwarePackagesViewProps> = ({ data, isLoading }): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <SoftwarePackagesContainer data-testid='SoftwarePackagesContent'>
            <Card variant='outlined'>
                <CardContent>
                    <Grid container item>
                        <Grid item xs={12} md={12} lg={12}>
                            <CustomTableContainer $isLoading={isLoading}>
                                <Table size='small'>
                                    <TableHead></TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.service')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo?.service ??
                                                    '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.system_tools')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo?.tools ??
                                                    '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.kernel')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo?.kernel ??
                                                    '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.firmware')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo?.fmw ??
                                                    '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.exchange')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                    ?.exchange ?? '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.ping_reboot')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                    ?.pingReboot ?? '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.sshtunneld')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                    ?.sshtunneld ?? '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.status_monitor')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                    ?.statusMonitor ?? '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.usb')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo?.usb ??
                                                    '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                {translate('t.watchdog')}
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                    ?.watchdog ?? '-'}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CustomTableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </SoftwarePackagesContainer>
    );
};
