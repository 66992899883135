import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ShowAnnouncementsActionsModal } from '../../states/global/Modal';
import { AnnouncementsActionsState } from '../../states/component/Announcements';
import { CRUD } from 'variables';
import Button from '../../components/Ui/Components/UiButton/UiButton';

export const AddButton: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const showAnnouncementsActionsModal = useSetRecoilState(ShowAnnouncementsActionsModal);
    const announcementsActionsState = useSetRecoilState(AnnouncementsActionsState);

    return (
        <Button
            testid='add-button'
            skin='success'
            color='primary'
            variant='contained'
            style={{ color: '#fff' }}
            onClick={() => {
                announcementsActionsState({
                    action: CRUD.ADD
                });
                showAnnouncementsActionsModal(true);
            }}
        >
            {translate('t.add')}
        </Button>
    );
};
