import styled from 'styled-components';
import Table from '@mui/material/Table';
import Tab from '@mui/material/Tab';
import TabContent from '../../components/Ui/Components/UiTab';
import Tabs from '@mui/material/Tabs';

export const VehicleMapPopupContentView = styled.div``;

export const CustomPopupTable = styled(Table)`
    & .MuiTableCell-root {
        font-size: 0.9rem;
        padding: 0 0 0 5px;
    }
    & .MuiTableRow-root:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.04);
    }

    & .MuiTableCell-head {
        font-weight: 600;
    }
`;

export const CustomTabContent = styled(TabContent)`
    overflow-y: auto;
    text-align: center;
`;

export const TabContentWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const CustomTab = styled(Tab)`
    &.MuiTab-root {
        min-width: 0;
        min-height: 20px;
        max-height: 20px;
        font-size: 14px;
        text-transform: none;
    }

    & .MuiTableCell-sizeSmall {
        padding: 6px 24px;
    }
`;

export const CustomTabs = styled(Tabs)`
    &.MuiTabs-root {
        min-height: 20px;
        max-height: 20px;
        font-size: 10px;
    }

    & .MuiTabs-flexContainer,
    .MuiTabs-fixed {
        height: 20px;
    }
`;
