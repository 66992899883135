import React, { useCallback } from 'react';
import { DeviceDiagnoseTableType, DiagnoseTable, GpsStates } from './DeviceDiagnoseTable.type';
import { DeviceDiagnoseTableContainer } from './DeviceDiagnoseTable.style';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TableCellValue } from './TableCellValue';
import { UserInfo } from '../../../states/global/User';
import { DateTime } from 'luxon';
export const DeviceDiagnoseTable: React.FC<DeviceDiagnoseTableType> = ({ data }): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    const totalAccelValue = useCallback(() => {
        if (data) {
            let count = 0;
            let isStopped = false;
            const arrayData = Object.entries(data);
            for (let i = 0, length = arrayData.length; i < length; i++) {
                if (
                    [DiagnoseTable.ACCEL_X, DiagnoseTable.ACCEL_Y, DiagnoseTable.ACCEL_Z].includes(
                        arrayData[i][0] as DiagnoseTable
                    )
                ) {
                    count += +(arrayData[i][1] ?? 0);
                }
                if (arrayData[i][0] === DiagnoseTable.GPS_STATE && arrayData[i][1] === GpsStates.STOPPED) {
                    isStopped = true;
                }
            }

            return count > 23 && isStopped;
        }
        return false;
    }, [data]);

    return (
        <DeviceDiagnoseTableContainer>
            {data && (
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableCell colSpan={2} />
                        </TableHead>
                        <TableBody>
                            {Object.entries(data).map((d, index) => {
                                if (d[0] !== DiagnoseTable.DEVICE_DIAGNOSE_SENSOR) {
                                    const value =
                                        d[0] === DiagnoseTable.CREATED_AT
                                            ? `${DateTime.fromISO(d[1] as string)
                                                  .toUTC()
                                                  .toFormat(
                                                      `${userInfo.user?.userSetting.dateFormat} ${userInfo.user?.userSetting.timeFormat}`
                                                  )} (UTC)`
                                            : d[1];
                                    return (
                                        <TableRow key={index}>
                                            <TableCellValue
                                                key={d[0] as DiagnoseTable}
                                                value={value as number | string | null}
                                                label={d[0]}
                                                totalAccelValue={totalAccelValue()}
                                                numberOfSensors={data?.deviceDiagnoseSensor?.length || 0}
                                            />
                                        </TableRow>
                                    );
                                }
                                return null;
                            })}
                        </TableBody>
                    </Table>
                    <h5>{translate('t.sensors')}</h5>
                    <Table size='small'>
                        <TableHead>
                            <TableCell colSpan={2} />
                        </TableHead>
                        <TableBody>
                            {!data.deviceDiagnoseSensor.length && <p>{translate('t.no_sensor')}</p>}
                            {data.deviceDiagnoseSensor.map((sensor) => {
                                let rows: JSX.Element[];

                                rows = Object.entries(sensor).map((d, index) => (
                                    <TableRow key={index}>
                                        <TableCellValue
                                            key={d[0] as DiagnoseTable}
                                            value={d[1] as number | string | null}
                                            label={d[0]}
                                        />
                                    </TableRow>
                                ));

                                rows.push(
                                    <TableRow>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                );

                                return rows;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DeviceDiagnoseTableContainer>
    );
};

export default DeviceDiagnoseTable;
