import React from 'react';
import { AreaDetailsViewContent } from './AreaDetails.style';
import { AreaDetailsViewProps } from './AreaDetails.type';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useConverter from '../../CustomHooks/Converter/Converter';

export const AreaDetailsContent: React.FC<AreaDetailsViewProps> = ({ data }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromServerToUserUnit, convertType } = useConverter();

    if (!data) {
        return <>{translate('t.there_no_data')}</>;
    }

    return (
        <AreaDetailsViewContent data-testid='AreaDetailsContent'>
            <TableBody>
                <TableRow>
                    <TableCell variant='head'>{translate('t.counter_zone')}:</TableCell>
                    <TableCell>{data.generateAreaEvents ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell>{translate('t.min_speed')}:</TableCell>
                    <TableCell>
                        {`${
                            typeof data.minSpeed === 'number' && data.generateSpeedAlerts
                                ? `${fromServerToUserUnit({
                                      type: convertType.speed,
                                      value: data.minSpeed,
                                      displayUnits: true,
                                      fixed: 0
                                  })}`
                                : '-'
                        }`}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{translate('t.maintenance_zone')}:</TableCell>
                    <TableCell>{data.maintenanceArea ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell>{translate('t.max_speed')}:</TableCell>
                    <TableCell>
                        {`${
                            typeof data.maxSpeed === 'number' && data.generateSpeedAlerts
                                ? `${fromServerToUserUnit({
                                      type: convertType.speed,
                                      value: data.maxSpeed,
                                      displayUnits: true,
                                      fixed: 0
                                  })}`
                                : '-'
                        }`}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{translate('t.speed_zone')}:</TableCell>
                    <TableCell>{data.generateSpeedAlerts ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell>{translate('t.speed_level')} 1:</TableCell>
                    <TableCell>{`${
                        typeof data.maxSpeedTemperatureL1 === 'number' && data.temperatureBasedSpeedAlerts
                            ? `${fromServerToUserUnit({
                                  type: convertType.speed,
                                  value: data.maxSpeedTemperatureL1,
                                  displayUnits: true,
                                  fixed: 1
                              })}`
                            : '-'
                    }`}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{translate('t.temperature_speed_alerts')}:</TableCell>
                    <TableCell>{data.temperatureBasedSpeedAlerts ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell>{translate('t.speed_level')} 2:</TableCell>
                    <TableCell>
                        {`${
                            typeof data.maxSpeedTemperatureL2 === 'number' && data.temperatureBasedSpeedAlerts
                                ? `${fromServerToUserUnit({
                                      type: convertType.speed,
                                      value: data.maxSpeedTemperatureL2,
                                      displayUnits: true,
                                      fixed: 1
                                  })}`
                                : '-'
                        }`}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{translate('t.speed_level')} 3:</TableCell>
                    <TableCell>
                        {`${
                            typeof data.maxSpeedTemperatureL3 === 'number' && data.temperatureBasedSpeedAlerts
                                ? `${fromServerToUserUnit({
                                      type: convertType.speed,
                                      value: data.maxSpeedTemperatureL3,
                                      displayUnits: true,
                                      fixed: 1
                                  })}`
                                : '-'
                        }`}
                    </TableCell>
                </TableRow>
            </TableBody>
        </AreaDetailsViewContent>
    );
};
