import styled from 'styled-components';
import { Box, TableCell } from '@mui/material';
import { ERROR } from '../../Ui/colors';

export const DeviceDiagnoseTableContainer = styled(Box)`
    height: 100%;
    width: 100%;
`;

export const IncorrectValue = styled(TableCell)`
    &.MuiTableCell-root {
        font-weight: bold;
        color: ${ERROR};
        background: #ffcccc;
    }
`;

export const TableCellCustom = styled(TableCell)`
    && {
        font-weight: normal;
        @media only screen and (min-width: 1200px) {
            width: 187px;
            max-width: 187px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`;
