import styled from 'styled-components';
import { TableCell, TableContainer, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import {
    BACKGROUND_DARK_MODE,
    BACKGROUND_PAPER_DARK_MODE,
    BACKGROUND_TABLE,
    BACKGROUND_TABLE_DARK,
    BACKGROUND_TABLE_DARK_HOVER,
    COLOR_GREY,
    COLOR_GREY_4,
    WHITE
} from '../../Ui/colors';
import { ThemeMode, ThemeType } from '../../../states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ColumnStickyBody = styled(TableCell)<{
    $lef?: string | number;
    $minWidth?: string | number;
    $rowNumber: number;
    $notBorderRight?: boolean;
    $theme?: ThemeType;
    align: string;
}>`
    ${({ align }) => (align ? `text-align: ${align} !important; ` : '')}

    ${({ $notBorderRight }) => ($notBorderRight ? 'border-right: none !important;' : '')}

    ${({ $theme, $rowNumber, $minWidth }) => {
        return applyStyleByMode({
            styleOld: `
            background-color: ${$rowNumber % 2 === 0 || $rowNumber === 0 ? '#fff' : BACKGROUND_TABLE};
            min-width: ${$minWidth ? `${$minWidth}px` : '0px'};
            `,
            styleJade: `
            min-width: ${$minWidth ? `${$minWidth}px` : '0px'};
            background-color: ${$theme?.mode === ThemeMode.light ? BACKGROUND_TABLE : BACKGROUND_PAPER_DARK_MODE};
            `
        });
    }}

    @media (min-width: 768px) {
        position: sticky;
        left: ${(props) => `${props.$lef}px` ?? '0px'} !important;
        z-index: 1;
    }
`;

export const TemperaturePressureListViewContent = styled.div`
    .alert-0 {
    }

    ${() => {
        const theme = useTheme();
        return `
            .alert-1 {
                border: 4px solid #c6c71f !important;
                color: ${theme.palette.mode === ThemeMode.light ? COLOR_GREY : WHITE}; /*pending #c6c71f*/
            }

            .alert-2 {
                border: 4px solid #ffa559 !important;
                color: ${theme.palette.mode === ThemeMode.light ? COLOR_GREY : WHITE}; /*pending #ffa559*/
            }

            .alert-3 {
                border: 4px solid #fa000c !important;
                color: ${theme.palette.mode === ThemeMode.light ? COLOR_GREY : WHITE}; /*pending #fa000c*/
            }

            .not-wheel {
                background: ${theme.palette.mode === ThemeMode.light ? COLOR_GREY_4 : BACKGROUND_TABLE_DARK};
            }
        `;
    }}

    .MuiTableCell-root + .MuiTableCell-root {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `
                    border-left: 1px solid #dddddd;
                `,
                dark: `
                    border-left: 1px solid #5e5e5e;
                    `
            });
        }}
    }

    .MuiTableCell-root {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `
                    border-bottom: 2px solid ${WHITE};
                `,
                dark: `
                    border-bottom: 2px solid ${BACKGROUND_DARK_MODE}; 
                    `
            });
        }}

        border-collapse: collapse !important;
        box-sizing: border-box;
        text-align: center;
    }

    .MuiTableCell-sizeSmall {
        padding: '0px 3px';
    }

    .old-info {
        color: #777777;
    }

    .not-transmitting {
        padding: 0;
        color: white;
        background: rgb(132, 150, 175) !important;
    }

    tbody .MuiTableRow-root {
        &:hover {
            ${() => {
                return `
                    background-color: ${
                        useTheme().palette.mode === ThemeMode.light ? '#ecf3f8' : BACKGROUND_TABLE_DARK_HOVER
                    };
                `;
            }}
        }

        &:hover ${ColumnStickyBody} {
            ${() => {
                return `
                    background-color: ${
                        useTheme().palette.mode === ThemeMode.light ? '#ecf3f8' : BACKGROUND_TABLE_DARK_HOVER
                    };
                `;
            }}
        }
    }

    tbody td {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size:  ${common.textSize};`;
        }}
        font-weight: 700;
    }

    ${() => {
        return `
            tr {
                background-color: ${
                    useTheme().palette.mode === ThemeMode.light ? BACKGROUND_TABLE : BACKGROUND_PAPER_DARK_MODE
                }; 
            }
        `;
    }}
`;

export const TableContainerList = styled(TableContainer)``;

export const ContainerNoData = styled.div`
    width: 100%;
    text-align: center;
`;
