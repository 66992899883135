import { AlertActions, stateAlertFilters, filtersActionType } from './AlertsLevelPopUp.type';

export const inicialStateAlertList: stateAlertFilters = {
    level: [3]
};

export const AlertsByLevelReducer = (state: stateAlertFilters, action: filtersActionType) => {
    switch (action.type) {
        case AlertActions.SET_ALERT_FILTER:
            return {
                ...state,
                level: action.payload
            };

        default:
            return state;
    }
};
