import React, { useCallback } from 'react';
import TabletActivationView from './TabletActivation.view';
import { Column } from 'components/Ui/Components/UiSimpleTable/UiSimpleTable.type';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { deactivateTablet } from './RequestFunctions';
import { RefreshSimpleTable } from 'components/Ui/Components/UiSimpleTable/UiSimpleTable.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import Menu from 'components/Ui/Components/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import { CustomersAtom } from 'states/global/Customers';

const TabletActivation = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const refreshTable = useSetRecoilState(RefreshSimpleTable);
    const { fromUTCtoUserTimezone } = useConverter();
    const customers = useRecoilValue(CustomersAtom);

    const matchCustomerNameWithId = useCallback(
        (id: number): string => {
            return customers.find((customer) => customer.id === id)?.name || '';
        },
        [customers]
    );

    const columns: Column[] = [
        {
            header: translate('t.name'),
            accessor: 'name'
        },
        {
            header: translate('t.customer'),
            accessor: 'lastCustomerId',
            parseData: (data) => matchCustomerNameWithId(data)
        },
        {
            header: translate('t.expires_at'),
            accessor: 'refreshTokenExpiresAt',
            parseData: (data) =>
                fromUTCtoUserTimezone({ date: data, format: 'dateTime', displayIfEmpty: translate('t.expired') })
        },
        {
            header: translate('t.action'),
            accessor: 'id',
            parseData: (data) => (
                <Menu
                    items={[
                        {
                            title: translate('t.deactivate'),
                            value: data,
                            action: () => {
                                deactivateTablet(data).then(() => refreshTable(true));
                            }
                        }
                    ]}
                    rootEl={
                        <Button
                            data-testid={`tablet-activation-${data}`}
                            style={{ minWidth: '20px', maxWidth: '20px' }}
                        >
                            <SettingsIcon />
                        </Button>
                    }
                />
            )
        }
    ];

    return <TabletActivationView columns={columns} />;
};

export default TabletActivation;
