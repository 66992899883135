import React from 'react';
import { AddHubNotifyContainer, CustomCheckBox, CustomTextField, RefButton } from './AddHubNotify.style';
import { AddHubNotifyViewProps, handleSubmit } from './AddHubNotify.type';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import { FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AddHubNotifyContent: React.FC<AddHubNotifyViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const formSchema = Yup.object().shape({
        hubId: Yup.number()
            .required(translate('p.this_field_is_required'))
            .typeError(translate('p.please_enter_hub_id')),
        message: Yup.string().required(translate('p.this_field_is_required'))
    });

    return (
        <AddHubNotifyContainer data-testid='AddHubNotifyContent'>
            <Formik
                initialValues={props.initValues}
                validationSchema={formSchema}
                enableReinitialize={true}
                onSubmit={(values: Parameters<handleSubmit>[0]) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<Parameters<handleSubmit>[0]>) => {
                    const { errors, handleChange, values } = propsFormik;

                    return (
                        <Form id='administration-hub-form'>
                            <Grid container>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <CustomTextField
                                            id='hubId'
                                            name='hubId'
                                            data-testid='Hubid-Field'
                                            error={!!errors?.hubId}
                                            label={translate('t.hub_id')}
                                            value={values.hubId}
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                            helperText={errors.hubId}
                                            onChange={handleChange}
                                            inputProps={{
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} sm={4}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckBox
                                                    id='vpnTunnel'
                                                    data-testid='VpnTunnel-Checkbox'
                                                    checked={values.vpnTunnel}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={translate('t.vpn_tunnel')}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckBox
                                                    id='reverseTunnel'
                                                    data-testid='ReverseTunnel-Checkbox'
                                                    checked={values.reverseTunnel}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={translate('t.reverse_tunnel')}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckBox
                                                    id='replierLog'
                                                    data-testid='ReplierLog-Checkbox'
                                                    checked={values.replierLog}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={translate('t.replier_log')}
                                        />
                                    </Grid>
                                </Grid>
                                <CustomTextField
                                    id='message'
                                    name='message'
                                    data-testid='Message-Field'
                                    error={!!errors.message}
                                    label={translate('t.message')}
                                    value={values.message}
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    type='text'
                                    helperText={errors.message}
                                    onChange={handleChange}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <RefButton type='submit' ref={props.refAddHub} />
                        </Form>
                    );
                }}
            </Formik>
        </AddHubNotifyContainer>
    );
};
