import React from 'react';
import { SystemNotificationMessageViewProps } from './SystemNotificationMessage.type';
import {
    CloseButton,
    SystemNotificationMessageContainer,
    WarningMessage,
    Content,
    WarningMessageCover
} from './SystemNotificationMessage.style';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export const SystemNotificationMessageContent: React.FC<SystemNotificationMessageViewProps> = (props): JSX.Element => (
    <SystemNotificationMessageContainer
        data-testid='SystemNotificationMessageContent'
        hide={props.displayedNotification === null}
    >
        {props.displayedNotification?.map((notification) => {
            return (
                <WarningMessage severity='warning' key={notification.id}>
                    <Content style={{ display: 'flex', alignItems: 'center' }}>
                        <WarningMessageCover>{notification && props.createMessage(notification)}</WarningMessageCover>
                        <CloseButton>
                            <IconButton onClick={() => props.handleCloseNotification(notification)} size='small'>
                                <CloseIcon />
                            </IconButton>
                        </CloseButton>
                    </Content>
                </WarningMessage>
            );
        })}
    </SystemNotificationMessageContainer>
);
