import React from 'react';
import { UiTableHideColumnsViewContent } from './UiTableHideColumns.style';
import { UiTableHideColumnsProps } from './UiTableHideColumns.type';
import Legend from '../Components/UiLegend/UiLegend';
import { IconButton } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import UiTableHideColumnsContentView from '../UiTableHideColumnsContent/UiTableHideColumnsContent';

const UiTableHideColumnsContent: React.FC<UiTableHideColumnsProps> = (props): JSX.Element => {
    return (
        <UiTableHideColumnsViewContent data-testid='UiTableHideColumnsContent'>
            <Legend
                root={
                    <IconButton size='small'>
                        <ViewColumnIcon />
                    </IconButton>
                }
                icon={<ViewColumnIcon />}
                width={400}
            >
                <UiTableHideColumnsContentView queryKey={props.queryKey} />
            </Legend>
        </UiTableHideColumnsViewContent>
    );
};

export default UiTableHideColumnsContent;
