import { RULE_TYPE } from 'models/Rule.type';

export type AlertSettingsProps = {} & PassProps;

export type AlertSettingsViewProps = {} & PassProps;

type PassProps = {
    elevation?: number;
    wheelId?: number;
    ruleType?: RULE_TYPE;
};

export enum ALERT_INDEX {
    COLD_PRESSURE_LOW_LEVEL_3 = 8,
    COLD_PRESSURE_LOW_LEVEL_2 = 7,
    COLD_PRESSURE_LOW_LEVEL_1 = 6,
    COLD_PRESSURE_HIGH_LEVEL_3 = 5,
    COLD_PRESSURE_HIGH_LEVEL_2 = 4,
    COLD_PRESSURE_HIGH_LEVEL_1 = 3,
    HOT_PRESSURE_HIGH_LEVEL_3 = 11,
    HOT_PRESSURE_HIGH_LEVEL_2 = 10,
    HOT_PRESSURE_HIGH_LEVEL_1 = 9,
    TEMPERATURE_LEVEL_1 = 0,
    TEMPERATURE_LEVEL_2 = 1,
    TEMPERATURE_LEVEL_3 = 2
}

export enum ALERT_ID {
    COLD_PRESSURE_LOW_LEVEL_3 = 20,
    COLD_PRESSURE_LOW_LEVEL_2 = 19,
    COLD_PRESSURE_LOW_LEVEL_1 = 18,
    COLD_PRESSURE_HIGH_LEVEL_3 = 17,
    COLD_PRESSURE_HIGH_LEVEL_2 = 16,
    COLD_PRESSURE_HIGH_LEVEL_1 = 15,
    HOT_PRESSURE_HIGH_LEVEL_3 = 28,
    HOT_PRESSURE_HIGH_LEVEL_2 = 27,
    HOT_PRESSURE_HIGH_LEVEL_1 = 26,
    TEMPERATURE_LEVEL_1 = 6,
    TEMPERATURE_LEVEL_2 = 8,
    TEMPERATURE_LEVEL_3 = 9
}
