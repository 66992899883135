import styled from 'styled-components';
import { Grid } from '@mui/material';
import useCommon from 'helpers/themeCommon/useCommon';

export const VehicleStatusIconsContainer = styled.div``;

export const StatusIcon = styled.span<{
    $pressureIcon?: boolean;
    $hide?: boolean;
    $temperatureIcon?: boolean;
}>`
    ${(props) => (props.$temperatureIcon ? 'color: #FF0000;' : '')};
    ${(props) => (props.$pressureIcon ? 'padding: 6px 3px 0 3px' : 'padding: 0px 3px')};
    font-weight: bold;
    ${({ $temperatureIcon }) => {
        const common = useCommon();
        return `font-size: ${$temperatureIcon ? common.titleSize : common.textSize};`;
    }}
    ${(props) => `visibility: ${props.$hide ? 'hidden' : 'visible'};`};
`;

export const IconContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 0 !important;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;
