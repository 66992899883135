import React from 'react';
import { SetupMapAreaContainer } from './SetupMapArea.style';
import UiButton from '../Ui/Components/UiButton/UiButton';
import Customer from '../../api/Customer';
import { Success } from '../Popup';
import { useTranslation } from 'react-i18next';
import { SetupMapAreaProps } from './SetupMapArea.type';
import { SIZE_BUTTON, VARIANT_BUTTON } from '../Ui/variables';
import { Wrapper } from 'helpers/wrapper';

const SetupMapArea: React.FC<SetupMapAreaProps> = (): JSX.Element => {
    const customerAPI = new Customer();
    const { t: translate } = useTranslation();

    const handleCLick = async (): Promise<void> => {
        try {
            await customerAPI.post({
                extendUrl: 'center-map-area',
                version: 'v1',
                data: {},
                apiProject: undefined
            });
            Success({ text: `${translate('t.coordinates')} ${translate('p.has_been_updated')}` });
        } catch (e) {
            false;
        }
    };

    return (
        <SetupMapAreaContainer data-testid='SetupMapArea-testid'>
            <UiButton
                skin='success'
                color='primary'
                onClick={handleCLick}
                title={translate('p.setup_map_area_automatically')}
                variant={VARIANT_BUTTON}
                size={SIZE_BUTTON}
                data-testid='SetupMapAreaButton'
            >
                {translate('t.setup_map_area')}
            </UiButton>
        </SetupMapAreaContainer>
    );
};

export default Wrapper(SetupMapArea);
