import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const JobsLogsTableContainer = styled.div`
    padding-top: 10px;
    th,
    td {
        text-overflow: ellipsis;

        overflow: clip;
    }

    ,
    thead tr {
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: 'background-color: #2b333e;'
            })}
    }
`;
