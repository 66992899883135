import React from 'react';
import { SensorStatisticsTabManagerContainer } from './SensorStatisticsTabManager.style';
import { SensorStatisticsTabManagerViewProps } from './SensorStatisticsTabManager.type';
import { useTranslation } from 'react-i18next';
import { SensorStatisticsType } from '../SensorStatistics/SensorStatistics.type';
import SensorStatistics from '../SensorStatistics/SensorStatistics';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';

export const SensorStatisticsTabManagerContent: React.FC<SensorStatisticsTabManagerViewProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <SensorStatisticsTabManagerContainer data-testid='SensorStatisticsTabManagerContent'>
            <UiWidgetTab
                height={330}
                tab={{
                    headers: [
                        {
                            label: 'RSSI',
                            icon: <></>
                        },
                        {
                            label: translate('t.battery'),
                            icon: <></>
                        },
                        {
                            label: translate('t.transmissions'),
                            icon: <></>
                        }
                    ],
                    constents: [
                        {
                            id: 1,
                            body: (
                                <SensorStatistics
                                    sensorId={props.sensorId}
                                    sensorType={SensorStatisticsType.RSSI_STATISTICS}
                                />
                            )
                        },
                        {
                            id: 2,
                            body: (
                                <SensorStatistics
                                    sensorId={props.sensorId}
                                    sensorType={SensorStatisticsType.BATTERY_STATISTICS}
                                />
                            )
                        },

                        {
                            id: 3,
                            body: (
                                <SensorStatistics
                                    sensorId={props.sensorId}
                                    sensorType={SensorStatisticsType.TRANSMISSIONS_STATISTICS}
                                />
                            )
                        }
                    ]
                }}
            />
        </SensorStatisticsTabManagerContainer>
    );
};
