import React from 'react';
import { CustomPopupTable } from '../VehicleMapPopup/VehicleMapPopup.style';
import {
    AlertTd,
    AlertValue,
    VehicleMapPopupTyresContent as VehicleMapPopupTyresContentEl
} from './VehicleMapPopupTyres.style';
import { VehicleMapPopupTyresViewProps } from './VehicleMapPopupTyres.type';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { useRecoilValue } from 'recoil';
import { UserInfo } from '../../states/global/User';
import { TyreDetail } from '../../models/Wheel.type';
import UiCircularProgress from '../Ui/Components/UiCircularProgress';
import { useTranslation } from 'react-i18next';
import useConverter from '../CustomHooks/Converter/Converter';

const NO_VALUE = '-';

export const VehicleMapPopupTyresContent: React.FC<VehicleMapPopupTyresViewProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { fromServerToUserUnit, convertType } = useConverter();
    const { t: translate } = useTranslation();
    return props.isLoading ? (
        <UiCircularProgress />
    ) : (
        <VehicleMapPopupTyresContentEl data-testid='VehicleMapPopupTyresContent'>
            <TableContainer>
                <CustomPopupTable size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('t.pos')}</TableCell>
                            <TableCell align='center'>
                                {`${translate(
                                    `t.${
                                        userInfo.user?.userSetting.showColdPressureInTyreSummary
                                            ? 'cold_pressure'
                                            : 'hot_pressure'
                                    }`
                                )} (${userInfo.user?.userSetting.pressureUnit})`}
                            </TableCell>
                            <TableCell align='center'>
                                °{userInfo.user?.userSetting.temperatureUnit?.toUpperCase()}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data
                            ?.sort((a, b) => {
                                return a.wheel.customPosition - b.wheel.customPosition;
                            })
                            .map((d: TyreDetail, index) => {
                                const noAttachedSensor: boolean = d.attachedSensors.length === 0;
                                const sensor = d.attachedSensors.filter(
                                    (attachedSensor) => attachedSensor.sensor.priority === 1
                                );
                                let values: {
                                    pressure: string | number | null;
                                    temperature: string | number | null;
                                } = {
                                    pressure: null,
                                    temperature: null
                                };
                                let alerts = {
                                    pressure: 0,
                                    temperature: 0
                                };

                                if (sensor.length > 0) {
                                    let pressureValue: number | string | null = 0;
                                    let temperatureValue: number | string | null = 0;
                                    const displayTemperature: boolean =
                                        sensor[0].sensor.sensorType?.id !== 2 ||
                                        (sensor[0].sensor.sensorType?.id === 2 &&
                                            (userInfo.user?.customer.externalTemperatureEnabled || false) &&
                                            userInfo.user?.userSetting.showTemperatureForExternalSensors);

                                    pressureValue = sensor[0].sensor.pressureStatus
                                        ? (userInfo.user?.userSetting.showColdPressureInTyreSummary
                                              ? sensor[0].sensorLog?.coldPressure
                                              : sensor[0].sensorLog?.hotPressure) || null
                                        : null;
                                    temperatureValue = displayTemperature
                                        ? sensor[0].sensor.temperatureStatus
                                            ? sensor[0].sensorLog?.temperature || null
                                            : null
                                        : null;

                                    pressureValue = fromServerToUserUnit({
                                        type: convertType.pressure,
                                        value: pressureValue as number,
                                        fixed: 1
                                    });
                                    temperatureValue = fromServerToUserUnit({
                                        type: convertType.temperature,
                                        value: temperatureValue as number,
                                        fixed: 1
                                    });

                                    if (!props.getHasInternalSensor()) {
                                        pressureValue = NO_VALUE;
                                        temperatureValue = NO_VALUE;
                                    }

                                    if (noAttachedSensor) {
                                        pressureValue = NO_VALUE;
                                        temperatureValue = NO_VALUE;
                                    }

                                    values.pressure = pressureValue;
                                    values.temperature = temperatureValue;

                                    alerts = props.getMaxAlertLevel(sensor[0].sensorAlerts);
                                }
                                return (
                                    <TableRow
                                        key={index}
                                        data-testid={`VehicleMapPopupTyresContent-TableRow-${index}-${alerts.temperature}-${alerts.pressure}`}
                                    >
                                        <TableCell>{`${d.wheel.customPosition}`}</TableCell>
                                        <AlertTd level={alerts.pressure} align='center'>
                                            <AlertValue>{values?.pressure ?? '-'}</AlertValue>
                                        </AlertTd>
                                        <AlertTd level={alerts.temperature} align='center'>
                                            <AlertValue>{values?.temperature ?? '-'}</AlertValue>
                                        </AlertTd>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </CustomPopupTable>
            </TableContainer>
        </VehicleMapPopupTyresContentEl>
    );
};
