import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
    CircularProgress,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import UiButton from '../../Ui/Components/UiButton';
import { TestResultTableViewProps } from './NewHubsTestResultTable.type';
import {
    GridWithHeight,
    Loading,
    LoadingContent,
    NeedNewHubServiceVersion,
    TextResultTableBox,
    TableHeadTestResult,
    SubTitleTestResult
} from './NewHubsTestResultTable.style';
import useConverter from '../../CustomHooks/Converter/Converter';
import { TableCellValue } from '../DeviceDiagnoseTable/TableCellValue';
import { UserInfo } from '../../../states/global/User';
import { DiagnoseTable } from '../DeviceDiagnoseTable/DeviceDiagnoseTable.type';
import { DiagnoseSensor } from '../../../models/DeviceDiagnose.type';
import { VARIANT_BUTTON } from '../../Ui/variables';
import { DateTime } from 'luxon';

export const NewHubsTestResultTableContent: React.FC<TestResultTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const [data, setData] = useState<[string, string | number | DiagnoseSensor[] | null][][]>();
    const [deviceDiagnoseSensor, setDeviceDiagnoseSensor] = useState<DiagnoseSensor[]>([]);

    useEffect(() => {
        if (props.data) {
            setDeviceDiagnoseSensor(props.data.deviceDiagnoseSensor);
            let entries = props.getValuesWithAlertFirst(props.data);
            let newData: [string, string | number | DiagnoseSensor[] | null][][] = [];

            newData.push([...entries.splice(0, Math.round(entries.length / 2) - 1)]);
            newData.push([...entries]);

            setData(newData);
        }
    }, [props.data]);

    return (
        <TextResultTableBox>
            <Grid container spacing={2}>
                {!props.enableTests ? (
                    <NeedNewHubServiceVersion>{translate('t.upgrade_hub_service')}</NeedNewHubServiceVersion>
                ) : (
                    <>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <UiButton
                                        testid='self-diagnose-button'
                                        skin='success'
                                        color='primary'
                                        variant={VARIANT_BUTTON}
                                        disabled={props.isLoading}
                                        onClick={() => {
                                            props.mutation.mutate();
                                        }}
                                    >
                                        {translate('t.self_diagnose')}
                                    </UiButton>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8}>
                                    <TextField
                                        select
                                        label={translate('t.saved_tests')}
                                        fullWidth
                                        size='small'
                                        id='test-results-list'
                                        date-testid='test-results-list'
                                        onChange={(event) => {
                                            props.setTestResultId(Number(event.target.value));
                                        }}
                                        disabled={!props.listData?.length || props.isLoading || props.isLoadingList}
                                        value={props.testResultId}
                                    >
                                        <MenuItem value={0} disabled>
                                            {translate(
                                                `t.${!props.listData?.length ? 'no_saved_test' : 'choose_saved_test'}`
                                            )}
                                        </MenuItem>
                                        {props.listData &&
                                            props.listData?.map((result) => (
                                                <MenuItem key={result.id} value={result.id}>
                                                    {fromUTCtoUserTimezone({
                                                        date: result.createdAt,
                                                        format: 'dateTime',
                                                        displaySeconds: true
                                                    })}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <GridWithHeight item xs={12} md={12} lg={12} height={260} $paddingTop>
                            {data && !props.isLoading ? (
                                <GridWithHeight container spacing={0} height={250} $overflow>
                                    {data.map((tableData, index) => {
                                        return (
                                            <Grid key={index} item xs={12} md={6} lg={6}>
                                                <TableContainer>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableCell colSpan={2} />
                                                        </TableHead>
                                                        <TableBody>
                                                            {tableData.map((d, index) => {
                                                                if (d[0] !== DiagnoseTable.DEVICE_DIAGNOSE_SENSOR) {
                                                                    const value =
                                                                        d[0] === DiagnoseTable.CREATED_AT
                                                                            ? `${DateTime.fromISO(d[1] as string)
                                                                                  .toUTC()
                                                                                  .toFormat(
                                                                                      `${userInfo.user?.userSetting.dateFormat} ${userInfo.user?.userSetting.timeFormat}`
                                                                                  )} (UTC)`
                                                                            : d[1];
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCellValue
                                                                                key={d[0] as DiagnoseTable}
                                                                                value={value as number | string | null}
                                                                                label={d[0]}
                                                                                totalAccelValue={false}
                                                                                numberOfSensors={
                                                                                    deviceDiagnoseSensor.length || 0
                                                                                }
                                                                            />
                                                                        </TableRow>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        );
                                    })}
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SubTitleTestResult>{translate('t.sensors')}</SubTitleTestResult>
                                        <Table size='small'>
                                            <TableHeadTestResult>
                                                <TableCell colSpan={2} />
                                            </TableHeadTestResult>
                                            <TableBody>
                                                {!deviceDiagnoseSensor.length && <p>{translate('t.no_sensor')}</p>}
                                                {deviceDiagnoseSensor.map((sensor) => {
                                                    let rows: JSX.Element[];

                                                    rows = Object.entries(sensor).map((d, index) => (
                                                        <TableRow key={index}>
                                                            <TableCellValue
                                                                key={d[0] as DiagnoseTable}
                                                                value={d[1] as number | string | null}
                                                                label={d[0]}
                                                            />
                                                        </TableRow>
                                                    ));

                                                    rows.push(
                                                        <TableRow>
                                                            <TableCell colSpan={2} />
                                                        </TableRow>
                                                    );

                                                    return rows;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </GridWithHeight>
                            ) : (
                                <Loading>
                                    {props.isLoading ? (
                                        <LoadingContent>
                                            <CircularProgress />
                                        </LoadingContent>
                                    ) : (
                                        <NeedNewHubServiceVersion>
                                            {translate('p.new_hubs_diagnose_message')}
                                        </NeedNewHubServiceVersion>
                                    )}
                                </Loading>
                            )}
                        </GridWithHeight>
                    </>
                )}
            </Grid>
        </TextResultTableBox>
    );
};
