import React, { useEffect } from 'react';
import { TemperatureExposureContent } from './TemperatureExposure.view';
import { TemperatureExposureProps } from './TemperatureExposure.type';
import { useQuery } from '@tanstack/react-query';
import WheelApi from 'api/Wheel';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SelectedVehicle } from 'states/global/Statistics';
import { VehicleWheels } from './TemperatureExposure.atom';
import { Wrapper } from 'helpers/wrapper';

const Wheel = new WheelApi();

const TemperatureExposure: React.FC<TemperatureExposureProps> = (): JSX.Element => {
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const resetSelectedVehicle = useResetRecoilState(SelectedVehicle);
    const setVehicleWheels = useSetRecoilState(VehicleWheels);

    const { isLoading, isFetching, refetch } = useQuery(
        ['wheel-by-vehicle'],
        () => {
            if (selectedVehicle.id !== 0) {
                return Wheel.getTyresDetail(selectedVehicle.id);
            }
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (data) {
                    const sortedData = [...data].sort(
                        (wheel1, wheel2) => wheel1.wheel.customPosition - wheel2.wheel.customPosition
                    );
                    setVehicleWheels({ isLoading: false, wheels: sortedData });
                }
            },
            enabled: false
        }
    );

    useEffect(() => {
        refetch();
    }, [selectedVehicle.id]);

    useEffect(() => {
        return () => {
            resetSelectedVehicle();
        };
    }, []);

    return <TemperatureExposureContent isLoading={isLoading || isFetching} data-testid='TemperatureExposure-testid' />;
};

export default Wrapper(TemperatureExposure);
