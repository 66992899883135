import React from 'react';
import {
    ContainerActionsButtonsHeaderWidget,
    ContainerMessageServerError,
    VehicleStatsContent as VehicleStatsContentStyle
} from './VehicleStats.style';
import { useTranslation } from 'react-i18next';
import { VehicleStatsPropsView } from './VehicleStats.type';
import { useRecoilValue } from 'recoil';
import UiWidget from '../../Ui/Components/UiWidget';
import VehicleStatsTable from './Components/VehicleStatsTable/VehicleStatsTable';
import { applyStyleByMode } from 'helpers';
import { WHITE } from '../../Ui/colors';
import { Theme } from 'states/global/Theme';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import VehicleStatsPeriod from '../VehicleStatsPeriod';
import VehicleStatsLegend from '../VehicleStatsLegend';
import VehicleStatsSearch from '../VehicleStatsSearch';

export const VehicleStatsContent: React.FC<VehicleStatsPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <div>
            <VehicleStatsContentStyle data-testid='VehicleStatsContent'>
                <UiWidget
                    title={translate('t.vehicle_stats')}
                    testid='Widget-VehicleStats'
                    avatar={
                        <UiIcon
                            icon={faTruck}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#333',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    }
                    applyPadding={false}
                    $minwidth={483}
                    $contentHeight={472}
                    margin={0}
                    headerAction={
                        <ContainerActionsButtonsHeaderWidget>
                            <VehicleStatsPeriod />
                            <VehicleStatsSearch />
                            <VehicleStatsLegend />
                        </ContainerActionsButtonsHeaderWidget>
                    }
                    content={
                        props.errorGettingVehicleStats ? (
                            <ContainerMessageServerError>
                                <h3> {translate('t.server_error')} </h3>
                            </ContainerMessageServerError>
                        ) : (
                            <VehicleStatsTable />
                        )
                    }
                />
            </VehicleStatsContentStyle>
        </div>
    );
};
