import React from 'react';
import { HeatmapPlaygroundContent } from './HeatmapPlayground.view';
import { HeatmapPlaygroundProps } from './HeatmapPlayground.type';
import { Wrapper } from 'helpers/wrapper';

const HeatmapPlayground: React.FC<HeatmapPlaygroundProps> = (): JSX.Element => {
    return <HeatmapPlaygroundContent data-testid='HeatmapPlayground-testid' />;
};

export default Wrapper(HeatmapPlayground);
