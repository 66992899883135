import { FormLabel, Radio, styled } from '@mui/material';

export const PopupContent = styled('div')`
    display: flex;
    justify-content: center;
`;

export const Label = styled(FormLabel)`
    font-size: 1.2rem;
`;

export const GreenRadio = styled(Radio)``;

export const RedRadio = styled(Radio)`
    &.MuiRadio-root {
        color: #a90329;
    }
    &&.Mui-checked {
        color: #a90329;
    }
`;

export const RowReportSensor = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ReportSensorLabel = styled(RowReportSensor)`
    font-weight: 500;
    font-size: 1.05rem;
`;
