import React from 'react';
import { UsersSuscribedNotificationsProps } from './UsersSuscribedNotifications.type';
import { UsersSuscribedNotificationsContent } from './UsersSuscribedNotifications.view';

const UsersSuscribedNotifications: React.FC<UsersSuscribedNotificationsProps> = ({ data, rowId }): JSX.Element => {
    return (
        <UsersSuscribedNotificationsContent
            data={data}
            rowId={rowId}
            data-testid='UsersSuscribedNotifications-testid'
        />
    );
};

export default UsersSuscribedNotifications;
