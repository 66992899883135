import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { applyStyleByMode } from 'helpers';
import { WHITE } from '../Ui/colors';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { Wrapper } from 'helpers/wrapper';

const StyledContainer = styled(ToastContainer).attrs({})`
    ${applyStyleByMode({
        styleJade: `
            &.Toastify__toast-container {
                width: 300px !important;
            }
        `
    })}
    .Toastify__toast {
        padding: 0;
    }
    .Toastify__toast-body {
        margin: 0;
    }
    .Toastify__progress-bar {
        background: rgba(255, 255, 255, 0.5);
    }
    .Toastify__close-button {
        position: absolute;
        ${applyStyleByMode({
            styleJade: `
                top: 1.65em;
                right: 0.6em;
                opacity: 1;
                svg {
                    color: ${WHITE};
                }
            `
        })}
    }
    .Toastify__toast {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleJade: `
                    border-radius: 4px;
                    font-size:${common.titleSize};

                `
            });
        }}
        @media (max-width: 768px) {
            margin: 5px;
        }
    }

    .Toastify__toast--default {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: 'color: #fff !important'
            });
        }}
    }
`;

const PopupContainer: React.FC = (): JSX.Element => (
    <StyledContainer
        position='top-right'
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
);

export default Wrapper(PopupContainer);
