import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    DeviceDetailsTableContainer,
    DeviceDetailsTabs,
    DeviceDetailsTabsContainer,
    DeviceTab
} from './DeviceDetailsTable.style';
import { DeviceDetailsTableViewProps } from './DeviceDetailsTable.type';
import TabContent from '../../Ui/Components/UiTab/UiTab';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { ActiveDeviceDetailsTab } from './DeviceDetailsTable.state';
import SoftwarePackages from './SoftwarePackages/SoftwarePackages';
import HubGeneralInfo from '../HubGeneralInfo/HubGeneralInfo';
import Alert from '@mui/material/Alert';
import { VARIANT_ALERT } from 'components/Ui/variables';
import { UserInfo } from 'states/global/User';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const DeviceDetailsTableContent: React.FC<DeviceDetailsTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const activeTabAtom = useRecoilValue(ActiveDeviceDetailsTab);
    const activeTab = activeTabAtom.details;
    const userInfo = useRecoilValue(UserInfo);

    return (
        <DeviceDetailsTableContainer data-testid='DeviceDetailsTableContent'>
            <Widget
                data-testid='AdministrationTableContent'
                avatar={<UiIcon icon={faEdit} size='lg' fixedWidth />}
                title={translate('t.device_details')}
                loading={false}
                content={
                    props.data ? (
                        <>
                            {props.data?.device.customer?.id !== userInfo.user?.customer.id && (
                                <Alert variant={VARIANT_ALERT} severity='info'>
                                    {translate('p.hub_maybe_not_assignment')}
                                </Alert>
                            )}
                            <DeviceDetailsTabsContainer>
                                <DeviceDetailsTabs
                                    value={activeTab}
                                    onChange={props.handleTabChange}
                                    indicatorColor='primary'
                                    textColor='primary'
                                >
                                    <DeviceTab label={`${translate('t.general_info')}`} />
                                    <DeviceTab label={`${translate('t.software_packages')}`} />
                                </DeviceDetailsTabs>
                            </DeviceDetailsTabsContainer>

                            <TabContent
                                value={activeTab}
                                index={0}
                                renderBeforeActive={true}
                                padding='0px'
                                overflow='hidden'
                                testId='DeviceGeneralInfo'
                            >
                                <HubGeneralInfo hubId={props.deviceId} />
                            </TabContent>
                            <TabContent
                                value={activeTab}
                                index={1}
                                renderBeforeActive={true}
                                padding='0px'
                                overflow='hidden'
                                testId='DeviceSoftwarePackages'
                            >
                                <SoftwarePackages data={props.data} isLoading={props.isLoading} />
                            </TabContent>
                        </>
                    ) : (
                        <></>
                    )
                }
            />
        </DeviceDetailsTableContainer>
    );
};
