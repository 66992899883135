import { Box } from '@mui/material';
import { styled as styleMui } from '@mui/system';

export const AlertsDefaultVehiclesListContainer = styleMui(Box)({
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    minHeight: 330,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
});
