import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import CurrentDateView from './CurrentDate.view';
import { InicialValuesForm } from './CurrentDate.type';
import { User, UserInfo } from 'states/global/User';
import { IUserSetting } from 'components/User/GeneralSettingsForm/GeneralSettingsForm.type';
import Account from 'api/Account';
import { DateToPHPFormat } from 'helpers';
import { Errors, Success } from 'components/Popup/Popup';
import { UserModel } from 'models/User.type';
import { Wrapper } from 'helpers/wrapper';

const ApiAccount = new Account();

const CurrentDateContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const UserState = useRecoilValue(UserInfo);
    const setUser = useSetRecoilState(User);
    const [closePopUp, setPopUp] = useState<boolean | undefined>(undefined);

    const [inicialValuesFormik, setInicialValuesFormik] = useState<InicialValuesForm>({
        language: '',
        dateFormat: ''
    });

    const getUser = async () => {
        try {
            const dataU: UserModel = await ApiAccount.getParsed();

            if (Object.keys(dataU).length) {
                setUser(dataU);
                setPopUp(undefined);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get user ', error);
        }
    };

    const handleSubmit = async (generalSettingsFormData: InicialValuesForm, setSubmitting): Promise<void> => {
        generalSettingsFormData.dateFormat = DateToPHPFormat(generalSettingsFormData.dateFormat);

        const { userSetting }: IUserSetting = await ApiAccount.patchSetting(generalSettingsFormData);
        if (userSetting) {
            getUser();
            setPopUp(true);
            Success({ text: translate('t.modified_format') });
        } else {
            Errors({ text: translate('t.error_modifying_format') });
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (UserState && UserState?.user && !!Object.keys(UserState.user.userSetting).length) {
            setInicialValuesFormik({
                language: UserState.user.userSetting.language,
                dateFormat: UserState.user.userSetting.dateFormat
            });
        }
    }, [UserState]);

    return (
        <CurrentDateView
            data-testid='CurrentDateView-testID'
            handleSubmit={handleSubmit}
            inicialValuesFormik={inicialValuesFormik}
            closePopUp={closePopUp}
        />
    );
};
export default Wrapper(CurrentDateContent);
