import DateTime from 'luxon';
import { UiDatePickerProps } from '../../UiDateRangePicker2.type';

export type DateTimeModalDialogProps = {
    dateValueStaticDatePickerFrom: DateTime;
    dateValueStaticDatePickerTo: DateTime;
    onlyDate?: boolean;
    adjustToPeriod: UiDatePickerProps['adjustToPeriod'];
    validFutureDate?: boolean;
    outOfPeriod: (dateFrom: DateTime, dateTo: DateTime) => boolean;
    setDateValueStaticDatePicker: (value: DateTime[]) => void;
    onDateChange?: (newData: DateTime | null) => void;
};

export enum TimeIntervals {
    HOURS = 'HOURS',
    MINUTES = 'MINUTES'
}

export enum RangePositionCalendar {
    START = 'start',
    END = 'end'
}

export enum TimeFormats {
    MILLITARY = 'HH',
    REGULAR = 'hh'
}
