import React from 'react';
import { ActionButton, AddSensorContainer } from './AddSensor.style';
import { AddSensorViewProps, InitialValues } from './AddSensor.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import DropDown from '../../Ui/Components/UiDropDown/UiDropDown';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import WheelDropDown from '../../Tyre/WheelDropDown/WheelDropDown';

export const AddSensorContent: React.FC<AddSensorViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({
        fittingReason: Yup.string().trim().required(translate('p.this_field_is_required')),
        vehicle: Yup.string().nullable(),
        wheelId: Yup.number().required(translate('p.this_field_is_required')),
        sensorTypeId: Yup.number().required(translate('p.this_field_is_required'))
    });

    return (
        <AddSensorContainer data-testid='AddTyreContent'>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validationSchema={schemaValidateForm}
                onSubmit={(values: InitialValues) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, setFieldValue, values } = propsFormik;

                    return (
                        <Form id='sensor-add-action-form'>
                            <Grid container justify-content='flex-start' direction='row'>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {props.fittingReasonLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <DropDown
                                            options={props.fittingReasonList}
                                            inputName='fittingReason'
                                            label={translate('t.fitting_reason')}
                                            value={values.fittingReason ? values.fittingReason : ' '}
                                            error={errors.fittingReason}
                                            disabled={false}
                                            getValue={(e) => {
                                                setFieldValue('fittingReason', e ?? null);
                                            }}
                                            defaultValue={values.fittingReason ?? null}
                                        />
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {props.sensorTypeLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <DropDown
                                            options={props.sensorType}
                                            inputName='sensor_type'
                                            label={translate('t.sensor_type')}
                                            value={values.sensorTypeId ? values.sensorTypeId : ' '}
                                            error={errors.sensorTypeId}
                                            disabled={false}
                                            getValue={(e) => {
                                                setFieldValue('sensorTypeId', e ?? null);
                                            }}
                                        />
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {props.vehiclesLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <DropDown
                                            options={props.vehicles}
                                            inputName='vehicle'
                                            label={translate('t.vehicle')}
                                            value={values.vehicle ? values.vehicle : ' '}
                                            error={errors.vehicle}
                                            disabled={false}
                                            getValue={(e) => {
                                                setFieldValue('wheelId', null);
                                                setFieldValue('vehicle', e ?? null);
                                            }}
                                            defaultValue={values.vehicle ?? null}
                                        />
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <WheelDropDown
                                        vehicleId={values.vehicle}
                                        value={values.wheelId ? values.wheelId : ' '}
                                        error={errors.wheelId}
                                        getValue={(e) => setFieldValue('wheelId', e ?? null)}
                                        defaultValue={values.wheelId ?? null}
                                    />
                                </Grid>
                            </Grid>

                            <ActionButton type='submit' ref={props.refForAddSensor} />
                        </Form>
                    );
                }}
            </Formik>
        </AddSensorContainer>
    );
};
