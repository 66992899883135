import React, { useEffect } from 'react';
import { RoutesMapContent } from './RoutesMap.view';
import { RoutesMapProps } from './RoutesMap.type';
import { MapAction } from '../../../states/global/Map';
import { useSetRecoilState } from 'recoil';
import { MapActionsEnum } from '../../Map/Map.type';
import { Wrapper } from 'helpers/wrapper';

const RoutesMap: React.FC<RoutesMapProps> = (props): JSX.Element => {
    const setMapAction = useSetRecoilState(MapAction);

    useEffect(() => {
        if (props.route && !!props.route.length) {
            setTimeout(() => {
                setMapAction({
                    action: MapActionsEnum.LOAD_JOURNEY_LINE,
                    params: { route: props.route }
                });
            }, 1500);
        }
    }, [props.route, setMapAction]);

    return <RoutesMapContent data-testid='RoutesMap-testid' route={props.route} />;
};

export default Wrapper(RoutesMap);
