import { Box } from '@mui/material';
import styled from 'styled-components';

export const SlopeInfoPopupContainer = styled.div``;

export const SlopeValue = styled.td`
    text-align: left;
`;

export const ColorIndicator = styled(Box)<{ $color?: string }>`
    width: 10px;
    height: 10px;
    display: inline-block;
    background: ${(props) => props.$color || 'black'};
`;
