import React from 'react';
import { AdminHubsContent } from './AdminHubs.view';
import { AdminHubsProps } from './AdminHubs.type';
import { Wrapper } from 'helpers/wrapper';

const AdminHubs: React.FC<AdminHubsProps> = (): JSX.Element => {
    return <AdminHubsContent data-testid={'AdminHubs-testid'} />;
};

export default Wrapper(AdminHubs);
