import React from 'react';
import { NoDataContainerContainer } from './NoDataContainer.style';
import { NoDataContainerProps } from './NoDataContainer.type';
import { ERROR, WHITE } from '../../Ui/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Typography } from '@mui/material';

const NoDataContainerContent: React.FC<NoDataContainerProps> = ({ height, icon, iconColor, message }): JSX.Element => {
    return (
        <NoDataContainerContainer height={height || 200} data-testid='NoDataContainerContent'>
            <Avatar
                sx={{
                    width: 47,
                    height: 47,
                    bgcolor: iconColor || ERROR,
                    margin: 'auto',
                    marginBottom: 1
                }}
            >
                <FontAwesomeIcon icon={['fas', icon]} fontSize={22} color={WHITE} />
            </Avatar>
            <Typography variant='subtitle1'> {message} </Typography>
        </NoDataContainerContainer>
    );
};

export default NoDataContainerContent;
