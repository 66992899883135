import { Box, Slider, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import {
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK
} from '../../Ui/colors';
import { ThemeMode } from 'states/global/Theme';

export const AlertTemperatureSliderViewContent = styleMui(Box)({
    marginTop: 45,
    padding: '0 20px'
});

export const TemperatureSlider = styleMui(Slider)(
    (props: { percentage: [number, number, number]; isWizard?: boolean } & { theme: Theme }) => ({
        '& .MuiSlider-thumb': {
            background: '#757575'
        },
        '& .MuiSlider-mark': {
            background: 'none'
        },
        '& .MuiSlider-track': {
            background: 'none',
            borderColor: props.theme.palette.mode === ThemeMode.dark ? '#1d2630' : 'white',
            height: 15
        },
        '& .MuiSlider-rail': {
            opacity: 1,
            height: 15,
            background: `linear-gradient(to right, ${
                props.theme.palette.mode === ThemeMode.dark
                    ? props.isWizard
                        ? '#161c24'
                        : '#28313a'
                    : 'rgb(230,230,230)'
            } 0% ${props.percentage[0]}%, ${
                props.theme.palette.mode === ThemeMode.dark ? ALERT_LEVEL_1_DARK : ALERT_LEVEL_1
            } ${props.percentage[0]}% ${props.percentage[1]}%, ${
                props.theme.palette.mode === ThemeMode.dark ? ALERT_LEVEL_2_DARK : ALERT_LEVEL_2
            } ${props.percentage[1]}% ${props.percentage[2]}%, ${
                props.theme.palette.mode === ThemeMode.dark ? ALERT_LEVEL_3_DARK : ALERT_LEVEL_3
            } ${props.percentage[2]}% 100%)`
        }
    })
);
