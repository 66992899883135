import { Box, TableCell } from '@mui/material';
import styled from 'styled-components';

export const AlertSettingsChangeOverviewTableContainer = styled.div``;

export const LevelIndicator = styled(TableCell)((props: { background?: string }) => ({
    width: 170,
    backgroundImage: `linear-gradient(to right, ${props.background}, ${props.background
        ?.replace('rgb(', 'rgba(')
        .replace('1)', '0)')})`
}));

export const TableBoxCover = styled(Box)({
    display: 'flex'
});

export const TableBox = styled(Box)((props: { color?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    color: props.color || 'inherit'
}));

//background-image: linear-gradient(to right, rgb(193 36 36), rgba(193, 36, 36, 0));

// l2 rgb(237, 132, 27)
// l1 rgb(163, 147, 0)
// opt rgb(51, 138, 51)
