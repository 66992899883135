import React from 'react';
import { CustomersGlobalAdminViewContent } from './CustomersGlobalAdmin.style';
import { CustomersGlobalAdminViewProps } from './CustomersGlobalAdmin.type';
import GlobalAdmin from '../../components/Customer/GlobalAdmin/GlobalAdmin';

export const CustomersGlobalAdminContent: React.FC<CustomersGlobalAdminViewProps> = (): JSX.Element => {
    return (
        <CustomersGlobalAdminViewContent data-testid='CustomersGlobalAdminContent'>
            <GlobalAdmin data-testid='CustomersGlobalAdminContent-GlobalAdmin' />
        </CustomersGlobalAdminViewContent>
    );
};
