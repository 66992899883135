import React from 'react';
import * as style from './UiTab.style';
import * as type from './UiTab.type';

const UiTabContent: React.FC<type.UiTabProps> = (props): JSX.Element => (
    <style.UiTabContent
        data-testid={`UiTabContent-${props.testId}`}
        role='tabpanel'
        hidden={props.value !== props.index}
        overflow={props.overflow || 'auto'}
        padding={props.padding || '10px'}
    >
        {props.renderBeforeActive ? props.children : props.value === props.index && props.children}
    </style.UiTabContent>
);

export default UiTabContent;
