import React, { useState } from 'react';
import { RowButton } from './ActionButtons.style';
import { useMutation } from '@tanstack/react-query';
import { Grid } from '@mui/material';
import { Close, Done } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { ActionButtonsProps } from './ActionButtons.type';
import AlertNotification from 'api/AlertNotification';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { AlertNotificationModel } from 'models/AlertNotification.type';
import ActionModalContent from '../ActionModalContent/ActionModalContent';
import { Success } from 'components/Popup/Popup';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import AddAlertNotificationManagment from 'components/Alert/AddAlertNotificationManagment/AddAlertNotificationManagment';
import { diffDate, toCamelCase_H } from 'helpers';
import UiTimePicker from 'components/Ui/Components/UiTimePicker/UiTimePicker';

const alertNotificationApi = new AlertNotification();

const ActionButtons: React.FC<ActionButtonsProps> = ({
    alertNotification,
    alertNotificationSelected,
    setAlertNotificationSelected
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('alertNotificationManagment-RefreshTableAtom'));
    const [snoozeToValid, setSnoozeToValid] = useState<boolean>(true);
    const alertNotificationTimeTo: DateTime = DateTime.fromISO(alertNotification.timeTo, { zone: 'utc' });
    const alertNotificationTimeFrom: DateTime = DateTime.fromISO(alertNotification.timeFrom, { zone: 'utc' });
    const alertNotificationSnoozeTo: DateTime = DateTime.fromISO(alertNotification.snoozedTo, { zone: 'utc' });

    const handleModalAdd = (alertNotificationUser: AlertNotificationModel) => {
        const contentModalAdd = {
            id: 'AlertNotificationManagmentModalActionsAdd',
            customContent: <AddAlertNotificationManagment alertNotificationUser={alertNotificationUser} />,
            leftTitle: toCamelCase_H(translate('t.add_alert_notification_management')),
            width: 750,
            height: 470,
            widthUnit: 'px',
            heightUnit: 'px',
            onClose: () =>
                modalDispatch({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispatch({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    const handleModalAction = (alertNotificationUser: AlertNotificationModel, typeAction: 'cancel' | 'delete') => {
        const keyTitleModal =
            typeAction === 'cancel' ? 'cancel_alert_notification_management' : 'delete_alert_notification_management';
        const keyContentModal = typeAction === 'cancel' ? 't.are_you_sure_to_cancel' : 'p.are_you_sure_to_delete';

        const contentModalAdd = {
            id: 'AlertNotificationManagmentModalActionsAction',
            customContent: (
                <ActionModalContent
                    content={translate(keyContentModal)}
                    id={alertNotificationUser.id}
                    typeAction={typeAction}
                />
            ),
            leftTitle: translate(`t.${keyTitleModal}`),
            width: 420,
            height: 60,
            widthUnit: 'px',
            heightUnit: 'px',
            onClose: () =>
                modalDispatch({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispatch({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    const { mutate: snoozeSchedulerMutate, isLoading: snoozeSchedulerLoading } = useMutation(
        alertNotificationApi.updateSnoozeScheduler,
        {
            onSuccess: (data) => {
                if (data.alertNotificationCallsScheduler) {
                    Success({
                        text: `Snooze ${translate('t.applied')}`
                    });
                    setAlertNotificationSelected(undefined);
                    setRefreshTableAtom(true);
                }
            }
        }
    );

    const setSnoozedTo = (newData: DateTime | null): void => {
        if (DateTime.isDateTime(newData) && !newData.invalid) {
            let newAlertNotificationSelected = {
                id: alertNotificationSelected?.id || 0,
                snoozedTo: newData
            };
            if (diffDate(newData, alertNotificationTimeTo) > 0) {
                newAlertNotificationSelected = {
                    ...newAlertNotificationSelected,
                    snoozedTo: alertNotification.snoozedTo
                        ? alertNotificationSnoozeTo
                        : alertNotificationTimeFrom.plus({ minutes: 5 })
                };
            }
            setAlertNotificationSelected(newAlertNotificationSelected);
        }
        setSnoozeToValid(DateTime.isDateTime(newData) && !newData.invalid);
    };

    if (alertNotification.id === alertNotificationSelected?.id) {
        return (
            <Grid paddingTop={1} container>
                <Grid item md={6}>
                    <UiTimePicker
                        label='Snooze'
                        value={alertNotificationSelected.snoozedTo}
                        onDateChange={setSnoozedTo}
                        testId='NotificationManagment-UiTimePicker-SnoozeTo'
                        displaySeconds
                        hiddenHelperText
                    />
                </Grid>
                <Grid item>
                    <RowButton
                        variant='contained'
                        skin='success'
                        color='primary'
                        date-testid='AlertNotificationManagment-ApplySnooze-Button'
                        disabled={!snoozeToValid}
                        onClick={() => snoozeSchedulerMutate(alertNotificationSelected)}
                        loading={snoozeSchedulerLoading}
                    >
                        <Done fontSize='inherit' />
                    </RowButton>
                </Grid>
                <Grid item>
                    <RowButton
                        variant='contained'
                        skin='error'
                        color='error'
                        date-testid='AlertNotificationManagment-CloseSnooze-Button'
                        disabled={snoozeSchedulerLoading}
                        onClick={() => setAlertNotificationSelected(undefined)}
                    >
                        <Close fontSize='inherit' />
                    </RowButton>
                </Grid>
            </Grid>
        );
    }

    if (alertNotification.snoozedTo) {
        return (
            <>
                <UiButton
                    skin='success'
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        setAlertNotificationSelected({
                            id: alertNotification.id,
                            snoozedTo: alertNotificationSnoozeTo
                        });
                    }}
                    testid='AlertNotificationManagment-PushSnooze-Button'
                >
                    {translate('t.push')} snooze
                </UiButton>
                <UiButton
                    variant='contained'
                    skin='error'
                    color='error'
                    testid='AlertNotificationManagment-CancelSnooze-Button'
                    onClick={() => handleModalAction(alertNotification, 'cancel')}
                >
                    {translate('t.cancel')}
                </UiButton>
            </>
        );
    }

    return (
        <>
            <UiButton
                skin='success'
                color='primary'
                variant='contained'
                onClick={() =>
                    setAlertNotificationSelected({
                        id: alertNotification.id,
                        snoozedTo: alertNotificationTimeFrom.plus({ minutes: 5 })
                    })
                }
                testid='AlertNotificationManagment-Snooze-Button'
            >
                snooze
            </UiButton>
            <UiButton
                color='warning'
                variant='contained'
                onClick={() => handleModalAdd(alertNotification)}
                testid='AlertNotificationManagment-Edit-Button'
            >
                {translate('t.edit')}
            </UiButton>
            <UiButton
                skin='info'
                color='info'
                variant='contained'
                onClick={() => {
                    return handleModalAdd({
                        ...alertNotification,
                        id: 0,
                        date: DateTime.now()
                    });
                }}
                testid='AlertNotificationManagment-CopyTo-Button'
            >
                {translate('t.copy_to')}
            </UiButton>
            <UiButton
                variant='contained'
                skin='error'
                color='error'
                testid='AlertNotificationManagment-Delete-Button'
                onClick={() => handleModalAction(alertNotification, 'delete')}
            >
                {translate('t.delete')}
            </UiButton>
        </>
    );
};

export default ActionButtons;
