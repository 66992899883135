import PortalApi from 'helpers/api/PortalApiClient';
import { FileModel, File as FileImage } from '../models/File.type';

export default class File extends PortalApi<FileModel> {
    route = 'file';

    uploadImage = async (formData): Promise<FileImage> => {
        return await this.uploadFile({ data: formData });
    };
}
