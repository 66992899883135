import styled from 'styled-components';

export const HideColumnsContent = styled.div`
    display: inline-block;
`;

export const HideColumnCover = styled.div`
    display: grid;
    grid-row-gap: 3px;
    grid-column-gap: 3px;
    grid-template-columns: repeat(2, 180px);
`;
