import React from 'react';
import { useRecoilValue } from 'recoil';
import { WheelPositionViewContent } from './WheelPosition.style';
import { WheelPositionViewProps } from './WheelPosition.type';
import { useTranslation } from 'react-i18next';
import UiButton from '../../Ui/Components/UiButton';
import { Alert, Grid, MenuItem, TextField } from '@mui/material';
import { VehicleDetailsActionMenuAtom } from '../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { VehicleDetailsWidgetAtom } from '../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VARIANT_BUTTON } from 'components/Ui/variables';

export const WheelPositionContent: React.FC<WheelPositionViewProps> = ({
    newPosition,
    setNewPosition,
    mutate
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    return (
        <WheelPositionViewContent data-testid='WheelPositionContent' container spacing={2}>
            <Grid item xs={12} md={9} lg={9}>
                <TextField
                    select
                    id='wheel-position-select'
                    label={translate('t.position')}
                    size='small'
                    fullWidth
                    value={newPosition}
                    onChange={(event) => {
                        setNewPosition(+event.target.value);
                    }}
                    data-testid='WheelPosition-TextField'
                >
                    {vehicleDetailsWidgetAtom.tyreDetails?.map((tyre) => (
                        <MenuItem key={tyre.wheel.customPosition} value={tyre.wheel.customPosition}>
                            {tyre.wheel.customPosition}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
                <UiButton
                    id='swap-tyre-positions'
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    disabled={newPosition === vehicleDetailsActionMenuAtom.data?.wheel.customPosition}
                    onClick={() => {
                        mutate({
                            wheelId: vehicleDetailsActionMenuAtom.data?.wheel.id || 0,
                            customPosition: newPosition
                        });
                    }}
                    testid='WheelPosition-Swap-Button'
                >
                    {translate('t.swap')}
                </UiButton>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Alert severity='info'>{translate('p.interchange_of_positions')}</Alert>
            </Grid>
        </WheelPositionViewContent>
    );
};
