import React, { useEffect } from 'react';
import { RedirectProps } from './Redirect.type';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RedirectParams } from './Redirect.atom';
import { loggedIn } from 'helpers/authentication';
import { PatchAccountMutation } from 'components/Mutations/Account/Account';
import UiFullScreenLoader from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader';
import { UiFullScreenLoaderAtom } from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader.atom';
import LinkHash from 'api/LinkHash';
import { getUrlBasedOnHash } from 'helpers';
import { Wrapper } from 'helpers/wrapper';

const LinkHashApi = new LinkHash();

const Redirect: React.FC<RedirectProps> = (): JSX.Element => {
    const setFullScreenLoaderState = useSetRecoilState(UiFullScreenLoaderAtom);

    const params = useParams();
    const navigate = useNavigate();

    const [redirectParams, setRedirectParams] = useRecoilState(RedirectParams);

    const { mutateAsync } = PatchAccountMutation({
        onSuccess: () => {
            redirectParams.redirectTo && navigate(redirectParams.redirectTo);
        }
    });

    const changeCustomer = async (customerId: number) => {
        await mutateAsync({ data: { customer: customerId }, extendUrl: 'change-customer' });
    };

    const getParamsFromHash = async () => {
        setFullScreenLoaderState({ open: true });

        const data = await LinkHashApi.getByHash(params.hash || '');

        const deliveredParams = {
            redirectTo: getUrlBasedOnHash(data.route, data.params),
            customerId: data.customerId,
            params: {
                tab: 'map',
                leakDateFrom: data.params.ldf,
                leakDateTo: data.params.ldt,
                fastLeak: data.params.l
            }
        };
        setFullScreenLoaderState({ open: false });
        setRedirectParams(deliveredParams);
    };

    const determineActionByLogin = () => {
        if (loggedIn()) {
            getParamsFromHash();
        } else {
            setRedirectParams({
                redirectTo: location.pathname
            });
            navigate('/login');
        }
    };

    useEffect(() => {
        if (redirectParams.customerId) {
            changeCustomer(redirectParams.customerId);
        } else {
            redirectParams.redirectTo && navigate(redirectParams.redirectTo);
        }
    }, [redirectParams.customerId, redirectParams.redirectTo]);

    useEffect(() => {
        determineActionByLogin();
    }, [loggedIn()]);

    return (
        <>
            <UiFullScreenLoader />
        </>
    );
};

export default Wrapper(Redirect);
