import React from 'react';
import { ErrorProps, ErrorState } from './Popup.type';
import { ErrContent } from './Popup.view';
import { Component } from 'react';

class ErrorPopup extends Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = {
            expanded: true
        };
    }

    updateExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        return <ErrContent {...this.state} {...this.props} updateExpand={this.updateExpand} />;
    }
}

export default ErrorPopup;
