import { PassProps, UiTableViewProps } from '../UiTable/UiTable.type';

export type UiTableExportDropdownProps = {} & Pick<PassProps, 'queryKey' | 'exportName'> &
    Pick<UiTableViewProps<(props) => Promise<string>>, 'exportFn'>;

export enum ExportState {
    SET_COPY = 'SET_COPY',
    SET_CSV = 'SET_CSV',
    SET_NONE = 'SET_NONE'
}
