import PortalApi from 'helpers/api/PortalApiClient';
import { DeviceDiagnoseList, DeviceDiagnoseModel, OneDeviceDiagnose } from '../models/DeviceDiagnose.type';

export default class DeviceDiagnose extends PortalApi<DeviceDiagnoseModel> {
    route = 'device-diagnose';
    showError404 = false;

    getList = async (deviceId: number): Promise<DeviceDiagnoseList> => {
        return this.getTable({
            extendUrl: `list/${deviceId}`
        });
    };

    getOne = async (testId: number): Promise<OneDeviceDiagnose> => {
        return this.get({
            extendUrl: `one/${testId}`
        });
    };
}
