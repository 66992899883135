import { TimelineType } from 'states/global/Statistics';
import { Scale } from '../../Tyre/SeverityRankGraph/SeverityRankGraph.type';
import { GraphFetchData, xDomainConfig, yDomainConfig } from 'components/Graph/Graph.type';

type PassProps = {
    sensorId: number;
    sensorType: SensorStatisticsType;
};

export type SensorStatisticsProps = {} & PassProps;

export type SensorStatisticsViewProps = {
    xDomainConfig: xDomainConfig[];
    yDomain2: yDomainConfig[];
    sensorType: SensorStatisticsType;
    timeline: TimelineType;
    getStatistic: () => Promise<FetchData[]>;
    csvExport?: any;
    scale: Scale;
};

export type FetchData = Record<string, number> & GraphFetchData;

export type StatisticsScalesType = Record<string, { scale: Scale; scaleNumber: number }>;

export type StatisticsDataKeysType = Record<
    string,
    { dataKey: string; resultFetchKey: string; resultFetchItemKey: string }
>;

export enum SensorStatisticsType {
    TRANSMISSIONS_STATISTICS = 'transmissions',
    RSSI_STATISTICS = 'RSSI',
    BATTERY_STATISTICS = 'battery'
}
