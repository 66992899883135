import React from 'react';
import { SeverityRankGraphContent } from './SeverityRankGraph.view';
import { SeverityRankGraphProps } from './SeverityRankGraph.type';
import { BesRankTimeline, RankDateTimePicker } from 'pages/SeverityRank/atom';
import ExternalSystemMddBesTyreLogApi from 'api/ExternalSystemMddBesTyreLog';
import { useRecoilValue } from 'recoil';
import {
    ExternalSystemMddBesTyreLogExtended,
    ExternalSystemMddBesTyreLogRecords
} from 'models/ExternalSystemMddBesTyreLog.type';
import { deepCopyObj } from 'helpers';
import { SelectedVehicle } from 'states/global/Statistics';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const ExternalSystemMddBesTyreLog = new ExternalSystemMddBesTyreLogApi();

const SeverityRankGraph: React.FC<SeverityRankGraphProps> = (props): JSX.Element => {
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const rankTimeline = useRecoilValue(BesRankTimeline);
    const { fromUTCToTimezone, fromTimezoneToUTC } = useConverter();
    const dateRange = useRecoilValue(RankDateTimePicker);

    const getRankData = async () => {
        let timeline = deepCopyObj(rankTimeline);

        if (props.tyreCustomPosition) {
            let dataXHR = await ExternalSystemMddBesTyreLog.getByCriteria<ExternalSystemMddBesTyreLogRecords>({
                criteria: {
                    vehicle: props.vehicleId ? props.vehicleId : selectedVehicle.id,
                    tyre: props.tyreId as number,
                    position: props.tyreCustomPosition,
                    judgementDateTo: fromTimezoneToUTC(dateRange.current.dateTo, false, false),
                    judgementDateFrom: fromTimezoneToUTC(dateRange.current.dateFrom, false, false)
                }
            });

            for (let i = 0, length = dataXHR.externalSystemMddBesTyreLog.length; i < length; i++) {
                let timeKey = dataXHR.externalSystemMddBesTyreLog[i].timeKey;
                let date = DateTime.fromSeconds(timeKey, { zone: 'utc' }).startOf('day').toSeconds();
                if (timeline[date]) {
                    const localData = dataXHR.externalSystemMddBesTyreLog[i] as ExternalSystemMddBesTyreLogExtended;
                    localData.createdAtConverted = fromUTCToTimezone(
                        DateTime.fromISO(localData.judgementDate, { zone: 'utc' }).startOf('day'),
                        false,
                        '-'
                    ).split(' ')[0];
                    timeline[date] = localData;
                }
            }
        }
        return Object.values(timeline);
    };

    return <SeverityRankGraphContent {...props} data-testid={'SeverityRankGraph-testid'} getRankData={getRankData} />;
};

export default Wrapper(SeverityRankGraph);
