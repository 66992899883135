import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const RolesDropDownContent = styled.div``;

export const DropDownContainer = styled.div`
    && .MuiFormControl-fullWidth {
        margin: 4px 0 8px 0;
    }

    && .MuiGrid-root {
        width: 100%;
        padding: 0px;
    }

    ${applyStyleByMode({
        styleOld: `
            && .MuiFormLabel-root {
                font-size: 14px;
            }
        `
    })}
`;
