import { atomFamily } from 'recoil';
import { CRUD } from 'variables';
export type SensorStateType = {
    id: number;
    serialNumberHex: string;
    serialNumber: string;
    isExternal: boolean;
    isFaulty: boolean;
};

export type SensorStateAtomType = {
    originalSensor?: SensorStateType;
    newSensor?: SensorStateType;
    typeId: number;
    action: CRUD.ADD | CRUD.REPLACE | CRUD.REMOVE;
};

export const SensorStateAtom = atomFamily<SensorStateAtomType, number>({
    key: 'SensorStateAtom',
    default: {
        typeId: 1,
        action: CRUD.ADD
    }
});
