import React from 'react';
import { ReleaseAllHubsType } from './ReleaseAllHubs.type';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReleaseAllHubs: React.FC<ReleaseAllHubsType> = () => {
    const { t: translate } = useTranslation();

    return <Alert severity='warning'>{translate('p.are_you_sure_to_release_all_hubs')}</Alert>;
};

export default ReleaseAllHubs;
