import React from 'react';
import Box from '@mui/material/Box';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { VariantRegularTypes } from 'components/Ui/Components/UiButton/UiButton.type';
import { RankZoomButtonsViewProps } from './RankZoomButtons.type';
import { useTranslation } from 'react-i18next';

export const RankZoomButtonsContent: React.FC<RankZoomButtonsViewProps> = ({
    zoom,
    previousZoom,
    resetDateTime
}): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <Box>
            {zoom.zoomLevels.length > 0 && (
                <>
                    <Button variant={VariantRegularTypes.OUTLINED} onClick={previousZoom}>
                        {translate('t.previous_zoom')}
                    </Button>
                    <Button variant={VariantRegularTypes.OUTLINED} onClick={resetDateTime}>
                        {translate('t.reset_zoom')}
                    </Button>
                </>
            )}
        </Box>
    );
};
