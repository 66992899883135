import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { ERROR } from '../../Ui/colors';
import TableCell from '@mui/material/TableCell';
import Button from '../../Ui/Components/UiButton';

export const DeviceDiagnoseContainer = styled(Box)<{ hasRef?: boolean }>`
    padding: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const NoDataWarningMsg = styled(Alert)`
    &.MuiAlert-root {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 12px;
        margin: 3px;
        width: 100%;
    }
`;

export const RequestContent = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
`;

export const ActionContent = styled(Box)`
    display: flex;
    align-items: center;
`;

export const InncorrectValue = styled(TableCell)`
    &.MuiTableCell-root {
        font-weight: bold;
        color: ${ERROR};
        background: #ffcccc;
    }
`;

export const RefBtn = styled.button`
    display: none;
    width: 1px;
    height: 1px;
`;

export const ButtonCustom = styled(Button)``;
