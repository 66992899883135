import React from 'react';
import { TemperaturePressureContainer } from './TemperaturePressure.style';
import { TemperaturePressureViewProps } from './TemperaturePressure.type';
import TemperaturePressureTable from '../../components/Tyre/TemperaturePressureTable/TemperaturePressureTable';

export const TemperaturePressureContent: React.FC<TemperaturePressureViewProps> = (): JSX.Element => {
    return (
        <TemperaturePressureContainer data-testid='TemperaturePressureContent'>
            <TemperaturePressureTable />
        </TemperaturePressureContainer>
    );
};
