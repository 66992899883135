import React from 'react';
import { WheelAlertsContent } from './WheelAlerts.view';
import { WheelAlertsProps } from './WheelAlerts.type';
import { Wrapper } from 'helpers/wrapper';

const WheelAlerts: React.FC<WheelAlertsProps> = (props): JSX.Element => {
    return <WheelAlertsContent data-testid={'WheelAlerts-testid'} {...props} />;
};

export default Wrapper(WheelAlerts);
