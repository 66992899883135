import { Column } from 'models/Table.type';
import { CustomerReportModel } from 'models/CustomerReport.type';
export type ReportTableProps = {};

export type ReportTableViewProps = {
    columns: Column<CustomerReportModel>[];
};

export enum EditedMsgValues {
    NO_GPS = 'no_gps',
    NO_DISK_SPACE = 'no_disk_space',
    TUNNEL_SERVER_DISCREPANCY = 'tunnel_server_discrepancy',
    CPU_REPORT = 'cpu_report',
    NON_TRANSMITTING_SENSORS = 'non_transmitting_sensors'
}

export type CustomerEditType = {
    id: number;
    typeReport: string;
};
