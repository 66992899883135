import styled from 'styled-components';
import { Typography } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { Grid } from '@mui/material';

export const UiLoadingContainer = styled.div<{}>`
    && .MuiCircularProgress-root {
        margin-top: 30px;
        margin-left: 15px;
    }
`;

export const TyreLifespanReportViewContent = styled.div`
    ${applyStyleByMode({
        styleOld: `
            && .MuiCard-root {
                background-color:white !important;
                background: white !important;
            };
            && .MuiCardContent-root {
                background-color:white !important;
                background: white !important;
            }
            && .MuiCardHeader-root {
                background-color:white !important;
                background: white !important;
            }
        `
    })}
`;

export const LazyLoadingGrid = styled(Grid)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiGrid-root {
                display: inline-flex !important;
                margin-left: 10px;
                margin-top: 15px;
            }
        `
    })}
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        margin-left: 10px;
        align-self: center;
    }
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: center;
`;
