import { UseMutateFunction } from '@tanstack/react-query';
import { UserAccount, UserData, UserSetAccount } from '../../../models/User.type';
import { CommonDropDown } from '../GeneralSettingsForm/GeneralSettingsForm.type';

export type passProps = {
    user: UserAccount;
    userId: number;
    propertyName: string;
    title: string;
    showLabel: string;
    colorTextLabel?: string;
    dropDownLabel: string;
    updateDefaultValue: number;
    listOptions: CommonDropDown[];
    disabled?: boolean;
    testid?: string;
};

export type UpdateDropDownProps = {
    queryKey: string;
} & passProps;

export type UpdateDropDownViewProps = {
    anchorOriginCustom: PropertyPositionPopover;
    transformOriginCustom: PropertyPositionPopover;
    updateValue: number;
    setUpdateValue: (value: number) => void;
    loading: boolean;
    setAccountUser: UseMutateFunction<UserData, unknown, UserSetAccount, unknown>;
} & passProps;

export type PropertyPositionPopover = {
    vertical: number | 'center' | 'top' | 'bottom';
    horizontal: number | 'center' | 'right' | 'left';
};

export enum UpdateDropDownActions {
    SET_UPDATE_VALUE = 'SET_UPDATE_VALUE'
}

export type UpdateDropDownState = {
    updateValue: number;
};

export type UpdateDropDownActionType = {
    type: UpdateDropDownActions.SET_UPDATE_VALUE;
    payload: number;
};
