import styled from 'styled-components';
import UiButton from '../../Ui/Components/UiButton';
import { Autocomplete } from '@mui/material';

export const UserTableViewContent = styled.div``;

export const AutocompleteWithMargin = styled(Autocomplete)`
    && .MuiFormControl-fullWidth {
        margin: 4px 0 8px 0;
    }
`;

export const EditButton = styled(UiButton)`
    min-height: 25px;
    max-height: 25px;
    margin: 0;
`;
