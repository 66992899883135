import styled from 'styled-components';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { BACKGROUND_BLUE } from '../../Ui/colors';
import TableCell from '@mui/material/TableCell';
import { TableContainer } from '@mui/material';

export const HubGeneralInfoContainer = styled.div<{ isLoading: boolean }>`
    @keyframes moveToLeft {
        0% {
            transform: translateX(100px);
        }
        100% {
            transform: translateX(0px);
        }
    }

    animation-name: moveToLeft;
    animation-duration: 150ms;
`;

export const StyledCardActions = styled(CardActions)`
    &.MuiCardActions-root {
        justify-content: flex-end;
        padding: 8px 16px;
    }
`;

export const GridCover = styled(Grid)`
    &.MuiGrid-root {
        justify-content: space-evenly;
        & .MuiTextField-root {
            width: 100%;
        }
    }
`;

export const StyledTextField = styled(TextField)`
    &.MuiTextField-root {
        margin-top: 10px;
    }
`;

export const SaveBtn = styled(UiButton)<{ $isVisible: boolean }>`
    &.MuiButtonBase-root {
        visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
        background-color: ${BACKGROUND_BLUE};
        color: #ffff;
    }
`;

export const ShowSelfDiagnosticsBtn = styled(UiButton)`
    &.MuiButtonBase-root {
        background-color: ${BACKGROUND_BLUE};
        color: #ffff;
    }
`;
