import React from 'react';
import { AddHubNotifyProps, handleSubmit } from './AddHubNotify.type';
import { AddHubNotifyContent } from './AddHubNotify.view';
import { Wrapper } from 'helpers/wrapper';

const AddHubNotify: React.FC<AddHubNotifyProps> = (props): JSX.Element => {
    const initValues: Parameters<handleSubmit>[0] = props.initValues || {
        id: 0,
        hubId: null,
        vpnTunnel: false,
        reverseTunnel: false,
        replierLog: true,
        message: ''
    };

    const handleSubmit: handleSubmit = (values) => {
        props.handleMutation(values);
    };

    return (
        <AddHubNotifyContent
            data-testid='AddHubNotify-testid'
            initValues={initValues}
            refAddHub={props.refAddHub}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(AddHubNotify);
