import React from 'react';
import { AccelerationHeatmapContent } from './AccelerationHeatmap.view';
import { AccelerationHeatmapProps } from './AccelerationHeatmap.type';
import { Wrapper } from 'helpers/wrapper';

const AccelerationHeatmap: React.FC<AccelerationHeatmapProps> = (): JSX.Element => {
    return <AccelerationHeatmapContent data-testid={'AccelerationHeatmap-testid'} />;
};

export default Wrapper(AccelerationHeatmap);
