import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { PRIMARY, ERROR } from '../../components/Ui/colors';
import UiButton from '../../components/Ui/Components/UiButton';

export const CustomButton = styled(UiButton)`
    ${applyStyleByMode({
        styleOld: `
        color: ${PRIMARY} !important;
        padding: 1px 5px !important;
        text-transform: initial !important;`
    })}
`;

export const HeaderRefetchTime = styled.div`
    width: 100px;
`;

export const NoRevisionLabel = styled('span')`
    color: ${ERROR};
`;
