import {
    NotifcationActions,
    generalActionType,
    notificationIdActionType,
    notificationPulseActionType,
    notificationSoundActionType,
    stateNotification
} from './ButtonShowNotifications.type';

export const InicialStateNotification: stateNotification = {
    notificationId: undefined,
    notificationPulse: false,
    notificationSound: false
};

export const NotificationReducer = (
    state: stateNotification,
    action: generalActionType | notificationIdActionType | notificationPulseActionType | notificationSoundActionType
) => {
    switch (action.type) {
        case NotifcationActions.SET_NOTIFICATION_GENERAL:
            return {
                ...state,
                ...action.payload
            };
        case NotifcationActions.SET_NOTIFICATION_ID:
            return {
                ...state,
                notificationId: action.payload
            };
        case NotifcationActions.SET_NOTIFICATION_PULSE:
            return {
                ...state,
                notificationPulse: action.payload
            };
        case NotifcationActions.SET_NOTIFICATION_SOUND:
            return {
                ...state,
                notificationSound: action.payload
            };
        default:
            return state;
    }
};
