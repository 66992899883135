import React, { useCallback } from 'react';
import { SensorManagementPopupProps } from './SensorManagementPopup.type';
import { CRUD } from 'variables';
import AddSensor from './AddSensor';
import RemoveSensor from './RemoveSensor';
import ReplaceSensor from './ReplaceSensor';

const SensorManagementPopup = ({ sensorAction, position }: SensorManagementPopupProps): JSX.Element => {
    const PopupContent = useCallback(() => {
        switch (sensorAction) {
            case CRUD.ADD:
                return <AddSensor position={position} />;
            case CRUD.REPLACE:
                return <ReplaceSensor position={position} />;
            case CRUD.REMOVE:
                return <RemoveSensor position={position} />;
            default:
                return <></>;
        }
    }, [sensorAction]);

    return <PopupContent />;
};

export default SensorManagementPopup;
