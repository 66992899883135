import { atom, selector } from 'recoil';
import { PlaygroundObject } from './HeatmapPlayground.type';

const HeatmapInitValues = {
    14: {
        dotSize: 3,
        data: 10000,
        innerRadius: 0,
        outerRadius: 20,
        innerOpacity: 1,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    15: {
        dotSize: 5,
        data: 10000,
        innerRadius: 0,
        outerRadius: 20,
        innerOpacity: 1,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    16: {
        dotSize: 8,
        data: 10000,
        innerRadius: 0,
        outerRadius: 20,
        innerOpacity: 1,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    17: {
        dotSize: 12,
        data: 10000,
        innerRadius: 0,
        outerRadius: 20,
        innerOpacity: 1,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    18: {
        dotSize: 15,
        data: 10000,
        innerRadius: 0,
        outerRadius: 20,
        innerOpacity: 1,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    }
};

const CustomizedHeatmapSettings = {
    13: {
        dotSize: 0,
        data: 6996,
        innerRadius: 1,
        outerRadius: 5,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    14: {
        dotSize: 0,
        data: 6996,
        innerRadius: 2,
        outerRadius: 7,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    15: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: 9,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    16: {
        dotSize: 0,
        data: 6000,
        innerRadius: 1,
        outerRadius: 11,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    17: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: 15,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    },
    18: {
        dotSize: 0,
        data: 6000,
        innerRadius: 0,
        outerRadius: 15,
        innerOpacity: 0.7,
        outerOpacity: 0,
        disableBlur: false,
        disableDotSize: false
    }
};

export const HeatmapPlaygroundAtom = atom<PlaygroundObject>({
    key: 'HeatmapPlayground',
    default: localStorage.getItem('playgroundSettings')
        ? JSON.parse(localStorage.getItem('playgroundSettings') || '')
        : HeatmapInitValues
});

export const HeatmapSettings = selector({
    key: 'HeatmapSettings',
    get: () => {
        return (fromLS = false): PlaygroundObject => {
            if (!fromLS) {
                return CustomizedHeatmapSettings;
            }
            return localStorage.getItem('playgroundSettings')
                ? JSON.parse(localStorage.getItem('playgroundSettings') || '')
                : CustomizedHeatmapSettings;
        };
    }
});
