import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { CustomTable } from '../../User/MinesPermissions/MinesPermissions.style';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ReportPermissionsTableContainer = styled.div``;

export const Description2 = styled.span`
    display: flex;
`;

export const Description = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 250px;
`;

export const ReportTable = styled(CustomTable)`
    &&& .MuiTableCell-head {
        font-size: 1em;
        padding: 5px;
    }
    &&& .MuiTableCell-body {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
        padding: 0 3px;
    }
    &&& .MuiCheckbox-root {
        height: 10px !important;
    }
`;

export const ReportName = styled.span`
    display: flex;
    align-items: center;
`;

export const TooltipText = styled.span`
    font-size: 1.2em;
`;

export const NameDescription = styled.span`
    margin-left: 5px;
`;
