import React from 'react';
import { SensorRemoveProps } from '../../SensorManagement/SensorManagementPopup/SensorManagementPopup.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SensorStateAtom } from '../../SensorManagement/SensorManagementRow/SensorManagementRow.atom';
import {
    GreenRadio,
    Label,
    PopupContent,
    RedRadio,
    ReportSensorLabel,
    RowReportSensor
} from '../../SensorManagement/SensorManagementPopup/SensorManagementPopup.style';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { UnmountReason } from '../../../../variables';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import {
    HasInternalSensorAtom,
    VehicleDetailsWidgetAtom
} from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { TyreDetail } from 'models/Wheel.type';
import { VehicleModel } from 'models/Vehicle.type';
import ReportSensor from 'components/Sensor/ReportSensor/ReportSensor';

const RemoveSensorView = ({ value, setValue, position }: SensorRemoveProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const sensorState = useRecoilValue(SensorStateAtom(position));
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const data: TyreDetail | undefined = vehicleDetailsWidgetAtom.tyreDetails?.filter(
        (tyre) => tyre.wheel.position === vehicleDetailsActionMenuAtom.data?.wheel.customPosition
    )[0];
    const hasInternalSensor = useRecoilValue(HasInternalSensorAtom());

    return (
        <>
            <PopupContent>
                <FormControl>
                    <Label>
                        {translate('t.reason_removing')} {sensorState.originalSensor?.serialNumberHex}
                    </Label>
                    <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={sensorState.originalSensor?.isFaulty ? UnmountReason.REMOVING_FAULTY : value}
                        onChange={(event) => setValue(event.target.value as UnmountReason)}
                    >
                        {!sensorState.originalSensor?.isFaulty && (
                            <>
                                <FormControlLabel
                                    value={UnmountReason.END_OF_LIFE}
                                    control={<GreenRadio />}
                                    label={translate('t.end_of_life')}
                                />
                                <FormControlLabel
                                    value={UnmountReason.REMOVED_TO_STOCK}
                                    control={<GreenRadio />}
                                    label={translate('t.removed_to_stock')}
                                />
                            </>
                        )}
                        <FormControlLabel
                            value={UnmountReason.REMOVING_FAULTY}
                            control={<RedRadio />}
                            label={translate('t.removing_faulty')}
                        />
                    </RadioGroup>
                </FormControl>
            </PopupContent>
            {value === UnmountReason.REMOVING_FAULTY && !sensorState.originalSensor?.isFaulty && (
                <ReportSensor
                    vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id || 0}
                    vehicleName={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name || ''}
                    sensorId={sensorState.originalSensor?.id || 0}
                    sensorName={sensorState.originalSensor?.serialNumberHex || ''}
                    showOnlyGraph={true}
                    wheelPosition={data?.wheel.position}
                    internalOnVehicle={hasInternalSensor}
                    isExternal={!!sensorState.originalSensor?.isExternal}
                    allowReportLeakAlert={true}
                />
            )}
            {sensorState.originalSensor?.isFaulty && (
                <ReportSensorLabel>
                    <RowReportSensor>
                        {translate('p.sensor_marked_faulty', {
                            serial_number: sensorState.originalSensor?.serialNumberHex
                        })}
                    </RowReportSensor>
                </ReportSensorLabel>
            )}
        </>
    );
};

export default RemoveSensorView;
