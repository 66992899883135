import React from 'react';
import * as style from './UiTableColumnFilter.style';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';

const UiTableColumnFilterContent: React.FC<{ column; applyFiltersFlag }> = ({
    column,
    applyFiltersFlag
}): JSX.Element => {
    const { cellValue, setFilterValue } = useTableFilter({
        filterContent: { name: column.Header, value: column.filterValue?.value },
        applyFiltersFlag: applyFiltersFlag,
        setFilter: column.setFilter
    });

    return (
        <style.UiTableColumnFilterContent data-testid='ColumnFilterContent'>
            <style.FilterTextField
                label={column.Header}
                value={cellValue?.value ?? ''}
                variant='outlined'
                size='small'
                inputProps={{ maxLength: 45 }}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                onChange={(e) => {
                    setFilterValue(e);
                }}
            />
        </style.UiTableColumnFilterContent>
    );
};

export default UiTableColumnFilterContent;
