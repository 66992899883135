import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const UiVehicleDropdownContent = styled.div``;

export const VehicleLoaderContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const VehicleLoader = styled(CircularProgress)`
    width: 20px !important;
    height: 20px !important;
`;
