import React from 'react';
import { SensorsViewContent } from './Sensors.style';
import SensorsTables from '../../components/Sensor/SensorsTables/SensorsTables';

export const SensorsContent: React.FC = (): JSX.Element => {
    return (
        <SensorsViewContent data-testid='SensorsContent'>
            <SensorsTables />
        </SensorsViewContent>
    );
};
