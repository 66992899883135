import React from 'react';
import { useRecoilValue } from 'recoil';
import { ColumnStickyHeader, TableCellSubHeaderSticky, ColumnTableSortLabel } from './TempAndPressListHeader.style';
import { TempAndPressListHeaderViewProps } from './TempAndPressListHeader.type';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { UserInfo } from '../../../../states/global/User';

let wheelLabel = (index): JSX.Element => {
    return (
        <TableCell align='center' style={{ minWidth: 50 }} colSpan={2} key={index}>
            Pos. {index + 1}
        </TableCell>
    );
};

export const TempAndPressListHeaderContent: React.FC<TempAndPressListHeaderViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    let wheelsHeader: JSX.Element[] = [];
    let tempPressHeader: JSX.Element[] = [];

    let tempPressLabel = (index): JSX.Element => {
        let secondColumnLabel: string = props.containsRecomValues
            ? 'THP'
            : `°${userInfo.user?.userSetting.temperatureUnit}`;
        return (
            <React.Fragment key={index}>
                <TableCellSubHeaderSticky $isPortalOld={false} align='center' $tableDisplay={props.tableDisplay ?? ''}>
                    {userInfo.user?.userSetting.pressureUnit}
                </TableCellSubHeaderSticky>
                <TableCellSubHeaderSticky $isPortalOld={false} align='center'>
                    {secondColumnLabel}
                </TableCellSubHeaderSticky>
            </React.Fragment>
        );
    };

    for (let i = 0, length = props.maxPosition; i < length; i++) {
        wheelsHeader.push(wheelLabel(i));
        tempPressHeader.push(tempPressLabel(i));
    }

    const getSorting = (column: string): 'desc' | 'asc' | null => {
        if (props.orderDirection && props.orderDirection.column === column) {
            return props.orderDirection.order;
        }
        return null;
    };

    return (
        <TableHead>
            <TableRow>
                <ColumnStickyHeader
                    rowSpan={2}
                    key='vehicle'
                    align='center'
                    $lef={0}
                    $minWidth='50'
                    onClick={() => props.handleSortRequest('name', getSorting('name') === 'desc' ? 'asc' : 'desc')}
                    data-testid='SortByVehicleName-Button'
                >
                    <ColumnTableSortLabel $active={!!getSorting('name')} active direction={getSorting('name') ?? 'asc'}>
                        {translate('t.vehicle')}
                    </ColumnTableSortLabel>
                </ColumnStickyHeader>
                <ColumnStickyHeader rowSpan={2} key='VehicleIcons' align='center' $lef={155}>
                    {' '}
                </ColumnStickyHeader>
                {wheelsHeader}

                <TableCell
                    rowSpan={2}
                    key='last_transmission_hub'
                    align='center'
                    style={{ minWidth: 130 }}
                    onClick={() =>
                        props.handleSortRequest(
                            'lastTransmission',
                            getSorting('lastTransmission') === 'desc' ? 'asc' : 'desc',
                            true
                        )
                    }
                >
                    <ColumnTableSortLabel
                        $active={!!getSorting('lastTransmission')}
                        active
                        direction={getSorting('lastTransmission') ?? 'asc'}
                        data-testid='SortByLastTransmission-Button'
                    >
                        {translate('t.last_transmission_hub')}
                    </ColumnTableSortLabel>
                </TableCell>

                <TableCell rowSpan={2} key='position_temp_difference ' align='right' style={{ maxWidth: 97 }}>
                    {translate('t.position_temp_difference')}
                </TableCell>

                <TableCell
                    rowSpan={2}
                    key='density'
                    align='right'
                    onClick={() =>
                        props.handleSortRequest(
                            'distanceTraveled',
                            getSorting('distanceTraveled') === 'desc' ? 'asc' : 'desc'
                        )
                    }
                >
                    <ColumnTableSortLabel
                        $active={!!getSorting('distanceTraveled')}
                        active
                        direction={getSorting('distanceTraveled') ?? 'asc'}
                        data-testid='SortByDistanceTraveled-Button'
                    >
                        {translate('t.distance_travelled')}
                        {`(${userInfo.user?.userSetting.speedUnit === 'km/h' ? 'km' : 'mi'})`}
                    </ColumnTableSortLabel>
                </TableCell>
            </TableRow>
            <TableRow>{tempPressHeader}</TableRow>
        </TableHead>
    );
};
