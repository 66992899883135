import { Grid } from '@mui/material';
import styled from 'styled-components';

export const AddCustomerSettingsContainer = styled(Grid)`
    width: 100%;
    justifycontent: 'space-around';
`;

export const AddInputCover = styled.div`
    margin: 3px 5px;
`;

export const ViewContent = styled.div`
    padding-top: 12px;
    display: flex;
    width: 100%;
`;
