import React, { useState } from 'react';
import { CounterActionType, SelectOption } from './CounterAction.type';
import { useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup';
import { RefreshPressTempTable } from 'components/Tyre/TemperaturePressureTable/TempPressFilters.atom';
import CounterActionView from './CounterAction.view';
import { GetCounterActions } from 'components/Queries/TPMS/Tpms';
import Vehicle from 'api/Vehicle';
import { CounterActionModel } from 'models/CounterAction.type';
import { useTranslation } from 'react-i18next';
import { TableRefreshAtom } from 'states/component/Table';

const prepareDataForPOST = (options: SelectOption[]): number[] => {
    let arrayToSend: number[] = [];

    for (let i = 0; i < options.length; i++) {
        arrayToSend.push(options[i].id);
    }

    return arrayToSend;
};

const vehicleAPI = new Vehicle();

const CounterAction = (props: CounterActionType): JSX.Element => {
    const [state, setState] = useState<SelectOption[]>([]);
    const { t: translate } = useTranslation();
    const setRefreshPressTempTable = useSetRecoilState(RefreshPressTempTable);
    const setRefreshTable = useSetRecoilState(TableRefreshAtom);

    const mutation = useMutation(() => vehicleAPI.postCounterAction(props.vehicleId, prepareDataForPOST(state)), {
        onSuccess: () => {
            Success({ text: translate('t.changes_have_been_saved_successfully') });
            setTimeout(() => {
                props.refetch();
            }, 500);
            setRefreshPressTempTable(true);
            setRefreshTable((prev) => !prev);
        }
    });

    const onClickSave = () => {
        mutation.mutate();
    };

    const handleOnCounterActionIsSelected = (data: SelectOption[]) => {
        setState(data);
    };

    const handleOnSuccessQuery = (data: CounterActionModel) => {
        if (data && props.counterActionIds) {
            setState(
                props.counterActionIds.map((id) => {
                    return { id: id, name: data[id - 1].name };
                })
            );
        }
    };

    return (
        <GetCounterActions onSuccess={handleOnSuccessQuery}>
            {({ data, isLoading: queryIsLoading }) => (
                <CounterActionView
                    queryIsLoading={queryIsLoading}
                    selectedOptions={state}
                    data={data}
                    mutationIsloading={mutation.isLoading}
                    onSaveIsClicked={onClickSave}
                    onCounterActionIsSelected={handleOnCounterActionIsSelected}
                    {...props}
                />
            )}
        </GetCounterActions>
    );
};

export default CounterAction;
