import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { TopBarAtom } from '../../components/Mixs/DataExport/TopBar/TopBar.atom';
import UiWidget from '../../components/Ui/Components/UiWidget';
import { OverSpeedingViewContent } from './OverSpeeding.style';
import { useQuery } from '@tanstack/react-query';
import DataExport from '../../components/Mixs/DataExport';
import SpeedEvent from '../../api/SpeedEvent';
import { Success } from '../../components/Popup';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { faGaugeMax } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { UserInfo } from 'states/global/User';
import { LoadedStatus } from 'components/Tracking/ExcessiveAcc/ExcessiveAcc.style';
import { MenuItem, Box } from '@mui/material';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import { Wrapper } from 'helpers/wrapper';

const speedEventAPI = new SpeedEvent();

const OverSpeeding: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit, displayUserUnits } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);
    const userInfo = useRecoilValue(UserInfo);

    const { refetch } = useQuery(
        ['GetTempTyreNotificationsCSV'],
        () =>
            speedEventAPI.getOverSpeedingReportsCsv({
                vehicleId: topBarAtom.vehicle.id,
                measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                measuredTo: topBarAtom.dateTo.toUTC().toISO(),
                operatorId: topBarAtom.operatorId,
                loaded: topBarAtom.loaded
            }),
        {
            enabled: false,
            onSuccess: () => Success({ text: translate('p.report_success_message') })
        }
    );

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicleName'
        },
        {
            header: translate('t.measured_at'),
            accessor: 'measuredAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: `${translate('t.actual_speed')} (${displayUserUnits.speed})`,
            accessor: 'speed',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.speed,
                    value: props,
                    displayUnits: false,
                    fixed: 1
                })
        },
        {
            header: `${translate('t.speed_limit')} (${displayUserUnits.speed})`,
            accessor: 'speedLimit',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.speed,
                    value: props,
                    displayUnits: false,
                    fixed: 1
                })
        },
        {
            header: translate('t.area'),
            accessor: 'areaName'
        }
    ];

    useEffect(() => {
        setPageHelpButton({ article: 'data-exports-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <OverSpeedingViewContent data-testid='OverSpeeding-testid'>
            <UiWidget
                title={translate('t.speed_in_curves')}
                id='over-speeding'
                avatar={
                    <UiIcon
                        icon={faGaugeMax}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={speedEventAPI.getOverSpeedingReports}
                        downloadFn={refetch}
                        queryKey='OverSpeeding'
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO(),
                            operatorId: topBarAtom.operatorId,
                            loaded: topBarAtom.loaded
                        }}
                        additionalElement={
                            <>
                                {userInfo.user?.customer.id === 155 && (
                                    <>
                                        <LoadedStatus
                                            data-testid={'overspeeding-status-select'}
                                            id='overspeeding-status-select'
                                            select
                                            label={translate('t.loaded_state')}
                                            size={'small'}
                                            value={topBarAtom.loaded}
                                            onChange={(event) => {
                                                setTopBarAtom((current) => ({
                                                    ...current,
                                                    loaded: +event.target.value
                                                }));
                                            }}
                                        >
                                            <MenuItem value={-1}>{translate('t.all')}</MenuItem>
                                            <MenuItem value={1}>{translate('t.loaded')}</MenuItem>
                                            <MenuItem value={0}>{translate('t.unloaded')}</MenuItem>
                                        </LoadedStatus>
                                        {
                                            <Box display={'inline-flex'}>
                                                <LazyloadingAutocomplete
                                                    data-testid={'HeatmapSpeedIncidents-LazyloadingAutocomplete'}
                                                    id={'speed-incidents-operator-lazyloading'}
                                                    queryId='speed-incidents-operator-lazyloading'
                                                    optionKey='firstNameLastName'
                                                    optionValue='id'
                                                    size='small'
                                                    clearButton
                                                    customMargin='0 0 0 4px'
                                                    query={speedEventAPI.getTable}
                                                    extendUrl='incidents/vehicle-operators'
                                                    label={translate('t.operator')}
                                                    onValueChange={(value) => {
                                                        setTopBarAtom((current) => ({
                                                            ...current,
                                                            operatorId: value || null
                                                        }));
                                                    }}
                                                    getOptionLabel={(value) => {
                                                        return {
                                                            text: `${value.firstName} ${value.lastName}`,
                                                            html: undefined
                                                        };
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </>
                                )}
                            </>
                        }
                    />
                }
            />
        </OverSpeedingViewContent>
    );
};

export default Wrapper(OverSpeeding);
