import styled from 'styled-components';

export const ReportSensorContainer = styled.div``;

export const RowReportSensor = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
