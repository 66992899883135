import React from 'react';
import { LinkContainer } from './VehicleItem.style';
import { VehicleItemProps } from './VehicleItem.type';
import { ListItem, ListItemText } from '@mui/material';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { COLOR_GREY, WHITE } from 'components/Ui/colors';

const VehicleItem: React.FC<VehicleItemProps> = ({ vehicle }): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);

    return (
        <ListItem component='a' key={vehicle.id} sx={{ textAlign: 'center' }}>
            <LinkContainer style={{ textAlign: 'center', width: '100%' }}>
                <UiLink
                    $padding='0'
                    styleCustom={{ width: '100%' }}
                    content={<ListItemText key={vehicle.name} primary={vehicle.name} />}
                    url={`/vehicle-status?vehicle=${vehicle.id}`}
                    color={applyStyleByMode({
                        theme: ThemeMode?.mode,
                        light: COLOR_GREY,
                        dark: WHITE
                    })}
                />
            </LinkContainer>
        </ListItem>
    );
};

export default VehicleItem;
