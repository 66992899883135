import React from 'react';
import { RoutesListViewContent } from './RoutesList.style';
import { RoutesListViewProps } from './RoutesList.type';
import { Grid } from '@mui/material';
import RoutesTable from '../RoutesTable/RoutesTable';
import RoutesMap from '../RoutesMap/RoutesMap';

export const RoutesListContent: React.FC<RoutesListViewProps> = (props): JSX.Element => {
    return (
        <RoutesListViewContent data-testid='RoutesListContent'>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <RoutesTable data-testid={'RoutesListContent-RoutesTable'} setRoute={props.setRoute} />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <RoutesMap data-testid={'RoutesListContent-RoutesMap'} route={props.route} />
                </Grid>
            </Grid>
        </RoutesListViewContent>
    );
};
