import en_GB from 'variables/languages/en_GB.json';
import en_US from 'variables/languages/en_US.json';
import es_ES from 'variables/languages/es_ES.json';
import fr_CA from 'variables/languages/fr_CA.json';
import pt_PT from 'variables/languages/pt_PT.json';
import ru_RU from 'variables/languages/ru_RU.json';
import uk_UA from 'variables/languages/uk_UA.json';
import ar_SA from 'variables/languages/ar_SA.json';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const InitializeTranslations = (lng = 'en_GB') => {
    i18n.use(initReactI18next).init({
        resources: {
            en_GB: {
                translation: en_GB
            },
            en_US: {
                translation: en_US
            },
            es_ES: {
                translation: es_ES
            },
            fr_CA: {
                translation: fr_CA
            },
            pt_PT: {
                translation: pt_PT
            },
            ru_RU: {
                translation: ru_RU
            },
            uk_UA: {
                translation: uk_UA
            },
            ar_SA: {
                translation: ar_SA
            }
        },
        lng: lng,
        fallbackLng: lng,
        interpolation: {
            escapeValue: false
        }
    });

    return i18n;
};
