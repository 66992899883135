import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BesSevereDrivingViewContent } from './BesSevereDriving.style';
import { BesSevereDrivingViewProps } from './BesSevereDriving.type';
import { useTranslation } from 'react-i18next';
import { MapAction, MapActionData } from 'states/global/Map';
import { MapActionsEnum, MapModes } from 'components/Map/Map.type';
import Map from 'components/Map';
import Widget from 'components/Ui/Components/UiWidget';
import { faSteeringWheel } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BesSevereDrivingContent: React.FC<BesSevereDrivingViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const mapData = useRecoilValue(MapActionData);

    useEffect(() => {
        if (!mapData.besHasMapData && mapData.besMapDataRequested) {
            setMapAction({
                action: MapActionsEnum.SHOW_MAP_MESSAGE,
                params: {
                    message: translate('t.there_no_data')
                }
            });
        }
    }, [mapData.besHasMapData, mapData.besMapDataRequested]); // check if possible to make this better

    return (
        <BesSevereDrivingViewContent data-testid='BesSevereDrivingContent'>
            <Widget
                title={translate('t.driving_assistance')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={<FontAwesomeIcon icon={faSteeringWheel} />}
                $contentHeight={60}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
            >
                <Map mapMode={MapModes.CUSTOM_DATA} />
            </Widget>
        </BesSevereDrivingViewContent>
    );
};
