import React, { useEffect, useState } from 'react';
import * as type from './UiMenu.type';
import * as view from './UiMenu.view';
import { Wrapper } from 'helpers/wrapper';
// import module

const UiMenu: React.FC<type.UiMenuProps> = (props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.close && handleClose();
    }, [props.close]);

    return <view.UiMenuContent openMenu={handleClick} closeMenu={handleClose} {...props} anchorEl={anchorEl} />;
};

export default Wrapper(UiMenu);
