import Feature from 'ol/Feature';
import Vector from 'ol/source/Vector';
import * as OlProj from 'ol/proj';
import { CustomLayers, DefaultSettings, MapActions, PointExcessiveSettings } from '../Map.type';
import Circle from 'ol/geom/Circle';
import Point from 'ol/geom/Point';
import AccelerometerEvent from '../../../api/AccelerometerEvent';
import { Geometry } from 'ol/geom';
import { Icon, Style, Stroke } from 'ol/style';
import { DateTime } from 'luxon';
import { MapActionDataType } from 'states/global/Map';
import { getImgSource } from 'helpers/image';

const AccelerometerEventApi = new AccelerometerEvent();

const speedParse = (accel: number): number => {
    return Math.abs(accel) / 9.8;
};

export const createAcelerationPoint = (
    pointSettings: PointExcessiveSettings,
    defaultSettings: DefaultSettings
    //enableLoaded: boolean
): Feature<Geometry> => {
    const featureId = `excessive-${pointSettings.id}`;
    let excessiveAccFeature = pointSettings.loaded
        ? new Feature({
              geometry: new Point(
                  OlProj.transform(
                      [pointSettings.position.latitude, pointSettings.position.longitude],
                      defaultSettings.epsg[0],
                      defaultSettings.epsg[1]
                  )
              ),
              id: featureId
          })
        : new Feature({
              geometry: new Circle(
                  OlProj.transform(
                      [pointSettings.position.latitude, pointSettings.position.longitude],
                      defaultSettings.epsg[0] || 'EPSG:4326',
                      defaultSettings.epsg[1] || 'EPSG:3857'
                  )
              ),
              id: featureId
          });

    const accelY = speedParse(pointSettings.accelY);
    const accelerationPointStyle = pointSettings.loaded
        ? new Style({
              zIndex: accelY < 0.15 ? 2 : pointSettings.accelY < 0.2 ? 5 : 10,
              image: new Icon({
                  opacity: 1,
                  src: getImgSource(accelY < 0.2 ? 'speedPointLoaded' : 'speedPointUnloaded'),
                  scale: 0.3
              })
          })
        : new Style({
              zIndex: speedParse(pointSettings.accelY) < 0.15 ? 2 : speedParse(pointSettings.accelY) < 0.2 ? 5 : 10,
              stroke: new Stroke({
                  color:
                      speedParse(pointSettings.accelY) < 0.15
                          ? '#FFFFE0'
                          : speedParse(pointSettings.accelY) < 0.2
                          ? '#FFFF00'
                          : '#FF0000',
                  width: 7
              })
          });

    let styles: Style[] = [accelerationPointStyle];
    excessiveAccFeature.setStyle(styles);

    return excessiveAccFeature;
};

export const drawExcessiveAcc = async (
    map: MapActions,
    mapData: MapActionDataType,
    storeMapActionData,
    timezoneName?: string
): Promise<void> => {
    const heatmapLayer = map.findLayerByName(CustomLayers.EXCESSIVE_ACC);

    if (mapData.heatmapDateRange?.dateFrom && mapData.heatmapDateRange?.dateTo && heatmapLayer.length) {
        map.updateLoadingMapStatus(true);
        map.toggleMapInteractions();
        const heatmapSource = new Vector();
        const dateFromDefault = mapData.heatmapDateRange?.dateFrom || DateTime.local().minus({ days: 30 });
        const dateToDefault = mapData.heatmapDateRange?.dateTo || DateTime.local();
        const dateFromParam = DateTime.fromMillis(
            dateFromDefault.toUnixInteger() * 1000,
            timezoneName || 'Europe/Bratislava'
        )
            .toUTC()
            .toISO();
        const dateToParam = DateTime.fromMillis(
            dateToDefault.toUnixInteger() * 1000,
            timezoneName || 'Europe/Bratislava'
        )
            .toUTC()
            .toISO();

        let loadedCountsExs = {
            loaded: {
                below: 0,
                above: 0
            },
            unloaded: {
                below: 0,
                above: 0
            },
            total: 0
        };

        const ExcessiveAcc = await AccelerometerEventApi.getAccelerometerEvent({
            vehicle: mapData?.selectedVehicles?.length ? mapData.selectedVehicles[0] : undefined,
            measuredFrom: dateFromParam,
            measuredTo: dateToParam,
            loaded: mapData.loaded,
            vehicleOperator: mapData.vehicleOperator
        });

        if (ExcessiveAcc.accelerometerEvent) {
            ExcessiveAcc.accelerometerEvent.forEach((value) => {
                heatmapSource.addFeature(
                    createAcelerationPoint(value, map.defaultSettings /* !!mapData.enableLoaded*/)
                );
                const loadedKey = value.accelY < 0.2 ? 'below' : 'above';
                const loadedStatus = value.loaded ? 'loaded' : 'unloaded';
                loadedCountsExs[loadedStatus][loadedKey] += 1;
            });
            loadedCountsExs.total = ExcessiveAcc.total;
            storeMapActionData({ loadedCountsExs });

            heatmapLayer[0].setSource(heatmapSource);
        }

        map.updateLoadingMapStatus(false);
        map.toggleMapInteractions();
    }
};
