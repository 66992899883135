import React from 'react';
import { AreaEditorContent } from './AreaEditor.view';
import { AreaEditorProps } from './AreaEditor.type';
import { Wrapper } from 'helpers/wrapper';

const AreaEditor: React.FC<AreaEditorProps> = (): JSX.Element => {
    return <AreaEditorContent data-testid={'AreaEditor-testid'} />;
};

export default Wrapper(AreaEditor);
