import React, { useEffect } from 'react';
import { UiLegendContent } from './UiLegend.view';
import { UiLegendProps } from './UiLegend.type';
import { Wrapper } from 'helpers/wrapper';

const UiLegend: React.FC<UiLegendProps> = (props): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.close && handleClose();
    }, [props.close]);

    return (
        <UiLegendContent
            {...props}
            openLegend={handleClick}
            closeLegend={handleClose}
            anchorEl={anchorEl}
            data-testid='UiLegend2-testid'
        />
    );
};

export default Wrapper(UiLegend);
