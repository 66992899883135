import styled from 'styled-components';
import { Button, Grid, Typography } from '@mui/material';
import { PRIMARY } from '../../Ui/colors';

export const DeviceStatisticsTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word !important;
    }
    width: 98%;
    && .MuiCardContent-root {
        padding: 16px 0;
        font-size: 13px !important;
    }

    & .MuiTableHead-root {
        border-bottom: 1px solid #474544;
        background: #f9f9f9;
    }

    margin-top: 10px;
`;

export const PercentageGraph = styled.div`
    border-radius: 5px;
    margin: 15px 20px;
    height: 10px;
`;

export const GridContainer = styled(Grid)`
    text-align: center;
    justify-content: center;
`;

export const TextTable = styled(Typography)`
    width: 330px;
    padding: 5px;
    margin-top: 30px;
    background-color: #fffcc4;
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
        margin: 8px 20px;
        font-weight: lighter;
        overflow-wrap: break-word !important;
    }
`;

export const TextModal = styled(Typography)`
    &.MuiTypography-root {
        font-size: 12px;
        font-weight: lighter;
        margin: 8px 10px;
        overflow-wrap: break-word;
    }
`;

export const TextBar = styled(Typography)`
    float: left;

    &.MuiTypography-root {
        font-size: 12px;
        margin: 8px 20px;
        font-weight: lighter;
        overflow-wrap: break-word !important;
    }
`;

export const TextPercentage = styled(Typography)`
    float: right;
    &.MuiTypography-root {
        font-size: 12px;
        margin: 8px 20px;
        font-weight: lighter;
        overflow-wrap: break-word !important;
    }
`;

export const Span = styled.span`
    padding-right: 10px;
    font-size: 16px;
`;

export const TextDate = styled(Typography)`
    color: #3c4444;
    &.MuiTypography-root {
        font-size: 1rem;
        margin: 8px 20px;
        font-weight: lighter;
        overflow-wrap: break-word !important;
    }
`;

export const GpsStatisticsGrid = styled(Grid)`
    margin-top: 10px !important;
`;

export const customButtonStatistics = `
    -webkit-box-shadow: inset -7px -193px 3px -190px rgba(242, 242, 242, 1);
    -moz-box-shadow: inset -7px -193px 3px -190px rgba(242, 242, 242, 1);
    box-shadow: inset -7px -193px 3px -190px rgba(242, 242, 242, 1);

    &.MuiButton-root {
        font-size: 12px;
        margin: 15px 10px;
        border: 1px solid ${PRIMARY} !important;
        color: ${PRIMARY} !important;
        background-color: white !important;
        padding: 6px 8px !important;
    }
`;

export const ButtonStatistics = styled(Button)`
    ${customButtonStatistics}
`;

export const LastTransmissionSpan = styled('span')<{ $last?: boolean }>`
    margin: ${(props) => (props.$last ? '0 0 0 10px' : '0 10px')};
`;
