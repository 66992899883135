import React from 'react';
import { UserActionContainer } from './UserAction.style';
import { UserActionViewProps } from './UserAction.type';
import AdminAction from '../../components/User/AdminAction';

export const UserActionContent: React.FC<UserActionViewProps> = (): JSX.Element => {
    return (
        <UserActionContainer data-testid='UserActionContent'>
            <AdminAction />
        </UserActionContainer>
    );
};
