export enum Filterlevel {
    LevelOne = 1,
    LevelDwo = 2,
    LevelTree = 3
}

export type stateAlertFilters = {
    level: Filterlevel[] | null;
};

export enum AlertActions {
    SET_ALERT_FILTER = 'SET_ALERT_FILTER',
    RESET_ALERT_FILTER = 'RESET_ALERT_FILTER'
}

export type filtersActionType = {
    type: AlertActions.SET_ALERT_FILTER;
    payload: Filterlevel[] | null;
};

export type filtersResetActionType = {
    type: AlertActions.RESET_ALERT_FILTER;
    payload: Filterlevel[] | null;
};

export type PopUpalert = {
    AddFilterAlertByLevel: (level: number) => void;
};
