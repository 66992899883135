import { atom } from 'recoil';
import { DateTime } from 'luxon';
import { TyreModel } from '../../../models/Tyre.type';
import { CRUD } from 'variables';
const DEFAULT_TYRE_STATE: TyreStateAtomType = {
    originalTyre: {
        id: 0,
        tyreSerialNumber: '',
        customPosition: 0,
        vehicleName: ''
    },
    newTyre: {
        id: 0,
        tyreSerialNumber: '',
        customPosition: 0,
        vehicleName: ''
    },
    state: CRUD.MOUNT
};

export type TyreStateType = Pick<TyreModel, 'id' | 'vehicleName' | 'tyreSerialNumber' | 'customPosition'>;

export type TyreStateAtomType = {
    originalTyre: TyreStateType;
    newTyre: TyreStateType;
    state: CRUD.MOUNT | CRUD.UNMOUNT | CRUD.REPLACE;
};

export const TyreStateAtom = atom<TyreStateAtomType>({
    key: 'TyreStateAtom',
    default: DEFAULT_TYRE_STATE
});

export const MountDateAtom = atom<DateTime>({
    key: 'MountDateAtom',
    default: DateTime.local()
});

export const UnmountDateAtom = atom<DateTime>({
    key: 'UnmountDateAtom',
    default: DateTime.local()
});
