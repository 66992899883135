import React, { useEffect, useMemo, useState } from 'react';
import { MinesPermissionsContent } from './MinesPermissions.view';
import { GetCustomersForSave, MinesPermissionsProps, MoveCustomersType } from './MinesPermissions.type';
import {
    ChangeCustomerEnum,
    MinesPermissionsAtom,
    MinesPermissionsDispatcher,
    MinesPermissionsType
} from './MinesPermissions.reducer';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { SelectedUser } from './Permissions.atom';
import { CustomerCodebook } from '../../../models/Customer.type';
import { CRUD } from 'variables';
import ApiUser from '../../../api/User';
import { ShowPermissionModal } from '../../../states/global/Modal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Customer from '../../../api/Customer';
import { Success } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import { AccountModelResponse, UserModel } from '../../../models/User.type';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from '../../../states/global/User';
import { ROLES } from 'variables';

const userAPI = new ApiUser();
const customerAPI = new Customer();

const MinesPermissions: React.FC<MinesPermissionsProps> = (props): JSX.Element => {
    const [minesPermissionsState, setMinesPermissionsState] = useRecoilState(MinesPermissionsAtom);
    const minesPermissionsDispatch = MinesPermissionsDispatcher(minesPermissionsState, setMinesPermissionsState);
    const [customers, setCustomers] = useState<CustomerCodebook[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const permissionsAtom = useRecoilValue(SelectedUser);
    const closeMinesPermissionWidget = useResetRecoilState(ShowPermissionModal);
    const { t: translate } = useTranslation();
    const refreshTable = useResetRecoilState(RefreshTable(`${props.queryKey}-RefreshTableAtom`));
    const userInfo = useRecoilValue(UserInfo);
    const queryClient = useQueryClient();

    const userCustomerAccess: CustomerCodebook[] | undefined = useMemo(() => {
        return (
            userInfo.user?.userCustomerAccess.map((customerAccess) => {
                return {
                    id: customerAccess.customer.id ?? 0,
                    name: customerAccess.customer.name ?? undefined,
                    region: customerAccess.customer.region ?? undefined
                };
            }) ?? undefined
        );
    }, [userInfo]);

    const isSuperAdmin = checkRole(permissionsAtom, [ROLES.ROLE_SUPER_ADMIN]);

    const { data } = useQuery<CustomerCodebook[]>(['GetCustomers'], () => customerAPI.getAllAsCodebook(), {
        refetchOnWindowFocus: false,
        refetchInterval: false
    });

    const { data: UserData } = useQuery<AccountModelResponse>(
        ['GetUserCustomerAccess'],
        () => userAPI.getUserById(permissionsAtom?.id || 0),
        {
            refetchOnWindowFocus: false,
            enabled: !!permissionsAtom?.id,
            refetchInterval: false
        }
    );

    const mutationSave = useMutation<UserModel, unknown, number>(
        (userId) =>
            userAPI.patchById<UserModel, GetCustomersForSave>({
                id: userId,
                data: saveAssignedCustomer(),
                apiProject: undefined
            }),
        {
            onSuccess: () => {
                refreshTable();
                closeMinesPermissionWidget();
                Success({
                    text: `${translate('t.mines_permissions_updated')}`
                });
            }
        }
    );

    const getAssignedCustomers = (): void => {
        let alreadyAssignedCustomers: number[] = [];
        let minesPermissions: MinesPermissionsType = {
            assigned: [],
            notAssigned: []
        };

        if (isSuperAdmin) {
            minesPermissions.assigned = [...customers];
        } else {
            UserData?.user?.userCustomerAccess.forEach((access) => {
                alreadyAssignedCustomers.push(access.customer.id);
            });

            customers.forEach((customer) => {
                if (alreadyAssignedCustomers.includes(customer.id)) {
                    minesPermissions.assigned.push(customer);
                } else {
                    minesPermissions.notAssigned.push(customer);
                }
            });
        }

        minesPermissionsDispatch({
            type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION,
            payload: minesPermissions
        });
        setTimeout(() => {
            setLoading(false);
        }, 200);
    };

    const saveAssignedCustomer = (): GetCustomersForSave => {
        let newAssignedCustomerId: { customer?: number }[] = [];
        let oldAssignedCustomerId: { id?: number }[] = [];

        minesPermissionsState.minesPermission.assigned.forEach((customer) => {
            newAssignedCustomerId.push({ customer: customer.id });
        });

        for (const key in UserData?.user?.userCustomerAccess) {
            for (let i = 0; i < newAssignedCustomerId.length; i++) {
                if (UserData?.user?.userCustomerAccess[key].customer.id === newAssignedCustomerId[i].customer) {
                    newAssignedCustomerId.splice(i, 1);
                    oldAssignedCustomerId.push({ id: UserData?.user?.userCustomerAccess[key].id });
                }
            }
        }
        return {
            userCustomerAccess: isSuperAdmin ? [] : [...newAssignedCustomerId, ...oldAssignedCustomerId],
            customer: minesPermissionsState.minesPermission.assigned[0].id
        };
    };

    const moveCustomer = (removeFrom: CustomerCodebook[], addTo: CustomerCodebook[], id: number): MoveCustomersType => {
        for (let i = 0; i < removeFrom.length; i++) {
            if (removeFrom[i].id === id) {
                addTo.push(removeFrom.splice(i, 1)[0]);
                break;
            }
        }
        return { removeFrom, addTo };
    };

    const rowAction = (action: CRUD, id: number): void => {
        let customerArrays: MoveCustomersType;
        switch (action) {
            case CRUD.ADD:
                customerArrays = moveCustomer(
                    [...minesPermissionsState.minesPermission.notAssigned],
                    [...minesPermissionsState.minesPermission.assigned],
                    id
                );
                minesPermissionsDispatch({
                    type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION,
                    payload: {
                        assigned: customerArrays.addTo,
                        notAssigned: customerArrays.removeFrom
                    }
                });
                return;
            case CRUD.REMOVE:
                customerArrays = moveCustomer(
                    [...minesPermissionsState.minesPermission.assigned],
                    [...minesPermissionsState.minesPermission.notAssigned],
                    id
                );
                minesPermissionsDispatch({
                    type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION,
                    payload: {
                        assigned: customerArrays.removeFrom,
                        notAssigned: customerArrays.addTo
                    }
                });
                return;
        }
    };

    useEffect(() => {
        if (customers && !!customers.length) {
            UserData?.user?.userCustomerAccess && getAssignedCustomers();
        }
    }, [UserData, customers]);

    useEffect(() => {
        if (checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])) {
            data && setCustomers(data);
        } else {
            userCustomerAccess && setCustomers(userCustomerAccess);
        }
    }, [data]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries(['GetUserCustomerAccess']);
            queryClient.invalidateQueries(['GetUserCustomerAccess']);
            setLoading(true);
            minesPermissionsDispatch({
                type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION,
                payload: {
                    assigned: [],
                    notAssigned: []
                }
            });
        };
    }, []);

    return (
        <MinesPermissionsContent
            data-testid='MinesPermissions-testid'
            rowAction={rowAction}
            mutationSave={mutationSave}
            customers={customers}
            loading={loading}
            setShowPermissionModal={props.setShowPermissionModal}
            noRenderModal={props.noRenderModal}
        />
    );
};

export default MinesPermissions;
