import React, { useCallback } from 'react';
import { StatisticsContainer } from './Statistics.style';
import { StatisticsViewProps } from './Statistics.type';
import StatisticsMap from 'components/StatisticsMap';
import StatisticsSummary from 'components/StatisticsSummary';
import StatisticsTopBar from 'components/StatisticsTopBar';
import StatisticsGraph from 'components/StatisticsGraphs/StatisticsGraphs';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { Settings } from 'luxon';
import { useTranslation } from 'react-i18next';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StatisticsContent: React.FC<StatisticsViewProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();

    const getTopBar = useCallback(() => {
        if (userInfo.user?.userSetting.timezone.timezoneName) {
            Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
            return <StatisticsTopBar currentTab={props.currentTab} />;
        }
        return <></>;
    }, [userInfo.user?.userSetting.timezone.timezoneName, props.currentTab]);

    return (
        <StatisticsContainer data-testid='StatisticsContent'>
            {getTopBar()}
            {
                <UiWidgetTab
                    title={translate('t.alerts_by_vehicle')}
                    unit='px'
                    setNumberTab={props.setCurrentTab}
                    initialTab={props.currentTab}
                    tab={{
                        headers: [
                            {
                                label: translate('t.graphs'),
                                icon: <FontAwesomeIcon icon={['fas', 'chart-area']} size='1x' />
                            },
                            {
                                label: translate('t.map'),
                                icon: <FontAwesomeIcon icon={['fas', 'map-marker']} size='1x' />
                            },
                            {
                                label: translate('t.summary'),
                                icon: <FontAwesomeIcon icon={['fas', 'book']} size='1x' />
                            }
                        ],
                        constents: [
                            {
                                id: 0,
                                body: (
                                    <div style={{ padding: 10 }}>
                                        <StatisticsGraph />
                                    </div>
                                )
                            },
                            {
                                id: 1,
                                body: (
                                    <div style={{ padding: 10 }}>
                                        <StatisticsMap />
                                    </div>
                                )
                            },
                            {
                                id: 2,
                                body: (
                                    <div style={{ padding: 10 }}>
                                        <StatisticsSummary />
                                    </div>
                                )
                            }
                        ]
                    }}
                />
            }
        </StatisticsContainer>
    );
};
