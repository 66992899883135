import React from 'react';
import {
    AttachedFiles,
    AttachmentsSize,
    Content,
    DragAndDropContainer,
    DropFileImage,
    ErrorMessage
} from './DragAndDrop.style';
import { DragAndDropViewProps } from './DragAndDrop.type';
import { useTranslation } from 'react-i18next';
import { faFileArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { MAX_FILE_SIZE } from 'variables';
import { UploadedFiles } from './UploadedFiles';

export const DragAndDropContent: React.FC<DragAndDropViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <DragAndDropContainer data-testid='DragAndDropContent'>
            <input
                onChange={props.updateAttachment}
                type='file'
                multiple
                id='attachments'
                ref={props.fileInput}
                hidden
                style={{ display: 'none' }}
            />
            <Content
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={(e) => {
                    props.fileDrop(e);
                }}
                onClick={() => {
                    props.clickUpload();
                }}
            >
                <DropFileImage icon={faFileArrowDown} />
                <p>{translate('p.drag_and_drop_files')}</p>
            </Content>
            <AttachedFiles>
                {translate('t.attached_files')}:{' '}
                {props.uploadedFiles.map((file, index) => (
                    <UploadedFiles key={index} {...props} file={file} />
                ))}
            </AttachedFiles>
            <AttachmentsSize>
                {translate('t.attachments_size')}: {props.showFileSize(props.uploadedSize)}/
                {props.showFileSize(MAX_FILE_SIZE)}
            </AttachmentsSize>
            <ErrorMessage>{props.errorMessage}</ErrorMessage>
        </DragAndDropContainer>
    );
};
