import React, { useState } from 'react';
import { BESLabel, BESLabelText, BESPopover, LoadingPopup, PositionDiv } from './VehicleStatsRow.style';
import { Popover } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { BesType } from './VehicleStatsRow.type';
import { useQuery } from '@tanstack/react-query';
import ExternalSystemMddBesTyreLog from '../../../../../api/ExternalSystemMddBesTyreLog';
import { CircularProgress } from '@mui/material';
import { getBesLabel, getBesRank } from 'helpers';
import ReactGA from 'helpers/ga';
import { CustomerSettings } from 'states/global/CustomerSettings';

const externalSystemMddBesTyreLogAPI = new ExternalSystemMddBesTyreLog();

const gTrackOnClick = () => {
    ReactGA.event({
        category: 'BES',
        action: 'Click',
        label: 'Click - Highest BES - Vehicle Stats'
    });
};

export const BESRank: React.FC<BesType> = ({ maxBesSeverityRankTotal, vehicleId }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { t: translate } = useTranslation();
    const customerSettings = useRecoilValue(CustomerSettings);

    const { data, isFetching } = useQuery(
        ['GetBesPerTyre'],
        () => externalSystemMddBesTyreLogAPI.getTyreLogDetailByVehicle(vehicleId),
        {
            enabled: Boolean(anchorEl),
            cacheTime: 0,
            keepPreviousData: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false
        }
    );

    const handleTogglePopover = (event: React.MouseEvent<HTMLElement>): void => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }

        gTrackOnClick();
    };

    return (
        <>
            <BESLabel
                rank={getBesRank(customerSettings.mdd_bes_display_name.toUpperCase(), maxBesSeverityRankTotal)}
                aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onClick={handleTogglePopover}
            >
                <BESLabelText $popup>
                    {getBesLabel(
                        customerSettings.mdd_bes_display_name.toUpperCase(),
                        maxBesSeverityRankTotal,
                        translate
                    )}
                </BESLabelText>
            </BESLabel>
            <Popover
                id='mouse-over-popover'
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {!isFetching ? (
                    <BESPopover>
                        <table
                            style={{
                                borderSpacing: '3px',
                                borderCollapse: 'separate'
                            }}
                        >
                            <tbody>
                                {data?.externalSystemMddBesTyreLogDetail.map((tyre, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <PositionDiv>{`${translate('t.pos')} ${tyre.position}`} :</PositionDiv>
                                            </td>
                                            <td>
                                                <BESLabel
                                                    rank={getBesRank(
                                                        customerSettings.mdd_bes_display_name.toUpperCase(),
                                                        tyre.besSeverityRankTotal ?? 0
                                                    )}
                                                >
                                                    <BESLabelText>
                                                        {(tyre.besSeverityRankTotal ?? 0) > 0
                                                            ? getBesLabel(
                                                                  customerSettings.mdd_bes_display_name.toUpperCase(),
                                                                  tyre.besSeverityRankTotal,
                                                                  translate
                                                              )
                                                            : customerSettings.mdd_bes_display_name.toUpperCase() ===
                                                              'CSEP'
                                                            ? ''
                                                            : 'N/A'}
                                                    </BESLabelText>
                                                </BESLabel>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </BESPopover>
                ) : (
                    <LoadingPopup>
                        <CircularProgress />
                    </LoadingPopup>
                )}
            </Popover>
        </>
    );
};
