import React from 'react';
import { FaultySensorReportsProps } from './FaultySensorReports.type';
import { FaultySensorReportsContent } from './FaultySensorReports.view';
import { Wrapper } from 'helpers/wrapper';

const FaultySensorReports: React.FC<FaultySensorReportsProps> = (): JSX.Element => {
    return <FaultySensorReportsContent data-testid='FaultySensorReports-testid' />;
};

export default Wrapper(FaultySensorReports);
