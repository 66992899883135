import styled from 'styled-components';

export const NotificationTireTresholdsLegendContainer = styled.div``;

export const ColumnRight = styled.td`
    padding-left: 15px;
`;

export const ContainerLinkToKB = styled.div`
    width: 100%;
    margin-top: 15px;
    display: block;
    text-align: center;
`;
