import React from 'react';
import { SetAlertsViewContent } from './SetAlerts.style';
import { SetAlertsViewProps } from './SetAlerts.type';
import AlertsAffectedVehicles from 'components/Alert/AlertsAffectedVehicles';
import { Grid } from '@mui/material';
import { RULE_TYPE } from 'models/Rule.type';
import SetAlertsFilterWidget from 'components/Alert/SetAlertsFilterWidget';
import SetAlertsTempPressureWidget from 'components/Alert/SetAlertsTempPressureWidget/SetAlertsTempPressureWidget';

export const SetAlertsContent: React.FC<SetAlertsViewProps> = (): JSX.Element => {
    return (
        <SetAlertsViewContent data-testid='SetAlertsContent'>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <SetAlertsFilterWidget />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <AlertsAffectedVehicles ruleType={RULE_TYPE.WIZARD} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <SetAlertsTempPressureWidget />
                </Grid>
            </Grid>
        </SetAlertsViewContent>
    );
};
