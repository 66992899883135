import PortalApi from 'helpers/api/PortalApiClient';
import { PaginatorParams } from '../models/Paginator.type';
import { RegionModel, RegionModelExtended, RegionsWithPaginator } from '../models/Region.type';

export default class Region extends PortalApi<RegionModel> {
    route = 'region';

    getAll = async (page: number, limit: number): Promise<RegionsWithPaginator> => {
        let params: PaginatorParams = { page, limit };

        return await this.getByCriteria({ apiProject: undefined, criteria: params });
    };

    getAllAsCodebook = async (): Promise<RegionModelExtended[]> => {
        return await this.getCodebook({ apiProject: undefined });
    };
}
