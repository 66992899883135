import React, { useState, useEffect, useCallback } from 'react';
import * as view from './UiColorPicker.view';
import * as type from './UiColorPicker.type';
import { Wrapper } from 'helpers/wrapper';

const UiColorPicker: React.FC<type.UiColorPickerProps> = ({
    defaultColor,
    getColor,
    labelInput,
    error,
    testid
}): JSX.Element => {
    const [color, setColor] = useState(defaultColor ?? 'rgba(255, 255, 255, 1)');

    const getColorPicker = useCallback(async () => {
        getColor(color);
    }, [color]);

    const validateColor = (color: string) => {
        const regex =
            /^(rgb|hsl)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/gm;
        if (color) {
            let isValidate: boolean = regex.test(color);
            return isValidate ? color : 'rgba(255, 255, 255, 1)';
        }
        return 'rgba(255, 255, 255, 1)';
    };

    useEffect(() => {
        getColorPicker();
    }, [getColorPicker]);

    return (
        <view.UiColorPickerContent
            testid={testid || 'UiColorPicker'}
            color={color}
            setColor={setColor}
            defaultColor={defaultColor}
            labelInput={labelInput}
            error={error}
            validateColor={validateColor}
        />
    );
};

export default Wrapper(UiColorPicker);
