import React from 'react';
import { SpeedIncidentPopUpContainer } from './SpeedIncidentPopUp.style';
import { CellsType, SpeedIncidentPopUpViewProps } from './SpeedIncidentPopUp.type';
import { Link, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { PopupHeader, PoupContent, TopLabel } from '../MapPopUp/MapPopUp.style';
import { CustomPopupTable } from '../../VehicleMapPopup/VehicleMapPopup.style';
import { useTranslation } from 'react-i18next';

import { useRecoilValue } from 'recoil';
import UiLoadingPage from '../../Ui/Components/UiLoadingPage/UiLoadingPage';
import useConverter from '../../CustomHooks/Converter/Converter';
import { snakeToCamel } from 'helpers/converter/text';
import { DateTime } from 'luxon';
import { MapActionData } from 'states/global/Map';

import { SpeedIncidentMap } from 'models/SpeedEvent.type';

const createRow = (Cells: CellsType): JSX.Element => {
    return (
        <>
            {Cells.map((cell, key) => {
                return (
                    <TableRow key={key} data-testid={`createRow-TableRow-${key}-${snakeToCamel(cell.label)}`}>
                        <TableCell padding='checkbox'>{cell.label}: </TableCell>
                        <TableCell align='right' padding='checkbox'>
                            {cell.value}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const SpeedIncidentPopUpContent: React.FC<SpeedIncidentPopUpViewProps> = ({
    speedIncidentData,
    speedIncidentFetched
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();
    const { fromServerToUserUnit, convertType } = useConverter();
    const mapActionData = useRecoilValue(MapActionData);

    if (speedIncidentFetched || !speedIncidentData) {
        return <UiLoadingPage />;
    }

    const realSpeed = (speedIncidentData: SpeedIncidentMap): { label: string; value: string } => {
        const { speedEvents } = speedIncidentData;
        let title: string = translate('t.max_speed');
        let value: number | null | undefined = speedEvents?.realMaxSpeed;

        if (speedEvents?.realMinSpeed && speedEvents?.trackLog?.speed < speedEvents?.realMinSpeed) {
            title = translate('t.min_speed');
            value = speedEvents?.realMinSpeed;
        }

        return {
            label: title,
            value: fromServerToUserUnit({
                type: convertType.speed,
                value: value,
                displayUnits: true
            })
        };
    };

    return (
        <SpeedIncidentPopUpContainer data-testid='SpeedIncidentPopUpContent'>
            <PopupHeader>
                <TopLabel>
                    <Link
                        data-testid={`SpeedIncidentPopUpContent-Link-vehicleName`}
                        href={`${location.pathname.includes('v2') ? 'v2' : ''}/vehicle-status?vehicle=${
                            speedIncidentData?.speedEvents?.vehicle?.id
                        }`}
                        variant='body2'
                        style={{ color: '#0071c7' }}
                    >
                        {speedIncidentData?.speedEvents?.vehicle.name}
                    </Link>
                </TopLabel>
            </PopupHeader>

            <PoupContent>
                <TableContainer>
                    <CustomPopupTable size='small'>
                        <TableBody>
                            {createRow(
                                [
                                    {
                                        label: translate('t.latitude'),
                                        value: speedIncidentData?.speedEvents?.positionAt?.latitude
                                    },
                                    {
                                        label: translate('t.longitude'),
                                        value: speedIncidentData?.speedEvents?.positionAt?.longitude
                                    },
                                    {
                                        label: translate('t.speed'),
                                        value: fromServerToUserUnit({
                                            type: convertType.speed,
                                            value: speedIncidentData?.speedEvents?.trackLog?.speed,
                                            displayUnits: true
                                        })
                                    },
                                    {
                                        label: translate('t.altitude'),
                                        value: fromServerToUserUnit({
                                            type: convertType.altitude,
                                            value: speedIncidentData?.speedEvents?.trackLog?.altitude,
                                            displayUnits: true
                                        })
                                    },
                                    realSpeed(speedIncidentData),
                                    {
                                        label: translate('t.created_at'),
                                        value: speedIncidentData?.speedEvents?.measuredAt
                                            ? fromUTCToTimezone(
                                                  DateTime.fromISO(speedIncidentData?.speedEvents?.measuredAt),
                                                  false
                                              )
                                            : ''
                                    }
                                ].concat(
                                    mapActionData.enableLoaded
                                        ? [
                                              {
                                                  label: translate('t.loaded_state'),
                                                  value: translate(
                                                      `t.${
                                                          speedIncidentData?.speedEvents?.loaded ? 'loaded' : 'unloaded'
                                                      }`
                                                  )
                                              }
                                          ].concat(
                                              speedIncidentData?.speedEvents?.loaded
                                                  ? [
                                                        {
                                                            label: translate('t.operator'),
                                                            value: speedIncidentData?.speedEvents?.vehicleOperator
                                                                ? `${speedIncidentData?.speedEvents?.vehicleOperator?.firstName} ${speedIncidentData?.speedEvents?.vehicleOperator?.lastName}`
                                                                : translate('t.unknown')
                                                        }
                                                    ]
                                                  : []
                                          )
                                        : []
                                )
                            )}
                        </TableBody>
                    </CustomPopupTable>
                </TableContainer>
            </PoupContent>
        </SpeedIncidentPopUpContainer>
    );
};
