import React, { useEffect, useState } from 'react';
import { VehicleStatusWidgetProps } from './VehicleStatusWidget.type';
import { VehicleStatusWidgetContent } from './VehicleStatusWidget.view';
import { GetVehicleStatusQuery } from '../../Queries/VehicleStatus/VehicleStatus';
import { ViewMode } from 'variables';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VehicleFiltersAtom } from './Components/VehicleFilters/VehicleFilters.atom';
import { AlertsOnVehicle, VehicleStatus2 } from '../../../models/Vehicle.type';
import { UserInfo } from '../../../states/global/User';
import { useMediaQuery, useTheme } from '@mui/material';
import useUserLocalStorageSettings from '../../CustomHooks/UserLocalStorageSettings';
import { AlertTypes } from 'variables/index';

const VehicleStatusWidget: React.FC<VehicleStatusWidgetProps> = (props): JSX.Element => {
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.GRID);
    const [vehicleFiltersAtom, setVehicleFiltersAtom] = useRecoilState(VehicleFiltersAtom);
    const userInfo = useRecoilValue(UserInfo);
    const theme = useTheme();
    const isLgDevice = useMediaQuery(theme.breakpoints.up('lg'));

    const { setUserSettings, userLSSettings: tableSettings } = useUserLocalStorageSettings([
        `vehicleStatusShowFilters`
    ]);

    useEffect(() => {
        setVehicleFiltersAtom({
            ...vehicleFiltersAtom,
            sortBy: localStorage.getItem('vehicleStatusSort') || 'name:asc'
        });
    }, []);

    useEffect(() => {
        if (tableSettings) {
            setVehicleFiltersAtom({
                ...vehicleFiltersAtom,
                showFilters: tableSettings && tableSettings['vehicleStatusShowFilters'] == 'true'
            });
        }
    }, [tableSettings]);

    const handleChangeView = (): void => {
        const newViewMode: ViewMode = viewMode === ViewMode.GRID ? ViewMode.LIST : ViewMode.GRID;
        localStorage.setItem('vehicleStatusViewMode', newViewMode);
        setViewMode(newViewMode);
    };

    const handleFilterByVehicle = (vehicleId: number): void => {
        props.setVehicleId(vehicleId);
    };

    const handleFilterToggle = (): void => {
        setVehicleFiltersAtom({
            ...vehicleFiltersAtom,
            showFilters: !vehicleFiltersAtom.showFilters
        });
        setUserSettings('vehicleStatusShowFilters', `${!vehicleFiltersAtom.showFilters}`);
    };

    const selectVehicleByParameter = (): void => {
        const url: string = location.href.split('=')[1];
        if (url) {
            const requestedVehicle: number = parseInt(url, 10);
            props.setVehicleId(requestedVehicle);
        }
    };

    const getHighestAlert = (data: AlertsOnVehicle): number => {
        let highestAlert = 0;
        for (const dataKey in data) {
            let alert = 0;

            if (data[dataKey].levelType === AlertTypes.TemperaturePrediction) {
                alert = data[dataKey].levelAlert > 1 ? 3 : 2;
            } else if (
                data[dataKey].levelType === AlertTypes.LowPressure ||
                data[dataKey].levelType === AlertTypes.HighPressure ||
                data[dataKey].levelType === AlertTypes.Temperature
            ) {
                alert = data[dataKey].levelAlert;
            }

            if (alert > highestAlert) {
                highestAlert = alert;
            }
        }
        return highestAlert;
    };

    const parseData = (data?: VehicleStatus2): VehicleStatus2 | undefined => {
        if (!userInfo.user?.userSetting.showOutOfService && data) {
            return { vehicleStatus: data?.vehicleStatus.filter((vehicle) => !vehicle.vehicle.outOfService) };
        }

        return data;
    };

    useEffect(() => {
        selectVehicleByParameter();
        setViewMode((localStorage.getItem('vehicleStatusViewMode') as ViewMode) || ViewMode.GRID);

        return () => {
            setViewMode(ViewMode.GRID);
            props.setVehicleId(0);
        };
    }, []);

    return (
        <GetVehicleStatusQuery>
            {({ data, isLoading }) => (
                <VehicleStatusWidgetContent
                    data-testid={'VehicleStatusWidget-testid'}
                    viewMode={viewMode}
                    isLoading={isLoading}
                    isLgDevice={isLgDevice}
                    data={parseData(data)}
                    handleChangeView={handleChangeView}
                    handleFilterByVehicle={handleFilterByVehicle}
                    handleFilterToggle={handleFilterToggle}
                    getHighestAlert={getHighestAlert}
                    {...props}
                />
            )}
        </GetVehicleStatusQuery>
    );
};

export default VehicleStatusWidget;
