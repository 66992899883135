export type HeatmapPlaygroundProps = {};

export type HeatmapPlaygroundViewProps = {
    setLocalPlayground: React.Dispatch<PlaygroundAction>;
    playground: PlaygroundObject;
};

export enum PlaygroundActionEnums {
    SET_DOT_SIZE = 'SET_DOT_SIZE',
    SET_DATA_LIMIT = 'SET_DATA_LIMIT',
    SET_DOT_OPACITY = 'SET_DOT_OPACITY',
    SET_BLUR_INNER_OPACITY = 'SET_BLUR_INNER_OPACITY',
    SET_BLUR_INNER_RADIUS = 'SET_BLUR_INNER_RADIUS',
    SET_BLUR_OUTER_RADIUS = 'SET_BLUR_OUTER_RADIUS',
    SET_ALLOW_BLUR = 'SET_ALLOW_BLUR',
    SET_ALLOW_DOT_SIZE = 'SET_ALLOW_DOT_SIZE'
}

type AllowBlurAction = {
    type: PlaygroundActionEnums.SET_ALLOW_BLUR | PlaygroundActionEnums.SET_ALLOW_DOT_SIZE;
    zoom: number;
    payload: boolean;
};

type SetPlayogroundAction = {
    type: Omit<keyof typeof PlaygroundActionEnums, 'SET_ALLOW_BLUR'>;
    zoom: number;
    payload: number;
};

export type PlaygroundAction = AllowBlurAction | SetPlayogroundAction;

export type PlaygroundObject = {
    [id: string]: {
        dotSize: number;
        data: number;
        innerRadius: number;
        outerRadius: number;
        innerOpacity: number;
        outerOpacity: number;
        disableBlur: boolean;
        disableDotSize: boolean;
    };
};
