import React from 'react';
import CustomerReportWidgetView from './CustomerReportWidget.view';
import CustomerReportAPI from 'api/CustomerReport';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'states/global/User';
import { CustomerReportModel } from 'models/CustomerReport.type';

const customerReportAPI = new CustomerReportAPI();

const CustomerReportWidget = () => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    const mutation = useMutation(
        ['UpdateDeviceReport'],
        (dataToSave: CustomerReportModel) =>
            customerReportAPI.patchById({
                id: userInfo.user?.customer.id || 0,
                data: { minimumActiveDeviceTrigger: dataToSave.minimumActiveDeviceTrigger },
                version: 'v2'
            }),
        {
            onSuccess: () => {
                Success({
                    text: translate('p.changes_have_been_saved_successfully')
                });
            }
        }
    );

    return <CustomerReportWidgetView mutation={mutation} />;
};

export default CustomerReportWidget;
