import { WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';
import { useTheme } from '@mui/material';

export const SensorTableAroundContainer = styled.div`
    width: 100%;

    & .MuiTableCell-root {
        padding: 0px !important;
    }
    & .MuiTableCell-body {
        color: rgba(0, 0, 0, 1) !important;
    }
    & .MuiTableCell-sizeSmall {
        padding: 0px !important;
    }
    & .MuiTableHead-root {
        & .MuiTableCell-head {
            padding: 6px 24px 6px 16px !important;
        }
    }
    & .MuiAlert-standardInfo {
        margin-top: 2%;
    }
    & .MuiIconButton-root {
        padding: 0px !important;
    }

    td {
        height: 50px;
    }
`;

export const CustomCell = styled.div<{ $attached?: boolean }>`
    padding: 6px 24px 6px 16px;
    height: 100%;
    ${({ $attached }) =>
        applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `background-color: ${$attached ? '#fffdc4' : WHITE} !important;`,
            dark: `background-color: ${$attached ? '#575400' : '#0a1016'} !important; color: ${WHITE} !important;`
        })}
`;
