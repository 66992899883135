import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RolesCodebookQueryType } from './Roles.type';
import RoleClient from '../../../api/Role';
import { RoleCodeBook } from '../../../models/Role.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const roleClient = new RoleClient();

export const GetRolesCodebookQuery: React.FC<RolesCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RoleCodeBook[], Error, RoleCodeBook[]>(
        ['GetRolesCodebook', userInfo.user?.customer.id],
        () => roleClient.getCodeBook(),
        { refetchOnWindowFocus: false }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetRolesAvailableCodebookQuery: React.FC<RolesCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RoleCodeBook[], Error, RoleCodeBook[]>(
        ['GetRolesAvailableCodebook', userInfo.user?.customer.id],
        () => roleClient.getAvailable(),
        { refetchOnWindowFocus: false }
    );
    return <>{children(useQueryProperties)}</>;
};
