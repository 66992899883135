import styled from 'styled-components';

export const VehicleDetailsMapViewContainer = styled.div`
    width: 100%;
    && .MuiCard-root {
        box-shadow: none !important;
        border-radius: none !important;
    }

    && .MuiCardHeader-root {
        border-bottom: none !important;
    }

    && .MuiCardContent-root {
        padding: 0;
    }
`;
