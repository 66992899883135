import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)<{ skin?: string }>``;

export const StyledFormControlLabel = styled(FormControlLabel)<{
    skin?: string;
    skinlabel?: boolean | number;
}>`
    &.MuiFormControlLabel-root {
        span {
            font-size: 0.93rem !important ;
        }
    }
`;
