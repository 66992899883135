import React from 'react';
import {
    CardDetail,
    CustomLabel,
    CustomTextfield,
    DateRangeContainer,
    LoadingProgress,
    LoadingProgressConatiner,
    ReportDetailContainer
} from './ReportDetail.style';
import { DateRangeType, ReportDetailViewProps } from './ReportDetail.type';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useConverter from '../../CustomHooks/Converter/Converter';
import { useTranslation } from 'react-i18next';
import { Avatar, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DARK_STEEL_GREY, COLOR_GREY_2, WHITE } from '../../Ui/colors';

const createRow = (Cells: { label: string; value: string | number }[]): JSX.Element => {
    return (
        <>
            {Cells.map((cell, key) => {
                return (
                    <TableRow key={key}>
                        <TableCell padding='checkbox'>
                            <Typography gutterBottom>{`${cell.label}: `}</Typography>
                        </TableCell>
                        <TableCell align='right' padding='checkbox'>
                            <Typography variant='subtitle1' gutterBottom>
                                {cell.value}
                            </Typography>
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const DateRange: React.FC<DateRangeType> = ({ dateFrom, dateTo, title }): JSX.Element => {
    return (
        <DateRangeContainer>
            <Grid container justifyContent='center'>
                <Grid item md={2} xs={2} textAlign='left'>
                    <Avatar
                        sx={{
                            width: 47,
                            height: 47,
                            bgcolor: DARK_STEEL_GREY,
                            border: `4px solid ${WHITE}`,
                            ml: { xl: 2, lg: 2, md: 2 }
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'calendar-range']} fontSize={18} color={WHITE} />
                    </Avatar>
                </Grid>
                <Grid item md={10} textAlign='center'>
                    <Typography color='primary' variant='subtitle1' gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant='subtitle1' component='span' gutterBottom marginRight={1}>
                        {dateFrom}
                    </Typography>
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} fontSize={13} color={COLOR_GREY_2} />
                    <Typography variant='subtitle1' component='span' marginLeft={1} gutterBottom>
                        {dateTo}
                    </Typography>
                </Grid>
            </Grid>
        </DateRangeContainer>
    );
};

export const ReportDetailContent: React.FC<ReportDetailViewProps> = (props): JSX.Element => {
    const { fromUTCToTimezone } = useConverter();
    const { t: translate } = useTranslation();

    return props.isLoading || props.isLoadingVerifiedStatus || !props.reportDetail ? (
        <LoadingProgressConatiner>
            <LoadingProgress />
        </LoadingProgressConatiner>
    ) : (
        <ReportDetailContainer data-testid='ReportDetailContent'>
            <Typography color='primary' variant='subtitle1' textAlign='center' marginTop={1} gutterBottom>
                {translate('t.faulty_report_detail')}
            </Typography>
            <Grid container marginTop={1}>
                <Grid md={12} lg={12} paddingLeft={1}>
                    <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                            <DateRange
                                title={translate('t.period')}
                                dateFrom={fromUTCToTimezone(props.reportDetail?.dateFrom)}
                                dateTo={fromUTCToTimezone(props.reportDetail?.dateTo)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={12}>
                            <FormControl variant='outlined' size='small' style={{ width: '100%' }}>
                                <CustomLabel htmlFor='verified-status-native-simple'>
                                    {translate('t.report_validity')}
                                </CustomLabel>
                                <Select
                                    disabled={!!props.reportDetail.verifiedAt}
                                    variant='outlined'
                                    label={translate('t.report_validity')}
                                    value={props.verifiedStatus || props.reportDetail?.verifiedStatus?.id}
                                    onChange={(e /*: React.ChangeEvent<{ value: unknown }> */) => {
                                        props.updateVerifiedStatus(Number(e.target.value));
                                    }}
                                    inputProps={{
                                        name: 'verified-status',
                                        id: 'verified-status-native-simple',
                                        shrink: true
                                    }}
                                    sx={{ textAlign: 'left' }}
                                >
                                    {props.verifiedStatusData?.map((verifiedStatus, index) => (
                                        <MenuItem value={verifiedStatus.id} key={index}>
                                            {translate(`t.${verifiedStatus.name.toLowerCase()}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} lg={12}>
                            <CustomTextfield
                                id='faulty-sensor-comment'
                                label={translate('t.comment')}
                                name='comment'
                                disabled={!!props.reportDetail.verifiedAt}
                                multiline
                                rows={3}
                                value={props.reportComment || props.reportDetail.comment}
                                onChange={(e) => props.updateReportComment(e.target.value)}
                                variant='outlined'
                                size='small'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{ maxLength: 500 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={12} lg={12} xs={12}>
                    <CardDetail elevation={0}>
                        <Table>
                            <TableBody>{createRow(props.cells)}</TableBody>
                        </Table>
                    </CardDetail>
                </Grid>
            </Grid>
        </ReportDetailContainer>
    );
};
