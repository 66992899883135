import React from 'react';
import { TemperatureHeatmapContent } from './TemperatureHeatmap.view';
import { TemperatureHeatmapProps } from './TemperatureHeatmap.type';
import { Wrapper } from 'helpers/wrapper';

const TemperatureHeatmap: React.FC<TemperatureHeatmapProps> = (): JSX.Element => {
    return <TemperatureHeatmapContent data-testid='TemperatureHeatmap-testid' />;
};

export default Wrapper(TemperatureHeatmap);
