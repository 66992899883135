import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ActionItem, Header, Icon, VehicleDetailsActionsViewContent } from './VehicleDetailsActions.style';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuList, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    faCrosshairs,
    faPlus,
    faSensor,
    faChartMixedUpCircleCurrency,
    faTireRugged,
    faClockRotateLeft
} from '@fortawesome/pro-solid-svg-icons';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { SelectedSensorAtom } from 'components/Tyre/TyreDetails/SelectedSensor.atom';
import useModal from 'components/CustomHooks/ModalHook';
import TyreManager from 'components/Tyre/TyreManager';
import SensorManagement from 'components/Sensor/SensorManagement';
import SensorReadings from './SensorReadings';
import AlertMenu from './AlertMenu';
import WheelPosition from 'components/Tyre/WheelPosition';
import PredictedGraph from 'components/Mixs/PredictedGraph/PredictedGraph';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleModel } from 'models/Vehicle.type';
import { CustomerSettings } from 'states/global/CustomerSettings';
import VehicleDetailsWheelHistory from '../VehicleDetailsWheelHistory/VehicleDetailsWheelHistory';
import { GRAY } from 'components/Ui/colors';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';

export const VehicleDetailsActions = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [actionMenuState, setActionMenuState] = useRecoilState(VehicleDetailsActionMenuAtom);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const selectedSensor = useRecoilValue(SelectedSensorAtom(vehicleDetailsActionMenuAtom.data?.wheel.position || 0));
    const hasSensor = useMemo(() => !!selectedSensor?.sensor.id, [selectedSensor]);
    const { setModalState, updateModalState } = useModal();
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const vehicleData = useRecoilValue(VehicleDetailsWidgetAtom());
    const customerSettings = useRecoilValue(CustomerSettings);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom); //temp fix popovers table
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom); //temp fix popovers table
    const predictedTempAlertId = useMemo(() => {
        let id = 0;
        let alertLevel = 0;

        if (selectedSensor?.sensorAlerts) {
            for (let i = 0; i < selectedSensor.sensorAlerts.length; i++) {
                const notificationEventLevel =
                    selectedSensor.sensorAlerts[i].wheelPrediction?.notificationEventLevel || 0;

                if (selectedSensor.sensorAlerts[i].wheelPrediction && id === 0) {
                    if (id === 0) id = selectedSensor.sensorAlerts[i].wheelPrediction?.id || 0;

                    if (notificationEventLevel > alertLevel) alertLevel = notificationEventLevel;
                }
            }
        }
        return { id: id, level: alertLevel };
    }, [selectedSensor]);

    const wheelHistoryContent = () => {
        //temp fix popovers table
        const newModal = {
            content: <VehicleDetailsWheelHistory />,
            leftTitle: translate('t.wheel_history'),
            rightTitle: `
                ${translate('t.tyre')}
                ${translate('t.pos')} 
                ${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}
            `,
            cancelColor: GRAY,
            width: 920,
            height: 100,
            heightUnit: '%',
            buttons: <></>
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    useEffect(() => {
        setModalState({
            rightTitle: `
                ${translate('t.tyre')}
                ${translate('t.pos')} 
                ${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}
            `
        });
    }, []);

    return (
        <VehicleDetailsActionsViewContent data-testid='VehicleDetailsActionsContent'>
            <Paper>
                <Header>
                    {translate('t.pos')} {vehicleDetailsActionMenuAtom.data?.wheel.customPosition}
                </Header>
                <MenuList>
                    {!hasSensor && (
                        <MenuItem
                            onClick={() => {
                                setActionMenuState({
                                    ...actionMenuState,
                                    isOpen: false
                                });
                                updateModalState({
                                    id: 'sensor-management-add',
                                    isOpen: true,
                                    content: <SensorManagement />,
                                    leftTitle: translate('t.sensor_management'),
                                    width: 850,
                                    height: 300
                                });
                            }}
                            data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-SensorManagementAdd-Button`}
                        >
                            <ActionItem>
                                <Icon icon={faPlus} />
                                <Typography>{translate('t.add_sensor')}</Typography>
                            </ActionItem>
                        </MenuItem>
                    )}
                    {hasSensor && (
                        <div>
                            <MenuItem
                                onClick={() => {
                                    setActionMenuState({
                                        ...actionMenuState,
                                        isOpen: false
                                    });
                                    updateModalState({
                                        id: 'sensor-management',
                                        isOpen: true,
                                        content: <SensorManagement />,
                                        leftTitle: translate('t.sensor_management'),
                                        width: 850,
                                        height: 300
                                    });
                                }}
                                data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-SensorManagement-Button`}
                            >
                                <ActionItem>
                                    <Icon icon={faSensor} />
                                    <Typography>{translate('t.sensor_management')}</Typography>
                                </ActionItem>
                            </MenuItem>
                            <SensorReadings />
                        </div>
                    )}
                    <MenuItem
                        onClick={() => {
                            setActionMenuState({
                                ...actionMenuState,
                                isOpen: false
                            });
                            updateModalState({
                                id: 'tyre-manager',
                                isOpen: true,
                                content: <TyreManager />,
                                leftTitle: translate('t.tyre_manager'),
                                width: 720,
                                height: 300
                            });
                        }}
                        data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-TyreManager-Button`}
                    >
                        <ActionItem>
                            <Icon icon={faTireRugged} />
                            <Typography>{translate('t.tyre_manager')}</Typography>
                        </ActionItem>
                    </MenuItem>
                    {customerSettings.temperature_prediction && (
                        <MenuItem
                            disabled={!predictedTempAlertId.id}
                            onClick={() => {
                                setActionMenuState({
                                    ...actionMenuState,
                                    isOpen: false
                                });
                                updateModalState({
                                    isOpen: true,
                                    leftTitle: `Remaining Times - ${
                                        (vehicleData?.vehicleDetails as VehicleModel)?.name
                                    }`,
                                    height: 72,
                                    width: 90,
                                    widthUnit: '%',
                                    heightUnit: '%',
                                    content: (
                                        <PredictedGraph
                                            vehicleName={(vehicleData?.vehicleDetails as VehicleModel)?.name}
                                            wheelPredictionId={predictedTempAlertId.id}
                                            alertLevel={predictedTempAlertId.level}
                                            vehicleId={(vehicleData?.vehicleDetails as VehicleModel)?.id}
                                        />
                                    )
                                });
                            }}
                        >
                            <ActionItem>
                                <Icon icon={faChartMixedUpCircleCurrency} />
                                {/*<Typography>{translate('t.predicted_temperature_graph')}</Typography>*/}
                                <Typography>Predicted Temperature Graph</Typography>
                            </ActionItem>
                        </MenuItem>
                    )}
                    {hasSensor && <AlertMenu />}
                    {customerSettings.custom_wheel_position && (
                        <MenuItem
                            onClick={() => {
                                setActionMenuState({
                                    ...actionMenuState,
                                    isOpen: false
                                });
                                updateModalState({
                                    id: 'wheel-position',
                                    isOpen: true,
                                    content: <WheelPosition />,
                                    leftTitle: translate('t.wheel_position'),
                                    height: showXsSm ? 290 : 250,
                                    width: 400
                                });
                            }}
                            data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-WheelPosition-Button`}
                        >
                            <ActionItem>
                                <Icon icon={faCrosshairs} />
                                <Typography>{translate('t.wheel_position')}</Typography>
                            </ActionItem>
                        </MenuItem>
                    )}

                    {hasSensor && (
                        <MenuItem
                            onClick={() => {
                                setActionMenuState({
                                    ...actionMenuState,
                                    isOpen: false
                                });
                                /* updateModalState({
                                id: 'wheel-history',
                                isOpen: true,
                                content: <VehicleDetailsWheelHistory />,
                                leftTitle: translate('t.wheel_history'),
                                height: showXsSm ? 820 : 770,
                                width: 900,
                                widthUnit: 'px',
                                heightUnit: 'px'
                            }); */

                                wheelHistoryContent(); //temp fix popovers table
                            }}
                            data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-WheelHistory-Button`}
                        >
                            <ActionItem>
                                <Icon icon={faClockRotateLeft} />
                                <Typography>{translate('t.wheel_history')}</Typography>
                            </ActionItem>
                        </MenuItem>
                    )}
                </MenuList>
            </Paper>
        </VehicleDetailsActionsViewContent>
    );
};

export default VehicleDetailsActions;
