import React, { useReducer } from 'react';
import * as view from './AlertsListTabManager.view';
import * as type from './AlertsListTabManager.type';
import { Filterlevel } from '../AlertsList/AlertsList.type';
import { AlertsListReducer, inicialStateAlertList } from './AlertsListTabManager.reducer';
import { Wrapper } from 'helpers/wrapper';

const AlertsListTabManager: React.FC<type.AlertsByVehicleProps> = (props): JSX.Element => {
    const [state, dispatch] = useReducer(AlertsListReducer, inicialStateAlertList);
    const customConfigReactQuery = {
        TpmsQuery: {
            refetchInterval: 30000,
            enabled: state.enabledQuery
        },
        VehicleAlertsQuery: {
            refetchInterval: 30000,
            enabled: state.enabledQuery
        },
        GpsQuery: {
            refetchInterval: 30000,
            enabled: state.enabledQuery
        },
        SensorQuery: {
            refetchInterval: 30000,
            enabled: state.enabledQuery
        }
    };

    const setRefresh = () => {
        dispatch({ type: type.AlertListActions.SET_SCROLL_REFRESH });
    };

    const setEnabledQuery = (enabled: boolean) => {
        dispatch({
            type: type.AlertListActions.SET_ENABLED_QUERY,
            payload: enabled
        });
    };

    const setTab = (tab: type.TabNumber) => {
        dispatch({
            type: type.AlertListActions.SET_NUMBER_TAB,
            payload: tab
        });

        dispatch({
            type: type.AlertListActions.SET_ENABLED_QUERY,
            payload: true
        });

        dispatch({
            type: type.AlertListActions.SET_ALERT_FILTER,
            payload: {
                type: null,
                level: null
            }
        });
    };

    const AddFilterAlertByLevel = (level: Filterlevel) => {
        let alertFilters = state.alertFilters.level;

        if (alertFilters) {
            if (!alertFilters.filter((Tlevel) => Tlevel === level).length) {
                alertFilters.push(level);
            }
        } else {
            alertFilters = [level];
        }

        dispatch({
            type: type.AlertListActions.SET_ALERT_FILTER,
            payload: {
                ...state.alertFilters,
                level: alertFilters
            }
        });

        if (!state.enabledQuery)
            dispatch({
                type: type.AlertListActions.SET_ENABLED_QUERY,
                payload: true
            });
    };

    const RemoveFilterAlertByLevel = (level: Filterlevel) => {
        let alertFilters = state.alertFilters.level;

        if (alertFilters) {
            alertFilters = alertFilters.filter((Tlevel) => Tlevel !== level);
            alertFilters = !alertFilters.length ? null : alertFilters;
        }

        dispatch({
            type: type.AlertListActions.SET_ALERT_FILTER,
            payload: {
                ...state.alertFilters,
                level: alertFilters
            }
        });

        if (!state.enabledQuery)
            dispatch({
                type: type.AlertListActions.SET_ENABLED_QUERY,
                payload: true
            });
    };

    const setFilterByType = (typeFilter: type.typeFilter) => {
        let alertFilters = state.alertFilters.type;

        if (alertFilters) {
            if (!alertFilters.filter((type) => type === typeFilter).length) {
                alertFilters.push(typeFilter);
            }
        } else {
            alertFilters = [typeFilter];
        }

        dispatch({
            type: type.AlertListActions.SET_ALERT_FILTER,
            payload: {
                ...state.alertFilters,
                type: alertFilters
            }
        });

        if (!state.enabledQuery)
            dispatch({
                type: type.AlertListActions.SET_ENABLED_QUERY,
                payload: true
            });
    };

    const RemoveFilterByType = (typeFilter: type.typeFilter) => {
        let alertFilters = state.alertFilters.type;

        if (alertFilters) {
            alertFilters = alertFilters.filter((type) => type !== typeFilter);
            alertFilters = !alertFilters.length ? null : alertFilters;
        }

        dispatch({
            type: type.AlertListActions.SET_ALERT_FILTER,
            payload: {
                ...state.alertFilters,
                type: alertFilters
            }
        });

        if (!state.enabledQuery)
            dispatch({
                type: type.AlertListActions.SET_ENABLED_QUERY,
                payload: true
            });
    };

    const setHasMore = (hasMore: boolean) => {
        dispatch({
            type: type.AlertListActions.SET_HAS_MORE,
            payload: hasMore
        });
    };

    const setAlertIdToSolve = (alertIdToSolve: number) => {
        dispatch({
            type: type.AlertListActions.SET_ALERT_ID,
            payload: alertIdToSolve
        });
    };

    const loadData = () => {
        if (state.hasMore) {
            dispatch({
                type: type.AlertListActions.SET_CURRENT_PAGE,
                payload: state.currentPage ? state.currentPage + 1 : 1
            });
        }
    };

    const actionScroll = (e: React.UIEvent) => {
        if (e?.currentTarget?.scrollTop > 100) {
            if (!state.displayRefresh) {
                setRefresh();
            }
        }

        if (e?.currentTarget?.scrollTop === 0) {
            setRefresh();
        }
    };

    return (
        <view.AlertsListTabManagerContent
            data-testid='AlertsByVehicle-testid'
            setNumberTab={setTab}
            tabNumber={state.tabNumber}
            currentPage={state.currentPage ? state.currentPage : 1}
            hasMore={state.hasMore ? state.hasMore : true}
            AddFilterAlertByLevel={AddFilterAlertByLevel}
            RemoveFilterAlertByLevel={RemoveFilterAlertByLevel}
            RemoveFilterByType={RemoveFilterByType}
            setFilterByType={setFilterByType}
            alertFilters={state.alertFilters}
            alertIdToSolve={state.alertIdToSolve}
            setAlertIdToSolve={setAlertIdToSolve}
            customConfigReactQuery={customConfigReactQuery}
            loadData={loadData}
            setHasMore={setHasMore}
            setEnabledQuery={setEnabledQuery}
            enabledQuery={state.enabledQuery}
            displayRefresh={state.displayRefresh}
            actionScroll={actionScroll}
            {...props}
        />
    );
};

export default Wrapper(AlertsListTabManager);
