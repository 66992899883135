import React from 'react';
import { TempPressAlertLevelTableViewProps } from './TempPressAlertLevelTable.type';
import { SelectVehicleMessage, TempPressAlertLevelTableView } from './TempPressAlertLevelTable.style';
import UiSimpleTable from '../Ui/Components/UiSimpleTable';
import Vehicle from '../../api/Vehicle';
import TopBar from '../Mixs/DataExport/TopBar/TopBar';
import { useRecoilValue } from 'recoil';
import { TopBarAtom } from '../Mixs/DataExport/TopBar/TopBar.atom';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TempPressAlertLevelTableContent: React.FC<TempPressAlertLevelTableViewProps> = (props): JSX.Element => {
    const vehicleAPI = new Vehicle();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { t: translate } = useTranslation();

    return (
        <TempPressAlertLevelTableView data-testid='TempPressAlertLevelTableContent'>
            <TopBar downloadFn={props.handleDownloadButton} showDatePickers={false} />
            {topBarAtom.vehicle.id === -1 ? (
                <SelectVehicleMessage>
                    <Typography variant='h5'>{translate('p.please_select_vehicle')}</Typography>
                </SelectVehicleMessage>
            ) : (
                <UiSimpleTable
                    columns={props.columns}
                    fetchFn={vehicleAPI.getTempPressReport}
                    defaultSorting={{
                        order: 'asc',
                        orderBy: 'name'
                    }}
                    additionalFetchProps={{
                        vehicleId: topBarAtom.vehicle.id
                    }}
                    refreshInterval={false}
                    parseData={props.parseTempPressResponse}
                    queryKey='TempPressAlertLevelTable'
                />
            )}
        </TempPressAlertLevelTableView>
    );
};
