import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserInfo } from '../../states/global/User';

const useUserLocalStorageSettings = (settingKey: string[] | 'All') => {
    const [userLSSettings, setUserLSSettings] = useState<unknown>(null);
    const userInfo = useRecoilValue(UserInfo);
    const userId = useMemo<number | undefined>(() => userInfo.user?.id, [userInfo.user?.id]);
    const userSettings = useMemo(() => {
        const dataFromLocalStorage: string | null = localStorage.getItem(`${userId}`);
        if (dataFromLocalStorage) {
            return JSON.parse(dataFromLocalStorage);
        }
        return {};
    }, [userId]);

    const getUserSettings = useCallback(() => {
        if (userId) {
            if (!userSettings) {
                return null;
            }
            if (settingKey === 'All') {
                return setUserLSSettings(userSettings);
            }
            const customSettings = {};
            for (let i = 0, length = settingKey.length; i < length; i++) {
                const settings = settingKey[i].split('.');
                let settingsToSave = settings.reduce((previousValue: object, currentValue: string, index: number) => {
                    if (!previousValue) {
                        return;
                    }
                    if (previousValue[currentValue]) {
                        if (index === settings.length - 1) {
                            return [currentValue, previousValue[currentValue]];
                        }
                        return previousValue[currentValue];
                    }
                    return [currentValue, null];
                }, userSettings);
                if (settingsToSave) {
                    customSettings[settingsToSave[0]] = settingsToSave[1];
                }
            }
            setUserLSSettings(customSettings);
        }
    }, [userId, userSettings]);

    const setUserSettings = (path: string, value: unknown): void => {
        let schema = userSettings;
        const pathList: string[] = path.split('.');
        const pathListLength: number = pathList.length;
        for (let i = 0; i < pathListLength - 1; i++) {
            const elem: string = pathList[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }

        schema[pathList[pathListLength - 1]] = value;

        localStorage.setItem(`${userId}`, JSON.stringify(userSettings));
    };

    useEffect(() => {
        getUserSettings();
    }, [getUserSettings]);

    return { userLSSettings, setUserSettings };
};

export default useUserLocalStorageSettings;

export const useUserLocalStorageSettingsTable = () => {
    const userInfo = useRecoilValue(UserInfo);
    const userId = useMemo<number | undefined>(() => userInfo.user?.id, [userInfo.user?.id]);

    const getUserSettingTable = (settingKey = 'All') => {
        let userIdLocal: string | undefined = userId ? userId.toString() : undefined;
        if (!userIdLocal) {
            return undefined;
        }

        if (userId) {
            const getValue: string | null = localStorage.getItem(userIdLocal);
            let value = {};

            if (getValue) {
                value = JSON.parse(getValue);
            }

            let customSettings: any = undefined;
            const settings = settingKey.split('.');
            const settingsToSave = settings.reduce((previousValue: object, currentValue: string, index: number) => {
                if (!previousValue) {
                    return;
                }
                if (previousValue[currentValue]) {
                    if (index === settings.length - 1) {
                        return [currentValue, previousValue[currentValue]];
                    }
                    return previousValue[currentValue];
                }
                return [currentValue, null];
            }, value);
            if (settingsToSave) {
                customSettings = settingsToSave[1];
            }
            return customSettings;
        }
    };

    const getUserSetting = (settingKey: string) => {
        let userIdLocal: string | undefined = userId ? userId.toString() : undefined;

        if (!userIdLocal) {
            return undefined;
        }

        if (userId) {
            const getValue: string | null = localStorage.getItem(userIdLocal);
            let value: {} = {};

            if (getValue) {
                value = JSON.parse(getValue);

                if (settingKey === 'All') {
                    return value;
                }

                const settings: string[] = settingKey.split('.');

                if (settingKey.includes('.')) {
                    if (Object.keys(value).length > 1) {
                        return value[settings[0]][settings[1]];
                    }
                }
                return value[settingKey];
            }
            if (typeof value === 'object') {
                return Object.keys(value).length === 0 ? null : value;
            }

            if (Array.isArray(value)) {
                return value && !!value.length ? value : null;
            }

            return value;
        }
    };

    return { getUserSettingTable, getUserSetting };
};
