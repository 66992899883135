import React from 'react';
import { PressureProps } from './TyreDetails.type';
import { PressureIcon, TargetedPressure, Value } from './TyreDetails.style';
import { SelectedSensorAtom } from './SelectedSensor.atom';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsTyresAtom } from '../VehicleDetailsTyres/VehicleDetailsTyres.atom';
import useConverter from '../../CustomHooks/Converter/Converter';
import useTempPressValue from '../../CustomHooks/TemperaturePressureValue/TemperaturePressureValue';

const Pressure: React.FC<PressureProps> = ({ wheelPosition, displayingHotPressure, alertLevel }): JSX.Element => {
    const selectedSensor = useRecoilValue(SelectedSensorAtom(wheelPosition));
    const vehicleDetailsTyresAtom = useRecoilValue(VehicleDetailsTyresAtom);
    const iconName = displayingHotPressure ? 'sun' : 'snowflake';
    const pressureValue = displayingHotPressure
        ? selectedSensor?.sensorLog?.hotPressure
        : selectedSensor?.sensorLog?.coldPressure;
    const targetPressureValue = displayingHotPressure
        ? selectedSensor?.targetPressure.hot
        : selectedSensor?.targetPressure.cold;
    const { convertType, fromServerToUserUnit } = useConverter();
    const { displayValue } = useTempPressValue();

    const temperatureValue = displayValue(selectedSensor?.sensorLog?.temperature, {
        sensor: selectedSensor?.sensor,
        valueType: 'temperature'
    });

    return (
        <>
            <PressureIcon icon={['fas', iconName]} $hotPressureIcon={displayingHotPressure} />
            <Value alertLevel={alertLevel}>
                {displayValue(pressureValue, {
                    sensor: selectedSensor?.sensor,
                    valueType: displayingHotPressure ? 'hotPressure' : 'coldPressure'
                })}
            </Value>

            {vehicleDetailsTyresAtom.targetPressure && targetPressureValue && (
                <TargetedPressure>
                    (
                    {temperatureValue === '-' && displayingHotPressure
                        ? temperatureValue
                        : fromServerToUserUnit({
                              type: convertType.pressure,
                              value: targetPressureValue,
                              displayUnits: false,
                              fixed: 1
                          })}
                    )
                </TargetedPressure>
            )}
        </>
    );
};

export default Pressure;
