import React from 'react';
import { useRecoilState } from 'recoil';
import { AlertConfigurationPosition } from 'components/Alert/AlertsConfigurationFilterInput/atom';
import { AxelSelectorType } from './VehicleSchema.type';

export const AxelSelector: React.FC<AxelSelectorType> = ({ allTyrePosition, isFront }): JSX.Element => {
    const [alertConfigurationPosition, setAlertConfigurationPosition] = useRecoilState(AlertConfigurationPosition);
    // #dbdbdb new color

    return (
        <>
            <rect
                x={isFront ? '34' : '0'}
                y={isFront ? '26' : '53.108'}
                width={isFront ? '105' : '95'}
                height='25'
                fill='red'
                cursor={'pointer'}
                opacity={0}
                onClick={() => {
                    if (allTyrePosition.every((position) => alertConfigurationPosition.position.includes(position))) {
                        setAlertConfigurationPosition((current) => ({
                            ...current,
                            position: current.position.filter(
                                (currentPosition) => !allTyrePosition.includes(currentPosition)
                            )
                        }));
                    } else {
                        setAlertConfigurationPosition((current) => ({
                            ...current,
                            position: [
                                ...current.position,
                                ...allTyrePosition.filter((position) => !current.position.includes(position))
                            ]
                        }));
                    }
                }}
            />
        </>
    );
};
