import React, { useState } from 'react';
import { AddTyreContent } from './AddTyre.view';
import { InitialValues } from './AddTyre.type';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import Vehicle from '../../../api/Vehicle';
import { VehicleList } from '../../../models/Vehicle.type';
import { TyreAdd, TyreData } from '../../../models/Tyre.type';
import Tyre from '../../../api/Tyre';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from '../../../states/global/Modal';
import { useTranslation } from 'react-i18next';
import { Success } from '../../Popup/Popup';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const vehicleApi = new Vehicle();
const tyreApi = new Tyre();

const AddTyre: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const [isSerialNumberUnique, setIsSerialNumberUnique] = useState<boolean>(true);
    const { fromTimezoneToUTC } = useConverter();
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('tyres-RefreshTableAtom'));
    const initialValues: InitialValues = {
        tyreSerialNumber: '',
        vehicle: undefined,
        wheel: null,
        date: DateTime.now().valueOf(),
        comment: ''
    };

    const { mutate, isLoading } = useMutation<TyreData, unknown, TyreAdd>(tyreApi.addTyre, {
        onSuccess: ({ data }) => {
            if (data.tyre) {
                Success({
                    text: translate('t.added_tyre')
                });
                setRefreshTableAtom(true);
                modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
            }
        }
    });

    const handleSubmit = (values: InitialValues) => {
        const dataSend: TyreAdd = {
            tyreSerialNumber: values.tyreSerialNumber,
            date: fromTimezoneToUTC(DateTime.fromMillis(values.date), true),
            comment: values.comment
        };

        if (values.wheel) {
            dataSend.wheel = values.wheel;
        }

        mutate(dataSend);
    };

    const { data: vehicleDataXHR, isLoading: vehicleLoading } = useQuery<VehicleList, Error>(
        ['VehicleList'],
        () => vehicleApi.getList(),
        {
            refetchOnWindowFocus: false
        }
    );

    const checkUniqSerialNumber = async (serialNumber): Promise<void> => {
        if (serialNumber !== '') {
            const isUnique = await tyreApi.getUniqueSerialNumber(serialNumber);
            setIsSerialNumberUnique(isUnique);
        }
    };

    return (
        <AddTyreContent
            data-testid={'AddTyre-testid'}
            vehicles={vehicleDataXHR ?? []}
            vehiclesLoading={vehicleLoading}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            checkUniqSerialNumber={checkUniqSerialNumber}
            isSerialNumberUnique={isSerialNumberUnique}
            addTyreLoading={isLoading}
        />
    );
};

export default Wrapper(AddTyre);
