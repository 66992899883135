import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RegionModelExtended } from '../../../models/Region.type';
import Region from '../../../api/Region';
import { RegionsCodebookQueryType } from './Regions.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const regionAPI = new Region();

export const GetRegionsCodebookQuery: React.FC<RegionsCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RegionModelExtended[]>(
        ['GetRegionsCodebook', userInfo.user?.customer.id],
        () => regionAPI.getAllAsCodebook(),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    return <>{children(useQueryProperties)}</>;
};
