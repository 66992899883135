import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UiVehicleDropdownProps, DropdownVehicleList, DrowpdownVehicleType } from './UiVehicleDropdown.type';
import VehicleApi from '../../../../api/Vehicle';
import { useTranslation } from 'react-i18next';
import Menu from '../UiMenu';
import Button from '../UiButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VehicleLoader, VehicleLoaderContent } from './UiVehicleDropdown.style';

const findVehicleNameById = (id, vehicleData) => {
    return vehicleData.filter((vehicle) => vehicle.id === id);
};

const vehicleList = (translate, vehicleData, updateVehicle): DropdownVehicleList => {
    if (!vehicleData) {
        return [];
    }
    return [
        {
            title: translate('t.all_vehicles'),
            value: 'all',
            action: () =>
                updateVehicle({
                    title: translate('t.all_vehicles'),
                    value: 'all'
                })
        }
    ].concat(
        vehicleData?.map((vehicle) => ({
            title: vehicle.name,
            value: vehicle.id,
            action: (e) => {
                let vehicle = findVehicleNameById(e, vehicleData);
                if (vehicle.length) {
                    updateVehicle({
                        title: vehicle[0].name,
                        value: vehicle[0].id
                    });
                }
            }
        }))
    );
};

const UiVehicleDropdownContent: React.FC<UiVehicleDropdownProps> = (props): JSX.Element => {
    const Vehicle = new VehicleApi();
    const { t: translate } = useTranslation();
    const [vehicle, updateVehicle] = useState<DrowpdownVehicleType>({ title: 'all', value: 0 });

    const { data, isLoading } = useQuery(['alertsBreakdownVehicleList'], () => Vehicle.getAllAsCodebook(), {});

    const vehicles = useMemo(() => vehicleList(translate, data, updateVehicle), [data]);
    const title = useMemo(() => {
        return vehicle.title.includes('all') ? translate('t.all_vehicles') : vehicle.title;
    }, [vehicle]);

    useEffect(() => {
        props.onVehicleChange && props.onVehicleChange(vehicle);
    }, [vehicle]);

    const btn: JSX.Element = isLoading ? (
        <VehicleLoaderContent>
            <VehicleLoader />
        </VehicleLoaderContent>
    ) : (
        <Button color='secondary' variant='text' testid='AlertsBreakdown-Vehicles-Button'>
            {title} <ExpandMoreIcon />
        </Button>
    );

    return <Menu rootEl={btn} items={vehicles} data-testid='AlertsBreakdownVehicleBtnContent' />;
};

export default UiVehicleDropdownContent;
