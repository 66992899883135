import React from 'react';
import { CommentContent, CommentUpdateViewContent, ShowComment } from './CommentUpdate.style';
import { CommentUpdateViewProps } from './CommentUpdate.type';
import { useTranslation } from 'react-i18next';
import UiLegend from '../../Ui/Components/UiLegend';
import { CommentUpdateFormik } from './CommentUpdate.formik';

export const CommentUpdateContent: React.FC<CommentUpdateViewProps> = ({
    initialValues,
    showComment,
    loading,
    handleSubmit
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <CommentUpdateViewContent data-testid='CommentUpdateContent'>
            <UiLegend
                title={translate('t.comment')}
                width={350}
                content={
                    <CommentContent>
                        <CommentUpdateFormik
                            initialValues={initialValues}
                            handleSubmit={handleSubmit}
                            loading={loading}
                        />
                    </CommentContent>
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                root={<ShowComment> {showComment ?? '---'} </ShowComment>}
            />
        </CommentUpdateViewContent>
    );
};
