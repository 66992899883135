import PortalApi from 'helpers/api/PortalApiClient';
import { CustomerSettingsModel, CustomerSettingsAll } from 'models/CustomerSettings.type';

export default class CustomerSettings extends PortalApi<CustomerSettingsModel> {
    route = 'customer-settings';

    getAll = async (): Promise<CustomerSettingsAll> => {
        return await this.get({ extendUrl: `all`, apiProject: undefined });
    };
}
