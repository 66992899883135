import React from 'react';
import { AlertsBreakdownContainer, CustomCell, CustomTable, CustomTableHead, LevelCell } from './AlertsBreakdown.style';
import PeriodBtn from '../AlertsBreakdownPeriodBtn';
import VehicleBtn from '../../Ui/Components/UiVehicleDropdown';
import AlertsBreakdownTable from '../AlertsBreakdownTable/AlertsBreakdownTableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Widget from '../../Ui/Components/UiWidget';
import { AlertsBreakdownVehicle } from '../state';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';

const TopTableHeader: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <TableRow>
            <CustomCell></CustomCell>
            {[1, 2, 3].map((level) => (
                <LevelCell alertlevel={level} colSpan={2} align='center' key={level}>
                    {translate('t.level')} {level}
                </LevelCell>
            ))}
            <CustomCell></CustomCell>
        </TableRow>
    );
};

const BottomTableHeader: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <TableRow>
            <TableCell></TableCell>
            {[1, 2, 3].map((index) => (
                <React.Fragment key={index}>
                    <TableCell align='center'>{translate('t.last')}</TableCell>
                    <TableCell align='center'>{translate('t.previous')}</TableCell>
                </React.Fragment>
            ))}
            <TableCell align='center'>+/-</TableCell>
        </TableRow>
    );
};

export const AlertsBreakdownContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const updateVehicle = useSetRecoilState(AlertsBreakdownVehicle);
    const Container = React.Fragment;
    const ThemeMode = useRecoilValue(Theme);
    return (
        <Widget
            testid='Widget-AlertsBreakdown'
            marginBottom={false}
            title={translate('t.alerts_break_down')}
            avatar={
                <UiIcon
                    icon={faChartSimple}
                    size='lg'
                    fixedWidth
                    color={applyStyleByMode({
                        styleOld: '#333',
                        theme: ThemeMode?.mode,
                        light: undefined,
                        dark: WHITE
                    })}
                />
            }
            headerAction={
                <>
                    <PeriodBtn />
                    <VehicleBtn onVehicleChange={(vehicle) => updateVehicle(vehicle)} />
                </>
            }
            content={
                <AlertsBreakdownContainer data-testid='AlertsBreakdownContent'>
                    <Container>
                        <CustomTable aria-label='Alerts Breakdown table' size='small'>
                            <CustomTableHead>
                                <TopTableHeader />
                                <BottomTableHeader />
                            </CustomTableHead>
                            <AlertsBreakdownTable /> {/* pending issue */}
                        </CustomTable>
                    </Container>
                </AlertsBreakdownContainer>
            }
        />
    );
};
