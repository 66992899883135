import React, { useState, useEffect } from 'react';
import { AutoCompleteOption, CustomerRegions, CustomerSelectorCheckboxProps } from './CustomerSelectorCheckbox.type';
import { Wrapper } from 'helpers/wrapper';
import { CustomerSelectorCheckboxContent } from './CustomerSelectorCheckbox.view';
import { useRecoilState } from 'recoil';
import {
    MultipleCustomerSelectorPopoverAtom,
    SelectedCustomersAtom,
    RegionsDataAtom,
    OriginalDataRegionsAtom
} from '../CustomerSelectorAtom';

const CustomerSelectorCheckbox: React.FC<CustomerSelectorCheckboxProps> = ({
    spaceToBottom,
    onChange,
    customerData,
    customersDataLoading
}): JSX.Element => {
    const [selectorState, setSelectorState] = useRecoilState(MultipleCustomerSelectorPopoverAtom);
    const [selectedCustomers, setSelectedCustomers] = useRecoilState(SelectedCustomersAtom);
    const [regionsData, setRegionsData] = useRecoilState(RegionsDataAtom);
    const [originalDataRegions, setOriginalDataRegions] = useRecoilState(OriginalDataRegionsAtom);
    const [searchCustomer, setSearchCustomer] = useState<string | undefined>();

    const closeRegion = (): void => {
        const allRegionsOpen: AutoCompleteOption[] = originalDataRegions.map((region) => ({
            ...region,
            open: false
        }));
        setOriginalDataRegions(allRegionsOpen);
        setRegionsData(allRegionsOpen);
    };

    const updateOriginalDataRegions = (updatedRegions: AutoCompleteOption[]): AutoCompleteOption[] => {
        const updatedCustomerAll: AutoCompleteOption[] = JSON.parse(JSON.stringify(originalDataRegions));

        updatedRegions.forEach((updatedRegion) => {
            const matchingRegionIndex = updatedCustomerAll.findIndex((region) => region.id === updatedRegion.id);

            if (matchingRegionIndex !== -1) {
                updatedRegion.customers.forEach((updatedCustomer) => {
                    const matchingCustomerIndex = updatedCustomerAll[matchingRegionIndex].customers.findIndex(
                        (customer) => customer.id === updatedCustomer.id
                    );

                    if (matchingCustomerIndex !== -1) {
                        updatedCustomerAll[matchingRegionIndex].customers[matchingCustomerIndex] = updatedCustomer;
                    }
                });
            }
        });

        return updatedCustomerAll;
    };

    const searchData = (): void => {
        if (!searchCustomer) {
            closeRegion();
            return;
        }

        const lowerCaseSearchTerm: string = searchCustomer.toLowerCase();

        const updatedRegions: AutoCompleteOption[] | [] = originalDataRegions
            .map((region) => {
                const filteredCustomers = region.customers.filter((customer) =>
                    customer.name.toLowerCase().includes(lowerCaseSearchTerm)
                );

                if (filteredCustomers.length > 0) {
                    return {
                        ...region,
                        open: true,
                        customers: filteredCustomers
                    };
                }

                return null;
            })
            .filter((region) => region !== null) as AutoCompleteOption[];

        const updatedRegionsAll: AutoCompleteOption[] = originalDataRegions.map((region) => {
            return {
                ...region,
                open: true,
                customers: region.customers
            };
        });

        setRegionsData(updatedRegions);
        setOriginalDataRegions(updatedRegionsAll);
    };

    const handleOnClosePopover = (): void => {
        onChange('', selectedCustomers);
        setSelectorState({ ...selectorState, isOpen: false });
        setSearchCustomer('');
        closeRegion();
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setSearchCustomer(e.target.value);
    };

    const openRegion = (regionId: number | null): void => {
        const updatedRegions: AutoCompleteOption[] = regionsData.map((region) => {
            if (region.id === regionId) {
                return {
                    ...region,
                    open: !region.open
                };
            } else {
                return region;
            }
        });

        setRegionsData(updatedRegions);
    };

    const clearSelectedCustomers = (): void => {
        const selectedCustomersList = [];
        setSelectedCustomers(selectedCustomersList);

        const updateOriginalDataRegions: AutoCompleteOption[] = JSON.parse(JSON.stringify(originalDataRegions));
        updateOriginalDataRegions.forEach((region) => {
            region.customers.forEach((customer) => {
                customer.selected = false;
            });
        });

        setOriginalDataRegions(updateOriginalDataRegions);
        setRegionsData(updateOriginalDataRegions);
    };

    const handleCustomerCheckboxChange = (customer: CustomerRegions): void => {
        const updatedRegions = regionsData.map((region) => ({
            ...region,
            customers: region.customers.map((customerItem) => {
                if (customerItem.id === customer.id) {
                    return { ...customerItem, selected: !customerItem.selected };
                } else {
                    return customerItem;
                }
            })
        }));

        const isSelected: boolean = selectedCustomers.some((selected) => selected.id === customer.id);

        const updatedSelectedCustomers: CustomerRegions[] = isSelected
            ? selectedCustomers.filter((selected) => selected.id !== customer.id)
            : [...selectedCustomers, customer];

        setSelectedCustomers(updatedSelectedCustomers);
        setRegionsData(updatedRegions);
        setOriginalDataRegions(updateOriginalDataRegions(updatedRegions));
    };

    const handleRegionCheckboxChange = (regionId: number, checked: boolean): void => {
        const updatedRegions: AutoCompleteOption[] = regionsData.map((region) =>
            region.id === regionId
                ? {
                      ...region,
                      customers: region.customers.map((customer) => ({
                          ...customer,
                          selected: !checked ? !region.customers.every((customer) => customer.selected) : checked
                      }))
                  }
                : region
        );

        let updatedSelectedCustomers: CustomerRegions[] = [];
        const selectedCustomerIds: Set<number> = new Set(selectedCustomers.map((customer) => customer.id));

        if (checked) {
            updatedSelectedCustomers =
                updatedRegions
                    .find((region) => region.id === regionId)
                    ?.customers.filter((customer) => !selectedCustomerIds.has(customer.id)) ?? [];
        } else {
            updatedSelectedCustomers = selectedCustomers.filter(
                (customer) =>
                    !updatedRegions
                        .find((region) => region.id === regionId)
                        ?.customers.some((regionCustomer) => regionCustomer.id === customer.id)
            );
        }

        setRegionsData(updatedRegions);
        setSelectedCustomers(checked ? [...selectedCustomers, ...updatedSelectedCustomers] : updatedSelectedCustomers);
        setOriginalDataRegions(updateOriginalDataRegions(updatedRegions));
    };

    useEffect(() => {
        searchData();
    }, [searchCustomer]);

    return (
        <CustomerSelectorCheckboxContent
            data-testid='CustomerSelectorCheckbox-testid'
            customersDataLoading={customersDataLoading}
            selectorState={selectorState}
            handleOnClosePopover={handleOnClosePopover}
            searchCustomer={searchCustomer}
            onInputChange={onInputChange}
            spaceToBottom={spaceToBottom}
            regionsData={regionsData}
            openRegion={openRegion}
            handleRegionCheckboxChange={handleRegionCheckboxChange}
            handleCustomerCheckboxChange={handleCustomerCheckboxChange}
            selectedCustomers={selectedCustomers}
            customerData={customerData}
            clearSelectedCustomers={clearSelectedCustomers}
        />
    );
};

export default Wrapper(CustomerSelectorCheckbox);
