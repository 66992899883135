import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { TpmsAlertQueryProps } from './TpmsAlertQuery.type';
import { ModelGetTpmsAlerts } from 'models/TpmsAlerts.type';
import NotificationEvent from 'api/NotificationEvent';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const notificationEventAPI = new NotificationEvent();

const TpmsAlertQuery: React.FC<TpmsAlertQueryProps> = ({
    children,
    solved,
    currentPage,
    keyQuery = 'GetTpmsAlert',
    limit,
    customConfig,
    vehicleId,
    sensorId,
    measuredFrom,
    alertFilters,
    tab
}): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);

    const useQueryProperties = useQuery<ModelGetTpmsAlerts, Error, ModelGetTpmsAlerts>(
        [
            `${keyQuery}${alertFilters && alertFilters.level ? alertFilters.level : ''}${
                alertFilters && alertFilters.type ? alertFilters.type : ''
            }${alertFilters && alertFilters.type2 ? alertFilters.type2 : ''}`,
            userInfo.user?.customer.id
        ],
        () =>
            notificationEventAPI.getNotification({
                vehicleId,
                solved,
                measuredFrom,
                sensorId,
                page: currentPage,
                limit,
                alertFilters,
                tab
            }),
        customConfig
    );

    return <>{children(useQueryProperties)}</>;
};

export default TpmsAlertQuery;
