import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    ActionsHeader,
    ButtonTitle,
    ContainerActionWidget,
    ContainerSelectFilter,
    ContainerToPrint,
    CustomButton,
    IconButtonRefresh,
    IconContainer,
    IconTemperatureAlertDisabledContainer,
    LegendIcon,
    LegendItemContainer,
    LegendListContainer,
    TemperaturePressureTableViewContent
} from './TemperaturePressureTable.style';
import {
    AlertLevelEnum,
    TableViewTypeActionsEnum,
    TemperaturePressureTableViewProps
} from './TemperaturePressureTable.type';
import { useTranslation } from 'react-i18next';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import Legend from 'components/Ui/Components/UiLegend';
import TemperaturePressureList from '../TemperaturePressureList/TemperaturePressureList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefreshIcon from '@mui/icons-material/Refresh';
import UiInputSearch from 'components/Ui/Components/UiInputSearch/UiInputSearch';
import { Typography, Grid, useTheme, useMediaQuery, Alert } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import Menu from 'components/Ui/Components/UiMenu';
import { ExpandMore } from '@mui/icons-material';
import { Item } from 'components/Ui/Components/UiMenu/UiMenu.type';
import { Theme } from 'states/global/Theme';
import { BACKGROUND_PAPER_DARK_MODE, BACKGROUND_TABLE_DARK, WHITE } from 'components/Ui/colors';
import { TempPressFilters } from './TempPressFilters.atom';
import FiltersTempAndPress, { FiltersForTempPressAtom } from './FiltersTempAndPress';
import GetVehicleGroupCodebook from 'components/Queries/VehicleGroup/VehicleGroup';
import TemperaturePredictionActionMenu from '../TemperaturePredictionActionMenu/TemperaturePredictionActionMenu';
import {
    faChartMixedUpCircleCurrency,
    faTruckClock,
    faThermometerThreeQuarters
} from '@fortawesome/pro-solid-svg-icons';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import { CustomerSettings } from 'states/global/CustomerSettings';

const createListLegent = (title: string, icon: JSX.Element): JSX.Element => (
    <LegendItemContainer>
        {icon} {title}
    </LegendItemContainer>
);

export const TemperaturePressureTableContent: React.FC<TemperaturePressureTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const tempPressFiltersAtom = useRecoilValue(TempPressFilters);
    const setFiltersForTempPressAtom = useSetRecoilState(FiltersForTempPressAtom);
    const theme = useTheme();
    const showXs = useMediaQuery(theme.breakpoints.up('xs'));
    const customerSettings = useRecoilValue(CustomerSettings);
    const [tempPredProblem, setTempPredProblem] = useState<boolean>(false);

    const handleChange = () => {
        setFiltersForTempPressAtom((currentValue) => !currentValue);
    };

    const titlesButtonFilter = {
        cold: `${translate('t.temperature')} & ${translate('t.cold_pressure')}`,
        cold_opt: `${translate('t.cold_pressure')} & ${translate('t.optimal_cold_pressure')}`,
        hot: `${translate('t.temperature')} & ${translate('t.hot_pressure')}`,
        hot_opt: `${translate('t.hot_pressure')} & ${translate('t.target_hot_pressure')}`,
        diff: `${translate('t.temperature')} &
        ${translate('t.cold_pressure')} - ${translate('t.tyre_optimal_pressure_diff')}`,
        all: translate('t.temperature_pressure')
    };

    const titlessortBy = {
        severity_of_alert: translate('t.severity'),
        'severity+name': `${translate('t.severity')} + ${translate('t.alphabetically')}`,
        name: translate('t.alphabetically')
    };

    const alertLevelIcon = {
        0: translate('t.all'),
        1: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#c1c121' transform='up-1 left-2' />,
        2: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#ea9800' transform='up-1 left-2' />,
        3: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#ff1616' transform='up-1 left-2' />
    };

    const contentUiLegend = (): JSX.Element => {
        return (
            <LegendListContainer>
                {createListLegent(
                    `${translate('t.alert_level')} 1`,
                    <LegendIcon $background='#fffda2' $borderColor='#C6C71F' />
                )}
                {createListLegent(
                    `${translate('t.alert_level')} 2`,
                    <LegendIcon $background='#fbdaa8' $borderColor='#FFA559' />
                )}
                {createListLegent(
                    `${translate('t.alert_level')} 3`,
                    <LegendIcon $background='#f9d7d7' $borderColor='#FA000C' />
                )}
                {createListLegent(
                    `${translate('p.measured_more_30m_ago')}`,
                    <LegendIcon $background='#8496af' $borderColor='#8496af' />
                )}
                {createListLegent(
                    `${translate('t.no_wheel')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            styleOld: 'rgb(210, 210, 210)',
                            theme: ThemeMode?.mode,
                            light: 'rgb(210, 210, 210)',
                            dark: BACKGROUND_TABLE_DARK
                        })}
                        $borderColor={applyStyleByMode({
                            styleOld: 'rgb(210, 210, 210)',
                            theme: ThemeMode?.mode,
                            light: 'rgb(210, 210, 210)',
                            dark: BACKGROUND_TABLE_DARK
                        })}
                    />
                )}
                {createListLegent(
                    `${translate('t.there_no_data')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            styleOld: WHITE,
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='#bdbdbd'
                        $borderWidth='1px'
                    />
                )}
                {createListLegent(
                    `${translate('t.measured_data')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            styleOld: WHITE,
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor={applyStyleByMode({
                            styleOld: '#bdbdbd',
                            theme: ThemeMode?.mode,
                            light: '#bdbdbd',
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderWidth='1px'
                    >
                        125
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('t.hot_pressure')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            styleOld: WHITE,
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='#FF1616'
                        $borderWidth='1px'
                    >
                        959
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('t.cold_pressure')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            styleOld: WHITE,
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='blue'
                        $borderWidth='1px'
                    >
                        265
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('p.measured_more_1d_ago')}`,
                    <IconContainer>
                        <UiIcon icon={['far', 'stopwatch']} size='lg' />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.vehicle_in_maintenance')}`,
                    <IconContainer>
                        <UiIcon
                            icon={['fas', 'wrench']}
                            size='lg'
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.out_of_service')}`,
                    <IconContainer>
                        <UiIcon
                            icon={faTruckClock}
                            size='lg'
                            color={applyStyleByMode({
                                styleOld: 'rgb(200,200,200)',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.show_statistics')}`,
                    <IconContainer>
                        <UiIcon
                            icon={['fas', 'chart-area']}
                            size='lg'
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('t.has_temperature_prediction')}`,
                        <IconContainer>
                            <FontAwesomeIcon
                                size='lg'
                                icon={faChartMixedUpCircleCurrency}
                                color={applyStyleByMode({
                                    styleOld: '#000',
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </IconContainer>
                    )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('p.old_temperature_prediction')}`,
                        <IconContainer>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon
                                    size='lg'
                                    icon={['fas', 'slash']}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                                <FontAwesomeIcon
                                    size='lg'
                                    icon={['fas', 'p']}
                                    color={applyStyleByMode({
                                        styleOld: '#000',
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            </span>
                        </IconContainer>
                    )}
                {createListLegent(
                    `${translate('p.faulty_sensor_value')}`,
                    <IconContainer>
                        <UiIcon icon={['fas', 'exclamation']} size='lg' className='exclamation-icon' color='red' />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.hidden_cold_pressure')} | ${translate('t.hidden_external_sensor_temperature')}`,
                    <IconContainer>
                        <strong>X</strong>
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.external_sensor')}`,
                    <IconContainer>
                        <ExternalIcon
                            style={{
                                width: '11px',
                                height: '11px',
                                fill: applyStyleByMode({
                                    styleOld: '#000',
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })
                            }}
                        />
                    </IconContainer>
                )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('t.temperature_alert_disabled')}`,
                        <IconTemperatureAlertDisabledContainer>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon
                                    icon={faSlashRegular}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                                <FontAwesomeIcon
                                    icon={faThermometerThreeQuarters}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                            </span>
                        </IconTemperatureAlertDisabledContainer>
                    )}
            </LegendListContainer>
        );
    };

    const alertLevelMenu = (): Item[] => {
        return [
            {
                title: translate('t.all'),
                value: AlertLevelEnum.ALERT_LEVEL_0,
                action: props.setFilterByAlert
            },
            {
                title: (
                    <>
                        <UiIcon
                            icon={['fas', 'triangle-exclamation']}
                            size='lg'
                            color='#c1c121'
                            transform='up-3 left-2'
                        />
                        {AlertLevelEnum.ALERT_LEVEL_1}
                    </>
                ),
                value: AlertLevelEnum.ALERT_LEVEL_1,
                action: props.setFilterByAlert
            },
            {
                title: (
                    <>
                        <UiIcon
                            icon={['fas', 'triangle-exclamation']}
                            size='lg'
                            color='#ea9800'
                            transform='up-3 left-2'
                        />
                        {AlertLevelEnum.ALERT_LEVEL_2}
                    </>
                ),
                value: AlertLevelEnum.ALERT_LEVEL_2,
                action: props.setFilterByAlert
            },
            {
                title: (
                    <>
                        <UiIcon
                            icon={['fas', 'triangle-exclamation']}
                            size='lg'
                            color='#ff1616'
                            transform='up-3 left-2'
                        />
                        {AlertLevelEnum.ALERT_LEVEL_3}
                    </>
                ),
                value: AlertLevelEnum.ALERT_LEVEL_3,
                action: props.setFilterByAlert
            }
        ];
    };

    const filterMenu = (): Item[] => {
        return [
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.COLD],
                value: TableViewTypeActionsEnum.COLD,
                action: props.setTableDisplay
            },
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.COLD_OPT],
                value: TableViewTypeActionsEnum.COLD_OPT,
                action: props.setTableDisplay
            },
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.HOT],
                value: TableViewTypeActionsEnum.HOT,
                action: props.setTableDisplay
            },
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.HOT_OPT],
                value: TableViewTypeActionsEnum.HOT_OPT,
                action: props.setTableDisplay
            },
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.ALL],
                value: TableViewTypeActionsEnum.ALL,
                action: props.setTableDisplay
            },
            {
                title: titlesButtonFilter[TableViewTypeActionsEnum.DIFF],
                value: TableViewTypeActionsEnum.DIFF,
                action: props.setTableDisplay
            }
        ];
    };

    const sortBy = (): Item[] => {
        return [
            {
                title: titlessortBy.severity_of_alert,
                value: 'severity_alert:desc',
                action: () => props.sortingByTable('severity_of_alert', 'desc')
            },
            {
                title: titlessortBy['severity+name'],
                value: 'severity+name',
                action: () => props.sortingByTable('severity+name', 'desc')
            },
            {
                title: titlessortBy.name,
                value: 'name:asc',
                action: () => props.sortingByTable('name', 'asc')
            }
        ];
    };

    const listVehicleType: Item[] = [
        {
            title: translate('t.all'),
            value: 0,
            action: props.setFilterVehicleGroup
        }
    ];

    return (
        <TemperaturePressureTableViewContent data-testid='TemperaturePressureTableContent'>
            <UiWidget
                avatar={
                    <UiIcon
                        icon={['fas', 'user']}
                        color={applyStyleByMode({
                            styleOld: WHITE,
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                margin={0}
                marginBottom={false}
                padding={0}
                data-testid='NotificationSettingsContent'
                loading={false}
                width={100}
                unit='%'
                applyPadding={false}
                title={
                    <>
                        {translate('t.temperature_pressure')}
                        {''}
                        {tempPredProblem ? (
                            <span
                                style={{
                                    marginLeft: 16,
                                    backgroundColor: 'rgb(253,242,217)',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    color: 'rgb(102, 60, 0)',
                                    borderRadius: 4,
                                    paddingTop: 3,
                                    paddingBottom: 3
                                }}
                            >
                                <UiIcon
                                    icon={['fas', 'triangle-exclamation']}
                                    size='sm'
                                    fixedWidth
                                    color='rgb(237, 108, 2)'
                                />
                                {translate('p.predictive_temperature_algorithm_maintenance')}
                            </span>
                        ) : (
                            <></>
                        )}
                    </>
                }
                headerAction={
                    <ContainerActionWidget>
                        <Legend minWidth={450} title={translate('t.legend')} content={contentUiLegend()} />
                        <IconButtonRefresh
                            title={translate('t.refresh')}
                            size='small'
                            onClick={() => props.handleRefresh(true)}
                            data-testid='Temp&Press-Refresh-Button'
                        >
                            <RefreshIcon fontSize='small' />
                        </IconButtonRefresh>
                        <IconButtonRefresh
                            title={translate('t.download')}
                            size='small'
                            onClick={() => props.setShowScroll(false)}
                            data-testid='Temp&Press-Refresh-Button'
                        >
                            <FontAwesomeIcon icon={['fas', 'download']} size='sm' />
                        </IconButtonRefresh>
                        <IconButtonRefresh
                            title={translate('t.filters')}
                            size='small'
                            onClick={handleChange}
                            data-testid='Temp&Press-ShowFilter-Button'
                        >
                            <FontAwesomeIcon icon={['fas', 'bars-filter']} size='sm' />
                        </IconButtonRefresh>
                    </ContainerActionWidget>
                }
                content={
                    <div style={{ height: props.showScroll ? 'calc(100vh - 131px)' : 'auto' }}>
                        <FiltersTempAndPress>
                            <ActionsHeader>
                                <ContainerActionWidget>
                                    <Grid container spacing={1}>
                                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                                            <ContainerSelectFilter>
                                                <UiInputSearch
                                                    inputSearch={{
                                                        valueInputSearch: props.tempAndPressState.filterVehicleName,
                                                        setValueInputSearch: props.setValueInputSearch
                                                    }}
                                                    getValueInputSearch={(e: string) => props.setValueInputSearch(e)}
                                                    widthInputSearchInit={200}
                                                    widthInputSearchActive={350}
                                                    searchByEnter={false}
                                                    placeholder={`${translate('t.vehicle_name')}, ${translate(
                                                        't.hub'
                                                    )} Id`}
                                                    $testid='Temp&Press-InputFilterByVehicleNameAndHubId-Input'
                                                />
                                            </ContainerSelectFilter>
                                        </Grid>
                                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                                            <ContainerSelectFilter>
                                                <Typography variant='subtitle1'>
                                                    {translate('t.sort_by') + ': '}
                                                </Typography>
                                                <Menu
                                                    rootEl={
                                                        <CustomButton
                                                            size='small'
                                                            variant='outlined'
                                                            color='secondary'
                                                            testid='Temp&Press-SortByFilter-Button'
                                                        >
                                                            {tempPressFiltersAtom.sortBy?.column
                                                                ? titlessortBy[tempPressFiltersAtom.sortBy?.column]
                                                                : titlessortBy.name}
                                                            <ExpandMore />
                                                        </CustomButton>
                                                    }
                                                    items={sortBy()}
                                                />
                                            </ContainerSelectFilter>
                                        </Grid>
                                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                                            <ContainerSelectFilter>
                                                <Typography variant='subtitle1'>
                                                    {translate(`t.${showXs ? 'level' : 'alert_level'}`) + ': '}
                                                </Typography>
                                                <Menu
                                                    rootEl={
                                                        <CustomButton
                                                            variant='outlined'
                                                            color='secondary'
                                                            size='small'
                                                            testid='Temp&Press-LevelFilter-Button'
                                                        >
                                                            {alertLevelIcon[props.tempAndPressState.filterAlertLevel]}
                                                            {props.tempAndPressState.filterAlertLevel || ''}
                                                            <ExpandMore />
                                                        </CustomButton>
                                                    }
                                                    items={alertLevelMenu()}
                                                />
                                            </ContainerSelectFilter>
                                        </Grid>
                                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                                            <ContainerSelectFilter>
                                                <Typography variant='subtitle1'>
                                                    {translate('t.view') + ': '}
                                                </Typography>
                                                <Menu
                                                    rootEl={
                                                        <CustomButton
                                                            variant='outlined'
                                                            title={
                                                                titlesButtonFilter[
                                                                    props.tempAndPressState.tableViewType
                                                                ]
                                                            }
                                                            color='secondary'
                                                            testid='Temp&Press-ViewFilter-Button'
                                                            sx={{ maxWidth: '180px' }}
                                                        >
                                                            <ButtonTitle>
                                                                {
                                                                    titlesButtonFilter[
                                                                        props.tempAndPressState.tableViewType
                                                                    ]
                                                                }
                                                            </ButtonTitle>
                                                            <ExpandMore />
                                                        </CustomButton>
                                                    }
                                                    items={filterMenu()}
                                                />
                                            </ContainerSelectFilter>
                                        </Grid>
                                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                                            <ContainerSelectFilter>
                                                <Typography variant='subtitle1'>
                                                    {translate('t.vehicle_group') + ': '}
                                                </Typography>
                                                <GetVehicleGroupCodebook>
                                                    {({ data }) => (
                                                        <Menu
                                                            rootEl={
                                                                <CustomButton
                                                                    variant='outlined'
                                                                    title={
                                                                        props.tempAndPressState.filterVehicleGroup.title
                                                                    }
                                                                    color='secondary'
                                                                    sx={{ maxWidth: '130px' }}
                                                                >
                                                                    <ButtonTitle>
                                                                        {
                                                                            props.tempAndPressState.filterVehicleGroup
                                                                                .title
                                                                        }
                                                                    </ButtonTitle>
                                                                    <ExpandMore />
                                                                </CustomButton>
                                                            }
                                                            items={
                                                                data
                                                                    ? listVehicleType.concat(
                                                                          data.map((vehicleType) => {
                                                                              return {
                                                                                  title: vehicleType.type,
                                                                                  value: vehicleType.id,
                                                                                  action: props.setFilterVehicleGroup
                                                                              };
                                                                          })
                                                                      )
                                                                    : []
                                                            }
                                                        />
                                                    )}
                                                </GetVehicleGroupCodebook>
                                            </ContainerSelectFilter>
                                        </Grid>
                                    </Grid>
                                </ContainerActionWidget>
                            </ActionsHeader>
                        </FiltersTempAndPress>

                        <ContainerToPrint
                            ref={(el) => (props.componentRef.current = el)}
                            $showScroll={props.showScroll}
                        >
                            <TemperaturePressureList
                                tableDisplay={props.tempAndPressState.tableViewType}
                                valueInputSearch={props.tempAndPressState.filterVehicleName}
                                filterByAlert={props.tempAndPressState.filterAlertLevel}
                                handleRefresh={props.handleRefresh}
                                refresh={props.tempAndPressState.refresh}
                                setFilterByAlert={props.setFilterByAlert}
                                showScroll={props.showScroll}
                                filterVehicleGroup={props.tempAndPressState.filterVehicleGroup}
                                setTempPredProblem={setTempPredProblem}
                            />
                        </ContainerToPrint>
                    </div>
                }
            ></UiWidget>
            <TemperaturePredictionActionMenu />
        </TemperaturePressureTableViewContent>
    );
};
