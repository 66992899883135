import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { PRIMARY, SUCCESS, BACKGROUND_BLUE, WHITE } from '../../../../colors';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import UiButton from '../../../UiButton/UiButton';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from '@mui/material';

export const CustomButton = styled(UiButton)`
    &&.MuiButton-root {
        min-width: 100%;
        margin: 0px;
        padding: 0px;
    }

    &&.MuiButtonBase-root {
        min-width: 100%;
        margin: 0px;
        padding: 0px;
        svg {
            font-size: 27px;
        }
    }

    background-color: transparent !important;
    svg {
        ${applyStyleByMode({
            styleOld: `color:${PRIMARY} !important;`,
            styleJade: `color:${SUCCESS} !important;`
        })}
    }
`;

export const CustomListButton = styled(ListItemButton)`
    &.MuiButtonBase-root {
        border-radius: 20px !important;
        padding: 3px 5px !important;
    }
`;

export const CustomListText = styled(ListItemText)`
    & .MuiTypography-root {
        font-size: 1.2em;
    }
`;

export const HrsMinContainer = styled.div<{ $ampm: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
        margin-left: ${(props) => (props.$ampm ? 63 : 0)}px;
    }

    .innerDiv {
        display: inline-flex;
        width: 50px;
    }

    .innerDiv:nth-child(2) {
        text-align: center;
        width: 20px;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column !important;
`;

export const CustomList = styled(List)`
    &.MuiList-root {
        display: inline-flex !important;
        width: 72px;
        margin-left: 5px;
    }

    & .MuiButtonBase-root {
        margin: 0 1px;
        justify-content: center;
    }
    & .Mui-selected {
        background-color: ${BACKGROUND_BLUE} !important;
        color: ${WHITE} !important;
    }
`;

export const CustomListItem = styled(ListItemButton)`
    width: 40px;
    &.MuiListItemButton-root {
        & .Mui-selected {
        }
    }
`;

export const CustomTextField = styled(TextField)`
    input {
        text-align: center;
    }

    && .MuiInputBase-input {
        font-size: 1.2rem;
    }
`;

export const HelperText = styled.div<{ $ampm: boolean }>`
    @media (min-width: 768px) {
        margin-right: ${(props) => (props.$ampm ? 63 : 0)}px;
    }
`;

export const CustomStaticDatePicker = styled(StaticDateTimePicker)`
    height: 300px;
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)`
    &.MuiFormControl-root {
        margin: 0 3px 3px 0 !important;
    }
    ${applyStyleByMode({
        styleOld: `
            && .MuiInputBase-input {
                padding: 8.5px 0px 8.5px 14px !important;
            }
        `
    })}
`;
