import React from 'react';
import { ConfirmationPopupContainer } from './ConfirmationPopup.style';
import Modal from '../Modal/Modal';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { ConfirmationPopupState } from '../../states/component/ConfirmationPopup';
import { ShowConfirmationPopupModal } from '../../states/global/Modal';

export const ConfirmationPopupContent: React.FC = (): JSX.Element => {
    const confirmationPopupState = useRecoilValue(ConfirmationPopupState);
    const resetConfirmationPopupModal = useResetRecoilState(ShowConfirmationPopupModal);
    const resetConfirmationPopupState = useResetRecoilState(ConfirmationPopupState);

    return (
        <ConfirmationPopupContainer data-testid='ConfirmationPopupContent'>
            <Modal
                type='ShowConfirmationPopupModal'
                leftTitle={confirmationPopupState.title}
                content={confirmationPopupState.body}
                buttons={confirmationPopupState.buttons}
                onClose={() => {
                    resetConfirmationPopupModal();
                    resetConfirmationPopupState();
                }}
                width={700}
                height={200}
                widthUnit='px'
                id='announcements-actions-modal'
            />
        </ConfirmationPopupContainer>
    );
};
