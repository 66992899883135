import React, { useCallback, useEffect } from 'react';
import { AlertsAvailableFiltersListContent } from './AlertsAvailableFiltersList.view';
import { AlertsAvailableFiltersListProps } from './AlertsAvailableFiltersList.type';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { AlertVehicleFilters } from '../AlertsFilterVehiclesBy/atom';
import { SelectedAvailableFilterIds } from '../AlertsAvailableFilters/atom';
import { Wrapper } from 'helpers/wrapper';
import { AvailableFilter } from '../AlertsAvailableFilters/AlertsAvailableFilters.type';

const AlertsAvailableFiltersList: React.FC<AlertsAvailableFiltersListProps> = (): JSX.Element => {
    const filters = useRecoilValue(AlertVehicleFilters);
    const resetFilters = useResetRecoilState(AlertVehicleFilters);
    const resetAvailabledFilterIds = useResetRecoilState(SelectedAvailableFilterIds);
    const filterIds = useRecoilValue(SelectedAvailableFilterIds);

    useEffect(() => {
        return () => {
            resetFilters();
            resetAvailabledFilterIds();
        };
    }, []);

    const checkDisabled = useCallback(
        (filter: AvailableFilter) => {
            if (!filterIds.length) {
                return false;
            }
            return filterIds.some((filterId) => {
                return !filter.availableWith.includes(filterId);
            });
        },
        [filters]
    );

    return (
        <AlertsAvailableFiltersListContent
            checkDisabled={checkDisabled}
            loading={false}
            data-testid={'AlertsAvailableFiltersList-testid'}
        />
    );
};

export default Wrapper(AlertsAvailableFiltersList);
