import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TyreStateAtom, UnmountDateAtom } from '../TyreManager.atom';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../Ui/Components/UiDatePicker/UiDatePicker';
import { CRUD } from 'variables';

const UnmountDate = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [unmountDate, setUnmountDate] = useRecoilState(UnmountDateAtom);
    const tyreState = useRecoilValue(TyreStateAtom);

    return (
        <>
            {tyreState.state !== CRUD.MOUNT && tyreState.newTyre.vehicleName && (
                <DatePicker
                    value={unmountDate}
                    onDateChange={(newData) => setUnmountDate(newData)}
                    label={translate('t.unmounted_at')}
                    noMargin={true}
                    datetest-id='unmount-date-picker'
                    testId='TyreManager-UnmountedAt-DatePicker'
                />
            )}
        </>
    );
};

export default UnmountDate;
