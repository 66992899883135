import { Paper } from '@mui/material';
import styled from 'styled-components';

export const PrivacyPolicyViewContent = styled.div`
    p {
        font-size: 0.9rem;
        margin: 15px 0px;
    }
    .logo {
        width: 40vh;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    padding-top: 10px;
`;

export const PrivacyPolicyPaper = styled(Paper)`
    width: 80%;
    margin: 0 auto;
    padding: 15px 30px;
    margin-bottom: 20px;
`;

export const SubTextContainer = styled.div`
    padding-left: 15px;
`;

export const FontBold = styled.span`
    font-weight bold;
    margin-right: 5px;
`;
