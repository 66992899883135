import { atom } from 'recoil';
import { DateTime } from 'luxon';
import { DatePickerAction } from '../../pages/SeverityRank/atom';
import { DateTimePickerType, TimelineType } from '../global/Statistics';

export type FaultySensorReporterProps = {
    sensorId: number;
    vehicleId: number;
    sensorName: string;
    vehicleName?: string;
    isExternal: boolean;
    internalOnVehicle?: boolean;
    reportDetailId?: number;
    reportDateFrom?: string;
    reportDateTo?: string;
    showOnlyGraph?: boolean;
    notValidate?: boolean;
    wheelPosition?: number;
    new?: boolean;
    onVerified?: boolean | (() => void);
    allowReportLeakAlert?: boolean;
    displayAllHubs?: boolean;
    afterAction?: () => void;
};

export type ProblemsSensorReporterType = { [key: string]: boolean };

export const FaultySensorReporterState = atom<FaultySensorReporterProps>({
    key: 'FaultySensorReporterState',
    default: {
        sensorId: 0,
        vehicleId: 0,
        sensorName: '',
        isExternal: false
    }
});

export const ProblemsSensorReporterState = atom<ProblemsSensorReporterType>({
    key: 'ProblemsSensorReporterState',
    default: {}
});

export const DateTimePicker = atom<DateTimePickerType>({
    key: 'FaultySensorReporterDateTimePicker',
    default: {
        current: {
            dateFrom: DateTime.now().minus({ days: 1 }),
            dateTo: DateTime.now()
        },
        original: {
            dateFrom: DateTime.now().minus({ days: 1 }),
            dateTo: DateTime.now()
        },
        action: DatePickerAction.APPLY
    }
});

export const Timeline = atom<TimelineType>({
    key: 'FaultySensorReporterTimeline',
    default: {
        timeline: {},
        granularity: 120
    }
});

export const StatusDataPressureGraph = atom<{ loading: boolean; dataAvailable: boolean }>({
    key: 'StatusDataPressureGraph',
    default: {
        loading: false,
        dataAvailable: false
    }
});

export const ShowAlerts = atom<boolean>({
    key: 'ShowAlerts',
    default: true
});

export const InvalidActionButtons = atom<boolean>({
    key: 'InvalidActionButtons',
    default: false
});
