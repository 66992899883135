import React, { useEffect } from 'react';
import { AlertNotificationManagmentContent } from './AlertNotificationManagment.view';
import { AlertNotificationManagmentProps } from './AlertNotificationManagment.type';
import { DateTime } from 'luxon';
import { useSetRecoilState } from 'recoil';
import { AlertNotificationManagmentState } from 'states/component/AlertNotificationManagment';
import { Wrapper } from 'helpers/wrapper';

const AlertNotificationManagment: React.FC<AlertNotificationManagmentProps> = (): JSX.Element => {
    const setAlertNotificationManagment = useSetRecoilState(AlertNotificationManagmentState);

    useEffect(() => {
        setAlertNotificationManagment({
            dateFrom: DateTime.local(),
            dateTo: DateTime.local().plus({ days: 7 }),
            isValidRange: true
        });
    }, []);

    return <AlertNotificationManagmentContent data-testid='AlertNotificationManagment-testid' />;
};

export default Wrapper(AlertNotificationManagment);
