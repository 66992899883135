import styled from 'styled-components';
import { LinearProgress } from '@mui/material';

export const QrCodeStyled = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 6px 0;
`;

export const Timer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 6px;
`;

export const TimerLinearProgress = styled(LinearProgress)<{ customColor: string }>`
    &.MuiLinearProgress-root {
        height: 15px;
        width: 100%;
        border-radius: 0;
        background-color: rgba(219, 219, 219, 0.7);
    }

    & .MuiLinearProgress-bar {
        height: 15px;
        border-radius: 10px;
        background-color: ${(props) => props.customColor};
    }
`;

export const Time = styled('div')`
    position: absolute;
    top: 68px;
    z-index: 5;
`;

export const ExpiredMessage = styled('div')`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    & .MuiAlert-root {
        width: 100%;
    }
`;
