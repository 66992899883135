import styled from 'styled-components';

export const HistoryPlacementTableContainer = styled.div`
    th,
    td {
        border: none;
    }
    width: 98%;
    && .MuiCardContent-root {
        margin: 0 7px;
        padding: 16px 0;
        font-size: 13px !important;
        border: none;
        overflow-x: hidden;
    }

    & .MuiTableHead-root {
        border: none;
        background: #f9f9f9;
        overflow-x: hidden;
    }

    && .infinite-scroll-component {
        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 7px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.5) !important;
            width: 7px !important;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.7) !important;
        }
    }

    margin-top: 10px;
`;
