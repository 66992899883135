import React from 'react';
import { JobsLogsTableContent } from './JobsLogsTable.view';
import { JobsLogsTableProps } from './JobsLogsTable.type';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

const JobsLogsTable: React.FC<JobsLogsTableProps> = ({ jobId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    const columns = [
        {
            header: 'ID',
            accessor: 'id',
            parseData: (id) => id ?? '-'
        },

        {
            header: translate('t.time'),
            accessor: 'time',
            parseData: (time) => (time ? fromUTCToTimezone(time, true) : '-')
        },
        {
            header: translate('t.message'),
            accessor: 'msg',
            parseData: (msg) => msg ?? '-'
        },
        {
            header: translate('t.app'),
            accessor: 'app',
            parseData: (app) => app ?? '-'
        }
    ];

    return <JobsLogsTableContent data-testid={`JobsLogsTable-testid-${jobId}`} columns={columns} jobId={jobId} />;
};

export default Wrapper(JobsLogsTable);
