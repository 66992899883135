import { IconButton, List } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UserListContainer = styled.div``;

export const IconButtonContent = styled.div`
    width: 45px;
`;

export const ListContainer = styled(List)`
    max-height: 230px;
    min-width: 218px;
    overflow-y: auto;
`;

export const RowIconButton = styled(IconButton)`
    ${() => {
        const {
            palette: { common }
        } = useTheme() as { palette: { common: CommonTypeTheme } };
        return `font-size: ${common.textSize} !important;`;
    }}
`;
