import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const TyreLifespanComparisonViewContent = styled.div`
    ${applyStyleByMode({
        styleOld: `
    && .MuiCard-root {
        background-color:white !important;
        background: white !important;
    };
    && .MuiCardContent-root {
        background-color:white !important;
        background: white !important;
    }
    && .MuiCardHeader-root {
        background-color:white !important;
        background: white !important;
    }
    `
    })}
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;
