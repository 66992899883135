import styled from 'styled-components';

export const LeakNotificationContainer = styled.div``;

export const ProcessingLabel = styled.div`
    display: flex;
    justify-content: center;
`;

export const EmailNotification = styled.span`
    font-size: 1rem;
`;
