import React from 'react';
import { ProblemsRadiosContainer } from './ProblemsRadios.style';
import { ProblemsRadiosViewProps } from './ProblemsRadios.type';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

const generateRadios = (
    problems: { status: boolean; label: string; name: string }[],
    updateProblemRadios: (type: string) => void
): (JSX.Element | undefined)[] => {
    return problems.map((problem, index) => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={problem.status}
                    onChange={() => {
                        updateProblemRadios(problem.name);
                    }}
                    name={problem.name}
                />
            }
            label={problem.label}
            key={index}
        />
    ));
};

export const ProblemsRadiosContent: React.FC<ProblemsRadiosViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const showAdditionalCheckboxes = (): (JSX.Element | undefined)[] => {
        return props.problemRadios.map((problem, index) => {
            if (problem.hasAction && problem.status) {
                let label: string = translate('t.disable_temperature_reporting');
                let checked: boolean = props.tempActionChecked;
                let onChange: (event: React.ChangeEvent<HTMLInputElement>) => void = props.setTempActionChecked;
                if (problem.name === 'faulty_pressure') {
                    label = translate('t.disable_pressure_reporting');
                    checked = props.pressureActionChecked;
                    onChange = props.setPressureActionChecked;
                }

                return (
                    <FormControlLabel
                        key={index}
                        control={<Checkbox checked={checked} onChange={onChange} name={`${problem.name}_action`} />}
                        label={label}
                    />
                );
            }
        });
    };

    return (
        <ProblemsRadiosContainer data-testid='ProblemsRadiosContent'>
            {generateRadios(props.problemRadios, props.updateProblemRadios)}
            {props.faultyTemp || props.faultyPress ? (
                <Typography color='primary' variant='subtitle1' marginTop={2}>
                    {translate('t.additional_actions')}
                </Typography>
            ) : (
                ''
            )}
            {showAdditionalCheckboxes()}
        </ProblemsRadiosContainer>
    );
};
