import React from 'react';
import { TemperatureExposureWheelReportContainer } from './TemperatureExposureWheelReport.style';
import { TemperatureExposureWheelReportViewProps } from './TemperatureExposureWheelReport.type';
import UiWidget from '../../Ui/Components/UiWidget/UiWidget';
import { useTranslation } from 'react-i18next';
import TemperatureExposureReport from '../TemperatureExposureReport/TemperatureExposureReport';

export const TemperatureExposureWheelReportContent: React.FC<TemperatureExposureWheelReportViewProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <TemperatureExposureWheelReportContainer>
            <UiWidget
                data-testid='TemperatureExposureWheelReportContent'
                id='TemperatureExposureWheelReportContent'
                title={`${translate('t.tyre')} ${props.position}`}
                margin={'0 0 10px 0'}
                height={310}
            >
                <TemperatureExposureReport data={props.data} />
            </UiWidget>
        </TemperatureExposureWheelReportContainer>
    );
};
