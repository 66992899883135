import React from 'react';
import { Circle, LegendLabel } from './ExcessiveAcc.style';
import MapLegend from 'components/Ui/Components/UiMapComponent';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { LoadedStatusAllowed, MapActionData } from 'states/global/Map';
import { useTranslation } from 'react-i18next';
import { getImgSource } from 'helpers/image';
import MapLegendInfoPopover from 'components/Maps/MapLegendInfoPopover';

const excessiveAccRanges = { range: [0.15, 0.2], max: [0.2] };

const ExcessiveAccLegend = () => {
    const { t: translate } = useTranslation();
    const loadedStatusAllowed = useRecoilValue(LoadedStatusAllowed);
    const mapActionData = useRecoilValue(MapActionData);

    return (
        <MapLegend top={loadedStatusAllowed ? '68%' : '76%'} right={loadedStatusAllowed ? '2.8em' : '1.6em'}>
            <LegendLabel>
                {loadedStatusAllowed && <Typography>{translate('t.unloaded')}</Typography>}
                <Typography>
                    <Circle className='yellow'></Circle>
                    {excessiveAccRanges.range[0]}g - {excessiveAccRanges.range[1]}g{' '}
                    {loadedStatusAllowed && <>({mapActionData.loadedCountsExs?.unloaded.below ?? 0})</>}
                </Typography>
                <Typography>
                    <Circle className='red'></Circle>
                    {excessiveAccRanges.max[0]}g +{' '}
                    {loadedStatusAllowed && <>({mapActionData.loadedCountsExs?.unloaded.above ?? 0})</>}
                </Typography>
                {loadedStatusAllowed && (
                    <>
                        <Typography>{translate('t.loaded')}</Typography>
                        <Typography>
                            <img
                                width={15}
                                src={getImgSource('speedPointUnloaded')}
                                alt='Excessive Acc Point Unoaded Icon'
                                style={{ marginRight: '8px' }}
                            />
                            {excessiveAccRanges.range[0]}g - {excessiveAccRanges.range[1]}g (
                            {mapActionData.loadedCountsExs?.loaded.below ?? 0})
                        </Typography>
                        <Typography>
                            <img
                                width={15}
                                src={getImgSource('speedPointLoaded')}
                                alt='Excessive Acc Point Loaded Icon'
                                style={{ marginRight: '8px' }}
                            />
                            {excessiveAccRanges.max[0]}g + ({mapActionData.loadedCountsExs?.loaded.above ?? 0})
                        </Typography>
                    </>
                )}
                {(mapActionData.loadedCountsExs?.total || 0) > 10000 && (
                    <MapLegendInfoPopover
                        message={translate('p.displaying_results', {
                            totalResults: mapActionData.loadedCountsExs?.total
                        })}
                    />
                )}
            </LegendLabel>
        </MapLegend>
    );
};

export default ExcessiveAccLegend;
