import PortalApi from 'helpers/api/PortalApiClient';
import { AppRegionModel } from 'models/AppRegion.type';

export default class AppRegion extends PortalApi<AppRegionModel> {
    route = 'app-region';

    getAllAsCodebook = async (): Promise<AppRegionModel[]> => {
        return await this.getCodebook({ apiProject: undefined });
    };
}
