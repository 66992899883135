import React, { useCallback } from 'react';
import { DiagnoseTable, TableCellValueProps } from './DeviceDiagnoseTable.type';
import { IncorrectValue, TableCellCustom } from './DeviceDiagnoseTable.style';
import { useTranslation } from 'react-i18next';
import { checkErrorsInCMD30 } from 'helpers';

export const TableCellValue: React.FC<TableCellValueProps> = ({
    value,
    totalAccelValue,
    label,
    numberOfSensors
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const translations = useCallback(
        (key) => {
            const trans = {
                id: 'ID',
                requestId: translate('t.request_id'),
                createdAt: translate('t.created_at'),
                region: translate('t.region'),
                gpsTime: `GPS ${translate('t.time')}`,
                gpsSatellites: `GPS ${translate('t.satellites')}`,
                gpsState: `GPS ${translate('t.status')}`,
                gpsLat: `GPS ${translate('t.latitude')}`,
                gpsLon: `GPS ${translate('t.longitude')}`,
                gpsSpeed: `GPS ${translate('t.speed')}`,
                gpsAlt: `GPS ${translate('t.altitude')}`,
                gpsCourse: `GPS ${translate('t.course')}`,
                accelAddr: translate('t.accelerometer_address'),
                accelPort: translate('t.accelerometer_port'),
                accelChipId: translate('t.accelerometer_chip_id'),
                accelX: `${translate('t.accelerometer')} X`,
                accelY: `${translate('t.accelerometer')} Y`,
                accelZ: `${translate('t.accelerometer')} Z`,
                accelVibrations: translate('t.accelerometer_vibrations'),
                voltageVehicle: translate('t.voltage_vehicle'),
                voltageBattery: translate('t.voltage_battery'),
                voltageTemperature: translate('t.processor_temp'),
                baroPressure: translate('t.barometer_pressure'),
                baroTemperature: translate('t.barometer_temperature'),
                sensorAvgRssi: translate('t.sensor_avg_rssi'),
                sensorFirmware: translate('t.sensor_firmware'),
                pos: translate('t.position'),
                serialNumberHex: `${translate('t.sensor_serial_number')} (HEX)`,
                temperature: translate('t.temperature'),
                rssiSecondaryAntenna: translate('t.rssi_secondary_antenna'),
                alertCount: translate('t.alerts_count'),
                gsm: 'GSM',
                gsmIp: 'GSM IP',
                gsmApn: 'GSM APN',
                gsmDns: 'GSM DNS',
                gsmGateway: 'GSM Gateway',
                gsmImsi: 'GSM IMSI',
                gsmCustomMode: 'GSM Custom Mode',
                sensorAvgRssiRem: translate('t.avg_rssi_not_assigned')
            };
            return trans[key] || key;
        },
        [translate]
    );

    const createCellElements = (label: string, value: number | string | null): JSX.Element => {
        return (
            <>
                <IncorrectValue>{translations(label)}</IncorrectValue>
                <IncorrectValue>{value}</IncorrectValue>
            </>
        );
    };

    switch (label) {
        case DiagnoseTable.BARO_PRESSURE:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(
                    translations(label),
                    value ? Number.parseFloat(((+value || 0) / 10).toString()).toFixed(1) : ''
                );
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value !== null && Number.parseFloat(((+value || 0) / 10).toString()).toFixed(1)}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.BARO_TEMPERATURE:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(
                    translations(label),
                    value ? Number.parseFloat(((+value || 0) / 10).toString()).toFixed(1) : ''
                );
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value !== null && Number.parseFloat(((+value || 0) / 10).toString()).toFixed(1)}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.SENSOR_AVG_RSSI_REM:
            if (checkErrorsInCMD30(label, value, numberOfSensors)) {
                return createCellElements(translations(label), value ? +value / 10 : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value && (Number.parseFloat((+value / 10).toString()).toFixed(1) || '')}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.SENSOR_AVG_RSSI:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? +value / 10 : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value && (Number.parseFloat((+value / 10).toString()).toFixed(1) || '')}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.RSSI:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? +value : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value && (Number.parseFloat((+value).toString()).toFixed(1) || '')}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.VOLTAGE_TEMPERATURE:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? (+value || 0) / 10 : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>{value !== null && +value / 10}</TableCellCustom>
                </>
            );
        case DiagnoseTable.VOLTAGE_BATTERY:
        case DiagnoseTable.VOLTAGE_VEHICLE:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(
                    translations(label),
                    value ? Number.parseFloat((+value / 1000).toString()).toFixed(1) ?? '' : ''
                );
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value !== null && (Number.parseFloat((+value / 1000).toString()).toFixed(1) ?? '')}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.GPS_SATELLITES:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? value : '');
            }
            break;
        case DiagnoseTable.ACCEL_X:
        case DiagnoseTable.ACCEL_Y:
        case DiagnoseTable.ACCEL_Z:
            if (checkErrorsInCMD30(label, value) || totalAccelValue) {
                return createCellElements(translations(label), value ? value : '');
            }
            break;
        case DiagnoseTable.ACCEL_VIBRATIONS:
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>
                        {value && (Number.parseFloat((+value / 10).toString()).toFixed(2) || '')}
                    </TableCellCustom>
                </>
            );
        case DiagnoseTable.ALERT_COUNT:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? value : '');
            }
            break;
        case DiagnoseTable.PSI:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? +value / 10 : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>{value && +value / 10}</TableCellCustom>
                </>
            );
        case DiagnoseTable.TEMPERATURE:
            if (checkErrorsInCMD30(label, value)) {
                return createCellElements(translations(label), value ? +value / 10 : '');
            }
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>{value && +value / 10}</TableCellCustom>
                </>
            );
        default:
            return (
                <>
                    <TableCellCustom>
                        <strong>{translations(label)}</strong>
                    </TableCellCustom>
                    <TableCellCustom>{value}</TableCellCustom>
                </>
            );
    }

    return (
        <>
            <TableCellCustom>
                <strong>{translations(label)}</strong>
            </TableCellCustom>
            <TableCellCustom>{value}</TableCellCustom>
        </>
    );
};
