import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const containerFormTrackingSettings = styled.div`
    max-width: 65%;
    margin: 0 auto;
    padding-top: 35px;

    @media only screen and (min-width: 300px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 600px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }

    ${applyStyleByMode({
        styleOld: `
            & .MuiTypography-body1 {
                font-size: 14px;
            }
        `
    })}
`;

export const TrackingSettingsFormContent = styled.div`
    .custom-pointers .react-colorful__pointer {
        width: 16px !important;
        height: 16px !important;
        border-radius: 3px !important;
    }
    .custom-pointers .react-colorful__hue-pointer,
    .custom-pointers .react-colorful__alpha-pointer {
        width: 16px !important;
        border-radius: 3px !important;
    }
`;

export const ContainerButton = styled.div`
    float: right;
    padding-right: 10px;
`;
