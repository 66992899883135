import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import { InitializeTranslations } from 'helpers/translations';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

const siteName = process.env.REACT_APP_SITE;

InitializeTranslations();

if (siteName === 'PROD' || siteName === 'RC' || siteName === 'FUTURE_PROD') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: siteName
    });
}

const container = document.getElementById('content-jade');
if (container !== null) {
    const root = createRoot(container);
    root.render(<App />);
} else {
    console.error('The element with the ID "content-jade" was not found.');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
