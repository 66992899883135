export type RankVehicleDropdownProps = { navigate?; generatePath? };

export type RankVehicleDropdownViewProps = {
    handleVehicleChange: (value, label, fullItem) => void;
    selectedVehicle: { id: number; name: string; icon: string; distribution?: string };
} & RankVehicleDropdownProps;

export type UnconfirmedVehicleType = { id: number; name: string; distribution: string };

export const DefaultUnconfirmedVehicle = {
    id: 0,
    name: '',
    distribution: ''
};
