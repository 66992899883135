import React from 'react';
import { UiIconContent } from './UiIcon.view';
import { UiIconProps } from './UiIcon.type';
import { Wrapper } from 'helpers/wrapper';

const UiIcon: React.FC<UiIconProps> = (props): JSX.Element => {
    return <UiIconContent data-testid={props?.testid || 'UiIcon-testid'} {...props} />;
};

export default Wrapper(UiIcon);
