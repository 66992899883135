import React from 'react';
import { RemovedSensorsTableContent } from './RemovedSensorsTable.view';
import { RemovedSensorsTableProps } from './RemovedSensorsTable.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Menu from 'components/Ui/Components/UiMenu';
import Sensors from 'api/Sensors';
import SensorType from 'api/SensorType';
import SettingsIcon from '@mui/icons-material/Settings';
import { CustomFormControl, Strikethrough } from './RemovedSensorsTable.style';
import { SensorTableResponse } from 'models/Sensors.type';
import { Autocomplete, CircularProgress, IconButton } from '@mui/material';
import CommentUpdate from '../CommentUpdate/CommentUpdate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { Success } from 'components/Popup/Popup';
import { GRAY } from 'components/Ui/colors';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import SensorStatisticsTabManager from '../SensorStatisticsTabManager/SensorStatisticsTabManager';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { TextField } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';
import WheelHistoryTable from 'components/Tyre/WheelHistoryTable/WheelHistoryTable';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { DateTime } from 'luxon';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';

const sensorApi = new Sensors();
const sensorTypeApi = new SensorType();

const RemovedSensorsTable: React.FC<RemovedSensorsTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('removedSensorsPage-RefreshTableAtom'));
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCtoUserTimezone } = useConverter();

    const { data: sensorTypeListData } = useQuery<optionsCodeBook[]>(
        ['SensorTypeListRemovedSensors'],
        sensorTypeApi.getSensorsTypeCodebook,
        {
            refetchOnWindowFocus: false
        }
    );

    const { mutate: restoreSensorMutate, isLoading: restoreSensorLoading } = useMutation(
        sensorApi.patchRestoreUnmounted,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.sensor_restore')
                });
                setRefreshTableAtom(true);
                modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
            }
        }
    );

    const wheelHistoryContent = (id) => {
        const newModal = {
            content: (
                <>
                    <WheelHistoryTable sensorId={id} />
                </>
            ),
            leftTitle: translate('t.wheel_history'),
            cancelColor: GRAY,
            width: 900,
            height: 600,
            buttons: <></>
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    const getStatisticsTabManager = (id: number | undefined, sensorHex: string | undefined) => {
        const newModal = {
            content: <SensorStatisticsTabManager sensorId={id ?? 0} />,
            width: 750,
            height: 350,
            buttons: <></>,
            cancelColor: GRAY,
            leftTitle: translate('t.sensor_statistics'),
            rightTitle: `${translate('t.sensor')} #${sensorHex}`
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () => {
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    });
                    setTimeout(() => {
                        modalDispach({
                            type: ModalActionTypesEnum.SET_MODAL_STATE,
                            ModalPropsPayload: {
                                ...contentModalResolved,
                                isOpen: false,
                                ...newModal
                            }
                        });
                    }, 300);
                },
                isOpen: true
            }
        });
    };

    const restoreSensor = (sensor) => {
        const newModal = {
            content: <>{translate('t.are_you_sure_restore_sensor')}</>,
            leftTitle: translate('t.restore_sensor'),
            buttons: (
                <Button
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        restoreSensorMutate(sensor.id);
                    }}
                >
                    {restoreSensorLoading ? <CircularProgress size='1.75em' /> : translate('t.yes')}
                </Button>
            )
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    const contentModalResolved = {
        id: 'ModalConfrim',
        content: <></>,
        leftTitle: '',
        width: 400,
        height: 60,
        widthUnit: 'px',
        heightUnit: 'px',
        buttons: (
            <Button
                skin='success'
                onClick={() => {
                    // eslint-disable-next-line no-console
                    console.warn('10');
                }}
            >
                {translate('t.remove')}
            </Button>
        ),
        onClose: () =>
            modalDispach({
                type: ModalActionTypesEnum.SET_MODAL_STATE,
                ModalPropsPayload: {
                    ...contentModalResolved,
                    isOpen: false
                }
            }),
        isOpen: true
    };

    const columns: Column<SensorTableResponse['items'][0]>[] = [
        checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
            ? {
                  Header: translate('t.sensor_id'),
                  accessor: 'id',
                  width: 90,
                  height: 20,
                  Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                      <>{props.row.original.id ?? '-'}</>
                  )
              }
            : {
                  Header: '',
                  accessor: 'id',
                  width: 0,
                  height: 0,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: () => <></>
              },

        checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
            ? {
                  Header: `${translate('t.serial_number')}(DEC)`,
                  accessor: 'sensorSerialNumber',
                  width: 120,
                  height: 20,
                  Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                      <>{props.row.original.sensorSerialNumber ?? '-'}</>
                  )
              }
            : {
                  Header: '',
                  accessor: 'sensorSerialNumber',
                  width: 0,
                  height: 0,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: () => <></>
              },
        {
            Header: `${translate('t.serial_number')}`,
            accessor: 'serialNumberHex',
            width: 140,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.serialNumberHex ?? '-'}</>
            )
        },

        {
            Header: translate('t.sensor_type'),
            accessor: (row: SensorTableResponse['items'][0]) => row.sensorType?.name,
            id: 'sensorType.id',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.sensorType?.name ?? '-'}</>
            ),
            Filter: ({ column, setFilter }) => {
                return (
                    <CustomFormControl variant='outlined' size='small'>
                        <Autocomplete
                            options={sensorTypeListData ?? []}
                            getOptionLabel={(option) => (option ? String(option.name) || '' : '')}
                            onChange={(e, newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.sensor_type'),
                                          humanValue: newValue.name,
                                          value: `${newValue.id}`
                                      };

                                setFilter(column.id, value);
                            }}
                            size='small'
                            defaultValue={sensorTypeListData?.find(
                                (element) => element.id === Number(column.filterValue?.value)
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate('t.sensor_type')}
                                    variant='outlined'
                                    name='sensor_type'
                                    value={column.filterValue?.value || ''}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    </CustomFormControl>
                );
            }
        },

        {
            Header: translate('t.battery_voltage'),
            accessor: (row: SensorTableResponse['items'][0]) => row.sensorStats[0].battery,
            id: 'sensorStats.battery',
            width: 85,
            height: 20,
            Cell: (props: { value: string; row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original?.sensorStats?.length > 0 ? props.row.original.sensorStats[0].battery : 0}</>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <>
                        <CustomFormControl variant='outlined' size='small'>
                            <TextField
                                id='outlined-number'
                                label={translate('t.battery_voltage_from')}
                                type='number'
                                size='small'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={
                                    state.filters.filter((filter) => filter.id === 'sensorBatteryVoltageFrom')[0]?.value
                                        .value || null
                                }
                                onChange={(e) => {
                                    setFilter('sensorBatteryVoltageFrom', {
                                        value: e.target.value,
                                        name: translate('t.battery_voltage_from')
                                    });
                                }}
                            />
                        </CustomFormControl>
                        <CustomFormControl variant='outlined' size='small'>
                            <TextField
                                id='outlined-number'
                                label={translate('t.battery_voltage_to')}
                                type='number'
                                size='small'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => {
                                    setFilter('sensorBatteryVoltageTo', {
                                        value: e.target.value,
                                        name: translate('t.battery_voltage_to')
                                    });
                                }}
                            />
                        </CustomFormControl>
                    </>
                );
            }
        },

        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) =>
                fromUTCtoUserTimezone({
                    date: props.row.original.createdAt,
                    format: 'dateTime',
                    displaySeconds: true
                }) != 'Invalid DateTime' ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.createdAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),

            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={`${translate('t.created_at_from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={`${translate('t.created_at_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.last_transmission'),
            accessor: 'measuredAt',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) =>
                fromUTCtoUserTimezone({
                    date: props.row.original.sensorInfo?.measuredAt,
                    format: 'dateTime',
                    displaySeconds: true
                }) != 'Invalid DateTime' ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.sensorInfo?.measuredAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),

            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtFrom'
                            keyFilter='measuredAtFrom'
                            label={`${translate('t.last_transmission')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtTo'
                            keyFilter='measuredAtTo'
                            label={`${translate('t.last_transmission')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.lifetime'),
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                return (
                    <>
                        {`${props.row.original.lifetime?.years} ${translate('t.years')} `}
                        {`${props.row.original.lifetime?.months} ${translate('t.months')} `}
                        {`${props.row.original.lifetime?.days} ${translate('t.days')}`}
                    </>
                );
            }
        },

        {
            Header: translate('t.removed_at'),
            accessor: 'removedAt',
            disableSortBy: true,
            disableFilters: true,
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) =>
                fromUTCtoUserTimezone({
                    date: props.row.original.removedAt,
                    format: 'dateTime',
                    displaySeconds: true
                }) != 'Invalid DateTime' ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.removedAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),

            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='RemovedAtFrom'
                            keyFilter='removedAtFrom'
                            label={`${translate('t.removed_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='RemovedAtTo'
                            keyFilter='removedAtTo'
                            label={`${translate('t.removed_at')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.comment'),
            accessor: 'comment',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.comment ?? '-'}</>
            )
        },

        {
            Header: translate('t.analysis'),
            width: 90,
            height: 20,

            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                if (
                    checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_BASE_USER, ROLES.ROLE_TECHNICAL_USER])
                ) {
                    return <>{props.row.original.analysis ?? '-'}</>;
                }

                return <CommentUpdate analysisId={props.row.original.id} showComment={props.row.original.analysis} />;
            }
        },

        {
            Header: translate('t.removed_by'),
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                return (
                    <>
                        {props.row.original.removedBy ? (
                            props.row.original.removedBy.removed ? (
                                <Strikethrough>
                                    {`${props.row.original.removedBy?.firstname} ${props.row.original.removedBy?.lastname}`}
                                </Strikethrough>
                            ) : (
                                `${props.row.original.removedBy?.firstname} ${props.row.original.removedBy?.lastname}`
                            )
                        ) : (
                            '-'
                        )}
                    </>
                );
            }
        },

        {
            Header: translate('t.actions'),
            //accessor: 'id',
            width: 80,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { value: number; row: { original } }) => (
                <Menu
                    testid={`Menu-Removed-Sensors-${props.row.original.id}`}
                    items={[
                        checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN])
                            ? {
                                  title: translate('t.restore'),
                                  value: props.value,
                                  action: () => {
                                      restoreSensor(props.row.original);
                                  }
                              }
                            : {
                                  title: '',
                                  none: true,
                                  value: ''
                              },
                        {
                            title: translate('t.statistics'),
                            value: props.value,
                            action: () => {
                                getStatisticsTabManager(props.row.original.id, props.row.original.serialNumberHex);
                            }
                        },
                        {
                            title: translate('t.wheel_history'),
                            value: props.value,
                            action: () => {
                                wheelHistoryContent(props.row.original.id);
                            }
                        }
                    ]}
                    rootEl={
                        <IconButton
                            size='small'
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            data-testid={`Action-Button-${props.row.original.id}`}
                        >
                            <SettingsIcon />
                        </IconButton>
                    }
                />
            )
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'removedAtFrom'
        },

        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'removedAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'sensorBatteryVoltageFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'sensorBatteryVoltageTo'
        }
    ];

    return <RemovedSensorsTableContent data-testid='RemovedSensorsTable-testid' columns={columns} />;
};

export default Wrapper(RemovedSensorsTable);
