import React from 'react';
import ProfileTabManager from 'components/User/ProfileTabManager/ProfileTabManager';
import { PaperStyle, ProfileContainer, ProfileWarningMsg } from './Profile.style';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const ProfileContent: React.FC<{}> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const URLvalues = location.search;
    const urlParams = new URLSearchParams(URLvalues);
    const changePswdParam = urlParams.get('change-password');

    return (
        <ProfileContainer data-testid='ProfileContent'>
            {changePswdParam && changePswdParam === 'true' && (
                <ProfileWarningMsg severity='warning'>
                    {translate('p.to_use_webtrack_change_password')}
                </ProfileWarningMsg>
            )}
            <PaperStyle>
                <ProfileTabManager />
            </PaperStyle>
        </ProfileContainer>
    );
};
