import React, { useState } from 'react';
import { UiPopperContent } from './UiPopper.view';
import { UiPopperProps } from './UiPopper.type';
import { Wrapper } from 'helpers/wrapper';

// This component is suitable for displaying legends in modals.
const UiPopper: React.FC<UiPopperProps> = ({ content, titlePopper, rootElemnet, popperPlacementType }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen =
        (): ((event: React.MouseEvent<HTMLButtonElement>) => void) =>
        (event: React.MouseEvent<HTMLButtonElement>): void => {
            setAnchorEl(event.currentTarget);
            setOpen((prev) => !prev);
        };

    const handleClosePopper = (): void => {
        setOpen(false);
    };

    return (
        <UiPopperContent
            data-testid='UiPopper-testid'
            content={content}
            open={open}
            anchorEl={anchorEl}
            handleClickOpen={handleClickOpen}
            handleClosePopper={handleClosePopper}
            titlePopper={titlePopper}
            rootElemnet={rootElemnet}
            popperPlacementType={popperPlacementType}
        />
    );
};

export default Wrapper(UiPopper);
