import styled from 'styled-components';

export const StatisticsGraphsContainer = styled.div``;

export const ZoomBtnCover = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;
