import React from 'react';
import { ButtonDone, ButtonLock, ContainerUpdateDropDown, UpdateDropDownViewContent } from './UpdateDropDown.style';
import { UpdateDropDownViewProps } from './UpdateDropDown.type';
import UiLegend from '../../Ui/Components/UiLegend';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField } from '@mui/material';
import Done from '@mui/icons-material/Done';

export const UpdateDropDownContent: React.FC<UpdateDropDownViewProps> = ({
    user,
    userId,
    propertyName,
    title,
    showLabel,
    colorTextLabel,
    dropDownLabel,
    anchorOriginCustom,
    transformOriginCustom,
    listOptions,
    updateValue,
    loading,
    disabled,
    testid,
    setUpdateValue,
    setAccountUser
}): JSX.Element => {
    return (
        <UpdateDropDownViewContent data-testid={`${testid || 'UpdateDropDown'}-View`}>
            <UiLegend
                title={title}
                content={
                    <ContainerUpdateDropDown>
                        <Autocomplete
                            options={listOptions}
                            getOptionLabel={(lockOption) => lockOption.name}
                            disableClearable={true}
                            onChange={(e, lockOption) => {
                                if (typeof lockOption.value === 'number') {
                                    setUpdateValue(lockOption.value);
                                }
                            }}
                            defaultValue={listOptions.find((lockOption) => lockOption.value === updateValue)}
                            size='small'
                            renderInput={(params) => (
                                <Grid container>
                                    <Grid item xs={9} md={9}>
                                        <TextField
                                            {...params}
                                            label={dropDownLabel}
                                            variant='outlined'
                                            name='updateDropDown'
                                            value={updateValue}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            data-testid={`${testid || 'UpdateDropDown'}-Field`}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3}>
                                        <ButtonDone
                                            onClick={async () => {
                                                if (!disabled) {
                                                    user[propertyName] = updateValue;
                                                    await setAccountUser({ userId, updateData: { ...user } });
                                                }
                                            }}
                                            skin='success'
                                            color='primary'
                                            size='small'
                                            variant='contained'
                                            testid={`${testid || 'UpdateDropDown'}-Button`}
                                            loading={loading}
                                        >
                                            <Done fontSize='inherit' />
                                        </ButtonDone>
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </ContainerUpdateDropDown>
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <ButtonLock
                        disabled={disabled}
                        $colorText={colorTextLabel}
                        color='primary'
                        size='small'
                        data-testid={`${testid || 'UpdateDropDown'}-Button-Lock`}
                    >
                        {showLabel}
                    </ButtonLock>
                }
            />
        </UpdateDropDownViewContent>
    );
};
