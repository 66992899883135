import BuildIcon from '@mui/icons-material/Build';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhoneIcon from '@mui/icons-material/Phone';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import React, { PropsWithChildren } from 'react';

type stateType = {
    hasError: boolean;
};

type propsType = PropsWithChildren;

export default class ErrorBoundary extends React.Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        /* eslint-disable no-console */
        console.error(error);
        console.error(errorInfo);
        /* eslint-enable no-console */
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span>
                        <SentimentVeryDissatisfiedIcon />
                        <NavigateNextIcon />
                        <PhoneIcon />
                        <NavigateNextIcon />
                        <BuildIcon />
                        <NavigateNextIcon />
                        <InsertEmoticonIcon />
                    </span>
                    <p style={{ display: 'block', fontSize: '14.5px', fontWeight: '500', marginBottom: 1 }}>
                        We&apos;re aware of an issue with Webtrack and our team is actively working to fix the problem.
                    </p>
                    <p style={{ display: 'block', fontSize: '14.5px', marginTop: 0 }}>
                        Thank you for your patience, we expect Webtrack to be fully operational shortly.
                    </p>
                    <a
                        style={{ color: '#0B9444', fontWeight: 'unset', textDecoration: 'none', padding: '5px' }}
                        href={`${process.env.PUBLIC_URL}/request-support`}
                        data-testid='webtrack-link-bug-reporter'
                    >
                        Report a Webtrack issue here
                    </a>
                </div>
            );
        }

        return this.props.children;
    }
}
