import React from 'react';
import { ReloadHubsProps } from './ReloadHubs.type';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { ROLES } from 'variables';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { Alert } from '@mui/material';
import { Success } from 'components/Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import Device from 'api/Device';

const deviceApi = new Device();

const ReloadHubs: React.FC<ReloadHubsProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const { setModalState } = useModal();

    const { mutate: reloadHubsMutate } = useMutation(deviceApi.reloadHubs, {
        onSuccess: () => {
            Success({
                text: translate('t.devices_was_successfully_validated')
            });
        },
        onError: (error) => {
            console.error(error);
        }
    });

    const handleReloadHubsModal = () => {
        setModalState({
            leftTitle: translate('t.reload_hubs'),
            isOpen: true,
            width: 450,
            height: 175,
            content: (
                <Alert severity='info'>
                    {translate('p.are_you_sure_to_recharge_hubs', {
                        customerName: userInfo?.user ? userInfo.user.customer.name : ''
                    })}
                </Alert>
            ),
            buttons: (
                <UiButton
                    skin='success'
                    variant='contained'
                    testid='enable-button'
                    onClick={() => {
                        reloadHubsMutate();
                        setModalState({});
                    }}
                >
                    {translate('t.yes')}
                </UiButton>
            )
        });
    };

    return userInfo.user?.role.name === ROLES.ROLE_SUPER_ADMIN ? (
        <UiButton
            color='primary'
            variant='contained'
            testid='ReportSensor-FaultySensorReport-Action'
            onClick={() => {
                handleReloadHubsModal();
            }}
        >
            {translate('t.reload_hubs')}
        </UiButton>
    ) : (
        <></>
    );
};

export default ReloadHubs;
