import React, { useEffect } from 'react';
import { TextBox } from '../TyreManager.style';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { MountDateAtom, TyreStateAtom, UnmountDateAtom } from '../TyreManager.atom';
import useConverter from '../../../CustomHooks/Converter/Converter';
import { CRUD } from 'variables';
import { VARIANT_BUTTON } from '../../../Ui/variables';
import useModal from '../../../CustomHooks/ModalHook';
import UiButton from '../../../Ui/Components/UiButton';
import Tyre from '../../../../api/Tyre';
import { useMutation } from '@tanstack/react-query';
import { FetchVehicleDetailsData } from '../../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';

const tyreAPI = new Tyre();

const ReplaceTyre = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const tyreState = useRecoilValue(TyreStateAtom);
    const mountDate = useRecoilValue(MountDateAtom);
    const unmountDate = useRecoilValue(UnmountDateAtom);
    const { dateTimeFormat, fromTimezoneToUTC } = useConverter();
    const { updateModalState } = useModal(1);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);

    const { mutate } = useMutation(
        ['PostMountTyre'],
        () =>
            tyreAPI.replaceTyre({
                dateMounted: fromTimezoneToUTC(mountDate),
                dateUnmounted: fromTimezoneToUTC(unmountDate),
                newId: tyreState.newTyre.id,
                tyreId: tyreState.originalTyre.id
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    id='replace-tyre'
                    skin={tyreState.state === CRUD.UNMOUNT ? 'error' : 'success'}
                    variant={VARIANT_BUTTON}
                    onClick={() => mutate()}
                >
                    {translate(`t.${tyreState.state}`)}
                </UiButton>
            )
        });
    }, [tyreState.state]);

    return (
        <TextBox>
            <span>
                {`${translate('p.do_you_really_want_replace_tyre')} `}
                <b>{`${tyreState.originalTyre.tyreSerialNumber} `}</b>by
                <b>{` ${tyreState.newTyre.tyreSerialNumber}`}</b>
            </span>
            {tyreState.newTyre.vehicleName ? (
                <>
                    <span>
                        {`${translate('t.mounted_at')} `}
                        <b>{mountDate.toFormat(dateTimeFormat('dateTime', false))}</b>
                    </span>
                    <span>
                        {`${translate('t.unmounted_at')} `}
                        <b>{unmountDate.toFormat(dateTimeFormat('dateTime', false))}</b>
                    </span>
                </>
            ) : (
                <span>
                    {`${translate('t.operation_time')} `}
                    <b>{mountDate.toFormat(dateTimeFormat('dateTime', false))}</b>
                </span>
            )}
        </TextBox>
    );
};

export default ReplaceTyre;
