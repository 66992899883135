import { UpdateDropDownActionType, UpdateDropDownActions, UpdateDropDownState } from './UpdateDropDown.type';

export const UpdateDropDownReducer = (state: UpdateDropDownState, action: UpdateDropDownActionType) => {
    switch (action.type) {
        case UpdateDropDownActions.SET_UPDATE_VALUE:
            return {
                ...state,
                updateValue: action.payload
            };
        default:
            return state;
    }
};
