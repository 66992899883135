import { atom } from 'recoil';

export const PageHelpButtonAtom = atom<{
    article: string;
    section?: string;
}>({
    key: 'url-page-help-button',
    default: {
        article: '',
        section: 'get-started'
    }
});
