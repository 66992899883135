import React from 'react';
import {
    BESBtn,
    ButtonDiv,
    ContainerButtonBES,
    StatisticsBtn,
    StatisticsButtonContainer
} from './StatisticsButton.style';
import { StatisticsButtonProps } from './StatisticsButton.type';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { isVehicleTyreBayHub } from 'helpers';

const StatisticsButton: React.FC<StatisticsButtonProps> = ({ vehicleId }): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    return (
        <StatisticsButtonContainer data-testid='StatisticsButtonContent'>
            {vehicleId &&
                !isVehicleTyreBayHub(vehicleDetailsWidgetAtom?.vehicleDetails?.vehicleConfigurationV2?.id || 0) && (
                    <ButtonDiv>
                        <StatisticsBtn
                            variant='contained'
                            skin='success'
                            size={SIZE_BUTTON}
                            onClick={() => {
                                navigate(`/vehicle/${vehicleId}/statistics`);
                            }}
                            testid='VehicleStatus-LinkToVehicleStatistics-Button'
                        >
                            {translate('t.statistics')}
                        </StatisticsBtn>

                        {customerSettings.mdd_bes && (
                            <ContainerButtonBES>
                                <BESBtn
                                    onClick={() => {
                                        navigate(`/severity-rank/${vehicleId}?back=vehicle-status`);
                                    }}
                                    skin='link'
                                    color='info'
                                    variant={VARIANT_BUTTON}
                                    testid='VehicleStatus-LinkToVehicleBesSeverityRank-Button'
                                >
                                    {translate('t.tyre_severity')}
                                </BESBtn>
                            </ContainerButtonBES>
                        )}
                    </ButtonDiv>
                )}
        </StatisticsButtonContainer>
    );
};

export default StatisticsButton;
