import PortalApi from 'helpers/api/PortalApiClient';
import { ITimeZone } from '../models/TimeZone.type';

export default class TimeZone extends PortalApi<ITimeZone[]> {
    route = 'timezone';

    getTimeZoneList = async (): Promise<ITimeZone[]> => {
        return await this.getCodebook({ apiProject: undefined });
    };
}
