import { atom } from 'recoil';
import { getFromLocalStorage } from 'helpers';

/*type Translation = {
    messages: { [id: string]: string | {} };
    notifications: { [id: string]: string | {} };
    validators: { [id: string]: string | {} };
};

let Dictionary: Translation | undefined = undefined;  */

export const Lang = atom<string>({
    key: 'StateGlobalTranslator-language',
    default: getFromLocalStorage('language') || 'en_GB'
});

/*

// **** Temporary atom until we know why the hook "useRecoilValue" is broken in portal ****
export const TranslationsForLocalStorage = atom<string[]>({
    key: 'TranslationsForLocalStorage-atom',
    default: ['the_following_errors_were_found', 'server_error', 'day', 'days', 'hour', 'hours', 'minute', 'minutes']
});

export const TranslatorData = selector<Translation | undefined>({
    key: 'StateGlobalTranslator-translator',
    get: async ({ get }) => {
        const getLang = get(UserInfo)?.user?.userSetting.language ?? getFromLocalStorage('language');
        const lang = getLang || 'en_US';
        // temp fix, will modified after full migration
        const tempTrans = get(TempTranslator);
        const Trans = tempTrans(lang);
        let response;

        if (!Dictionary && Trans) {
            response = await Trans.get({ apiProject: undefined, extendUrl: lang });
            Dictionary = response;
        }

        return Dictionary;
    }
});

export const Translate = selector({
    key: 'StateGlobalTranslator-translate',
    get: async ({ get }) => {
        const data = await get(TranslatorData);
        const TranslationsList = await get(TranslationsForLocalStorage);

        return (key, params?): string => {
            const path = key.split('.');

            const newTranslationsList: object = {};

            let value = path.reduce((transObj, currentKey) => {
                TranslationsList.map((translation) => {
                    if (!!Object.keys(transObj).length && transObj[translation]) {
                        newTranslationsList[translation] = transObj[translation];
                    }
                });
                if (!transObj) {
                    return '';
                }
                if (transObj[currentKey]) {
                    return transObj[currentKey];
                }
                return path[1] + '.' + currentKey;
            }, data);

            setToLocalStorage('translator', newTranslationsList);

            for (const param in params) {
                value = value.replaceAll(`%${param}%`, params[param]);
            }

            return value;
        };
    }
});

export default Translate; */
