import React from 'react';
import { FaultySensorReportsViewProps } from './FaultySensorReports.type';
import { FaultySensorReportsContainer } from './FaultySensorReports.style';
import UiTable from '../Ui/Components/UiTable2';
import Widget from '../Ui/Components/UiWidget';
import SensorFaultyReport from '../../api/SensorFaultyReport';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import { faUnlink } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

const sensorFaultyAPI = new SensorFaultyReport();

export const FaultySensorReportsContent: React.FC<FaultySensorReportsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <FaultySensorReportsContainer data-testid='FaultySensorReportsContent'>
            <Widget
                title={translate('t.faulty_sensor_reports')}
                avatar={
                    <UiIcon
                        icon={faUnlink}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
            >
                <UiTable
                    columns={props.columns}
                    fetchFn={sensorFaultyAPI.getAll}
                    exportFn={sensorFaultyAPI.getExport}
                    exportName={'Faulty sensor'}
                    defaultSortBy={{
                        id: 'createdAt',
                        desc: true
                    }}
                    defaultFilter={{
                        id: 'verifiedStatus.name',
                        value: {
                            value: `3;${translate('t.open')}`,
                            name: translate('t.report_validity')
                        }
                    }}
                    hiddenColumns={[
                        'verifiedAtFrom',
                        'verifiedAtTo',
                        'createdAtFrom',
                        'createdAtTo',
                        'firstActivationFrom',
                        'firstActivationTo',
                        'lastActivationFrom',
                        'lastActivationTo'
                    ]}
                    queryKey='FaultySensorReports'
                />
            </Widget>
        </FaultySensorReportsContainer>
    );
};
