import React, { useState, useEffect } from 'react';
import { LeakNotificationProps } from './LeakNotification.type';
import { LeakNotificationContent } from './LeakNotification.view';
import FileApi from 'api/File';
import html2canvas from 'html2canvas';
import { Success } from '../Popup';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { CaptureImageTrigger } from 'states/global/FaultyReporter';
import NotificationEvent from 'api/NotificationEvent';
import { Wrapper } from 'helpers/wrapper';

const notificationEventAPI = new NotificationEvent();

const LeakNotification: React.FC<LeakNotificationProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ProcessingStatus = {
        PROCESSING_IMAGE: translate('t.processing_image'),
        UPLOADINF_IMAGE: translate('t.uploading_image'),
        NO_PROCESS: ''
    };
    const [processingStatus, setProcessingStatus] = useState<string>('');
    const File = new FileApi();
    const { fromTimezoneToUTC } = useConverter();
    const captureImageTrigger = useRecoilValue(CaptureImageTrigger);

    const uploadImage = async (image: string): Promise<void> => {
        setProcessingStatus(ProcessingStatus.UPLOADINF_IMAGE);
        const formData = new FormData();
        formData.append('file', image);

        try {
            const ImageXHR = await File.uploadImage(formData);
            reportLeakNotification(ImageXHR.data.file.id);
        } catch (e) {
            false;
        }
    };

    const captureImage = () => {
        setProcessingStatus(ProcessingStatus.PROCESSING_IMAGE);
        if (props.imageContentRef.current) {
            html2canvas(props.imageContentRef.current, {
                removeContainer: true,
                allowTaint: false,
                useCORS: true,
                scale: 0.7
            }).then((canvas) => {
                const data = canvas.toDataURL();
                uploadImage(data);
            });
        }
    };

    const reportLeakNotification = async (imageId: number): Promise<void> => {
        try {
            const params = {
                notificationId: props.leakNotificationType.id,
                fileId: imageId,
                sensorId: props.sensorId,
                dateFrom: fromTimezoneToUTC(props.dateFrom),
                dateTo: fromTimezoneToUTC(props.dateTo)
            };
            await notificationEventAPI.postLeakNotification(params);
            Success({
                text: translate(`t.sensor_${props.leakNotificationType.type}_leak_reported`)
            });
            setProcessingStatus(ProcessingStatus.NO_PROCESS);
            props.onReport && props.onReport();
        } catch (e) {
            false;
        }
    };

    useEffect(() => {
        captureImageTrigger && captureImage();
    }, [captureImageTrigger]);

    return (
        <LeakNotificationContent
            processingStatus={processingStatus}
            leakNotificationType={props.leakNotificationType.type}
            data-testid='LeakNotification-testid'
        />
    );
};

export default Wrapper(LeakNotification);
