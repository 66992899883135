import { Box } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { AlertInfo } from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';

export const SaveAlertsSettingsButtonContainer = styleMui(Box)``;

export const ModalContent = styleMui(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

export const ModalWarning = styleMui(AlertInfo)({
    marginBottom: 10
});

export const AlertChangesInfoContainer = styleMui('div')({
    ['.MuiTypography-root']: {
        fontWeight: 'bolder'
    }
});

export const MainAffectedVehiclesBox = styleMui(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    ['.MuiTypography-root']: {
        fontWeight: 'bolder'
    }
});
