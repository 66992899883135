import React, { useEffect } from 'react';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TrackLogContainer } from './TrackLog.style';
import { faRoad } from '@fortawesome/pro-solid-svg-icons';
import UiWidget from '../../components/Ui/Components/UiWidget';
import DataExport from '../../components/Mixs/DataExport';
import TrackLogAPI from '../../api/TrackLog';
import { TopBarAtom } from '../../components/Mixs/DataExport/TopBar/TopBar.atom';
import { useMutation } from '@tanstack/react-query';
import { Success } from '../../components/Popup';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';

const trackLogAPI = new TrackLogAPI();

const TrackLog: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit, displayUserUnits } = useConverter();
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    const mutation = useMutation<unknown, unknown, void>(
        () =>
            trackLogAPI.getTrackLogCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO()
            ),
        {
            onSuccess: () => {
                Success({ text: translate('p.report_success_message') });
            }
        }
    );

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicle',
            parseData: (props) => props.name
        },
        {
            header: translate('t.date'),
            accessor: 'measuredAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: translate('t.latitude'),
            accessor: 'latitude',
            parseData: (props) => props
        },
        {
            header: translate('t.longitude'),
            accessor: 'longitude',
            parseData: (props) => props
        },
        {
            header: `${translate('t.altitude')} (${displayUserUnits.altitude})`,
            accessor: 'altitude',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.altitude,
                    value: props,
                    displayUnits: false,
                    fixed: 2
                })
        },
        {
            header: translate('t.heading'),
            accessor: 'heading',
            parseData: (props) => props
        },
        {
            header: `${translate('t.speed')} (${displayUserUnits.speed})`,
            accessor: 'speed',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.speed,
                    value: props,
                    displayUnits: false
                })
        },
        {
            header: `${translate('t.distance')} (${displayUserUnits.altitude})`,
            accessor: 'odometer',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.altitude,
                    value: props,
                    displayUnits: false,
                    fixed: 2
                })
        }
    ];

    useEffect(() => {
        setPageHelpButton({ article: 'data-exports-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <TrackLogContainer data-testid='TrackLogContent'>
            <UiWidget
                title={translate('t.track_log_report')}
                id='track-log-widget'
                avatar={
                    <UiIcon
                        icon={faRoad}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={trackLogAPI.getTrackLogReports}
                        downloadFn={mutation.mutate}
                        queryKey='TrackLogReports'
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO()
                        }}
                    />
                }
            />
        </TrackLogContainer>
    );
};

export default Wrapper(TrackLog);
