import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertsFilterVehiclesByViewContent } from './AlertsFilterVehiclesBy.style';
import { AlertsFilterVehiclesByProps } from './AlertsFilterVehiclesBy.type';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import AlertsFilterVehiclesByList from '../AlertsFilterVehiclesByList';
import { RefetchAffectedVehicles } from '../AlertsAffectedVehicles/atom';
import { AlertVehicleFilters, AppliedFilters, ApplyFilterTimeout } from './atom';

const AlertsFilterVehiclesByContent: React.FC<AlertsFilterVehiclesByProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const refetchAffectedVehicles = useRecoilValue(RefetchAffectedVehicles);
    const filters = useRecoilValue(AlertVehicleFilters);
    const setAppliedFilters = useSetRecoilState(AppliedFilters);
    const [filterTimeout, setFilterTimeout] = useRecoilState(ApplyFilterTimeout);

    const updateFilters = (): void => {
        filterTimeout && clearTimeout(filterTimeout);

        setFilterTimeout(
            setTimeout(() => {
                setAppliedFilters(filters);
                refetchAffectedVehicles.refetch && refetchAffectedVehicles.refetch();
            }, 2000)
        );
    };

    useEffect(() => {
        updateFilters();
    }, [filters, refetchAffectedVehicles]);

    return (
        <AlertsFilterVehiclesByViewContent data-testid='AlertsFilterVehiclesByContent'>
            <Grid container xs md lg>
                <Grid item xs={6} md={4} lg={4} height={'30px'} display={'flex'} alignItems={'center'}>
                    <Typography variant='subtitle1'>{`${translate('t.filter_vehicles_by')}:`}</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} marginTop={'5px'} marginRight={'4px'}>
                    <AlertsFilterVehiclesByList />
                </Grid>
            </Grid>
        </AlertsFilterVehiclesByViewContent>
    );
};

export default AlertsFilterVehiclesByContent;
