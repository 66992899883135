import React, { useState } from 'react';
import { AreaEditorContent } from './AreaEditor.view';
import { Wrapper } from 'helpers/wrapper';
import { AreaTabPanelProps } from './AreaEditor.type';

const AreaEditor: React.FC = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState<number>(0);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const TabPanel = (props: AreaTabPanelProps): JSX.Element => {
        const { children, value, index } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && <>{children}</>}
            </div>
        );
    };

    return (
        <AreaEditorContent
            data-testid='AreaEditor-testid'
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            TabPanel={TabPanel}
        />
    );
};

export default Wrapper(AreaEditor);
