import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { WheelAlertsViewContent } from './WheelAlerts.style';
import { TabPanelProps, WheelAlertsViewProps } from './WheelAlerts.type';
import { useTranslation } from 'react-i18next';
import AlertSettings from '../AlertSettings/AlertSettings';
import { Tab, Tabs } from '@mui/material';
import { IconLabel } from 'components/Vehicle/VehicleDetails/VehicleDetails.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import WheelAlertsLogs from '../WheelAlertsLogs';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { RULE_TYPE } from 'models/Rule.type';
import AlertSettingsWizardSaveButton from 'components/Alert/AlertSettingsWizardButtons';
import { isSuperOrAdmin } from 'states/global/User';

export const WheelAlertsContent: React.FC<WheelAlertsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tabValue, setTabValue] = useState<number>(0);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const { updateModalState } = useModal();
    const isAdmin = useRecoilValue(isSuperOrAdmin);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        updateModalState({
            buttons: (
                <AlertSettingsWizardSaveButton
                    disabled={tabValue !== 0}
                    hidden={tabValue !== 0 || !isAdmin}
                    ruleType={RULE_TYPE.CUSTOM}
                    wheelId={vehicleDetailsActionMenuAtom.data?.wheel.id}
                    position={
                        vehicleDetailsActionMenuAtom.data?.wheel
                            ? vehicleDetailsActionMenuAtom.data?.wheel.position
                            : undefined
                    }
                />
            )
        });
    }, [tabValue]);

    const TabPanel = (props: TabPanelProps): JSX.Element => {
        const { children, value, index } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && <>{children}</>}
            </div>
        );
    };

    return (
        <WheelAlertsViewContent data-testid='WheelAlertsContent'>
            <Tabs value={tabValue} onChange={handleChange} aria-label='Alert Setting Tabs'>
                <Tab
                    label={
                        <IconLabel>
                            <UiIcon icon={['fas', 'gear']} fontSize={17} fixedWidth transform='up-0 down-1 left-3' />
                            {translate('t.wheel_alert_level_setting')}
                        </IconLabel>
                    }
                />
                <Tab
                    data-testid='WheelAlertLogs-Tab-Button'
                    label={
                        <IconLabel>
                            <UiIcon
                                icon={['fas', 'triangle-exclamation']}
                                fontSize={17}
                                fixedWidth
                                transform='up-0 down-1 left-3'
                            />
                            {translate('t.wheel_alerts_logs')}
                        </IconLabel>
                    }
                />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <AlertSettings wheelId={props.wheelId} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <WheelAlertsLogs wheelId={props.wheelId} />
            </TabPanel>
        </WheelAlertsViewContent>
    );
};
