import styled from 'styled-components';
import Checkbox from '../Ui/Components/UiCheckbox';

export const HeatmapPlaygroundContainer = styled.div``;

export const ZoomInputConver = styled.div`
    .MuiTextField-root {
        margin-right: 10px;
    }
    margin-bottom: 20px;
`;

export const PlaygroundCover = styled.div`
    > div {
        display: inline-block;
        margin-right: 20px;
        > p {
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
`;

export const InputGroup = styled.div`
    display: inline-block;
    .MuiTextField-root {
        width: 100px;
        display: block;
        margin-bottom: 15px;
    }
    .MuiTextField-root:nth-child(2) {
        width: 88px;
        display: block;
    }
`;

export const CustomCheckbox = styled(Checkbox)`
    $.MuiCheckbox-root {
        margin-left: 8px;
    }
`;
