import PortalApi from 'helpers/api/PortalApiClient';
import { AreaFeaturesResponse, AreaFeaturesResponseData } from 'models/Area.type';
import { getParams } from 'helpers';
import { GetParamsProps } from 'models/Table.type';
import { Paginator } from 'helpers/api/type';

export default class Area extends PortalApi<AreaFeaturesResponse> {
    route = 'area';

    getByIdArea = async (areaId, apiProject = ''): Promise<AreaFeaturesResponseData> => {
        return await this.get({ extendUrl: `${areaId}`, apiProject: apiProject });
    };

    getAreaFeatures = async (): Promise<AreaFeaturesResponse[]> => {
        return await this.get({});
    };

    getAreasTable = async (props: GetParamsProps): Promise<AreaFeaturesResponse[]> => {
        return await this.getByCriteria({ extendUrl: 'list', criteria: getParams(props) });
    };

    getAreasForSelect = async (): Promise<{ items: AreaFeaturesResponse[] }> => {
        return await this.getByCriteria({
            extendUrl: 'list',

            criteria: {
                limit: 20,
                page: 1
            }
        });
    };

    getLazyAreasForSelect = async (): Promise<Paginator<any, AreaFeaturesResponse[]>> => {
        return await this.getByCriteria({
            extendUrl: 'list'
        });
    };

    getExport = async (props): Promise<string> => {
        return this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams(props)
        });
    };
}
