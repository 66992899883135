import React from 'react';
import { HeatmapLegendContainer, HeatmapLegend, HeatmapLegendLabel } from './HeatmapLegend.style';
import { HeatmapLegendProps } from './HeatmapLegend.type';

const HeatmapLegendContent: React.FC<HeatmapLegendProps> = (props): JSX.Element => {
    return (
        <HeatmapLegendContainer data-testid='HeatmapLegendContent'>
            <HeatmapLegend data-testid='legendGradient' />
            <HeatmapLegendLabel>
                <span data-testid='HeatmapLegendValueFrom'>{props.from}</span>
                <span data-testid='HeatmapLegendUnit'>{props.unit ?? ''}</span>
                <span data-testid='HeatmapLegendValueTo'>{props.to}</span>
            </HeatmapLegendLabel>
        </HeatmapLegendContainer>
    );
};

export default HeatmapLegendContent;
