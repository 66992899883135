import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { styled as styleMui } from '@mui/system';

export const AlertsPressureSettingsViewContent = styleMui(Box)({});

export const AlertLabel = styleMui(Typography)({
    marginBottom: '5px'
});

export const AlertsCheckboxConver = styleMui(Grid)({
    display: 'flex',
    alignItems: 'center'
});

export const StyledSkeleton = styleMui(Skeleton)({
    marginLeft: 10
});
