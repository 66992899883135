import { DateTime } from 'luxon';

export type generalConvertTimezoneType = {
    date: Date | DateTime | null | number; // number is temporal
    format: 'dateTime' | 'time' | 'date';
    displaySeconds?: boolean;
    displayIfEmpty?: string;
    customFormat?: string;
    returnObjectLuxon?: boolean;
};

export type ConvertTypeMode = {
    acceleration: ConverterTypeEnum;
    altitude: ConverterTypeEnum;
    distance: ConverterTypeEnum;
    pressure: ConverterTypeEnum;
    speed: ConverterTypeEnum;
    temperature: ConverterTypeEnum;
};

export type UseConverterReturnType = {
    decimalToHex: (value: string) => string;
    fromServerToUserUnit: ({ type, value, fixed, displayUnits, displayIfEmpty }: ConverterFromServerProps) => string;
    fromUserToServerUnit: ({ type, value, fixed }: ConverterFromServerProps) => number;
    hexToDecimal: (value: string) => string;
    convertType: ConvertTypeMode;
    displayUserUnits: {
        acceleration: string;
        altitude: string;
        distance: string;
        pressure: string;
        speed: string;
        temperature: string;
    };
    secondToDateTime: (seconds: number, hideData?: HideData) => string;
    fromMetersToUserUnit?: ({ type, value, displayUnits, fixed, displayIfEmpty }: ConverterFromServerProps) => string;
    fromUTCToTimezone: (utc: string | Date | number, displaySeconds?: boolean, displayIfEmpty?: string) => string;
    dateTimeFormat: (formatType: 'dateTime' | 'date' | 'time', displaySeconds: boolean) => string;
    fromTimezoneToUTC: (
        dateTime: generalConvertTimezoneType['date'],
        displaySeconds?: boolean,
        displayTime?: boolean,
        customFormat?: string
    ) => string;
    fromUTCtoUserTimezone: (dateParams: generalConvertTimezoneType) => string;
    fromUserTimezoneToTimezone: (dateTime: Date | DateTime) => DateTime;
    fromUserTimezonetoUTC: (dateParams: generalConvertTimezoneType) => string;
    userFormatUTC: (dateParams: generalConvertTimezoneType) => string | DateTime;
};

export type ConverterFromServerProps = {
    type: ConverterTypeEnum;
    displayUnits?: boolean;
    displayIfEmpty?: string;
    toType?: ConverterTypeEnum;
} & ConvertProps;

export type ConverterFromUserProps = {
    type: ConverterTypeEnum;
} & ConvertProps;

type ConvertProps = {
    value: number | undefined | null;
    fixed?: number;
};

export enum ConverterTypeEnum {
    ACCELERATION = 'acceleration',
    ALTITUDE = 'altitude',
    DISTANCE = 'distance',
    PRESSURE = 'pressure',
    SPEED = 'speed',
    TEMPERATURE = 'temperature'
}

export type UserSettingsType = {
    acceleration: AccelerationUnits;
    altitude: AltitudeUnits;
    distance: DistanceUnits;
    pressure: PressureUnits;
    speed: SpeedUnits;
    temperature: TemperatureUnits;
};
export enum PressureUnits {
    PSI = 'psi',
    BAR = 'bar',
    KPA = 'kpa'
}
export enum TemperatureUnits {
    CELSIUS = 'c',
    FAHRENHEIT = 'f'
}

export enum AccelerationUnits {
    MS2 = 'm/s2',
    FTS2 = 'ft/s2'
}

export enum AltitudeUnits {
    M = 'm',
    FT = 'ft'
}

export enum DistanceUnits {
    KM = 'km',
    MI = 'mi'
}

export enum SpeedUnits {
    KMH = 'km/h',
    MPH = 'mph'
}

export type HideData = {
    day?: boolean;
    hour?: boolean;
    minute?: boolean;
};
