import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CustomersCodebookQueryType, CustomersPaginationQueryType } from './Customers.type';
import {
    CustomerByIdResponse,
    CustomerCodebook,
    CustomerCodebookResponse,
    CustomerTableModel
} from '../../../models/Customer.type';
import CustomerClient from '../../../api/Customer';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const customerClient = new CustomerClient();

export const GetCustomersPaginationQuery: React.FC<CustomersPaginationQueryType> = ({
    children,
    requestParams
}): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<DefaultRequestPropsType, Error, CustomerTableModel>(
        ['GetCustomersPagination', userInfo.user?.customer.id],
        () => customerClient.getByCriteria({ criteria: requestParams, apiProject: undefined }),
        { refetchOnWindowFocus: false }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetCustomersCodebookQuery: React.FC<CustomersCodebookQueryType> = ({ children }): JSX.Element => {
    const useQueryProperties = useQuery<
        CustomerCodebook[] | CustomerByIdResponse | unknown,
        Error,
        CustomerCodebookResponse
    >(['GetCustomersCodebook'], () => customerClient.getCodeBook(), { refetchOnWindowFocus: false });
    return <>{children(useQueryProperties)}</>;
};

export const GetCustomersByRegionQuery: React.FC<CustomersCodebookQueryType> = ({
    regionId,
    children
}): JSX.Element => {
    const useQueryProperties = useQuery<CustomerTableModel, Error, CustomerCodebookResponse>(
        ['GetCustomersByRegion'],
        () => customerClient.getCustomersByRegion(regionId),
        { refetchOnWindowFocus: false }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetAvailableCustomersCodebookQuery: React.FC<CustomersCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<CustomerCodebookResponse, Error, CustomerCodebookResponse>(
        ['GetAvailableCustomersCodebook', userInfo.user?.id],
        () => customerClient.getAvailableAsCodebook(),
        { refetchOnWindowFocus: false }
    );
    return <>{children(useQueryProperties)}</>;
};
