import React from 'react';
import * as type from './Dot.type';

const DotContent: React.FC<type.DotProps> = (props): JSX.Element => {
    const { cx, cy, payload, stroke } = props;
    if (payload?.showAlienData && props?.showDot) {
        return (
            <svg x={(cx ?? 0) - 3} y={(cy ?? 0) - 3} width={6} height={6} fill={stroke}>
                <g transform='translate(3 3)'>
                    <circle r='2' fill={stroke} />
                </g>
            </svg>
        );
    }
    return <></>;
};

export default DotContent;
