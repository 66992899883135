import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';
import * as style from './UiCircularGraph.style';
import * as type from './UiCircularGraph.type';
// import module

export const UiCircularGraphContent: React.FC<type.UiCircularGraphProps> = (props): JSX.Element => (
    <Box position='relative' display='inline-flex' data-testid='CircularGraphContent'>
        <style.BackgroundCircularProgress variant='determinate' value={100} style={{ width: '60px', height: '60px' }} />
        <CircularProgress
            variant='determinate'
            {...props}
            style={{ position: 'absolute', width: '60px', height: '60px' }}
        />
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position='absolute'
            display='flex'
            alignItems='center'
            justifyContent='center'
        >
            <Typography variant='caption' component='div' color='textSecondary' style={{ fontSize: '0.9rem' }}>{`${
                props.value ? Math.round(props.value) : 0
            }%`}</Typography>
        </Box>
    </Box>
);
