import React from 'react';
import { VehicleDetailsSettingsViewProps } from './VehicleDetailsSettings.type';
import {
    CustomAlert,
    CustomFormControlLabel,
    InsideDiv,
    OutsideDiv,
    SaveButton,
    VehicleDetailsSettingsViewContent
} from './VehicleDetailsSettings.style';

import { Grid, Switch } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiButton from '../../Ui/Components/UiButton/UiButton';

import { ConfirmationPopupState } from '../../../states/component/ConfirmationPopup';
import { ShowConfirmationPopupModal } from '../../../states/global/Modal';
import ConfirmationPopup from '../../ConfirmationPopup/ConfirmationPopup';
import { VARIANT_BUTTON } from '../../Ui/variables';
import { VehicleDetailsSettingFormik } from './VehicleDetailsSettings.formik';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const VehicleDetailsSettingsContent: React.FC<VehicleDetailsSettingsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const confirmationPopup = useSetRecoilState(ConfirmationPopupState);
    const showConfirmationPopup = useSetRecoilState(ShowConfirmationPopupModal);

    return (
        <VehicleDetailsSettingsViewContent data-testid='VehicleDetailsSettingsContent'>
            {props.isLoading ? (
                <UiLoadingPage size='30px' $marginTop='30vh' />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} textAlign='center'>
                            <CustomFormControlLabel
                                value='top'
                                control={
                                    <Switch
                                        checked={props.switchOutOfService}
                                        data-testid='SettingsTab-OutOfService-Switch'
                                        onChange={(event) => {
                                            let checked = event.target.checked;
                                            if (!props.switchOutOfService) {
                                                confirmationPopup({
                                                    title: translate('t.vehicle_settings_service_off_confirm_title'),
                                                    body: (
                                                        <OutsideDiv>
                                                            <InsideDiv>
                                                                <CustomAlert severity='warning'>
                                                                    {translate(
                                                                        't.vehicle_settings_service_off_confirm_message'
                                                                    )}
                                                                </CustomAlert>
                                                            </InsideDiv>
                                                        </OutsideDiv>
                                                    ),
                                                    buttons: (
                                                        <UiButton
                                                            skin='success'
                                                            color='primary'
                                                            variant={VARIANT_BUTTON}
                                                            onClick={() => {
                                                                props.handleChangeSwitch(checked, 'outOfService');
                                                                showConfirmationPopup(false);
                                                            }}
                                                            testid='SettingsTab-YesOutOfService-Button'
                                                        >
                                                            {translate('t.yes')}
                                                        </UiButton>
                                                    )
                                                });
                                                showConfirmationPopup(true);
                                            } else {
                                                props.handleChangeSwitch(checked, 'outOfService');
                                            }
                                        }}
                                        name='out-of-service'
                                        color='primary'
                                    />
                                }
                                label={translate('t.out_of_service')}
                                labelPlacement='top'
                            />
                            <CustomFormControlLabel
                                value='top'
                                control={
                                    <Switch
                                        checked={props.switchMaintenance}
                                        onChange={(event) => {
                                            props.handleChangeSwitch(event.target.checked, 'maintenance');
                                        }}
                                        name='maintenance'
                                        color='primary'
                                        data-testid='SettingsTab-Maintenance-Switch'
                                    />
                                }
                                label={translate('t.maintenance')}
                                labelPlacement='top'
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12} xl={12}>
                            <VehicleDetailsSettingFormik
                                initialFormValues={props.initialFormValues}
                                handleSubmit={props.handleSubmit}
                            />
                        </Grid>
                    </Grid>
                    <SaveButton>
                        <UiButton
                            skin='success'
                            color='primary'
                            variant={VARIANT_BUTTON}
                            form='vehicle-details-settings'
                            type='submit'
                            testid='SettingsTab-SaveForm-Button'
                        >
                            {translate('t.save')}
                        </UiButton>
                    </SaveButton>
                </>
            )}
            <ConfirmationPopup />
        </VehicleDetailsSettingsViewContent>
    );
};
