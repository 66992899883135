import React from 'react';
import { useTranslation } from 'react-i18next';
import { LegendItemsProps } from '../SystemTotals.type';
import { TableLegend } from '../SystemTotals.style';

const LegendItem = ({ title, text }: LegendItemsProps) => (
    <tr>
        <td>
            <b>{title}</b>
        </td>
        <td>{text}</td>
    </tr>
);

const SystemTotalsLegendView = () => {
    const { t: translate } = useTranslation();

    return (
        <TableLegend className='legend-table'>
            <tbody>
                <LegendItem
                    title={translate('t.transmitting_vehicles')}
                    text={translate('p.all_vehicles_reported_24h')}
                />
                <LegendItem
                    title={translate('t.total_vehicles')}
                    text={translate('p.all_vehicles_in_service_with_hub')}
                />
                <LegendItem
                    title={translate('t.transmitting_sensors')}
                    text={translate('p.all_sensors_reported_24h')}
                />
                <LegendItem title={translate('t.fitted_sensors')} text={translate('p.all_sensors_fitted_wheel')} />
                <LegendItem
                    title={translate('t.internal_sensor')}
                    text={translate('p.all_sensors_fitted_internally')}
                />
                <LegendItem
                    title={translate('t.external_sensor')}
                    text={translate('p.all_sensors_fitted_externally')}
                />
                <LegendItem title={translate('t.out_of_service')} text={translate('p.out_of_service_message')} />
                <LegendItem title='' text={translate('p.out_of_reach_message')} />
            </tbody>
        </TableLegend>
    );
};

export default SystemTotalsLegendView;
