import styled from 'styled-components';
import { PRIMARY } from '../../Ui/colors';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import QRCode from 'react-qr-code';
import { InputBase } from '@mui/material';

export const ShowWifiPasswordContainer = styled.div``;

export const CustomTextfield = styled(InputBase)`
    padding-left: 0px !important;

    input {
        padding: 1px 0px;
        text-align: left;
        cursor: pointer;
        width: 250px !important;
    }
`;

export const CustomButton = styled(UiButton)`
    &.MuiButtonBase-root {
        min-width: 40px;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0px;
    color: ${PRIMARY} !important;
`;

export const TextTitle = styled(Typography)`
    &.MuiTypography-root {
        font-weight: bold !important;
    }
`;

export const Text = styled(Typography)``;

export const CustomQR = styled(QRCode)`
    justify-self: center;
    height: 100%;
`;

export const GridItems = styled(Grid)`
    margin-bottom: 20px !important;
    height: 100%;
`;

export const GridContainer = styled(Grid)`
    margin-top: 30px;
    align-items: center;
    div {
        & .MuiTypography-root {
            margin: 10px 0 !important;
            font-size: 16px !important;
        }
    }
    div:nth-child(1) {
        text-align: center;
        border-right: 1px solid ${PRIMARY};
    }
    div:nth-child(2) {
        padding-left: 20px;

        button {
            font-size: 13px;
            max-width: 100px !important;
        }
    }
`;

export const NoDataContainer = styled.div`
    padding-top: 180px;
    text-align: center;
`;

export const ContainerQr = styled.div`
    background: #fff;
    padding: 0px;
    width: 60vw;
    text-align: center;
    display: contents;
`;

export const ContentQr = styled.div`
    background: #fff;
    padding: 10px 18px;
    display: inline-block;
`;
