import { DeviceSerialConnectionTypeList, DeviceModel } from '../models/Device.type';
import PortalApiClient from 'helpers/api/PortalApiClient';

export default class DeviceSerialConnectionType extends PortalApiClient<DeviceModel> {
    route = 'device-serial-connection-type';

    getDeviceSerialConnectionType = async (): Promise<DeviceSerialConnectionTypeList> => {
        return await this.get({ extendUrl: 'enabled/codebook' });
    };
}
