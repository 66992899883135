import { AppBar, Toolbar, Typography, useTheme } from '@mui/material';
import { COLOR_GREY_2, PRIMARY, WHITE } from '../Ui/colors';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { ThemeMode } from '../../states/global/Theme';

export const TopBar = styled(AppBar)`
    &.MuiAppBar-colorPrimary {
        background: ${() => useTheme().palette.background.paper};
        position: fixed;
        box-shadow: none;
    }
`;

export const Bar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
    color: ${() => useTheme().palette.text.primary};
    padding: 0 24px 2px 5px !important;

    @media (min-width: 0px) and (max-width: 374px) {
        padding: 0 !important;
    }
`;

export const Title = styled(Typography)``;

export const LogoContainer = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    & img {
        width: 180px;
        max-width: 180px;
        border-radius: 5px;
        max-height: 50px;
        margin: auto;
    }
`;

export const LeftBar = styled.div<{ $collapsedSidebar: boolean }>`
    height: 100% !important;
    display: flex;
    align-items: center;
`;

export const RightBar = styled.div`
    height: 100% !important;
    width: 100%;
    display: flex;
    align-items: center;
    float: right;
`;

export const RightBarContainer = styled.div`
    width: 100%;
`;

export const RightBarLeft = styled.div`
    padding-top: 4px;
    align-items: center;
    display: flex;
    float: left;
`;

export const RightBarRight = styled.div`
    align-items: center;
    display: flex;
    padding-top: 4px;
    float: right;
`;

export const BarGroup = styled.div`
    height: 45px;
    margin-right: 8px;
    margin-left: 5px;
    .AmbientValue {
        color: ${PRIMARY} !important;
    }
    button {
        margin: 0 3px;
        padding: 0;
        height: 45px;
    }
    button:hover {
        background: none;
        color: ${() => useTheme().palette.primary.main};
        transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
    }
    .MuiButton-label {
        height: 100%;
    }
    p {
        margin: 0;
        padding: 0 5px;
        text-transform: none;
        ${() => {
            if (useTheme().palette.mode === ThemeMode.dark) return 'color: #fff;';
        }}
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        margin-right: 0px;
    }
`;

export const BarGroupInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-right: thin solid ${() => useTheme().palette.grey[500]};
    text-transform: capitalize;

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        border-right: none;
        margin-top: 2px;
        margin-left: -8px;
    }
`;

export const BarGroupIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
    color: ${COLOR_GREY_2};

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        svg {
            font-size: 20px;
        }
    }
`;

export const BarGroupLogo = styled.div`
    margin-right: 5px;
    background: ${WHITE};
    border-radius: 4px;
    height: 85%;
    display: flex !important;
    align-self: center;
    overflow: hidden;

    & img {
        height: fit-content;
        align-self: center;
        border-radius: 4px;
    }
`;

export const ButtonIcon = styled.div`
    margin-right: 3px;
    button {
        padding: 5px;
        margin: 0 3px;
        padding: 6px;
    }
    button:hover {
        color: ${() => useTheme().palette.primary.main};
        transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
    }
`;

export const NewAlertIcon = styled(IconButton)`
    @keyframes opacityChange {
        0% {
            background: ${() => useTheme().palette.primary.main};
        }
        50% {
            background: ${() => useTheme().palette.secondary.main};
        }
        100% {
            opacity: ${() => useTheme().palette.primary.main};
        }
    }

    color: #fff !important;
    background: #b5002a;
    animation-name: opacityChange;
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

export const IconButtonMobile = styled(IconButton)`
    margin-right: 0px;
    margin-left: 1px;
    display: none;

    @media (max-width: 899px) {
        display: block;
    }
`;
