import PortalApiClient from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { SensorHistory as SensorHistoryModel, SensorHistoryWithPaginator } from '../models/SensorHistory.type';

export default class SensorHistory extends PortalApiClient<SensorHistoryModel> {
    route = 'sensor-history';

    getSensorsHistory = async (props, additionalProps): Promise<SensorHistoryWithPaginator> => {
        return await this.getTable({
            extendUrl: `sensor/${additionalProps.sensorId}`,
            criteria: getParams(props)
        });
    };

    exportSensorWheelHistory = async (props, additionalProps): Promise<string> => {
        return await this.getByCriteria({
            extendUrl: `sensor/${additionalProps.sensorId}/export`,
            criteria: getParams(props)
        });
    };

    exportSensorWheelHistoryByIdSensors = async (sensorIds: number[]): Promise<string> => {
        let sensorIdsdParam = '?';

        sensorIds.map((sensor, index) => {
            sensorIdsdParam += `sensor[]=${sensor}${index < sensorIds.length && '&'}`;
        });

        return await this.getByCriteria({
            extendUrl: `sensor/export${sensorIdsdParam}`
        });
    };
}
