import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { BarGroup } from '../TopBar.style';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ChooseLanguageButtonViewContent = styled.div``;

export const BarGroupLanguage = styled(BarGroup)`
    margin-right: 0px;

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `min-width: ${common.bigFonts ? '50ox' : '133px'} ;`;
    }}

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        min-width: auto;

        & .MuiButton-root {
            min-width: 45px !important;
        }
    }
`;
