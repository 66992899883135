import { Paper } from '@mui/material';
import { WHITE } from 'components/Ui/colors';
import styled from 'styled-components';

export const ReportIssueViewContent = styled.div`
    p {
        font-size: 0.9rem;
        margin: 15px 0px;
    }
    .logo {
        width: 40vh;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
`;

export const ReportIssuePaper = styled(Paper)`
    width: 60%;
    margin: 0 auto;
    padding: 15px 30px;
    margin-top: 20px;
    margin-bottom: 90px;
`;

export const Footer = styled.div`
    background-color: ${WHITE};
    padding: 15px 0%;
    text-align: center;
`;
