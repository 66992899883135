import { Box } from '@mui/material';
import { styled } from '@mui/system';
import {
    UiHighPressureIcon,
    UiLowPressureIcon
} from '../VehicleStats/Components/VehicleStatsRow/VehicleStatsRow.style';

export const VehicleStatsLegendContainer = styled(Box)``;

export const CustomizedUiLowPressureIcon = styled(UiLowPressureIcon)`
    width: 17px !important;
    height: 17px !important;
`;

export const CustomizedUiHighPressureIcon = styled(UiHighPressureIcon)`
    width: 17px !important;
    height: 17px !important;
`;

export const PressueIconCover = styled(Box)`
    padding-right: 7px !important;
    margin-left: -2px !important;
`;

export const TemperatureGenericDisabled = styled(Box)`
    padding-right: 9px !important;
    margin-left: -5px !important;
`;

export const OldTemperatureIcon = styled('span')`
    width: 47px !important;
    margin-left: -13px;
    margin-top: 3px;
`;
