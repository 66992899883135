import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertInput, AlertSettingInputViewContent } from './AlertSettingInput.style';
import { AlertSettingInputProps } from './AlertSettingInput.type';
import { InputAdornment, Tooltip, useTheme } from '@mui/material';
import {
    CustomAlertsLevelCheckbox,
    HotPressureConsts,
    PressureLevelsSelector,
    pressureDecimals
} from '../AlertSettings/atom';
import { AlertLevelEnum } from 'models/Alerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { UserInfo, isSuperOrAdmin } from 'states/global/User';
import { useTranslation } from 'react-i18next';

const AlertSettingInputContent: React.FC<AlertSettingInputProps> = (props): JSX.Element => {
    const [levelState, setLevelState] = useRecoilState(PressureLevelsSelector(props.level));
    const setHighHotPressure = useSetRecoilState(PressureLevelsSelector(props.level + 10));
    const hotPressureConsts = useRecoilValue(HotPressureConsts);
    const hasCustomAlerts = useRecoilValue(CustomAlertsLevelCheckbox);
    const { displayUserUnits } = useConverter();
    const theme = useTheme();
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const isAdmin = useRecoilValue(isSuperOrAdmin);

    const delta = useMemo(() => {
        const fixed = pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi'];
        return 1 / Math.pow(10, fixed);
    }, [userInfo.user?.userSetting.pressureUnit]);

    const title = useMemo(() => {
        let indicator = 'below';
        if (levelState.value === '') {
            indicator = 'above';
        } else {
            if (typeof levelState.adjustToValue !== 'undefined') {
                if (+levelState.value >= levelState.adjustToValue) {
                    indicator = 'below';
                } else if (+levelState.value <= levelState.adjustToValue) {
                    indicator = 'above';
                }
            }
        }
        return translate(`t.value_needs_to_be_or_${indicator}`, { value: levelState.adjustToValue });
    }, [levelState.adjustToValue, levelState.value, props.level]);

    return (
        <AlertSettingInputViewContent data-testid='AlertSettingInputContent'>
            <Tooltip open={levelState.percentageError} title={title} arrow>
                <AlertInput
                    id={`alert-level${props.level}-pressure-input-percentage`}
                    data-testid={`alert-level${props.level}-pressure-input-percentage`}
                    type='number'
                    size='small'
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        inputProps: {
                            min: 0,
                            step: 0.1
                        },
                        readOnly: !isAdmin
                    }}
                    fullWidth
                    theme={theme}
                    error={levelState.percentageError}
                    value={levelState.percentage}
                    onChange={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            percentage: e.target.value,
                            action: AlertLevelEnum.VALIDATE_PERCENTAGE
                        }));
                        if (props.level > 0 && !hasCustomAlerts) {
                            setHighHotPressure((current) => ({
                                ...current,
                                ambientPressure: hotPressureConsts.ambientPressure,
                                hotPressure: hotPressureConsts.hotPressure,
                                ambientTemperature: hotPressureConsts.ambientTemperature,
                                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
                            }));
                        }
                    }}
                    onBlur={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            percentage: e.target.value,
                            action: AlertLevelEnum.ADJUST_PERCENTAGE
                        }));
                    }}
                />
            </Tooltip>
            <Tooltip open={levelState.valueError} title={title} arrow>
                <AlertInput
                    id={`alert-level${props.level}-pressure-input-value`}
                    data-testid={`alert-level${props.level}-pressure-input-value`}
                    type='number'
                    size='small'
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>{displayUserUnits.pressure}</InputAdornment>,
                        inputProps: {
                            min: 0,
                            step: delta
                        },
                        readOnly: !isAdmin
                    }}
                    theme={theme}
                    error={levelState.valueError}
                    value={levelState.value}
                    onBlur={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            value: e.target.value,
                            action: AlertLevelEnum.ADJUST_VALUE
                        }));
                    }}
                    onChange={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            value: e.target.value,
                            action: AlertLevelEnum.VALIDATE_VALUE
                        }));
                        if (props.level > 0 && !hasCustomAlerts) {
                            setHighHotPressure((current) => ({
                                ...current,
                                ambientPressure: hotPressureConsts.ambientPressure,
                                hotPressure: hotPressureConsts.hotPressure,
                                ambientTemperature: hotPressureConsts.ambientTemperature,
                                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
                            }));
                        }
                    }}
                />
            </Tooltip>
        </AlertSettingInputViewContent>
    );
};

export default AlertSettingInputContent;
