import styled from 'styled-components';
import { Tab, Tabs, useTheme } from '@mui/material';

export const ContainerSchemmaVehicleStatus = styled.div`
    align-content: center;
    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        && .MainContainerSchemma {
            height: 200px;
            display: flex;
            justify-content: center;
        }
    }
`;

export const SchemaMapTabs = styled(Tabs)`
    @media (max-width: 599px) {
        width: 300px;
    }
`;

export const IconLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const SchemaMapTab = styled(Tab)<{ $hide?: boolean; $badge?: boolean }>`
    &.MuiButtonBase-root {
        ${(props) => (props.$hide ? 'display: none !important;' : ' display: flex')}
        flex-flow: row wrap;
        flex-direction: row-reverse !important;
        ${({ $badge }) => {
            return `
                    &.MuiTab-root {
                        padding: ${$badge ? '0px 28px 0px 15px' : '0px 15px'};
                    }

                    & .MuiTab-wrapper {
                        flex-direction: row;
                        align-items: end;
                        svg {
                            margin-right: 3px;
                        }
                    }
                `;
        }}
    }
`;
