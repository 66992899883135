import React, { useMemo } from 'react';
import { Tyre } from './Tyre';
import { FrontAxelType } from './VehicleSchema.type';
import { AxelSelector } from './AxelSelector';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import { getClickableAxelColor } from 'helpers';

export const FrontAxel: React.FC<FrontAxelType> = ({
    width,
    height,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    highlightWheels,
    highlightColor
}): JSX.Element => {
    const theme = useRecoilValue(Theme);
    const allPositions = [1, 2];
    const clickableColor = useMemo(() => {
        return getClickableAxelColor(!!allowSelectTyre, theme);
    }, [allowSelectTyre, theme.mode]);

    return (
        <svg id='front_axel'>
            <g>
                <svg viewBox={`0 0 ${width} ${height}`} id='front_axel_core'>
                    <rect x='36' y='28' width='21' height='5' fill={clickableColor} />
                    <rect x='58' y='26' width='7' height='9' fill={clickableColor} />
                    <polygon points='66,25 106,25 106,36 91,36 91,46 81,46 81,36 66,36' fill={clickableColor} />
                    <rect x='108' y='26' width='7' height='9' fill={clickableColor} />
                    <rect x='116' y='28' width='21' height='5' fill={clickableColor} />
                    <rect x='82.5' y='47.5' width='7' height='60' />
                    {allowSelectTyre && <AxelSelector allTyrePosition={allPositions} isFront />}
                </svg>
                <Tyre
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    viewBox={`-15 0 ${width} ${height}`}
                    front={true}
                    position={1}
                    color={
                        highlightWheels && highlightWheels[1] ? highlightWheels[1]?.color || highlightColor : undefined
                    }
                    vehicleAtomId={vehicleAtomId}
                />
                <Tyre
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    viewBox={`-147 0 ${width} ${height}`}
                    front={true}
                    position={2}
                    color={
                        highlightWheels && highlightWheels[2] ? highlightWheels[2]?.color || highlightColor : undefined
                    }
                    vehicleAtomId={vehicleAtomId}
                />
            </g>
        </svg>
    );
};
