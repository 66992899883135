import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { UiTableRowCheckboxViewContent } from './UiTableRowCheckbox.style';
import { UiTableRowCheckboxProps } from './UiTableRowCheckbox.type';
import { SelectRow, SelectedRowDispatcher, SelectedRowEnum } from '../UiTable/TableAtom';
import { Checkbox } from '@mui/material';

const UiTableRowCheckboxContent: React.FC<UiTableRowCheckboxProps> = (props): JSX.Element => {
    const [selectRow, setSelectRow] = useRecoilState(SelectRow);
    const selectRowDispatch = SelectedRowDispatcher(selectRow, setSelectRow);

    const checked = useMemo(() => {
        const isSelected = selectRow.selectedRows?.filter((row) => row.id === props.rowData.id);
        return isSelected?.length ? !selectRow.selectedAll : selectRow.selectedAll;
    }, [selectRow]);

    return (
        <UiTableRowCheckboxViewContent
            {...props.getCellProps()}
            data-testid={`checkboxCellColumn${props.columnIndex}Row${props.rowIndex}`}
        >
            <Checkbox
                checked={checked}
                onChange={(e) => {
                    selectRowDispatch({
                        type: SelectedRowEnum.SET_SELECT,
                        payload: {
                            rowData: props.rowData,
                            added: selectRow.selectedAll ? !e.target.checked : e.target.checked
                        }
                    });
                }}
            />
        </UiTableRowCheckboxViewContent>
    );
};

export default UiTableRowCheckboxContent;
