import { styled as styleMui } from '@mui/system';
import { StyledAutocompleteListProps, StyledSelectCoverProps } from './LazyloadingAutocomplete.type';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { MenuItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { applyStyleByMode } from 'helpers';
const COVER_PADDING = 0;
const DEFAULT_WIDTH = 200;

export const AutocompleteCover = styleMui(Box)((props: StyledSelectCoverProps) => ({
    padding: COVER_PADDING,
    position: 'relative',
    width: props.fullWidth ? '100%' : props.width || DEFAULT_WIDTH,
    margin: props.customMargin || 'auto'
}));

export const AutocompleteList = styleMui(Paper)((props: StyledAutocompleteListProps) => ({
    position: 'absolute',
    top: props.top + COVER_PADDING,
    left: props.left + COVER_PADDING,
    right: COVER_PADDING,
    zIndex: 1400,
    height: 250,
    width: 'fit-content',
    minWidth: props.width || DEFAULT_WIDTH,
    overflowY: 'auto',
    overflowX: 'hidden'
}));

export const AutocompleteListUl = styleMui(Box)({
    marginTop: 7,
    padding: 0
});

export const AutocompleteListMenuItem = styleMui(MenuItem)({
    padding: '6px 16px'
});

export const NoDataMsg = styleMui(Typography)({
    padding: '6px 16px'
});

export const GroupHeader = styled('div')`
    background-color: #939393;
    padding-left: 10px;
    height: 20px;
    display: flex;
    font-weight: 600;
    align-items: center;
    color: #fff;
`;

export const ClearIconButton = styled(IconButton)`
    ${applyStyleByMode({
        styleJade: `&&.MuiIconButton-root {
            position: absolute;
            z-index: 9999;
            top: 2.3px;
            right: 31px;
        }`,
        styleOld: `
        &&.MuiIconButton-root {
            position: absolute;
            z-index: 9999;
            top: 4.3px;
            right: 31px;
            font-size: 1.2rem;
        }
        `
    })}
`;

export const InputCover = styleMui('fieldset')({
    border: 'solid thin rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '5px 5px',
    position: 'relative'
});

export const CustomInputLabel = styleMui('legend')({
    transform: 'translate(-5px, 0px) scale(0.78)',
    fontSize: '0.96rem',
    color: 'rgba(0, 0, 0, 0.6)'
});
