import React, { useReducer } from 'react';
import { SensorStatisticsTabManagerContent } from './SensorStatisticsTabManager.view';
import { SensorStatisticsTabManagerProps } from './SensorStatisticsTabManager.type';
import { deepCopyObj } from 'helpers';
import { Wrapper } from 'helpers/wrapper';

const CollapseWidget = (
    state: {},
    action: {
        type: string;
        collapse?: boolean;
        collapsedWidget?: { [id: string]: { isSecondary: boolean; collapsed: boolean } };
        widgetId?: string;
    }
) => {
    switch (action.type) {
        case 'setCollapse':
            state = action.collapsedWidget as { [id: string]: { isSecondary: boolean; collapsed: boolean } };
            return state;
        case 'updateSecondary': {
            let newObj = deepCopyObj(state);
            Object.keys(newObj)
                .filter((collapse) => {
                    return newObj[collapse].isSecondary && newObj[collapse].collapse !== action.collapse;
                })
                .map((collapse) => {
                    newObj[collapse] = { ...newObj[collapse], collapsed: action.collapse };
                });
            return newObj;
        }
        case 'updateAll': {
            let newObj = deepCopyObj(state);
            Object.keys(newObj)
                .filter((collapse) => {
                    return newObj[collapse].collapse !== action.collapse;
                })
                .map((collapse) => {
                    newObj[collapse] = { ...newObj[collapse], collapsed: action.collapse };
                });
            return newObj;
        }
        case 'updateWidget':
            Object.keys(state)
                .filter((collapse) => {
                    return collapse === action.widgetId;
                })
                .map((collapse) => {
                    state[collapse] = { ...state[collapse], collapsed: action.collapse };
                });
            return state;
        default:
            throw new Error();
    }
};

const SensorStatisticsTabManager: React.FC<SensorStatisticsTabManagerProps> = (props): JSX.Element => {
    const [collapsed, setCollapsed] = useReducer(CollapseWidget, {});

    return (
        <SensorStatisticsTabManagerContent
            data-testid='SensorStatisticsTabManager-testid'
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            sensorId={props.sensorId}
        />
    );
};

export default Wrapper(SensorStatisticsTabManager);
