import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MountDateAtom, TyreStateAtom } from '../TyreManager.atom';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../Ui/Components/UiDatePicker/UiDatePicker';
import { CRUD } from 'variables';

const MountDate = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mountDate, setMountDate] = useRecoilState(MountDateAtom);
    const tyreState = useRecoilValue(TyreStateAtom);

    return (
        <>
            {tyreState.state !== CRUD.UNMOUNT && (
                <DatePicker
                    value={mountDate}
                    onDateChange={(newData) => setMountDate(newData)}
                    label={translate(`t.${tyreState.newTyre.vehicleName ? 'mounted_at' : 'action_date'}`)}
                    noMargin={true}
                    datetest-id='mount-date-picker'
                    testId='TyreManager-MountedAt-DatePicker'
                />
            )}
        </>
    );
};

export default MountDate;
