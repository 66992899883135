import React, { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import UiButton from 'components/Ui/Components/UiButton';
import useConverter from '../../CustomHooks/Converter/Converter';
import { ButtonHolder, Container, FirstItem, LastItem, MiddleItem } from './CounterAction.style';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_GREY_4 } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { CounterActionViewType } from './CounterAction.type';
import { useTranslation } from 'react-i18next';

const CounterActionView = ({
    data,
    queryIsLoading,
    mutationIsloading,
    onSaveIsClicked,
    onCounterActionIsSelected,
    selectedOptions,
    counterActionIds,
    expiresAt
}: CounterActionViewType): JSX.Element => {
    const { t: translator } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    const filteredOptions = useMemo(
        () => data?.filter((value) => !selectedOptions.some((item) => item.id === value.id)) || [],
        [data, selectedOptions]
    );

    const flagIconColor = useMemo(
        () =>
            applyStyleByMode({
                theme: ThemeMode?.mode,
                light: counterActionIds.length > 0 ? '#01fe00' : COLOR_GREY_4,
                dark: counterActionIds.length > 0 ? '#01fe00' : COLOR_GREY_4
            }),
        [counterActionIds.length, ThemeMode]
    );

    return (
        <Container container xs={12} spacing={2}>
            <FirstItem item xs={8}>
                <Autocomplete
                    disabled={queryIsLoading}
                    id='counter-action'
                    size='small'
                    multiple
                    fullWidth
                    value={selectedOptions}
                    options={filteredOptions}
                    onChange={(_, newArray) => onCounterActionIsSelected(newArray)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translator('t.counter_actions')}
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            data-testid='CounterActionMultiselect'
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFlag}
                                            size='sm'
                                            fixedWidth
                                            style={{ '--fa-primary-color': COLOR_GREY_4 } as React.CSSProperties}
                                            color={flagIconColor}
                                        />
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: params.InputProps.endAdornment
                            }}
                        />
                    )}
                />
            </FirstItem>
            <MiddleItem item xs={3}>
                <span>{translator('t.expires_at')}: </span>
                <span>
                    {fromUTCtoUserTimezone({
                        displayIfEmpty: '---',
                        date: expiresAt,
                        format: 'dateTime',
                        displaySeconds: false
                    })}
                </span>
            </MiddleItem>
            <LastItem item xs={1}>
                <ButtonHolder>
                    <UiButton skin='success' variant='contained' onClick={onSaveIsClicked} loading={mutationIsloading}>
                        {translator('t.save')}
                    </UiButton>
                </ButtonHolder>
            </LastItem>
        </Container>
    );
};

export default CounterActionView;
