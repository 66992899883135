import React from 'react';
import { useRecoilValue } from 'recoil';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UserInfo } from '../../../states/global/User';
import { CustomTextField, ImageDetailSetting, ImageDetailSettingContent } from './VehicleDetailsSettings.style';
import InputAdornment from '@mui/material/InputAdornment';
import { ROLES } from 'variables';
import { VehicleDetailsSettingsFormikValidation } from './VehicleDetailsSettings.type';
import { Divider, Grid } from '@mui/material';
import { getImgSource } from 'helpers/image';

export const VehicleDetailsSettingFormik: React.FC<VehicleDetailsSettingsFormikValidation> = ({
    handleSubmit,
    initialFormValues
}): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();

    const formValidationSchema = Yup.object().shape({
        maxSpeedLevel: Yup.number().notRequired(),
        maxLongitudinalAcceleration: Yup.number().notRequired(),
        maxLateralAcceleration: Yup.number().notRequired(),
        maxVerticalAcceleration: Yup.number().notRequired()
    });

    return (
        <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={formValidationSchema}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {(formikProps) => (
                <Form id='vehicle-details-settings'>
                    <Grid container justifyContent='center' columnSpacing={1}>
                        <Grid item md='auto'>
                            <ImageDetailSetting>
                                <ImageDetailSettingContent $widthImage='50px'>
                                    <img src={getImgSource('truckSpeed')} alt='logo' />
                                </ImageDetailSettingContent>
                            </ImageDetailSetting>
                        </Grid>
                        <Grid item xs='auto' sm={7} md={6} lg={7} xl='auto'>
                            <CustomTextField
                                id='max-speed'
                                label={translate('t.max_speed_level')}
                                value={formikProps.values.maxSpeedLevel}
                                variant='outlined'
                                size='small'
                                name='max-speed-level'
                                error={!!formikProps.errors.maxSpeedLevel && formikProps.touched.maxSpeedLevel}
                                type='number'
                                fullWidth
                                onChange={(event) => {
                                    formikProps.setFieldValue('maxSpeedLevel', event.target.value, false);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {userInfo.user?.userSetting.speedUnit}
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{
                                    step: 1
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={formikProps.errors.maxSpeedLevel}
                                data-testid='SettingsTab-MaxSpeedLevel-TextField'
                            />
                            <Divider />
                        </Grid>
                    </Grid>
                    {userInfo.user?.role.name === ROLES.ROLE_SUPER_ADMIN && (
                        <>
                            <Grid container justifyContent='center' columnSpacing={1}>
                                <Grid item md='auto'>
                                    <ImageDetailSetting>
                                        <ImageDetailSettingContent $widthImage='70px'>
                                            <img src={getImgSource('truckLongitudinal')} alt='logo' />
                                        </ImageDetailSettingContent>
                                    </ImageDetailSetting>
                                </Grid>
                                <Grid item xs='auto' sm={7} md={6} lg={7} xl='auto'>
                                    <CustomTextField
                                        id='max-longitudinal-accel'
                                        name='max-longitudinal-accel'
                                        error={
                                            !!formikProps.errors.maxLongitudinalAcceleration &&
                                            formikProps.touched.maxLongitudinalAcceleration
                                        }
                                        label={translate('t.max_longitudinal_accel')}
                                        value={formikProps.values.maxLongitudinalAcceleration}
                                        variant='outlined'
                                        size='small'
                                        type='number'
                                        fullWidth
                                        helperText={
                                            formikProps.errors.maxLongitudinalAcceleration &&
                                            formikProps.touched.maxLongitudinalAcceleration
                                                ? formikProps.errors.maxLongitudinalAcceleration
                                                : ''
                                        }
                                        onChange={(event) => {
                                            formikProps.setFieldValue(
                                                'maxLongitudinalAcceleration',
                                                event.target.value,
                                                false
                                            );
                                        }}
                                        inputProps={{
                                            step: 1,
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {userInfo.user?.userSetting.accelerationUnit}
                                                </InputAdornment>
                                            )
                                        }}
                                        data-testid='SettingsTab-MaxLongitudinalAcceleration-TextField'
                                    />
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='center' columnSpacing={1}>
                                <Grid item md='auto'>
                                    <ImageDetailSetting>
                                        <ImageDetailSettingContent $widthImage='65px'>
                                            <img src={getImgSource('truckLateral')} alt='logo' />
                                        </ImageDetailSettingContent>
                                    </ImageDetailSetting>
                                </Grid>
                                <Grid item xs='auto' sm={7} md={6} lg={7} xl='auto'>
                                    <CustomTextField
                                        id='max-lateral-accel'
                                        name='max-lateral-accel'
                                        error={
                                            !!formikProps.errors.maxLateralAcceleration &&
                                            formikProps.touched.maxLateralAcceleration
                                        }
                                        label={translate('t.max_lateral_accel')}
                                        value={formikProps.values.maxLateralAcceleration}
                                        variant='outlined'
                                        size='small'
                                        type='number'
                                        fullWidth
                                        helperText={
                                            formikProps.errors.maxLateralAcceleration &&
                                            formikProps.touched.maxLateralAcceleration
                                                ? formikProps.errors.maxLateralAcceleration
                                                : ''
                                        }
                                        onChange={(event) => {
                                            formikProps.setFieldValue(
                                                'maxLateralAcceleration',
                                                event.target.value,
                                                false
                                            );
                                        }}
                                        inputProps={{
                                            step: 1,
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {userInfo.user?.userSetting.accelerationUnit}
                                                </InputAdornment>
                                            )
                                        }}
                                        data-testid='SettingsTab-MaxLateralAcceleration-TextField'
                                    />
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='center' columnSpacing={1}>
                                <Grid item md='auto'>
                                    <ImageDetailSetting>
                                        <ImageDetailSettingContent $widthImage='40px'>
                                            <img src={getImgSource('truckVertical')} alt='logo' />
                                        </ImageDetailSettingContent>
                                    </ImageDetailSetting>
                                </Grid>
                                <Grid item xs='auto' sm={7} md={6} lg={7} xl='auto'>
                                    <CustomTextField
                                        id='max-vertical-accel'
                                        name='max-vertical-accel'
                                        error={
                                            !!formikProps.errors.maxVerticalAcceleration &&
                                            formikProps.touched.maxVerticalAcceleration
                                        }
                                        label={translate('t.max_vertical_accel')}
                                        value={formikProps.values.maxVerticalAcceleration}
                                        variant='outlined'
                                        size='small'
                                        type='number'
                                        fullWidth
                                        helperText={
                                            formikProps.errors.maxVerticalAcceleration &&
                                            formikProps.touched.maxVerticalAcceleration
                                                ? formikProps.errors.maxVerticalAcceleration
                                                : ''
                                        }
                                        onChange={(event) => {
                                            formikProps.setFieldValue(
                                                'maxVerticalAcceleration',
                                                event.target.value,
                                                false
                                            );
                                        }}
                                        inputProps={{
                                            step: 1,
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {userInfo.user?.userSetting.accelerationUnit}
                                                </InputAdornment>
                                            )
                                        }}
                                        data-testid='SettingsTab-MaxVerticalAcceleration-TextField'
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};
