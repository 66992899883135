import React from 'react';
import { TemperatureExposureSensorModalContainer } from './TemperatureExposureSensorModal.style';
import { TemperatureExposureSensorModalViewProps } from './TemperatureExposureSensorModal.type';
import TemperatureExposureTopbar from '../../Mixs/TemperatureExposureTopbar/TemperatureExposureTopbar';
import TemperatureExposureReport from '../../Mixs/TemperatureExposureReport/TemperatureExposureReport';

export const TemperatureExposureSensorModalContent: React.FC<TemperatureExposureSensorModalViewProps> = (
    props
): JSX.Element => {
    return (
        <TemperatureExposureSensorModalContainer data-testid='TemperatureExposureSensorModalContent'>
            <TemperatureExposureTopbar isLoading={props.isLoading} />
            <TemperatureExposureReport data={props.data} />
        </TemperatureExposureSensorModalContainer>
    );
};
