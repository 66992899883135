import React from 'react';
import { WheelHistoryTableContent } from './WheelHistoryTable.view';
import { WheelHistoryTableProps } from './WheelHistoryTable.type';
import { useTranslation } from 'react-i18next';
import { SensorHistory } from 'models/SensorHistory.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

const WheelHistoryTable: React.FC<WheelHistoryTableProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    const columns: Column<SensorHistory>[] = [
        {
            Header: translate('t.status'),
            width: 70,
            height: 30,
            Cell: (props: { value: string; row: { original: SensorHistory } }) => (
                <>{props.row.original.status ?? '-'}</>
            )
        },
        {
            Header: translate('t.priority'),
            width: 50,
            height: 30,
            Cell: (props: { value: string; row: { original: SensorHistory } }) => (
                <>{props.row.original.sensor?.priority ?? '-'}</>
            )
        },
        {
            Header: translate('t.action'),
            width: 70,
            height: 30,
            Cell: (props: { row: { original: SensorHistory } }) => <>{props.row.original.action ?? '-'}</>
        },
        {
            Header: translate('t.created_at'),
            width: 70,
            height: 30,
            Cell: (props: { row: { original: SensorHistory } }) => (
                <>{fromUTCToTimezone(props.row.original.createdAt || '', true) ?? '-'}</>
            )
        },
        {
            Header: translate('t.vehicle_name'),
            width: 70,
            height: 30,
            Cell: (props: { row: { original: SensorHistory } }) => <>{props.row.original.wheel?.vehicle?.name ?? '-'}</>
        },
        {
            Header: translate('t.wheel_position'),
            width: 70,
            height: 30,
            Cell: (props: { row: { original: SensorHistory } }) => (
                <>{props.row.original.wheel?.customPosition ?? '-'}</>
            )
        }
    ];

    return (
        <WheelHistoryTableContent
            data-testid={`WheelHistoryTable-testid-${props.sensorId}`}
            columns={columns}
            sensorId={props.sensorId}
        />
    );
};

export default Wrapper(WheelHistoryTable);
