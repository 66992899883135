import React from 'react';
import { useRecoilValue } from 'recoil';
import { ReportTableContainer } from './ReportTable.style';
import { ReportTableViewProps } from './ReportTable.type';
import { useTranslation } from 'react-i18next';
import UiTable from '../../Ui/Components/UiTable2';
import Widget from '../../Ui/Components/UiWidget';
import { faList } from '@fortawesome/pro-solid-svg-icons';
import CustomerReport from '../../../api/CustomerReport';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

const customerReportApi = new CustomerReport();

export const ReportTableContent: React.FC<ReportTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <ReportTableContainer data-testid='ReportTableContent'>
            <Widget
                data-testid='CustomersReportContent'
                avatar={
                    <UiIcon
                        icon={faList}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.customer_report')}
                loading={false}
                content={
                    <UiTable
                        columns={props.columns}
                        fetchFn={customerReportApi.getCustomerReportTable}
                        queryKey={'customerReport'}
                        hiddenColumns={[]}
                        refresh={false}
                    />
                }
            ></Widget>
        </ReportTableContainer>
    );
};
