import React from 'react';
import { HelperTextContainer } from './HelperText.style';
import { HelperTextProps } from './HelperText.type';

const HelperTextContent: React.FC<HelperTextProps> = (props): JSX.Element => {
    return (
        <HelperTextContainer data-testid='HelperTextContent'>
            {props.error && props.text ? props.text : props.defaultText}
        </HelperTextContainer>
    );
};

HelperTextContent.defaultProps = { error: true };

export default HelperTextContent;
