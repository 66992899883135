import React, { useEffect } from 'react';
import TyreLazyLoading from './Components/TyreLazyLoading';
import { TyreManagerContent } from './TyreManager.style';
import MountDate from './Components/MountDate';
import UnmountDate from './Components/UnmountDate';
import Buttons from './Components/Buttons';
import WarningMessage from './Components/WarningMessage';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { TyreStateAtom, MountDateAtom } from './TyreManager.atom';
import { VehicleDetailsActionMenuAtom } from '../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { CRUD } from 'variables';
import { DateTime } from 'luxon';
import { VehicleDetailsWidgetAtom } from '../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { Grid } from '@mui/material';
import { VehicleModel } from 'models/Vehicle.type';

const TyreManager = (): JSX.Element => {
    const refreshAtom = useResetRecoilState(TyreStateAtom);
    const setTyreState = useSetRecoilState(TyreStateAtom);
    const setMountDate = useSetRecoilState(MountDateAtom);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    useEffect(() => {
        const originalTyre = {
            id: vehicleDetailsActionMenuAtom.data?.wheel.tyreId || 0,
            vehicleName: (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name || '',
            tyreSerialNumber: vehicleDetailsActionMenuAtom.data?.wheel.tyreSerialNumber || '',
            customPosition: vehicleDetailsActionMenuAtom.data?.wheel.customPosition || 0
        };

        setMountDate(DateTime.local());

        setTyreState({
            originalTyre: originalTyre,
            newTyre: originalTyre,
            state: originalTyre.id ? CRUD.UNMOUNT : CRUD.MOUNT
        });

        return () => {
            refreshAtom();
        };
    }, [vehicleDetailsActionMenuAtom.data]);

    return (
        <>
            <TyreManagerContent data-testid='TyreManagerContentModal'>
                <Grid container spacing={1}>
                    <Grid item>
                        <TyreLazyLoading />
                    </Grid>
                    <Grid item>
                        <MountDate />
                    </Grid>
                    <Grid item>
                        <UnmountDate />
                    </Grid>
                    <Grid item xl={4} lg={4}>
                        <Buttons />
                    </Grid>
                </Grid>
            </TyreManagerContent>
            <WarningMessage />
        </>
    );
};

export default TyreManager;
