import React, { ComponentType, useEffect, useState } from 'react';
import { SxProps, Theme, Tooltip } from '@mui/material';
import { ContainerLink, UiAViewContent } from './UiLink.style';
import { UiLinkProps } from './UiLink.type';
import { LinkProps } from 'react-router-dom';

const UiLink: React.FC<UiLinkProps> = ({
    content,
    title,
    url,
    color,
    $className,
    $padding,
    $fontWeight,
    styleCustom,
    onClick,
    testId,
    openNewTab,
    relationshipAttribute
}): JSX.Element => {
    const [Link, setLink] = useState<
        | React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>
        | ComponentType<{ sx: SxProps<Theme> | undefined }>
    >(UiAViewContent);

    const getLink = async () => {
        const module = await import('react-router-dom');
        setLink(module.Link);
    };

    useEffect(() => {
        getLink();
    }, []);

    return (
        <ContainerLink
            $color={color}
            $padding={$padding}
            $fontWeight={$fontWeight}
            datatest-id={`${testId || 'ContainerLink'}-Container`}
        >
            <Tooltip title={title || ''}>
                <Link
                    sx={{ padding: $padding, fontWeight: $fontWeight }}
                    data-testid={`${testId || 'UiLinkContent'}-Link`}
                    className={$className}
                    to={url || ''}
                    target={openNewTab ? '_blank' : undefined}
                    onClick={() => onClick && onClick()}
                    style={styleCustom}
                    rel={relationshipAttribute}
                >
                    {content}
                </Link>
            </Tooltip>
        </ContainerLink>
    );
};

export default UiLink;
