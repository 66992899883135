import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { applyStyleByMode } from 'helpers';

export const FaultySensorReportsContainer = styled.div`
    ${applyStyleByMode({
        styleOld: `
            th,
            td {
                overflow-wrap: break-word;
            }
            width: 100%;
            && .MuiCardContent-root {
                padding: 16px 0;
                font-size: 13px !important;
            }

            & .MuiTableHead-root {
                border-bottom: 1px solid #474544;
                background: #f9f9f9;
            }
        `
    })}
`;

export const DatePickerText = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
            margin-top: 8px !important;
            & .MuiTextField-root {m 
                margin-top: 8px !important;
            }
        `
    })}
`;
