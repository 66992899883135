import { Box, Grid, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { ThemeMode } from 'states/global/Theme';

export const SetAlertsViewContent = styleMui(Box)``;

export const SaveBtnCover = styleMui(Box)({
    maxHeight: 36,
    margin: 'auto 0',
    button: {
        maxHeight: 36
    }
});

export const LeftCover = styleMui(Grid)((props: { theme: Theme }) => ({
    borderRight: `solid thin ${props.theme.palette.mode === ThemeMode.dark ? '#28313a' : 'rgb(245,245,245)'}`
}));

export const SetAlertsStepBadge = styleMui(Box)({
    width: 20,
    height: 20,
    background: '#009bd5',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1em'
});
