import { ModalProps } from '../../Mixs/StatedModal/StatedModal.type';

export type ModalHookReturnType = {
    modalState: ModalProps;
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    updateModalState: (payload: ModalProps) => void;
    setModalState: (payload: ModalProps) => void;
};

export enum ModalActionTypesEnum {
    TOGGLE_MODAL,
    CLOSE_MODAL,
    OPEN_MODAL,
    UPDATE_MODAL_STATE,
    SET_MODAL_STATE
}

export type DispatcherAction = {
    type: ModalActionTypesEnum;
    ModalPropsPayload?: ModalProps;
};
