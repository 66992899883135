import { NotificationSeetingValue, NotificationsSettings } from 'models/Notification.type';
import { UserModel } from '../../../models/User.type';

export type NotificationsSettingsFormProps = {
    isCurrentUser?: boolean;
    userId?: number;
    userData: UserModel | null;
    setReloadPage: (reload: boolean) => void;
    checkPermissionsUser: boolean;
};

export type StateOpenTab = {
    idTab: number;
    openTab: boolean;
};

export type NotificationsSettingsFormViewProps = {
    data?: GroupedData;
    setData: React.Dispatch<React.SetStateAction<GroupedData>>;
    handleSubmit: () => void;
    isCurrentUser?: boolean;
    userData: UserModel | null;
    notificationLabel: (type: string, type2: string) => string;
    checkPermissionsUser: boolean;
};

export type GroupedData = Record<string, Record<string, NotificationsSettings[]>>;

export type SettingGroupProps = {
    title: string;
    groupSettings: Record<string, NotificationsSettings[]>;
    translate: any;
    additionalGroup?: Record<string, NotificationsSettings[]>;
} & Pick<NotificationsSettingsFormViewProps, 'setData' | 'notificationLabel'>;

export enum NotificationDeviceType {
    PORTAL = 'portal',
    EMAIL = 'email',
    WHATSAPP = 'whatsapp',
    MOBILE = 'mobile'
}

export type RowData = {
    rowData: NotificationsSettings[];
} & Pick<NotificationsSettingsFormViewProps, 'setData' | 'notificationLabel'>;

export type CheckboxSetting = NotificationSeetingValue & {
    deviceType: NotificationDeviceType;
    index: number;
} & Pick<RowData, 'setData'> &
    Pick<RowData['rowData'][0], 'type' | 'type2'>;

export type FirstCellType = {
    index: number;
    groupCount: number;
} & Pick<RowData['rowData'][0], 'type' | 'type2'> &
    Pick<NotificationsSettingsFormViewProps, 'notificationLabel'>;
