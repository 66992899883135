import React from 'react';
import { HistoryPlacementTableContent } from './HistoryPlacementTable.view';
import { HistoryPlacementTableProps } from './HistoryPlacementTable.type';
import { HubHistoryPlacementList } from 'models/HubAdministration.type';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { getVersionFromService } from 'helpers';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

const HistoryPlacementTable: React.FC<HistoryPlacementTableProps> = (props): JSX.Element => {
    const { fromUTCToTimezone } = useConverter();
    const { t: translate } = useTranslation();

    const columns: Column<HubHistoryPlacementList>[] = [
        {
            Header: translate('t.vehicle'),
            disableFilters: true,
            Cell: (props: { row: { original: HubHistoryPlacementList } }) => (
                <>{props.row.original.vehicleName ?? '-'}</>
            )
        },
        {
            Header: translate('t.firmware'),
            disableFilters: true,
            Cell: (props: { row: { original: HubHistoryPlacementList } }) => (
                <>{props.row.original.firmwareVersion?.split(' - ')[0] ?? '-'}</>
            )
        },
        {
            Header: translate('t.service'),
            disableFilters: true,
            Cell: (props: { row: { original: HubHistoryPlacementList } }) => (
                <>{getVersionFromService(props.row.original.vehicleDeviceHistoryService, '-')}</>
            )
        },
        {
            Header: translate('t.date'),
            disableFilters: true,
            Cell: (props: { row: { original: HubHistoryPlacementList } }) => (
                <>{fromUTCToTimezone(props.row.original.vehicleDeviceHistoryCreatedAt || '', true) ?? '-'}</>
            )
        }
    ];

    return (
        <HistoryPlacementTableContent
            {...props}
            data-testid={`HistoryPlacementTable-testid-${props.deviceId}`}
            columns={columns}
        />
    );
};

export default Wrapper(HistoryPlacementTable);
