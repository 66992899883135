import React, { useMemo } from 'react';
import { Tyre } from './Tyre';
import { BackAxelType } from './VehicleSchema.type';
import { useRecoilValue } from 'recoil';
import { AxelSelector } from './AxelSelector';
import { getClickableAxelColor } from 'helpers';
import { Theme } from 'states/global/Theme';

const spaceBetweenAxels = 90;

export const BackAxel: React.FC<BackAxelType> = ({
    width,
    height,
    startY,
    axelPosition,
    numberOfWheels,
    startWheel,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    highlightColor,
    highlightWheels,
    isLoader_2_2,
    isEmptySchema
}): JSX.Element => {
    const startWheelPosition = startWheel - numberOfWheels;
    const allPositions = [startWheelPosition + 1, startWheelPosition + numberOfWheels].concat(
        numberOfWheels === 4 ? [startWheelPosition + 2, startWheelPosition + 3] : []
    );
    const theme = useRecoilValue(Theme);
    const clickableColor = useMemo(() => {
        return getClickableAxelColor(!!allowSelectTyre, theme);
    }, [allowSelectTyre, theme.mode]);

    return (
        <svg id={`back_axel_${axelPosition}`} viewBox={`-10 0 ${width} ${height}`}>
            <g transform={isLoader_2_2 ? `rotate(-15) translate(${isEmptySchema ? '-60' : '-55'}, 40)` : ''}>
                <svg
                    viewBox={`-28 ${startY - spaceBetweenAxels * axelPosition} ${width} ${height}`}
                    id='back_axel_core'
                >
                    <path
                        d='M 47.584 53.428 C 41.484 53.428 36.584 58.428 36.584 64.428 C
                        36.584 70.528 41.584 75.428 47.584 75.428 C 53.684 75.428 58.584
                        70.428 58.584 64.428 C 58.584 58.428 53.684 53.428 47.584 53.428'
                        fill={clickableColor}
                    />
                    <path
                        d='M 47.671 51.014 C 49.571 51.014 51.271 51.414
                        52.871 52.214 L 52.871 43.114 L 42.471 43.114 L 42.471
                        52.214 C 44.071 51.514 45.771 51.014 47.671 51.014'
                    />
                    <path
                        d='M 59.845 59.345 C 60.545 60.945 61.045 62.645 61.045
                        64.545 C 61.045 66.445 60.645 68.245 59.845 69.745 L 67.045
                        69.745 L 67.045 59.345 L 59.845 59.345 Z'
                        fill={clickableColor}
                    />
                    <path
                        d='M 34.093 64.383 C 34.093 62.583 34.493 60.783 35.293
                        59.183 L 28.093 59.183 L 28.093 69.583 L 35.293 69.583 C
                        34.593 67.983 34.093 66.283 34.093 64.383'
                        fill={clickableColor}
                    />
                    <rect x='44.5' y='-13' width='7' height='54.5' />
                    {numberOfWheels === 2 && (
                        <g fill={clickableColor}>
                            <rect x='0' y='62' width='25' height='5' />
                            <rect x='69.323' y='62.108' width='25' height='5' />
                        </g>
                    )}
                    {allowSelectTyre && <AxelSelector allTyrePosition={allPositions} />}
                </svg>
                <Tyre
                    position={startWheelPosition + 1}
                    viewBox={`0 ${startY - spaceBetweenAxels * axelPosition - 37} ${width} ${height}`}
                    vehicleAtomId={vehicleAtomId}
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    color={
                        highlightWheels && highlightWheels[startWheelPosition + 1]
                            ? highlightWheels[startWheelPosition + 1]?.color || highlightColor
                            : undefined
                    }
                />
                <Tyre
                    position={startWheelPosition + numberOfWheels}
                    viewBox={`-125 ${startY - spaceBetweenAxels * axelPosition - 37} ${width} ${height}`}
                    vehicleAtomId={vehicleAtomId}
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    color={
                        highlightWheels && highlightWheels[startWheelPosition + numberOfWheels]
                            ? highlightWheels[startWheelPosition + numberOfWheels]?.color || highlightColor
                            : undefined
                    }
                />
                {numberOfWheels === 4 && (
                    <g>
                        <Tyre
                            position={startWheelPosition + 2}
                            viewBox={`-28 ${startY - spaceBetweenAxels * axelPosition - 37} ${width} ${height}`}
                            vehicleAtomId={vehicleAtomId}
                            allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                            allowSelectTyre={allowSelectTyre}
                            color={
                                highlightWheels && highlightWheels[startWheelPosition + 2]
                                    ? highlightWheels[startWheelPosition + 2]?.color || highlightColor
                                    : undefined
                            }
                        />
                        <Tyre
                            position={startWheelPosition + 3}
                            viewBox={`-97 ${startY - spaceBetweenAxels * axelPosition - 37} ${width} ${height}`}
                            vehicleAtomId={vehicleAtomId}
                            allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                            allowSelectTyre={allowSelectTyre}
                            color={
                                highlightWheels && highlightWheels[startWheelPosition + 3]
                                    ? highlightWheels[startWheelPosition + 3]?.color || highlightColor
                                    : undefined
                            }
                        />
                    </g>
                )}
            </g>
        </svg>
    );
};
