import styled from 'styled-components';
import { Autocomplete, Paper, Table, TableCell, TableRow, useTheme } from '@mui/material';
import UiButton from '../../Ui/Components/UiButton';
import { PRIMARY, WHITE } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const MinesPermissionsViewContent = styled.div``;

export const WidgetContent = styled.div`
    min-height: 300px;
    max-height: 85vh;
`;

export const SubHead = styled.div`
    display: flex;
    justify-content: space-between;

    border-bottom: rgba(224, 224, 244) 1px solid;
    ${() => {
        return `
            min-height: 34px;
            padding: 10px;
            &&& { 
                .MuiTextField-root {
                    margin: -1.7px;
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : WHITE};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
        `;
    }}
`;

export const TableName = styled(Paper)`
    display: flex;
    align-items: flex-end;
    background: transparent !important;
    & .MuiTypography-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
    }
`;

export const Button = styled(UiButton)`
    max-width: 25px !important;
    min-width: 25px !important;
`;

export const CustomTable = styled(Table)`
    && .MuiTableCell-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
    }
    tbody {
        color: ${PRIMARY};
        font-size: 0.9em;
        tr:nth-child(odd) {
            ${() => {
                return applyStyleByMode({
                    styleOld: 'background: #f5f5f5;'
                });
            }}
        }

        tr:nth-child(even) {
            ${() => {
                return useTheme().palette?.mode === ThemeMode.dark ? 'background: #232d3a;' : 'background: #f5f5f5;';
            }}
        }

        td {
            ${'border-bottom: 1px solid #b5b5b5 !important;'}
        }
    }
`;

export const AutocompleteWithStyle = styled(Autocomplete)`
    && .MuiFormControl-fullWidth {
    {
        width: 200px;
    }
`;

export const CustomTableRow = styled(TableRow)<{ $assigned: boolean }>`
    ${({ $assigned }) => {
        if ($assigned) {
            return applyStyleByMode({
                styleJade:
                    useTheme().palette.mode === ThemeMode.dark
                        ? 'background: #006182 !important;'
                        : 'background: #e2f3ff  !important;'
            });
        }
        return '';
    }}
`;

export const StickyHeader = styled(TableCell)`
    &.MuiTableCell-stickyHeader {
        ${applyStyleByMode({
            styleOld: 'top: -8px;',
            styleJade: 'top: 0px;'
        })}
    }
`;

export const ContainerTable = styled.div`
    @media (min-width: 0px) and (max-width: 425px) {
        max-height: 188px;
        overflow: auto;
    }

    @media (min-width: 426px) and (max-width: 768px) {
        max-height: 200px;
        overflow: auto;
    }
`;
