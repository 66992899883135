import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { WidgetStatisticsPressureProps } from './WidgetStatisticsPressure.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../../components/Ui/Components/UiWidget';
import TimelineIcon from '@mui/icons-material/Timeline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../../states/global/Theme';
import { BACKGROUND_DARK_MODE } from '../../Ui/colors';
import StatisticsPressure from '../StatisticsPressure/StatisticsPressure';
import { UserInfo } from '../../../states/global/User';
import { Timeline } from 'states/component/FaultySensorReporter';

const WidgetStatisticsPressure: React.FC<WidgetStatisticsPressureProps> = ({
    faultySensorReporterState,
    externalTemperatureEnabled,
    zoomInExternal
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeModeValue = useRecoilValue(Theme);
    const userInfo = useRecoilValue(UserInfo);
    const [timeline, setTimeline] = useRecoilState(Timeline);
    const [allHubsChecked, setAllHubsChecked] = useState<boolean>(
        faultySensorReporterState.displayAllHubs ?? Boolean(faultySensorReporterState.vehicleId) === false
    );

    const setAllHubsCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAllHubsChecked(event.target.checked);
        setTimeline({
            timeline: timeline.timeline,
            granularity: timeline.granularity
        });
    };

    return (
        <Widget
            avatar={<TimelineIcon />}
            title={<>{translate('t.pressure')}</>}
            applyPadding={false}
            headerColor={applyStyleByMode({
                theme: ThemeModeValue?.mode,
                light: '#e9e9e9;',
                dark: BACKGROUND_DARK_MODE
            })}
            headerAction={
                !faultySensorReporterState.reportDetailId ? (
                    <FormControlLabel
                        control={
                            <Checkbox checked={allHubsChecked} onChange={setAllHubsCheckbox} name='display-mounted' />
                        }
                        label={translate('t.display_readings_from_all_hubs')}
                    />
                ) : undefined
            }
            sx={{ marginTop: 1 }}
            id={`pressure-chart-${userInfo?.user?.customer?.name || 12}-${faultySensorReporterState.vehicleId}`}
        >
            <StatisticsPressure
                faultySensorReporterState={faultySensorReporterState}
                externalTemperatureEnabled={externalTemperatureEnabled}
                allHubsChecked={allHubsChecked}
                zoomInExternal={zoomInExternal}
            />
        </Widget>
    );
};

export default WidgetStatisticsPressure;
