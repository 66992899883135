import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsWheelHistoryViewContent, Text } from './VehicleDetailsWheelHistory.style';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import WheelHistoryTable from 'components/Tyre/WheelHistoryTable/WheelHistoryTable';
import DropDown from 'components/Ui/Components/UiDropDown/UiDropDown';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { SelectedSensorAtom } from 'components/Tyre/TyreDetails/SelectedSensor.atom';

export const VehicleDetailsWheelHistory = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const selectedSensor = useRecoilValue(
        SelectedSensorAtom(vehicleDetailsActionMenuAtom?.data?.wheel.position as number)
    );
    const [currentSensor, setCurrentSensor] = useState(selectedSensor?.sensor.id);

    const parseData = (): optionsCodeBook[] => {
        return vehicleDetailsActionMenuAtom?.data?.attachedSensors
            ? vehicleDetailsActionMenuAtom.data.attachedSensors.reduce((acc: optionsCodeBook[], curr) => {
                  acc.push({ id: curr?.sensor.id, name: curr?.sensor.serialNumberHex });
                  return acc;
              }, [])
            : [];
    };

    return (
        <VehicleDetailsWheelHistoryViewContent data-testid='VehicleDetailsWheelHistoryViewContent'>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4} sm={4}>
                    <DropDown
                        options={parseData()}
                        inputName='id'
                        label={`${translate('t.sensor')} ${translate('t.serial_number')}`}
                        value={currentSensor ? currentSensor : ' '}
                        disabled={false}
                        getValue={(e) => {
                            setCurrentSensor((e as number) ?? undefined);
                        }}
                        defaultValue={currentSensor ?? null}
                    />
                </Grid>
            </Grid>
            {!currentSensor ? (
                <Grid container justifyContent={'center'}>
                    <Grid item xs={4} sm={4}>
                        <Text>{translate('t.select_sensor')}</Text>
                    </Grid>
                </Grid>
            ) : (
                <WheelHistoryTable sensorId={currentSensor} />
            )}
        </VehicleDetailsWheelHistoryViewContent>
    );
};

export default VehicleDetailsWheelHistory;
