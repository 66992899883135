import PortalApi from 'helpers/api/PortalApiClient';
import { UtilModel, TestAlert } from '../models/Util.type';

export default class Util extends PortalApi<UtilModel> {
    route = 'util';

    getSetTestAlert = async (router: string): Promise<TestAlert> => {
        return await this.get({ extendUrl: router, apiProject: undefined, version: '' });
    };
}
