import Backdrop from '@mui/material/Backdrop';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const SpinnerContainer = styled(Backdrop)`
    color: ${() => useTheme().palette.primary.main};
    z-index: 99999 !important;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background-color: rgb(229 229 229 / 65%) !important;',
            dark: 'background-color: rgb(0 0 0 / 75%) !important;'
        });
    }}
`;
