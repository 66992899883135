import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { SUCCESS } from '../Ui/colors';

export const ProcessingProgressContainer = styled.div``;

export const RequestProgress = styled(CircularProgress)({
    color: `${SUCCESS} !important`,
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});
