import React from 'react';
import { HubNotifyContent } from './HubNotify.view';
import { HubNotifyProps } from './HubNotify.type';
import { Wrapper } from 'helpers/wrapper';

const HubNotify: React.FC<HubNotifyProps> = (): JSX.Element => {
    return <HubNotifyContent data-testid='HubNotify-testid' />;
};

export default Wrapper(HubNotify);
