import { ExcessiveAccViewContent, Img, LoadedStatus } from './ExcessiveAcc.style';
import { ExcessiveAccState, ExcessiveAccActions } from './ExcessiveAcc.type';
import React, { useEffect, useReducer } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MapActionsEnum } from '../../Map/Map.type';
import { useTranslation } from 'react-i18next';
import Button from '../../Ui/Components/UiButton';
import { MapAction, MapActionData } from 'states/global/Map';
import { ExcessiveAccReducer } from './ExcessiveAcc.reducer';
import { UserInfo } from 'states/global/User';
import { DateTime, Settings } from 'luxon';
import LazyloadingAutocomplete from '../../Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import Vehicle from 'api/Vehicle';
import { MenuItem } from '@mui/material';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';

import { getImgSource } from 'helpers/image';
import AccelerometerEventApi from 'api/AccelerometerEvent';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

const AccelerometerEvent = new AccelerometerEventApi();
const VehicleApi = new Vehicle();

export const ExcessiveAccContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const setMapAction = useSetRecoilState(MapAction);
    const setMapActionData = useSetRecoilState(MapActionData);
    const ExcessiveAccInitialState: ExcessiveAccState = {
        dateRange: {
            dateFrom: DateTime.local().minus({ days: 7 }),
            dateTo: DateTime.local(),
            isValidRange: true
        },
        vehiclesIds: null,
        loaded: -1,
        operator: null
    };

    const [state, dispatch] = useReducer(ExcessiveAccReducer, ExcessiveAccInitialState);

    const updateExcessiveAccMap = () => {
        setMapActionData((currentMapActionData) => {
            return {
                ...currentMapActionData,
                ...{
                    selectedVehicles: state.vehiclesIds || undefined,
                    heatmapDateRange: state.dateRange || undefined,
                    loaded: state.loaded,
                    vehicleOperator: state.operator
                }
            };
        });
        setMapAction({
            action: MapActionsEnum.DRAW_EXCESSIVE_ACC,
            params: {
                dateFrom: state.dateRange?.dateFrom,
                dateTo: state.dateRange?.dateTo,
                vehicleIds: state.vehiclesIds ?? [0]
            }
        });
    };

    /*const setLoadedStatus = (event) => {
        dispatch({
            type: ExcessiveAccActions.SET_LOADED_STATUS,
            payload: Number(event.target.value)
        });
    };*/

    useEffect(() => {
        Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';

        dispatch({
            type: ExcessiveAccActions.SET_DATE_RANGE,
            payload: { dateFrom: DateTime.local().minus({ days: 7 }), dateTo: DateTime.local() }
        });
    }, [userInfo?.user?.userSetting.timezone.timezoneName]);

    return (
        <ExcessiveAccViewContent data-testid='ExcessiveAccContent'>
            <UiDateRangePicker2
                adjustToPeriod={{ period: 7, periodType: PeriodTypeEnum.DAYS }}
                data-testid={'ExcessiveAccContent-UiDateRangePicker'}
                dateFrom={state.dateRange?.dateFrom}
                dateTo={state.dateRange?.dateTo}
                dateFromLabel={translate('t.date_from')}
                dateToLabel={translate('t.date_to')}
                onDateChange={(dateRange) => {
                    if (dateRange) {
                        dispatch({
                            type: ExcessiveAccActions.SET_DATE_RANGE,
                            payload: { dateFrom: dateRange[0], dateTo: dateRange[1] }
                        });
                    }
                }}
            />

            <LazyloadingAutocomplete
                id='excessive-acc-lazyloading'
                size='small'
                label={translate('t.vehicles')}
                customMargin='0 0 10px 0'
                data-testid={'ExcessiveAccLazyAutocomplete-testid'}
                query={VehicleApi.getTable}
                querySortBy={{ id: 'vehicleName', desc: false }}
                extendUrl='excessive-acceleration'
                getOptionLabel={(option) => ({
                    html: (
                        <>
                            {option.icon && <Img src={getImgSource(`${option.icon}`)} alt={option.icon} />}{' '}
                            {option.name}
                        </>
                    ),
                    text: option.name
                })}
                clearButton={true}
                queryId='ExcessiveAccLazyAutocomplete-list'
                optionKey='name'
                optionValue='id'
                onValueChange={(newVehicleId) => {
                    dispatch({
                        type: ExcessiveAccActions.SET_VEHICLES_IDS,
                        payload: [newVehicleId as number]
                    });
                }}
                defaultOption={{
                    id: 0,
                    name: translate('t.all_vehicles'),
                    icon: '',
                    active: true
                }}
                width={200}
            />

            {userInfo.user?.customer.id === 155 && (
                <>
                    <LoadedStatus
                        data-testid={'excessive-acc-status-select'}
                        id='excessive-acc-status-select'
                        select
                        label={translate('t.loaded_state')}
                        size={'small'}
                        value={state.loaded}
                        onChange={(event) => {
                            dispatch({
                                type: ExcessiveAccActions.SET_LOADED_STATUS,
                                payload: Number(event.target.value)
                            });
                        }}
                    >
                        <MenuItem value={-1}>{translate('t.all')}</MenuItem>
                        <MenuItem value={1}>{translate('t.loaded')}</MenuItem>
                        <MenuItem value={0}>{translate('t.unloaded')}</MenuItem>
                    </LoadedStatus>
                    <LazyloadingAutocomplete
                        data-testid={'ExcessiveAccel-LazyloadingAutocomplete'}
                        id={'excessive-accel-operator-lazyloading'}
                        queryId='excessive-accel-operator-lazyloading'
                        optionKey='firstNameLastName'
                        optionValue='id'
                        size='small'
                        clearButton
                        customMargin='0 0 0 4px'
                        query={AccelerometerEvent.getTable}
                        extendUrl='excessive-acceleration/vehicle-operators'
                        label={translate('t.operator')}
                        onValueChange={(value) => {
                            dispatch({
                                type: ExcessiveAccActions.SET_OPERATOR,
                                payload: value || null
                            });
                        }}
                        getOptionLabel={(value) => {
                            return {
                                text: `${value.firstName} ${value.lastName}`,
                                html: undefined
                            };
                        }}
                    />
                </>
            )}
            <Button
                skin='success'
                color='primary'
                variant='contained'
                disabled={typeof state.dateRange?.isValidRange === 'undefined' ? false : !state.dateRange?.isValidRange}
                onClick={updateExcessiveAccMap}
            >
                {translate('t.apply')}
            </Button>
        </ExcessiveAccViewContent>
    );
};
