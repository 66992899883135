import React from 'react';
import { AlertNotificationManagmentContainer } from './AlertNotificationManagment.style';
import { AlertNotificationManagmentViewProps } from './AlertNotificationManagment.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import AlertNotificationManagmentTable from 'components/Alert/AlertNotificationManagmentTable/AlertNotificationManagmentTable';
import AlertNotificationManagmentTopbar from 'components/Alert/AlertNotificationManagmentTopbar/AlertNotificationManagmentTopbar';
import { applyStyleByMode } from 'helpers';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { faBells } from '@fortawesome/pro-solid-svg-icons';

export const AlertNotificationManagmentContent: React.FC<AlertNotificationManagmentViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <AlertNotificationManagmentContainer data-testid='AlertNotificationManagmentContent'>
            <AlertNotificationManagmentTopbar />
            <Widget
                title={translate('t.alert_notification_management')}
                data-testid={`AlertNotificationManagmentContainer-Widget`}
                avatar={
                    <UiIcon
                        icon={faBells}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                loading={false}
            >
                <AlertNotificationManagmentTable />
            </Widget>
        </AlertNotificationManagmentContainer>
    );
};
