import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';

export const HeatmapTopbarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const DateTimePicker = styled(DatePicker)`
    &.MuiFormControl-root {
        margin: 0 3px !important;
    }
`;
