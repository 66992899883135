import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UiTableFooterViewContent } from './UiTableFooter.style';
import { UiTableFooterProps } from './UiTableFooter.type';
import { useTranslation } from 'react-i18next';
import { TableRow, TablePagination } from '@mui/material';
import { TableAtom } from 'states/component/Table';
import { TableFn } from '../UiTable/TableAtom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';

const UiTableFooterContent: React.FC<UiTableFooterProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const tableFns = useRecoilValue(TableFn(`${props.queryKey}-TableFn`));

    const handleChangeRowsPerPage = (event): void => {
        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            pageSize: +event.target.value
        });
        setTableState({ ...tableState, queryPageSize: +event.target.value, queryPageIndex: 0 });
    };
    return (
        <UiTableFooterViewContent data-testid='UiTableFooterContent'>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={undefined}
                    count={tableState.totalCount}
                    labelRowsPerPage={translate('t.entries_per_page')}
                    rowsPerPage={tableState.queryPageSize}
                    page={tableState.queryPageIndex}
                    onPageChange={(event, newPage) => {
                        tableFns.gotoPage instanceof Function && tableFns.gotoPage(newPage);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableRow>
        </UiTableFooterViewContent>
    );
};

export default UiTableFooterContent;
