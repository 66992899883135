import React, { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { EmailNotification, EmailNotificationCover, RequestProgress } from './ActionButtons.style';
import { ActionButtonsProps } from './ActionButtons.type';
import { useTranslation } from 'react-i18next';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from '../../../states/global/User';
import { ROLES } from 'variables';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { SIZE_BUTTON, VARIANT_BUTTON } from '../../Ui/variables';
import { ERROR } from '../../Ui/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaptureImageTrigger } from '../../../states/global/FaultyReporter';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { CancelButton } from 'components/StatedModal/Modal/Modal.style';
import NotificationApi from 'api/Notification';
import { useQuery } from '@tanstack/react-query';
import { LeakNotifications } from '../FaultySensorReport.type';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import { InvalidActionButtons } from 'states/component/FaultySensorReporter';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';

const Notification = new NotificationApi();

const ActionButtons: React.FC<ActionButtonsProps> = ({
    noSelectedIssues,
    hideReportOptions,
    isProcessing,
    userIsSuperAdmin,
    faultySensorReporterState,
    leakNotification,
    verifyingAllowed,
    createReport,
    setLeakNotification,
    updateHideReportOptions,
    verifyReport,
    reopenReport
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const invalidActionButtons = useRecoilValue(InvalidActionButtons);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const userInfo = useRecoilValue(UserInfo);
    const [captureImageTrigger, setCaptureImageTrigger] = useRecoilState(CaptureImageTrigger);
    const [leakNotifications, setLeakNotifications] = useState<LeakNotifications>({});
    const setMapState = useSetRecoilState(MapAction);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('FaultySensorReports-RefreshTableAtom'));

    useQuery(['faultySensorReportNotificationCodebook'], () => Notification.getNotificationCodebook(), {
        onSuccess(data) {
            const leakNotifications = data.filter((n) => n.type === 'pressure_leak');

            const leaks = leakNotifications.reduce((previousValue, currentValue) => {
                let copy = { ...previousValue };
                copy[currentValue.type2] = {
                    id: currentValue.id,
                    key: currentValue.type2
                };
                return copy;
            }, {});

            setLeakNotifications(leaks);
        },
        enabled: faultySensorReporterState.allowReportLeakAlert,
        refetchOnWindowFocus: false
    });

    let showButtons = (
        <>
            <EmailNotificationCover sx={{ textAlign: 'left' }} severity='info'>
                <EmailNotification>
                    {translate('p.warning_multiple_sensors_on_wheel_marking_primary')}
                </EmailNotification>
            </EmailNotificationCover>
            <UiButton
                skin='success'
                form='tyre-action-form'
                color='primary'
                testid='Report-FaultySensorReport-Action'
                variant={VARIANT_BUTTON}
                onClick={createReport}
                disabled={invalidActionButtons}
            >
                {translate('t.report')}
            </UiButton>
        </>
    );

    const hasPermission = useMemo(
        () => !checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_TECHNICAL_USER, ROLES.ROLE_BASE_USER]),
        [userInfo.user]
    );

    if ((noSelectedIssues && !hideReportOptions) || !hasPermission) {
        showButtons = <></>;
    }

    if (isProcessing) {
        showButtons = <RequestProgress />;
    } else {
        if (hideReportOptions) {
            showButtons = (
                <>
                    {faultySensorReporterState.allowReportLeakAlert && userIsSuperAdmin && (
                        <>
                            <UiButton
                                skin={ERROR}
                                color='error'
                                variant={VARIANT_BUTTON}
                                testid='ReportSlowLeak-FaultySensorReport-Action'
                                disabled={invalidActionButtons}
                                onClick={() => {
                                    setLeakNotification({
                                        id: leakNotifications.slow?.id ?? 0,
                                        show: true,
                                        type: leakNotifications.slow?.key || ''
                                    });
                                }}
                            >
                                {translate('t.report_slow_leak')}
                            </UiButton>
                            <UiButton
                                skin={ERROR}
                                color='primary'
                                variant={VARIANT_BUTTON}
                                testid='ReportFastLeak-FaultySensorReport-Action'
                                disabled={invalidActionButtons}
                                onClick={() => {
                                    setLeakNotification({
                                        id: leakNotifications.fast?.id ?? 0,
                                        show: true,
                                        type: leakNotifications.fast?.key || ''
                                    });
                                }}
                            >
                                {translate('t.report_fast_leak')}
                            </UiButton>
                        </>
                    )}
                    <UiButton
                        skin='#c37e00'
                        color='warning'
                        variant={VARIANT_BUTTON}
                        testid='ReportSensor-FaultySensorReport-Action'
                        disabled={invalidActionButtons}
                        onClick={() => {
                            updateHideReportOptions();
                        }}
                        startIcon={<FontAwesomeIcon icon={['fas', 'bug']} size={'sm'} />}
                    >
                        {translate('t.report_sensor')}
                    </UiButton>
                </>
            );
        }

        if (leakNotification.show && leakNotification.id) {
            showButtons = (
                <UiButton
                    skin={ERROR}
                    color={leakNotification.type === 'fast' ? 'primary' : 'error'}
                    variant={VARIANT_BUTTON}
                    testid={`Report${leakNotification.type}-FaultySensorReport-Action`}
                    onClick={() => {
                        setCaptureImageTrigger(true);
                    }}
                    disabled={captureImageTrigger}
                >
                    {translate(`t.report_${leakNotification.type}_leak`)}
                </UiButton>
            );
        }

        if (faultySensorReporterState.reportDetailId) {
            showButtons = (
                <UiButton
                    skin='success'
                    variant='contained'
                    color='primary'
                    testid='Validate-FaultySensorReport-Action'
                    onClick={() => {
                        verifyReport();
                    }}
                    disabled={!verifyingAllowed.status}
                >
                    {translate('t.validate')}
                </UiButton>
            );

            if (!faultySensorReporterState.notValidate) {
                showButtons = (
                    <UiButton
                        skin='success'
                        variant='contained'
                        color='primary'
                        testid='Reopen-FaultySensorReport-Action'
                        onClick={() => {
                            reopenReport();
                        }}
                    >
                        {translate('t.reopen')}
                    </UiButton>
                );
            }
        }
    }

    return (
        <>
            {showButtons}
            <CancelButton
                color='primary'
                variant='outlined'
                size={SIZE_BUTTON}
                data-testid='Cancel-FaultySensorReport-Action'
                onClick={() => {
                    modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                    setMapState({ action: MapActionsEnum.HIDE_MAP_MESSAGE });
                    setRefreshTableAtom(true);
                }}
            >
                {translate('t.cancel')}
            </CancelButton>
        </>
    );
};

export default ActionButtons;
