import { atom } from 'recoil';
import { AreaFeaturesResponse } from '../../../models/Area.type';
import { AreaTypeModel } from 'models/AreaType.type';

export type fieldError = {
    message: string;
};

export type FormState = {
    mode: 'EDIT' | 'CREATE'; //Move to enum
    selectedArea: Partial<AreaFeaturesResponse> | undefined;
    hasNewChanges: boolean;
    loading?: boolean;
    errors: {
        //Error can be improved by moving it to a validator funct
        name?: fieldError;
        area?: fieldError;
        maxSpeed?: fieldError;
        areaType?: fieldError;
    };
};

export const defaultSelectedArea: Partial<AreaFeaturesResponse> = {
    name: '',
    area: undefined,
    color: 'rgba(255,0,0,0.4)',
    generateAreaEvents: false,
    generateSpeedAlerts: false,
    maintenanceArea: false,
    areaType: undefined
};

const DefaultAreaEditorState = {
    mode: 'EDIT',
    selectedArea: undefined,
    hasNewChanges: false,
    loading: false,
    errors: {
        name: undefined,
        area: { message: 't.required' },
        maxSpeed: undefined
    }
};

export const AreaEditorFormAtom = atom({
    key: 'AreaEditorformAtom',
    default: DefaultAreaEditorState as FormState
});

export enum AreaEditorFormTypesEnum {
    SET_SELECTED_AREA = 'SET_SELECTED_AREA',
    SET_TEMP_SPEED_LVL_1 = 'SET_TEMP_SPEED_LVL_1',
    SET_TEMP_SPEED_LVL_2 = 'SET_TEMP_SPEED_LVL_2',
    SET_TEMP_SPEED_LVL_3 = 'SET_TEMP_SPEED_LVL_3',
    SET_MIN_AREA_SPEED = 'SET_MIN_AREA_SPEED',
    SET_MAX_AREA_SPEED = 'SET_MAX_AREA_SPEED',
    TOGGLE_COUNTER_ZONE = 'TOGGLE_COUNTER_ZONE',
    TOGGLE_MAINTENANCE_ZONE = 'TOGGLE_MAINTENANCE_ZONE',
    TOGGLE_SPEED_ZONE = 'TOGGLE_SPEED_ZONE',
    TOGGLE_TEMP_SPEED_ZONE = 'TOGGLE_TEMP_SPEED_ZONE',
    SET_AREA_NAME = 'SET_AREA_NAME',
    SET_AREA_COLOR = 'SET_AREA_COLOR',
    RESET_SELECTED_AREA = 'RESET_SELECTED_AREA',
    SET_AREA_EDITOR_FORM = 'SET_AREA_EDITOR_FORM',
    SET_AREA_COORDINATES = 'SET_AREA_COORDINATES',
    CREATE_NEW_AREA = 'CREATE_NEW_AREA',
    VALIDATE_FORM = 'VALIDATE_FORM',
    TOGGLE_HAS_NEW_CHANGES = 'TOGGLE_HAS_NEW_CHANGES',
    TOGGLE_LOADING = 'TOGGLE_LOADING',
    SET_AREA_TYPE = 'SET_AREA_TYPE',
    RESET_COLOR_BY_AREA_TYPE = 'RESET_COLOR_BY_AREA_TYPE'
}

type EmptyPayload = {
    type:
        | AreaEditorFormTypesEnum.RESET_SELECTED_AREA
        | AreaEditorFormTypesEnum.TOGGLE_COUNTER_ZONE
        | AreaEditorFormTypesEnum.TOGGLE_HAS_NEW_CHANGES
        | AreaEditorFormTypesEnum.TOGGLE_MAINTENANCE_ZONE
        | AreaEditorFormTypesEnum.TOGGLE_TEMP_SPEED_ZONE
        | AreaEditorFormTypesEnum.TOGGLE_SPEED_ZONE
        | AreaEditorFormTypesEnum.CREATE_NEW_AREA
        | AreaEditorFormTypesEnum.RESET_COLOR_BY_AREA_TYPE;
};

type FormStatePayload = {
    type: AreaEditorFormTypesEnum.SET_AREA_EDITOR_FORM | AreaEditorFormTypesEnum.SET_SELECTED_AREA;
    formPayload: FormState;
};

type AreaPayload = {
    type: AreaEditorFormTypesEnum.SET_AREA_EDITOR_FORM | AreaEditorFormTypesEnum.SET_SELECTED_AREA;
    formPayload: Partial<AreaFeaturesResponse> | undefined;
};

type CoordinateArrayPayload = {
    type: AreaEditorFormTypesEnum.SET_AREA_COORDINATES;
    formPayload: [number, number][] | undefined;
};

type NumberPayload = {
    type:
        | AreaEditorFormTypesEnum.SET_MIN_AREA_SPEED
        | AreaEditorFormTypesEnum.SET_MAX_AREA_SPEED
        | AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_1
        | AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_2
        | AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_3;
    formPayload: number;
};

type StringPayload = {
    type: AreaEditorFormTypesEnum.SET_AREA_NAME | AreaEditorFormTypesEnum.SET_AREA_COLOR;
    formPayload: string;
};

type BooleanPayload = {
    type: AreaEditorFormTypesEnum.TOGGLE_LOADING;
    formPayload: boolean;
};

type AreaTypePayload = {
    type: AreaEditorFormTypesEnum.SET_AREA_TYPE;
    formPayload: AreaTypeModel;
};

type FormAction =
    | EmptyPayload
    | BooleanPayload
    | FormStatePayload
    | NumberPayload
    | StringPayload
    | AreaPayload
    | CoordinateArrayPayload
    | AreaTypePayload;

export const AreaEditorFormDispatcher = (previousState: FormState, setState) => (action: FormAction) => {
    switch (action.type) {
        case AreaEditorFormTypesEnum.SET_SELECTED_AREA: {
            return setState({
                ...previousState,
                selectedArea: action.formPayload,
                hasNewChanges: false,
                loading: false,
                errors: {
                    name: undefined,
                    area: undefined
                },
                mode: 'EDIT'
            });
        }
        case AreaEditorFormTypesEnum.RESET_SELECTED_AREA:
            return setState({
                ...previousState,
                selectedArea: defaultSelectedArea,
                errors: {
                    name: undefined,
                    area: undefined
                },
                hasNewChanges: true,
                loading: false
            });
        case AreaEditorFormTypesEnum.CREATE_NEW_AREA:
            return setState({
                ...previousState,
                ...DefaultAreaEditorState,
                selectedArea: defaultSelectedArea,
                mode: 'CREATE',
                hasNewChanges: false,
                loading: false
            });
        case AreaEditorFormTypesEnum.SET_AREA_EDITOR_FORM:
            return setState({
                ...previousState,
                ...action.formPayload
            });
        case AreaEditorFormTypesEnum.SET_AREA_TYPE: {
            const previousAreaType = previousState.selectedArea?.areaType?.id;
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState['selectedArea'],
                    areaType: action.formPayload,
                    color: action.formPayload?.color
                },
                hasNewChanges: previousAreaType !== action.formPayload.id,
                errors: {
                    ...previousState.errors,
                    areaType: !action.formPayload ? { message: 't.required' } : undefined
                }
            });
        }
        case AreaEditorFormTypesEnum.RESET_COLOR_BY_AREA_TYPE: {
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState['selectedArea'],
                    color: previousState['selectedArea']?.areaType?.color
                },
                hasNewChanges: true
            });
        }
        case AreaEditorFormTypesEnum.SET_AREA_NAME: {
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    name: action.formPayload
                },
                hasNewChanges: true,
                errors: {
                    ...previousState.errors,
                    name:
                        action.formPayload.length <= 0 || action.formPayload[0] === ' '
                            ? { message: 't.required' }
                            : undefined
                }
            });
        }

        case AreaEditorFormTypesEnum.SET_AREA_COLOR:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    color: action.formPayload
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_1:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    maxSpeedTemperatureL1: action.formPayload
                },
                hasNewChanges: true
            });

        case AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_2:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    maxSpeedTemperatureL2: action.formPayload
                },
                hasNewChanges: true
            });

        case AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_3:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    maxSpeedTemperatureL3: action.formPayload
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.SET_MAX_AREA_SPEED:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    maxSpeed: action.formPayload
                },
                hasNewChanges: true,
                errors: {
                    ...previousState.errors,
                    maxSpeed:
                        (previousState.selectedArea?.minSpeed || 0) > action.formPayload
                            ? { message: 't.max_speed_bigger_min_speed' }
                            : undefined
                }
            });
        case AreaEditorFormTypesEnum.SET_MIN_AREA_SPEED:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    minSpeed: action.formPayload
                },
                hasNewChanges: true,
                errors: {
                    ...previousState.errors,
                    maxSpeed:
                        (previousState.selectedArea?.maxSpeed || 0) < action.formPayload
                            ? { message: 't.max_speed_bigger_min_speed' }
                            : undefined
                }
            });
        case AreaEditorFormTypesEnum.TOGGLE_COUNTER_ZONE:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    generateAreaEvents: !previousState.selectedArea?.generateAreaEvents
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.TOGGLE_TEMP_SPEED_ZONE:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    temperatureBasedSpeedAlerts: !previousState.selectedArea?.temperatureBasedSpeedAlerts
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.TOGGLE_HAS_NEW_CHANGES:
            return setState({
                ...previousState,
                hasNewChanges: !previousState.hasNewChanges
            });
        case AreaEditorFormTypesEnum.TOGGLE_MAINTENANCE_ZONE:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    maintenanceArea: !previousState.selectedArea?.maintenanceArea
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.TOGGLE_LOADING:
            return setState({
                ...previousState,
                loading: action.formPayload
            });
        case AreaEditorFormTypesEnum.TOGGLE_SPEED_ZONE:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    generateSpeedAlerts: !previousState.selectedArea?.generateSpeedAlerts,
                    temperatureBasedSpeedAlerts: !previousState.selectedArea?.generateSpeedAlerts
                        ? previousState.selectedArea?.temperatureBasedSpeedAlerts
                        : false
                },
                hasNewChanges: true
            });
        case AreaEditorFormTypesEnum.SET_AREA_COORDINATES:
            return setState({
                ...previousState,
                selectedArea: {
                    ...previousState.selectedArea,
                    area: action.formPayload
                },
                errors: {
                    ...previousState.errors,
                    area: !action.formPayload || action.formPayload.length === 0 ? { message: 't.required' } : undefined
                },
                hasNewChanges: true
            });
        default:
            return setState(previousState);
    }
};
