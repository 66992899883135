import { atom } from 'recoil';
import { TyreDetail } from 'models/Wheel.type';
import { ExternalSystemMddBesTyreLogModel } from 'models/ExternalSystemMddBesTyreLog.type';
import { DateTime } from 'luxon';

export type WheelsType = {
    isLoading: boolean;
    wheels: TyreDetail[];
};

export const SelectedVehicleId = atom<number>({
    key: 'selectedVehicleId',
    default: 0
});

export const SeverityWheels = atom<WheelsType>({
    key: 'wheels',
    default: {
        isLoading: false,
        wheels: []
    }
});

export const BesRankTimeline = atom<{ [key: number]: Partial<ExternalSystemMddBesTyreLogModel> }>({
    key: 'besRankTimeline',
    default: []
});

export const BesRankTicks = atom<number[]>({
    key: 'besRankTicks',
    default: []
});

export enum DatePickerAction {
    APPLY = 'APPLY',
    ZOOM_IN = 'ZOOM_IN',
    PREVIOUS_ZOOM = 'PREVIOUS_ZOOM'
}

export const RankDateTimePicker = atom<{
    current: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
    original: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
    action: DatePickerAction;
}>({
    key: 'RankDateTimePicker',
    default: {
        current: {
            dateFrom: DateTime.now().minus({ days: 30 }),
            dateTo: DateTime.now()
        },
        original: {
            dateFrom: DateTime.now().minus({ days: 30 }),
            dateTo: DateTime.now()
        },
        action: DatePickerAction.APPLY
    }
});
