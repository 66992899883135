import React from 'react';
import {
    HeaderTable,
    RowHeaderTable,
    TableContainerVehicle,
    VehicleStatsTableContent as VehicleStatsTableContentStyle
} from './VehicleStatsTable.style';
import { VehicleStatsTablePropsView } from './VehicleStatsTable.type';
import { Paper, Table, TableBody, TableHead, TableSortLabel } from '@mui/material';
import VehicleStatsRow from '../VehicleStatsRow/VehicleStatsRow';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const VehicleStatsTableContent: React.FC<VehicleStatsTablePropsView> = ({
    columns,
    handleSortRequest,
    isLoading,
    orderDirection,
    vehicleStatsData
}): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);

    return (
        <VehicleStatsTableContentStyle data-testid='VehicleStatsTableContent'>
            {isLoading && <UiLoadingPage size='30px' />}
            {!isLoading && (
                <Paper sx={{ width: '100%' }} elevation={0}>
                    <TableContainerVehicle>
                        <Table stickyHeader aria-label='sticky collapsible table'>
                            <TableHead>
                                <RowHeaderTable>
                                    {columns.map((column) => {
                                        if (!customerSettings.mdd_bes && column.id === 'besRank') return;
                                        if (column.id == 'vehicleName') {
                                            return (
                                                <HeaderTable
                                                    key={column.id}
                                                    align={column.align}
                                                    $minWidth={column.minWidth}
                                                    onClick={handleSortRequest}
                                                >
                                                    <TableSortLabel active={true} direction={orderDirection ?? 'asc'}>
                                                        {column.label}
                                                    </TableSortLabel>
                                                </HeaderTable>
                                            );
                                        }
                                        return (
                                            <HeaderTable
                                                key={column.id}
                                                align={column.align}
                                                $minWidth={column.minWidth}
                                            >
                                                {column.label}
                                            </HeaderTable>
                                        );
                                    })}
                                </RowHeaderTable>
                            </TableHead>
                            <TableBody>
                                {vehicleStatsData.map((row, i) => (
                                    <VehicleStatsRow key={row.name + i} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainerVehicle>
                </Paper>
            )}
        </VehicleStatsTableContentStyle>
    );
};
