import { Box } from '@mui/material';
import styled from 'styled-components';

export const SeverityRankGraphViewContent = styled(Box)`
    display: flex;
    width: 100%;
    > * {
        flex: 1;
        margin-bottom: 5px;
        min-width: 0;
    }
`;
