import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { applyStyleByMode } from 'helpers';

export const SaveRoutePopupContainer = styled.div``;

export const CustomTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin-bottom: 10px;

        ${applyStyleByMode({
            styleOld: `
                    margin: 8px;
                    margin-left: 0px;
                    && label {
                        padding: 0 5px 0 0;
                        background: #fff;
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(14px, -6px) scale(0.9);
                    }
                    & .Mui-disabled:not(.MuiInputLabel-shrink) {
                        color: #3a3633;
                    }
                    & svg {
                        fill: #999999;
                    }
                    `,
            styleJade: 'margin-top: 10px;'
        })}
    }
`;

export const RequestProgress = styled(CircularProgress)({
    color: '#4f714f !important',
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});
