import { VehicleModel } from 'models/Vehicle.type';
import { DefaultValue, atom, atomFamily, selector, selectorFamily } from 'recoil';

type VehicleFilters = {
    input: string;
    filter: any;
};

export const AlertVehicleFilters = atom<Record<string, any>>({
    key: 'AlertVehicleFilters',
    default: {}
});

export const AppliedFilters = atom<Record<string, any>>({
    key: 'AppliedFilters',
    default: {}
});

export const AlertVehicleFiltersParams = selector<Record<string, any>>({
    key: 'AlertVehicleFiltersParams',
    get: ({ get }) => {
        const filters = get(AlertVehicleFilters);
        let params: Partial<VehicleModel> = {};
        if (filters.vehicleConfiguration) {
            params.vehicleConfiguration = filters.vehicleConfiguration.id;
        }

        if (filters.vehicleModelV2) {
            params.vehicleModelV2 = filters.vehicleModelV2.modelName;
        }

        if (filters.vehicleManufacturerV2) {
            params.vehicleManufacturerV2 = filters.vehicleManufacturerV2.manufacturer;
        }
        return params;
    }
});

export const AlertVehicleFiltersFamily = atomFamily<VehicleFilters, string>({
    key: 'AlertVehicleFiltersFamily',
    default: {
        input: '',
        filter: ''
    }
});

export const AlertVehicleFiltersSelector = selectorFamily({
    key: 'AlertVehicleFiltersSelector',
    get:
        (filter: string) =>
        ({ get }) => {
            return get(AlertVehicleFiltersFamily(filter));
        },
    set:
        (filter: string) =>
        ({ set }, newValue: VehicleFilters | DefaultValue) => {
            const value = newValue as VehicleFilters;
            return set(AlertVehicleFiltersFamily(filter), () => ({
                input: value.input,
                filter: value.filter
            }));
        }
});

export const SelectedVehicle = atom<any>({
    key: 'SelectedVehicle',
    default: undefined
});

export const ApplyFilterTimeout = atom<NodeJS.Timeout | null>({
    key: 'ApplyFilterTimeout',
    default: null
});
