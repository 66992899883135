import { CardHeader, Tab, Tabs, useTheme } from '@mui/material';
import styled from 'styled-components';
import { SIZE_TEXT_VARIANT } from '../../variables';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const TabCustom = styled(Tab)<{ ishidden?: boolean }>`
    // max-width: 250px;
    &.MuiButtonBase-root {
        ${(props) => props.ishidden && 'display: none !important;'}
    }
    & .MuiTab-wrapper {
        flex-direction: row;
        align-items: center;
        svg {
            margin-right: 3px;
        }
    }
`;

export const TabsCustom = styled(Tabs)`
    & .MuiTabs-root {
        max-height: 30px;
        min-height: 30px;
        width: 280px;

        & .MuiButtonBase-root {
            text-transform: capitalize !important;
        }
    }
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            button {
                max-height: 20px;
                min-height: 20px;
                font-size: ${common.titleSize};
            }
        `;
    }}
`;

export const CustomCardHeaderWithTab = styled(CardHeader)`
    font-size: ${SIZE_TEXT_VARIANT(-1)};
    height: 30px;
    min-height: 30px;

    & .MuiTabs-root {
        min-height: 20px;
        max-height: 20px;
        border-bottom: none !important;

        &.MuiTab-root {
            font-size: ${SIZE_TEXT_VARIANT(-1)} !important;
        }

        button {
            padding-right: 15px;
            padding-top: 0px;
        }
    }

    &.MuiCardHeader-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            const height = common.easyMode ? '37px' : '30px';
            return `
                min-height: ${height};
                max-height: ${height};
            `;
        }}
        padding: 4px 0px 4px 0px;
        border-bottom: 1px solid #c5c5c5;

        .MuiCardHeader-content {
            width: 93%;
        }

        & .MuiCardHeader-action {
            align-self: center;
            > button,
            .MuiIconButton-root,
            > button:hover,
            .legend > button {
                ${() => {
                    const common = useTheme().palette.common as CommonTypeTheme;
                    return `font-size: ${common.titleSize} !important;`;
                }}
                box-shadow: none;
            }
            > button,
            > div > button {
                padding: 8px;
                display: inline-flex;
            }
            & .MuiAutocomplete-root {
                display: inline-flex;
                width: 150px;
                & .MuiInputBase-root {
                    height: 33px;
                }
                & .MuiAutocomplete-input {
                    padding: 0;
                }
                & .MuiInputBase-root:hover,
                .MuiInputBase-root:focus-within {
                    background-color: rgba(0, 0, 0, 0.3);
                    transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
                    & .MuiOutlinedInput-notchedOutline {
                        border-color: rgba(205, 201, 198, 0.2);
                    }
                }
            }
        }
    }
`;
