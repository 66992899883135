import React from 'react';
import { RequestProgress, SaveRoutePopupContainer } from './SaveRoutePopup.style';
import { SaveRouteViewProps } from './SaveRoutePopup.type';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ShowSaveRouteModal } from '../../states/global/Modal';
import Modal from '../Modal';
import { SaveRoute } from '../../states/component/SaveRoutePopup';
import Button from '../Ui/Components/UiButton';
import { Alert, AlertTitle } from '@mui/material';
import { MapActionData } from '../../states/global/Map';
import { DateTimePicker } from '../../states/global/Statistics';
import { useTranslation } from 'react-i18next';
import { SaveRoutePopupFormik } from './SaveRoutePopup.formik';
import useConverter from 'components/CustomHooks/Converter/Converter';

const buttons = (isProcessing: boolean, disabled: boolean, translate: any): JSX.Element => {
    if (isProcessing) {
        return <RequestProgress />;
    }
    return (
        <Button
            skin={'success'}
            form='save-route-form'
            type='submit'
            disabled={disabled}
            color='primary'
            variant='contained'
        >
            {translate('t.save')}
        </Button>
    );
};

export const SaveRoutePopupContent: React.FC<SaveRouteViewProps> = (props): JSX.Element => {
    const resetSaveRoute = useResetRecoilState(SaveRoute);
    const saveRouteModal = useSetRecoilState(ShowSaveRouteModal);
    const globalDateTimePicker = useRecoilValue(DateTimePicker);
    const mapActionData = useRecoilValue(MapActionData);
    const { t: translate } = useTranslation();
    const { fromUserTimezoneToTimezone, dateTimeFormat } = useConverter();

    return (
        <SaveRoutePopupContainer data-testid='SaveRoutePopupContent'>
            <Modal
                id={`save-route-modal-${new Date().getTime()}`}
                leftTitle={translate('t.save_route')}
                type={'ShowSaveRouteModal'}
                forceClose={props.forceClose}
                onClose={() => {
                    resetSaveRoute();
                    saveRouteModal(false);
                }}
                width={595}
                buttons={buttons(props.isProcessing, !mapActionData.journey?.length, translate)}
                content={
                    <>
                        <SaveRoutePopupFormik sendData={props.sendData} />
                        {!mapActionData.journey?.length && (
                            <Alert severity='warning' style={{ marginBottom: '5px', marginTop: '15px' }}>
                                <AlertTitle>
                                    <strong>
                                        {translate('t.no_data_for_period_time_from_to', {
                                            time1: fromUserTimezoneToTimezone(
                                                globalDateTimePicker.current.dateFrom
                                            ).toFormat(dateTimeFormat('dateTime', false)),
                                            time2: fromUserTimezoneToTimezone(
                                                globalDateTimePicker.current.dateTo
                                            ).toFormat(dateTimeFormat('dateTime', false))
                                        })}
                                    </strong>
                                </AlertTitle>
                            </Alert>
                        )}
                    </>
                }
            ></Modal>
        </SaveRoutePopupContainer>
    );
};
