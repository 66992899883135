import PortalApiClient from 'helpers/api/PortalApiClient';
import { CurrentExternalSystemsResponse, ExternalSystemModel } from '../models/ExternalSystem.type';
import { Paginator } from 'helpers/api/type';

export default class ExternalSystem extends PortalApiClient<ExternalSystemModel> {
    route = 'external-system';

    getCurrent = async (): Promise<CurrentExternalSystemsResponse> => {
        return await this.get({ extendUrl: 'current' });
    };

    getAllExternalSystems = async (): Promise<Paginator<ExternalSystemModel>> => {
        return await this.getTable({ criteria: { page: 1, limit: 100 } });
    };
}
