import React from 'react';
import { useRecoilValue } from 'recoil';
import { applyStyleByMode } from 'helpers';
import { Theme, ThemeMode } from 'states/global/Theme';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import * as type from './HubConnectionIndicator.type';
import * as view from './HubConnectionIndicator.view';
import { DateTime, Duration } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const isLastTimeConnectedInRange = (
    lastTimeConnected: DateTime | string | null,
    range: number,
    unit: 'days' | 'minutes' = 'minutes'
): boolean => {
    if (lastTimeConnected) {
        const lastTimeParsed = DateTime.fromISO(lastTimeConnected);
        const now = DateTime.utc();
        const diff = now.diff(lastTimeParsed);

        if (!isNaN(diff.valueOf())) {
            const duration = Duration.fromMillis(diff.valueOf());
            const lastTimeConnectedDiff = duration.shiftTo(unit).toObject();

            if (lastTimeConnectedDiff && lastTimeConnectedDiff[unit] < range) {
                return true;
            }
        }
    }

    return false;
};

const getIconColor = (lastTimeConnected: DateTime | string | null, color: string, theme: string): string => {
    if (isLastTimeConnectedInRange(lastTimeConnected, 1, 'days')) {
        if (isLastTimeConnectedInRange(lastTimeConnected, 15.01, 'minutes')) {
            return '#158015';
        }
        return theme === ThemeMode.dark ? '#ffd372' : '#de9d0d';
    }

    return color;
};

const HubConnectionIndicator: React.FC<type.HubConnectionIndicatorProps> = ({
    connectionType,
    lastTimeConnected
}): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);

    return (
        <view.HubConnectionIndicatorContent
            data-testid={'HubConnectionIndicator-testid'}
            connectionType={connectionType}
            isNotTransmittingHub={connectionType == 'wifi' && !isLastTimeConnectedInRange(lastTimeConnected, 1, 'days')}
            color={getIconColor(
                lastTimeConnected,
                applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                ThemeMode?.mode
            )}
        />
    );
};

export default Wrapper(HubConnectionIndicator);
