import React, { useMemo } from 'react';
import { UiTableRefreshBtnViewContent } from './UiTableRefreshBtn.style';
import { UiTableRefreshBtnProps } from './UiTableRefreshBtn.type';
import { Refresh } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { QueryFn } from '../UiTable/TableAtom';

const UiTableRefreshBtnContent: React.FC<UiTableRefreshBtnProps> = ({ queryKey }): JSX.Element => {
    const queryFn = useRecoilValue(QueryFn(`${queryKey}-QueryFn`));

    const refreshBtn = useMemo(
        () => (
            <UiTableRefreshBtnViewContent data-testid='RefreshBtn' size='small'>
                <Refresh
                    onClick={() => {
                        queryFn.refetch && queryFn.refetch instanceof Function && queryFn.refetch();
                    }}
                />
            </UiTableRefreshBtnViewContent>
        ),
        []
    );
    return <React.Fragment>{refreshBtn}</React.Fragment>;
};

export default UiTableRefreshBtnContent;
