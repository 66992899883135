import { Button } from '@mui/material';
import styled from 'styled-components';
import { LINK } from '../../Ui/colors';

export const RoutesTableViewContent = styled.div``;

export const ActionButton = styled(Button)`
    min-width: 20px !important;
    max-width: 20px !important;
`;

export const RemoveTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0 0 0 !important;
`;

export const A = styled.a`
    color: ${LINK};
`;
