import PortalApi from 'helpers/api/PortalApiClient';
import AzureMapModel from '../models/AzureMap.type';

export default class AzureMap extends PortalApi<AzureMapModel> {
    route = 'azure-map';

    getAzureToken = async (): Promise<{ token: string }> => {
        return await this.get({ extendUrl: 'token' });
    };
}
