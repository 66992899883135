import React from 'react';
import { CustomerReportViewContent } from './CustomerReport.style';
import { CustomerReportViewProps } from './CustomerReport.type';
import ReportTable from '../../components/Customer/ReportTable/ReportTable';

export const CustomerReportContent: React.FC<CustomerReportViewProps> = (): JSX.Element => {
    return (
        <CustomerReportViewContent data-testid='CustomerReportContent'>
            <ReportTable />
        </CustomerReportViewContent>
    );
};
