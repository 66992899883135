import React, { useCallback } from 'react';
import { DeviceInformationTableContent } from './DeviceInformationTable.view';
import { DeviceInformationList, DeviceInformationTableProps } from './DeviceInformationTable.type';
import { Wrapper } from 'helpers/wrapper';
import DeviceInformationTableLoader from './DeviceInformationTableLoader';
import { useTranslation } from 'react-i18next';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { faCircleInfo, faScannerImage, faNetworkWired } from '@fortawesome/pro-solid-svg-icons';

const isHotspot = (wifiChannel: string | null): string => {
    return +(wifiChannel || 0) > 0 ? 'active' : 'inactive';
};

const DeviceInformationTable: React.FC<DeviceInformationTableProps> = ({ isLoading, deviceData }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    const getDeviceInfo = useCallback(() => {
        let data: DeviceInformationList[] = [];

        if (deviceData && Object.keys(deviceData).length) {
            data = [
                {
                    title: translate('t.device_information'),
                    icon: faScannerImage,
                    content: [
                        { label: `${translate('t.device')} ID`, value: deviceData.id ?? '-' },
                        {
                            label: translate('t.mounted_in_vehicle'),
                            value: (
                                <UiLink
                                    content={deviceData.vehicle?.name ?? '-'}
                                    title={translate('t.vehicle_detail')}
                                    url={
                                        deviceData.vehicle
                                            ? `/vehicle-status?vehicle=${deviceData.vehicle?.id}`
                                            : undefined
                                    }
                                    styleCustom={{ padding: '0' }}
                                    testId='deviceInfo-LinkTo-VehicleStatus-Button'
                                />
                            )
                        },
                        { label: translate('t.customer_name'), value: deviceData.customer?.name ?? '-' },
                        { label: translate('t.mac_address'), value: deviceData.uuid ?? '-' },
                        { label: translate('t.firmware'), value: deviceData.deviceInfo.fmw ?? '-' },
                        { label: translate('t.sensor_types'), value: deviceData.deviceInfo.seq ?? '-' },
                        { label: translate('t.service'), value: deviceData.deviceInfo.service ?? '-' },
                        { label: translate('t.system_tools'), value: deviceData.deviceInfo.tools ?? '-' },
                        { label: translate('t.revision'), value: deviceData?.deviceRevision?.name ?? '-' }
                    ]
                },
                {
                    title: translate('t.network_settings'),
                    icon: faNetworkWired,
                    content: [
                        {
                            label: translate('t.wifi_band'),
                            value: deviceData.deviceInfo.wifiBand ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: translate('t.channel'),
                            value: deviceData.deviceInfo.wifiChannel ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: 'Wi-Fi Mode',
                            value: deviceData?.deviceInfo?.wifiMode ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: 'Wi-Fi SSID',
                            value: deviceData?.deviceInfo?.wifiSSID ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: 'IP',
                            value: deviceData?.deviceInfo?.ip ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: translate('t.mask'),
                            value: deviceData?.deviceInfo?.mask ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: translate('t.gateway'),
                            value: deviceData?.deviceInfo?.gateway ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        },
                        {
                            label: translate('t.hotspot'),
                            value: translate(`t.${isHotspot(deviceData.deviceInfo.wifiChannel)}`)
                        },
                        {
                            label: `Wi-Fi ${translate('t.country')}`,
                            value: deviceData?.deviceInfo?.wifiCountry ?? '-',
                            hidden: isHotspot(deviceData.deviceInfo.wifiChannel) === 'inactive'
                        }
                    ]
                },
                {
                    title: translate('t.other_information'),
                    icon: faCircleInfo,
                    content: [
                        {
                            label: translate('t.manufacturing_date'),
                            value: deviceData.createdAt ? fromUTCToTimezone(deviceData.createdAt || '', true) : '-'
                        },
                        {
                            label: translate('t.device_age'),
                            value: deviceData.createdAt ? (
                                <>
                                    {`${Math.floor(
                                        DateTime.now().diff(DateTime.fromISO(deviceData.createdAt), [
                                            'years',
                                            'months',
                                            'days'
                                        ]).values.years
                                    )} ${translate('t.years')} `}
                                    {`${Math.floor(
                                        DateTime.now().diff(DateTime.fromISO(deviceData.createdAt), [
                                            'years',
                                            'months',
                                            'days'
                                        ]).values.months
                                    )} ${translate('t.months')} `}
                                    {`${Math.floor(
                                        DateTime.now().diff(DateTime.fromISO(deviceData.createdAt), [
                                            'years',
                                            'months',
                                            'days'
                                        ]).values.days
                                    )} ${translate('t.days')}`}
                                </>
                            ) : (
                                '-'
                            )
                        },
                        {
                            label: 'eMMC',
                            value: deviceData?.deviceInfo?.deviceEmmcStatus?.wearPerc
                                ? `${deviceData.deviceInfo.deviceEmmcStatus.wearPerc}%`
                                : '-'
                        },
                        { label: translate('t.mount'), value: deviceData?.deviceInfo.mount ?? '-' }
                    ]
                }
            ];
        }

        return data;
    }, [deviceData, isLoading]);

    if (isLoading) {
        return <DeviceInformationTableLoader />;
    }

    return <DeviceInformationTableContent data-testid='DeviceInformationTable-testid' getDeviceInfo={getDeviceInfo} />;
};

export default Wrapper(DeviceInformationTable);
