import React from 'react';
import { SystemTotalsType } from './SystemTotals.type';
import Sensors from 'api/Sensors';
import { GetSystemTotalModel } from 'models/Sensors.type';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const sensorsApi = new Sensors();

export const GetSystemTotalsData = ({ options, children }: SystemTotalsType) => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<GetSystemTotalModel>(
        ['GetSystemTotals', userInfo.user?.customer.id],
        () => sensorsApi.getSystemTotalsNew(),
        options
    );

    return <>{children(useQueryProperties)}</>;
};
