import React, { useEffect, useState } from 'react';
import {
    CustomButton,
    CustomList,
    CustomListButton,
    CustomListText,
    CustomTextField,
    FieldContainer,
    HrsMinContainer,
    HelperText
} from './TimeSelector.style';
import { useRecoilValue } from 'recoil';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TimeSelectorProps } from './TimeSelector.type';
import { Is12HrsFormat } from 'helpers/Converters';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const TimeSelector: React.FC<TimeSelectorProps> = ({
    dateValueStaticDatePicker,
    onTimeChange,
    minutesInterval,
    hoursInterval,
    disabled
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [is12HrsFormatSelected, setIs12HrsFormatSelected] = useState<number | null>(
        dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1
    );
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const [hours, setHours] = useState<number | null>(null);
    const [minutes, setMinutes] = useState<number | null>(null);

    useEffect(() => {
        setHours(fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour));
        setMinutes(dateValueStaticDatePicker.toObject().minute);
        setIs12HrsFormatSelected(dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1);
    }, [dateValueStaticDatePicker]);

    useEffect(() => {
        return () => {
            setHours(null);
            setMinutes(null);
            setIs12HrsFormatSelected(null);
        };
    }, []);

    const handleListItemClick = (event, index) => {
        setIs12HrsFormatSelected(index);
        onTimeChange(
            index == 2 ? dateValueStaticDatePicker.plus({ hours: 12 }) : dateValueStaticDatePicker.minus({ hours: 12 })
        );
    };

    const setHoursMinutes = (props): DateTime => {
        return DateTime.fromObject({
            year: dateValueStaticDatePicker.toObject().year,
            month: dateValueStaticDatePicker.toObject().month,
            day: dateValueStaticDatePicker.toObject().day,
            hour: props.hour ?? dateValueStaticDatePicker.toObject().hour,
            minute: props.minute ?? dateValueStaticDatePicker.toObject().minute
        });
    };

    const fromMSTto12HoursFormat = (value: number): number => {
        if (value != 0) return is12HrsFormat ? (value > 12 ? value - 12 : value) : value;
        else return is12HrsFormat ? 12 : value;
    };

    const inRangeHours = (value: number): boolean => {
        return is12HrsFormat ? (value <= 12 && value >= 1 ? true : false) : value <= 23 && value >= 0 ? true : false;
    };

    const inRangeMinutes = (value: number): boolean => {
        return value <= 59 && value >= 0 ? true : false;
    };

    const handleHoursTimeButtons = (dateValue: DateTime, action: string, add: boolean): DateTime => {
        return action == 'HOURS'
            ? add == false
                ? dateValue.minus({ hours: hoursInterval ?? 1 })
                : dateValue.plus({ hours: hoursInterval ?? 1 })
            : add == false
            ? dateValue.minus({ minutes: minutesInterval ?? 1 })
            : dateValue.plus({ minutes: minutesInterval ?? 1 });
    };

    return (
        <>
            <div></div>
            <HrsMinContainer $ampm={is12HrsFormat}>
                <FieldContainer className='innerDiv'>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => {
                            onTimeChange(handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', true));
                        }}
                    >
                        <ArrowDropUp />
                    </CustomButton>

                    <CustomTextField
                        disabled={disabled}
                        placeholder={is12HrsFormat ? 'HH' : 'hh'}
                        error={
                            !inRangeHours(hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour))
                        }
                        value={hours != null && hours < 10 ? `0${hours}` : hours}
                        onChange={(newDate) => {
                            if (!Number.isNaN(Number(newDate.target.value))) {
                                setHours(Number(newDate.target.value));

                                if (inRangeHours(Number(newDate.target.value))) {
                                    onTimeChange(setHoursMinutes({ hour: Number(newDate.target.value) }));
                                }
                            }
                        }}
                        defaultValue={
                            fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour) < 10
                                ? `0${fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)}`
                                : fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)
                        }
                        inputProps={{
                            'data-testid': 'DateTimeRange_TimeSelector_Hours_TextField'
                        }}
                    ></CustomTextField>

                    <CustomButton
                        disabled={disabled}
                        onClick={() => {
                            onTimeChange(handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', false));
                        }}
                    >
                        <ArrowDropDown />
                    </CustomButton>
                </FieldContainer>
                <FieldContainer className='innerDiv'>:</FieldContainer>

                <FieldContainer className='innerDiv'>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => {
                            onTimeChange(handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', true));
                        }}
                    >
                        <ArrowDropUp />
                    </CustomButton>
                    <CustomTextField
                        disabled={disabled}
                        placeholder='mm'
                        value={minutes && minutes < 10 ? `0${minutes}` : minutes}
                        error={!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute)}
                        onChange={(newDate) => {
                            if (!Number.isNaN(Number(newDate.target.value))) {
                                setMinutes(Number(newDate.target.value));

                                if (newDate.target.value && inRangeMinutes(Number(newDate.target.value))) {
                                    onTimeChange(setHoursMinutes({ minute: Number(newDate.target.value) }));
                                }
                            }
                        }}
                        defaultValue={
                            dateValueStaticDatePicker.toObject().minute < 10
                                ? `0${dateValueStaticDatePicker.toObject().minute}`
                                : dateValueStaticDatePicker.toObject().minute
                        }
                        inputProps={{
                            'data-testid': 'DateTimeRange_TimeSelector_Minutes_TextField'
                        }}
                    ></CustomTextField>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => {
                            onTimeChange(handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', false));
                        }}
                    >
                        <ArrowDropDown />
                    </CustomButton>
                </FieldContainer>

                {is12HrsFormat && (
                    <CustomList>
                        <CustomListButton
                            disabled={disabled}
                            selected={is12HrsFormatSelected === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                        >
                            <CustomListText primary={'AM'} />
                        </CustomListButton>
                        <CustomListButton
                            disabled={disabled}
                            selected={is12HrsFormatSelected === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                        >
                            <CustomListText primary={'PM'} />
                        </CustomListButton>
                    </CustomList>
                )}
            </HrsMinContainer>

            <div></div>
            {!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute) && (
                <HelperText $ampm={is12HrsFormat}> {translate('t.the_date_format_is_invalid')}</HelperText>
            )}
            {!inRangeHours(hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)) && (
                <HelperText $ampm={is12HrsFormat}>{translate('t.the_date_format_is_invalid')}</HelperText>
            )}
        </>
    );
};

export default TimeSelector;
