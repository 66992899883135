import React from 'react';
import * as style from './TrackingMap.style';
import { TrackingMapViewProps } from './TrackingMap.type';
import AddArea from '../../components/AddArea';
import Map from '../../components/DashboardMap';
import TopBar from '../../components/TrackingMapTopBar';

export const TrackingMapContent: React.FC<TrackingMapViewProps> = (): JSX.Element => {
    return (
        <style.TrackingMapContent data-testid='TrackingMapContent'>
            <AddArea data-testid='TrackingMapContent-AddArea' />
            <TopBar data-testid='TrackingMapContent-TopBar' />
            <Map data-testid='TrackingMapContent-Map' enableDrawing={true} height={75} unit='vh' />
        </style.TrackingMapContent>
    );
};
