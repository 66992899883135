import MuiDialogContent from '@mui/material/DialogContent';
import React from 'react';
import { ModalViewProps } from './Modal.type';
import { CancelButton, ContainerDialogTitle, DialogActions, DialogBody, DialogTitle } from './Modal.style';

import { useTranslation } from 'react-i18next';
import { SIZE_BUTTON } from '../Ui/variables';
import UiScroll from '../Ui/Components/UiScroll/UiScroll';
import { useMediaQuery, useTheme } from '@mui/material';

export const ModalContent: React.FC<ModalViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    return (
        <>
            <span
                role='button'
                tabIndex={0}
                style={{ cursor: props.disabled ? 'auto' : 'pointer' }}
                onClick={() => {
                    if (!props.disabled) {
                        props.toggleModal();
                        if (props.onOpen) {
                            props.onOpen();
                        }
                    }
                }}
            >
                {props.rootEl}
            </span>
            <DialogBody
                id={props.id}
                onClose={() => {
                    props.toggleModal();
                    if (props.onClose) {
                        props.onClose();
                    }
                }}
                $allowContentOverflow={props.$allowContentOverflow}
                aria-labelledby='customized-dialog-title'
                open={props.isOpen}
                data-testid={`${props.testid || 'ModalContent'}-Content`}
                width={props.width}
                height={props.height}
                widthunit={props.widthUnit}
                heightunit={props.heightUnit}
                fullScreen={smallScreen}
                $maxWidth={props.maxWidth}
                $maxHeight={props.maxHeight}
            >
                <ContainerDialogTitle>
                    <DialogTitle>{props.leftTitle}</DialogTitle>
                </ContainerDialogTitle>
                <MuiDialogContent>
                    <UiScroll height='calc(100% - 10px)' content={props.content || props.children} />
                </MuiDialogContent>
                <DialogActions>
                    {props.buttons}
                    <CancelButton
                        size={SIZE_BUTTON}
                        variant='outlined'
                        onClick={() => {
                            props.toggleModal();
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}
                        customcolor={props.cancelColor}
                        data-testid={`${props.testid || ''}-CloseModal-Button`}
                    >
                        {translate('t.cancel')}
                    </CancelButton>
                </DialogActions>
            </DialogBody>
        </>
    );
};
