import { FormHelperText, useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { BACKGROUND_GREY, GRAY, WHITE } from 'components/Ui/colors';
import PhoneInput from 'react-phone-number-input';
import { ThemeMode } from 'states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiPhoneInputNumberContent = styled.div<{ isValid: string | null }>`
    .PhoneInputCustom input {
        padding: 2px 5px 7px 5px; old 0px 5px 7px
        border: none !important;
        background: transparent;

        border-radius: 4px;

        :focus ~ & {
            border: 2px solid blue;
        }

        :focus + & {
            border: 2px solid blue;
        }
        ${(props) => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `
                    // border: thin ${props.isValid ? '#f44336 solid' : '#0000003b solid'};
                    color: currentColor;
                    &:focus-visible {
                        border: none !important;
                    }
                `,
                dark: `
                    border: none !important;
                    color: ${WHITE};
                    &:focus-visible {
                        border: none !important;
                    }
                `
            });
        }}
        background: transparent;
    }

    .PhoneInputCustom .PhoneInputCountry {
        display: none;
    }

    .PhoneInput {
        display: grid !important;
    }
`;

export const FormHelperTextError = styled(FormHelperText)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'color: #a90329 !important;',
            dark: 'color: #f44336 !important;'
        });
    }}
    margin-left: 15px !important;
`;

export const ContainerInputPhone = styled.fieldset<{ isValid: string | null }>`
    ${({ isValid }) => {
        const borderColorLight = isValid ? '#a90329' : '#c4c4c4';
        const borderColorDark = useTheme().palette.mode === ThemeMode.light ? '#f44336' : '#5a6168';
        const borderColor =
            useTheme().palette.mode === ThemeMode.light ? borderColorLight : isValid ? '#f44336' : borderColorDark;
        return `
            border-color: ${borderColor};
            & legend {
                color: ${borderColor};
            }
        `;
    }}

    border-radius: 5px;
    border-width: thin;
    border-style: solid;
    padding: 0px 5px;
    margin-top: -8px;
    overflow: hidden;
    height: 42px;
    width: 99%;

    & legend {
        padding-inline: 4px !important;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.easyMode ? common.textSize : '0.71rem'};`;
        }}
        text-align: initial;
        margin: 0px !important;
        width: auto;
    }

    ${({ isValid }) => {
        const borderColorLight = isValid
            ? useTheme().palette.mode === ThemeMode.light
                ? '#a90329'
                : '#f44336'
            : '#696969';
        const borderColorLightFocus = isValid
            ? useTheme().palette.mode === ThemeMode.light
                ? '#a90329'
                : '#f44336'
            : useTheme().palette.mode === ThemeMode.light
            ? 'black'
            : 'white';
        const borderColorDark = isValid ? '#f44336' : '#fff';
        const borderColor = useTheme().palette.mode === ThemeMode.light ? borderColorLight : borderColorDark;
        return `
            &:hover {
                border-color: ${borderColor};
            }
            & legend {
                color: ${borderColor};
            }
            &:focus-within {
                border-width: 2px;
                border-color: ${borderColorLightFocus};

                & legend {
                    color: ${borderColorLightFocus};
                }
            }
        `;
    }}
`;

export const StyledPhoneInput = styled(PhoneInput)`
    &&& {
        input {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}
`;

export const StyledPhoneMessage = styled('span')`
    ${() => {
        return `
            display: flex;
            font-size: 0.75rem;
            padding-left: 10px;
            margin-top: 3px;
            color: ${useTheme().palette.mode === ThemeMode.light ? GRAY : BACKGROUND_GREY};   
        `;
    }}
`;
