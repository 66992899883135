import React from 'react';
import * as style from './VehicleMapPopupDetails.style';
import { VehicleDetailsType, VehicleMapPopupDetailsProps } from './VehicleMapPopupDetails.type';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { CustomPopupTable } from '../VehicleMapPopup/VehicleMapPopup.style';
import { useRecoilValue } from 'recoil';
import { DateTimeFormat } from 'helpers/Converters';
import { MapActionData } from '../../states/global/Map';
import useConverter from '../CustomHooks/Converter/Converter';
import UiLink from '../Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { DARK_STEEL_GREY, BACKGROUND_BLUE } from 'components/Ui/colors';
import { DateTime, Duration } from 'luxon';

const getDiff = (duration: Duration): string => {
    let durationTime = '-';
    if (duration.months) {
        durationTime = `${Math.round(duration.months)} months ago`;
    } else if (duration.days) {
        durationTime = `${Math.round(duration.days)} days ago`;
    } else if (duration.hours) {
        durationTime = `${Math.round(duration.hours)} hours ago`;
    } else if (duration.minutes) {
        durationTime = `${Math.round(duration.minutes)} minutes ago`;
    } else if (duration.seconds) {
        durationTime = `${Math.round(duration.seconds)} seconds ago`;
    }
    return durationTime;
};

const VehicleMapPopupDetailsContent: React.FC<VehicleMapPopupDetailsProps> = (): JSX.Element => {
    const dateTimeFormat = useRecoilValue(DateTimeFormat);
    const { t: translate } = useTranslation();
    const mapActionData = useRecoilValue(MapActionData);
    const vehicleDetails: VehicleDetailsType =
        mapActionData?.vehiclesPosition && mapActionData?.clickedVehicle
            ? mapActionData?.vehiclesPosition[mapActionData?.clickedVehicle]
            : undefined;
    const lastTransmissionDiff = DateTime.local().diff(
        DateTime.fromISO(vehicleDetails?.vehicle.device?.deviceInfo?.lastTransmission || '', dateTimeFormat(false)),
        ['months', 'days', 'hours', 'minutes', 'seconds']
    );
    const duration: string = getDiff(lastTransmissionDiff);
    const { fromServerToUserUnit, convertType } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    return (
        <style.VehicleMapPopupDetailsContent data-testid='VehicleMapPopupDetailsContent'>
            <TableContainer>
                <CustomPopupTable size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>{translate('t.vehicle_name')}</TableCell>
                            <TableCell align='right'>
                                <UiLink
                                    testId={'VehicleMapPopup-UiLink'}
                                    $padding='0'
                                    content={vehicleDetails?.vehicle.name || ' '}
                                    url={`/vehicle-status?vehicle=${vehicleDetails?.vehicle.id}`}
                                    color={applyStyleByMode({
                                        styleOld: '#0071c7',
                                        theme: ThemeMode?.mode,
                                        light: DARK_STEEL_GREY,
                                        dark: BACKGROUND_BLUE
                                    })}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.latitude')}</TableCell>
                            <TableCell align='right'>
                                {vehicleDetails?.latitude ?? translate('t.not_available')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.longitude')}</TableCell>
                            <TableCell align='right'>
                                {vehicleDetails?.longitude ?? translate('t.not_available')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.speed')}</TableCell>
                            <TableCell align='right'>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: vehicleDetails?.speed ?? 0,
                                    displayUnits: true,
                                    fixed: 1
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.altitude')}</TableCell>
                            <TableCell align='right'>
                                {fromServerToUserUnit({
                                    type: convertType.altitude,
                                    value: vehicleDetails?.altitude ?? 0,
                                    displayUnits: true,
                                    fixed: 1
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.last_transmission_hub')}</TableCell>
                            <TableCell align='right'>{duration}</TableCell>
                        </TableRow>
                    </TableBody>
                </CustomPopupTable>
            </TableContainer>
        </style.VehicleMapPopupDetailsContent>
    );
};

export default VehicleMapPopupDetailsContent;
