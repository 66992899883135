import React from 'react';
import { ExcessiveAccViewContent } from './ExcessiveAcc.style';
import { ExcessiveAccViewProps } from './ExcessiveAcc.type';
import { useTranslation } from 'react-i18next';
import ExcessiveAcc from 'components/Tracking/ExcessiveAcc/ExcessiveAcc';
import { useRecoilState } from 'recoil';
import { MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import Widget from 'components/Ui/Components/UiWidget';
import Map from 'components/Map';
import { MapAction } from 'states/global/Map';
import { faCarTilt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcessiveAccLegend from './ExcessiveAccLegend';

export const ExcessiveAccContent: React.FC<ExcessiveAccViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);

    return (
        <ExcessiveAccViewContent data-testid='ExcessiveAccContent'>
            <ExcessiveAcc data-testid={'ExcessiveAccContent-ExcessiveAcc'} />
            <Widget
                data-testid={'ExcessiveAccContent-Widget'}
                title={translate('t.excessive_lateral_load')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={<FontAwesomeIcon icon={faCarTilt} data-testid={'ExcessiveAccContent-FontAwesomeIcon'} />}
                $contentHeight={72}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
                applyPadding={false}
                marginBottom={false}
            >
                <ExcessiveAccLegend />
                <Map mapMode={MapModes.EXCESSIVE_ACC} data-testid={'ExcessiveAccContent-Map'} />
            </Widget>
        </ExcessiveAccViewContent>
    );
};
