import React, { useState, useEffect } from 'react';
import { ProblemsRadiosContent } from './ProblemsRadios.view';
import { ProblemsRadiosProps, ProblemsRadiosType } from './ProblemsRadios.type';
import { useSetRecoilState } from 'recoil';
import { ProblemsSensorReporterState, ProblemsSensorReporterType } from 'states/component/FaultySensorReporter';
import { useTranslation } from 'react-i18next';

const ProblemsRadios: React.FC<ProblemsRadiosProps> = (): JSX.Element => {
    const setProblemsSensorReporterState = useSetRecoilState(ProblemsSensorReporterState);
    const { t: translate } = useTranslation();
    const [faultyPress, setFaultyPress] = useState<boolean>(false);
    const [faultyTemp, setFaultyTemp] = useState<boolean>(false);
    const [pressureActionChecked, setPressureActionChecked] = useState<boolean>(false);
    const [tempActionChecked, setTempActionChecked] = useState<boolean>(false);
    const [problemRadios, setProblemRadios] = useState<ProblemsRadiosType[]>([
        {
            status: false,
            label: translate('t.faulty_temperature'),
            name: 'faulty_temperature',
            hasAction: true
        },
        { status: false, label: translate('t.faulty_pressure'), name: 'faulty_pressure', hasAction: true },
        {
            status: false,
            label: translate('t.transmitting_poorly'),
            name: 'transmitting_poorly',
            hasAction: false
        },
        { status: false, label: translate('t.no_transmitting'), name: 'no_transmitting', hasAction: false },
        { status: false, label: translate('t.damaged_sensor'), name: 'damaged_sensor', hasAction: false },
        { status: false, label: translate('t.damaged_magmount'), name: 'damaged_magmount', hasAction: false }
    ]);

    const setPressureActionCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        setPressureActionChecked(checked);
        setProblemsSensorReporterState((currentProblems): ProblemsSensorReporterType => {
            const newProblems: ProblemsSensorReporterType = { ...currentProblems, faulty_pressure: checked };
            return newProblems;
        });
    };

    const setTempActionCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        setTempActionChecked(checked);
        setProblemsSensorReporterState((currentProblems): ProblemsSensorReporterType => {
            const newProblems: ProblemsSensorReporterType = { ...currentProblems, faulty_temperature: checked };
            return newProblems;
        });
    };

    const updateProblemRadios = (type: string): void => {
        setProblemRadios((currentProblems): ProblemsRadiosType[] => {
            return currentProblems.map((problem: ProblemsRadiosType) => {
                if (problem.name === type) {
                    problem.status = !problem.status;
                }
                return problem;
            });
        });
    };

    const problemsUpdate = () => {
        const checkedProblems: ProblemsRadiosType[] = problemRadios.filter((prob) => prob.status);
        const pressureChecked: ProblemsRadiosType[] = problemRadios.filter((prob) => prob.name === 'faulty_pressure');
        const temperatureChecked: ProblemsRadiosType[] = problemRadios.filter(
            (prob) => prob.name === 'faulty_temperature'
        );

        const isPressureChecked: boolean = pressureChecked[0].status;
        const isTemperatureChecked: boolean = temperatureChecked[0].status;

        setFaultyPress(isPressureChecked);
        setFaultyTemp(isTemperatureChecked);

        if (!isPressureChecked) {
            setPressureActionChecked(isPressureChecked);
        }
        if (!isTemperatureChecked) {
            setTempActionChecked(isTemperatureChecked);
        }

        const problems: ProblemsSensorReporterType = checkedProblems.reduce(
            (map, prob: ProblemsRadiosType): ProblemsSensorReporterType => {
                let hasAction = false;
                if (prob.name === 'faulty_pressure') {
                    hasAction = pressureActionChecked;
                }
                if (prob.name === 'faulty_temperature') {
                    hasAction = tempActionChecked;
                }
                map[prob.name] = hasAction;
                return map;
            },
            {}
        );

        setProblemsSensorReporterState(problems);
    };

    useEffect(() => {
        problemsUpdate();
    }, [problemRadios]);

    return (
        <ProblemsRadiosContent
            data-testid='ProblemsRadios-testid'
            faultyPress={faultyPress}
            faultyTemp={faultyTemp}
            pressureActionChecked={pressureActionChecked}
            tempActionChecked={tempActionChecked}
            problemRadios={problemRadios}
            setPressureActionChecked={setPressureActionCheckbox}
            setTempActionChecked={setTempActionCheckbox}
            updateProblemRadios={updateProblemRadios}
        />
    );
};

export default ProblemsRadios;
