import { useEffect, useState, useCallback } from 'react';
import mqtt, { MqttClient } from 'mqtt';

const useMqtt = (username: string, password: string, clientId: string, topics: string[]) => {
    const [client, setClient] = useState<MqttClient | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [isConnected, setIsConnected] = useState(false);

    const connectToMqtt = useCallback(() => {
        if (process.env.REACT_APP_MQTT_URL?.length) {
            const newClient = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
                username,
                password,
                clientId
            });

            newClient.on('connect', () => {
                setIsConnected(true);
            });

            newClient.on('error', (error) => {
                // eslint-disable-next-line no-console
                console.error('MQTT connection error:', error);
            });

            newClient.on('disconnect', () => {
                setIsConnected(false);
            });

            setClient(newClient);

            return () => {
                newClient.end();
            };
        }
    }, [username, password, clientId, process.env.REACT_APP_MQTT_URL]);

    useEffect(() => {
        const unsubscribe = connectToMqtt();
        return unsubscribe;
    }, [connectToMqtt]);

    useEffect(() => {
        if (client && isConnected) {
            topics.forEach((topic) => {
                client.subscribe(topic);
            });

            client.on('message', (topic, receivedMessage) => {
                setMessage(receivedMessage.toString());
            });
        }
    }, [client, isConnected, topics]);

    return { client, message, isConnected };
};

export default useMqtt;
