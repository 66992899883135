import PortalApi from 'helpers/api/PortalApiClient';
import { TyreNotificationModel, TyreNotificationPreview } from '../models/TyreNotification.type';

export default class TyreNotification extends PortalApi<TyreNotificationModel> {
    route = 'notification-tyre';

    //will be removed
    getTyreNotificationsReports = async (props): Promise<TyreNotificationPreview> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }

        return this.getByCriteria({
            extendUrl: 'preview',

            criteria
        });
    };

    getTyreNotificationsReportsCsv = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string
    ): Promise<void> => {
        let criteria = {
            measuredFrom: measuredFrom,
            measuredTo: measuredTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }

        return this.getByCriteria({
            extendUrl: 'export',

            criteria
        });
    };

    getTempTyreNotificationsReportsCsv = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string
    ): Promise<void> => {
        let criteria = {
            measuredFrom: measuredFrom,
            measuredTo: measuredTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }

        return this.getByCriteria({
            extendUrl: 'temp/export',

            criteria
        });
    };
}
