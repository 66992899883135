import React, { useEffect, useState } from 'react';
import useModal from '../../../CustomHooks/ModalHook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiButton from '../../../Ui/Components/UiButton';
import { Grid, TextField, InputAdornment, CircularProgress } from '@mui/material';
import HelperText from '../../../Mixs/HelperText';
import Tyre from '../../../../api/Tyre';
import { MountDateAtom } from '../TyreManager.atom';
import DatePicker from '../../../Ui/Components/UiDatePicker/UiDatePicker';
import { useMutation, useQuery } from '@tanstack/react-query';
import UiIcon from '../../../Ui/Components/UiIcon';
import useConverter from '../../../CustomHooks/Converter/Converter';
import { VehicleDetailsActionMenuAtom } from '../../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { FetchVehicleDetailsData } from '../../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';

const tyreAPI = new Tyre();

const AddTyre = ({ value }: { value: string }): JSX.Element => {
    const [serialNumber, setSerialNumber] = useState<string>(value);
    const [isUniqSerialNumber, setIsUniqSerialNumber] = useState<boolean>(true);
    const { t: translate } = useTranslation();
    const { updateModalState } = useModal(1);
    const [mountDate, setMountDate] = useRecoilState(MountDateAtom);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const { fromTimezoneToUTC } = useConverter();
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        refetch();
        updateModalState({
            buttons: (
                <UiButton
                    skin='success'
                    disabled={!isUniqSerialNumber || isFetching}
                    onClick={() => {
                        mutate();
                        setIsSaving(true);
                    }}
                    loading={isSaving}
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, [isUniqSerialNumber]);

    const { mutate } = useMutation(
        ['CreateAndMountTyre'],
        () =>
            tyreAPI.addTyre({
                date: fromTimezoneToUTC(mountDate),
                comment: '',
                tyreSerialNumber: serialNumber,
                wheel: vehicleDetailsActionMenuAtom.data?.wheel.id || 0
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    const { refetch, isFetching } = useQuery(
        ['GetUniqueSerialNumber'],
        () => {
            if (serialNumber && !serialNumber.includes('/')) {
                return tyreAPI.getUniqueSerialNumber(serialNumber);
            }
            return false;
        },
        {
            onSuccess: (res) => setIsUniqSerialNumber(res),
            enabled: false,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    id='tyre-serial-number'
                    fullWidth
                    label={translate('t.serial_number')}
                    value={serialNumber}
                    onChange={(event) => {
                        setSerialNumber(event.target.value);
                    }}
                    onBlur={() => {
                        if (serialNumber) {
                            refetch();
                        }
                    }}
                    size='small'
                    error={!isUniqSerialNumber}
                    helperText={
                        <HelperText text={translate('t.tyre_serial_number_exists')} error={!isUniqSerialNumber} />
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {isFetching ? (
                                    <CircularProgress size='1.4375rem' />
                                ) : isUniqSerialNumber ? (
                                    <UiIcon icon={['fas', 'circle-check']} color='green' size='lg' />
                                ) : (
                                    <UiIcon icon={['fas', 'circle-xmark']} color='red' size='lg' />
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    value={mountDate}
                    onDateChange={(newData) => setMountDate(newData)}
                    label={translate('t.action_date')}
                    noMargin={true}
                    datetest-id='mount-date-picker'
                />
            </Grid>
        </Grid>
    );
};

export default AddTyre;
