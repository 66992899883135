import styled from 'styled-components';

export const SensorManagementViewContent = styled.div`
    width: 100%;

    @media (max-width: 865px) {
        display: inline-grid;
    }

    @media (max-width: 768px) {
        margin-left: -42px;
    }
`;

export const Column = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    padding: 0 5px;
`;

export const Row = styled.div`
    width: 100%;
    padding: 16px 0;
    display: flex;
`;
