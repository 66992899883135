import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VehicleDetailsActionMenuAtom } from './VehicleDetailsActionMenu.atom';
import { CustomPopover, VehicleDetailsActionMenuViewContent } from './VehicleDetailsActionMenu.style';
import VehicleDetailsActions from '../VehicleDetailsActions';
import { SelectedSensorAtom } from '../../Tyre/TyreDetails/SelectedSensor.atom';

const VehicleDetailsActionMenu = (): JSX.Element => {
    const [vehicleSchemaActionMenu, setVehicleSchemaActionMenu] = useRecoilState(VehicleDetailsActionMenuAtom);
    const selectedSensor = useRecoilValue(SelectedSensorAtom(vehicleSchemaActionMenu.data?.wheel.position || 0));

    const getXPosition = (): number => {
        if (document.documentElement.clientWidth - vehicleSchemaActionMenu.positionX > 170) {
            return vehicleSchemaActionMenu.positionX - 15;
        }
        return vehicleSchemaActionMenu.positionX - 165;
    };

    const getYPosition = (): number => {
        if (selectedSensor?.sensor) {
            if (document.documentElement.clientHeight - vehicleSchemaActionMenu.positionY > 210) {
                return vehicleSchemaActionMenu.positionY - 15;
            }
            return vehicleSchemaActionMenu.positionY - (selectedSensor?.sensor?.id > 0 ? 215 : 115);
        }
        return vehicleSchemaActionMenu.positionY - 115;
    };

    return (
        <VehicleDetailsActionMenuViewContent data-testid='VehicleDetailsActionMenuContent'>
            <CustomPopover
                top={getYPosition()}
                left={getXPosition()}
                open={vehicleSchemaActionMenu.isOpen}
                onClose={() => {
                    setVehicleSchemaActionMenu({
                        ...vehicleSchemaActionMenu,
                        isOpen: false
                    });
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <VehicleDetailsActions />
            </CustomPopover>
        </VehicleDetailsActionMenuViewContent>
    );
};

export default VehicleDetailsActionMenu;
