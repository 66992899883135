import React from 'react';
import { TrackingSummaryViewProps } from './TrackingSummary.type';
import { CardTrackingSummary, Title, TrackingSummary } from './TrackingSummary.style';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'states/global/User';
import { ROLES } from 'variables';
import { checkRole } from 'helpers/authentication';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Grid } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { WHITE } from 'components/Ui/colors';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const TrackingSummaryContent: React.FC<TrackingSummaryViewProps> = ({
    data,
    isLoading,
    dateDifference
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromServerToUserUnit, convertType, secondToDateTime } = useConverter();

    return (
        <TrackingSummary data-testid='TrackingSummaryContent'>
            <Title>{translate('t.tracking_summary')}</Title>
            {isLoading ? (
                <UiLoadingPage size='30px' />
            ) : (
                <Grid container spacing={1.5} style={{ paddingTop: '27px' }}>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'calendar-day']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.selected_period_time')}</strong>
                            <p>{dateDifference}</p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'hourglass-half']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.hub_operating_time')}</strong>
                            <p>
                                {' '}
                                {data.operationTime.operatingTime
                                    ? secondToDateTime(data.operationTime.operatingTime)
                                    : `0 ${translate('t.days')}`}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'truck-fast']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.vehicle_in_motion')}</strong>
                            <p>{data.inMotion ? `${translate('t.yes')}` : `${translate('t.no')}`}</p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'truck-moving']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.distance_travelled')}</strong>
                            <p>
                                {data.distance
                                    ? fromServerToUserUnit({
                                          type: convertType.distance,
                                          value: data.distance ?? 0,
                                          displayUnits: false,
                                          fixed: 1
                                      })
                                    : 0}
                                {` ${userInfo?.user?.userSetting?.distanceUnit || 'km'}`}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'gauge']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.average_speed')}</strong>
                            <p>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: data.avgSpeed || 0,
                                    fixed: 2,
                                    displayUnits: true
                                })}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon
                                            icon={['fas', 'gauge-circle-bolt']}
                                            size='lg'
                                            fixedWidth
                                            color={WHITE}
                                        />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.gross_average_speed')}</strong>
                            <p>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: data.avgDaySpeed || 0,
                                    fixed: 2,
                                    displayUnits: false
                                })}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'gauge-max']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.max_speed')}</strong>
                            <p>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: data.maxSpeed || 0,
                                    displayUnits: true,
                                    fixed: 2,
                                    displayIfEmpty: '0'
                                })}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'gauge-min']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.max_breaking')}</strong>

                            <p>
                                {fromServerToUserUnit({
                                    type: convertType.acceleration,
                                    value: data.breaking || 0,
                                    displayUnits: true,
                                    fixed: 2,
                                    displayIfEmpty: '0'
                                })}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6}>
                        <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                            <div className='AvatarCard'>
                                <div className='AvatarCardImage'>
                                    <div className='AvatarCardImageContent'>
                                        <UiIcon icon={['fas', 'gauge-high']} size='lg' fixedWidth color={WHITE} />
                                    </div>
                                </div>
                            </div>
                            <strong>{translate('t.max_acceleration')}</strong>
                            <p>
                                {fromServerToUserUnit({
                                    type: convertType.acceleration,
                                    value: data.acceleration || 0,
                                    displayUnits: true,
                                    fixed: 2,
                                    displayIfEmpty: '0'
                                })}
                            </p>
                        </CardTrackingSummary>
                    </Grid>
                    {checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]) && (
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <CardTrackingSummary sx={{ p: 1, mb: 2 }}>
                                <div className='AvatarCard'>
                                    <div className='AvatarCardImage'>
                                        <div className='AvatarCardImageContent'>
                                            <UiIcon
                                                icon={['fas', 'hourglass-clock']}
                                                size='lg'
                                                fixedWidth
                                                color={WHITE}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <strong>{translate('t.vehicle_idling_time')}</strong>
                                <p>
                                    {data.operationTime.idlingTime
                                        ? secondToDateTime(data.operationTime.idlingTime)
                                        : `0 ${translate('t.days')}`}
                                </p>
                            </CardTrackingSummary>
                        </Grid>
                    )}
                </Grid>
            )}
        </TrackingSummary>
    );
};
