import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const PermissionContent = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    ${applyStyleByMode({
        styleOld: 'width: 98%;',
        styleJade: 'width: 100%;'
    })}
    && .MuiCardContent-root {
        ${applyStyleByMode({
            styleOld: 'padding: 16px 0;',
            styleJade: 'padding: 0px 10px 10px 10px;'
        })}
        font-size: 13px !important;
    }

    & .MuiTableHead-root {
        ${applyStyleByMode({
            styleOld: 'border-bottom: 1px solid #474544; background: #f9f9f9;'
        })}
    }
`;
