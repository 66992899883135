import React from 'react';
import { TyreViewContainer } from './Tyre.style';
import { TyreViewProps } from './Tyre.type';
import TyreTable from '../../components/Tyre/TyreTable/TyreTable';

export const TyreContent: React.FC<TyreViewProps> = (): JSX.Element => {
    return (
        <TyreViewContainer data-testid='TyreContent'>
            <TyreTable />
        </TyreViewContainer>
    );
};
