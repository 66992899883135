import PortalApi from 'helpers/api/PortalApiClient';
import {
    Scale,
    ScaleParams,
    SensorAroundProps,
    SensorLogModel,
    SensorsAroundType,
    TempPress,
    TempPressReportPreview,
    TempPressSensor,
    TempPressSensorParam,
    TempPressureDiff,
    TemperatureExposureBySensor,
    TemperatureResponse,
    TyreDeviation,
    VehicleSensor,
    TemperatureExposureBySensorResponse,
    TemperatureExposureByTyre,
    TemperatureExposureByWheel,
    PassingVehiclesResponse,
    SensorAroundParams
} from '../models/SensorLog.type';
import {
    BatteryStatisticsReponse,
    RssiStatisticsReponse,
    TransmissionsStatisticsReponse
} from '../models/Sensors.type';
import { BasicHeatmapProps } from '../models/TrackLog.type';

export default class SensorLog extends PortalApi<SensorLogModel> {
    route = 'sensor-log';

    getScale = async (vehicleId, measuredFrom, measuredTo): Promise<Scale> => {
        let params: ScaleParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        return await this.getByCriteria({
            extendUrl: `vehicle/${vehicleId}/tyre/scale`,
            criteria: params
        });
    };

    getTempPressureDifference = async (vehicleId, measuredFrom, measuredTo, allDevices): Promise<TempPressureDiff> => {
        let params: ScaleParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        params['allDevices'] = allDevices;
        return await this.getByCriteria({
            extendUrl: `vehicle/${vehicleId}/tyre/difference`,
            criteria: params
        });
    };

    getTempPressure = async (
        vehicleId: number,
        wheelId: number,
        measuredFrom: string,
        measuredTo: string,
        granularity: number,
        sensorId?: number
    ): Promise<TempPress> => {
        let params: ScaleParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        if (vehicleId) {
            params['vehicleId'] = vehicleId;
        }
        if (wheelId) {
            params['wheelId'] = wheelId;
        }
        if (sensorId) {
            params['sensorId'] = sensorId;
        }
        params['granularity'] = granularity;
        return await this.getByCriteria({
            extendUrl: `temp-and-press/wheel`,
            criteria: params
        });
    };

    getTempPressureSensor = async (
        sensorId: number,
        allHubsChecked: boolean,
        measuredFrom?: string,
        measuredTo?: string,
        granularity?: number
    ): Promise<TempPressSensor> => {
        let params: TempPressSensorParam = {
            allDevices: +allHubsChecked
        };
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        if (granularity) {
            params['granularity'] = granularity;
        }
        return await this.getByCriteria({
            extendUrl: `temp-and-press/sensor/${sensorId}`,

            criteria: params
        });
    };

    getSensorsByPeriod = async (vehicleId, measuredFrom, measuredTo): Promise<VehicleSensor> => {
        let params: ScaleParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        return await this.getByCriteria({
            extendUrl: `vehicle/${vehicleId}/sensors`,

            criteria: params
        });
    };

    getSensorsAround = async (id): Promise<VehicleSensor> => {
        return await this.get({ extendUrl: `sensors-around/${id}` });
    };

    getTyreDeviationByVehicleId = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string
    ): Promise<TyreDeviation> => {
        return await this.getByCriteria<TyreDeviation>({
            extendUrl: `tyre-deviations/${vehicleId}`,

            criteria: {
                measuredFrom: measuredFrom,
                measuredTo: measuredTo
            }
        });
    };

    getTempPressWheelReportPreview = async (props): Promise<TempPressReportPreview> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }
        if (props.additionalProps.wheelPosition > 0) {
            criteria['customPosition'] = props.additionalProps.wheelPosition;
        }

        return await this.getByCriteria({
            extendUrl: `temp-and-press/wheel/report-preview`,

            criteria: criteria
        });
    };

    getWheelTPMSCsv = async (
        vehicleId: number,
        dateFrom: string,
        dateTo: string,
        wheelCustomPosition?: number,
        granularity?: number | null
    ): Promise<string> => {
        let criteria = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }
        if (wheelCustomPosition && wheelCustomPosition > 0) {
            criteria['wheelCustomPosition'] = wheelCustomPosition;
        }

        if (granularity) {
            criteria['granularity'] = granularity;
        }

        return await this.getByCriteria({
            extendUrl: `report/wheel-tpms`,

            criteria: criteria
        });
    };

    getTemperatureHeatmap = async (params: BasicHeatmapProps): Promise<TemperatureResponse> => {
        const heatmapParams: BasicHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };

        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds;
        }
        return await this.getByCriteria({
            extendUrl: 'temperature-heatmap',

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    //HEATMAP_API_AUTOMATIC_GENERATOR
    getSensorAround = async (props: SensorAroundProps): Promise<SensorsAroundType> => {
        const sensorAroundParams: SensorAroundParams = {};

        if (props.additionalProps.measuredFrom) {
            sensorAroundParams.measuredFrom = props.additionalProps.measuredFrom;
        }

        if (props.additionalProps.measuredTo) {
            sensorAroundParams.measuredTo = props.additionalProps.measuredTo;
        }
        return await this.getByCriteria({
            extendUrl: `sensors-around/${props.additionalProps.deviceId}`,

            criteria: sensorAroundParams
        });
    };

    getStatisticsSensorBattery = async (props): Promise<BatteryStatisticsReponse> => {
        return await this.getByCriteria({
            extendUrl: `sensor/${props.sensorId}/battery-voltage`,
            version: 'v2',
            criteria: {
                measuredFrom: props.measuredFrom,
                measuredTo: props.measuredTo
            }
        });
    };

    getStatisticsSensorRssi = async (props): Promise<RssiStatisticsReponse> => {
        return await this.getByCriteria({
            extendUrl: `sensor/${props.sensorId}/rssi`,
            version: 'v2',
            criteria: {
                measuredFrom: props.measuredFrom,
                measuredTo: props.measuredTo
            }
        });
    };

    getStatisticsSensortransmissions = async (props): Promise<TransmissionsStatisticsReponse> => {
        return await this.getByCriteria({
            extendUrl: `sensor/${props.sensorId}/transmissions`,
            version: 'v2',
            criteria: {
                measuredFrom: props.measuredFrom,
                measuredTo: props.measuredTo
            }
        });
    };

    getTemperatureExposureBySensor = async (
        props: TemperatureExposureBySensor
    ): Promise<TemperatureExposureBySensorResponse> => {
        return await this.getByCriteria({
            extendUrl: `temperature-exposure-report/sensor/${props.sensorId}`,

            criteria: {
                measuredFrom: props.dateFrom,
                measuredTo: props.dateTo
            }
        });
    };

    getTemperatureExposureByTyre = async (
        props: TemperatureExposureByTyre
    ): Promise<TemperatureExposureBySensorResponse> => {
        return await this.getByCriteria({
            extendUrl: `temperature-exposure-report/tyre/${props.tyreId}`,

            criteria: {
                measuredFrom: props.dateFrom,
                measuredTo: props.dateTo
            }
        });
    };

    getTemperatureExposureByWheel = async (
        props: TemperatureExposureByWheel
    ): Promise<TemperatureExposureBySensorResponse> => {
        return await this.getByCriteria({
            extendUrl: `temperature-exposure-report/wheel/${props.wheelId}`,

            criteria: {
                measuredFrom: props.dateFrom,
                measuredTo: props.dateTo
            }
        });
    };

    getVehiclePassing = async (deviceId: number): Promise<PassingVehiclesResponse> => {
        return await this.get({
            extendUrl: `passing-vehicles/vehicle/${deviceId}`
        });
    };

    getSensorTransmissionCSV = async (props): Promise<void> => {
        return await this.get({
            extendUrl: `sensor/${props.sensorId}/export-statistics?measuredFrom=${props.measuredFrom}&measuredTo=${props.measuredTo}`,
            version: 'v2'
        });
    };
}
