import React, { useState } from 'react';
import { Button, DropDown, GridCustom, TopBarTerminalContainer, ValueTextField } from './TopBarTerminal.style';
import { TopBarTerminalViewProps } from './TopBarTerminal.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { Grid } from '@mui/material';

export const TopBarHistoryContent: React.FC<TopBarTerminalViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [command, setCommand] = useState<number | null | string>(null);
    const [valueCommand, setValueCommand] = useState<string | undefined>(undefined);
    const userInfo = useRecoilValue(UserInfo);

    return (
        <TopBarTerminalContainer data-testid='DataExportTopBarContent'>
            {checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]) && (
                <>
                    <Grid container marginTop={1} columnSpacing={1}>
                        <Grid item xs={10} sm={12} md={6}>
                            <DropDown
                                options={props.commandsCodebook}
                                inputName='allcommands'
                                label={translate('t.choose_command')}
                                value={command}
                                disabled={props.commandsAvailable}
                                getValue={(e) => {
                                    setCommand(e);
                                }}
                                testid='AllCommandsDropdown'
                            />
                        </Grid>
                        {props.commandsRaw.find((value) => value.name == command && value.hasValue) && (
                            <Grid item xs={6} sm={6} md={4}>
                                <ValueTextField
                                    hidden={!props.commandsRaw.find((value) => value.name == command && value.hasValue)}
                                    size='small'
                                    label={translate('t.value')}
                                    onChange={(newValue) => {
                                        if (newValue.target.value.length > 0) setValueCommand(newValue.target.value);
                                        else setValueCommand(undefined);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    data-testid='CommandValue'
                                ></ValueTextField>
                            </Grid>
                        )}
                        <GridCustom item xs={6} sm={6} md={2}>
                            <Button
                                skin='success'
                                variant={VARIANT_BUTTON}
                                color='primary'
                                data-testid='SendCommandButton'
                                hidden={
                                    props.commandsRaw.find((value) => value.name == command && value.hasValue)
                                        ? !valueCommand
                                        : !command
                                }
                                disabled={
                                    props.commandsRaw.find((value) => value.name == command && value.hasValue)
                                        ? !valueCommand
                                        : !command
                                }
                                onClick={() =>
                                    props.handleSubmit(
                                        command as string,
                                        props.commandsRaw.find((value) => value.name == command && value.hasValue) &&
                                            valueCommand
                                    )
                                }
                            >
                                {translate('t.send')}
                            </Button>
                        </GridCustom>
                    </Grid>
                </>
            )}
        </TopBarTerminalContainer>
    );
};
