import React from 'react';
import * as style from './ColumnFilter.style';

const ColumnFilterContent: React.FC<{ column }> = ({ column }): JSX.Element => {
    return (
        <style.ColumnFilterContent data-testid='ColumnFilterContent'>
            <style.FilterTextField
                label={<>{column.Header}</>}
                value={column.filterValue?.value ?? ''}
                variant='outlined'
                size='small'
                inputProps={{ maxLength: 45 }}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                onChange={(e) => {
                    let value: string = e.target.value;
                    if (value.length === 0) {
                        column.setFilter(undefined);
                    } else {
                        column.setFilter({ name: column.Header, value: e.target.value });
                    }
                }}
            />
        </style.ColumnFilterContent>
    );
};

export default ColumnFilterContent;
