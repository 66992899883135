import React from 'react';
import { useRecoilState } from 'recoil';
import { ThemeButtonProps } from './ThemeButton.type';
import { Theme, ThemeMode } from '../../../states/global/Theme';
import { Brightness2Outlined, WbSunnyOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ThemeButton: React.FC<ThemeButtonProps> = (): JSX.Element => {
    const [theme, setTheme] = useRecoilState(Theme);
    const { t: translate } = useTranslation();

    const toggleTheme = () => {
        setTheme({
            ...theme,
            mode: theme.mode === ThemeMode.light ? ThemeMode.dark : ThemeMode.light
        });
    };

    return (
        <Tooltip
            title={translate(theme.mode === ThemeMode.light ? 't.activate_dark_mode' : 't.activate_light_mode')}
            placement='bottom'
            arrow
        >
            <IconButton onClick={() => toggleTheme()} size='small' data-testid='ChangeTheme-Button'>
                {theme.mode === ThemeMode.light ? (
                    <Brightness2Outlined fontSize='inherit' />
                ) : (
                    <WbSunnyOutlined fontSize='inherit' />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default ThemeButton;
