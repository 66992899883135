import React, { useEffect } from 'react';
import { CustomerReportsViewContent } from './CustomerReports.style';
import { CustomerReportsViewProps } from './CustomerReports.type';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

export const CustomerReportsContent: React.FC<CustomerReportsViewProps> = (): JSX.Element => {
    useEffect(() => {
        const options = {
            url: 'https://eu-west-1.quicksight.aws.amazon.com/embed/b9d565a235f74977b89f0f4d861787e4/dashboards/4f54ad44-4f38-41eb-8310-3c4083a440fb?code=AYABeC-Eyj0F-UAcMTPXql1806wAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4BMk1ctz8ka0HUxBPXH2BRVvhVY8QtFORCKhqhc2DcsUBMU7vtVm5MvmgFOhObSEimAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDCCiqQ8lTPUPBgTXNAIBEIA7O2npPMqbmj1v2NzoyLvYQCPZL14UvU9FqocQV2ktS73h6SE4ET67tPfQGAcG65Q3dnUI7xH7tKsYVF8CAAAAAAwAABAAAAAAAAAAAAAAAAAAyQ3ysp91IfI1pKp2MeTv5P____8AAAABAAAAAAAAAAAAAAABAAAAm6JmVGCOApt6XHvh7Gx_rF_yrXLCoqc5WWjDHaOAnEGrtYpz37BsOLKHpIvcZcdVKmLydSTrdk7kJBzQVdZSdMZDWKOI9wwl2DKfxvHLoW5AaVxWD-3lK2yl43xUJjIzfCzOzwlWPip_zosATMoTTpkI-IsZMoTudYAvDkzlc-ZtD65I5pDobxUUmpOQ-6XBzWGjQEX0sCP6rlBuu8ns5GqUg7Qxke-B7dqiMg%3D%3D&identityprovider=quicksight&isauthcode=true',
            container: '#embed-test',
            scrolling: 'no',
            height: '700px',
            width: '1000px',
            footerPaddingEnabled: true
        };
        embedDashboard(options);
    }, []);

    return (
        <CustomerReportsViewContent data-testid='CustomerReportsContent' id='embed-test'></CustomerReportsViewContent>
    );
};
