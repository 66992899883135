import styled from 'styled-components';
import { useTheme } from '@mui/material';

export const Button = styled('button')`
    border: none;
    width: 100%;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
    ${() => {
        return `
            :hover {
                background-color: ${useTheme().palette.background.default};
            }
        `;
    }}
`;
