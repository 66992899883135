import React from 'react';
import { TabletActivationProps } from './TabletActivation.type';
import TabletActivationContent from 'components/Mixs/TabletActivation';
import { Wrapper } from 'helpers/wrapper';

const TabletActivation: React.FC<TabletActivationProps> = (): JSX.Element => {
    return <TabletActivationContent data-testid='TabletActivation-testid' />;
};

export default Wrapper(TabletActivation);
