import {
    IinicialStateTemperaturePressure,
    TemperaturePressureActionType,
    TemperaturePressureActionsEnum
} from './TemperaturePressureList.type';

export const InicialStateTemperaturePressure = {
    vehiclesList: [],
    maxPosition: 0,
    orderDirection: { column: 'name', order: 'asc' },
    loandingContent: true,
    wetTyres: false
} as IinicialStateTemperaturePressure;

export const TemperaturePressureReducer = (
    state: IinicialStateTemperaturePressure,
    action: TemperaturePressureActionType
) => {
    switch (action.type) {
        case TemperaturePressureActionsEnum.SET_VEHICLES_LIST:
            return {
                ...state,
                vehiclesList: action.payload
            };
        case TemperaturePressureActionsEnum.SET_ORDER_DIRECTION:
            return {
                ...state,
                orderDirection: action.payload
            };
        case TemperaturePressureActionsEnum.SET_LOADING:
            return {
                ...state,
                loandingContent: action.payload
            };
        case TemperaturePressureActionsEnum.SET_WET_TYRES:
            return {
                ...state,
                wetTyres: action.payload
            };
        case TemperaturePressureActionsEnum.SET_MAX_POSITION:
            return {
                ...state,
                maxPosition: action.payload
            };
        default:
            return state;
    }
};
