import styled from 'styled-components';
import { useTheme } from '@mui/material';

export const LayoutContainer = styled.div`
    display: flex;
    background: ${() => useTheme().palette.background.default};
    min-height: 100vh;
    width: 100%;
`;

export const MainBodyApp = styled.div<{ $collapsedSidebar: boolean; $marginLeft: string; $easyMode: boolean }>`
    margin-top: ${({ $easyMode }) => ($easyMode ? '70' : '55')}px;
    transition: 300ms;
    padding: 10px 20px 20px 20px;
    // margin-left: ${(props) => (!props.$collapsedSidebar ? props.$marginLeft : '60px')};
    width: 100%;
`;

export const MainBodyContainer = styled.div<{ $collapsedSidebar: boolean; $marginLeft: string }>`
    min-height: 100vh;
    width: ${({ $collapsedSidebar, $marginLeft }) => (!$collapsedSidebar ? `calc(100% - ${$marginLeft})` : '100%')};
    margin-left: ${(props) => (!props.$collapsedSidebar ? props.$marginLeft : '60px')};
    transition: 300ms;

    @media (min-width: 320px) and (max-width: 1200px) {
        margin-top: 50px;
        width: calc(100% - 60px);
    }

    @media (max-width: 899px) {
        margin-left: 0px;
        margin-top: 50px;
        width: 100%;
    }
`;
