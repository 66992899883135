import React, { useCallback } from 'react';
import { CRUD } from 'variables';
import { useRecoilValue } from 'recoil';
import { TyreStateAtom } from '../TyreManager.atom';
import { MessageBox } from '../TyreManager.style';
import MountTyre from './MountTyre';
import UnmountTyre from './UnmountTyre';
import ReplaceTyre from './ReplaceTyre';

const TyreActionPopup = (): JSX.Element => {
    const tyreState = useRecoilValue(TyreStateAtom);

    const PopupContent = useCallback(() => {
        switch (tyreState.state) {
            case CRUD.MOUNT:
                return <MountTyre />;
            case CRUD.UNMOUNT:
                return <UnmountTyre />;
            case CRUD.REPLACE:
                return <ReplaceTyre />;
        }
    }, [tyreState.state]);

    return (
        <MessageBox>
            <PopupContent />
        </MessageBox>
    );
};

export default TyreActionPopup;
