import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';

type Pass = {
    showComment?: string | null;
    valueId?: number;
    type: DropdownTypesEnum;
    testid?: string;
};

export type DropdownUpdateProps = {
    sensorId?: number;
} & Pass;

export type DropdownUpdateViewProps = {
    initialValues: InitialValues;
    loading: boolean;
    listLoading: boolean;
    list: optionsCodeBook[] | undefined;
    handleSubmit: (values: InitialValues) => void;
} & Pass;

export type InitialValues = {
    id: number | null;
};

export enum DropdownTypesEnum {
    SENSOR_TYPE = 'sensor_type',
    STATUS = 'status'
}

export type DropDownUpdateFormikProps = {
    initialValues: InitialValues;
    list: optionsCodeBook[] | undefined;
    listLoading: boolean;
    handleSubmit: (values: InitialValues) => void;
    loading: boolean;
} & Pass;
