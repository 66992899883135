import { SensorsType } from './NewHubsExtendedRow.type';

export const initSensorValues: SensorsType = {
    ldl: {
        value: 1,
        checked: false
    },
    golfball: {
        value: 2,
        checked: false
    },
    bTag: {
        value: 5555,
        checked: false
    }
};

export type SensorAction = {
    sensor: SensorTypeNames;
    status: boolean;
};

export enum SensorTypeNames {
    LDL = 'ldl',
    GOLFBALL = 'golfball',
    BTAG = 'bTag'
}

export const reduce = (state: SensorsType, action: SensorAction): SensorsType => {
    switch (action.sensor) {
        case SensorTypeNames.LDL:
            return { ...state, ldl: { value: 1, checked: action.status } };
        case SensorTypeNames.GOLFBALL:
            return { ...state, golfball: { value: 2, checked: action.status } };
        case SensorTypeNames.BTAG:
            return { ...state, bTag: { value: 5555, checked: action.status } };
        default:
            return state;
    }
};
