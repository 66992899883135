import styled from 'styled-components';

export const ExcessiveAccViewContent = styled.div``;

export const Circle = styled.div`
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 20px;
    margin-right: 10px;
`;

export const LegendLabel = styled.div`
    width: 150px;

    .yellow {
        background-color: #ffff00;
    }

    .red {
        background-color: #ff0000;
    }
`;
