import React from 'react';
import * as style from './UiCircularProgress.style';
import * as type from './UiCircularProgress.type';
import CircularProgress from '@mui/material/CircularProgress';

const UiCircularProgressContent: React.FC<type.UiCircularProgressProps> = (): JSX.Element => (
    <style.UiCircularProgressContent data-testid='UiCircularProgressContent'>
        <CircularProgress />
    </style.UiCircularProgressContent>
);

export default UiCircularProgressContent;
