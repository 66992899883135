import React, { useState } from 'react';
import { CommandContentProps } from './CommandContent.type';
import {
    CommandGrid,
    DeviceCommandsTabsContainer,
    DeviceCommandsTabs,
    DeviceCommandsTab
} from './CommandContent.style';
import TabContent from '../../../../Ui/Components/UiTab/UiTab';
import ReactJson from '@microlink/react-json-view';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ThemeMode, Theme } from 'states/global/Theme';

export const CommandContent: React.FC<CommandContentProps> = ({ content, result }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [commandContentTab, setCommandContentTab] = useState<number>(0);
    const ThemeUi = useRecoilValue(Theme);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text.toString() ?? ' ');
    };

    return (
        <>
            <CommandGrid item xs={12} md={12}>
                <DeviceCommandsTabsContainer>
                    <DeviceCommandsTabs
                        value={commandContentTab}
                        onChange={(event, newTabIndex): void => {
                            setCommandContentTab(newTabIndex);
                        }}
                        indicatorColor='primary'
                        textColor='primary'
                    >
                        <DeviceCommandsTab label={`${translate('t.sent')}`} />

                        <DeviceCommandsTab label={`${translate('t.response')}`} />
                    </DeviceCommandsTabs>
                </DeviceCommandsTabsContainer>
                <TabContent value={commandContentTab} index={0} testId='CmdSent'>
                    <ReactJson
                        src={content ? JSON.parse(content) : {}}
                        name={false}
                        enableClipboard={(copy) => {
                            handleCopy(`${copy.name ? copy.name + ':' : ''}${JSON.stringify(copy.src)}`);
                        }}
                        theme={ThemeUi?.mode === ThemeMode.dark ? 'harmonic' : 'rjv-default'}
                    />
                </TabContent>
                <TabContent value={commandContentTab} index={1} testId='CmdResponse'>
                    <ReactJson
                        src={result ? JSON.parse(result) : {}}
                        name={false}
                        enableClipboard={(copy) => {
                            handleCopy(`${copy.name ? copy.name + ':' : ''}${JSON.stringify(copy.src)}`);
                        }}
                        theme={ThemeUi?.mode === ThemeMode.dark ? 'harmonic' : 'rjv-default'}
                    />
                </TabContent>
            </CommandGrid>
        </>
    );
};
