import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import CircularProgress from '@mui/material/CircularProgress';

export const ReportTableContainer = styled.div`
    ${applyStyleByMode({
        styleOld: `
        th,
        td {
            overflow-wrap: break-word;
        }
        width: 98%;
        && .MuiCardContent-root {
            padding: 16px 0;
            font-size: 13px !important;
        }
    
        & .MuiTableHead-root {
            border-bottom: 1px solid #474544;
            background: #f9f9f9;
    `
    })}
`;

export const CustomLoading = styled(CircularProgress)`
    margin-left: 10px;
    margin-top: 5px;
`;
