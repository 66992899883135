import React from 'react';
import { ColorIndicator, SlopeInfoPopupContainer, SlopeValue } from './SlopeInfoPopup.style';
import { SlopeInfoPopupProps } from './SlopeInfoPopup.type';
import { useTranslation } from 'react-i18next';
import { MapActionData } from 'states/global/Map';
import { useRecoilValue } from 'recoil';
import useConverter from 'components/CustomHooks/Converter/Converter';

const SlopeInfoPopup: React.FC<SlopeInfoPopupProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const mapActionData = useRecoilValue(MapActionData);
    const { fromServerToUserUnit, convertType } = useConverter();
    return mapActionData.slopeAngle !== undefined ? (
        <SlopeInfoPopupContainer data-testid='SlopeInfoPopupContent'>
            <table>
                <tr>
                    <td>{translate('t.slope_angle')}:</td>
                    <SlopeValue>
                        {mapActionData.slopeAngle.toFixed(2)} <ColorIndicator $color={mapActionData.slopeColor} />
                    </SlopeValue>
                </tr>
                <tr>
                    <td>{translate('t.speed')} (AVG):</td>
                    <SlopeValue>
                        {fromServerToUserUnit({
                            type: convertType.speed,
                            value: mapActionData.slopeSpeedAvg,
                            displayUnits: true
                        })}
                    </SlopeValue>
                </tr>
            </table>
        </SlopeInfoPopupContainer>
    ) : (
        <></>
    );
};

export default SlopeInfoPopup;
