import { Alert, Autocomplete, Box, Grid, TableRow } from '@mui/material';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import UiButton from '../../Ui/Components/UiButton/UiButton';

export const CustomerSettingsContainer = styled.div`
    & tbody .MuiTableCell-root {
        ${applyStyleByMode({
            styleJade: 'padding: 10px 14px !important;'
        })}

        ${() => useTheme().breakpoints.between('xs', 'sm')} {
            padding: 10px 1px !important;
        }
    }

    & .MuiTableHead-root .MuiTableRow-root {
        display: inline-table !important;
        width: 100% !important;
    }

    ${applyStyleByMode({
        styleJade: `
        &&& { 
            .MuiTextField-root {
                margin: 8px;
                margin-left: 0px;
                & label {
                    padding: 0 5px 0 0;
                    background: transparent;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(10px, -9px) scale(0.8);
                }
            }
        }
        `
    })}
`;

export const CustomerModalContent = styled(Box)`
    &.MuiBox-root {
        overflow-wrap: break-word;
    }
`;

export const SettingsAutocomplete = styled(Autocomplete)`
    &.MuiAutocomplete-root {
        padding: 3px 0;
    }
`;

export const AvailableSettingsContent = styled(Grid)`
    &.MuiGrid-root {
        display: flex;
        justify-content: right;
    }
`;

export const AddNewSettingBtn = styled(UiButton)`
    &.MuiButton-root {
        margin: 3px 0 0 8px;
        height: 34px;
    }
`;

export const EditRowMode = styled(TableRow)`
    &.MuiTableRow-root {
        ${() => {
            return applyStyleByMode({
                styleOld: 'background: rgb(255, 244, 229);',
                theme: useTheme().palette.mode,
                light: 'background: #f2f2f2;',
                dark: 'background: #1d2630;'
            });
        }}
    }
`;

export const BasicRow = styled(TableRow)`
    &.MuiTableRow-root {
        min-height: 49.75px;
        height: 49.75px;
    }
`;

export const ItemsAmbTepm = styled.div<{ $hasEditMode: boolean; height: string }>`
    overflow: hidden;
    height: 48px;
    transition: height 0.5s ease-in-out;
    display: table;
    width: 100%;

    ${({ $hasEditMode, height }) => ($hasEditMode ? `height: ${height || '48px'};` : '')}
`;

export const WarningMsg = styled(Alert)<{ visible: boolean }>`
    &.MuiAlert-root {
        visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        padding-left: 10px;
    }
`;

export const KeyValue = styled('span')`
    display: flex;
    flex-direction: column;
`;

export const DeprecatedKey = styled('span')`
    font-size: 0.7rem;
    color: grey;
`;
