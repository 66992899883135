import { VehicleModel } from 'models/Vehicle.type';
import { atom } from 'recoil';

type VehicleTypeAddProps = {
    action: string;
    id?: number;
    type?: string;
    icon?: string;
    maxSpeed?: number;
    vehicle?: Pick<VehicleModel, 'id' | 'name'>[];
    maxSpeedTemperatureL1?: number;
    maxSpeedTemperatureL2?: number;
    maxSpeedTemperatureL3?: number;
    enableTemperatureCheckbox?: boolean;
};

export const VehicleTypeAddState = atom<VehicleTypeAddProps>({
    key: 'VehicleTypeAddState',
    default: {
        action: '',
        id: 0,
        type: '',
        icon: 'adt1_Yellow.png',
        maxSpeed: 0,
        maxSpeedTemperatureL1: 0,
        maxSpeedTemperatureL2: 0,
        maxSpeedTemperatureL3: 0,
        vehicle: [],
        enableTemperatureCheckbox: false
    }
});
