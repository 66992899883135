type SetInputValue = {
    type: AutocompleteValuesEnum.SET_INPUT_VALUE;
    payload: {
        inputValue: AutocompleteValuesState['inputValue'];
    };
};

type SetSelectedValue = {
    type: AutocompleteValuesEnum.SET_SELECTED_VALUE;
    payload: {
        inputValue: AutocompleteValuesState['inputValue'];
        selectedValue: AutocompleteValuesState['selectedValue'];
        value: AutocompleteValuesState['value'];
        origItem: AutocompleteValuesState['origItem'];
    };
};

type SetSelectedValueToInputValue = {
    type: AutocompleteValuesEnum.SET_SELECTED_TO_INPUT_VALUE;
};

type ResetSearchValue = {
    type: AutocompleteValuesEnum.RESET_SEARCH_VALUE;
};

type Reset = {
    type: AutocompleteValuesEnum.RESET;
};

export enum AutocompleteValuesEnum {
    SET_INPUT_VALUE = 'SET_INPUT_VALUE',
    SET_SELECTED_VALUE = 'SET_SELECTED_VALUE',
    SET_SELECTED_TO_INPUT_VALUE = 'SET_SELECTED_TO_INPUT_VALUE',
    RESET_SEARCH_VALUE = 'RESET_SEARCH_VALUE',
    RESET = 'RESET'
}

export type AutocompleteValuesAction =
    | SetInputValue
    | SetSelectedValue
    | SetSelectedValueToInputValue
    | ResetSearchValue
    | Reset;

export type AutocompleteValuesState = {
    inputValue: string | undefined;
    selectedValue: string | undefined;
    value: number | undefined;
    searchValue: string | undefined;
    origItem: unknown | undefined;
};

export const AutocompleteValues = (
    state: AutocompleteValuesState,
    action: AutocompleteValuesAction
): AutocompleteValuesState => {
    switch (action.type) {
        case AutocompleteValuesEnum.SET_INPUT_VALUE: {
            return {
                ...state,
                inputValue: action.payload.inputValue,
                searchValue: action.payload.inputValue,
                value: !action.payload.inputValue?.length ? undefined : state['value']
            };
        }
        case AutocompleteValuesEnum.SET_SELECTED_VALUE:
            return {
                ...state,
                inputValue: action.payload.inputValue,
                selectedValue: action.payload.selectedValue,
                value: action.payload.value,
                origItem: action.payload.origItem
            };
        case AutocompleteValuesEnum.SET_SELECTED_TO_INPUT_VALUE:
            return { ...state, inputValue: state['selectedValue'], searchValue: undefined };
        case AutocompleteValuesEnum.RESET_SEARCH_VALUE:
            return { ...state, searchValue: undefined };
        case AutocompleteValuesEnum.RESET:
            return {
                inputValue: ' ',
                selectedValue: ' ',
                value: undefined,
                searchValue: ' ',
                origItem: undefined
            };
        default:
            throw new Error();
    }
};
