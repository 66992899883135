import React, { useEffect, useState } from 'react';
import { VehicleStatusContent } from './VehicleStatus.view';
import {
    HasInternalSensorAtom,
    VehicleDetailsWidgetAtom
} from '../../components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { useLocation } from 'react-router-dom';
import { Wrapper } from 'helpers/wrapper';
import { ActiveVehicleDetailsTab } from 'components/Vehicle/VehicleDetails/VehicleDetails.state';

const VehicleStatus: React.FC = (): JSX.Element => {
    const [vehicleId, setVehicleId] = useState<number>(0);
    const queryParameters = new URLSearchParams(useLocation().search);
    const vehicleID = queryParameters.get('vehicle');
    const showTab = queryParameters.get('tab');
    const setActiveVehicleDetailsTab = useSetRecoilState(ActiveVehicleDetailsTab);
    const resetVehicleDataAtom = useResetRecoilState(VehicleDetailsWidgetAtom());
    const resetHasInternalSensorAtom = useResetRecoilState(HasInternalSensorAtom());
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);
    const handleVehicleSelection = (id: number): void => {
        resetVehicleDataAtom();
        resetHasInternalSensorAtom();
        setVehicleId(id);
    };

    const handleShowTab = (showTab: number): void => {
        setActiveVehicleDetailsTab((currentState) => ({ ...currentState, detailsWidget: showTab }));
    };

    useEffect(() => {
        vehicleID && setVehicleId(Number(vehicleID));
        showTab && handleShowTab(Number(showTab));
        setPageHelpButton({ article: 'vehicle-status' });

        return () => {
            resetPageHelpButton();
        };
    }, []);

    return (
        <VehicleStatusContent
            data-testid='VehicleStatus-testid'
            vehicleId={vehicleId}
            setVehicleId={handleVehicleSelection}
        />
    );
};

export default Wrapper(VehicleStatus);
