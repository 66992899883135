import styled from 'styled-components';
import Paper from '@mui/material/Paper';

export const AlertListContent = styled.div``;

export const List = styled(Paper)<{ maxHeight?: number }>`
    &.MuiPaper-root {
        min-height: 68px;
        max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : '625px')};
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
