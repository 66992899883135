import React from 'react';
import * as style from './TrackingSettingsForm.style';
import * as type from './TrackingSettingsForm.type';
import { useTranslation } from 'react-i18next';
import { TrackingSettingsFormik } from './TrackingSettingsForm.formik';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';

export const TrackingSettingsFormContent: React.FC<type.TrackingSettingsFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.TrackingSettingsFormContent data-testid='TrackingSettingsFormContent'>
            <ContainerBodyTab>
                <TitleContentTab>{`${translate('t.tracking')} ${translate('t.settings')}`}</TitleContentTab>
                <style.containerFormTrackingSettings>
                    <TrackingSettingsFormik
                        inicialValuesFormik={props.inicialValuesFormik}
                        mapTypeList={props.mapTypeList}
                        mapJourneyReportList={props.mapJourneyReportList}
                        mapVehicleLabelList={props.mapVehicleLabelList}
                        handleSubmit={props.handleSubmit}
                    />
                </style.containerFormTrackingSettings>
            </ContainerBodyTab>
        </style.TrackingSettingsFormContent>
    );
};
