import { atom } from 'recoil';

export const FilterPeriod = atom({
    key: 'FilterPeriod',
    default: 24
});

export const SearchVehicle = atom<string>({
    key: 'SearchVehicle',
    default: ''
});
