import { Grid, Paper, Typography } from '@mui/material';
import { styled as styleMui } from '@mui/system';

export const AlertSettingsViewContent = styleMui(Paper)({
    padding: '5px 10px'
});

export const AlertLabel = styleMui(Typography)({
    marginBottom: '5px'
});

export const AlertsCheckboxConver = styleMui(Grid)({
    display: 'flex',
    alignItems: 'center'
});
