import { styled } from '@mui/system';
import { Box, TableCell, TableContainer, TableRow } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const VehicleStatsTableContent = styled(Box)`
    & .MuiTableCell-root {
        border-bottom: none;
    }

    & .MuiTableContainer-root {
        overflow-x: auto;
        overflow-y: auto;
    }

    & .MuiCardContent-root {
        padding: 5px 0px !important;
    }
`;

export const TableContainerVehicle = styled(TableContainer)`
    ${applyStyleByMode({
        styleOld: `
            max-height: 483px;
            min-height: 483px;
        `,
        styleJade: `
            max-height: 465px;
            min-height: 465px;
        `
    })}
`;

export const HeaderTable = styled(TableCell)<{ $minWidth?: number }>`
    font-weight: 600;
    min-width: ${({ $minWidth }) => `${$minWidth}px` ?? '0px'};
`;

export const RowHeaderTable = styled(TableRow)`
    & .MuiTableCell-root {
        border-bottom: thin solid #c0c0c0;
        ${applyStyleByMode({
            styleJade: 'padding: 5px 8px !important;'
        })}
    }
`;
