import React, { useEffect, useState } from 'react';
import { UiIconPickerProps } from './UiIconPicker.type';
import { UiIconPickerContent } from './UiIconPicker.view';
import { Wrapper } from 'helpers/wrapper';
// import module

const UiIconPicker: React.FC<UiIconPickerProps> = (props) => {
    const defaultColor = 'Yellow';
    const defaultStep = 1;
    const titles = {
        1: 'Choose Vehicle',
        2: 'Choose Color'
    };
    const [defaultIcon, setDefaultIcon] = useState<string>(props.defaultIcon);
    const [selectedIcon, setSelectedIcon] = useState<string>(props.defaultIcon);
    const [colors, setColors] = useState<string[]>([]);
    const [step, setStep] = useState<number>(defaultStep);
    const [closePicker, setClosePicker] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(titles[step]);

    const reset = (): void => {
        setSelectedIcon(props.defaultIcon);
        setDefaultIcon(props.defaultIcon);
        setStep(defaultStep);
        setClosePicker(false);
    };

    const confirmVehicle = (color: string): void => {
        setStep(defaultStep);
        setClosePicker(true);
        const newIcon: string = selectedIcon.replace(defaultColor, color);
        setSelectedIcon(newIcon);
        setDefaultIcon(newIcon);
    };

    const updateIcon = (icon: string): void => {
        setSelectedIcon(icon);
    };

    const udpateStep = (step: number, icon: string, colors: string[]): void => {
        setStep(step);
        updateIcon(icon);
        setColors(colors);
    };

    const updateClosePicker = (close: boolean): void => {
        setClosePicker(close);
    };

    useEffect(() => {
        setClosePicker(false);
    }, [closePicker]);

    useEffect(() => {
        setTitle(titles[step]);
    }, [step]);

    useEffect(() => {
        props.onIconSelect && props.onIconSelect(selectedIcon);
    }, [selectedIcon]);

    useEffect(() => {
        setDefaultIcon(props.defaultIcon);
    }, [props.defaultIcon]);

    return (
        <UiIconPickerContent
            {...props}
            updateStep={udpateStep}
            colors={colors}
            step={step}
            selectedIcon={selectedIcon}
            title={title}
            defaultIcon={defaultIcon}
            updateClosePicker={updateClosePicker}
            closePicker={closePicker}
            reset={reset}
            confirmVehicle={confirmVehicle}
            defaultColor={defaultColor}
            data-testid='UiIconPicker-testid'
        />
    );
};

export default Wrapper(UiIconPicker);
