import React from 'react';
import {
    ActiveStepActionsEnum,
    AddAreaAction,
    AddAreaData,
    AddAreaViewProps,
    AreaActionsEnum,
    StepContentProps
} from './AddArea.type';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '../Ui/Components/UiButton';
import { SUCCESS } from '../Ui/colors';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { Grid, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSetRecoilState } from 'recoil';
import { MapAction } from '../../states/global/Map';
import { MapActionsEnum } from '../../components/Map/Map.type';
import ProcessingProgress from '../ProcessingProgress';
import { snakeToCamel } from 'helpers/converter/text';
import { isValidColor } from 'helpers';
import useConverter from '../CustomHooks/Converter/Converter';
import {
    AddAreaContainer,
    ButtonsContent,
    ColorPreview,
    CustomTextfield,
    FormGroupContent,
    FormGroupLabel,
    OverviewTable
} from './AddArea.style';

const steps: string[] = ['Basic information', 'Zone Type', 'Save Zone'];

const StepOneContent: React.FC<StepContentProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <div>
            <p>
                <strong>{translate('t.step')} 1</strong> - {translate('t.basic_information')}
            </p>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                    <CustomTextfield
                        data-testid={'StepOneContent-CustomTextfield-name'}
                        id='area-name'
                        name='name'
                        required
                        label={translate('t.name')}
                        value={props.formData.name}
                        variant='outlined'
                        size='small'
                        fullWidth
                        onChange={(newValue) => {
                            props.setFormData({ type: AreaActionsEnum.SET_NAME, payload: newValue.target.value });
                        }}
                        inputProps={{
                            form: {
                                autocomplete: 'off'
                            }
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const StepTwoContent: React.FC<StepContentProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { displayUserUnits } = useConverter();

    return (
        <div>
            <h3>
                <strong>{translate('t.step')} 2</strong> - {translate('t.zone_type')}
            </h3>
            <Grid container spacing={1}>
                <Grid item xs={12} md={7} lg={7}>
                    <FormControl variant='outlined' component='fieldset'>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        data-testid={'StepTwoContent-Switch-gilad'}
                                        checked={props.formData.speedZone}
                                        onChange={(e) => {
                                            props.setFormData({
                                                type: AreaActionsEnum.SET_SPEED_ZONE,
                                                payload: e.target.checked
                                            });
                                        }}
                                        name='gilad'
                                    />
                                }
                                label={translate('t.speed_zone')}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        data-testid={'StepTwoContent-Switch-jason'}
                                        checked={props.formData.counterZone}
                                        onChange={(e) => {
                                            props.setFormData({
                                                type: AreaActionsEnum.SET_COUNTER_ZONE,
                                                payload: e.target.checked
                                            });
                                        }}
                                        name='jason'
                                    />
                                }
                                label={translate('t.counter_zone')}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        data-testid={'StepTwoContent-Switch-antoine'}
                                        checked={props.formData.maintenanceZone}
                                        onChange={(e) => {
                                            props.setFormData({
                                                type: AreaActionsEnum.SET_MAINTENANCE_ZONE,
                                                payload: e.target.checked
                                            });
                                        }}
                                        name='antoine'
                                    />
                                }
                                label={translate('t.maintenance_zone')}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                    <CustomTextfield
                        data-testid={'StepTwoContent-CustomTextfield-minSpeed'}
                        id='area-min-speed'
                        name='minSpeed'
                        type='number'
                        label={translate('t.min_speed')}
                        value={props.formData.minSpeed}
                        variant='outlined'
                        disabled={!props.formData.speedZone}
                        size='small'
                        fullWidth
                        onChange={(e) => {
                            props.setFormData({
                                type: AreaActionsEnum.SET_MIN_SPEED,
                                payload: parseInt(e.target.value)
                            });
                        }}
                        inputProps={{
                            form: {
                                autocomplete: 'off'
                            },
                            step: 0.1,
                            min: 0,
                            max: 10
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                        }}
                    />
                    <CustomTextfield
                        data-testid={'StepTwoContent-CustomTextfield-maxSpeed'}
                        id='area-max-speed'
                        name='maxSpeed'
                        type='number'
                        label={translate('t.max_speed')}
                        value={props.formData.maxSpeed}
                        variant='outlined'
                        disabled={!props.formData.speedZone}
                        size='small'
                        fullWidth
                        onChange={(e) => {
                            props.setFormData({
                                type: AreaActionsEnum.SET_MAX_SPEED,
                                payload: parseInt(e.target.value)
                            });
                        }}
                        inputProps={{
                            form: {
                                autocomplete: 'off'
                            },
                            step: 0.1,
                            min: 0
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const StepThreeContent: React.FC<Omit<StepContentProps, 'setFormData'>> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { displayUserUnits } = useConverter();

    return (
        <div>
            <h3>
                <strong>{translate('t.step')} 3</strong> - {translate('t.save_zone')}
            </h3>
            <FormGroupContent>
                <FormGroupLabel data-testid={'StepThreeContent-FormGroupLabel-steps-0'}>{steps[0]}</FormGroupLabel>
                <OverviewTable>
                    <tbody>
                        <tr>
                            <td>{translate('t.name')}:</td>
                            <td>{props.formData.name}</td>
                        </tr>
                        <tr>
                            <td>{translate('t.color')}:</td>
                            <td>
                                {props.formData.color}{' '}
                                <ColorPreview
                                    data-testid={'StepThreeContent-ColorPreview-color'}
                                    color={props.formData.color}
                                />
                            </td>
                        </tr>
                    </tbody>
                </OverviewTable>
            </FormGroupContent>
            <FormGroupContent style={{ marginBottom: '10px' }}>
                <FormGroupLabel>{steps[1]}</FormGroupLabel>
                <OverviewTable>
                    <tbody>
                        <tr>
                            <td style={{ width: '30%' }}>{translate('t.speed_zone')}:</td>
                            <td style={{ width: '20%' }}>{props.formData.speedZone ? <CheckIcon /> : <CloseIcon />}</td>
                            <td style={{ width: '20%', color: props.formData.speedZone ? 'inherit' : '#bfbfbf' }}>
                                {translate('t.min_speed')}:
                            </td>
                            <td style={{ color: props.formData.speedZone ? 'inherit' : '#bfbfbf' }}>
                                {props.formData.minSpeed} {displayUserUnits.speed}
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('t.counter_zone')}:</td>
                            <td>{props.formData.counterZone ? <CheckIcon /> : <CloseIcon />}</td>
                            <td style={{ color: props.formData.speedZone ? 'inherit' : '#bfbfbf' }}>
                                {translate('t.max_speed')}:
                            </td>
                            <td style={{ color: props.formData.speedZone ? 'inherit' : '#bfbfbf' }}>
                                {props.formData.maxSpeed} {displayUserUnits.speed}
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('t.maintenance_zone')}:</td>
                            <td>{props.formData.maintenanceZone ? <CheckIcon /> : <CloseIcon />}</td>
                        </tr>
                    </tbody>
                </OverviewTable>
            </FormGroupContent>
        </div>
    );
};

const getStepContent = (
    step: number,
    formData: AddAreaData,
    setFormData: React.Dispatch<AddAreaAction>
): JSX.Element => {
    switch (step) {
        case 0:
            return <StepOneContent {...{ formData, setFormData }} data-testid={'StepOneContent-testid'} />;
        case 1:
            return <StepTwoContent {...{ formData, setFormData }} data-testid={'StepTwoContent-testid'} />;
        case 2:
            return <StepThreeContent formData={formData} data-testid={'StepThreeContent-testid'} />;
        default:
            return <StepOneContent {...{ formData, setFormData }} data-testid={'StepOneContent-default-testid'} />;
    }
};

const getAddBtn = (
    isExecuting: boolean,
    activeStep: number,
    saveArea: () => Promise<void>,
    translate: any
): JSX.Element => {
    return isExecuting ? (
        <ProcessingProgress />
    ) : (
        <Button
            skin={SUCCESS}
            disabled={activeStep < steps.length - 1}
            onClick={saveArea}
            data-testid={'getAddBtn-Button-add'}
        >
            {translate('t.add')}
        </Button>
    );
};

export const AddAreaContent: React.FC<AddAreaViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setMapAction = useSetRecoilState(MapAction);
    return (
        <AddAreaContainer data-testid='AddAreaContent'>
            <Modal
                data-testid={'AddAreaContent-Modal-ShowAddAreaModal'}
                type='ShowAddAreaModal'
                id='add-area-modal'
                leftTitle={translate('t.add_area')}
                width={600}
                buttons={getAddBtn(props.isExecuting, props.activeStep, props.saveArea, translate)}
                onClose={() => {
                    setMapAction({ action: MapActionsEnum.CLEAN_DRAW_AREA });
                }}
                onOpen={() => {
                    props.resetModal();
                }}
                $allowContentOverflow={true}
            >
                <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={props.activeStep}
                    data-testid={'AddAreaContent-Stepper-ShowAddAreaModal'}
                >
                    {steps.map((label, index) => (
                        <Step
                            key={index}
                            completed={props.activeStep > index}
                            data-testid={`AddAreaContent-Step-ShowAddAreaModal-${index}-${snakeToCamel(label)}`}
                        >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>{getStepContent(props.activeStep, props.formData, props.setFormData)}</div>
                <ButtonsContent>
                    <Button
                        data-testid={'AddAreaContent-Button-previous'}
                        style={{ opacity: props.activeStep > 0 ? 1 : 0 }}
                        onClick={() => {
                            props.setActiveStep({ type: ActiveStepActionsEnum.PREVIOUS_STEP });
                        }}
                    >
                        {translate('t.previous')}
                    </Button>
                    {props.activeStep < steps.length - 1 && (
                        <Button
                            data-testid={'AddAreaContent-Button-next'}
                            disabled={
                                !props.formData.name.length ||
                                !props.formData.color.length ||
                                !isValidColor(props.formData.color)
                            }
                            onClick={() => {
                                props.setActiveStep({ type: ActiveStepActionsEnum.NEXT_STEP });
                            }}
                        >
                            {translate('t.next')}
                        </Button>
                    )}
                </ButtonsContent>
            </Modal>
        </AddAreaContainer>
    );
};
