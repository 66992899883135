import React from 'react';
import { ContainerLogo, CustomerRedirectionContainer, ContentBody } from './CustomerRedirection.style';
import { Grid } from '@mui/material';
import CustomerRedirectionForm from './../../components/Customer/CustomerRedirectionForm/CustomerRedirectionForm';
import { getImgSource } from 'helpers/image';

export const CustomerRedirectionContent: React.FC = (): JSX.Element => {
    return (
        <CustomerRedirectionContainer data-testid='CustomerRedirectionContent'>
            <Grid container>
                <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
                    <ContentBody>
                        <Grid container spacing={4} direction='column' alignItems='center' justifyContent='center'>
                            <Grid item xl={7} lg={7} md={7} xs={5} sm={5}>
                                <ContainerLogo>
                                    <img
                                        src={getImgSource('logoWithOutBg')}
                                        className='logo-login'
                                        alt='Logo Bridgestone iTrack Without background'
                                    />
                                </ContainerLogo>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} xs={8} sm={10}>
                                <CustomerRedirectionForm />
                            </Grid>
                        </Grid>
                    </ContentBody>
                </Grid>
                <Grid item xl={6} lg={6} md={12} xs={12} sm={12}></Grid>
            </Grid>
        </CustomerRedirectionContainer>
    );
};
