import TableCell from '@mui/material/TableCell';
import styled from 'styled-components';

export const VehicleMapPopupTyresContent = styled.div``;

export const AlertTd = styled(TableCell)<{ level: number }>`
    padding: 0 15px !important;
    background-color: ${(props) => {
        switch (props.level) {
            case 0:
                return 'transparent';
            case 1:
                return 'rgb(255, 251, 233)';
            case 2:
                return 'rgb(255, 232, 209)';
            case 3:
                return 'rgb(255, 204, 204)';
        }
    }} !important;
    color: ${(props) => {
        switch (props.level) {
            case 0:
                return 'inherit';
            case 1:
                return 'rgb(159, 148, 21)';
            case 2:
                return 'rgb(205, 110, 10)';
            case 3:
                return 'rgb(224, 0, 0)';
        }
    }} !important;
    font-weight: ${(props) => {
        switch (props.level) {
            case 0:
                return 200;
            case 1:
            case 2:
            case 3:
                return 600;
        }
    }} !important;
`;

export const AlertValue = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;
