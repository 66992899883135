import React, { useMemo, useCallback } from 'react';
import { CustomList, DeviceBatteryVoltageGraphContainer } from './DeviceBatteryVoltageGraph.style';
import { DeviceBatteryVoltageGraphViewProps, FetchBatteryData } from './DeviceBatteryVoltageGraph.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTimePicker } from '../../../states/global/Statistics';
import { generateYConfig, isInPeriod } from 'helpers';
import Graf from '../../Graph/Graph';
import { xDomainConfig as xDomainConfigType } from '../../Graph/Graph.type';
import { Interval } from 'luxon';

export const DeviceBatteryVoltageGraphContent: React.FC<DeviceBatteryVoltageGraphViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const globalDateTimePicker = useRecoilValue(DateTimePicker);

    const less3days = useMemo(
        () => isInPeriod(globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo, 2, 'days'),

        [globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo]
    );

    const ticks = useMemo(() => {
        const ticks: number[] = [];
        Interval.fromDateTimes(
            globalDateTimePicker.current.dateFrom.toUTC(),
            globalDateTimePicker.current.dateTo.toUTC()
        )
            .splitBy(less3days ? { hour: 8 } : { day: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                ticks.push(element.valueOf() / 1000);
            });
        return ticks;
    }, [globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo]);

    // later replace with proper timeline in tsx based on granularity
    const timeline = useMemo(() => {
        let timeline = {};
        Interval.fromDateTimes(
            globalDateTimePicker.current.dateFrom.toUTC(),
            globalDateTimePicker.current.dateTo.toUTC()
        )
            .splitBy({ minute: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                const timeKey = element.valueOf();
                timeline[timeKey] = { timeKey };
            });

        return {
            timeline,
            granularity: 60
        };
    }, [globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo]);

    const yDomainConfig = [
        {
            id: '1',
            name: translate('t.battery_voltage'),
            isShared: true
        }
    ];

    const xDomainConfig: xDomainConfigType[] = [
        {
            dataKey: 'measuredAt',
            yAxisId: 'hiddenData',
            name: translate('t.measured_at'),
            hide: true,
            showDot: false
        },
        {
            dataKey: 'batteryVoltage',
            stroke: '#d87d00',
            yAxisId: '1',
            name: translate('t.battery_voltage'),
            showDot: true
        }
    ];

    const yConfig = useCallback(() => {
        return generateYConfig(yDomainConfig, 250, props.scale);
    }, [yDomainConfig, props.scale]);

    return (
        <DeviceBatteryVoltageGraphContainer data-testid='DeviceBatteryVoltageGraphContent'>
            <>
                <CustomList>
                    <ListItemButton
                        selected={props.timeSelected === 1}
                        onClick={(event) => props.handleListItemClick(event, 1)}
                    >
                        <ListItemText primary={translate('ui.time_select.1d')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={props.timeSelected === 2}
                        onClick={(event) => props.handleListItemClick(event, 2)}
                    >
                        <ListItemText primary={translate('ui.time_select.2d')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={props.timeSelected === 3}
                        onClick={(event) => props.handleListItemClick(event, 3)}
                    >
                        <ListItemText primary={translate('ui.time_select.3d')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={props.timeSelected === 7}
                        onClick={(event) => props.handleListItemClick(event, 7)}
                    >
                        <ListItemText primary={translate('ui.time_select.7d')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={props.timeSelected === 14}
                        onClick={(event) => props.handleListItemClick(event, 14)}
                    >
                        <ListItemText primary={translate('ui.time_select.14d')} />
                    </ListItemButton>
                    <ListItemButton
                        selected={props.timeSelected === 30}
                        onClick={(event) => props.handleListItemClick(event, 30)}
                    >
                        <ListItemText primary={translate('ui.time_select.1m')} />
                    </ListItemButton>
                </CustomList>

                <>
                    <Graf
                        xDomainConfig={xDomainConfig}
                        yDomainConfig={yConfig()}
                        graphHeight={400}
                        globalXDomain={globalDateTimePicker}
                        timeline={timeline}
                        fetchData={({ dateFrom, dateTo }): Promise<FetchBatteryData[]> => {
                            return props.getBatteryVoltage(dateFrom, dateTo);
                        }}
                        xTicks={ticks}
                        xTickFormatter={(tickItem: number) => {
                            return `${fromUTCtoUserTimezone({
                                date: tickItem * 1000,
                                format: less3days ? 'dateTime' : 'date',
                                displaySeconds: false
                            })}  `;
                        }}
                    />
                </>
            </>
        </DeviceBatteryVoltageGraphContainer>
    );
};
