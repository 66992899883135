import React, { useEffect } from 'react';
import { HubsAdministrationContent } from './HubsAdministration.view';
import { HubsAdministrationProps } from './HubsAdministration.type';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const HubsAdministration: React.FC<HubsAdministrationProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'administration-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <HubsAdministrationContent data-testid='HubsAdministration-testid' />;
};

export default Wrapper(HubsAdministration);
