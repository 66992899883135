import { TyreTempPressReport, Wheels } from '../../../../models/Tyre.type';

export type PassProps = {
    vehicle: TyreTempPressReport;
    maxPosition: number;
    rowNumber: number;
};

export type TempAndPressListBodyProps = {
    tableDisplay: string | null;
    containsRecomValues: boolean;
} & PassProps;

export type TempAndPressListBodyViewProps = {
    wheelCells: (wheels: Wheels[], maximumWheels: number, hideCold: boolean, isOlderThan24h?: boolean) => JSX.Element[];
    tempDifferenceCell: (wheels: Wheels[]) => JSX.Element;
} & PassProps;

export type PressureCellMode = {
    cold: JSX.Element | null | 'x';
    cold_opt: JSX.Element | null | 'x';
    diff: JSX.Element | null | 'x' | '!' | '';
    hot: JSX.Element;
    hot_opt: JSX.Element;
    all: JSX.Element;
};

export enum ValueTypes {
    TEMPERATURE = 'temperature',
    PRESSURE = 'pressure'
}
