import React, { PropsWithChildren } from 'react';
import { Collapse } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { atom } from 'recoil';

export type FiltersTempAndPressProps = PropsWithChildren & {};

export const FiltersForTempPressAtom = atom<boolean>({
    key: 'filtersForTempPressAtom',
    default: true
});

const FiltersTempAndPress: React.FC<FiltersTempAndPressProps> = (props): JSX.Element => {
    const filtersForTempPressAtom = useRecoilValue(FiltersForTempPressAtom);

    return <Collapse in={filtersForTempPressAtom}>{props.children}</Collapse>;
};

export default FiltersTempAndPress;
