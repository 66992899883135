import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const UiTableViewContent = styled(Box)({});

export const UiTableMainViewContent = styled(Box)({
    overflow: 'auto'
});

export const TableTopbar = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px'
});

export const LeftActionBar = styled(Box)({
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
});

export const RightActionBar = styled(Box)({
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right'
});

export const TableCover = styled(Box)({
    position: 'relative'
});

export const TableLoader = styled(Box)({
    position: 'absolute',
    background: 'rgba(255,255,255,0.7)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10
});
