import PortalApi from 'helpers/api/PortalApiClient';
import {
    ExternalSystemMddBesOverviewResponse,
    ExternalSystemMddBesTyreLogModel,
    TyreLogDetailByVehicleResponse
} from '../models/ExternalSystemMddBesTyreLog.type';

export default class ExternalSystemMddBesTyreLog extends PortalApi<ExternalSystemMddBesTyreLogModel> {
    route = 'external-system-mdd-bes-tyre-log';

    getTyreLogDetailByVehicle = async (vehicleId: number): Promise<TyreLogDetailByVehicleResponse> => {
        return await this.get({ extendUrl: `vehicle/${vehicleId}/detail` });
    };

    getTyreLogOverview = async (): Promise<ExternalSystemMddBesOverviewResponse> => {
        return await this.get({ extendUrl: 'overview' });
    };
}
