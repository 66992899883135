import React from 'react';
import { SensorTableAroundContainer } from './SensorTableAround.style';
import { SensorTableAroundViewProps } from './SensorTableAround.type';
import SensorLog from '../../../api/SensorLog';
import UiSimpleTable from '../../Ui/Components/UiSimpleTable';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { Grid } from '@mui/material';
import HideColumnsSensorsAroundContent from './Components/HideColumns/HideColumnsSensorsAround';

const SensorLogApi = new SensorLog();

export const SensorTableAroundContent: React.FC<SensorTableAroundViewProps> = ({
    deviceId,
    columns,
    transmissionFiltering,
    toggleHideColumn
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <SensorTableAroundContainer data-testid='SensorTableAroundContent'>
            {transmissionFiltering.offlineSensor && (
                <Alert sx={{ margin: '10px 0px;' }} severity='error'>
                    {translate('t.offline_hub')}
                </Alert>
            )}

            {deviceId ? (
                <>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            textAlign: { sm: 'left', md: 'right' },
                            order: { sm: 1, md: 2 }
                        }}
                    >
                        <HideColumnsSensorsAroundContent columns={columns} toggleHideColumn={toggleHideColumn} />
                    </Grid>

                    <UiSimpleTable
                        columns={columns}
                        fetchFn={SensorLogApi.getSensorAround}
                        queryKey={'sensorAroundtable'}
                        allprops={true}
                        style={{
                            paddingTop: 0
                        }}
                        usePaginator={false}
                        additionalFetchProps={{
                            deviceId: deviceId
                        }}
                        customRowsPerPage={10000} // temp fix
                        defaultSorting={{ order: 'desc', orderBy: 'ownSensor' }}
                    />
                </>
            ) : (
                <Alert severity='info'>{translate('t.no_records_to_show_in_that_time')}</Alert>
            )}
        </SensorTableAroundContainer>
    );
};
