import React from 'react';
import Error from '../../components/ErrorPage';
import { Error404ViewProps } from './Error404.type';
import { Error404ContentContainer } from './Error404.style';
// import module

export const Error404Content: React.FC<Error404ViewProps> = (props): JSX.Element => (
    <Error404ContentContainer data-testid='Error404Content'>
        <Error code={props.code} />
    </Error404ContentContainer>
);
