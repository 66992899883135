import React, { useEffect, useState } from 'react';
import * as view from './NotificationsToggleButton.view';
import * as type from './NotificationsToggleButton.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { User, UserInfo } from '../../../states/global/User';
import { useTranslation } from 'react-i18next';
import { Success } from '../../Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import Account from '../../../api/Account';
import { Wrapper } from 'helpers/wrapper';

const NotificationsToggleButton: React.FC<type.NotificationsToggleButtonProps> = (): JSX.Element => {
    const accountApi = new Account();
    const [muteNotification, setMuteNotification] = useState<boolean | undefined>(true);
    const userInfo = useRecoilValue(UserInfo);
    const [user, setUser] = useRecoilState(User);
    const { t: translate } = useTranslation();
    const toolTipText = translate(muteNotification ? 't.enable_notifications' : 't.disable_notifications');

    const { mutate: notificationMuteMutate, isLoading: notificationMuteLoading } = useMutation(
        accountApi.patchNotificationMute,
        {
            onSuccess: () => {
                const messageKey = !muteNotification ? 't.disabled_notifications' : 't.enabled_notifications';
                setMuteNotification(!muteNotification);

                if (user) {
                    setUser({ ...user, userSetting: { ...user.userSetting, muteNotification: !muteNotification } });
                }

                Success({
                    text: translate(messageKey)
                });
            }
        }
    );

    const toggleNotification = () => {
        notificationMuteMutate(!muteNotification);
    };

    useEffect(() => {
        if (userInfo) {
            setMuteNotification(userInfo.user?.userSetting?.muteNotification);
        }
    }, [userInfo]);

    return (
        <view.NotificationsToggleButtonContent
            data-testid={'NotificationsToggleButton-testid'}
            muteNotification={muteNotification}
            toggleNotification={toggleNotification}
            notificationMuteLoading={notificationMuteLoading}
            toolTipText={toolTipText}
        />
    );
};

export default Wrapper(NotificationsToggleButton);
