import React, { useEffect } from 'react';
import { VehicleStatsContent } from './VehicleStats.view';
import { VehicleStatsProps } from './VehicleStats.type';
import { useSetRecoilState } from 'recoil';
import { FilterPeriod } from './VehicleStats.atom';
import { Wrapper } from 'helpers/wrapper';

const VehicleStats: React.FC<VehicleStatsProps> = (): JSX.Element => {
    const setPeriod = useSetRecoilState(FilterPeriod);

    useEffect(() => {
        setPeriod(+(localStorage.getItem('VehicleStatsPeriod') || 24));
    }, []);

    return (
        <VehicleStatsContent
            data-testid={'VehicleStats-testid'}
            isLoadingForFirstTime={false}
            errorGettingVehicleStats={null}
        />
    );
};

export default Wrapper(VehicleStats);
