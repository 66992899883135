import PortalApi from 'helpers/api/PortalApiClient';
import WheelModel, { SwapPositionResponse, TyreDetailResponse, WheelCodebook } from '../models/Wheel.type';
import { AxiosResponse } from 'axios';

export default class Wheel extends PortalApi<WheelModel> {
    route = 'wheel';

    getCodeBook = async (vehicleId?: number): Promise<WheelCodebook> => {
        return await this.getByCriteria({
            extendUrl: 'codebook',
            criteria: {
                vehicleId: vehicleId ?? 0
            }
        });
    };

    getTyresDetail = async (vehicleId: number): Promise<TyreDetailResponse> => {
        return await this.get({ extendUrl: `vehicle/${vehicleId}`, version: 'v2' });
    };

    swapWheelPositions = async (
        wheelId: number,
        customPosition: number
    ): Promise<AxiosResponse<SwapPositionResponse>> => {
        return await this.post({ extendUrl: `${wheelId}/swap-position`, data: { customPosition } });
    };
}
