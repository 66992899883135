import React from 'react';
import { CollapseMenuButtonProps } from './CollapseMenuButton.type';
import {
    CollapseMenuButtonContainer,
    CollapseSidebarButton,
    CollapseSidebarButtonTooltip
} from './CollapseMenuButton.style';
import { ButtonIcon } from 'components/TopBar/TopBar.style';
import { useProSidebar } from 'react-pro-sidebar';
import useUserLocalStorageSettings from 'components/CustomHooks/UserLocalStorageSettings';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';

const CollapseMenuButton: React.FC<CollapseMenuButtonProps> = (): JSX.Element => {
    const { collapseSidebar, collapsed } = useProSidebar();
    const { setUserSettings } = useUserLocalStorageSettings(['collapseSidebar']);
    const { t: translate } = useTranslation();

    return (
        <CollapseMenuButtonContainer $collapsedSidebar={!collapsed}>
            <CollapseSidebarButtonTooltip
                title={translate('t.collapse_menu')}
                aria-label={'CollapseSidebarButton-Tooltip'}
            >
                <ButtonIcon>
                    <CollapseSidebarButton
                        edge='start'
                        className='CollapseSidebarButton'
                        color='inherit'
                        aria-label='collapse drawer'
                        onClick={() => {
                            setUserSettings('collapseSidebar', !collapsed);
                            collapseSidebar();
                        }}
                        $collapsedSidebar={!collapsed}
                        data-testid='CollapsSidebar-Button'
                    >
                        {collapsed ? <ChevronRight /> : <ChevronLeft />}
                    </CollapseSidebarButton>
                </ButtonIcon>
            </CollapseSidebarButtonTooltip>
        </CollapseMenuButtonContainer>
    );
};

export default Wrapper(CollapseMenuButton);
