import React from 'react';
import {
    ButtonTabs,
    Title,
    SubTitle,
    IconArrowTab,
    MainContainerBodyTab,
    ProfileTabManagerContent as ProfileTabManagerContentStyle,
    SubTitleMainTabBtn,
    TitleMainTabBtn,
    IconWrapper,
    TabsContainer,
    TabsCustom,
    TabCustom
} from './ProfileTabManager.style';
import * as type from './ProfileTabManager.type';
import Box from '@mui/material/Box';
import { ArrowForwardIos } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';

export const ProfileTabManagerContent: React.FC<type.ProfileTabManagerViewProps> = (props): JSX.Element => {
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const TabPanel = (props: type.TabPanelProps): JSX.Element => {
        const { children, value, index, ...other } = props;
        return (
            <MainContainerBodyTab
                role='tabpanel'
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && <Box> {children} </Box>}
            </MainContainerBodyTab>
        );
    };

    const idTabProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    };

    return (
        <ProfileTabManagerContentStyle data-testid='ProfileTabsContent'>
            <TabsContainer>
                <TabsCustom
                    orientation={showXsSm ? 'horizontal' : 'vertical'}
                    variant='scrollable'
                    scrollButtons='auto'
                    value={props.idTabActive}
                    onChange={props.handleChangeTab}
                    aria-label='Vertical tabs example'
                >
                    {props.listTabsMenu.map((tabButton, key) => {
                        return (
                            <TabCustom
                                key={`tab${key}`}
                                data-testid={tabButton.testId}
                                label={
                                    <>
                                        <ButtonTabs>
                                            <TitleMainTabBtn>
                                                <IconWrapper>{tabButton.icon}</IconWrapper>
                                                <Title>{tabButton.title}</Title>
                                            </TitleMainTabBtn>
                                        </ButtonTabs>
                                        <SubTitleMainTabBtn>
                                            <SubTitle>{tabButton.subTitle}</SubTitle>
                                        </SubTitleMainTabBtn>
                                        {!showXsSm ? (
                                            <IconArrowTab>
                                                <ArrowForwardIos />
                                            </IconArrowTab>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                }
                                {...idTabProps(tabButton.idTab)}
                                disabled={tabButton.disabled}
                            />
                        );
                    })}
                </TabsCustom>

                {props.listTabsBody.map((tabBody, key) => {
                    const newTab: number = props.checkPermissionsUser ? tabBody.idTab : tabBody.idTab - 1;

                    return (
                        <TabPanel key={`tabBody${key}`} value={props.idTabActive} index={newTab}>
                            {tabBody.body}
                        </TabPanel>
                    );
                })}
            </TabsContainer>
        </ProfileTabManagerContentStyle>
    );
};
