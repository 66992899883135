import React from 'react';
import { AlertsFilterVehiclesByListContent } from './AlertsFilterVehiclesByList.view';
import { AlertsFilterVehiclesByListProps } from './AlertsFilterVehiclesByList.type';
import { Wrapper } from 'helpers/wrapper';

const AlertsFilterVehiclesByList: React.FC<AlertsFilterVehiclesByListProps> = (): JSX.Element => {
    return <AlertsFilterVehiclesByListContent data-testid='AlertsFilterVehiclesByList-testid' />;
};

export default Wrapper(AlertsFilterVehiclesByList);
