import Account from 'api/Account';
import User from 'api/User';
import React, { useEffect, useMemo, useState } from 'react';
import * as view from './TrackingSettingsForm.view';
import * as type from './TrackingSettingsForm.type';
import { Errors, Success } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { useRecoilValue } from 'recoil';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';

const ApiAccount = new Account();
const ApiUser = new User();

const TrackingSettingsForm: React.FC<type.TrackingSettingsFormProps> = ({
    userDataSetting,
    isCurrentUser,
    userId,
    setReloadPage
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [viewLoading, setViewLoading] = useState<boolean>(true);
    const customerSettingsAtom = useRecoilValue(CustomerSettingsAtom);

    const [inicialValuesForm, setInicialValuesForm] = useState<type.InicialValuesForm>({
        mapType: '',
        showJourneyReport: null,
        mapVehicleLabel: null,
        trackingTrailColour: '',
        useZoomFeatureInStatisticsPage: false,
        showIconOnMap: undefined
    });

    const mapTypeList: type.CommonDropDown[] = useMemo(() => {
        return [
            { value: 'osm', name: 'Open street map' },
            { value: 'bing', name: 'Bing Maps' },
            { value: 'mapbox', name: 'Mapbox' },
            { value: 'arcgis', name: 'ArcGIS' }
        ];
    }, []);

    const mapJourneyReportList: type.CommonDropDown[] = [
        { value: 0, name: 'No' },
        { value: 10, name: '10 min' },
        { value: 30, name: '30 min' },
        { value: 60, name: '1h' },
        { value: 120, name: '2h' },
        { value: 240, name: '4h' },
        { value: 480, name: '8h' },
        { value: 720, name: '12h' },
        { value: 1440, name: '24h' }
    ];

    const mapVehicleLabelList: type.CommonDropDown[] = [
        { value: 1, name: translate('t.show') },
        { value: 0, name: translate('t.hide') }
    ];

    const handleSubmit = async (trackingSettingsFormData: type.InicialValuesForm, setSubmitting): Promise<void> => {
        trackingSettingsFormData.vehicleIconsOnMap = trackingSettingsFormData.showIconOnMap;
        trackingSettingsFormData.mapJourneyReport = trackingSettingsFormData.showJourneyReport;
        trackingSettingsFormData.trackingColor = trackingSettingsFormData.trackingTrailColour
            ? trackingSettingsFormData.trackingTrailColour.replace(/\s+/g, '')
            : trackingSettingsFormData.trackingTrailColour;
        trackingSettingsFormData.useZoomInStatistics = trackingSettingsFormData.useZoomFeatureInStatisticsPage;

        const { ...newTrackingSettingsFormData } = trackingSettingsFormData;

        const TrackingPatch = isCurrentUser
            ? ApiAccount.patchSetting(newTrackingSettingsFormData)
            : ApiUser.patchSetting(userId as number, newTrackingSettingsFormData);
        const { userSetting }: type.IUserSetting = await TrackingPatch;

        if (userSetting) {
            Success({ text: translate('t.tracking_settings_modified_successfully') });
            setReloadPage(true);
        } else {
            Errors({ text: translate('t.error_modifying_tracking_settings') });
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (userDataSetting && !!Object.keys(userDataSetting).length) {
            setInicialValuesForm({
                mapType: userDataSetting.mapType,
                showJourneyReport: userDataSetting.mapJourneyReport, // need to check this for user
                mapVehicleLabel: userDataSetting.mapVehicleLabel,
                trackingTrailColour: userDataSetting.trackingColor,
                useZoomFeatureInStatisticsPage: userDataSetting.useZoomInStatistics,
                showIconOnMap: userDataSetting.vehicleIconsOnMap
            });
        }
        setViewLoading(false);
    }, [userDataSetting]);

    return !viewLoading ? (
        <view.TrackingSettingsFormContent
            data-testid={'TrackingSettingsForm-testid'}
            inicialValuesFormik={inicialValuesForm}
            mapTypeList={mapTypeList}
            mapJourneyReportList={mapJourneyReportList}
            mapVehicleLabelList={mapVehicleLabelList}
            handleSubmit={handleSubmit}
        />
    ) : (
        <UiLoadingPage size='30px' />
    );
};

export default TrackingSettingsForm;
