import React from 'react';
import { SIZE_INPUT } from '../../variables';
import * as style from './UiCheckbox.style';
import * as type from './UiCheckbox.type';
// import module

export const UiCheckboxContent: React.FC<type.UiCheckboxProps> = (props): JSX.Element => (
    <style.StyledFormControlLabel
        control={<style.StyledCheckbox {...props} size={SIZE_INPUT} />}
        label={<>{props.label}</>}
        skinlabel={props.skinlabel}
        skin={props.skin}
        labelPlacement={props.labelPlacement ? props.labelPlacement : 'end'}
    />
);
