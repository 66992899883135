import PortalApi from 'helpers/api/PortalApiClient';
import { ChangesPasswordType, RecoverPasswordModel, SendEmailType } from '../models/RecoverPassword.type';
import { UserData } from 'models/User.type';

export default class RecoverPassword extends PortalApi<RecoverPasswordModel> {
    route = 'recover-password';

    sendEmail = async (data: SendEmailType): Promise<{ data: UserData }> => {
        return await this.post({
            data: { username: data.username },
            apiProject: 'public'
        });
    };

    updatePassword = async (data: ChangesPasswordType): Promise<any> => {
        return await this.patch({
            data: { newPassword: data.newPassword },
            extendUrl: `change-password/${data.hash}`,
            apiProject: 'public'
        });
    };
}
