import React from 'react';
import * as view from './ActiveVersions.view';
import * as type from './ActiveVersions.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import Version from '../../api/Version';
import { VersionAPIModel } from '../../models/Version.type';

const ActiveVersions: React.FC<type.ActiveVersionsProps> = (): JSX.Element => {
    const activeVersionAPI = new Version();
    const { data } = useQuery<boolean, Error, VersionAPIModel>(
        ['activeVersions'],
        () => {
            return activeVersionAPI.get({ apiProject: undefined });
        },
        { refetchInterval: false }
    );

    return <view.ActiveVersionsContent data-testid='ActiveVersions-testid' data={data} />;
};

export default Wrapper(ActiveVersions);
