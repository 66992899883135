import React, { useEffect } from 'react';
import * as view from './TrackingMap.view';
import * as type from './TrackingMap.type';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const TrackingMap: React.FC<type.TrackingMapProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tracking-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <view.TrackingMapContent data-testid='TrackingMap-testid' />;
};

export default Wrapper(TrackingMap);
