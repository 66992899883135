import styled from 'styled-components';

export const ActiveVersionsContainer = styled.div`
    padding-top: 16px;

    & .MuiTypography-root {
        font-size: 0.86rem;
    }
`;

export const Versions = styled.div`
    font-size: 0.86rem;
`;
