import React from 'react';
import * as style from './GeneralSettingsForm.style';
import * as type from './GeneralSettingsForm.type';
import { useTranslation } from 'react-i18next';
import { GeneralSettingsFormik } from './GeneralSettingsForm.formik';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';

export const GeneralSettingsFormContent: React.FC<type.GeneralSettingsFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <style.GeneralSettingsFormContent data-testid='GeneralSettingsFormContent'>
            <ContainerBodyTab>
                <TitleContentTab>{translate('t.general_settings')}</TitleContentTab>
                <style.ContainerFormGeneralSettings>
                    <GeneralSettingsFormik
                        inicialValuesFormik={props.inicialValuesFormik}
                        timeZoneData={props.timeZoneData ? props.timeZoneData : []}
                        loginTimeOutPeriodList={props.loginTimeOutPeriodList}
                        handleSubmit={props.handleSubmit}
                        pressureUnitList={props.pressureUnitList}
                    />
                </style.ContainerFormGeneralSettings>
            </ContainerBodyTab>
        </style.GeneralSettingsFormContent>
    );
};
