import React from 'react';
import { FabCustom, VehicleStatsCollapseButtonContainer } from './VehicleStatsCollapseButton.style';
import { VehicleStatsCollapseButtonProps } from './VehicleStatsCollapseButton.type';
import { useRecoilState } from 'recoil';
import { CollapseVehicleStatsRow } from './VehicleStatsCollapseButton.atom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VehicleStatsCollapseButtonContent: React.FC<VehicleStatsCollapseButtonProps> = ({
    testId,
    vehicleId
}): JSX.Element => {
    const [collapsedVehicleRow, setCollapsedVehicleRow] = useRecoilState(CollapseVehicleStatsRow);

    return (
        <VehicleStatsCollapseButtonContainer data-testid='VehicleStatsCollapseButtonContent'>
            <FabCustom
                data-testid={`expand-action-${testId}`}
                size='small'
                aria-label='Expand Row'
                $collapsed={collapsedVehicleRow === vehicleId}
                onClick={() => {
                    setCollapsedVehicleRow((current) => (current === vehicleId ? 0 : vehicleId));
                }}
            >
                <FontAwesomeIcon icon={['fas', 'chevron-down']} size='sm' />
            </FabCustom>
        </VehicleStatsCollapseButtonContainer>
    );
};

export default VehicleStatsCollapseButtonContent;
