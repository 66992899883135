import {
    ExportedReportActions,
    ExportedReportState,
    ReportDetailActionType,
    ReportIdToCancelActionType
} from './ExportedReports.type';

export const ExportedReportInitalState: ExportedReportState = {
    report: undefined,
    reportIdToCancel: undefined
};

export const ExportedReportReducer = (
    state: ExportedReportState,
    action: ReportDetailActionType | ReportIdToCancelActionType
) => {
    switch (action.type) {
        case ExportedReportActions.SET_REPORT_DETAIL:
            return {
                ...state,
                report: action.payload
            };
        case ExportedReportActions.SET_REPORT_ID_TO_CANCEL:
            return {
                ...state,
                reportIdToCancel: action.payload
            };
        default:
            return state;
    }
};
