import React from 'react';
import { CloseButton, MapPopUpContainer } from './MapPopUp.style';
import { MapPopUpProps } from './MapPopUp.type';
import CloseIcon from '@mui/icons-material/Close';

const MapPopUp: React.FC<MapPopUpProps> = (props): JSX.Element => {
    return (
        <MapPopUpContainer showPopup={props.showPopup} data-testid='MapPopUpContent'>
            <div id='map-popup-general' className='ol-popup'>
                <CloseButton
                    id='popup-closer'
                    variant='body2'
                    style={{ color: '#0071c7', cursor: 'pointer' }}
                    data-testid={'MapPopUp-CloseButton'}
                >
                    <CloseIcon />
                </CloseButton>
                {props.content}
            </div>
        </MapPopUpContainer>
    );
};

export default MapPopUp;
