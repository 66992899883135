import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DropDownUpdateFormikProps, InitialValues } from './DropdownUpdate.type';
import Grid from '@mui/material/Grid';
import { ButtonDone, GridDropdownUpdate } from './DropdownUpdate.style';
import DropDown from '../../Ui/Components/UiDropDown';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import Done from '@mui/icons-material/Done';

export const DropDownUpdateFormik: React.FC<DropDownUpdateFormikProps> = ({
    initialValues,
    handleSubmit,
    loading,
    listLoading,
    list,
    type,
    testid,
    valueId
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({
        comment: Yup.string().trim().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InitialValues>) => {
                const { setFieldValue, values } = propsFormik;

                const handleOnClick = (): void => {
                    handleSubmit(values);
                };

                return (
                    <Form id='sensordropdown-action-form'>
                        <Grid container>
                            <GridDropdownUpdate item xl={10} lg={10} md={10} sm={10} xs={10}>
                                {listLoading ? (
                                    <UiLoadingPage size='30px' $marginTop='0px' />
                                ) : (
                                    <DropDown
                                        options={list ?? []}
                                        inputName='id-dropdown'
                                        testid={`${testid || 'UiDropDown'}-Field`}
                                        label={translate(`t.${type}`)}
                                        value={values.id ? values.id : ' '}
                                        disabled={false}
                                        getValue={(e) => {
                                            setFieldValue('id', e ?? null);
                                        }}
                                        defaultValue={valueId ?? null}
                                    />
                                )}
                            </GridDropdownUpdate>
                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <ButtonDone
                                    skin='success'
                                    color='primary'
                                    testid={`${testid || 'UiDropDown'}-Button`}
                                    variant={VARIANT_BUTTON}
                                    onClick={handleOnClick}
                                    disabled={loading || !list?.find((element) => element.id == values.id)}
                                >
                                    {loading ? (
                                        <UiLoadingPage size='25px' $marginTop='0px' />
                                    ) : (
                                        <Done fontSize='inherit' />
                                    )}
                                </ButtonDone>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
