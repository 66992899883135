import { LoginContentStyle, BackgroundVideo, BlurLayer, ContainerVideoLogin } from './Login.style';
import { Card, CardContent } from '@mui/material';
import { LoginViewProps } from './Login.type';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Theme, ThemeMode } from 'states/global/Theme';
import { getVideoSource } from 'helpers/video';
import Login from 'components/User/Login/Login';
import RecoveryPassword from 'components/User/RecoveryPassword/RecoveryPassword';
import { LoginShowContent, showContentType } from 'states/global/Login';
import { getImgSource } from 'helpers/image';
import UsernameRecovery from 'components/User/UsernameRecovery/UsernameRecovery';

const showContent = (loginShowContent: showContentType): JSX.Element => {
    switch (loginShowContent) {
        case 'recovery-password':
            return <RecoveryPassword />;
            break;
        case 'username-recovery':
            return <UsernameRecovery />;
            break;
        default:
            return <Login />;
            break;
    }
};

export const LoginContent: React.FC<LoginViewProps> = (): JSX.Element => {
    const theme = useRecoilValue(Theme);
    const loginShowContent = useRecoilValue(LoginShowContent);

    return (
        <LoginContentStyle data-testid='LoginContent'>
            <ContainerVideoLogin>
                <BackgroundVideo autoPlay loop muted>
                    <source src={getVideoSource('videoOfBackgroundForLogin')} type='video/mp4' />
                </BackgroundVideo>
                <BlurLayer />
            </ContainerVideoLogin>

            <Card className='card-login' sx={{ zIndex: 99 }}>
                <CardContent>
                    <img
                        src={theme.mode === ThemeMode.light ? getImgSource('logo') : getImgSource('logolight')}
                        className='logo-login'
                        alt='logo'
                    />
                    {showContent(loginShowContent)}
                </CardContent>
            </Card>
        </LoginContentStyle>
    );
};
