import React from 'react';
import {
    CustomerCheckerContainer,
    CustomerCheckerHeader,
    CustomerContent,
    SelectCustomerBtn,
    SelectCustomerExpandBtn
} from './CustomerChecker.style';
import { CustomerCheckerViewProps } from './CustomerChecker.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Alert, Grid } from '@mui/material';
import LogoutButton from 'components/TopBar/LogoutButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { LogoContainer } from 'components/TopBar/TopBar.style';
import { Theme, ThemeMode } from 'states/global/Theme';
import CustomerSelector from 'components/Customer/CustomerSelector';
import { getImgSource } from 'helpers/image';
import Modal from 'components/StatedModal/Modal/Modal';
import UiFullScreenLoader from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader';

export const CustomerCheckerContent: React.FC<CustomerCheckerViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useRecoilValue(Theme);

    return (
        <CustomerCheckerContainer data-testid='CustomerCheckerContent'>
            <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center'>
                <Grid item xs={12} md={8} lg={8}>
                    <CustomerContent>
                        <CustomerCheckerHeader>
                            <LogoContainer>
                                <img
                                    src={
                                        theme.mode === ThemeMode.light
                                            ? getImgSource('logo')
                                            : getImgSource('logolight')
                                    }
                                    className='Header-logo'
                                    alt='logo itrack'
                                ></img>
                            </LogoContainer>
                            <LogoutButton isCustomerDisabled />
                        </CustomerCheckerHeader>
                        <Alert severity='error'>{translate('p.site_trying_to_login_is_disabled')}</Alert>

                        <SelectCustomerBtn variant='contained' onClick={props.updateCustomer} id='customer-selector'>
                            {translate('t.select_customer')}
                            <SelectCustomerExpandBtn>
                                <ExpandMore />
                            </SelectCustomerExpandBtn>
                        </SelectCustomerBtn>
                    </CustomerContent>
                </Grid>
            </Grid>
            <CustomerSelector />
            <Modal />
            <UiFullScreenLoader />
        </CustomerCheckerContainer>
    );
};
