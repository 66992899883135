import React from 'react';
import { DataExportProps } from './DataExport.type';
import { ContainerDataExportsList, DataExportContainer, SelectVehicleMessage } from './DataExport.style';
import TopBar from './TopBar/TopBar';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TopBarAtom } from './TopBar/TopBar.atom';
import { Typography } from '@mui/material';
import UiSimpleTable from '../../Ui/Components/UiSimpleTable';

const DataExport: React.FC<DataExportProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);

    return (
        <DataExportContainer data-testid='DataExport-testid'>
            <TopBar {...props} />
            {topBarAtom.vehicle.id === -1 ? (
                <SelectVehicleMessage>
                    <Typography variant='h5'>{translate('p.please_select_vehicle')}</Typography>
                </SelectVehicleMessage>
            ) : (
                <ContainerDataExportsList>
                    <UiSimpleTable {...props} refreshInterval={false} />
                </ContainerDataExportsList>
            )}
        </DataExportContainer>
    );
};

export default DataExport;
