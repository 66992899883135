import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { WidgetStatisticsMapProps } from './WidgetStatisticsMap.type';
import Map from '../../DashboardMap';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../../states/global/Theme';
import { BACKGROUND_DARK_MODE, ERROR } from '../../Ui/colors';
import { useTranslation } from 'react-i18next';
import { MapActionData } from '../../../states/global/Map';
import RoomIcon from '@mui/icons-material/Room';
import { Alert, AlertTitle } from '@mui/material';
import useConverter from '../../CustomHooks/Converter/Converter';
import NoDataContainerContent from '../../Mixs/NoDataContainer/NoDataContainer';
import Widget from '../../../components/Ui/Components/UiWidget';

const WidgetStatisticsMap: React.FC<WidgetStatisticsMapProps> = ({
    sx,
    dateTimePicker,
    validRangeHour
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeModeValue = useRecoilValue(Theme);
    const mapData = useRecoilValue(MapActionData);
    const { fromUserTimezoneToTimezone, dateTimeFormat } = useConverter();
    const headerColor = useMemo(() => {
        return applyStyleByMode({
            theme: ThemeModeValue?.mode,
            light: '#e9e9e9;',
            dark: BACKGROUND_DARK_MODE
        });
    }, [ThemeModeValue]);

    if (validRangeHour) {
        return (
            <Widget
                title={translate('t.map')}
                avatar={<RoomIcon />}
                applyPadding={false}
                headerColor={headerColor}
                id={`map-chart-no-data`}
                sx={{ marginTop: 1 }}
            >
                <NoDataContainerContent
                    icon='hourglass-clock'
                    iconColor={ERROR}
                    message={translate('t.3_hours_period_allowed')}
                />
            </Widget>
        );
    }

    return (
        <>
            {mapData?.journey && !mapData?.journey?.length ? (
                <Alert
                    severity='warning'
                    style={{ margin: '5px 0', marginTop: 1, justifyContent: 'center', alignItems: 'center' }}
                >
                    <AlertTitle>
                        <strong>
                            {translate('t.no_data_for_period_time_from_to', {
                                time1: fromUserTimezoneToTimezone(dateTimePicker.current.dateFrom).toFormat(
                                    dateTimeFormat('dateTime', false)
                                ),
                                time2: fromUserTimezoneToTimezone(dateTimePicker.current.dateTo).toFormat(
                                    dateTimeFormat('dateTime', false)
                                )
                            })}
                        </strong>
                    </AlertTitle>
                </Alert>
            ) : null}

            <Map
                enableDrawing={false}
                singleVehicleMode={true}
                height={57}
                headerColor={headerColor}
                unit='vh'
                sx={sx}
            />
        </>
    );
};

export default WidgetStatisticsMap;
