export enum AlertLevelEnum {
    SET_PERCENTAGE = 'SET_PERCENTAGE',
    SET_VALUE = 'SET_VALUE',
    SET_PERCENTAGE_VALUE = 'SET_PERCENTAGE_VALUE',
    ADJUST_PERCENTAGE = 'ADJUST_PERCENTAGE',
    ADJUST_VALUE = 'ADJUST_VALUE',
    INIT_VALUES = 'INIT_VALUES',
    RECALCULATE_VALUE = 'RECALCULATE_VALUE',
    RECALCULATE_HOT_PRESSURE = 'RECALCULATE_HOT_PRESSURE',
    VALIDATE_VALUE = 'VALIDATE_VALUE',
    VALIDATE_PERCENTAGE = 'VALIDATE_PERCENTAGE',
    VALIDATE_HOT_PRESSURE_VALUE = 'VALIDATE_HOT_PRESSURE_VALUE',
    ADJUST_HOT_PRESSURE_VALUE = 'ADJUST_HOT_PRESSURE_VALUE'
}
