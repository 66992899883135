import React from 'react';
import { SkeletonLoaderContainer } from './SkeletonLoader.style';
import { Skeleton } from '@mui/material';
import { SkeletonLoaderProps } from './SkeletonLoader.type';

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ customRowHeight, testid }): JSX.Element => {
    const rowHeight = customRowHeight ?? 45;

    return (
        <SkeletonLoaderContainer data-testid={`${testid || 'SkeletonLoader'}-SkeletonLoaderContainer`}>
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
            <Skeleton variant='rounded' width='100%' height={rowHeight} />
        </SkeletonLoaderContainer>
    );
};

export default SkeletonLoader;
