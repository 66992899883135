import { Box, ListItemButton, Table, TableCell, TableRow, useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const NotificationsSettingsFormContent = styled.div``;

export const ContainerFormNotificationsSettings = styled.div`
    max-width: 70%;
    min-height: 137px;
    margin: 0 auto;
    padding-top: 5px;

    @media only screen and (min-width: 300px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 600px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }

    ${applyStyleByMode({
        styleOld: `
            & .MuiTypography-body1 {
                font-size: 14px;
            }
        
            & .MuiFormControlLabel-root {
                margin: 0px !important;
            }
        `
    })}
`;

export const LisPageContent = styled.div`
    margin-top: -3px;
    min-width: 600px;
    padding: 0;
    ${() => {
        return applyStyleByMode({
            styleJade: `
                border-radius: 4px;
            `,
            theme: useTheme().palette.mode,
            light: `
                background-color: ${useTheme().palette.background.paper} !important;
                box-shadow: rgb(115 115 115) 0px 3px 20px -9px inset; 
            `,
            dark: `
                background-color: ${useTheme().palette.background.default} !important;
                box-shadow: rgb(91 95 124) 0px 3px 20px -9px inset;
            `
        });
    }}
`;

export const CustomTable = styled(Table)`
    min-width: auto;
    table-layout: fixed;
    & .MuiTableCell-head {
        text-transform: capitalize;
        text-transform: capitalize;
    }
    & .MuiTableCell-root {
        padding: 7px;
        border-bottom: none;
    }
`;

export const CustomBodyRow = styled(TableRow)`
    border-top: #e3e3e3 solid thin;
    padding: 2px !important;
`;

export const CustomBodyCell = styled(TableCell)<{ hiddenBorderLeft?: boolean }>`
    padding: 0px !important;
    border: #e3e3e3 solid thin;
    border-left: ${({ hiddenBorderLeft }) => (hiddenBorderLeft ? 'hidden !important;' : 'initial !important;')};
`;

export const ContainerButton = styled.div`
    float: right;
    padding: 7px;
`;

export const ListItemCustom = styled(ListItemButton)<{ isActive: boolean }>`
    border-radius: 4px;
    ${() => {
        return `
            background: ${useTheme().palette.background.default} !important;
            margin-top: 10px;
        `;
    }}
`;

export const ButtonsCover = styled(Box)`
    display: flex;
    justify-content: start;
`;
