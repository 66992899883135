import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import styled from 'styled-components';

export const AlertsBreakdownTableBodyContent = styled(TableBody);

export const LoadingContent = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 87px;
`;

export const Loader = styled(CircularProgress)`
    width: 20px !important;
    height: 20px !important;
`;
