import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const FilterColumnsContent = styled.div`
    display: inline-block;
`;

export const FilterColumnCover = styled.div`
    display: grid;
    grid-column-gap: 10px;
    padding-top: 1px;
    ${applyStyleByMode({
        styleJade: 'padding-top: 1px;'
    })}
`;

export const LegendContainer = styled.div<{ extraWidth?: boolean }>`
    font-size: 0.875rem;
    margin-left: auto;
    margin: 0px 5px;
    padding: 6px;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 36.5px;
    width: ${({ extraWidth }) => (extraWidth ? '93.34px' : '64px')};
`;

export const TextContainer = styled.div`
    font-size: 12.96px;
    font-weight: 500;

    svg {
        margin-left: 5px;
    }
`;
