import PortalApi from 'helpers/api/PortalApiClient';
import { VehicleModelType, VehicleModelUsed } from '../models/VehicleModel.type';
import { DefaultRequestPropsType } from 'helpers/api/type';

export default class VehicleModel extends PortalApi<VehicleModelType> {
    route = 'vehicle-model';

    getUsed = (props: Omit<DefaultRequestPropsType, 'criteria'>): Promise<VehicleModelUsed> => {
        return this.get({ extendUrl: 'used', ...props });
    };
}
