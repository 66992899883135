import React from 'react';
import { FilterSelectOption } from './VehicleFilters.style';
import { FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import { useRecoilState } from 'recoil';
import { VehicleFiltersAtom } from './VehicleFilters.atom';
import { useTranslation } from 'react-i18next';
import { VehicleTypesCodeBook } from 'models/VehicleType.type';

type FilteringByVehicleGroupTypes = {
    vehicleTypes?: VehicleTypesCodeBook;
};

export const FilteringByVehicleGroup: React.FC<FilteringByVehicleGroupTypes> = ({ vehicleTypes }): JSX.Element => {
    const [vehicleFiltersAtom, setVehicleFiltersAtom] = useRecoilState(VehicleFiltersAtom);
    const { t: translate } = useTranslation();

    return (
        <FormControl
            data-testid='SelectToFilterByVehicleGroup'
            variant='outlined'
            size='small'
            sx={{ margin: '5px 0px 10px' }}
        >
            <InputLabel shrink>{translate('t.vehicle_group')}</InputLabel>
            <Select
                variant='outlined'
                size='small'
                value={vehicleFiltersAtom.filterByVehicleType || 0}
                style={{ width: '140px' }}
                onChange={(event) => {
                    setVehicleFiltersAtom({
                        ...vehicleFiltersAtom,
                        filterByVehicleType: event.target.value as number
                    });
                }}
                input={<OutlinedInput notched label={translate('t.vehicle_group')} />}
            >
                <FilterSelectOption selected value={0}>
                    {translate('t.all')}
                </FilterSelectOption>
                {vehicleTypes?.map((vehicleType, index) => {
                    return (
                        <FilterSelectOption value={vehicleType.id} key={`${vehicleType.id}-${index}`}>
                            {vehicleType.type}
                        </FilterSelectOption>
                    );
                })}
            </Select>
        </FormControl>
    );
};
