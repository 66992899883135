import React, { useMemo } from 'react';
import { AlertsBreakdownTableRowProps } from './AlertsBreakdownTableRow.type';
import { useTranslation } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import { AlertsBreakdownLevel, AlertsRowSummary } from '../AlertsBreakdownTable/AlertsBreakdownTableBody.type';
import TableRow from '@mui/material/TableRow';
import { TableCellDiff } from './AlertsBreakdownTableRow.style';

const DEFAULT_ALERT_COUNTS = {
    '': {
        last: 0,
        previous: 0
    },
    1: {
        last: 0,
        previous: 0
    },
    2: {
        last: 0,
        previous: 0
    },
    3: {
        last: 0,
        previous: 0
    }
};

const calculateDiff = (data?: AlertsBreakdownLevel): AlertsRowSummary => {
    if (data) {
        const alertLevels: AlertsBreakdownLevel = { ...DEFAULT_ALERT_COUNTS, ...data };
        let diffPreviousLast = 0;
        for (let level in alertLevels) {
            let lastPreviousData = alertLevels[level];
            const last = lastPreviousData?.last as number;
            const previous = lastPreviousData?.previous as number;
            diffPreviousLast += (last ?? 0) - (previous ?? 0);
            alertLevels[level] = {
                last: last ?? '-',
                previous: previous ?? '-'
            };
        }
        return { levels: alertLevels, diff: diffPreviousLast };
    }
    return {
        levels: DEFAULT_ALERT_COUNTS,
        diff: 0
    };
};

const AlertsBreakdownTableRowContent: React.FC<AlertsBreakdownTableRowProps> = ({ title, data }): JSX.Element => {
    const { t: translate } = useTranslation();
    const alerts = useMemo(() => calculateDiff(data), [data]);

    return (
        <TableRow data-testid='AlertsBreakdownTableRowContent'>
            <TableCell align='center' style={{ fontWeight: 700 }}>
                {translate(`t.${title}`)}
            </TableCell>
            <TableCell align='center'>{alerts?.levels[1]?.last}</TableCell>
            <TableCell align='center'>{alerts?.levels[1]?.previous}</TableCell>
            <TableCell align='center'>{alerts?.levels[2]?.last}</TableCell>
            <TableCell align='center'>{alerts?.levels[2]?.previous}</TableCell>
            <TableCell align='center'>{alerts?.levels[3]?.last}</TableCell>
            <TableCell align='center'>{alerts?.levels[3]?.previous}</TableCell>
            <TableCellDiff diff={alerts.diff} align='center'>
                {`${alerts.diff > 0 ? '+' : ''}${alerts.diff}`}
            </TableCellDiff>
        </TableRow>
    );
};

export default AlertsBreakdownTableRowContent;
