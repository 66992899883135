import React, { useMemo, useRef } from 'react';
import { StatisticsGraphsViewProps, xDomainConfigType, yDomainConfigType } from './StatisticsGraphs.type';
import { StatisticsGraphsContainer } from './StatisticsGraphs.style';
import { VehicleSensor } from 'models/SensorLog.type';
import Graf from '../Graph/Graph';
import Widget from 'components/Ui/Components/UiWidget';
import Button from 'components/Ui/Components/UiButton';
import TimelineIcon from '@mui/icons-material/Timeline';
import { DateTimePicker, SelectedVehicle, Timeline } from 'states/global/Statistics';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import { DEFAULT_GRAPH_SCALE, applyStyleByMode, deepCopyObj, generateYConfig, propertyInObjectArray } from 'helpers';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { SensorStatusEnum } from 'variables';
import UiIcon from '../Ui/Components/UiIcon/UiIcon';
import { useTranslation } from 'react-i18next';
import { Theme, ThemeMode } from 'states/global/Theme';
import { BACKGROUND_DARK_MODE, BACKGROUND_BLUE, TEXT_BLACK_OLD, DARK_STEEL_GREY, WHITE } from '../Ui/colors';
import { UserInfo } from 'states/global/User';
import StatisticsSpeed from './StatisticsSpeed/StatisticsSpeed';
import useConverter from '../CustomHooks/Converter/Converter';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import UiLink from 'components/Ui/Components/UiLink/UiLink';

const getExternalIcon = (sensorType: number, color: string): JSX.Element => {
    return sensorType === 2 ? (
        <ExternalIcon width={12} height={12} fill={color} key={`${sensorType}-${color}`} />
    ) : (
        <></>
    );
};

const getFaultyIcon = (status: SensorStatusEnum, color: string): JSX.Element => {
    return [SensorStatusEnum.FAULT, SensorStatusEnum.SUSPICIOUS].includes(status) ? (
        <>
            {
                <PriorityHighIcon
                    width={12}
                    height={12}
                    fill={color}
                    style={{ fontSize: '1em' }}
                    key={`${status}-${color}`}
                />
            }
        </>
    ) : (
        <></>
    );
};

const isExternalTemperatureDisabled = (sensorType: number, externalTemperatureEnabled: boolean): boolean => {
    return sensorType === 2 && !externalTemperatureEnabled;
};

export const StatisticsGraphsContent: React.FC<StatisticsGraphsViewProps> = (props): JSX.Element => {
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);
    const timeline = useRecoilValue(Timeline);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const tempPressWidgetRef = useRef<HTMLDivElement | null>(null);
    const { t: translate } = useTranslation();
    const ThemeModeValue = useRecoilValue(Theme);
    const lineColor: string = ThemeModeValue?.mode === ThemeMode.light ? '#000' : '#16a085';
    const lineColorTempPress: string = ThemeModeValue?.mode === ThemeMode.light ? '#000' : '#EA2027';
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const { fromUTCToTimezone, displayUserUnits, fromServerToUserUnit, convertType } = useConverter();

    let xDomainConfig: xDomainConfigType[] = [
        {
            dataKey: 'measuredAtScale',
            yAxisId: 'hiddenData',
            name: translate('t.measured_at'),
            hide: true,
            showDot: false
        },
        {
            dataKey: 'sensorName',
            yAxisId: 'hiddenData',
            hide: true,
            showDot: false,
            name: translate('t.sensor')
        },
        {
            dataKey: 'coldPressure',
            stroke: '#100ef9',
            yAxisId: '1',
            name: translate('t.cold_pressure'),
            unit: displayUserUnits.pressure,
            showDot: true
        },
        {
            dataKey: 'hotPressure',
            stroke: lineColorTempPress,
            yAxisId: '1',
            name: translate('t.hot_pressure'),
            unit: displayUserUnits.pressure,
            showDot: true
        },
        {
            dataKey: 'temperature',
            stroke: '#d87d00',
            yAxisId: '2',
            name: translate('t.temperature'),
            unit: displayUserUnits.temperature,
            showDot: true
        },
        {
            dataKey: 'readByVehicleName',
            stroke: lineColorTempPress,
            yAxisId: 'hiddenData',
            name: translate('t.read_by_vehicle'),
            showDot: false,
            hide: true
        },
        {
            dataKey: 'readByHubSerialNumber',
            stroke: lineColorTempPress,
            yAxisId: 'hiddenData',
            name: translate('t.read_by_hub'),
            showDot: false,
            hide: true
        },
        {
            dataKey: 'optimalPressure',
            stroke: '#29a40f',
            yAxisId: '1',
            name: translate('t.optimal_cold_pressure'),
            showDot: false,
            hide: false
        }
    ];

    const extendedTooltipData = [
        {
            dataKey: 'id',
            stroke: lineColorTempPress,
            yAxisId: 'hiddenData',
            name: translate('t.log'),
            showDot: false,
            hide: true
        },
        {
            dataKey: 'wheelId',
            stroke: lineColorTempPress,
            yAxisId: 'hiddenData',
            name: `${translate('t.wheel')} ID`,
            showDot: false,
            hide: true
        },
        {
            dataKey: 'vehicleId',
            stroke: lineColorTempPress,
            yAxisId: 'hiddenData',
            name: `${translate('t.vehicle')} ID`,
            showDot: false,
            hide: true
        }
    ];

    const yDomainConfig: yDomainConfigType[] = [
        {
            id: '1',
            name: translate('t.pressure_with_unit', { unit: displayUserUnits.pressure })
        },
        {
            id: '2',
            name: translate('t.temperature_with_unit', { unit: displayUserUnits.temperature })
        }
    ];

    xDomainConfig = isSuperAdmin ? [...xDomainConfig, ...extendedTooltipData] : xDomainConfig;

    const xDomainConfigDiff = deepCopyObj(xDomainConfig);
    const optimalPressureProp = xDomainConfigDiff.filter((xDomain) => xDomain.dataKey === 'optimalPressure');
    if (optimalPressureProp.length) {
        optimalPressureProp[0].hide = true;
        optimalPressureProp[0].showDot = false;
    }

    const maxLimit: number = displayUserUnits.pressure === 'bar' ? 1 : displayUserUnits.pressure === 'kPa' ? 206 : 30;
    const tictLevel: number = displayUserUnits.pressure === 'bar' ? 0.1 : displayUserUnits.pressure === 'kPa' ? 40 : 10;
    const yDomainDiff = generateYConfig(yDomainConfig, tictLevel, { left: [0, maxLimit], right: [0, 30] }, true);

    if (props.diffData.length > 0) {
        const maxLimitLeft = Math.round(props?.diffScale?.left[1] as number);
        const maxLimitRight = Math.round(props?.diffScale?.right[1] as number);

        yDomainDiff[0].ticks = [0, maxLimitLeft + (maxLimitLeft > 8 ? 5 : 2) ?? 30];
        yDomainDiff[1].ticks = [0, maxLimitRight + (maxLimitRight > 8 ? 5 : 2) ?? 30];
    }

    const generateWidgets = (
        allSensors?: VehicleSensor,
        isLoading?: boolean,
        selectedVehicle?: {
            id: number;
            name: string;
            icon: string;
            distribution?: string | undefined;
        }
    ): (JSX.Element | JSX.Element[])[] => {
        if (isLoading) {
            return [];
        } else {
            const yDomain = generateYConfig(yDomainConfig, DEFAULT_GRAPH_SCALE, props.globalYDomain);
            return (
                allSensors?.vehicleSensor.map((wheel, index) => {
                    let currentSensor = wheel.currentSensor?.map((sensor, index, sensorArray) => {
                        const externalIcon: JSX.Element = getExternalIcon(
                            sensor.sensorType,
                            applyStyleByMode({
                                styleOld: '#fff',
                                theme: ThemeModeValue?.mode,
                                light: BACKGROUND_BLUE,
                                dark: BACKGROUND_BLUE
                            })
                        );
                        const faultyIcon: JSX.Element = getFaultyIcon(
                            sensor.status,
                            applyStyleByMode({
                                styleOld: '#fff',
                                theme: ThemeModeValue?.mode,
                                light: BACKGROUND_BLUE,
                                dark: BACKGROUND_BLUE
                            })
                        );
                        const separator: string = index < sensorArray.length - 1 ? ',' : '';
                        return (
                            <strong key={index}>
                                <UiLink
                                    styleCustom={{ textDecoration: 'underline' }}
                                    $padding='0'
                                    content={sensor.serialNumberHex}
                                    url={`/sensors?id=${sensor.serialNumberHex}${
                                        Object.keys(faultyIcon.props).length > 0 ? '&inactive=true' : ''
                                    }`}
                                    color={applyStyleByMode({
                                        styleOld: TEXT_BLACK_OLD,
                                        theme: ThemeModeValue?.mode,
                                        light: DARK_STEEL_GREY,
                                        dark: WHITE
                                    })}
                                />

                                {externalIcon}
                                {faultyIcon}
                                {separator}
                            </strong>
                        );
                    });
                    const currentSensorLabel = currentSensor?.length ? (
                        currentSensor
                    ) : (
                        <strong key={`strong-1-${index}`}>{translate('t.none')}</strong>
                    );
                    const externalTemperatureDisabled: boolean = wheel.currentSensor.length
                        ? isExternalTemperatureDisabled(
                              wheel.currentSensor[0].sensorType,
                              !!userInfo?.user?.customer?.externalTemperatureEnabled
                          )
                        : false;
                    let tempPressLabel: string = externalTemperatureDisabled
                        ? translate('t.pressure')
                        : translate('t.temperature_pressure');
                    const sensorsInPeriod: JSX.Element[] =
                        wheel.sensorsInPeriod
                            ?.filter((sensor) => {
                                return !propertyInObjectArray(sensor.serialNumber, 'serialNumber', wheel.currentSensor);
                            })
                            .map((sensor) => {
                                const externalIcon: JSX.Element = getExternalIcon(
                                    sensor.sensorType,
                                    applyStyleByMode({
                                        styleOld: '#fff',
                                        theme: ThemeModeValue?.mode,
                                        light: BACKGROUND_BLUE,
                                        dark: BACKGROUND_BLUE
                                    })
                                );
                                const faultyIcon: JSX.Element = getFaultyIcon(
                                    sensor.status,
                                    applyStyleByMode({
                                        styleOld: '#fff',
                                        theme: ThemeModeValue?.mode,
                                        light: BACKGROUND_BLUE,
                                        dark: BACKGROUND_BLUE
                                    })
                                );
                                return (
                                    <>
                                        {sensor.serialNumberHex} {externalIcon} {faultyIcon}
                                    </>
                                );
                            }) || [];

                    const xDomainCopy = deepCopyObj(xDomainConfig);
                    const optimalPressureProp = xDomainCopy.filter((xDomain) => xDomain.dataKey === 'optimalPressure');
                    optimalPressureProp.length &&
                        (optimalPressureProp[0].name = `${optimalPressureProp[0].name}: ${fromServerToUserUnit({
                            type: convertType.pressure,
                            value: wheel.optimalPressure ?? 64,
                            displayUnits: true,
                            fixed: 1
                        })}`);

                    if (!wheel.overlappingSensor?.length) {
                        const temperatureProp = xDomainCopy.filter((xDomain) => xDomain.dataKey === 'temperature');
                        if (temperatureProp.length && externalTemperatureDisabled) {
                            temperatureProp[0].showDot = false;
                            temperatureProp[0].name = translate('t.no_temperature_available');
                            temperatureProp[0].hideOnlyLine = true;
                        }
                    }
                    const coldPressureProp = xDomainCopy.filter((xDomain) => xDomain.dataKey === 'coldPressure');
                    if (coldPressureProp.length && props.isAllSensorExternal) {
                        coldPressureProp[0].hideOnlyLine = true;
                        coldPressureProp[0].showDot = false;
                        coldPressureProp[0].name = translate('t.no_cold_pressure_available');
                    }

                    if (wheel.overlappingSensor?.length) {
                        return wheel.overlappingSensor.map((overlappingSensor, innerIndex) => {
                            const overlapIsExternalTemperatureDisabled = isExternalTemperatureDisabled(
                                overlappingSensor.sensorType,
                                !!userInfo?.user?.customer?.externalTemperatureEnabled
                            );
                            const overlapTempPressLabel = overlapIsExternalTemperatureDisabled
                                ? translate('t.pressure')
                                : translate('t.temperature_pressure');

                            const temperatureProp = xDomainCopy.filter((xDomain) => xDomain.dataKey === 'temperature');
                            if (temperatureProp.length && overlapIsExternalTemperatureDisabled) {
                                temperatureProp[0].showDot = false;
                                temperatureProp[0].name = translate('t.no_temperature_available');
                                temperatureProp[0].hideOnlyLine = true;
                            } else {
                                temperatureProp[0].showDot = true;
                                temperatureProp[0].name = translate('t.temperature');
                                temperatureProp[0].hideOnlyLine = false;
                            }
                            const externalIcon: JSX.Element = getExternalIcon(
                                overlappingSensor.sensorType,
                                applyStyleByMode({
                                    styleOld: '#fff',
                                    theme: ThemeModeValue?.mode,
                                    light: BACKGROUND_BLUE,
                                    dark: BACKGROUND_BLUE
                                })
                            );
                            const faultyIcon: JSX.Element = getFaultyIcon(
                                overlappingSensor.status,
                                applyStyleByMode({
                                    styleOld: '#fff',
                                    theme: ThemeModeValue?.mode,
                                    light: BACKGROUND_BLUE,
                                    dark: BACKGROUND_BLUE
                                })
                            );
                            const label: string = overlappingSensor.serialNumberHex;
                            const overlappingSensorLabel: JSX.Element = (
                                <strong key={`${label}-strong`}>
                                    <UiLink
                                        styleCustom={{ textDecoration: 'underline' }}
                                        $padding='0'
                                        content={label}
                                        url={`/sensors?id=${label}${
                                            Object.keys(faultyIcon.props).length > 0 ? '&inactive=true' : ''
                                        }`}
                                        color={applyStyleByMode({
                                            styleOld: TEXT_BLACK_OLD,
                                            theme: ThemeModeValue?.mode,
                                            light: DARK_STEEL_GREY,
                                            dark: WHITE
                                        })}
                                    />
                                    {externalIcon} {faultyIcon}
                                </strong>
                            );
                            const isSecondary: boolean = innerIndex > 0;
                            let comparingData = 0;
                            if (wheel.position < 3 && !isSecondary) {
                                comparingData = wheel.position;
                            }
                            const id = `statistics-chart-${userInfo?.user?.customer?.name || 12}-${
                                selectedVehicle?.id
                            }-${wheel.customPosition}-${overlappingSensor.id}`;
                            return (
                                <Widget
                                    avatar={<TimelineIcon />}
                                    key={`overlapping${innerIndex}`}
                                    headerColor={applyStyleByMode({
                                        theme: ThemeModeValue?.mode,
                                        light: '#e9e9e9;',
                                        dark: BACKGROUND_DARK_MODE
                                    })}
                                    collapse={isSecondary}
                                    ignoreStoredCollapse={true}
                                    title={
                                        props.isReloading ? (
                                            translate('t.loading')
                                        ) : (
                                            <>
                                                {`${translate('t.tyre')} #${wheel.customPosition} ${translate(
                                                    't.sensor'
                                                )} `}{' '}
                                                {overlappingSensorLabel}
                                                {` - ${overlapTempPressLabel}`}
                                            </>
                                        )
                                    }
                                    collapseButton={true}
                                    loading={isLoading}
                                    id={id}
                                    forceCollapse={props?.collapsed[id]?.collapsed || false}
                                    forceUncollapse={!(props?.collapsed[id]?.collapsed || false)}
                                    onCollapse={(id2, collapse) => {
                                        props.setCollapsed({ type: 'updateWidget', collapse, widgetId: id });
                                    }}
                                >
                                    <Graf
                                        key={`graf${innerIndex}`}
                                        testId={'TempPress-Graph1'}
                                        xDomainConfig={deepCopyObj(xDomainCopy)}
                                        yDomainConfig={yDomain}
                                        globalXDomain={globalDateTimePicker}
                                        setGlobalXDomain={setGlobalDateTimePicker}
                                        timeline={timeline}
                                        fetchData={({ dateFrom, dateTo }): Promise<unknown> => {
                                            return props.getTempPress(
                                                dateFrom,
                                                dateTo,
                                                selectedVehicle?.id as number,
                                                wheel.wheelId,
                                                comparingData,
                                                wheel.optimalPressure ?? 64,
                                                translate,
                                                overlappingSensor.id
                                            );
                                        }}
                                        excludeFromTooltip={['optimalPressure']}
                                        xTickFormatter={(tickItem: number) => {
                                            return fromUTCToTimezone(tickItem * 1000, false);
                                        }}
                                    />
                                </Widget>
                            );
                        });
                    }
                    let comparingData = 0;
                    if (wheel.position < 3) {
                        comparingData = wheel.position;
                    }
                    const id = `statistics-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle?.id}-${
                        wheel.customPosition
                    }-${wheel.currentSensor && wheel.currentSensor.length ? wheel.currentSensor[0].id : 999999}`;
                    return (
                        <Widget
                            avatar={<TimelineIcon />}
                            key={`wheel${index}`}
                            headerColor={applyStyleByMode({
                                theme: ThemeModeValue?.mode,
                                light: '#e9e9e9;',
                                dark: BACKGROUND_DARK_MODE
                            })}
                            applyPadding={false}
                            title={
                                props.isReloading ? (
                                    translate('t.loading')
                                ) : (
                                    <>
                                        {`${translate('t.tyre')} #${wheel.customPosition} ${translate('t.sensor')} `}{' '}
                                        {sensorsInPeriod.length ? (
                                            <>
                                                {sensorsInPeriod} {`- ${translate('t.current_sensor')}`}{' '}
                                                {currentSensorLabel}
                                            </>
                                        ) : (
                                            currentSensorLabel
                                        )}
                                        {` - ${tempPressLabel}`}
                                    </>
                                )
                            }
                            $testidCollapseButton={`'Statistics-Tyre#${wheel.customPosition}${sensorsInPeriod}-CollapseButton'`}
                            collapseButton={true}
                            loading={isLoading}
                            ignoreStoredCollapse={true}
                            id={id}
                            forceCollapse={props.collapsed[id]?.collapsed || false}
                            forceUncollapse={!(props.collapsed[id]?.collapsed || false)}
                            headerAction={
                                <div style={{ display: 'inline-block' }}>
                                    <Button
                                        skin={'#c37e00'}
                                        size='small'
                                        color='warning'
                                        variant='text'
                                        style={{
                                            color: applyStyleByMode({
                                                styleOld: '#fff',
                                                theme: ThemeModeValue?.mode,
                                                light: '#e67e22',
                                                dark: '#f39c12'
                                            })
                                        }}
                                        onClick={() => {
                                            setFaultySensorReporterState({
                                                vehicleId: selectedVehicle?.id as number,
                                                vehicleName: selectedVehicle?.name,
                                                sensorId: wheel.currentSensor[0].id,
                                                sensorName: wheel.currentSensor[0].serialNumberHex,
                                                showOnlyGraph: false,
                                                wheelPosition: wheel.customPosition,
                                                internalOnVehicle: !props.isAllSensorExternal,
                                                isExternal: wheel.currentSensor[0].sensorType === 2
                                            });
                                        }}
                                    >
                                        <UiIcon
                                            icon={['fas', 'bug']}
                                            size='lg'
                                            fixedWidth
                                            transform='left-4 up-1'
                                            color={applyStyleByMode({
                                                styleOld: '#fff',
                                                theme: ThemeModeValue?.mode,
                                                light: '#e67e22',
                                                dark: '#f39c12'
                                            })}
                                        />
                                        {translate('t.report_sensor')}
                                    </Button>
                                </div>
                            }
                            onCollapse={(id2, collapse) => {
                                props.setCollapsed({ type: 'updateWidget', collapse, widgetId: id });
                            }}
                        >
                            <Graf
                                xDomainConfig={deepCopyObj(xDomainCopy)}
                                yDomainConfig={yDomain}
                                globalXDomain={globalDateTimePicker}
                                timeline={timeline}
                                testId={'TempPress-Graph2'}
                                setGlobalXDomain={setGlobalDateTimePicker}
                                fetchData={({ dateFrom, dateTo }) => {
                                    return props.getTempPress(
                                        dateFrom,
                                        dateTo,
                                        selectedVehicle?.id as number,
                                        wheel.wheelId,
                                        comparingData,
                                        wheel.optimalPressure ?? 64,
                                        translate
                                    );
                                }}
                                excludeFromTooltip={['optimalPressure']}
                                xTickFormatter={(tickItem: number) => {
                                    return fromUTCToTimezone(tickItem * 1000, false);
                                }}
                            />
                        </Widget>
                    );
                }) || []
            );
        }
    };

    return (
        <StatisticsGraphsContainer data-testid='StatisticsGraphsContent'>
            <Widget
                avatar={<TimelineIcon />}
                title={<>{translate('t.speed')}</>}
                applyPadding={false}
                headerColor={applyStyleByMode({
                    theme: ThemeModeValue?.mode,
                    light: '#e9e9e9;',
                    dark: BACKGROUND_DARK_MODE
                })}
                collapseButton={true}
                lockButton={true}
                id={`speed-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`}
                forceCollapse={
                    props?.collapsed[`speed-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`]
                        ?.collapsed || false
                }
                forceUncollapse={
                    !(
                        props?.collapsed[`speed-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`]
                            ?.collapsed || false
                    )
                }
                onCollapse={(id, collapse) => {
                    props.setCollapsed({
                        type: 'updateWidget',
                        collapse,
                        widgetId: `speed-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`
                    });
                }}
                onScrollFixed={() => {
                    if (tempPressWidgetRef.current) {
                        tempPressWidgetRef.current.style.paddingTop = `314px`;
                    }
                }}
                onScrollRelative={() => {
                    if (tempPressWidgetRef.current) {
                        tempPressWidgetRef.current.style.paddingTop = '0px';
                    }
                }}
                $testidCollapseButton='Statistics-Speed-CollapseButton'
            >
                <StatisticsSpeed lineColor={lineColor} selectedVehicle={selectedVehicle.id} />
            </Widget>

            <div ref={tempPressWidgetRef}>{generateWidgets(props.allSensors, props.isReloading, selectedVehicle)}</div>

            <Widget
                avatar={<TimelineIcon />}
                title={props.isReloading ? translate('t.loading') : <>{translate('t.tyre_temp_press_diff')}</>}
                applyPadding={false}
                headerColor={applyStyleByMode({
                    theme: ThemeModeValue?.mode,
                    light: '#e9e9e9;',
                    dark: BACKGROUND_DARK_MODE
                })}
                collapseButton={true}
                id={`statistics-diff-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`}
                forceCollapse={
                    props?.collapsed[
                        `statistics-diff-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`
                    ]?.collapsed || false
                }
                forceUncollapse={
                    !props?.collapsed[
                        `statistics-diff-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`
                    ]?.collapsed || false
                }
                onCollapse={(id, collapse) => {
                    props.setCollapsed({
                        type: 'updateWidget',
                        collapse,
                        widgetId: `statistics-diff-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`
                    });
                }}
                $testidCollapseButton='Statistics-TyreTempPressDiff-CollapseButton'
            >
                <Graf
                    xDomainConfig={xDomainConfigDiff}
                    yDomainConfig={yDomainDiff}
                    globalXDomain={globalDateTimePicker}
                    timeline={timeline}
                    testId={'TempPress-Graph3'}
                    setGlobalXDomain={setGlobalDateTimePicker}
                    data={props.diffData}
                    excludeFromTooltip={['optimalPressure']}
                    excludeFromLegend={['optimalPressure']}
                    xTickFormatter={(tickItem: number) => {
                        return fromUTCToTimezone(tickItem * 1000, false);
                    }}
                />
            </Widget>
        </StatisticsGraphsContainer>
    );
};
