import React from 'react';
import { TableContainerList, TemperaturePressureListViewContent } from './TemperaturePressureList.style';
import { TemperaturePressureListViewProps } from './TemperaturePressureList.type';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TempAndPressListHeader from './TempAndPressListHeader/TempAndPressListHeader';
import TempAndPressListBody from './TempAndPressListBody/TempAndPressListBody';

import UiScroll from '../../Ui/Components/UiScroll/UiScroll';

export const TemperaturePressureListContent: React.FC<TemperaturePressureListViewProps> = ({
    tableDisplay,
    vehiclesList,
    containsRecomValues,
    orderDirection,
    handleSortRequest,
    maxPosition,
    showScroll
}): JSX.Element => {
    const createContent = () => {
        return (
            <Table size='small' stickyHeader aria-label='sticky table' sx={{ width: '100%', padding: 0 }}>
                <TempAndPressListHeader
                    orderDirection={orderDirection}
                    handleSortRequest={handleSortRequest}
                    maxPosition={maxPosition}
                    tableDisplay={tableDisplay}
                    containsRecomValues={containsRecomValues()}
                />
                <TableBody>
                    {vehiclesList && vehiclesList.length ? (
                        vehiclesList.map((vehicle, i) => (
                            <TempAndPressListBody
                                key={`${vehicle.id}_body`}
                                rowNumber={i}
                                vehicle={vehicle}
                                maxPosition={maxPosition}
                                tableDisplay={tableDisplay}
                                containsRecomValues={containsRecomValues()}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                </TableBody>
            </Table>
        );
    };

    return (
        <TemperaturePressureListViewContent data-testid='TemperaturePressureListContent'>
            <TableContainerList>
                {!showScroll ? createContent() : <UiScroll height='calc(100vh - 180px)'>{createContent()}</UiScroll>}
            </TableContainerList>
        </TemperaturePressureListViewContent>
    );
};
