import PortalApiClient from 'helpers/api/PortalApiClient';
import { SensorsModel } from '../models/Sensors.type';
import { optionsCodeBook } from '../components/Ui/Components/UiDropDown/UiDropDown.type';

export default class SensorStatus extends PortalApiClient<SensorsModel> {
    route = 'sensor-status';

    getSensorsStatusCodebook = async (): Promise<optionsCodeBook[]> => {
        return await this.getCodebook({});
    };
}
