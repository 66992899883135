import { CustomerCodebook } from '../../../models/Customer.type';
import { atom } from 'recoil';

export enum ChangeCustomerEnum {
    CHANGE_MINES_PERMISSION,
    ASSIGNED_CUSTOMER_SEARCH,
    NOT_ASSIGNED_CUSTOMER_SEARCH
}

export type MinesPermissionsType = {
    assigned: CustomerCodebook[];
    notAssigned: CustomerCodebook[];
};

export type InitialStateProps = {
    assignedCustomersSearch: CustomerCodebook | null;
    notAssignedCustomersSearch: CustomerCodebook | null;
    minesPermission: MinesPermissionsType;
};

type ChangeMinesPermissions = {
    type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION;
    payload: MinesPermissionsType;
};

type ChangeCustomerSearch = {
    type: ChangeCustomerEnum.ASSIGNED_CUSTOMER_SEARCH | ChangeCustomerEnum.NOT_ASSIGNED_CUSTOMER_SEARCH;
    payload: CustomerCodebook | null;
};

type ActionType = ChangeMinesPermissions | ChangeCustomerSearch;

export const MinesPermissionsAtom = atom<InitialStateProps>({
    key: 'MinesPermissionAtomKey',
    default: {
        assignedCustomersSearch: null,
        notAssignedCustomersSearch: null,
        minesPermission: {
            assigned: [],
            notAssigned: []
        }
    }
});

export const MinesPermissionsDispatcher = (state: InitialStateProps, setState) => (action: ActionType) => {
    switch (action.type) {
        case ChangeCustomerEnum.CHANGE_MINES_PERMISSION:
            return setState({
                ...state,
                minesPermission: action.payload
            });
        case ChangeCustomerEnum.ASSIGNED_CUSTOMER_SEARCH:
            return setState({
                ...state,
                assignedCustomersSearch: action.payload
            });
        case ChangeCustomerEnum.NOT_ASSIGNED_CUSTOMER_SEARCH:
            return setState({
                ...state,
                notAssignedCustomersSearch: action.payload
            });
    }
};
