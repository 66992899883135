import React, { useEffect } from 'react';
import { VehicleDetailsSchemaContent } from './VehicleDetailsSchema.view';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { VehicleSchemaHoverAtom } from './VehicleDetailsSchema.atom';
import { Loading, LoadingDiv } from './VehicleDetailsSchema.style';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailsSchemaProps } from './VehicleDetailsSchema.type';

const VehicleDetailsSchema: React.FC<VehicleDetailsSchemaProps> = (props): JSX.Element => {
    const setVehicleSchemaHoverAtom = useSetRecoilState(
        VehicleSchemaHoverAtom(props.vehicleAtomId ? `${'VehicleSchemaHoverAtom-' + props.vehicleAtomId}` : undefined)
    );
    const vehicleDetailsWidgetAtom = useRecoilValue(
        VehicleDetailsWidgetAtom(props.vehicleAtomId ? `${'VehicleSchemaAtom-' + props.vehicleAtomId}` : undefined)
    );

    useEffect(() => {
        setVehicleSchemaHoverAtom({
            hoveredPosition: 0,
            hasHover: true
        });
        return () => {
            setVehicleSchemaHoverAtom({
                hoveredPosition: 0,
                hasHover: false
            });
        };
    }, [setVehicleSchemaHoverAtom]);

    if (!vehicleDetailsWidgetAtom.tyreDetails && !vehicleDetailsWidgetAtom.vehicleDetails) {
        return (
            <LoadingDiv>
                <Loading />
            </LoadingDiv>
        );
    }

    return <VehicleDetailsSchemaContent {...props} data-testid='VehicleDetailsSchema-testid' />;
};

export default VehicleDetailsSchema;
