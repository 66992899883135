import React from 'react';
import { ActionContent, DeviceDiagnoseContainer, NoDataWarningMsg, RequestContent } from './DeviceDiagnose.style';
import { DeviceDiagnoseViewProps } from './DeviceDiagnose.type';
import { useTranslation } from 'react-i18next';
import DeviceDiagnoseTable from '../DeviceDiagnoseTable';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const DeviceDiagnoseContent: React.FC<DeviceDiagnoseViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <DeviceDiagnoseContainer data-testid='DeviceDiagnoseTableContent'>
            <ActionContent>
                <Box>
                    {props.isLoading && (
                        <RequestContent>
                            <CircularProgress />
                        </RequestContent>
                    )}
                </Box>
            </ActionContent>

            {props.isFetched && !props.data && (
                <NoDataWarningMsg severity='info'>{translate('t.there_no_data')}</NoDataWarningMsg>
            )}
            {!props.isLoading && props.data && <DeviceDiagnoseTable data={props.data} />}
        </DeviceDiagnoseContainer>
    );
};
