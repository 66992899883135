import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { applyStyleByMode } from 'helpers';
import { TextField } from '@mui/material';

export const HeatmapTopBarContainer = styled.div<{ height?: string }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    ${(props) => {
        return applyStyleByMode({
            styleOld: `height: ${props.height ? props.height : '65px'}`,
            styleJade: `
                height: ${props.height ? props.height : 'auto'};
                padding-top: 5px;
                @media (max-width: 426px) {
                    height: auto;
                    margin-bottom: 5px;
                    display: block;
                    .MuiButton-root{
                        margin-top: 5px;
                    }
                }
            `
        });
    }}
`;

export const DateTimePicker = styled(DatePicker)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiFormControl-root {
                margin: 0 3px !important;
            }
        `
    })}
`;

export const Button = styled(UiButton)`
    ${applyStyleByMode({
        styleOld: `
            margin: 0 !important;
            margin-left: 3px !important;
            padding: 8.81px 8px;
        `
    })}
`;

export const StyledTextField = styled(TextField)`
    &.MuiFormControl-root {
        padding: 0 3px 0 0;
    }
`;
