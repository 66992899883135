import React, { useEffect, useState, useMemo } from 'react';
import { QrCodeModalType } from './QrCodeModal.type';
import QRCode from 'react-qr-code';
import { QrCodeStyled, Time, Timer, TimerLinearProgress } from './QrCodeModal.style';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime, Interval } from 'luxon';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { QrCodeIsExpired } from '../TabletActivation.atom';

//temp function
const parseAPIUrl = (url: string): string => {
    const isRaw = url.includes('.dev');
    let enviroment = '';

    if (isRaw) {
        enviroment = url.split('.')[0].replace('https://itrack-api', '').replace('https://itrack-api-', '');
    } else {
        enviroment = url.split('.')[0].replace('https://webtrack', '').replace('https://webtrack-', '');
    }

    return `https://itrack-api${enviroment ? `${enviroment}` : ''}.atms.dev/`;
};

const QrCodeModal = ({ code, expiresIn }: QrCodeModalType): JSX.Element => {
    const { t: translate } = useTranslation();
    const setQrCodeIsExpired = useSetRecoilState(QrCodeIsExpired);
    const apiURL = process.env.REACT_APP_API_URL || 'https://webtrack.atmstechnology.com/api/';
    const [time, setTime] = useState<number>(expiresIn);
    let timerInterval: NodeJS.Timeout | null = null;
    const progressBarProps = useMemo(() => {
        const percentage = 100 - Math.ceil((time * 100) / expiresIn);
        const color = percentage < 45 ? 'green' : percentage < 80 ? 'orange' : 'red';
        return { percentage, color };
    }, [time]);
    const { setModalState } = useModal(1);

    const windowEvents = () => {
        let onHideTime = DateTime.local().toUTC();
        document.addEventListener(
            'visibilitychange',
            function () {
                if (document.hidden) {
                    timerInterval && clearInterval(timerInterval);
                    onHideTime = DateTime.local().toUTC();
                } else {
                    const onShowTime = DateTime.local().toUTC();
                    const duration = Math.ceil(
                        Interval.fromDateTimes(onHideTime, onShowTime).toDuration(['seconds']).toObject().seconds
                    );
                    setTime((prevState) => prevState - duration);
                    initTimer();
                }
            },
            false
        );
    };

    const initTimer = () => {
        timerInterval && clearInterval(timerInterval);
        timerInterval = setInterval(() => {
            setTime((prevState) => prevState - 1);
        }, 1000);
    };

    useEffect(() => {
        if (time <= 0) {
            setQrCodeIsExpired(true);
            setModalState({ isOpen: false });
        }
    }, [time]);

    useEffect(() => {
        initTimer();

        windowEvents();

        return () => {
            timerInterval && clearInterval(timerInterval);
        };
    }, []);

    return (
        <>
            <Timer>
                <>
                    <>{translate('p.qr_code_expiration')}</>
                    <Timer>
                        <Time>
                            {String(Math.floor(time / 60)).padStart(2, '0')}:{String(time % 60).padStart(2, '0')}
                        </Time>
                        <TimerLinearProgress
                            variant={'determinate'}
                            value={progressBarProps.percentage}
                            customColor={progressBarProps.color}
                        />
                    </Timer>
                </>
            </Timer>
            <QrCodeStyled>
                <QRCode value={`${parseAPIUrl(apiURL)}tablet?code=${code}`} />
            </QrCodeStyled>
        </>
    );
};

export default QrCodeModal;
