import { ZoomType } from 'components/StatisticsTopBar/StatisticsTopBar.type';

export type RankZoomButtonsProps = {};

export type RankZoomButtonsViewProps = {
    resetDateTime: () => void;
    previousZoom: () => void;
    zoom: ZoomType;
};

export const DefaultSeverityRankZoom = { zoomLevels: [], lastZoom: [] };
