import { CircularProgress, Tab, Tabs } from '@mui/material';
import React from 'react';
import { NotTransmittingPopoverViewProps, TabPanelProps } from './NotTransmittingPopover.type';
import { LoadingContent, NotTransmittingPopoverContainer } from './NotTransmittingPopover.style';
import { useTranslation } from 'react-i18next';
import PopoverListContent from '../PopoverListContent/PopoverListContent';
// import module

const TabPanel = (props: TabPanelProps): JSX.Element => (
    <>{props.value === props.index && <PopoverListContent data={props.data} />}</>
);

export const NotTransmittingPopoverContent: React.FC<NotTransmittingPopoverViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <NotTransmittingPopoverContainer data-testid='NotTransmittingPopoverContent'>
            {props.isLoading ? (
                <LoadingContent>
                    <CircularProgress />
                </LoadingContent>
            ) : (
                <>
                    <Tabs
                        value={props.value}
                        variant='fullWidth'
                        onChange={(event, newValue) => props.handleChange(newValue)}
                    >
                        <Tab data-testid='NotTransmittingPopover-Tab-1' label={translate('t.sensors')} />
                        <Tab data-testid='NotTransmittingPopover-Tab-2' label={translate('t.vehicles')} />
                    </Tabs>
                    {props.data && (
                        <>
                            <TabPanel value={props.value} index={0} data={props.data.sensors} />
                            <TabPanel value={props.value} index={1} data={props.data.vehicles} />
                        </>
                    )}
                </>
            )}
        </NotTransmittingPopoverContainer>
    );
};
