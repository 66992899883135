import React from 'react';
import { SensorStateAtomType, SensorStateType } from './SensorManagementRow.atom';
import { CRUD } from 'variables';
export enum SensorStateEnum {
    CHANGE_SENSOR_TYPE,
    CHANGE_NEW_SENSOR,
    RESET_NEW_SENSOR
}

type ChangeSensorTypeType = {
    type: SensorStateEnum.CHANGE_SENSOR_TYPE;
    payload: number;
};

type ChangeNewSensorType = {
    type: SensorStateEnum.CHANGE_NEW_SENSOR;
    payload: SensorStateType;
};

type ResetNewSensor = {
    type: SensorStateEnum.RESET_NEW_SENSOR;
};

export type SensorStateActionType = ChangeSensorTypeType | ChangeNewSensorType | ResetNewSensor;

export const SensorStateDispatcher =
    (state: SensorStateAtomType, setState: React.Dispatch<SensorStateAtomType>) => (action: SensorStateActionType) => {
        switch (action.type) {
            case SensorStateEnum.CHANGE_SENSOR_TYPE:
                return setState({
                    ...state,
                    typeId: action.payload
                });
            case SensorStateEnum.CHANGE_NEW_SENSOR:
                return setState({
                    ...state,
                    action: !state.originalSensor
                        ? CRUD.ADD
                        : state.originalSensor?.id !== action.payload.id
                        ? CRUD.REPLACE
                        : CRUD.REMOVE,
                    newSensor: action.payload
                });
            case SensorStateEnum.RESET_NEW_SENSOR:
                return setState({
                    ...state,
                    newSensor: state.originalSensor,
                    action: state.originalSensor ? CRUD.REMOVE : CRUD.ADD
                });
            default:
                return setState(state);
        }
    };
