import { ButtonGroup, Button as ButtonMui, IconButton, Select, useTheme } from '@mui/material';
import styled from 'styled-components';
import { BACKGROUND_DARK_MODE, WHITE } from '../../Ui/colors';
import Button from '../../Ui/Components/UiButton';
import { ThemeMode } from '../../../states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const TemperaturePressureTableViewContent = styled.div`
    width: 100%;
    margin-right: -400px;

    & .MuiTableHead-root .MuiTableCell-head {
        text-align: center;
        min-height: 45px;
        ${() => {
            return `
                background-color: ${useTheme().palette.mode === ThemeMode.light ? WHITE : BACKGROUND_DARK_MODE};
                min-width: 49px;
                padding: 9px;
            `;
        }}
    }
`;

export const LegendListContainer = styled.ul`
    list-style: none;
    padding: 0px;
    font-size: 13px;
    display: inline-grid;
    line-height: 1.7;
`;

export const LegendItemContainer = styled.li`
    display: inline-flex;
`;

export const IconContainer = styled.div`
    min-width: 28px;
    margin-left: 10px;
`;

export const IconTemperatureAlertDisabledContainer = styled.div`
    min-width: 31px;
    margin-left: 7px;
`;

export const LegendIcon = styled.div<{
    $background: string;
    $borderColor: string;
    $borderWidth?: string;
    $borderRadius?: string;
}>`
    background: ${(props) => `${props.$background}` ?? '#c0c0c0'};
    border: ${(props) => props.$borderWidth ?? '4px'} solid ${(props) => props.$borderColor ?? '#c0c0c0'};
    width: 24px;
    margin-right: 5px;
    margin-bottom: 3px;
    text-align: center;
    ${(props) => {
        return props.$borderWidth === '1px' && 'min-width: 30px;';
    }}
`;

export const ButtonFilter = styled(ButtonMui)<{ $active: boolean }>`
    display: inline-flex;
    border: #777 1px solid !important;
    padding: 5px !important;
    margin: 0 !important;
    background: ${(props) => (props.$active ? '#d9d9d9 ' : '#fff')} !important;
`;

export const ContainerGroupButtonFilter = styled(ButtonGroup)`
    margin-right: 5px !important;
    padding: 1px !important;

    .MuiButtonGroup-grouped {
        min-width: 25px;
    }
`;

export const ContainerActionWidget = styled.div`
    display: flex;
    padding: 0px 0px;
    align-items: center;

    ${() => useTheme().breakpoints.between('xs', 'lg')} {
        height: auto;
    }
`;

export const IconButtonRefresh = styled(IconButton)`
    margin: 0px 3px !important;
    padding: 2px 2px !important;
    font-size: 19px !important;
    width: 26px !important;
    height: 24px !important;
`;

export const SelectFilter = styled(Select)`
    color: unset;

    & .MuiInputBase-input {
        padding: 1px 24px 2px 5px !important;
    }

    & .MuiSelect-icon {
        color: #fff !important;
    }

    &:before {
        border-bottom: 1px solid #fff !important;
    }
    &:after {
        border-bottom: 1px solid #fff !important;
    }
`;

export const ContainerSelectFilter = styled.div`
    padding: 5px 5px;
    align-items: baseline;
    display: inline-flex;
`;

export const SelectFilterLabel = styled.div`
    margin-right: 5px;
`;

export const ContainerToPrint = styled.div<{ $showScroll: boolean }>`
    @media print {
        @page {
            size: A3 landscape;
        }
    }
`;

export const CustomButton = styled(Button)`
    ${() => {
        return `
            color: ${useTheme().palette.mode === ThemeMode.light ? '#000' : WHITE} !important;
        `;
    }}
    text-transform: initial !important;
`;

export const ButtonTitle = styled.div`
    max-width: ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return common.bigFonts ? '175px' : '200px';
    }}
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media only screen and (max-width: 424px) {
        max-width: 140px;
    }
    @media only screen and (min-width: 900px) {
        max-width: ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.bigFonts ? '175px' : '200px';
        }}
    }
    @media only screen and (min-width: 1200px) {
        max-width: ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.bigFonts ? '175px' : '200px';
        }}
    }
    @media only screen and (min-width: 1536px) {
        max-width: 500px;
    }
`;

export const ActionsHeader = styled.div`
    ${() => {
        return `
            background: ${useTheme().palette.mode === ThemeMode.light ? WHITE : 'transparent'};
            border-bottom: thin solid #c0c0c0;
        `;
    }}

    padding: 0px 3px 0px 3px
    text-align: center;
    width: 97.3%;
    margin: 0 auto;
`;
