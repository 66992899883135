import { ListItemText } from '@mui/material';
import React from 'react';
import { PopoverListContentViewProps } from './PopoverListContent.type';
import { AllTransmittingList, PopoverContainer, SensorList } from './PopoverListContent.style';
import { useTranslation } from 'react-i18next';
import SensorItem from './SensorItem/SensorItem';
import VehicleItem from './VehicleItem/VehicleItem';
// import module

export const PopoverContentContent: React.FC<PopoverListContentViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <PopoverContainer data-testid='PopoverContentContent'>
            {!props.data.length ? (
                <AllTransmittingList>
                    <ListItemText>{translate('t.all_transmitting')}</ListItemText>
                </AllTransmittingList>
            ) : (
                <SensorList>
                    {props.data.map((sensor, index) => {
                        return sensor.sensorSerialNumber ? (
                            <SensorItem key={`SensorItem-${sensor.id}`} sensor={sensor} index={index} />
                        ) : (
                            <VehicleItem key={`VehicleItem-${sensor.id}`} vehicle={sensor} />
                        );
                    })}
                </SensorList>
            )}
        </PopoverContainer>
    );
};
