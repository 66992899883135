import React, { useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import {
    GridCover,
    HubGeneralInfoContainer,
    SaveBtn,
    ShowSelfDiagnosticsBtn,
    StyledCardActions,
    StyledTextField
} from './HubGeneralInfo.style';
import { HubGeneralInfoViewProps } from './HubGeneralInfo.type';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { UserInfo } from '../../../states/global/User';
import DeviceDiagnose from '../DeviceDiagnose/DeviceDiagnose';
import { Theme } from 'states/global/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import useModal from 'components/CustomHooks/ModalHook';
import DeviceInformationTable from '../DeviceInformationTable/DeviceInformationTable';

export const HubGeneralInfoContent: React.FC<HubGeneralInfoViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const refshowBtn = useRef<HTMLButtonElement>(null);
    const { setModalState } = useModal();
    const userIsAdmin = useMemo(
        () => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]),
        [userInfo.user, ROLES]
    );
    const userIsSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user, ROLES]);
    const ThemeState = useRecoilValue(Theme);

    return (
        <HubGeneralInfoContainer data-testid='HubGeneralInfoContent' isLoading={props.isLoading}>
            <Card variant='outlined'>
                <CardContent>
                    <GridCover container xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container item>
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                                    <DeviceInformationTable isLoading={props.isLoading} deviceData={props.hubData} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={12} lg={12} spacing={1}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Autocomplete
                                    options={props?.faultyReasons ? props.faultyReasons() : []}
                                    getOptionLabel={(option) =>
                                        translate(`t.${option.statusName.toLowerCase().replaceAll(' ', '_')}`)
                                    }
                                    multiple
                                    readOnly={!userIsAdmin}
                                    value={props.hubFaultyStatus}
                                    size='small'
                                    filterSelectedOptions
                                    inputValue=''
                                    onChange={props.changeFaultyStatus}
                                    renderInput={(params) => {
                                        return (
                                            <StyledTextField
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                                label={translate('t.hub_faulty_status')}
                                                size='small'
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <StyledTextField
                                    id='hub-status'
                                    multiline
                                    size='small'
                                    rows={3}
                                    label={translate('t.comment')}
                                    value={props.comment}
                                    onChange={props.changeComment}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: !userIsAdmin }}
                                />
                            </Grid>
                        </Grid>
                    </GridCover>
                </CardContent>
                <StyledCardActions>
                    {userIsSuperAdmin && (
                        <>
                            <div>
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={translate('p.change_of_region_in_hub')}
                                    sx={{ mt: 2 }}
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </Tooltip>
                            </div>

                            <Grid item xs={6} md={4} lg={4} xl={3} justifySelf='flex-start'>
                                <Autocomplete
                                    options={props.appRegions || []}
                                    getOptionLabel={(option) => (option?.name ? option?.name.toUpperCase() : '')}
                                    onChange={(_e, newValue) => {
                                        const value = !newValue ? '' : newValue;

                                        props.changeAppRegion(value);
                                    }}
                                    size='small'
                                    disableClearable
                                    defaultValue={props.appRegionCurrent || undefined}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.region')}
                                            variant='outlined'
                                            name='appRegionId'
                                            value={props.appRegionCurrent || ''}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    )}
                    {userIsAdmin && (
                        <ShowSelfDiagnosticsBtn
                            loading={props.patchIsLoading}
                            onClick={() => {
                                setModalState({
                                    isOpen: true,
                                    content: <DeviceDiagnose id={props?.hubData?.id ?? 0} refshowBtn={refshowBtn} />,
                                    width: 600,
                                    height: ThemeState.bigFonts ? 505 : 605,
                                    leftTitle: translate('t.self_diagnostics_result')
                                });
                            }}
                        >
                            {translate('t.show_self_diagnostics_result')}
                        </ShowSelfDiagnosticsBtn>
                    )}

                    <SaveBtn
                        loading={props.patchIsLoading}
                        disabled={props.patchIsLoading}
                        $isVisible={userIsAdmin}
                        onClick={() => {
                            props.patchHub({
                                comment: props.comment,
                                faultyStatus: props.getUpdatedFaultyStatus(props.hubFaultyStatus)
                            });
                            props.appRegionCurrent && props.patchAppRegion(props.appRegionCurrent);
                        }}
                    >
                        {translate('t.save')}
                    </SaveBtn>
                </StyledCardActions>
            </Card>
        </HubGeneralInfoContainer>
    );
};
