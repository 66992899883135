import React from 'react';
import { CustomerReportsContent } from './CustomerReports.view';
import { CustomerReportsProps } from './CustomerReports.type';
import { Wrapper } from 'helpers/wrapper';

const CustomerReports: React.FC<CustomerReportsProps> = (): JSX.Element => {
    return <CustomerReportsContent data-testid={'CustomerReports-testid'} />;
};

export default Wrapper(CustomerReports);
