import styled from 'styled-components';

export const UiColorPickerContent = styled.div``;

export const ContainerColorBox = styled.div<{ color?: string }>`
    width: 40px;
    height: 40px;
    border: thin solid rgb(152, 152, 152);
    border-radius: 10%;
    cursor: pointer;
    background: ${(props) => props.color ?? 'rgba(153, 121, 50, 1)'};
`;
