import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleDetailsSummaryViewContent = styled.div``;

export const DetailsTable = styled.table`
    width: 100%;

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            border-spacing: unset;
            & td{
                border: none;
                padding: 5px 10px !important;
                border-bottom: 1px solid #ddd !important;
                font-size: ${common.textSize};
            }
        `;
    }}
`;

export const DetailsTD = styled.td`
    width: 105px;
`;

export const ConnectedDisplaysTD = styled.td`
    vertical-align: top;
    width: 105px;
`;

export const PassingVehicle = styled.span`
    padding-right: 4px;
`;

export const DisplayTD = styled.td`
    position: relative;
`;
