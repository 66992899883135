import React, { useState, useCallback, useEffect, useMemo } from 'react';
import GraphCore from 'components/Graph/Graph';
import { xDomainConfig } from 'components/Graph/Graph.type';
import { generateYConfig, deepCopyObj } from 'helpers';
import { useRecoilValue } from 'recoil';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useTranslation } from 'react-i18next';
import { GraphDataType, GraphProps } from '../PredictedGraph.type';
import { DateTime } from 'luxon';
import {
    ACTUAL_TEMP_COLOR,
    AMBIENT_TEMP_COLOR,
    PREDICTED_TEMP_COLOR,
    CRITICAL_TEMP_COLOR,
    ACTUAL_TEMP_COLOR_DARK,
    AMBIENT_TEMP_COLOR_DARK
} from '../PredictedGraph.style';
import { Theme, ThemeMode } from 'states/global/Theme';

const CustomizedAxisTick = (props: {
    x: number;
    y: number;
    stroke: string;
    payload: { value: number };
    tickFormatter: any;
}) => {
    const { x, y, payload, tickFormatter } = props;
    const splittedTime = tickFormatter(payload.value).split(' ');
    const theme = useRecoilValue(Theme);

    return (
        <g transform={`translate(${x + 30},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor='end'
                fill={theme.mode === ThemeMode.light ? '#666' : ACTUAL_TEMP_COLOR_DARK}
            >
                {splittedTime[0]}
            </text>
            <text
                x={0}
                y={10}
                dy={16}
                textAnchor='end'
                fill={theme.mode === ThemeMode.light ? '#666' : ACTUAL_TEMP_COLOR_DARK}
            >
                {splittedTime[1]}
            </text>
        </g>
    );
};

const Graph = ({ data, criticalTemperature }: GraphProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone, displayUserUnits, fromServerToUserUnit, convertType } = useConverter();
    const [graphData, setGraphData] = useState<GraphDataType[]>([]);
    const theme = useRecoilValue(Theme);

    const converterCriticalTemperature = Number(
        fromServerToUserUnit({
            type: convertType.temperature,
            value: criticalTemperature,
            fixed: 2
        })
    );

    let xDomainConf: xDomainConfig[] = [
        {
            dataKey: 'actualTemperature',
            yAxisId: '2',
            name: 'Ambient Temperature',
            showDot: false,
            stroke: theme.mode === ThemeMode.light ? AMBIENT_TEMP_COLOR : AMBIENT_TEMP_COLOR_DARK,
            strokeWidth: 1,
            xAxisId: '1'
        },
        {
            dataKey: 'temperature',
            yAxisId: '1',
            name: 'Actual Temperature',
            showDot: false,
            stroke: theme.mode === ThemeMode.light ? ACTUAL_TEMP_COLOR : ACTUAL_TEMP_COLOR_DARK,
            strokeWidth: 2,
            xAxisId: '1'
        },
        {
            dataKey: 'predictedTemperature',
            yAxisId: '1',
            name: 'Predicted Temperature',
            showDot: false,
            stroke: PREDICTED_TEMP_COLOR,
            strokeWidth: 2,
            strokeDasharray: '1 1',
            xAxisId: '1'
        },
        {
            dataKey: 'criticalTemperature',
            yAxisId: 'hiddenData',
            name: 'Critical Temperature (Level 3)',
            showDot: false,
            hide: true,
            stroke: CRITICAL_TEMP_COLOR
        },
        {
            dataKey: 'timeForTooltip',
            yAxisId: 'hiddenData',
            name: 'Date',
            showDot: false,
            hide: true
        }
    ];

    const getScale = useCallback(() => {
        const scaleLeft = [criticalTemperature, criticalTemperature];
        const scaleRight = [criticalTemperature, -100];

        for (let i = 0; i < data.sensorData.length; i++) {
            if (data.sensorData[i].temperature > scaleLeft[1]) scaleLeft[1] = data.sensorData[i].temperature;
            if (data.sensorData[i].temperature < scaleLeft[0]) scaleLeft[0] = data.sensorData[i].temperature;
        }

        for (let i = 0; i < data.predictionData.length; i++) {
            if (data.predictionData[i].predictedTemperature > scaleLeft[1])
                scaleLeft[1] = data.predictionData[i].predictedTemperature;
            if (data.predictionData[i].predictedTemperature < scaleLeft[0])
                scaleLeft[0] = data.predictionData[i].predictedTemperature;
        }

        for (let i = 0; i < data.weatherData.length; i++) {
            if (data.weatherData[i].actualTemperature > scaleRight[1])
                scaleRight[1] = data.weatherData[i].actualTemperature;
            if (data.weatherData[i].actualTemperature < scaleRight[0])
                scaleRight[0] = data.weatherData[i].actualTemperature;
        }

        for (let i = 0; i < data.forecastData.length; i++) {
            if (data.forecastData[i].forecastTemperature > scaleRight[1])
                scaleRight[1] = data.forecastData[i].forecastTemperature;
            if (data.forecastData[i].forecastTemperature < scaleRight[0])
                scaleRight[0] = data.forecastData[i].forecastTemperature;
        }

        //toPrecision prevents javascript conversion
        //todo: needs to be looked at
        return {
            left: [
                Number(
                    Number(
                        fromServerToUserUnit({ type: convertType.temperature, value: scaleLeft[0] - 5, fixed: 2 })
                    ).toPrecision(3)
                ),
                Number(
                    Number(
                        fromServerToUserUnit({ type: convertType.temperature, value: scaleLeft[1], fixed: 2 })
                    ).toPrecision(3)
                )
            ],
            right: [
                Number(
                    Number(
                        fromServerToUserUnit({ type: convertType.temperature, value: scaleRight[0] - 5, fixed: 2 })
                    ).toPrecision(3)
                ),
                Number(
                    Number(
                        fromServerToUserUnit({
                            type: convertType.temperature,
                            value: Math.max(criticalTemperature, scaleRight[1] + 5),
                            fixed: 2
                        })
                    ).toPrecision(3)
                )
            ]
        };
    }, [data, criticalTemperature]);

    const parseData = useCallback((): void => {
        let wheelTemperature: GraphDataType[] = deepCopyObj(data.sensorData);

        //Concat sensorData and predictionData into 1 array.
        for (let i = 0; i < data.predictionData.length; i++) {
            wheelTemperature.push(data.predictionData[i]);
        }

        let weatherTemperature: GraphDataType[] = data.weatherData;

        //Concat forecastData and weatherData into 1 array.
        for (let i = 0; i < data.forecastData.length; i++) {
            weatherTemperature.push({
                timeKey: data.forecastData[i].timeKey,
                actualTemperature: data.forecastData[i].forecastTemperature
            });
        }

        const dictionary: Record<number, GraphDataType> = {};

        //Create dictionary based on wheelTemperature array (key: timeKey)
        for (let i = 0; i < wheelTemperature.length; i++) {
            if (!dictionary[wheelTemperature[i].timeKey]) {
                dictionary[wheelTemperature[i].timeKey] = { timeKey: wheelTemperature[i].timeKey };
            }
            dictionary[wheelTemperature[i].timeKey]['temperature'] = wheelTemperature[i].temperature
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.temperature,
                          value: wheelTemperature[i].temperature,
                          fixed: 2
                      })
                  )
                : undefined;
            dictionary[wheelTemperature[i].timeKey]['predictedTemperature'] = wheelTemperature[i].predictedTemperature
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.temperature,
                          value: wheelTemperature[i].predictedTemperature,
                          fixed: 2
                      })
                  )
                : undefined;
            dictionary[wheelTemperature[i].timeKey]['timeKey'] = wheelTemperature[i].timeKey;
            dictionary[wheelTemperature[i].timeKey]['criticalTemperature'] = criticalTemperature
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.temperature,
                          value: criticalTemperature,
                          fixed: 2
                      })
                  )
                : undefined;
            dictionary[wheelTemperature[i].timeKey]['timeForTooltip'] = fromUTCtoUserTimezone({
                date: DateTime.fromMillis(wheelTemperature[i].timeKey * 1000),
                format: 'dateTime'
            });
        }

        //Add ambient temperature into dictionary based on timeKey
        for (let i = 0; i < weatherTemperature.length; i++) {
            if (!dictionary[weatherTemperature[i].timeKey]) {
                dictionary[weatherTemperature[i].timeKey] = { timeKey: weatherTemperature[i].timeKey };
            }
            dictionary[weatherTemperature[i].timeKey]['actualTemperature'] = Number(
                fromServerToUserUnit({
                    type: convertType.temperature,
                    value: weatherTemperature[i].actualTemperature,
                    fixed: 2
                })
            );
        }

        //Fill missing ambient temperature
        let calculatedAmbient = weatherTemperature.length
            ? Number(
                  fromServerToUserUnit({
                      type: convertType.temperature,
                      value: weatherTemperature[0].actualTemperature,
                      fixed: 2
                  })
              )
            : undefined;

        for (const dictionaryKey in dictionary) {
            if (!dictionary[dictionaryKey]['actualTemperature']) {
                dictionary[dictionaryKey]['actualTemperature'] = calculatedAmbient;
            } else {
                calculatedAmbient = dictionary[dictionaryKey]['actualTemperature'] || 0;
                if (!(dictionary[dictionaryKey]['predictedTemperature'] || dictionary[dictionaryKey]['temperature'])) {
                    delete dictionary[dictionaryKey];
                }
            }
        }

        setGraphData(Object.values(dictionary));
    }, [
        criticalTemperature,
        data.forecastData,
        data.predictionData,
        data.sensorData,
        data.weatherData,
        fromUTCtoUserTimezone
    ]);

    const yConfig = useCallback(() => {
        return generateYConfig(
            [
                {
                    id: '1',
                    name: `${translate('t.temperature')} ${displayUserUnits.temperature}`,
                    isShared: true
                },
                {
                    id: '2',
                    name: `Amb. temp. ${displayUserUnits.temperature}`,
                    isShared: true
                }
            ],
            5,
            getScale()
        );
    }, [displayUserUnits.temperature, translate, getScale]);

    const getXTicks = useMemo((): number[] => {
        const ticks: number[] = [];

        if (graphData.length) {
            const start = graphData[0].timeKey;
            const end = graphData[graphData.length - 1].timeKey;

            let index = graphData.findIndex((a) => !!a.predictedTemperature);

            const currentTime = index > 0 ? graphData[index].timeKey : end;

            let a = Math.ceil((currentTime - start) / 3);
            let b = Math.ceil((end - currentTime) / 2);

            for (let i = 0; i <= 2; i++) {
                ticks.push(start + a * i);
            }
            ticks.push(currentTime);
            ticks.push(currentTime + b);
            ticks.push(end);
        }

        return ticks;
    }, [graphData]);

    useEffect(() => {
        parseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div data-testid='graph-testid'>
            <GraphCore
                xDomainConfig={xDomainConf}
                xTicks={getXTicks}
                yDomainConfig={yConfig()}
                data={graphData}
                referenceLineX={{
                    y: converterCriticalTemperature,
                    label: '',
                    stroke: CRITICAL_TEMP_COLOR,
                    yAxisId: 1
                }}
                referenceLineY={{
                    x: getXTicks[3],
                    label: '',
                    yAxisId: 1,
                    stroke: PREDICTED_TEMP_COLOR
                }}
                resetZoomButton={true}
                showLegend={false}
                dataYPadding={0}
                customAxisTicks={(props2) => (
                    <CustomizedAxisTick
                        {...props2}
                        tickFormatter={(tickItem: number) => {
                            return fromUTCtoUserTimezone({
                                date: tickItem * 1000,
                                format: 'dateTime',
                                displaySeconds: false
                            });
                        }}
                    />
                )}
            />
        </div>
    );
};

export default Graph;
