import React, { useCallback, useMemo } from 'react';
import { AlertSettingEditCheckerContainer } from './AlertSettingEditChecker.style';
import { AlertSettingEditCheckerProps } from './AlertSettingEditChecker.type';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { DefaultTemperatureSliderValue, TemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import { DefaultPressureLevel, PressureIsChanged, PressureLevelsSelector } from '../AlertSettings/atom';
import { AlertLevelEnum } from 'models/Alerts.type';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from '@mui/material';

const AlertSettingEditCheckerContent: React.FC<AlertSettingEditCheckerProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [sliderValue, setSliderValue] = useRecoilState(TemperatureSliderValue);
    const defaultSliderValue = useRecoilValue(DefaultTemperatureSliderValue);
    const pressureIsChanged = useRecoilValue(PressureIsChanged);
    const pressureDefaultValues = useRecoilValue(DefaultPressureLevel);
    const setInitValuesLow3 = useSetRecoilState(PressureLevelsSelector(-3));
    const setInitValuesLow2 = useSetRecoilState(PressureLevelsSelector(-2));
    const setInitValuesLow1 = useSetRecoilState(PressureLevelsSelector(-1));
    const setInitValuesOptimal = useSetRecoilState(PressureLevelsSelector(0));
    const setInitValuesHigh1 = useSetRecoilState(PressureLevelsSelector(1));
    const setInitValuesHigh2 = useSetRecoilState(PressureLevelsSelector(2));
    const setInitValuesHigh3 = useSetRecoilState(PressureLevelsSelector(3));
    const setInitValuesHot1 = useSetRecoilState(PressureLevelsSelector(11));
    const setInitValuesHot2 = useSetRecoilState(PressureLevelsSelector(12));
    const setInitValuesHot3 = useSetRecoilState(PressureLevelsSelector(13));

    const temperatureIsChanged = useMemo(() => {
        return JSON.stringify(sliderValue) !== JSON.stringify(defaultSliderValue);
    }, [sliderValue, defaultSliderValue]);

    const resetTemperatureSlider = () => {
        setSliderValue(defaultSliderValue);
    };

    const adjustPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.ADJUST_VALUE
        };
    }, []);

    const initPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.INIT_VALUES
        };
    }, []);

    const resetPressure = () => {
        setInitValuesOptimal((current) => initPressureValue(current, pressureDefaultValues.optimal));
        setInitValuesLow3((current) => adjustPressureValue(current, pressureDefaultValues.low3));
        setInitValuesLow2((current) => adjustPressureValue(current, pressureDefaultValues.low2));
        setInitValuesLow1((current) => adjustPressureValue(current, pressureDefaultValues.low1));
        setInitValuesHigh3((current) => adjustPressureValue(current, pressureDefaultValues.high3));
        setInitValuesHigh2((current) => adjustPressureValue(current, pressureDefaultValues.high2));
        setInitValuesHigh1((current) => adjustPressureValue(current, pressureDefaultValues.high1));
        setInitValuesHot3((current) => initPressureValue(current, pressureDefaultValues.highHot3));
        setInitValuesHot2((current) => initPressureValue(current, pressureDefaultValues.highHot2));
        setInitValuesHot1((current) => initPressureValue(current, pressureDefaultValues.highHot1));
    };

    const resetAll = () => {
        resetTemperatureSlider();
        resetPressure();
    };

    return (
        <AlertSettingEditCheckerContainer data-testid='AlertSettingEditCheckerContent'>
            <Alert
                severity='warning'
                style={{
                    visibility: temperatureIsChanged || pressureIsChanged ? 'visible' : 'hidden',
                    display: 'flex',
                    alignItems: 'center'
                }}
                action={
                    <Box>
                        {temperatureIsChanged && pressureIsChanged && (
                            <UiButton color='inherit' size='small' onClick={resetAll} variant='outlined'>
                                {translate('t.reset_all')}
                            </UiButton>
                        )}
                        {temperatureIsChanged && (
                            <UiButton color='inherit' size='small' onClick={resetTemperatureSlider} variant='outlined'>
                                {translate('t.reset_temperature')}
                            </UiButton>
                        )}
                        {pressureIsChanged && (
                            <UiButton color='inherit' size='small' onClick={resetPressure} variant='outlined'>
                                {translate('t.reset_pressure')}
                            </UiButton>
                        )}
                    </Box>
                }
            >
                {translate('p.temperature_or_pressure_has_been_changed')}
            </Alert>
        </AlertSettingEditCheckerContainer>
    );
};

export default AlertSettingEditCheckerContent;
