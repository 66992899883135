import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { PatchCustomerUserResponse } from '../../../models/User.type';
import Account from '../../../api/Account';
import { PatchAccountMutationProps } from './Account.type';
import { useSetRecoilState } from 'recoil';
import { User } from '../../../states/global/User';

const accountClient = new Account();

export const PatchAccountMutation = (
    mutationSettings?
): UseMutationResult<PatchCustomerUserResponse, Error, PatchAccountMutationProps> => {
    const setAccountState = useSetRecoilState(User);
    const mutationProperties = useMutation<PatchCustomerUserResponse, Error, PatchAccountMutationProps>(
        ({ data, extendUrl }) => {
            const request: Promise<PatchCustomerUserResponse> = accountClient.patch({
                data: data as Partial<unknown> | undefined,
                extendUrl: (extendUrl as string) || '',
                apiProject: undefined
            });
            request.then((data) => {
                setAccountState((current) => {
                    if (current === null) {
                        return current;
                    }
                    return { ...current, customer: data.user.customer, customerToken: data.user.customerToken };
                });
            });
            return request;
        },
        mutationSettings
    );
    return mutationProperties;
};
