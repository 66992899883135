import styled from 'styled-components';
import { Table, TableHead, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../../../states/global/Theme';
import { BACKGROUND_TABLE } from '../../../../Ui/colors';
import { SIZE_TEXT } from '../../../../Ui/variables';

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    svg {
        margin: auto 2px;
        font-size: 16px;
    }
`;

export const MainDetailsView = styled.div`
    margin-left: 16px;
`;

export const TableCustom = styled(Table)`
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: ${SIZE_TEXT} !important;

    & tbody td:first-child {
        font-weight: bold;
        text-align: center;
    }
    & tbody #default {
        text-align: left !important;
        padding-left: 10px !important;
    }
    & tr td {
        padding: 8px 10px !important;
        ${applyStyleByMode({
            styleJade: `font-size: ${SIZE_TEXT} !important;`
        })}
        vertical-align: middle !important;
        border-left: 1px solid white !important;
    }
`;

export const CustomTableHead = styled(TableHead)`
    & .MuiTableCell-head {
        background-color: ${() => (useTheme().palette.mode === ThemeMode.light ? BACKGROUND_TABLE : 'transparent')};
    }
`;

export const MainDetailsContainer = styled.div`
    font-weight: 600;
    font-weight: bold;
    padding: 0 !important;
    padding-bottom: 15px !important;
    text-align: left;
    th {
        min-width: 210px;
    }
`;
