import { ListItemButton, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';

export const DeviceInformationTableContainer = styled.div``;

export const ListItemCustom = styled(ListItemButton)<{ isActive: boolean }>`
    border-radius: 4px;
    cursor: default !important;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${useTheme().palette.primary.main};`,
            dark: `color: ${useTheme().palette.secondary.main};`
        });
    }}
    ${() => {
        return `
            background: ${useTheme().palette.background.default} !important;
            margin-top: 10px;
        `;
    }}
`;

export const InfoContent = styled.div`
    padding: 10px;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `
                background-color: ${useTheme().palette.background.paper} !important;
            `
        });
    }}
`;

export const DeviceInformationTableLoaderContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
`;
