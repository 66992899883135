import { ExcessiveAccActions, ExcessiveAccState, ExcessiveActionType } from './ExcessiveAcc.type';

export const ExcessiveAccReducer = (state: ExcessiveAccState, action: ExcessiveActionType) => {
    switch (action.type) {
        case ExcessiveAccActions.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            };
        case ExcessiveAccActions.SET_VEHICLES_IDS:
            return {
                ...state,
                vehiclesIds: action.payload
            };
        case ExcessiveAccActions.SET_LOADED_STATUS:
            return {
                ...state,
                loaded: action.payload
            };
        case ExcessiveAccActions.SET_OPERATOR:
            return {
                ...state,
                operator: action.payload
            };
        default:
            return state;
    }
};
