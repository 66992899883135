import React, { useState, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    AdditionalData,
    GraphData,
    GraphDataAdvanced,
    GraphDataBasic,
    StatisticsPressureProps,
    xDomainConfig,
    yDomainConfig
} from './StatisticsPressure.type';
import { useTranslation } from 'react-i18next';
import Graph from '../../Graph/Graph';
import { DEFAULT_SPEED_GRAPH_SCALE, deepCopyObj, generateYConfig } from 'helpers';
import { UserInfo } from '../../../states/global/User';
import { Theme, ThemeMode } from '../../../states/global/Theme';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import SensorLogApi from '../../../api/SensorLog';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { TempPressSensorData } from '../../../models/SensorLog.type';
import { DateTimePicker, StatusDataPressureGraph, Timeline } from '../../../states/component/FaultySensorReporter';

const SensorLog = new SensorLogApi();

const StatisticsPressure: React.FC<StatisticsPressureProps> = ({
    faultySensorReporterState,
    externalTemperatureEnabled,
    allHubsChecked,
    zoomInExternal
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const ThemeModeValue = useRecoilValue(Theme);
    const { fromUTCToTimezone, fromTimezoneToUTC, fromServerToUserUnit, convertType, displayUserUnits } =
        useConverter();
    const timeline = useRecoilValue(Timeline);
    const setStatusDataPressureGraph = useSetRecoilState(StatusDataPressureGraph);
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);
    const lineColorTempPress: string = useMemo(
        () => (ThemeModeValue?.mode === ThemeMode.light ? ' #000' : '#EA2027'),
        [ThemeModeValue]
    );
    const [additionalData, setAdditionalData] = useState<AdditionalData>({
        pressure: [],
        temperature: []
    });

    const externalWithDisabledTemp = !!faultySensorReporterState.isExternal && !externalTemperatureEnabled;

    let xDomainConfig = useMemo(() => {
        let xDomain: xDomainConfig[] = [
            {
                dataKey: 'createdAt',
                stroke: '#000',
                yAxisId: '1',
                name: translate('t.created_at'),
                showDot: false,
                hide: true
            },
            {
                dataKey: 'measuredAt',
                stroke: '#000',
                yAxisId: '1',
                name: translate('t.measured_at'),
                showDot: false,
                hide: true
            },
            {
                dataKey: 'coldPressure',
                stroke: '#100ef9',
                yAxisId: '1',
                name: translate('t.pressure'),
                unit: displayUserUnits.pressure,
                showDot: true,
                hide: !!faultySensorReporterState.isExternal && !faultySensorReporterState.internalOnVehicle
            },
            {
                name: translate('t.hot_pressure'),
                stroke: '#000',
                dataKey: 'hotPressure',
                yAxisId: '1',
                hide: false,
                unit: displayUserUnits.pressure,
                showDot: true
            },
            {
                name: externalWithDisabledTemp ? translate('t.no_temperature_available') : translate('t.temperature'),
                stroke: '#d87d00',
                dataKey: 'temperature',
                yAxisId: '2',
                hide: externalWithDisabledTemp,
                unit: displayUserUnits.temperature,
                showDot: externalWithDisabledTemp,
                hideOnlyLine: externalWithDisabledTemp
            },
            {
                name: 'RSSI',
                stroke: '#00c40a',
                dataKey: 'rssi',
                yAxisId: '2',
                hide: true,
                unit: 'dBm',
                showDot: false
            },
            {
                name: translate('t.battery_voltage'),
                stroke: '#009ba6',
                dataKey: 'batteryVoltage',
                yAxisId: '2',
                hide: true,
                unit: 'V',
                showDot: false
            },
            {
                name: translate('t.ambient_pressure'),
                stroke: '#a60095',
                dataKey: 'ambientPressure',
                yAxisId: '2',
                hide: true,
                unit: displayUserUnits.pressure,
                showDot: false
            },
            {
                name: translate('t.absolute_pressure'),
                stroke: '#f57542',
                dataKey: 'absolutePressure',
                yAxisId: '2',
                hide: true,
                unit: displayUserUnits.pressure,
                showDot: false
            },
            {
                name: externalWithDisabledTemp
                    ? translate('t.no_sensor_temperature_available')
                    : translate('t.sensor_temperature'),
                stroke: '#a60000',
                dataKey: 'sensorTemperature',
                yAxisId: '2',
                hide: true,
                unit: displayUserUnits.temperature,
                showDot: false,
                hideOnlyLine: externalWithDisabledTemp
            },
            {
                name: translate('t.current_hub'),
                stroke: '#32a887',
                dataKey: 'deviceId',
                yAxisId: '2',
                hide: true,
                showDot: false
            },
            {
                name: translate('t.current_vehicle'),
                stroke: '#f542c2',
                dataKey: 'vehicleName',
                yAxisId: '2',
                hide: true,
                showDot: false
            },
            {
                name: translate('t.received_from_hub'),
                stroke: '#8000c9',
                dataKey: 'deviceIdData',
                yAxisId: '2',
                hide: true,
                showDot: false
            },
            {
                name: translate('t.received_from_vehicle'),
                stroke: '#00c907',
                dataKey: 'vehicleNameData',
                yAxisId: '2',
                hide: true,
                showDot: false
            }
        ];

        if (additionalData) {
            if (!additionalData.statusProperties?.temperatureStatus) {
                xDomain.splice(4, 1);
            }
            if (!additionalData.statusProperties?.pressureStatus) {
                xDomain.splice(2, 2);
            }
        }

        if (checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])) {
            xDomain.push(
                {
                    dataKey: 'id',
                    stroke: lineColorTempPress,
                    yAxisId: 'hiddenData',
                    name: translate('t.log'),
                    showDot: false,
                    hide: true
                },
                {
                    dataKey: 'wheelId',
                    stroke: lineColorTempPress,
                    yAxisId: 'hiddenData',
                    name: `${translate('t.wheel_id')}`,
                    showDot: false,
                    hide: true
                },
                {
                    dataKey: 'vehicleId',
                    stroke: lineColorTempPress,
                    yAxisId: 'hiddenData',
                    name: `${translate('t.vehicle_id')}`,
                    showDot: false,
                    hide: true
                }
            );
        }

        return xDomain;
    }, [externalTemperatureEnabled, faultySensorReporterState, lineColorTempPress, userInfo, additionalData]);

    const yDomainConfig: yDomainConfig[] = useMemo(
        () => [
            {
                id: '1',
                name: translate('t.pressure_with_unit', { unit: displayUserUnits.pressure })
            },
            {
                id: '2',
                name:
                    additionalData.temperature[0] === 10000000
                        ? translate('t.pressure_with_unit', { unit: displayUserUnits.pressure })
                        : translate('t.temperature_with_unit', { unit: displayUserUnits.temperature })
            }
        ],

        [displayUserUnits, additionalData]
    );

    const xDomainConfigDiff = xDomainConfig;
    const yDomainDiff = generateYConfig(yDomainConfig, DEFAULT_SPEED_GRAPH_SCALE, {
        left: [additionalData.pressure[0] - 5, additionalData.pressure[1]],
        right:
            additionalData.temperature[0] === 10000000
                ? [additionalData.pressure[0] - 5, additionalData.pressure[1]]
                : [additionalData.temperature[0] - 5, additionalData.temperature[1]]
    });

    const parseDataPressure = (data: TempPressSensorData[]): GraphData[] => {
        let scalePressure = [0, 0];
        let scaleTemperature = [0, 0];
        let copyTimeline = deepCopyObj(timeline.timeline);
        const externalWithDisabledTemp = !!faultySensorReporterState.isExternal && !externalTemperatureEnabled;

        data.forEach((d) => {
            const basicData: GraphDataBasic = {
                timeKey: d.timeKey,
                timestamp: fromUTCToTimezone(d.measuredAt),
                name: fromUTCToTimezone(d.measuredAt),
                coldPressure: Number(
                    fromServerToUserUnit({
                        type: convertType.pressure,
                        value: d.pressure.coldPressure,
                        displayUnits: false,
                        fixed: 1,
                        displayIfEmpty: '----'
                    })
                ),
                hotPressure: Number(
                    fromServerToUserUnit({
                        type: convertType.pressure,
                        value: d.pressure.hotPressure,
                        displayUnits: false,
                        fixed: 1,
                        displayIfEmpty: '----'
                    })
                ),
                temperature: fromServerToUserUnit({
                    type: convertType.temperature,
                    value: externalWithDisabledTemp ? null : d.temperature.temperature,
                    displayUnits: false,
                    fixed: 1,
                    displayIfEmpty: externalWithDisabledTemp ? '-' : '----'
                })
            };

            let advancedData: GraphDataAdvanced | {} = {};

            if (checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])) {
                advancedData = {
                    rssi: d.rssi,
                    batteryVoltage: d.batteryVoltage,
                    ambientPressure: Number(
                        fromServerToUserUnit({
                            type: convertType.pressure,
                            value: d.pressure.ambientPressure,
                            displayUnits: false,
                            fixed: 1,
                            displayIfEmpty: '----'
                        })
                    ),
                    sensorTemperature: fromServerToUserUnit({
                        type: convertType.temperature,
                        value: externalWithDisabledTemp ? null : d.temperature.sensorTemperature,
                        displayUnits: false,
                        fixed: 1,
                        displayIfEmpty: externalWithDisabledTemp ? '-' : '----'
                    }),
                    deviceId: d.device.id,
                    vehicleName: d.vehicle.name,
                    deviceIdData: d.device.readFromDeviceId,
                    vehicleNameData: d.vehicle.readFromVehicleName,
                    createdAt: fromUTCToTimezone(d.createdAt),
                    absolutePressure: Number(
                        fromServerToUserUnit({
                            type: convertType.pressure,
                            value: d.pressure.absolutePressure,
                            displayUnits: false,
                            fixed: 1,
                            displayIfEmpty: '----'
                        })
                    ),
                    measuredAt: fromUTCToTimezone(d.measuredAt)
                };
            }

            const prepareData = Object.assign({}, basicData, advancedData);

            if (prepareData.coldPressure && prepareData.coldPressure < scalePressure[0]) {
                scalePressure[0] = Number(prepareData.coldPressure);
            }
            if (prepareData.hotPressure && prepareData.hotPressure > scalePressure[1]) {
                scalePressure[1] = Number(prepareData.hotPressure);
            }

            if (
                prepareData.temperature &&
                prepareData.temperature !== '-' &&
                +prepareData.temperature < scaleTemperature[0]
            ) {
                scaleTemperature[0] = Number(prepareData.temperature);
            }
            if (
                prepareData.temperature &&
                prepareData.temperature !== '-' &&
                +prepareData.temperature > scaleTemperature[1]
            ) {
                scaleTemperature[1] = Number(prepareData.temperature);
            }

            if (prepareData.timeKey.toString() in copyTimeline) {
                copyTimeline[prepareData.timeKey] = prepareData;
            }
        });

        if (data.length) {
            setAdditionalData({
                pressure: scalePressure,
                temperature: scaleTemperature,
                statusProperties: {
                    temperatureStatus: Boolean(data[0].temperature.temperatureStatus),
                    pressureStatus: Boolean(data[0].pressure.pressureStatus)
                }
            });
        }

        return data.length ? (Object.values(copyTimeline) as GraphData[]) : [];
    };

    const getPressure = async (dateFrom: DateTime, dateTo: DateTime): Promise<GraphData[]> => {
        setStatusDataPressureGraph({
            loading: true,
            dataAvailable: false
        });
        const dataXHR = await SensorLog.getTempPressureSensor(
            faultySensorReporterState.sensorId,
            allHubsChecked,
            fromTimezoneToUTC(dateFrom.valueOf()),
            fromTimezoneToUTC(dateTo.valueOf()),
            timeline.granularity
        );

        const pressureData = await parseDataPressure(dataXHR.tempPressSensor);
        setStatusDataPressureGraph({
            loading: false,
            dataAvailable: Boolean(pressureData.length)
        });
        return pressureData;
    };

    return (
        <Graph
            xDomainConfig={xDomainConfigDiff}
            yDomainConfig={yDomainDiff}
            globalXDomain={globalDateTimePicker}
            timeline={timeline}
            testId={'Pressure-Graph'}
            setGlobalXDomain={setGlobalDateTimePicker}
            zoomInExternal={zoomInExternal}
            tooltipSettings={{
                zIndex: 9999
            }}
            excludeFromTooltip={['optimalPressure']}
            excludeFromLegend={['optimalPressure']}
            fetchData={({ dateFrom, dateTo }): Promise<GraphData[]> => {
                return getPressure(dateFrom, dateTo);
            }}
            xTickFormatter={(tickItem: number) => {
                return fromUTCToTimezone(tickItem * 1000, false);
            }}
        />
    );
};

export default StatisticsPressure;
