import styled from 'styled-components';
import { useTheme } from '@mui/material';

export const AddAlertNotificationManagmentContainer = styled.div`
    padding: 15px 10px;
`;

export const UsersListContainer = styled.div`
    border-radius: 3px;
    margin-top: 10px;
    ${() => `background: ${useTheme().palette.background.default};`}
`;

export const PreviewUsers = styled.div`
    margin-top: 80px;
`;
