import { DateRangeData } from '../../Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

export enum ExcessiveAccActions {
    SET_DATE_RANGE = 'SET_DATE_RANGE',
    SET_VEHICLES_IDS = 'SET_VEHICLES_IDS',
    SET_LOADED_STATUS = 'SET_LOADED_STATUS',
    SET_OPERATOR = 'SET_OPERATOR'
}

export type ExcessiveAccState = {
    dateRange: DateRangeData | null;
    vehiclesIds: number[] | null;
    loaded: number;
    operator: number | null;
};

type DateRangeActionType = {
    type: ExcessiveAccActions.SET_DATE_RANGE;
    payload: DateRangeData | null;
};

type VehiclesIdsActionType = {
    type: ExcessiveAccActions.SET_VEHICLES_IDS;
    payload: number[] | null;
};

type LoadedStatusActionType = {
    type: ExcessiveAccActions.SET_LOADED_STATUS;
    payload: number;
};

type OperatorStatusActionType = {
    type: ExcessiveAccActions.SET_OPERATOR;
    payload: number | null;
};

export type ExcessiveActionType =
    | DateRangeActionType
    | VehiclesIdsActionType
    | LoadedStatusActionType
    | OperatorStatusActionType;
