import { BACKGROUND_PAPER_DARK_MODE } from 'components/Ui/colors';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';

export const FooterContainer = styled.div`
    top: 100vh;
    position: sticky;
`;

export const FooterBody = styled.div`
    padding: 10px 10px 5px 10px;

    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            dark: `background-color: ${BACKGROUND_PAPER_DARK_MODE}`,
            light: 'background-color: #e3e3e3'
        });
    }}
`;

export const ContainerContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FooterContentRight = styled.div``;

export const FooterContentLeft = styled.div``;
