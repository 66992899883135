import React from 'react';
import { HubsUpdaterContent } from './HubsUpdater.view';
import { HubsUpdaterProps } from './HubsUpdater.type';
import { Wrapper } from 'helpers/wrapper';

const HubsUpdater: React.FC<HubsUpdaterProps> = (): JSX.Element => {
    return <HubsUpdaterContent data-testid={'HubsUpdater-testid'} />;
};

export default Wrapper(HubsUpdater);
