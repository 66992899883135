import React from 'react';
import { SpeedHeatmapProps } from './SpeedHeatmap.type';
import { SpeedHeatmapContent } from './SpeedHeatmap.view';
import { Wrapper } from 'helpers/wrapper';

const SpeedHeatmap: React.FC<SpeedHeatmapProps> = (): JSX.Element => {
    return <SpeedHeatmapContent data-testid='SpeedHeatmap-testid' />;
};

export default Wrapper(SpeedHeatmap);
