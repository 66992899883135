import { atom } from 'recoil';

export enum ThemeMode {
    light = 'light',
    dark = 'dark',
    old = 'old'
}

export type ThemeType = {
    easyReadingMode: boolean;
    bigFonts: boolean;
    mode: ThemeMode.light | ThemeMode.dark | ThemeMode.old;
};

export const ThemeLocal: ThemeType = JSON.parse(
    localStorage.getItem('theme') ??
        JSON.stringify({
            easyReadingMode: false,
            bigFonts: false,
            mode: ThemeMode.light
        })
);

export const Theme = atom<ThemeType>({
    key: 'Theme',
    default: ThemeLocal
});
