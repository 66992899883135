import React, { useMemo, useState } from 'react';
import { SensorTableAroundContent } from './SensorTableAround.view';
import { SensorTableAroundProps } from './SensorTableAround.type';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Column } from '../../Ui/Components/UiSimpleTable/UiSimpleTable.type';
import { CustomCell } from './SensorTableAround.style';
import Checkbox from '../../Ui/Components/UiCheckbox';
import { SensorAroundType } from '../../../models/SensorLog.type';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { ROLES } from 'variables';
import { Wrapper } from 'helpers/wrapper';

const SensorTableAround: React.FC<SensorTableAroundProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const isAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const initialColumns: Column[] = useMemo(() => {
        let columnsResponse = [
            {
                header: `${translate('t.serial_number')} ${isAdmin ? '(DEC)' : ''}`,
                accessor: isAdmin ? 'sensorSerialNumber' : 'serialNumberHex',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={Boolean(props.ownSensor)}>
                        {isAdmin ? props.sensorSerialNumber : props.serialNumberHex}
                    </CustomCell>
                ),
                hidden: isAdmin,
                minWidth: 200
            },
            {
                header: translate('t.vehicle_id'),
                accessor: 'vehicleId',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={props ? Boolean(props.ownSensor) : false}>
                        {props.vehicleId || '-'}
                    </CustomCell>
                ),
                hidden: true
            },
            {
                header: translate('t.vehicle'),
                accessor: 'vehicleName',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={props ? Boolean(props.ownSensor) : false}>
                        {props.vehicleName || '-'}
                    </CustomCell>
                ),
                hidden: false
            },
            {
                header: translate('t.transmissions'),
                accessor: 'count',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={Boolean(props.ownSensor)}>{props.count}</CustomCell>
                ),
                hidden: false
            },
            {
                header: translate('t.battery'),
                accessor: 'batteryVoltage',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={Boolean(props.ownSensor)}>{props.batteryVoltage}</CustomCell>
                ),
                hidden: false
            },
            {
                header: 'RSSI',
                accessor: 'rssi',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={Boolean(props.ownSensor)}>{props.rssi}</CustomCell>
                ),
                hidden: false
            },
            {
                header: translate('t.last_transmission'),
                accessor: 'lastTransmission',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={props ? Boolean(props.ownSensor) : false}>
                        {fromUTCtoUserTimezone({ date: props.lastTransmission, format: 'dateTime' }) ?? '-'}
                    </CustomCell>
                ),
                hidden: true
            },
            {
                header: 'Attached',
                accessor: 'ownSensor',
                hidden: false,
                parseData: (props: SensorAroundType) => {
                    return (
                        <CustomCell $attached={Boolean(props.ownSensor)}>
                            <Checkbox name='attached' checked={Boolean(props.ownSensor)} disabled size='small' />
                        </CustomCell>
                    );
                }
            }
        ];

        if (isAdmin) {
            columnsResponse.splice(1, 0, {
                header: translate('t.serial_number'),
                accessor: 'serialNumberHex',
                parseData: (props: SensorAroundType) => (
                    <CustomCell $attached={Boolean(props.ownSensor)}>{props.serialNumberHex}</CustomCell>
                ),
                hidden: false
            });
        }
        return columnsResponse;
    }, [isAdmin]);
    const [columns, setColumns] = useState<Column[]>(initialColumns);

    const toggleHideColumn = (index: number) => {
        let data = [...columns];
        data[index].hidden = !data[index].hidden;
        setColumns(data);
    };

    return (
        <SensorTableAroundContent
            data-testid='SensorTableAround-testid'
            deviceId={props.deviceId}
            columns={columns}
            toggleHideColumn={toggleHideColumn}
            transmissionFiltering={props.transmissionFiltering}
        />
    );
};

export default Wrapper(SensorTableAround);
