import React, { useCallback, useEffect } from 'react';
import { RankDateRangePickersContent } from './RankDateRangePickers.view';
import { DateTime } from 'luxon';
import { RankDateRangePickersProps } from './RankDateRangePickers.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TopBarRankDateTimePicker } from './RankDateRangePickers.atom';
import { UserInfo } from 'states/global/User';
import { Settings } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const RankDateRangePickers: React.FC<RankDateRangePickersProps> = (): JSX.Element => {
    const [dateRange, setDateRange] = useRecoilState(TopBarRankDateTimePicker);
    const userInfo = useRecoilValue(UserInfo);

    const setDateTimeRange = useCallback(
        (dateRange) => {
            dateRange && setDateRange({ dateFrom: dateRange[0], dateTo: dateRange[1] });
        },
        [dateRange]
    );

    useEffect(() => {
        setDateRange({ dateFrom: DateTime.local().minus({ days: 30 }), dateTo: DateTime.local() });
    }, []);

    useEffect(() => {
        Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
    }, [userInfo.user?.userSetting.timezone.timezoneName]);

    return <RankDateRangePickersContent dateRange={dateRange} setDateTimeRange={setDateTimeRange} />;
};

export default Wrapper(RankDateRangePickers);
