import { TableCell, TableHead, styled } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const TableHeadEl = styled(TableHead)({
    marginTop: '10px'
});

export const HeaderCell = styled('span')`
    ${applyStyleByMode({
        styleOld: `
            font-size: 1em !important;
            line-height: 21px;
            font-weight: 600 !important;
        `
    })}
}`;

export const ActionHederText = styled('span')({
    display: 'flex',
    justifyContent: 'center'
});

export const TableCellEl = styled(TableCell)({
    fontSize: 'inherit !important'
});
