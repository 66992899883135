import styled from 'styled-components';

export const AreaEditorViewContent = styled.div``;

export const AreaEditorMap = styled.div`
    height: 420px;
`;

export const IconLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const TdTitle = styled.td`
    padding: 5px 10px 10px 0px;
`;

export const TdBody = styled.td`
    padding: 5px 10px 10px 0px;
`;
