import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Tooltip } from '@mui/material';
import { AlertConnectionProps } from './AlertConnection.type';
import { useTranslation } from 'react-i18next';
import { getFromLocalStorage, setToLocalStorage } from 'helpers';
import { useQuery } from '@tanstack/react-query';
import { SystemNotificationTableModel } from 'models/SystemNotification.type';
import SystemNotification from 'api/SystemNotification';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';
import UiLink from 'components/Ui/Components/UiLink/UiLink';

const systemNotificationAPI = new SystemNotification();

const AlertConnectionContent: React.FC<AlertConnectionProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    useQuery<boolean, Error, SystemNotificationTableModel>(
        ['check-connection-query'],
        () =>
            systemNotificationAPI.getByCriteria({
                criteria: {
                    limit: 1,
                    customerId: userInfo.user?.customer.id as number
                }
            }),
        {
            refetchInterval: 8000,
            retry: false,
            onSuccess: () => !getFromLocalStorage<boolean>('isOnline') && setToLocalStorage<boolean>('isOnline', true),
            onError: (error) => error.message === 'Network Error' && setToLocalStorage<boolean>('isOnline', false)
        }
    );
    const alertNetworkConnection: JSX.Element = useMemo(
        () => (
            <>
                {getFromLocalStorage<boolean>('isOnline') === false && (
                    <Alert
                        variant='filled'
                        severity='error'
                        sx={{ marginBottom: '15px' }}
                        action={
                            <Tooltip title={translate('t.context_help')} aria-label='PagaHelpButton' arrow>
                                <UiLink
                                    $padding='0'
                                    content={
                                        <Button color='inherit' size='small' sx={{ color: '#fff' }}>
                                            ?
                                        </Button>
                                    }
                                    url={`https://help.atmstechnology.com/en_US/get-started/dashboard`}
                                    openNewTab={true}
                                    relationshipAttribute='noreferrer'
                                />
                            </Tooltip>
                        }
                    >
                        {translate('t.no_internet_connection', {
                            portal: 'WebTrack'
                        })}
                    </Alert>
                )}
            </>
        ),
        [getFromLocalStorage<boolean>('isOnline')]
    );

    useEffect(() => {
        setToLocalStorage<boolean>('isOnline', true);
    }, []);

    return <>{alertNetworkConnection}</>;
};

export default AlertConnectionContent;
