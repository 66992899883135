import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Role } from 'states/global/User';
import { loggedIn } from 'helpers/authentication/index';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { MenuOption } from 'components/MainMenu/MenuOption.type';

type RequiredAuthElementProps = {
    children: JSX.Element;
    requiredRoles?: string[];
} & Pick<MenuOption, 'bes' | 'besDrivingAssistance' | 'tableActivation' | 'temperaturePrediction'>;

type ExtendedRouteProps = RequiredAuthElementProps;

export const RequiresPermissions = ({
    children,
    requiredRoles,
    bes,
    besDrivingAssistance,
    tableActivation,
    temperaturePrediction
}: ExtendedRouteProps) => {
    const userRole: string = useRecoilValue(Role);
    const hasRequiredRole: boolean = requiredRoles?.length ? requiredRoles?.includes(userRole) : true;
    const customerSettings = useRecoilValue(CustomerSettings);

    if (
        !hasRequiredRole ||
        (customerSettings.ready_to_use_data &&
            ((bes && !customerSettings.mdd_bes) ||
                (bes &&
                    besDrivingAssistance &&
                    !(customerSettings.mdd_bes && customerSettings.mdd_bes_driving_assistance)) ||
                (tableActivation && !customerSettings.tablet_activation) ||
                (temperaturePrediction && !customerSettings.temperature_prediction)))
    ) {
        return <Navigate to='/no-permission' />;
    }

    return children;
};

export const RequiresAuth = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = loggedIn();

    if (!isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return children;
};
