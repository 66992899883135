import React from 'react';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { UiTableFilterColumnsBtnProps } from './UiTableFilterColumnsBtn.type';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilState } from 'recoil';
import { FilterBtnStates } from '../UiTable/TableAtom';

const UiTableFilterColumnsBtn: React.FC<UiTableFilterColumnsBtnProps> = (props): JSX.Element => {
    const [filterBtnState, setFilterBtnState] = useRecoilState(FilterBtnStates);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setFilterBtnState({
            open: !filterBtnState.open,
            anchorEl: event.currentTarget
        });
    };

    return (
        <IconButton data-testid={`FilterColumns-${props.queryKey}Table-Button`} size={'small'} onClick={handleClick}>
            <FilterListIcon />
        </IconButton>
    );
};

export default Wrapper(UiTableFilterColumnsBtn);
