import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const TemperatureNotificationsContainer = styled.div`
    ${applyStyleByMode({
        styleOld: `
            && .MuiCardContent-root {
                padding: 0;
            }
        `
    })}
`;
