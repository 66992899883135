import { AlertsSettingsFormik } from './AlertsSettingsForm.formik';
import React from 'react';
import * as style from './AlertsSettingsForm.style';
import * as type from './AlertsSettingsForm.type';
import { useTranslation } from 'react-i18next';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';

export const AlertsSettingsFormContent: React.FC<type.AlertsSettingsFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.AlertsSettingsFormContent data-testid='AlertsSettingsFormContent'>
            <ContainerBodyTab>
                <TitleContentTab>{translate('t.alert_reminder')}</TitleContentTab>
                <style.ContainerFormAlertsSettings>
                    <AlertsSettingsFormik
                        inicialvaluesFormik={props.inicialvaluesFormik}
                        unresolvedAlertReminderLevelList={props.unresolvedAlertReminderLevelList}
                        notificationPeriodList={props.notificationPeriodList}
                        handleSubmit={props.handleSubmit}
                        userEmail={props.userEmail}
                    />
                </style.ContainerFormAlertsSettings>
            </ContainerBodyTab>
        </style.AlertsSettingsFormContent>
    );
};
