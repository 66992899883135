import styled from 'styled-components';
import Button from '../../Ui/Components/UiButton/UiButton';
import { Button as ButtonMaterialUI } from '@mui/material';
import { PRIMARY } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';

export const UpdateDropDownViewContent = styled.div``;

export const ButtonLock = styled(ButtonMaterialUI)<{ $colorText?: string }>`
    text-decoration: underline !important;
    text-transform: capitalize !important;
    color: ${(props) => (props.$colorText ? props.$colorText : PRIMARY)} !important;
`;

export const ButtonDone = styled(Button)`
    margin: 0px 0px 0px 10px !important;
    min-width: 45px !important;
    ${applyStyleByMode({
        styleOld: 'height: 37px !important;',
        styleJade: 'height: 34px !important;'
    })}
`;

export const ContainerUpdateDropDown = styled.div`
    padding: 5px;
`;
