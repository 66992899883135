import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import Popover from '@mui/material/Popover';
import { PRIMARY_LIGHT, WHITE } from '../../Ui/colors';
import { Collapse, MenuItem, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { SIZE_TEXT_VARIANT } from '../../Ui/variables';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const CustomerSelectorContainer = styled.div`
    .popper-customer-selector {
        z-index: 9999;
        background: #fff;
        max-height: 890px;
    }
`;

export const CollapseTable = styled(Collapse)`
    & .MuiCollapse-wrapperInner {
        height: auto !important;
        background: ${() => useTheme().palette.background.default};
    }
`;

export const CustomText = styled(Typography)`
    font-weight: 400;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
    padding: 8px;
    text-align: center;
`;

export const RegionMenuItem = styled(MenuItem)`
    display: block;
    width: 300px;
    font-weight: 300;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.titleSize};`;
    }}
    padding: 3px 0px 3px 8px;
    background: transparent;
    margin: auto;

    & svg {
        font-size: ${SIZE_TEXT_VARIANT(2)};
    }

    span {
        float: right;
        background-color: transparent;
        border: none;
        padding-right: 12px;
    }
`;

export const LocationMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        position: relative;
        display: block;
        max-width: 300px;
        margin: auto;
        margin-top: 0px;
        cursor: pointer;
        padding: 2px 10px;
        border-bottom: 1px solid ${WHITE};
        text-align: left;
        border-radius: 0px;
    }
`;

export const SearchMenuItem = styled(MenuItem)`
    margin: 10px 0 0 0;
    &.MuiMenuItem-root {
        font-weight: 300;
    }
    & .MuiTextField-root {
        width: 100%;
    }
    &.MuiMenuItem-root:hover,
    &.MuiMenuItem-root:focus,
    &.Mui-focusVisible {
        background: transparent;
    }
`;

export const SearchTextField = styled(TextField)``;

export const LoadingContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

export const CustomerSelectorPopover = styled(Popover)`
    .popper-customer-selector {
        z-index: 9999;
        background: #fff;
        max-height: 92vh;
    }

    .MuiPopover-paper {
        min-height: 280px;
        max-height: 90vh;
        padding: 0px 10px 10px 10px;
        overflow: hidden;
    }

    .MuiAutocomplete-popperDisablePortal {
        position: relative;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }
`;

export const CustomersAutocomplete = styled(Autocomplete)`
    &&.MuiAutocomplete {
        background: #fdfdfd;
        box-shadow: 0px 10px 20px #000000ab;

        &.MuiAutocomplete-listbox {
            padding: 0px;
            background: ${PRIMARY_LIGHT};
        }

        &.MuiAutocomplete-option {
            border-bottom: 1px solid #80808012;
        }
    }
`;
