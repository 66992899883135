import React, { useEffect, useState } from 'react';
import { HeatmapTopbarContainer } from './HeatmapTopbar.style';
import VehicleMultiselect from '../Ui/Components/UiVehicleMultiselect';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Button from '../Ui/Components/UiButton';
import { MapAction, MapActionData } from 'states/global/Map';
import { HeatmapKeys, MapActionsEnum } from '../Map/Map.type';
import { DateRangeData } from '../Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import HeatmapPlaygroundCompnent from '../HeatmapPlayground/HeatmapPlayground';
import { HeatmapTopbarProps } from './HeatmapTopbar.type';
import { LoadingMapdata } from 'states/global/Map';
import {
    HeatmapPlaygroundAtom,
    HeatmapSettings as HeatmapSettinsSelector
} from '../HeatmapPlayground/HeatmapPlayground.state';
import { PlaygroundObject } from '../HeatmapPlayground/HeatmapPlayground.type';
import { DataLoaderProgress } from '../MapDataLoader/MapDataLoader.style';
import { DateTime } from 'luxon';
import { UserInfo } from 'states/global/User';
import { Settings } from 'luxon';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { Wrapper } from 'helpers/wrapper';

const HeatmapTopbar: React.FC<HeatmapTopbarProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setMapAction = useSetRecoilState(MapAction);
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const [vehicles, setVehicles] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<DateRangeData>({
        dateFrom: DateTime.local().minus({ days: 7 }),
        dateTo: DateTime.local(),
        isValidRange: true
    });
    const playground = useRecoilValue<PlaygroundObject>(HeatmapPlaygroundAtom);
    const heatmapSettingsState = useRecoilValue(HeatmapSettinsSelector);
    const heatmapSettings = heatmapSettingsState();
    const loadingMapData = useRecoilValue(LoadingMapdata);
    const userInfo = useRecoilValue(UserInfo);

    const zoom = Math.max(mapActionData.zoomLevel || 14, 14);

    useEffect(() => {
        Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        setDateRange({
            dateFrom: DateTime.local().minus({ days: 7 }),
            dateTo: DateTime.local(),
            isValidRange: true
        });
    }, [userInfo.user?.userSetting.timezone.timezoneName]);

    return (
        <div>
            {props.showPlayground && <HeatmapPlaygroundCompnent />}
            <HeatmapTopbarContainer data-testid='HeatmapTopbar-testid'>
                {userInfo.user?.userSetting.timezone === Settings.defaultZone.zoneName && (
                    <UiDateRangePicker2
                        adjustToPeriod={{ period: 7, periodType: PeriodTypeEnum.DAYS }}
                        dateFrom={dateRange?.dateFrom}
                        dateTo={dateRange?.dateTo}
                        dateFromLabel={translate('t.date_from')}
                        dateToLabel={translate('t.date_to')}
                        onDateChange={(dateRange) => {
                            if (dateRange)
                                setDateRange({
                                    dateFrom: dateRange[0],
                                    dateTo: dateRange[1]
                                });
                        }}
                    />
                )}
                <VehicleMultiselect
                    onSelectedVehiclesChange={(selectedVehicles) => {
                        setVehicles(selectedVehicles.map((vehicle) => vehicle.id));
                    }}
                />
                <Button
                    skin='success'
                    disabled={
                        loadingMapData ||
                        (typeof dateRange?.isValidRange === 'undefined' ? false : !dateRange?.isValidRange)
                    }
                    onClick={() => {
                        let playgroundSettings = {
                            dotSize: !props.showPlayground
                                ? heatmapSettings[zoom].dotSize
                                : !playground[zoom].disableDotSize
                                ? playground[zoom].dotSize
                                : 0,
                            innerRadius: !props.showPlayground
                                ? heatmapSettings[zoom].innerRadius
                                : playground[zoom].innerRadius,
                            outerRadius: !props.showPlayground
                                ? heatmapSettings[zoom].outerRadius
                                : playground[zoom].outerRadius,
                            limit: !props.showPlayground ? heatmapSettings[zoom].data : playground[zoom].data,
                            innerOpacity: !props.showPlayground
                                ? heatmapSettings[zoom].innerOpacity
                                : playground[zoom].innerOpacity,
                            outerOpacity: !props.showPlayground
                                ? heatmapSettings[zoom].outerOpacity
                                : playground[zoom].outerOpacity,
                            enableBlur: !props.showPlayground
                                ? !heatmapSettings[zoom].disableBlur
                                : !playground[zoom].disableBlur
                        };

                        setMapActionData((currentMapActionData) => {
                            return {
                                ...currentMapActionData,
                                ...{
                                    selectedVehicles: vehicles,
                                    heatmapDateRange: dateRange,
                                    ...playgroundSettings
                                },
                                heatmapKey: HeatmapKeys.SPEED
                            };
                        });
                        setMapAction({
                            action: MapActionsEnum.DRAW_HEATMAP
                        });
                    }}
                >
                    {loadingMapData ? <DataLoaderProgress /> : translate('t.apply')}
                </Button>
            </HeatmapTopbarContainer>
        </div>
    );
};

export default Wrapper(HeatmapTopbar);
