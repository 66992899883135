import React from 'react';
import { AreaEditorViewContent } from './AreaEditor.style';
import AreaEditor from '../../components/Tracking/AreaEditor/AreaEditor';
import { Grid } from '@mui/material';

export const AreaEditorContent: React.FC = (): JSX.Element => {
    return (
        <AreaEditorViewContent data-testid='AreaEditorContent'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <AreaEditor data-testid={'AreaEditorContent-AreaEditor'} />
                </Grid>
            </Grid>
        </AreaEditorViewContent>
    );
};
