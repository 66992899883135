import React from 'react';
import { useRecoilValue } from 'recoil';
import { AdminActionViewContent } from './AdminAction.style';
import { AdminActionViewProps } from './AdminAction.type';
import { useTranslation } from 'react-i18next';
import UiTable from '../../Ui/Components/UiTable2';
import Widget from '../../Ui/Components/UiWidget';
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import LoggerRequest from '../../../api/LoggerRequest';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

const userActionApi = new LoggerRequest();

export const AdminActionContent: React.FC<AdminActionViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const hidden: string[] = ['createdAtTo', 'createdAtFrom'];
    const ThemeMode = useRecoilValue(Theme);

    return (
        <AdminActionViewContent data-testid='AdminActionContent'>
            <Widget
                data-testid='AdminActionTableContent'
                avatar={
                    <UiIcon
                        icon={faLightbulb}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.user_action')}
                loading={false}
                content={
                    <UiTable
                        columns={props.columns}
                        fetchFn={userActionApi.getUserActionsTable}
                        defaultSortBy={{
                            id: 'id',
                            desc: true
                        }}
                        hiddenColumns={hidden}
                        refresh={false}
                        queryKey={'userAction'}
                    />
                }
            ></Widget>
        </AdminActionViewContent>
    );
};
