import React from 'react';
import { Tyre } from '../Tyre';
import { FrontAxelType } from '../VehicleSchema.type';

export const Loader22FrontAxel: React.FC<FrontAxelType> = ({
    width,
    height,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    highlightWheels,
    highlightColor
}) => {
    return (
        <svg id='front_axel'>
            <g transform='rotate(15) translate(10, 0)'>
                <svg viewBox={`0 0 ${width} ${height}`} id='front_axel_core'>
                    <rect x='38' y='28' width='18' height='5' />
                    <rect x='58' y='26' width='7' height='9' />
                    <polygon points='66,25 106,25 106,36 91,36 91,46 81,46 81,36 66,36' />
                    <rect x='107' y='26' width='7' height='9' />
                    <rect x='115' y='28' width='18' height='5' />
                    <rect x='82.5' y='47.5' width='7' height='60' />
                    <circle cx='86' cy='100' r='12' />
                </svg>
                <Tyre
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    viewBox={`-10 0 ${width} ${height}`}
                    front={false}
                    position={1}
                    color={
                        highlightWheels && highlightWheels[1] ? highlightWheels[1]?.color || highlightColor : undefined
                    }
                    vehicleAtomId={vehicleAtomId}
                />
                <Tyre
                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                    allowSelectTyre={allowSelectTyre}
                    viewBox={`-135 0 ${width} ${height}`}
                    front={false}
                    position={2}
                    color={
                        highlightWheels && highlightWheels[2] ? highlightWheels[2]?.color || highlightColor : undefined
                    }
                    vehicleAtomId={vehicleAtomId}
                />
            </g>
        </svg>
    );
};
