import { DispatcherAction, ModalActionTypesEnum, ModalHookReturnType } from './ModalHook.type';
import { useRecoilState } from 'recoil';
import { StatedModalSelector, modalAtomDefaultProps } from './ModalHook.atom';

const useModal = (layer = 0): ModalHookReturnType => {
    const [modalState, setModalState] = useRecoilState(StatedModalSelector(layer));

    const dispatcher = (action: DispatcherAction): void => {
        switch (action.type) {
            case ModalActionTypesEnum.TOGGLE_MODAL:
                return setModalState({
                    isOpen: !modalState.isOpen
                });
            case ModalActionTypesEnum.CLOSE_MODAL:
                return setModalState({
                    isOpen: false
                });
            case ModalActionTypesEnum.OPEN_MODAL:
                return setModalState({
                    isOpen: true
                });
            case ModalActionTypesEnum.UPDATE_MODAL_STATE:
                return setModalState({
                    ...action.ModalPropsPayload
                });
            case ModalActionTypesEnum.SET_MODAL_STATE:
                return setModalState({
                    ...modalAtomDefaultProps,
                    ...action.ModalPropsPayload
                });
        }
    };

    return {
        modalState: modalState,
        toggleModal: () => dispatcher({ type: ModalActionTypesEnum.TOGGLE_MODAL }),
        closeModal: () => dispatcher({ type: ModalActionTypesEnum.CLOSE_MODAL }),
        openModal: () => dispatcher({ type: ModalActionTypesEnum.OPEN_MODAL }),
        updateModalState: (payload) =>
            dispatcher({ type: ModalActionTypesEnum.UPDATE_MODAL_STATE, ModalPropsPayload: payload }),
        setModalState: (payload) =>
            dispatcher({ type: ModalActionTypesEnum.SET_MODAL_STATE, ModalPropsPayload: payload })
    };
};

export default useModal;
