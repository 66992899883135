import React, { useState, useReducer } from 'react';
import {
    AddAreaAction,
    AreaActionsEnum,
    ActiveStepActionsEnum,
    AddAreaData,
    AddAreaProps,
    ActiveStepAction
} from './AddArea.type';
import { useMutation } from '@tanstack/react-query';
import { AddAreaContent } from './AddArea.view';
import { MapAction, MapActionData } from '../../states/global/Map';
import { MapActionsEnum } from '../Map/Map.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AreaApi from '../../api/Area';
import { Success } from '../Popup/Popup';
import { ShowAddAreaModal } from '../../states/global/Modal';
import { DefaultRequestPropsType, RequestPropsWithData } from 'helpers/api/type';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';

const InitState: AddAreaData = {
    name: '',
    color: 'rgba(255,0,0,0.4)',
    speedZone: false,
    counterZone: false,
    maintenanceZone: false,
    maxSpeed: 0,
    minSpeed: 0
};

const AddAreaReducer = (state: AddAreaData, action: AddAreaAction): AddAreaData => {
    switch (action.type) {
        case AreaActionsEnum.SET_NAME:
            return { ...state, name: action.payload };
        case AreaActionsEnum.SET_COLOR:
            return { ...state, color: action.payload };
        case AreaActionsEnum.SET_COUNTER_ZONE:
            return { ...state, counterZone: action.payload };
        case AreaActionsEnum.SET_MAINTENANCE_ZONE:
            return { ...state, maintenanceZone: action.payload };
        case AreaActionsEnum.SET_SPEED_ZONE: {
            let copyState = { ...state };
            if (!action.payload) {
                copyState.maxSpeed = 0;
                copyState.minSpeed = 0;
            }
            return { ...copyState, speedZone: action.payload };
        }
        case AreaActionsEnum.SET_MIN_SPEED:
            return { ...state, minSpeed: action.payload };
        case AreaActionsEnum.SET_MAX_SPEED:
            return { ...state, maxSpeed: action.payload };
        case AreaActionsEnum.RESET:
            return InitState;
        default:
            return state;
    }
};

const ActiveStepReducer = (state: number, action: ActiveStepAction): number => {
    switch (action.type) {
        case ActiveStepActionsEnum.PREVIOUS_STEP:
            return state - 1;
        case ActiveStepActionsEnum.NEXT_STEP:
            return state + 1;
        case ActiveStepActionsEnum.RESET:
            return 0;
        default:
            return state;
    }
};

const AddArea: React.FC<AddAreaProps> = (): JSX.Element => {
    const [addArea, setAddArea] = useReducer(AddAreaReducer, InitState);
    const [activeStep, setActiveStep] = useReducer(ActiveStepReducer, 0);
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    const mapActionData = useRecoilValue(MapActionData);
    const setMapAction = useSetRecoilState(MapAction);
    const setAddAreaModal = useSetRecoilState(ShowAddAreaModal);
    const Area = new AreaApi();
    const { t: translate } = useTranslation();

    const { mutate: AddAreaMutation } = useMutation<
        DefaultRequestPropsType,
        unknown,
        RequestPropsWithData<Partial<AddAreaData>>
    >(Area.post, {
        onSuccess: () => Success({ text: `${translate('t.area')} ${translate('t.has_been_added')}` }),
        onSettled: () => {
            setIsExecuting(false);
            setAddAreaModal(false);
        }
    });

    const resetModal = () => {
        setActiveStep({ type: ActiveStepActionsEnum.RESET });
        setAddArea({ type: AreaActionsEnum.RESET });
    };

    const saveArea = async (): Promise<void> => {
        setIsExecuting(true);
        const areaCoordinates = mapActionData.newArea || [[]];
        let data = {
            area: areaCoordinates[0],
            name: addArea.name,
            color: addArea.color,
            generateSpeedAlerts: addArea.speedZone ? 1 : 0,
            generateAreaEvents: addArea.counterZone ? 1 : 0,
            maintenanceArea: addArea.maintenanceZone ? 1 : 0
        };
        if (addArea.speedZone) {
            data = Object.assign(data, {
                maxSpeed: +addArea.maxSpeed,
                minSpeed: +addArea.minSpeed
            });
        }

        await AddAreaMutation({ data: data });

        setMapAction({ action: MapActionsEnum.CLEAN_DRAW_AREA });
        setMapAction({ action: MapActionsEnum.DRAW_AREAS });
    };
    return (
        <AddAreaContent
            formData={addArea}
            setFormData={setAddArea}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            saveArea={saveArea}
            resetModal={resetModal}
            isExecuting={isExecuting}
            data-testid='AddArea-testid'
        />
    );
};

export default Wrapper(AddArea);
