import { Box, TextField, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { BACKGROUND_PAPER_DARK_MODE, WHITE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';

export const AlertSettingInputViewContent = styleMui(Box)``;

export const AlertInput = styleMui(TextField)((props: { theme: Theme }) => ({
    background: props.theme.palette.mode === ThemeMode.dark ? BACKGROUND_PAPER_DARK_MODE : WHITE,
    marginBottom: 3,
    '.MuiInputAdornment-positionEnd': {
        '.MuiTypography-root': {
            borderLeft: 'solid thin rgba(200,200,200)',
            paddingLeft: '10px',
            minWidth: '31px'
        }
    }
}));
