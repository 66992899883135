import React from 'react';
import { SetAlertsTempPressureWidgetContainer } from './SetAlertsTempPressureWidget.style';
import { SetAlertsTempPressureWidgetProps } from './SetAlertsTempPressureWidget.type';
import { Collapse, Grid, Hidden, Typography } from '@mui/material';
import AlertSettings from '../AlertSettings/AlertSettings';
import { RULE_TYPE } from 'models/Rule.type';
import { useTranslation } from 'react-i18next';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { SettingSteps, SettingsStep } from '../AlertSettings/atom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CollapseContent } from 'components/Ui/Components/UiWidget/UiWidget.style';
import AlertSettingEditChecker from '../AlertSettingEditChecker/AlertSettingEditChecker';
import { SaveBtnCover, SetAlertsStepBadge } from 'pages/SetAlerts/SetAlerts.style';
import SaveAlertsSettingsButton from '../SaveAlertsSettingsButton/SaveAlertsSettingsButton';
import { AppliedFilters } from 'components/Alert/AlertsFilterVehiclesBy/atom';
import CheckIcon from '@mui/icons-material/Check';
import NotificationTireTresholdsLegend from '../NotificationTireTresholdsLegend/NotificationTireTresholdsLegend';

const SetAlertsTempPressureWidgetContent: React.FC<SetAlertsTempPressureWidgetProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [settingsStep, setSettingsStep] = useRecoilState(SettingsStep);
    const filters = useRecoilValue(AppliedFilters); // dat samostatne

    return (
        <SetAlertsTempPressureWidgetContainer data-testid='SetAlertsTempPressureWidgetContent'>
            <Grid container>
                <Grid item xs={12} md={4} lg={3} display='flex' alignItems='center' height='45px' padding='5px 10px'>
                    <Typography variant='subtitle1'>
                        <SetAlertsStepBadge>
                            {SettingSteps.TEMPERATURE_PRESSURE_SETTING < settingsStep ? (
                                <CheckIcon fontSize='inherit' />
                            ) : (
                                SettingSteps.TEMPERATURE_PRESSURE_SETTING + 2
                            )}
                        </SetAlertsStepBadge>{' '}
                        {`${translate('t.temperature_and_pressure_settings')}: `}
                    </Typography>
                </Grid>
            </Grid>
            <Collapse in={settingsStep === SettingSteps.TEMPERATURE_PRESSURE_SETTING} timeout='auto'>
                <CollapseContent $paddingcontent='10px 10px'>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <AlertSettings ruleType={RULE_TYPE.WIZARD} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            lg={5}
                            justifyContent='left'
                            paddingTop='10px'
                            display='flex'
                            width='100%'
                        >
                            <NotificationTireTresholdsLegend popperPlacementType='top' />
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={12} paddingTop='10px' display='flex' width='100%'>
                                <AlertSettingEditChecker />
                            </Grid>
                        </Hidden>

                        <Grid
                            item
                            xs={12}
                            md={7}
                            lg={7}
                            justifyContent='right'
                            paddingTop='10px'
                            display='flex'
                            width='100%'
                        >
                            <Hidden smDown>
                                <AlertSettingEditChecker />
                            </Hidden>
                            <SaveBtnCover>
                                <SaveAlertsSettingsButton
                                    ruleType={filters.default ? RULE_TYPE.DEFAULT : RULE_TYPE.WIZARD}
                                />
                            </SaveBtnCover>
                            <SaveBtnCover>
                                <UiButton
                                    variant='outlined'
                                    onClick={() => {
                                        setSettingsStep(SettingSteps.SELECT_FILTER);
                                    }}
                                >
                                    {translate('t.back')}
                                </UiButton>
                            </SaveBtnCover>
                        </Grid>
                    </Grid>
                </CollapseContent>
            </Collapse>
        </SetAlertsTempPressureWidgetContainer>
    );
};

export default SetAlertsTempPressureWidgetContent;
