import { atom } from 'recoil';
import { DateRangeData } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

export const AlertNotificationManagmentState = atom<DateRangeData>({
    key: 'AlertNotificationManagmentState',
    default: {
        dateFrom: null,
        dateTo: null,
        isValidRange: false
    }
});
