import { List } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const PopoverContainer = styled.div`
    ${applyStyleByMode({
        styleJade: 'width: 250px;'
    })}
`;

export const AllTransmittingList = styled(List)`
    height: 45px;
    width: 130px;
    text-align: center;
`;

export const SensorList = styled(List)`
    overflow-y: auto;
    min-width: 218px;
    ${applyStyleByMode({
        styleOld: `
            max-height: 300px;
        `,
        styleJade: `
            max-height: 400px;
        `
    })}
`;
