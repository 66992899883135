import { Autocomplete, Box, IconButton, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { WHITE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';

export const AlertsFilterVehiclesInputViewContent = styleMui(Box)({});

export const CloseButton = styleMui(IconButton)((props: { theme: Theme }) => ({
    position: 'absolute',
    top: 8,
    right: -8,
    background: '#fff',
    border: 'solid thin #aeacac',
    height: '18px',
    width: '18px',
    fontSize: '0.8rem',
    '&.MuiIconButton-root:hover': {
        background: props.theme.palette.mode === ThemeMode.dark ? '#1d2630' : 'rgb(245,245,245)'
    }
}));

export const CustomAutocomplete = styleMui(Autocomplete)((props: { theme: Theme }) => ({
    margin: '8px 0',
    '.MuiFormLabel-root': {
        background: `${props.theme.palette.mode === ThemeMode.dark ? '#1d2630' : WHITE}`
    }
}));
