import styled from 'styled-components';
import { Grid, Paper, useTheme } from '@mui/material';
import UiButton from '../../Ui/Components/UiButton';
import { ThemeMode } from '../../../states/global/Theme';
import { BACKGROUND_PAPER_DARK_MODE, COLOR_GREY_6 } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';

export const NewHubsExtendedRowStyle = styled.div`
    padding: 10px;
`;

export const LeftBox = styled(Paper)`
    &.MuiPaper-root {
        padding: 19px 10px;
        ${() => {
            return applyStyleByMode({
                styleOld: '',
                styleJade: `
                background: ${useTheme().palette.mode === ThemeMode.light ? COLOR_GREY_6 : BACKGROUND_PAPER_DARK_MODE};
                        `
            });
        }}
    }
`;

export const IdentifyDiv = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding-bottom: 16px;
`;

export const SensorTypeDiv = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding: 20px 0;
`;

export const SetSensorTypesRow = styled(Grid)`
    display: flex;
    justify-content: center;
`;

export const Text = styled.div`
    margin: 12px 0;
    font-weight: 500;
`;

export const ButtonWithoutLeftMargin = styled(UiButton)`
    &.MuiButton-root {
        margin-left: 0;
        margin-top: 6px;
    }
`;

export const GridWithMarginTop = styled(Grid)<{ marginTop: number }>`
    &.MuiGrid-item {
        margin-top: ${(props) => props.marginTop}px;
    }
`;
