import React from 'react';
import { VehicleStatusIconsContent } from './VehicleStatusIcons.view';
import { VehicleStatusIconsProps } from './VehicleStatusIcons.type';
import { Wrapper } from 'helpers/wrapper';

const VehicleStatusIcons: React.FC<VehicleStatusIconsProps> = (props): JSX.Element => {
    return <VehicleStatusIconsContent data-testid='VehicleStatusIcons-testid' {...props} />;
};

export default Wrapper(VehicleStatusIcons);
