import React from 'react';
import { TagFilterProps } from './TagFilter.type';
import { TagFilterContainer } from './TagFilter.style';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip } from '@mui/material';

export const TagFilterContent: React.FC<TagFilterProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <TagFilterContainer data-testid='TagFilterContent'>
            <Tooltip title={translate('t.remove_filter')} placement='right' disableInteractive>
                <Chip size='small' clickable label={`${props.chipText}`} onDelete={props.resetAction} />
            </Tooltip>
        </TagFilterContainer>
    );
};
