import { atom } from 'recoil';

export type SidebarMenuType = {
    showMenu: boolean;
    showMenuResponsive: boolean;
};

export const SidebarMenu = atom<SidebarMenuType>({
    key: 'SidebarMenu',
    default: {
        showMenu: true,
        showMenuResponsive: false
    }
});
