import React from 'react';
import Alert from '@mui/material/Alert';
import {
    ExportedReportDetailContainer,
    ItemsContainer,
    LoadingContent,
    StatusReport,
    TitleReport
} from './ExportedReportDetail.style';
import { ExportedReportDetailViewProps, LanguageType } from './ExportedReportDetail.type';
import { useTranslation } from 'react-i18next';
import { ExportedReportTable } from './ExportedReportsTable.view';
import { VARIANT_ALERT } from '../../../Ui/variables';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';

export const ExportedReportDetailContent: React.FC<ExportedReportDetailViewProps> = ({
    report,
    reportDetail,
    loading,
    reportStatus,
    dateFormat,
    timeFormat,
    temperatureFormat
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    if (loading) {
        return (
            <LoadingContent>
                <UiLoadingPage size='30px' />
            </LoadingContent>
        );
    }

    return !reportDetail ? (
        <Alert variant={VARIANT_ALERT} severity='info'>
            {translate('p.select_report_preview')}
        </Alert>
    ) : (
        <ExportedReportDetailContainer data-testid='ExportedReportDetailContent'>
            <TitleReport variant='subtitle1'>
                {translate('t.report')}: {report?.reportName}
            </TitleReport>
            <StatusReport color={reportStatus[report?.status ?? 'done'].color} variant='subtitle1'>
                {translate('t.status')}: {reportStatus[report?.status ?? 'done'].label}
            </StatusReport>

            <ItemsContainer>
                <ExportedReportTable
                    title={translate('t.csv_settings')}
                    cells={[
                        {
                            label: translate('t.language'),
                            value: LanguageType[reportDetail.format.language]
                        },
                        {
                            label: translate('t.date_format'),
                            value: dateFormat(reportDetail.format.dateFormat)
                        },
                        {
                            label: translate('t.time_format'),
                            value: timeFormat(reportDetail.format.timeFormat)
                        },
                        {
                            label: translate('t.timezone'),
                            value: reportDetail.format.timezone
                        },
                        {
                            label: translate('t.pressure_unit'),
                            value: reportDetail.format.pressureUnit
                        },
                        {
                            label: translate('t.temperature_unit'),
                            value: `°${temperatureFormat(reportDetail.format.temperatureUnit)}`
                        }
                    ]}
                />
            </ItemsContainer>

            <ItemsContainer>
                <ExportedReportTable
                    title={translate('t.email_settings')}
                    cells={reportDetail.emails.map((email) => {
                        return {
                            label: translate('t.receiver'),
                            value: email
                        };
                    })}
                />
            </ItemsContainer>

            <ItemsContainer>
                <ExportedReportTable
                    title={translate('t.report_period')}
                    cells={[
                        {
                            label: translate('t.date_from'),
                            value: fromUTCToTimezone(DateTime.fromSeconds(reportDetail.dateFrom).toISO(), false)
                        },
                        {
                            label: translate('t.date_to'),
                            value: fromUTCToTimezone(DateTime.fromSeconds(reportDetail.dateTo).toISO(), false)
                        }
                    ]}
                />
            </ItemsContainer>
        </ExportedReportDetailContainer>
    );
};
