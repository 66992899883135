import styled from 'styled-components';

import { ALERT_LEVEL_1_DARK, ALERT_LEVEL_2_DARK, ALERT_LEVEL_3_DARK } from '../Ui/colors';
import { ThemeMode } from '../../states/global/Theme';
import { useTheme } from '@mui/material';

export const TempPressAlertLevelTableView = styled.div`
    min-height: 73vh;
`;

export const UnitValue = styled.span`
    float: right;
`;

export const TableRows = styled.div<{ alertLevel: number; $type?: boolean }>`
    display: flex;
    padding-right: 5px;
    ${(props) => !props.$type && 'text-align: center'};
    flex-direction: column;
    ${(props) =>
        props.alertLevel === 0 &&
        `
        border-left: 1px solid #b2b2b2;
        border-right: 1px solid #b2b2b2;
    `};
    background: ${(props) => {
        const alertColorsOld = {
            0: 'rgb(233, 255, 224)',
            1: 'rgb(255, 251, 233)',
            2: 'rgb(255, 232, 209)',
            3: 'rgb(255, 204, 204)',
            4: 'inherit'
        };

        const alertColors = {
            0: '#06a347',
            1: ALERT_LEVEL_1_DARK,
            2: ALERT_LEVEL_2_DARK,
            3: ALERT_LEVEL_3_DARK,
            4: 'inherit'
        };
        return useTheme().palette.mode === ThemeMode.light
            ? alertColorsOld[props.alertLevel]
            : alertColors[props.alertLevel];
    }};
`;

export const BorderDiv = styled.div`
    border-bottom: 1px solid rgb(212, 212, 212);
`;

export const SelectVehicleMessage = styled.div`
    padding: 50px;
    display: flex;
    justify-content: center;
    min-height: 53vh;
`;

export const TableStyles = styled.div`
    td:first-child,
    td:nth-child(2) {
        padding-left: 16px;
    }
    th:first-child,
    th:nth-child(2) {
        padding-left: 16px;
    }
    th {
        padding: 6px 0 6px 0;
        ${() => useTheme().breakpoints.between('xs', 'sm')} {
            min-width: 120px;
        }
    }
    td {
        padding: 0;
    }
    tbody > tr {
        border-bottom: 1px solid #b2b2b2;
    }
`;

export const CenterText = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;
