import { Checkbox, CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const CustomCheckBox = styled(Checkbox)`
    height: 36px;
`;

export const CustomLoading = styled(CircularProgress)`
    margin-left: 10px;
    margin-top: 5px;
`;
