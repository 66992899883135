import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';

export const RowButton = styled(UiButton)`
    margin: 0px 0px 0px 10px !important;
    min-width: 40px !important;
    ${applyStyleByMode({
        styleOld: 'height: 37px !important;',
        styleJade: 'height: 34px !important;'
    })}
`;
