import styled from 'styled-components';
import { Autocomplete, Grid, TextField } from '@mui/material';
import UiButton from '../../../../Ui/Components/UiButton/UiButton';
import UiDropDown from '../../../../Ui/Components/UiDropDown/UiDropDown';

export const TopBarTerminalContainer = styled.div`
    padding: 0px 10px 0 10px;

    h3 {
        font-weight: lighter !important;
    }

    &&& {
        .MuiTextField-root {
            & label {
                padding: 0 5px 0 0;
                background: transparent;
            }
            & .MuiInputLabel-shrink {
                transform: translate(10px, -9px) scale(0.8);
            }
        }
    }
`;

export const GridCustom = styled(Grid)`
    .MuiButtonBase-root {
        margin: 0 !important;
    }
`;

export const Button = styled(UiButton)<{ hidden: boolean }>`
    margin: 0 0 0 15px !important;
    &.MuiButtonBase-root {
        ${(props) =>
            props.hidden
                ? `
            display:none`
                : ''}
    }
`;

export const DropDown = styled(UiDropDown)``;

export const ValueTextField = styled(TextField)<{ hidden: boolean }>`
    float: right;
    &.MuiTextField-root {
        margin-left: 15px;
        min-width: 70%;

        ${(props) =>
            props.hidden
                ? `
        display:none`
                : ''}
    }
`;

export const TopBarRightSide = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    & .MuiFormControl-root {
        width: 225px !important;
    }
`;

export const TopBarLeftSide = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledAutocomplete = styled(Autocomplete)``;

export const Selectors = styled.div`
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
`;
