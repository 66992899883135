import React from 'react';
import { ActiveVersionsViewProps } from './ActiveVersions.type';
import { ActiveVersionsContainer, Versions } from './ActiveVersions.style';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../states/global/User';

export const ActiveVersionsContent: React.FC<ActiveVersionsViewProps> = (props): JSX.Element => {
    const userSettings = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    return (
        <ActiveVersionsContainer data-testid='ActiveVersionsContent'>
            {userSettings.user?.role.name === 'ROLE_SUPER_ADMIN' && (
                <>
                    <Typography variant='h4' data-testid='ActiveVersionsLabel'>
                        {translate('t.active_versions')}
                    </Typography>
                    <Versions data-testid='ActiveVersionsVersionLabel'>API: {props.data?.version || ''}</Versions>
                </>
            )}
        </ActiveVersionsContainer>
    );
};
