import { SystemTotalModel, Transmitting } from 'models/Sensors.type';
import { GetSystemTotalModel } from 'models/Sensors.type';

export type TableProps = {
    data: SystemTotalModel;
};

export enum TypeStage {
    SIMPLE,
    ADVANCE,
    ADVANCE_TOTAL
}

export type SystemTotalsViewProps = {
    handleStageChange: () => void;
};

export type LegendItemsProps = {
    title: string | JSX.Element;
    text: string;
};

export type TableRowsProps = {
    label: string;
    value: number;
    percentage?: number;
    getColorOfChart?: (percentage: number) => string;
};

export type TableRowProps = {
    label: string;
    value: Transmitting;
    dataType: PopupDataType;
};

export enum PopupDataType {
    VEHICLES = 'vehicles',
    SENSORS = 'sensors',
    INTERNAL = 'internal',
    EXTERNAL = 'external'
}

export type TableBodyType = {
    data?: GetSystemTotalModel;
    isLoading: boolean;
};
