import React, { useState } from 'react';
import { UserPermissionsTableContent } from './UserPermissionsTable.view';
import { UserTableProps } from './UserPermissionsTable.type';
import { GetRegionsCodebookQuery } from '../../Queries/Regions';
import { GetRolesCodebookQuery } from '../../Queries/Roles/Roles';
import { useSetRecoilState } from 'recoil';
import { ShowPermissionModal } from 'states/global/Modal';
import { SelectedUser } from '../MinesPermissions/Permissions.atom';
import { UserModel } from 'models/User.type';

const UserPermissionsTable: React.FC<UserTableProps> = (props): JSX.Element => {
    const setPermissionAtom = useSetRecoilState(SelectedUser);
    const setPermissionModal = useSetRecoilState(ShowPermissionModal);
    const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false);

    const editPermissions = (data: UserModel): void => {
        setPermissionAtom(data);
        setShowPermissionModal(true);
        setPermissionModal(true);
    };

    return (
        <GetRolesCodebookQuery>
            {({ data: roleData }) => (
                <GetRegionsCodebookQuery>
                    {({ data: regionData }) => (
                        <UserPermissionsTableContent
                            data-testid={'UserPermissionsTable-testid'}
                            {...props}
                            region={regionData}
                            role={roleData}
                            editPermissions={editPermissions}
                            showPermissionModal={showPermissionModal}
                            setShowPermissionModal={setShowPermissionModal}
                        />
                    )}
                </GetRegionsCodebookQuery>
            )}
        </GetRolesCodebookQuery>
    );
};

export default UserPermissionsTable;
