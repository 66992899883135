import { TextField } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import styled from 'styled-components';
import { WHITE } from 'components/Ui/colors';

export const QuicksightReportsViewContent = styled.div``;

export const CustomTextField = styled(TextField)`
    width: 100%;

    ${applyStyleByMode({
        styleOld: ` 
            & .MuiSvgIcon-root {
                color: ${WHITE} !important;
            }
            `
    })}
`;

export const QuicksightReportsWrapper = styled.div`
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UiCircularProgressCover = styled.div`
    position: absolute;
`;

export const InitMsg = styled.p`
    position: absolute;
`;

export const QuicksightReportsContentEl = styled.div`
    width: 100%;
    height: 80vh;
`;

export const ContainerAutoCompleteReports = styled.div`
    margin-left: 10px;

    ${applyStyleByMode({
        styleJade: `
            && .MuiAutocomplete-popupIndicator {
                padding: 4px;
                margin-top: 3px;
            }
        `
    })}
`;
