import PortalApi from 'helpers/api/PortalApiClient';
import * as type from '../models/SensorFaultyReport.type';
import { SensorFaultyReportModel } from '../models/SensorFaultyReport.type';
import { getParams } from 'helpers';

export default class SensorFaultyReport extends PortalApi<SensorFaultyReportModel> {
    route = 'sensor-faulty-report';

    getAll = async (props): Promise<type.TableResponse> => {
        return await this.getTable<type.TableItem>({
            criteria: getParams(props)
        });
    };

    getExport = async (props): Promise<string> => {
        return await this.getByCriteria({
            extendUrl: 'export',

            criteria: getParams(props)
        });
    };

    createReport = async (data: {}) => {
        return await this.post({ data: data });
    };

    reopenReport = async (id) => {
        return await this.patch({ extendUrl: `${id}/reopen` });
    };

    verifyReport = async (props: { id: number; data: {} }) => {
        return await this.patchById({ id: props.id, data: props.data });
    };
}
