import styled from 'styled-components';
import UiButton from '../../Ui/Components/UiButton';

export const VehicleTableContentView = styled.div`
    width: 100%;
`;

export const Button = styled(UiButton)`
    color: #fff !important;
    min-width: 20px !important;
    max-width: 20px !important;
`;
export const ContainerSwitchFilter = styled.div`
    padding: 0px 3px;
`;

export const HubContainer = styled.div`
    margin-left: 5px;
`;
