import React from 'react';
import { Error404Props } from './Error404.type';
import { Error404Content } from './Error404.view';
import { Wrapper } from 'helpers/wrapper';
// import module

const Error404: React.FC<Error404Props> = (props) => {
    return <Error404Content {...props} data-testid='Error404-testid' />;
};

export default Wrapper(Error404);
