import React from 'react';
import * as type from './UiButton.type';
import * as view from './UiButton.view';
// import module

const UiButton: React.FC<type.UiPassProps> = (props) => {
    return <view.UiButtonContent {...props} data-testid={props.testid || 'UiButtonContent'} />;
};

export default UiButton;
