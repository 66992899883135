import React, { useState, useEffect } from 'react';
import { VehicleMapPopupAlertsContent } from './VehicleMapPopupAlerts.view';
import { VehicleMapPopupAlertsProps } from './VehicleMapPopupAlerts.type';
import { MapActionData } from 'states/global/Map';
import { useRecoilValue } from 'recoil';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import NotificationEvent from 'api/NotificationEvent';
import { Wrapper } from 'helpers/wrapper';

const notificationEventAPI = new NotificationEvent();

const VehicleMapPopupAlerts: React.FC<VehicleMapPopupAlertsProps> = (props): JSX.Element => {
    const [data, setData] = useState<ModelTpmsAlerts[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const mapActionData = useRecoilValue(MapActionData);

    const loadData = async () => {
        try {
            if (hasMore) {
                const alertsDataXHR = await notificationEventAPI.getNotification({
                    vehicleId: mapActionData.clickedVehicle,
                    solved: false,
                    page: currentPage + 1,
                    limit: 2,
                    tab: 'tpms'
                });
                setData((current) => [...current, ...alertsDataXHR.items]);
                const nextPage = currentPage + 1;
                const pages = alertsDataXHR.paginator.pageCount;
                setCurrentPage(nextPage);
                setHasMore(pages > nextPage);
            }
        } catch (e) {
            false;
        }
    };

    useEffect(() => {
        if (props.isActive) {
            setHasMore(true);
            setCurrentPage(0);
        }
    }, [props.isActive]);

    return (
        <VehicleMapPopupAlertsContent
            hasMore={hasMore}
            loadData={loadData}
            data={data}
            data-testid={'VehicleMapPopupAlerts-testid'}
        />
    );
};

export default Wrapper(VehicleMapPopupAlerts);
