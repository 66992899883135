import { Box, Grid, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import {
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_OPTIMAL_PRESSURE,
    ALERT_OPTIMAL_PRESSURE_DARK
} from '../../Ui/colors';
import { AlertSettingLevelProps } from './AlertSettingLevel.type';
import { ThemeMode } from 'states/global/Theme';

let mode = 'light';

export const getColor = (level: number, theme: Theme): string => {
    if (theme && theme.palette.mode !== mode) {
        mode = theme.palette.mode;
    }
    const alertColors = {
        light: {
            0: ALERT_OPTIMAL_PRESSURE,
            1: ALERT_LEVEL_1,
            2: ALERT_LEVEL_2,
            3: ALERT_LEVEL_3
        },
        dark: {
            0: ALERT_OPTIMAL_PRESSURE_DARK,
            1: ALERT_LEVEL_1_DARK,
            2: ALERT_LEVEL_2_DARK,
            3: ALERT_LEVEL_3_DARK
        }
    };
    return alertColors[mode][level];
    /*switch (level) {
        case 0:
            return '#c5ffc5';
        case 1:
            return ALERT_LEVEL_1;
        case 2:
            return ALERT_LEVEL_2;
        case 3:
            return ALERT_LEVEL_3;
        default:
            return ALERT_LEVEL_0;
    }*/
};

export const AlertSettingLevelViewContent = styleMui(Grid)(
    (props: Pick<AlertSettingLevelProps, 'level'> & { theme: Theme }) => ({
        background: getColor(props.level % 10, props.theme),
        ':not(:last-child)': {
            borderLeft: 'thin solid rgba(200,200,200)',
            borderTop: 'thin solid rgba(200,200,200)',
            borderBottom: 'thin solid rgba(200,200,200)',
            borderRight: props.level === 11 ? 'thin solid rgba(200,200,200)' : 0
        },
        ':last-child': {
            border: 'thin solid rgba(200,200,200)'
        }
    })
);

export const AlertLabel = styleMui(Box)((props: { theme: Theme }) => ({
    justifyContent: 'center',
    padding: 5,
    display: 'flex',
    background: props.theme.palette.mode === ThemeMode.dark ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.6)'
}));

export const AlertContent = styleMui(Box)({
    padding: '5px 10px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
});
