import React, { useMemo } from 'react';
import { VehicleSchemaViewType } from './VehicleSchema.type';
import { VehicleSchemaViewContent } from './VehicleSchema.style';
import { FrontAxel } from './FrontAxel';
import { BackAxel } from './BackAxel';
import { Loader22FrontAxel } from './Axels/Loader-2-2-FrontAxel';
import BesLabelHover from '../../Mixs/BesLabelHover';

const viewBoxes = [
    { startX: -15, startY: -20, width: 200, height: 250 },
    { startX: -15, startY: -20, width: 200, height: 340 },
    { startX: -15, startY: -20, width: 200, height: 425 },
    { startX: -15, startY: -20, width: 200, height: 510 }
];

export const VehicleSchemaView: React.FC<VehicleSchemaViewType> = ({
    schema,
    emptySchema,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    highlightColor,
    highlightWheels
}): JSX.Element => {
    const isLoader_2_2 = useMemo(() => schema[0].includes('L'), [schema]);
    let currentTotal = 2;
    let viewBoxValues = useMemo(() => {
        if (isLoader_2_2) return viewBoxes[1];
        return viewBoxes[schema.length - 2 || 0];
    }, [schema, isLoader_2_2]);

    return (
        <VehicleSchemaViewContent className='MainContainerSchemma'>
            {!emptySchema && <BesLabelHover />}
            <svg
                x={0}
                y={0}
                viewBox={
                    emptySchema
                        ? `0 0 400 400`
                        : `${viewBoxValues.startX} -10 ${viewBoxValues?.width} ${viewBoxValues?.height}`
                }
                id='schema'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                xmlSpace='preserve'
            >
                <g className='ContainerSchema'>
                    {isLoader_2_2 ? (
                        <Loader22FrontAxel
                            width={viewBoxValues?.width}
                            height={viewBoxValues?.height}
                            vehicleAtomId={vehicleAtomId}
                            allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                            allowSelectTyre={allowSelectTyre}
                            highlightColor={highlightColor}
                            highlightWheels={highlightWheels}
                        />
                    ) : (
                        <FrontAxel
                            width={viewBoxValues?.width}
                            height={viewBoxValues?.height}
                            vehicleAtomId={vehicleAtomId}
                            allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                            allowSelectTyre={allowSelectTyre}
                            highlightColor={highlightColor}
                            highlightWheels={highlightWheels}
                        />
                    )}
                    {schema.map((wheelsInAxel, index) => {
                        if (index > 0) {
                            currentTotal += +wheelsInAxel;

                            return (
                                <BackAxel
                                    key={index}
                                    width={viewBoxValues?.width}
                                    height={viewBoxValues?.height}
                                    startY={viewBoxValues?.startY}
                                    axelPosition={index}
                                    numberOfWheels={+wheelsInAxel}
                                    startWheel={currentTotal}
                                    vehicleAtomId={vehicleAtomId}
                                    allowDisplaySelectedTyre={allowDisplaySelectedTyre}
                                    allowSelectTyre={allowSelectTyre}
                                    highlightColor={highlightColor}
                                    highlightWheels={highlightWheels}
                                    isLoader_2_2={isLoader_2_2}
                                    isEmptySchema={emptySchema}
                                />
                            );
                        }
                    })}
                </g>
            </svg>
        </VehicleSchemaViewContent>
    );
};
