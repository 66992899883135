import PortalApi from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { RouteModel, RouteModelResponse } from '../models/Route.type';

export default class Route extends PortalApi<RouteModel> {
    route = 'route';

    getAll = async (props): Promise<RouteModelResponse> => {
        return await this.getTable<RouteModel>({ criteria: getParams(props) });
    };

    removeRoute = async (id: number): Promise<void | unknown> => {
        return await this.delete({ id: id });
    };
}
