export type AddAreaProps = {};
export type AddAreaViewProps = {
    formData: AddAreaData;
    setFormData: React.Dispatch<AddAreaAction>;
    activeStep: number;
    setActiveStep: React.Dispatch<ActiveStepAction>;
    saveArea: () => Promise<void>;
    resetModal: () => void;
    isExecuting: boolean;
};

export enum AreaActionsEnum {
    SET_NAME = 'SET_NAME',
    SET_COLOR = 'SET_COLOR',
    SET_SPEED_ZONE = 'SET_SPEED_ZONE',
    SET_COUNTER_ZONE = 'SET_COUNTER_ZONE',
    SET_MAINTENANCE_ZONE = 'SET_MAINTENANCE_ZONE',
    SET_MAX_SPEED = 'SET_MAX_SPEED',
    SET_MIN_SPEED = 'SET_MIN_SPEED',
    RESET = 'RESET'
}

type AddNumber = {
    type: AreaActionsEnum.SET_MAX_SPEED | AreaActionsEnum.SET_MIN_SPEED;
    payload: number;
};

type AddString = {
    type: AreaActionsEnum.SET_NAME | AreaActionsEnum.SET_COLOR;
    payload: string;
};

type Reset = {
    type: AreaActionsEnum.RESET;
};

type AddBoolean = {
    type: AreaActionsEnum.SET_COUNTER_ZONE | AreaActionsEnum.SET_MAINTENANCE_ZONE | AreaActionsEnum.SET_SPEED_ZONE;
    payload: boolean;
};

export type AddAreaAction = AddNumber | AddString | AddBoolean | Reset;

export type AddAreaData = {
    name: string;
    color: string;
    speedZone: boolean;
    counterZone: boolean;
    maintenanceZone: boolean;
    maxSpeed: number;
    minSpeed: number;
};

export enum ActiveStepActionsEnum {
    NEXT_STEP = 'NEXT_STEP',
    PREVIOUS_STEP = 'PREVIOUS_STEP',
    RESET = 'RESET'
}

export type ActiveStepAction = {
    type: ActiveStepActionsEnum.PREVIOUS_STEP | ActiveStepActionsEnum.NEXT_STEP | ActiveStepActionsEnum.RESET;
};

export type StepContentProps = {
    formData: AddAreaData;
    setFormData: React.Dispatch<AddAreaAction>;
};
