import React from 'react';
import { DefaultValue, atomFamily, selectorFamily } from 'recoil';
import { ModalProps } from '../../Mixs/StatedModal/StatedModal.type';

export const modalAtomDefaultProps: ModalProps = {
    leftTitle: '',
    rightTitle: '',
    content: <>Modal content</>,
    width: 400,
    height: 400,
    onOpen: () => {},
    onClose: () => {},
    id: 'ID21',
    widthUnit: 'px',
    heightUnit: 'px',
    isOpen: false,
    buttons: null,
    customCancelTitle: undefined
};

export const StatedModalAtomFamily = atomFamily<ModalProps, number>({
    key: 'StatedModalAtomFamily',
    default: modalAtomDefaultProps
});

export const StatedModalSelector = selectorFamily({
    key: 'StatedModalSelector',
    get:
        (layer: number) =>
        ({ get }) =>
            get(StatedModalAtomFamily(layer)),
    set:
        (layer: number) =>
        ({ set }, newValue: ModalProps | DefaultValue) =>
            set(StatedModalAtomFamily(layer), (prevValue) => ({ ...prevValue, ...newValue }))
});
