import { styled as styledMui, keyframes, css, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';

export const VehicleSchemaViewContent = styled.div`
    height: 100%;

    & .ContainerSchema {
        ${() => useTheme().breakpoints.between('xs', 'sm')} {
            fill: ${() =>
                applyStyleByMode({
                    theme: useTheme().palette.mode,
                    light: 'fill: #000;',
                    dark: `fill: #7f7f7f;`
                })};
        }

        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                light: 'fill: #000;',
                dark: `fill: #7f7f7f;`
            })}
    }
`;

export const WheelLayer = styledMui('path')<{ $hover: boolean }>`
    fill: #fff;
    ${(props) => `
        ${
            props.$hover
                ? `
            opacity: 0.4;
            cursor: pointer;
        `
                : `
            opacity: 5.0e-2;
        `
        }        
    `}
`;

export const WheelG = styledMui('g')<{
    $sensor: boolean;
    alertLevel?: number | null;
    $theme?: string;
    customColor?: string;
    allowClick?: boolean;
}>`
    animation: ${({ alertLevel }) => alertLevel && wheelAnimation(alertLevel)};
    cursor: ${({ allowClick }) => {
        return allowClick ? 'pointer' : 'auto';
    }};
    ${({ $theme, $sensor, customColor, allowClick }) => {
        const color =
            customColor ||
            ($theme === ThemeMode.light ? (allowClick ? '#656565' : '#000') : allowClick ? '#fff' : '#7f7f7f');
        const colorWithSensor = customColor || ($theme === ThemeMode.light ? '#000' : '#fff');
        return !$sensor ? `fill: ${color};` : `fill: ${colorWithSensor};`;
    }}
`;

const alert1Animation = keyframes`
    0%   { fill: #c4bf08; }
    100% { fill: #7a7700; }
`;

const alert2Animation = keyframes`
    0%   { fill: #F68B2C; }
    100% { fill: #6e2500; }
`;

const alert3Animation = keyframes`
    0%   { fill: #E61414; }
    100% { fill: #810600; }
`;

const alertAnimations = {
    1: alert1Animation,
    2: alert2Animation,
    3: alert3Animation
};

const wheelAnimation = (alert: number) => css`
    ${alertAnimations[alert]} 0.8s steps(60) infinite alternate;
`;
