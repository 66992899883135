import PortalApi from 'helpers/api/PortalApiClient';
import {
    AssignedToRoles,
    AssignRemoveRoleProps,
    AvailableQuicksightReports,
    AvailableToAssing,
    GenerateUrlProps,
    GenerateUrlResponse,
    ReportingModel
} from '../models/Reporting.type';

export default class Reporting extends PortalApi<ReportingModel> {
    route = 'reporting';

    getAvailableQuicksightReports = async (): Promise<AvailableQuicksightReports> => {
        return await this.get({ extendUrl: 'available-to-view' });
    };

    generateEmbeddedUrl = async (data: GenerateUrlProps): Promise<GenerateUrlResponse> => {
        return await this.post({
            data: data,
            extendUrl: 'generate-embedded-url'
        });
    };

    getAvailableToAssign = async (): Promise<AvailableToAssing[]> => {
        return await this.get({ extendUrl: 'available-to-assign' });
    };

    getAssignedToRoles = async (): Promise<AssignedToRoles[]> => {
        return await this.get({ extendUrl: 'assigned-to-roles' });
    };

    assignToRole = async (data: AssignRemoveRoleProps): Promise<void | unknown> => {
        return await this.post({ data: data, extendUrl: 'assign-to-role' });
    };

    removeFromRole = async (data: AssignRemoveRoleProps): Promise<void> => {
        await this.post({ data: data, extendUrl: 'remove-from-role' });
    };
}
