import { RecoilState, atom } from 'recoil';
import { VehicleModel } from '../../../models/Vehicle.type';
import { TyreDetailResponse } from '../../../models/Wheel.type';
import { ConfigurationV2 } from 'models/VehicleConfiguration.type';

let refreshVehicleAtoms = {};

enum AtomsKey {
    VEHICLE_DETAILS_ATOM = 'VehicleDetailsWidgetAtom',
    INTERNAL_SENSOR_ATOM = 'HasInternalSensorAtom'
}

export type VehicleDetailsWidgetType<T> = {
    vehicleDetails?:
        | Partial<VehicleModel>
        | { vehicleConfigurationV2: ConfigurationV2 | { distribution: string; id?: number } };
    tyreDetails?: T;
    isLoadingVehicle?: boolean;
};

const AtomInstance = <T>(defaultValue: T, defaultKey: string, key?: string): RecoilState<T> => {
    if (key && !refreshVehicleAtoms[key]) {
        refreshVehicleAtoms[key] = atom<T>({
            key: key,
            default: defaultValue
        });
    } else if (!key && !refreshVehicleAtoms[defaultKey]) {
        refreshVehicleAtoms[defaultKey] = atom<T>({
            key: defaultKey,
            default: defaultValue
        });
    }

    return refreshVehicleAtoms[key ?? defaultKey];
};

export const VehicleDetailsWidgetAtom = <R = TyreDetailResponse>(vehicleDetailKey?) =>
    AtomInstance<VehicleDetailsWidgetType<R>>(
        {
            isLoadingVehicle: false
        },
        AtomsKey.VEHICLE_DETAILS_ATOM,
        vehicleDetailKey
    );

export const HasInternalSensorAtom = (internalSensorKey?) =>
    AtomInstance<boolean>(false, AtomsKey.INTERNAL_SENSOR_ATOM, internalSensorKey);

export const FetchVehicleDetailsData = atom<boolean>({
    key: 'FetchVehicleDetailsData',
    default: false
});
