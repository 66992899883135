import React from 'react';
import { VehicleSchemaType } from './VehicleSchema.type';
import { VehicleSchemaView } from './VehicleSchema.view';
import ExternalSystemMddBesTyreLog from '../../../api/ExternalSystemMddBesTyreLog';
import { useQuery } from '@tanstack/react-query';
import { BesAtom } from './BesAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';

const externalSystemMddBesTyreLog = new ExternalSystemMddBesTyreLog();

const VehicleSchema: React.FC<VehicleSchemaType> = ({
    schema,
    vehicleId,
    emptySchema,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    highlightColor,
    highlightWheels
}): JSX.Element => {
    const setBesAtom = useSetRecoilState(BesAtom);
    const customerSettings = useRecoilValue(CustomerSettings);

    useQuery(
        ['GetBESDataFroSchema', vehicleId, customerSettings.mdd_bes],
        () => externalSystemMddBesTyreLog.getTyreLogDetailByVehicle(vehicleId || 0),
        {
            enabled: !!vehicleId && customerSettings.mdd_bes,
            onSuccess: (data) => setBesAtom(data)
        }
    );

    return (
        <VehicleSchemaView
            vehicleAtomId={vehicleAtomId}
            schema={schema.split('-')}
            emptySchema={emptySchema}
            allowDisplaySelectedTyre={allowDisplaySelectedTyre}
            allowSelectTyre={allowSelectTyre}
            highlightColor={highlightColor}
            highlightWheels={highlightWheels}
        />
    );
};

export default VehicleSchema;
