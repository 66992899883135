import React from 'react';
import { useRecoilValue } from 'recoil';
import { UiTableActionViewContent } from './UiTableAction.style';
import { UiTableActionProps } from './UiTableAction.type';
import { SelectRow } from '../UiTable/TableAtom';
import { getParams } from 'helpers';
import { TableAtom } from 'states/component/Table';

const UiTableActionContent: React.FC<UiTableActionProps> = (props): JSX.Element => {
    const selectRow = useRecoilValue(SelectRow);
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));

    return (
        <UiTableActionViewContent data-testid='UiTableActionContent'>
            {props.actionBtns &&
                props.actionBtns(
                    selectRow.selectedAll,
                    selectRow.selectedRows,
                    getParams(tableState),
                    tableState.totalCount
                )}
        </UiTableActionViewContent>
    );
};

export default UiTableActionContent;
