import React from 'react';
import { HubNotifyViewContent } from './HubNotify.style';
import { HubNotifyViewProps } from './HubNotify.type';
import GlobalAdminNotifyTable from '../../components/Hub/GlobalAdminNotifyTable/GlobalAdminNotifyTable';

export const HubNotifyContent: React.FC<HubNotifyViewProps> = (): JSX.Element => {
    return (
        <HubNotifyViewContent data-testid='HubNotifyContent'>
            <GlobalAdminNotifyTable />
        </HubNotifyViewContent>
    );
};
