import React from 'react';
import {
    AvatarCustom,
    CardActionsCustom,
    CardContentCustom,
    CardCustom,
    DeleteButton,
    TypographyCustom
} from './UserCard.style';
import { UserCardProps } from './UserCard.type';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { useTranslation } from 'react-i18next';

const UserCard: React.FC<UserCardProps> = ({
    alertNotificationUser,
    removeAlertNotificationCallsUser
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <CardCustom>
            <CardContentCustom>
                <AvatarCustom>{alertNotificationUser.username?.charAt(0)}</AvatarCustom>
                <TypographyCustom gutterBottom>{alertNotificationUser.username}</TypographyCustom>
            </CardContentCustom>
            <CardActionsCustom>
                <DeleteButton
                    form='tyre-action-form'
                    skin='error'
                    color='error'
                    testid='AddAlertNotificationManagment-delete-alertNotificationCallsUser-button'
                    variant={VARIANT_BUTTON}
                    type='submit'
                    onClick={() => {
                        removeAlertNotificationCallsUser(alertNotificationUser);
                    }}
                >
                    {translate('t.delete')}
                </DeleteButton>
            </CardActionsCustom>
        </CardCustom>
    );
};

export default UserCard;
