import React from 'react';
import { SeverityRankTopbarContent } from './SeverityRankTopbar.view';
import { SeverityRankTopbarProps } from './SeverityRankTopbar.type';
import { Wrapper } from 'helpers/wrapper';

const SeverityRankTopbar: React.FC<SeverityRankTopbarProps> = (): JSX.Element => {
    return <SeverityRankTopbarContent data-testid={'SeverityRankTopbar-testid'} />;
};

export default Wrapper(SeverityRankTopbar);
