import { Alert, Box, Paper, Theme, Typography } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { ThemeMode } from 'states/global/Theme';

export const AlertsAffectedVehiclesListViewContent = styleMui(Box)((props: { height?: string | number }) => ({
    display: 'flex',
    flexDirection: 'row',
    minHeight: props.height || 330,
    marginTop: 5,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    position: 'relative'
}));

export const SchemaBox = styleMui(Box)({
    width: 110,
    height: 90
});

export const SchemaContent = styleMui(Box)({
    width: 110,
    height: 110,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: 10,
    margin: '10px 13px'
});

export const AlertInfo = styleMui(Alert)({
    width: '100%',
    minHeight: 36,
    padding: '0 16px'
});

export const VehicleListContainer = styleMui(Paper)((props: { height?: string | number }) => ({
    height: props.height || 330,
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    overflowY: 'scroll'
}));

export const VehicleLabel = styleMui(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    width: 100,
    minHeight: 20
});

export const LoadingSchema = styleMui('div')((props: { show: boolean; theme: Theme }) => ({
    width: '100%',
    display: props.show ? 'grid' : 'none',
    height: '330px',
    position: 'absolute',
    backgroundColor: props.theme.palette.mode === ThemeMode.dark ? '#0e12176e' : '#ffffff85'
}));

export const LoadingSchemaCover = styleMui(Box)({
    height: '100%',
    width: '100%'
});
