import React from 'react';
import { HistoryPlacementTableContainer } from './HistoryPlacementTable.style';
import { HistoryPlacementTableViewProps } from './HistoryPlacementTable.type';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Device from 'api/Device';
import UiTable from 'components/Ui/Components/UiTable2/UiTable';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import { useRecoilValue } from 'recoil';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

const historyPlacementHubApi = new Device();

export const HistoryPlacementTableContent: React.FC<HistoryPlacementTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <HistoryPlacementTableContainer data-testid='HistoryPlacementTableContent'>
            <Widget
                data-testid='HistoryPlacementTableContentUi'
                avatar={
                    <UiIcon
                        icon={faEdit}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.history_placement')}
                loading={false}
                content={
                    <UiTable
                        maxHeight={450}
                        columns={props.columns}
                        fetchFn={historyPlacementHubApi.getHistoryPlacementHubTable}
                        additionalProps={{ deviceId: props.deviceId }}
                        refresh={false}
                        hiddenColumns={[]}
                        queryKey={`historyPlacementTableHub-${props.deviceId}`}
                        hiddenFilterAction={true}
                        defaultSortBy={{
                            id: 'createdAt',
                            desc: true
                        }}
                    />
                }
            ></Widget>
        </HistoryPlacementTableContainer>
    );
};
