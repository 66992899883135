import React from 'react';
import { ExtraFilterViewContent } from './ExtraFilter.style';
import { ExtraFilterProps } from './ExtraFilter.type';
import { FormControlLabel, Switch } from '@mui/material';
import { useRecoilState } from 'recoil';
import { TableActions, TableAtom, TableDispatcher } from '../../../../../states/component/Table';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from '../../../../CustomHooks/UserLocalStorageSettings';
import { FilterProps } from '../UiTable.type';

const ExtraFilter: React.FC<ExtraFilterProps> = (props): JSX.Element => {
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.tableKey}-Table`));
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.tableKey}Table`]);

    const isChecked = (): boolean => {
        if (tableState.queryPageFilter.length) {
            const exist = tableState.queryPageFilter.filter((filter) => filter.id === props.extraFilter.id);

            return !!exist.length;
        }
        return false;
    };

    const setValue = (check): void => {
        let value: FilterProps | undefined = undefined;
        let hiddenColumns = getUserSettingTable(`table.${props.tableKey}Table`)
            ? getUserSettingTable(`table.${props.tableKey}Table`)?.hiddenColumns || []
            : [];
        let filters = getUserSettingTable(`table.${props.tableKey}Table`)
            ? getUserSettingTable(`table.${props.tableKey}Table`)?.filter || []
            : [];

        if (check) {
            value = props.extraFilter;
            tableDispatch({
                type: TableActions.PAGE_FILTER_CHANGED,
                payload: [...tableState.queryPageFilter, value]
            });
            filters = [...filters, value];
        } else {
            if (tableState.queryPageFilter.length) {
                tableDispatch({
                    type: TableActions.PAGE_FILTER_CHANGED,
                    payload: tableState.queryPageFilter.filter((filter) => filter.id != props.extraFilter.id)
                });
                filters =
                    filters && !!filters.length
                        ? filters.filter((filter) => filter.id != props.extraFilter.id)
                        : filters;
            }
        }

        setUserSettings(`table.${props.tableKey}Table`, {
            filter: filters,
            pageSize: tableState.queryPageSize,
            hiddenColumns: hiddenColumns,
            orderBy: getUserSettingTable(`table.${props.tableKey}Table`)
                ? getUserSettingTable(`table.${props.tableKey}Table`)?.orderBy
                : []
        });
    };

    return (
        <ExtraFilterViewContent data-testid='ExtraFilterByTable'>
            <FormControlLabel
                value={props.extraFilter.value}
                labelPlacement='start'
                control={
                    <Switch
                        color={props.color || 'primary'}
                        checked={isChecked()}
                        value={props.extraFilter.value}
                        data-testid={props.testid || 'ExtraFilter-Switch'}
                        name='targetPressure'
                    />
                }
                label={props.extraFilter.value.name}
                onChange={(e) => {
                    setValue((e.target as HTMLInputElement).checked);
                }}
            />
        </ExtraFilterViewContent>
    );
};

export default ExtraFilter;
