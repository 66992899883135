import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const AddAreaContainer = styled.div``;

export const ButtonsContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CustomTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin: 8px;
        margin-left: 0px;
        && label {
            padding: 0 5px 0 0;
            background: #fff;
        }
        & .MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.9);
        }
        & .Mui-disabled:not(.MuiInputLabel-shrink) {
            color: #3a3633;
        }
        & svg {
            fill: #999999;
        }
    }
`;

export const ColorPreview = styled.span<{ color: string }>`
    width: 10px;
    height: 10px;
    display: inline-block;
    background: ${(props) => props.color || 'red'};
`;

export const FormGroupLabel = styled.span`
    position: absolute;
    margin-top: -21px;
    margin-left: -2px;
    background: #fff;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.54);
`;

export const FormGroupContent = styled.div`
    border: thin #bfbfbf solid;
    border-radius: 4px;
    position: relative;
    padding-bottom: 20px;
    margin-top: 10px;
    padding: 10px;
`;

export const OverviewTable = styled.table`
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.00938em;
`;
