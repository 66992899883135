import styled from 'styled-components';
import { AlertFontSize } from '../../Ui/Theme';
import { Autocomplete, CircularProgress, IconButton, TextField } from '@mui/material';
import { PRIMARY } from '../../Ui/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UiButton from '../../Ui/Components/UiButton';
import { applyStyleByMode } from 'helpers';

export const ContainerForm = styled.div`
    ${applyStyleByMode({
        styleJade: `
           padding-top: 8px;
        `
    })}
`;

export const BugReporterButton = styled(IconButton)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiIconButton-root {
                color: ${PRIMARY};
                background-color: rgb(242 242 242);
                border-radius: 2px;
                box-shadow: none;
                border: thin solid #bfbfbf;
                height: 30px;
                width: 30px;
                top: 10px;
                && .MuiBadge-badge {
                    font-size: ${AlertFontSize};
                }
            }
            &.MuiIconButton-root:hover {
                color: ${PRIMARY};
                background-color: rgb(242 242 242);
            }
        `
    })}
`;

export const TitleTextField = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTextField-root {
                && label {
                    padding: 0 5px 0 0;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.8);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: #3a3633;
                }
                & svg {
                    fill: #999999;
                }
            }
        `,
        styleJade: `
            margin-bottom: 10px;
        `
    })}
`;

export const DescriptionTextField = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
        &.MuiTextField-root {
            && label {
                padding: 0 5px 0 0;
            }
            & .MuiInputLabel-shrink {
                transform: translate(14px, -6px) scale(0.8);
            }
            & .Mui-disabled:not(.MuiInputLabel-shrink) {
                color: #3a3633;
            }
            & svg {
                fill: #999999;
            }
            & textarea {
                height: 80px !important;
            }
        }`
    })}
`;

export const CustomAutocomplete = styled(Autocomplete)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiAutocomplete-root {
                width: 100%;
                && label {
                    padding: 0 5px 0 0;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.8);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: ${PRIMARY};
                }
                &&& .MuiAutocomplete-popupIndicator {
                    padding: 5px;
                    margin-right: 2px;
                }
            }
        `,
        styleJade: `
            margin-bottom: 10px;
            &&& .MuiAutocomplete-popupIndicator {
                padding: 5px;
                margin-right: 2px;
            }
        `
    })}
`;

export const LegendHeaderIcon = styled(FontAwesomeIcon)`
    ${applyStyleByMode({
        styleOld: `
            padding-right: 5px;
        `
    })}
`;

export const Button = styled(UiButton)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiButtonBase-root {
                height: 30px;
                width: 150px;
            }
        `
    })}
`;

export const LoadingButton = styled(CircularProgress)`
    &.MuiCircularProgress-root {
        color: #474544;
        height: 24px !important;
        width: 24px !important;
    }
`;

export const RequestSupport = styled.div`
    ${applyStyleByMode({
        styleOld: `
            color: #c0bbb7;
            line-height: normal;
            font-size: 14px;
            padding: 3px 10px 7px 3px;
            font-weight: 400;
            position: relative;
            cursor: pointer;
            display: flex;
            :hover {
                color: #fff;
            }
        `
    })}
`;
