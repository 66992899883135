import { atom } from 'recoil';

type BesLabelHoverAtomType = {
    x?: number;
    y?: number;
    text?: string;
    display: boolean;
};

export const BesLabelHoverAtom = atom<BesLabelHoverAtomType>({
    key: 'BesLabelAtom',
    default: {
        display: false
    }
});
