import React from 'react';
import UsersAdministration from '../../components/User/UsersAdministration';
import { GetRolesAvailableCodebookQuery } from '../../components/Queries/Roles/Roles';

export const UsersAdministrationContent: React.FC<{}> = (): JSX.Element => {
    return (
        <GetRolesAvailableCodebookQuery>
            {({ data: roleData }) => <UsersAdministration role={roleData} />}
        </GetRolesAvailableCodebookQuery>
    );
};
