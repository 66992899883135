import React from 'react';
import { CustomerReportProps } from './CustomerReport.type';
import CustomerReportAPI from 'api/CustomerReport';
import { CustomerReportModel } from 'models/CustomerReport.type';
import { useQuery } from '@tanstack/react-query';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const customerReportAPI = new CustomerReportAPI();

const GetCustomerReportData = ({ customerId, options, children }: CustomerReportProps): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<CustomerReportModel>(
        ['GetSystemTotals', userInfo.user?.customer.id],
        () => customerReportAPI.getById({ id: customerId }),
        options
    );

    return <>{children(useQueryProperties)}</>;
};

export default GetCustomerReportData;
