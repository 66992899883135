import styled from 'styled-components';
import { Button, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const TyreTableViewContent = styled.div``;

export const ActionButton = styled(Button)`
    min-width: 20px !important;
    max-width: 20px !important;
`;

export const CustomLabel = styled.span`
    padding: 0 5px !important;
    ${() =>
        applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background: #fff !important;',
            dark: 'background: transparent !important;'
        })}

    transform: translate(14px, -6px) scale(0.9) !important;
`;

export const ContainerExtraFilter = styled.span`
    padding-right: 7px;
`;
