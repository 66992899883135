import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import { ERROR, PRIMARY } from '../Ui/colors';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleMapPopupAlertsContent = styled.div`
    min-width: 330px;
`;

export const AlertContent = styled.div<{ level: number }>`
    text-align: left;
    margin-bottom: 15px;
    background-image: ${(props) => {
        switch (props.level) {
            case 0:
                return 'transparent';
            case 1:
                return 'linear-gradient(to right, rgb(255, 237, 158) 9%, rgb(255, 251, 233) 62%)';
            case 2:
                return 'linear-gradient(to right, rgb(255, 208, 163) 9%, rgb(255, 232, 209) 62%)';
            case 3:
                return 'linear-gradient(to right, rgb(255, 159, 159) 9%, rgb(255, 204, 204) 62%)';
        }
    }};
    color: ${PRIMARY};
    border-radius: 4px;
    padding: 10px;
    min-height: 100px;
    padding-bottom: 5px;

    table {
        font-size: 0.875rem;
    }
`;

export const CustomChip = styled(Chip)<{ level: number }>`
    background-color: ${(props) => {
        switch (props.level) {
            case 0:
                return 'inherit';
            case 1:
                return 'rgb(159, 148, 21)';
            case 2:
                return 'rgb(205, 110, 10)';
            case 3:
                return 'rgb(224, 0, 0)';
        }
    }} !important;
    color: #fff !important;
    height: 20px !important;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize} !important;`;
    }}
    span {
        padding: 0 5px;
    }
`;

export const AlertHeader = styled.div`
    text-align: left;
    h6:first-of-type {
        margin: 0;
    }
    color: ${PRIMARY};
`;

export const varianceTr = styled.tr`
    color: ${ERROR};
`;

export const ExclamationIcon = styled(PriorityHighIcon)<{ level: number }>`
    font-size: 3rem !important;
    color: ${(props) => {
        switch (props.level) {
            case 0:
                return 'inherit';
            case 1:
                return 'rgb(159, 148, 21)';
            case 2:
                return 'rgb(205, 110, 10)';
            case 3:
                return 'rgb(224, 0, 0)';
        }
    }};
`;

export const AlertFooter = styled.div`
    text-align: right;
    font-size: 0.875rem;
`;
