import React from 'react';
import { UiScrollProps } from './UiScroll.type';
import { UiScrollCustom } from './UiScroll.style';

const UiScroll: React.FC<UiScrollProps> = ({ content, children, height, maxHeight, unit }): JSX.Element => {
    return (
        <UiScrollCustom data-testid='UiScroll-testid' $height={height} $maxHeight={maxHeight} unit={unit}>
            <>{content || children}</>
        </UiScrollCustom>
    );
};

export default UiScroll;
