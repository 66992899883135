import React from 'react';
import Button from '../Ui/Components/UiButton';
import { Box } from '@mui/material';

type Messages = {
    content: string;
    title: string;
};

export type MessageType = 'UNSAVED_CHANGES' | 'DELETE_ITEM';

const getMessages = (messageType: MessageType): Messages => {
    switch (messageType) {
        case 'UNSAVED_CHANGES': {
            return {
                content: 'p.unsaved_changes',
                title: 't.unsaved_changes'
            };
        }
        case 'DELETE_ITEM': {
            return {
                content: 'p.are_you_sure_to_delete',
                title: 't.remove'
            };
        }
    }
};

type CreateConfirmtionMessageProps = {
    translate: any;
    onClickYes: () => void;
    onClickNo: () => void;
    messageType: MessageType;
    butttonTextYes?: string | JSX.Element;
    disabledButttonYes?: boolean;
};

export const CreateConfirmtionMessage = ({
    translate,
    onClickNo,
    onClickYes,
    messageType,
    butttonTextYes,
    disabledButttonYes
}: CreateConfirmtionMessageProps) => {
    const messages = getMessages(messageType);
    return {
        leftTitle: translate(messages.title) + ' ',
        rightTitle: '',
        content: (
            <Box component='div' marginBottom='20px' marginTop='20px'>
                {translate(messages.content)}
            </Box>
        ),
        width: 400,
        height: 100,
        buttons: (
            <>
                <Button
                    color='error'
                    variant='contained'
                    disabled={disabledButttonYes}
                    onClick={onClickYes}
                    skin={'error'}
                >
                    {butttonTextYes ? butttonTextYes : translate('t.yes')}
                </Button>
                <Button color='primary' variant='contained' onClick={onClickNo}>
                    {translate('t.no')}
                </Button>
            </>
        ),
        replaceButtons: true,
        onOpen: () => {},
        onClose: () => {},
        id: 'confirmation-message',
        widthUnit: 'px',
        heightUnit: 'px',
        isOpen: true
    };
};
