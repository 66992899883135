import { Paper } from '@mui/material';
import styled from 'styled-components';

export const CustomerTableContainer = styled.div``;

export const Settings = styled(Paper)`
    &.MuiPaper-root {
        margin-top: 8px;
    }
`;

export const SettingsContent = styled('div')``;
