import React, { useEffect, useState } from 'react';
import { GpsIconProps, GpsIndicatorProps } from './GpsIndicator.type';
import { GpsIndicatorContent } from './GpsIndicator.style';
import { getGpsIconValues } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme, ThemeMode } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';

const GpsIndicator: React.FC<GpsIndicatorProps> = (props): JSX.Element => {
    const [noGpsIcon, setNoGpsIcon] = useState<GpsIconProps>({
        show: false,
        color: 'red'
    });
    const ThemeState = useRecoilValue(Theme);

    useEffect(() => {
        setNoGpsIcon(getGpsIconValues({ ...props }));
    }, [props]);

    if (!noGpsIcon.show) {
        return <></>;
    }

    const getColorJade = (color): string => {
        if (color === 'red' && ThemeState?.mode === ThemeMode.dark) {
            return '#fd79a8';
        }
        if (color === 'blue' && ThemeState?.mode === ThemeMode.dark) {
            return '#0984e3';
        }
        if (color === 'orange' && ThemeState?.mode === ThemeMode.dark) {
            return '#c15208';
        }
        return color;
    };

    return (
        <GpsIndicatorContent data-testid='GpsIndicatorContent'>
            <UiIcon icon={['fas', 'map-marker-alt-slash']} size='sm' color={getColorJade(noGpsIcon.color)} />
        </GpsIndicatorContent>
    );
};

export default GpsIndicator;
