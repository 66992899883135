import { LoginProps } from 'pages/Login/Login.type';
import PortalApi from 'helpers/api/PortalApiClient';

export default class Login extends PortalApi<LoginProps> {
    route = 'login_check';

    getHelpJuice = (data): Promise<{ token: string; service: string }> => {
        this.route = 'login_check?service=helpjuice';
        return this.post({ data, version: '', apiProject: '' }).then((data: any) => data.data);
    };
}
