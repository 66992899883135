import React from 'react';
import { ListSubscribedUsersContainer, UserList } from './ListSubscribedUsers.style';
import { ListSubscribedUsersPropsView } from './ListSubscribedUsers.type';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const ListSubscribedUsersContent: React.FC<ListSubscribedUsersPropsView> = ({
    data,
    isLoading
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <ListSubscribedUsersContainer data-testid='ListSubscribedUsersContent'>
            {isLoading ? (
                <UiLoadingPage size='30px' />
            ) : (
                <UserList>
                    {data && !!data.subscribed.user.length ? (
                        data?.subscribed.user.map((user) => {
                            return (
                                <ListItem component='p' key={user.id}>
                                    <ListItemText
                                        key={user.username}
                                        primary={`${user.firstname} ${user.lastname} | ${user.username}`}
                                    />
                                </ListItem>
                            );
                        })
                    ) : (
                        <Typography align={'center'}>{translate('t.no_subscribed_user')}</Typography>
                    )}
                </UserList>
            )}
        </ListSubscribedUsersContainer>
    );
};
