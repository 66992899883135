import React, { useEffect, useState } from 'react';
import { TopBarTerminalProps, CommandPropsToSubmit } from './TopBarTerminal.type';
import { TopBarHistoryContent } from './TopBarTerminal.view';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RefreshTable } from '../../../../Ui/Components/UiTable2/UiTable.atom';
import { Success } from '../../../../Popup/Popup';
import DeviceCommand from '../../../../../api/DeviceCommand';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { optionsCodeBook } from '../../../../Ui/Components/UiDropDown/UiDropDown.type';
import { UserInfo } from '../../../../../states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import DeviceApi from '../../../../../api/Device';

const deviceCommandApi = new DeviceCommand();
const Device = new DeviceApi();

const TopBarTerminal: React.FC<TopBarTerminalProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('deviceCommandsTableTerminal-RefreshTableAtom'));
    const [commandsCodebook, setCommandsCodebook] = useState<optionsCodeBook[]>();
    const [commandsAvailable, setCommandsAvailable] = useState<boolean>(true);

    const parseData = (data): optionsCodeBook[] => {
        return data.deviceCommand.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({
                id: curr.name,
                name: `${translate(`device.cmd.${curr.name}`)} (CMD ${curr?.command})`
            });
            return acc;
        }, []);
    };

    const handleSubmit = (id: string, value?: string) => {
        let commandProps: CommandPropsToSubmit = {
            ...commandsData?.deviceCommand.find((element) => element.name === id),
            value: null
        };
        if (value) {
            commandProps.value = value;
        }

        if (props.sendCommandProps?.filters || props.sendCommandProps?.selectedRows) {
            if (props.sendCommandProps?.selectedRows) {
                commandProps = { ...commandProps, deviceIds: props.sendCommandProps?.selectedRows };
            }
            executeAdminCommandMutation({ ...commandProps, criteria: props.sendCommandProps?.filters });
        } else {
            executeCommandMutation({
                ...commandProps,
                deviceIds: [props.deviceId]
            });
        }
    };

    const {
        data: commandsData,
        isLoading: commandsLoading,
        isFetching: commandsFetching,
        refetch: refetchDeviceCommands
    } = useQuery(['DeviceCommandsQuery'], () => deviceCommandApi.getAvailableDeviceCommand(), {
        enabled: false,
        retry: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess: (dataOnSuccess) => setCommandsCodebook(parseData(dataOnSuccess))
    });

    const { mutate: executeAdminCommandMutation } = useMutation(
        ['ExecuteAdminCommandMutation'],
        Device.sendGlobalHubCommand,
        {
            onSuccess() {
                Success({
                    text: translate('p.command_sent'),
                    customSetting: {
                        position: 'bottom-right',
                        duration: 3000
                    }
                });
            }
        }
    );

    const { mutate: executeCommandMutation } = useMutation(
        ['ExecuteCommandMutation'],
        deviceCommandApi.postExcuteCommand,
        {
            onSuccess: () => {
                Success({
                    text: translate('p.command_sent'),
                    customSetting: {
                        position: 'bottom-right',
                        duration: 3000
                    }
                });
            },
            onSettled: () => {
                setRefreshTableAtom(true);
            }
        }
    );

    useEffect(() => {
        if (checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN])) {
            refetchDeviceCommands();
        } else {
            setCommandsAvailable(true);
        }
    }, [userInfo.user]);

    useEffect(() => {
        if (commandsFetching || commandsLoading) setCommandsAvailable(true);
        else if (!commandsFetching && !commandsLoading) setCommandsAvailable(false);
    }, [commandsFetching, commandsLoading]);

    return (
        <TopBarHistoryContent
            {...props}
            commandsCodebook={commandsCodebook ?? []}
            commandsRaw={commandsData?.deviceCommand ?? []}
            data-testid='TopBarTerminal-testid'
            handleSubmit={handleSubmit}
            commandsAvailable={commandsAvailable}
        />
    );
};

export default TopBarTerminal;
