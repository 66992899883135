import React from 'react';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { VariantRegularTypes } from 'components/Ui/Components/UiButton/UiButton.type';
import { RankApplyButtonViewProps } from './RankApplyButton.type';
import { SUCCESS } from 'components/Ui/colors';
import { useTranslation } from 'react-i18next';

export const RankApplyButtonContent: React.FC<RankApplyButtonViewProps> = ({
    isButtonDisabled,
    onApplyAction
}): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <Button
            disabled={isButtonDisabled()}
            onClick={onApplyAction}
            variant={VariantRegularTypes.CONTAINED}
            skin={SUCCESS}
        >
            {translate('t.apply')}
        </Button>
    );
};
