import { HistoryLog } from '../../../models/Tyre.type';
import { Filterlevel } from './Components/AlertsLevelPopUp/AlertsLevelPopUp.type';

export type LifespanReportHistoryProps = {
    tyreId: number;
};

export type LifespanReportHistoryViewProps = {
    isLoading: boolean;
    tyreLifeHistory: HistoryLog[];
    timeLineCell: (index, date, description, hasConnetor, type, aditionalInfo?) => JSX.Element;
    handleScroll: (event) => void;
    AddFilterAlertByLevel: (level: number) => void;
    RemoveFilterAlertByLevel: (level: number) => void;
    valueIndex: number;
    level: Filterlevel[] | null;
};

export enum AditionalInfoEnum {
    VEHICLE_SCHEMA = 'VEHICLE_SCHEMA',
    IMAGE = 'IMAGE'
}

export enum TypeInfoEnum {
    ALERT = 'alert',
    REACHED_DISTANCE = 'reached_distance',
    MOVEMENT = 'movement',
    MDD_BES = 'mdd_bes'
}
