import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { AlertTemperatureSliderViewContent, TemperatureSlider } from './AlertTemperatureSlider.style';
import { AlertTemperatureSliderProps, Mark } from './AlertTemperatureSlider.type';
import { TemperatureSliderValue } from './atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Skeleton, useTheme } from '@mui/material';
import { RULE_TYPE } from 'models/Rule.type';
import { IsLoadingAlertSettings } from '../AlertSettings/atom';
import { isSuperOrAdmin } from 'states/global/User';

const AlertTemperatureSliderContent: React.FC<AlertTemperatureSliderProps> = (props): JSX.Element => {
    const { fromServerToUserUnit, convertType } = useConverter();
    const MIN = +fromServerToUserUnit({ type: convertType.temperature, value: 50, fixed: 0, displayUnits: false });
    const MAX = +fromServerToUserUnit({ type: convertType.temperature, value: 100, fixed: 0, displayUnits: false });
    const [sliderValue, setSliderValue] = useRecoilState(TemperatureSliderValue);
    const resetSliderValue = useResetRecoilState(TemperatureSliderValue);
    const [perc, setPerc] = useState<[number, number, number]>([0, 0, 0]);
    const theme = useTheme();
    const isLoadingAlertSettings = useRecoilValue(IsLoadingAlertSettings);
    const isAdmin = useRecoilValue(isSuperOrAdmin);

    const onChange = (e, values) => {
        setSliderValue(values);
    };

    const marks = useMemo(() => {
        let marks: Mark[] = [];
        const step = (MAX - MIN) / 50;
        for (let i = MIN; i < MAX + 1; i += Math.ceil(step)) {
            if (i % MIN === 0 || i % MAX === 0) {
                marks.push({
                    label: i,
                    value: i
                });
            } else {
                marks.push({
                    label: '|',
                    value: i
                });
            }
        }
        return marks;
    }, []);

    useEffect(() => {
        return () => {
            resetSliderValue();
        };
    }, []);

    useEffect(() => {
        setPerc(
            sliderValue.map((val) => {
                return ((val - MIN) * 100) / (MAX - MIN);
            }) as [number, number, number]
        );
    }, [sliderValue]);

    return (
        <AlertTemperatureSliderViewContent data-testid='AlertTemperatureSliderContent'>
            {isLoadingAlertSettings ? (
                <Skeleton variant='rectangular' width='100%' height={50} />
            ) : (
                <TemperatureSlider
                    aria-label='Temperature Slider'
                    valueLabelDisplay='on'
                    value={sliderValue}
                    theme={theme}
                    onChange={onChange}
                    min={MIN}
                    max={MAX}
                    disabled={!isAdmin}
                    percentage={perc}
                    step={1}
                    marks={marks}
                    data-testid='AlertTemperatureSlider'
                    isWizard={props.ruleType === RULE_TYPE.WIZARD || props.ruleType === RULE_TYPE.DEFAULT}
                />
            )}
        </AlertTemperatureSliderViewContent>
    );
};

export default AlertTemperatureSliderContent;
