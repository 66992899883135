import { Popover } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { ThemeMode } from '../../../../states/global/Theme';
import {
    DARK_STEEL_GREY,
    BACKGROUND_BLUE,
    BACKGROUND_GREY,
    PRIMARY,
    PRIMARY_SEMI_LIGHT,
    SECONDARY,
    WHITE
} from '../../colors';
import UiScroll from '../UiScroll/UiScroll';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiLegendViewContent = styled.div`
    display: inline-block;
`;

export const Legend = styled(Popover)<{
    width?: number;
}>`
    &.MuiPopover-root {
        color: ${PRIMARY};
        & p {
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `
                    font-size: ${common.titleSize};
                `;
            }}
        }

        & .MuiPaper-root {
            min-width: 250px !important;
            ${({ width }) => {
                return `
                    width: ${width ? `${width}px` : 'auto'};
                    max-width: ${width ? `${width}px` : 'auto'};
                `;
            }}

            ${() => useTheme().breakpoints.between('xs', 'md')} {
                width: calc(100vw - 31px);
            }
        }
        & .legend-header {
            color: ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : DARK_STEEL_GREY)};
            padding: 8px 0px 4px 0px !important;
            border-bottom: solid 1px ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_GREY : SECONDARY)};
            line-height: 2;
            min-height: 25px;
            width: 100% !important;
            display: inline-flex !important;
            font-weight: 600;
            align-items: center;

            button {
                color: ${() => (useTheme().palette.mode === ThemeMode.dark ? SECONDARY : PRIMARY_SEMI_LIGHT)};
                font-size: 0.875rem;
                margin-left: auto;
            }
            button:hover {
                background: ${() =>
                    useTheme().palette.mode === ThemeMode.dark ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'};
                color: ${() => (useTheme().palette.mode === ThemeMode.dark ? '#fff' : PRIMARY)};
                transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
            }
        }
        & .legend-body {
            padding: 10px !important;
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `font-size: ${common.textSize};`;
            }}
            min-height: auto;
            color: ${() => (useTheme().palette.mode === ThemeMode.dark ? WHITE : PRIMARY)};
        }
    }
`;

export const HeaderLegend = styled.div`
    text-align: center;
    padding: 0px 10px;
`;

export const UiScrollCustom = styled(UiScroll)<{ $minWidth?: number }>`
    min-width: ${(props) => (props.$minWidth ? `${props.$minWidth}px` : '250px')};
    width: ${(props) => (props.$minWidth ? `${props.$minWidth}px` : 'auto')};
    padding: 0px 7px;
    max-height: calc(100vh - 100px);
`;
