import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import HideColumns from './HideColumns';
import IconButton from '@mui/material/IconButton';
import FilterColumns from './FilterColumns';
import ChipBar from './ChipBar';
import { useTranslation } from 'react-i18next';
import { ContentCopy, Download, Print } from '@mui/icons-material';
import { CircularProgress, Collapse, Grid } from '@mui/material';
import {
    ContainerToPrint,
    CustomHeaderCell,
    CustomHeaderRow,
    CustomTableRow,
    EmptyTable,
    ExportDataButton,
    ResizeSeparator,
    SpinningRefreshButton,
    Table,
    TableContainerCustom,
    UiTableViewContent
} from './UiTable.style';
import { UiDownloadActions, UiTableViewProps } from './UiTable.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TableAtom } from '../../../../states/component/Table';
import { RefreshTable } from './UiTable.atom';
import { SIZE_BUTTON } from '../../variables';
import UiMenu from '../UiMenu/UiMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from '../../../CustomHooks/UserLocalStorageSettings';
import { TableInstance } from 'react-table';
import { ColumnInstance } from 'react-table';

const GenerateDownloadActions: React.FC<UiDownloadActions> = ({
    loading,
    tableId,
    downloadTableData,
    copyTableData,
    printTable
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <UiMenu
            testid={`Menu-export-${tableId}Table`}
            items={[
                {
                    title: 'CSV',
                    value: 'CSV',
                    iconUI: <Download />,
                    actionGeneral: () => downloadTableData()
                },
                {
                    title: translate('t.copy'),
                    value: 'copy',
                    iconUI: <ContentCopy />,
                    actionGeneral: () => copyTableData()
                },
                {
                    title: translate('t.print'),
                    value: 'print',
                    iconUI: <Print />,
                    actionGeneral: () => printTable(false)
                }
            ]}
            rootEl={
                <ExportDataButton
                    endIcon={<ExpandMoreIcon />}
                    loading={loading}
                    color='secondary'
                    size={SIZE_BUTTON}
                    variant='outlined'
                    testid={`Menu-Export-${tableId}Table-Button`}
                >
                    {translate('t.export')}
                </ExportDataButton>
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        />
    );
};

export const UiTableContent: React.FC<UiTableViewProps> = (props): JSX.Element => {
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));
    const refreshTable = useSetRecoilState(RefreshTable(`${props.queryKey}-RefreshTableAtom`));
    const { t: translate } = useTranslation();
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.queryKey}Table`]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        props.tableInstance.gotoPage(newPage);
    };

    return (
        <UiTableViewContent data-testid={`${props.queryKey}-UiTableContent`}>
            <Grid container marginBottom={1} spacing={1}>
                <Grid item xs={12} md={props.export ? 9 : 8} sx={{ order: { sm: 2, md: 1 } }}>
                    {props.export && (
                        <>
                            <GenerateDownloadActions
                                downloadTableData={props.downloadTableData}
                                copyTableData={props.copyTableData}
                                printTable={props.setShowScroll}
                                loading={props.isLoadingExportData}
                                tableId={props.queryKey}
                            />
                        </>
                    )}

                    <ChipBar
                        filters={props.tableInstance.state.filters}
                        table={{ ...props.tableInstance, tableKey: props.tableKey }}
                        currentFilters={props.currentFilters}
                        setCurrentFilters={props.setCurrentFilters}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={props.export ? 3 : 4}
                    sx={{
                        textAlign: { sm: 'left', md: 'right' },
                        order: { sm: 1, md: 2 }
                    }}
                >
                    {props.actionsButton}
                    {props.tableAction && (
                        <>
                            <IconButton
                                data-testid={`Refresh-${props.queryKey}Table-Button`}
                                onClick={() => {
                                    refreshTable(true);
                                }}
                                size={SIZE_BUTTON}
                            >
                                <SpinningRefreshButton $spin={props.isFetching} />
                            </IconButton>
                            {!props.hiddenFilterAction && (
                                <FilterColumns
                                    allColumns={props.tableInstance.allColumns}
                                    table={{ ...props.tableInstance, tableKey: props.tableKey }}
                                    currentFilters={props.currentFilters}
                                    setCurrentFilters={props.setCurrentFilters}
                                />
                            )}
                            <HideColumns
                                allColumns={props.tableInstance.allColumns}
                                toggleHideColumn={props.tableInstance.toggleHideColumn}
                                table={{ ...props.tableInstance, tableKey: props.tableKey }}
                                defaultHiddenColumns={props.hiddenColumns}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            {props.loadingUnmount ? (
                <CircularProgress size='1.75em' sx={{ marginLeft: '20px', marginTop: '10px' }} />
            ) : (
                <ContainerToPrint ref={(el) => (props.componentRef.current = el)}>
                    <TableContainerCustom
                        className={`${props.isFetching && 'loading'}`}
                        $maxHeight={props.maxHeight}
                        $minHeight={props.minHeight}
                        $autoMaxHeight={!props.showScroll}
                        id='UiTableView'
                    >
                        <Table
                            data-testid={`Table-${props.queryKey}-testid`}
                            {...props.tableInstance.getTableProps()}
                            size='small'
                        >
                            <TableHead>
                                {props.tableInstance.headerGroups.map((headerGroup, index) => (
                                    <CustomHeaderRow
                                        {...headerGroup.getHeaderGroupProps()}
                                        key={index}
                                        data-testid={`${props.queryKey}-headerRow${index}`}
                                    >
                                        {headerGroup.headers.map((column, index2) => (
                                            <CustomHeaderCell
                                                {...column.getHeaderProps()}
                                                key={index2}
                                                data-testid={`${props.queryKey}-${column.id}-HeaderColumn`}
                                            >
                                                {column.canSort && column.id !== 'selection' ? (
                                                    <TableSortLabel
                                                        active={column.isSorted}
                                                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                        style={column.getSortByToggleProps().style}
                                                        title={column.getSortByToggleProps().title}
                                                        onClick={(e) => {
                                                            (
                                                                column.getSortByToggleProps() as {
                                                                    onClick: (e) => void;
                                                                }
                                                            ).onClick(e);
                                                            setTimeout(() => {
                                                                column.isSortedDesc === undefined
                                                                    ? setUserSettings(`table.${props.tableKey}Table`, {
                                                                          hiddenColumns: getUserSettingTable(
                                                                              `table.${props.tableKey}Table`
                                                                          )
                                                                              ? getUserSettingTable(
                                                                                    `table.${props.tableKey}Table`
                                                                                )?.hiddenColumns || []
                                                                              : [],
                                                                          filter: getUserSettingTable(
                                                                              `table.${props.queryKey}Table`
                                                                          )
                                                                              ? getUserSettingTable(
                                                                                    `table.${props.queryKey}Table`
                                                                                )?.filter || []
                                                                              : [],
                                                                          orderBy: [],
                                                                          pageSize: tableState.queryPageSize
                                                                      })
                                                                    : setUserSettings(`table.${props.tableKey}Table`, {
                                                                          hiddenColumns: getUserSettingTable(
                                                                              `table.${props.tableKey}Table`
                                                                          )
                                                                              ? getUserSettingTable(
                                                                                    `table.${props.tableKey}Table`
                                                                                )?.hiddenColumns || []
                                                                              : [],
                                                                          filter: getUserSettingTable(
                                                                              `table.${props.queryKey}Table`
                                                                          )
                                                                              ? getUserSettingTable(
                                                                                    `table.${props.queryKey}Table`
                                                                                )?.filter || []
                                                                              : [],
                                                                          orderBy: [
                                                                              {
                                                                                  id: column.id,
                                                                                  desc: column.isSortedDesc
                                                                              }
                                                                          ],
                                                                          pageSize: tableState.queryPageSize
                                                                      });
                                                            }, 1000);
                                                        }}
                                                    >
                                                        <>
                                                            {column.Header}
                                                            {column.canFilter ? '' : ''}
                                                        </>
                                                    </TableSortLabel>
                                                ) : (
                                                    <>
                                                        {column.Header}
                                                        {column.canFilter ? '' : ''}
                                                    </>
                                                )}
                                                {column.canResize && (
                                                    <ResizeSeparator
                                                        {...column.getResizerProps()}
                                                        className={`${column.isResizing ? 'isResizing' : ''}`}
                                                    />
                                                )}
                                            </CustomHeaderCell>
                                        ))}
                                    </CustomHeaderRow>
                                ))}
                            </TableHead>
                            <TableBody
                                className='main-table-body'
                                {...props.tableInstance.getTableBodyProps()}
                                data-testid={`${props.queryKey}-TableBody`}
                            >
                                <>
                                    {props.tableInstance.page.map((row, i) => {
                                        props.tableInstance.prepareRow(row);
                                        return (
                                            <React.Fragment key={i + 100}>
                                                <CustomTableRow
                                                    {...row.getRowProps()}
                                                    key={i}
                                                    data-testid={`${props.queryKey}-bodyRow${i}`}
                                                    id={`bodyRowId${i}`}
                                                    $background={(
                                                        row.cells[0]?.column as ColumnInstance<{}> & { BackgroundTr }
                                                    )?.BackgroundTr?.(row.original)}
                                                    $keyRow={i}
                                                >
                                                    {row.cells.map((cell, j) => {
                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps()}
                                                                key={j}
                                                                data-testid={`${props.queryKey}-cellColumn${j}Row${i}`}
                                                            >
                                                                {cell.render('Cell')}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </CustomTableRow>
                                                {props.isExpandable && (
                                                    <CustomTableRow key={`subrow-${i}`}>
                                                        <Collapse in={row.isExpanded} timeout='auto' unmountOnExit>
                                                            {props.expandableContent &&
                                                                props.expandableContent(row.original)}
                                                        </Collapse>
                                                    </CustomTableRow>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                    {tableState.totalCount === 0 && (
                                        <CustomTableRow $notData={true}>
                                            <EmptyTable>{translate('t.there_no_data')}</EmptyTable>
                                        </CustomTableRow>
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainerCustom>
                </ContainerToPrint>
            )}

            <Table>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={
                                (props.tableInstance as TableInstance<{}> & { rowsPerPageOptions }).rowsPerPageOptions
                            }
                            count={tableState.totalCount}
                            labelRowsPerPage={translate('t.entries_per_page')}
                            rowsPerPage={tableState.queryPageSize}
                            page={tableState.queryPageIndex}
                            onPageChange={handleChangePage}
                            sx={{ selectLabel: { display: 'none' } }}
                            onRowsPerPageChange={props.handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </UiTableViewContent>
    );
};

UiTableContent.defaultProps = { tableAction: true };
