import styled from 'styled-components';
import imgSrc from '../../assets/images/exception_404_background.png';

export const CustomerRedirectionContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${imgSrc});
    background-size: cover;
    background-repeat: no-repeat, repeat;
    background-position: center;
`;

export const ContainerLogo = styled.div`
    img {
        max-width: 445px;
    }
`;

export const ContentBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;
