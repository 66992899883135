import React from 'react';
import { useRecoilValue } from 'recoil';
import { BesLabelHoverAtom } from './BesLabelHover.atom';
import { Popover } from '@mui/material';
import useConverter from '../../CustomHooks/Converter/Converter';

import ReactGA from 'helpers/ga';

const gTrackOnMouseEnter = () => {
    ReactGA.event({
        category: 'BES',
        action: 'Mouse Enter',
        label: 'Mouse Enter - BES Rank Vehicle Schema'
    });
};

const BesLabelHover: React.FC = (): JSX.Element => {
    const besLabelHoverAtom = useRecoilValue(BesLabelHoverAtom);
    const { fromUTCtoUserTimezone } = useConverter();

    if (besLabelHoverAtom.display) {
        gTrackOnMouseEnter();
    }
    return (
        <Popover
            open={besLabelHoverAtom.display}
            anchorReference='anchorPosition'
            anchorPosition={{ top: besLabelHoverAtom.y || 0, left: besLabelHoverAtom.x || 0 }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            sx={{
                pointerEvents: 'none'
            }}
        >
            {fromUTCtoUserTimezone({
                displaySeconds: false,
                format: 'dateTime',
                date: besLabelHoverAtom.text
            })}
        </Popover>
    );
};

export default BesLabelHover;
