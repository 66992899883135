import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const JobsTableContainer = styled.div`
    padding-top: 10px;
    th,
    td {
        text-overflow: ellipsis;

        overflow: clip;
    }

    ,
    thead tr {
        ${() =>
            applyStyleByMode({
                theme: useTheme().palette.mode,
                dark: 'background-color: #2b333e;'
            })}
    }
`;

export const ButtonContainer = styled.div`
    margin: 0 3px;
    display: flex;
    p {
        display: flex;
        margin-left: 5px;
    }
`;
