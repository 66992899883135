import { List } from '@mui/material';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';
import styled from 'styled-components';

export const ListSubscribedUsersContainer = styled.div`
    min-height: 150px;
    .MuiTypography-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize} !important;`;
        }}
    }
`;

export const UserList = styled(List)`
    max-height: 230px;
    min-width: 218px;
    overflow-y: auto;
`;
