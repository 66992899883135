import { Box, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { BACKGROUND_BLUE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';

export const AlertsAvailableFiltersListViewContent = styleMui(Box)({
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap'
});

export const AvailableFilterBox = styleMui(Box)((props: { isDisabled: boolean; theme: Theme }) => ({
    minWidth: 150,
    width: '100%',
    height: 35,
    minHeight: 35,
    padding: 15,
    background: props.isDisabled
        ? props.theme.palette.mode === ThemeMode.dark
            ? '#535353'
            : '#979797'
        : BACKGROUND_BLUE,
    display: 'flex',
    alignItems: 'center',
    color: `rgba(255,255,255,${props.isDisabled ? 0.5 : 1})`,
    margin: '3px 0',
    borderRadius: '3px',
    cursor: props.isDisabled ? 'auto' : 'pointer'
}));
