import React from 'react';
import { VehicleDetailsMapViewContainer } from './VehicleDetailsMap.style';
import { VehicleDetailsMapViewProps } from './VehicleDetailsMap.type';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import { useRecoilState } from 'recoil';
import { MapActionsEnum } from 'components/Map/Map.type';
import { MapAction } from 'states/global/Map';
import Map from 'components/Map/Map';

export const VehicleDetailsMapContent: React.FC<VehicleDetailsMapViewProps> = ({ mapHeight }): JSX.Element => {
    const [mapAction, setMapAction] = useRecoilState(MapAction);

    return (
        <VehicleDetailsMapViewContainer data-testid='VehicleDetailsMap2Content'>
            <Widget
                data-testid={'VehicleDetailsMapContent-Widget'}
                marginBottom={false}
                scrollbar={false}
                height={mapHeight}
                $contentHeight={mapHeight}
                applyPadding={false}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit={'px'}
                padding={0}
            >
                <Map disableVehiclePopup data-testid={'VehicleDetailsMapContent-Map'} />
            </Widget>
        </VehicleDetailsMapViewContainer>
    );
};
