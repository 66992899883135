import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '../../components/FontAwesome/fontawesome';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { ContainerLoading } from './wrapper.style';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retryDelay: 5000,
            networkMode: 'offlineFirst'
        },
        mutations: {
            networkMode: 'offlineFirst'
        }
    }
});

export const WrapperApp = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
    const WrapperContentApp = ({ ...props }) => {
        return (
            <ErrorBoundary>
                <RecoilRoot override={false}>
                    <React.Suspense
                        fallback={
                            <ContainerLoading>
                                <UiLoadingPage size='30px' $marginTop='50vh' />
                            </ContainerLoading>
                        }
                    >
                        <QueryClientProvider client={queryClient}>
                            <Component {...(props as P)} />
                        </QueryClientProvider>
                    </React.Suspense>
                </RecoilRoot>
            </ErrorBoundary>
        );
    };
    return WrapperContentApp;
};

export const Wrapper = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
    const WrapperComponent = ({ ...props }) => {
        return (
            <React.Suspense
                fallback={
                    <ContainerLoading>
                        <UiLoadingPage size='30px' $marginTop='50vh' />
                    </ContainerLoading>
                }
            >
                <ErrorBoundary>
                    <Component {...(props as P)} />
                </ErrorBoundary>
            </React.Suspense>
        );
    };
    return WrapperComponent;
};
