import React, { useState } from 'react';
import { WheelPositionContent } from './WheelPosition.view';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { VehicleDetailsActionMenuAtom } from '../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { useMutation } from '@tanstack/react-query';
import Wheel from '../../../api/Wheel';
import { MutationType } from './WheelPosition.type';
import { FetchVehicleDetailsData } from '../../Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';

const wheelAPI = new Wheel();

const WheelPosition = (): JSX.Element => {
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const [newPosition, setNewPosition] = useState<number>(
        vehicleDetailsActionMenuAtom.data?.wheel.customPosition || 0
    );

    const { mutate } = useMutation<unknown, unknown, MutationType>(
        ['SwapWheelPositions'],
        ({ wheelId, customPosition }) => wheelAPI.swapWheelPositions(wheelId, customPosition),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    return (
        <WheelPositionContent
            data-testid={'WheelPosition-testid'}
            newPosition={newPosition}
            setNewPosition={setNewPosition}
            mutate={mutate}
        />
    );
};

export default WheelPosition;
