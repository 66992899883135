import styled from 'styled-components';

export const SensorStatisticsContainer = styled.div`
    height: 360px;
`;

export const StatisticsDateTimePicker = styled.div`
    display: flex;
    height: 90px;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    padding-top: 10px;
`;
