import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Checkbox } from '@mui/material';
import { ERROR, SUCCESS, WHITE } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { ThemeMode } from '../../../states/global/Theme';

export const AddGlobalAdminContainer = styled.div``;

export const CustomCheckBox = styled(Checkbox)``;
export const CustomTextfield = styled(TextField)``;
export const CustomButton = styled(Button)`
    color: #fff !important;
    background-color: ${SUCCESS} !important;
    font-size: 10px !important;
    height: 25px;
    width: 135px;
    margin: 7px 0px !important;
`;

export const CustomInput = styled.div<{ warning?: boolean }>`
    display: flex;
    color: #fff !important;
    border-radius: 4px;
    background-color: ${({ warning }) => (warning ? ERROR : SUCCESS)} !important;
    font-size: 10px !important;
    height: 25px;
    width: 70px;
    margin: 7px 0px 14px 0px !important;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 5px;
    }
`;

export const GridInput = styled(Grid)`
    margin-bottom: 20px !important;
`;

export const GridContainer = styled(Grid)`
    margin-top: 15px;
    ${() => {
        return applyStyleByMode({
            styleJade: `
            &&& { 
                .MuiTextField-root {
                    margin: 8px;
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : WHITE};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(8px,-9px) scale(0.78);
                    }
                }
            }
            `
        });
    }}
`;

export const AmbientTempGrid = styled(Grid)`
    margin: 5px 0px 30px 0px;
    display: grid;
    grid-template-columns: repeat(6, 85px);
    grid-gap: 10px;
    grid-auto-rows: repeat(2, 100px);
`;

export const TextTitle = styled(Typography)`
    &.MuiTypography-root {
        font-size: 16px;
    }
`;

export const AmbientTextField = styled(TextField)`
    width: 100%;
`;

export const MapAreaGrid = styled(Grid)`
    margin: 0px 0px 25px 0px;
    display: grid;
    grid-template-columns: repeat(4, 132.5px);
    grid-gap: 10px;
`;

export const MapAreaTextField = styled(TextField)`
    width: 100%;
`;

export const LogoGrid = styled(Grid)`
    margin: 5px 0px 30px 0px;
    display: grid;
    grid-template-columns: minmax(250px, auto);
`;

export const ImageLogo = styled.img`
    ${applyStyleByMode({
        styleJade: 'padding: 15px; background-color: #fff;'
    })}
    max-width: -webkit-fill-available;
    background-size: cover;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'border: solid 2px #c7c7c7;',
            dark: ''
        });
    }}
    border-radius: 4px;

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        max-width: 76vw;
    }
`;

export const ContainerBtnLogo = styled.div<{ previewImg: string }>`
    background-color: transparent !important;
    background: ${({ previewImg }) => previewImg};

    & input[type='file'] {
        display: none;
    }
`;

export const RefButton = styled(Button)`
    display: none;
    width: 1px;
    height: 1px;
`;

export const InputImg = styled.input`
    display: none;
    width: 1px;
    height: 1px;
`;

export const UploadLogo = styled.div`
    align-items: center;
    display: flex;
    padding-bottom: 10px;
    & .MuiFormLabel-root {
        padding-right: 10px;
    }
    & .MuiButton-root {
        box-shadow: none;
    }
`;

export const ContentImge = styled.div`
    text-align: center;
`;
