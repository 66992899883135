import { TableCell } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { ERROR } from '../../Ui/colors';
import { useTheme } from '@mui/material/styles';

export const TableCellDiff = styled(TableCell)<{ diff: number }>`
    ${({ diff }) =>
        applyStyleByMode({
            styleJade: diff > 0 ? `color: ${useTheme().palette.mode === ThemeMode.dark ? '#ff4757' : ERROR}` : ''
        })}
`;
