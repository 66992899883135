import PortalApi from 'helpers/api/PortalApiClient';
import {
    WheelPredictionGraphData,
    WheelPredictionModel,
    TemperaturePredictionPreview
} from 'models/WheelPrediction.type';

export default class WheelPrediction extends PortalApi<WheelPredictionModel> {
    route = 'wheel-prediction';

    getGraphData = async (wheelPredictionId: number): Promise<WheelPredictionGraphData> => {
        return this.get({
            extendUrl: `${wheelPredictionId}/graph`
        });
    };

    getTempPressWheelReportPreview = async (props): Promise<TemperaturePredictionPreview> => {
        let criteria = {
            dateFrom: props.additionalProps.measuredFrom,
            dateTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }
        if (props.additionalProps.wheelPosition > 0) {
            criteria['wheelCustomPosition'] = props.additionalProps.wheelPosition;
        }
        return await this.getByCriteria({
            extendUrl: `temperature-kpi/preview`,
            criteria
        });
    };

    getTemperaturePredictionCsv = async (
        vehicleId: number,
        dateFrom: string,
        dateTo: string,
        wheelCustomPosition?: number
    ): Promise<void> => {
        let criteria = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }
        if (wheelCustomPosition && wheelCustomPosition > 0) {
            criteria['wheelCustomPosition'] = wheelCustomPosition;
        }
        return await this.getByCriteria({
            extendUrl: `temperature-kpi/export`,
            criteria
        });
    };
}
