import React from 'react';
import * as style from './ChangePasswordForm.style';
import * as type from './ChangePasswordForm.type';
import { useTranslation } from 'react-i18next';
import { ChangePasswordFormik } from './ChangePasswordForm.formik';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';

export const ChangePasswordFormContent: React.FC<type.ChangePasswordFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.ChangePasswordFormContent data-testid='ChangePasswordFormContent'>
            <ContainerBodyTab>
                <TitleContentTab>{translate('t.change_password')}</TitleContentTab>
                <style.ContainerFormChangePassword>
                    <ChangePasswordFormik handleSubmit={props.handleSubmit} isCurrentUser={props.isCurrentUser} />
                </style.ContainerFormChangePassword>
            </ContainerBodyTab>
        </style.ChangePasswordFormContent>
    );
};
