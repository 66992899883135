import { Box, styled } from '@mui/material';
import UiButton from '../../Ui/Components/UiButton';

export const TyreManagerContent = styled('div')`
    &&& .MuiTextField-root {
        & label {
            padding: 0 5px 0 0;
            background: transparent;
        }
    }
`;

export const StyledButton = styled(UiButton)<{ width: number }>`
    &&.MuiButton-root {
        height: 34px;
        padding: 0;
        margin: 0;
        width: ${(props) => props.width}px;
    }
`;

export const Column = styled('div')<{ $first?: boolean; $last?: boolean }>`
    margin: 0 6px;
    ${(props) => props.$first && 'margin-left: 0;'}
    ${(props) => props.$last && 'margin-right: 0;'}
`;

export const TextBox = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.9rem;
`;

export const MessageBox = styled('div')`
    display: flex;
    justify-content: center;
`;

export const WarningBox = styled(Box)<{ $isReplace }>`
    margin-top: ${(props) => (props.$isReplace ? '0' : '10px')};
`;
