import React, { useEffect, useCallback } from 'react';
import { isInPeriod } from 'helpers';
import { UiDateRangePicker2El } from './UiDateRangePicker2.style';
import { UiDatePickerProps } from './UiDateRangePicker2.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DateTimeDialogSelector from './Components/DateTimeDialogSelector/DateTimeRangeDialogSelector';
import DateTimePicker from './Components/DateTimePicker/DateTimePicker';
import { RangePositionCalendar } from './Components/DateTimeDialogSelector/DateTimeRangeDialogSelector.type';
import { RangePosition } from './UiDateRangePicker2.atom';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import UiButton from '../UiButton/UiButton';
import { useTranslation } from 'react-i18next';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { debounce } from 'lodash';
import { MaxYear, MinYear } from 'variables';

const UiDatePicker2Content: React.FC<UiDatePickerProps> = (props): JSX.Element => {
    const [openDate, setOpenDate] = React.useState(false);
    const [rangePosition, setRangePosition] = useRecoilState(RangePosition);
    const [dateValue, setDateValue] = React.useState<DateTime[] | null>([
        (typeof props.dateFrom === 'string' ? DateTime.fromISO(props.dateFrom).minus({ second: 1 }) : props.dateFrom) ||
            DateTime.local().minus({ second: 1 }),
        (typeof props.dateTo === 'string' ? DateTime.fromISO(props.dateTo).minus({ second: 1 }) : props.dateTo) ||
            DateTime.local().minus({ second: 1 })
    ]);
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = React.useState<DateTime[] | null>(dateValue);
    const { t: translate } = useTranslation();

    const outOfPeriod = useCallback(
        (dateFrom: DateTime, dateTo: DateTime): boolean => {
            if (props.adjustToPeriod) {
                return !isInPeriod(dateFrom, dateTo, props.adjustToPeriod?.period, props.adjustToPeriod?.periodType);
            }
            return false;
        },
        [props.adjustToPeriod]
    );

    const updateBothDateByPeriod = (
        newDateFrom: DateTime,
        newDateTo: DateTime,
        position: RangePositionCalendar
    ): DateTime[] => {
        const currentDate = DateTime.local();

        if (outOfPeriod(newDateFrom, newDateTo) && !!props.adjustToPeriod) {
            if (newDateFrom?.valueOf() > newDateTo?.valueOf() && !props.validFutureDate) {
                if (position === RangePositionCalendar.START && newDateFrom?.valueOf() < currentDate.valueOf()) {
                    const resetDateTo = newDateFrom.plus({ minute: 1 });
                    if (resetDateTo?.valueOf() > currentDate.valueOf()) {
                        return [newDateFrom.minus({ minute: 1 }), newDateFrom];
                    }
                    return [newDateFrom, resetDateTo];
                }
                if (position === RangePositionCalendar.END) {
                    const resetDateFrom = newDateTo.minus({ minute: 1 });
                    return [resetDateFrom, newDateTo];
                }
                return [currentDate.minus({ minute: 1 }), currentDate];
            }

            if (position === RangePositionCalendar.START) {
                return [
                    newDateFrom,
                    newDateFrom.plus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period })
                ];
            } else if (position === RangePositionCalendar.END) {
                if (newDateTo?.valueOf() > currentDate.valueOf() && !props.validFutureDate) {
                    return [
                        newDateFrom,
                        newDateFrom.plus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period })
                    ];
                }

                return [
                    newDateTo.minus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period }),
                    newDateTo
                ];
            }
        }

        if (newDateFrom?.valueOf() === newDateTo?.valueOf()) {
            const resetDateFrom = newDateFrom.minus({ minute: 1 });
            return [resetDateFrom, newDateFrom];
        }

        if (!props.validFutureDate) {
            if (newDateFrom.c && newDateTo.c) {
                if (newDateFrom.c.day === newDateTo.c.day) {
                    if (
                        newDateFrom?.valueOf() > currentDate.valueOf() ||
                        newDateTo?.valueOf() > currentDate.valueOf()
                    ) {
                        if (rangePosition === RangePositionCalendar.START) {
                            return [currentDate.minus({ minute: 1 }), newDateTo];
                        }
                        if (rangePosition === RangePositionCalendar.END) {
                            return [newDateFrom, currentDate];
                        }
                    }
                }
            }

            if (newDateFrom?.valueOf() > currentDate.valueOf() || newDateTo?.valueOf() > currentDate.valueOf()) {
                if (rangePosition === RangePositionCalendar.START) {
                    return [currentDate.minus({ minute: '1' }), currentDate];
                }

                if (rangePosition === RangePositionCalendar.END) {
                    return [newDateFrom, currentDate];
                }

                const startDate = props.adjustToPeriod
                    ? currentDate.minus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period })
                    : currentDate.minus({ days: 1 });
                return [startDate, currentDate];
            }
        }

        return [newDateFrom, newDateTo];
    };

    const validateDatesForModal = (position: RangePositionCalendar) => {
        debouncedOnDateChangeTo.cancel();
        debouncedOnDateChangeFrom.cancel();

        if (dateValue) {
            let updatedDateValue: DateTime[] | undefined = undefined;
            let updatedOnDateChange: DateTime;

            if (dateValue[0].invalid || dateValue[0] === '' || dateValue[1].invalid || dateValue[1] === '') {
                updatedDateValue = [
                    dateValue[0].invalid || dateValue[0] === '' ? DateTime.local().minus({ days: 1 }) : dateValue[0],
                    dateValue[1].invalid || dateValue[1] === '' ? DateTime.local() : dateValue[1]
                ];
            } else {
                updatedDateValue = updateBothDateByPeriod(dateValue[0], dateValue[1], position);

                if (
                    updatedDateValue[0].toUnixInteger() === dateValue[0].toUnixInteger() &&
                    updatedDateValue[1].toUnixInteger() === dateValue[1].toUnixInteger()
                ) {
                    updatedDateValue = undefined;
                }
            }
            if (updatedDateValue) {
                updatedOnDateChange = updatedDateValue;
                setDateValue(updatedDateValue);
                props.onDateChange && props.onDateChange(updatedOnDateChange);
            }
        }
    };

    const debouncedOnDateChangeTo = useCallback(
        debounce((newDate: DateTime) => {
            if (!openDate) {
                if (newDate == null) {
                    setDateValue([dateValue && dateValue[0], '']);
                    props.onDateChange && props.onDateChange([dateValue && dateValue[0], '']);
                } else {
                    if (newDate.year >= MinYear && newDate.year <= MaxYear) {
                        if (newDate.invalid) {
                            setDateValue([dateValue && dateValue[0], newDate]);
                            props.onDateChange && props.onDateChange([dateValue && dateValue[0], newDate]);
                        } else {
                            setDateValue(
                                updateBothDateByPeriod(dateValue && dateValue[0], newDate, RangePositionCalendar.END)
                            );
                            props.onDateChange &&
                                props.onDateChange(
                                    updateBothDateByPeriod(
                                        dateValue && dateValue[0],
                                        newDate,
                                        RangePositionCalendar.END
                                    )
                                );
                        }
                    }
                }
            }
        }, 500),
        [dateValue, props.onDateChange]
    );

    const debouncedOnDateChangeFrom = useCallback(
        debounce((newDate: DateTime) => {
            if (!openDate) {
                if (newDate == null) {
                    setDateValue(['', dateValue && dateValue[1]]);
                    props.onDateChange && props.onDateChange(['', dateValue && dateValue[1]]);
                } else {
                    if (newDate.year >= MinYear && newDate.year <= MaxYear) {
                        if (newDate.invalid) {
                            setDateValue([newDate, dateValue && dateValue[1]]);
                            props.onDateChange && props.onDateChange([newDate, dateValue && dateValue[1]]);
                        } else {
                            setDateValue(
                                updateBothDateByPeriod(newDate, dateValue && dateValue[1], RangePositionCalendar.START)
                            );
                            props.onDateChange &&
                                props.onDateChange(
                                    updateBothDateByPeriod(
                                        newDate,
                                        dateValue && dateValue[1],
                                        RangePositionCalendar.START
                                    )
                                );
                        }
                    }
                }
            }
        }, 500),
        [dateValue, props.onDateChange]
    );

    useEffect(() => {
        setDateValue([DateTime.fromISO(props.dateFrom), DateTime.fromISO(props.dateTo)]);
    }, [props.dateFrom, props.dateTo]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiDateRangePicker2El data-testid='UiDatePickerContent'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                    onlyDate={props.onlyDate}
                    inputFormat={props.inputFormatFrom || props.inputFormat}
                    testId={props.testIdFrom}
                    label={props.dateFromLabel}
                    onFocus={() => setRangePosition(RangePositionCalendar.START)}
                    onOpen={() => {
                        setOpenDate(true);
                        validateDatesForModal(RangePositionCalendar.START);
                        setRangePosition(RangePositionCalendar.START);
                    }}
                    value={dateValue && dateValue[0]}
                    onDateChange={(newValue) => debouncedOnDateChangeFrom(newValue as DateTime)}
                />

                <DateTimePicker
                    inputFormat={props.inputFormatTo || props.inputFormat}
                    testId={props.testIdTo}
                    onlyDate={props.onlyDate}
                    label={props.dateToLabel}
                    onFocus={() => setRangePosition(RangePositionCalendar.END)}
                    onOpen={() => {
                        setOpenDate(true);
                        validateDatesForModal(RangePositionCalendar.END);
                        setRangePosition(RangePositionCalendar.END);
                    }}
                    value={dateValue && dateValue[1]}
                    onDateChange={(newValue) => debouncedOnDateChangeTo(newValue as DateTime)}
                />

                <Dialog open={openDate} onClose={() => setOpenDate(false)}>
                    <DialogContent>
                        <DateTimeDialogSelector
                            dateValueStaticDatePickerFrom={dateValueStaticDatePicker && dateValueStaticDatePicker[0]}
                            dateValueStaticDatePickerTo={dateValueStaticDatePicker && dateValueStaticDatePicker[1]}
                            onlyDate={props.onlyDate}
                            adjustToPeriod={props.adjustToPeriod}
                            validFutureDate={props.validFutureDate}
                            outOfPeriod={outOfPeriod}
                            setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                        />
                    </DialogContent>
                    <DialogActions>
                        <UiButton
                            color='primary'
                            variant='outlined'
                            onClick={() => {
                                setOpenDate(false);
                                dateValue &&
                                    setDateValueStaticDatePicker([
                                        dateValue[0].invalid ? DateTime.local() : dateValue[0],
                                        dateValue[1].invalid ? DateTime.local() : dateValue[1]
                                    ]);
                            }}
                            size='small'
                        >
                            {translate('t.cancel')}
                        </UiButton>
                        <UiButton
                            color='primary'
                            variant={VARIANT_BUTTON}
                            size='small'
                            onClick={() => {
                                if (
                                    dateValueStaticDatePicker &&
                                    !dateValueStaticDatePicker[0].invalid &&
                                    !dateValueStaticDatePicker[1].invalid
                                ) {
                                    setDateValue(
                                        updateBothDateByPeriod(
                                            dateValueStaticDatePicker[0],
                                            dateValueStaticDatePicker[1],
                                            RangePositionCalendar.START
                                        )
                                    );

                                    props.onDateChange &&
                                        props.onDateChange(
                                            updateBothDateByPeriod(
                                                dateValueStaticDatePicker[0],
                                                dateValueStaticDatePicker[1],
                                                RangePositionCalendar.START
                                            )
                                        );
                                }

                                setOpenDate(false);
                            }}
                        >
                            OK
                        </UiButton>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider>
        </UiDateRangePicker2El>
    );
};

export default UiDatePicker2Content;
