import { FilterContent, UseTableFilterReturnType, UseTableFilterType } from './TableFilterState.type';
import { useEffect, useState } from 'react';
import useConverter from '../Converter/Converter';

const useTableFilter: UseTableFilterType = ({
    filterContent,
    setFilter,
    applyFiltersFlag,
    filterId,
    valueType
}): UseTableFilterReturnType => {
    const { dateTimeFormat } = useConverter();
    const [cellValue, setCellValue] = useState<FilterContent>(filterContent);

    const setFilterValue = (newValue) => {
        if (valueType == 'date') {
            const value = {
                name: filterContent?.name as string,
                humanValue: newValue ? newValue.toFormat(dateTimeFormat('date', false)) : undefined,
                value: newValue ? newValue : undefined
            };
            setCellValue(value);
        } else if (valueType == 'dropdown') {
            const value = !newValue
                ? undefined
                : {
                      name: filterContent?.name as string,
                      humanValue: newValue.name,
                      value: `${newValue.id}`
                  };
            setCellValue(value);
        } else if (valueType == 'checkbox') {
            const value: FilterContent = !newValue.target.checked
                ? undefined
                : {
                      name: filterContent?.name as string,
                      value: '1',
                      humanValue: 'True'
                  };

            setCellValue(value);
        } else {
            const value: FilterContent = !newValue
                ? undefined
                : {
                      name: filterContent?.name as string,
                      value: newValue.target.value === '' ? undefined : newValue.target.value
                  };

            setCellValue(value);
        }
    };

    useEffect(() => {
        if (applyFiltersFlag) {
            if (applyFiltersFlag === 'reset') {
                setCellValue(undefined);
            } else {
                if (filterId && cellValue?.name) {
                    setFilter(filterId, cellValue);
                } else if (cellValue?.name) {
                    setFilter(cellValue);
                }
            }
        }

        return () => setCellValue(undefined);
    }, [applyFiltersFlag]);

    return {
        cellValue,
        setFilterValue
    };
};

export default useTableFilter;
