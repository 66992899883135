import React from 'react';
import { WarnNotificationsViewContent } from './WarnNotifications.style';
import { WarnNotificationsViewProps } from './WarnNotifications.type';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

export const WarnNotificationsContent: React.FC<WarnNotificationsViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <WarnNotificationsViewContent data-testid='WarnNotificationsContent'>
            <Alert variant='filled' severity='warning' sx={{ marginBottom: '15px' }}>
                {translate('t.customer_no_longer_active')}
            </Alert>
        </WarnNotificationsViewContent>
    );
};
