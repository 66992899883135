import React from 'react';
import * as type from './HubBatteryIndicator.type';
import * as view from './HubBatteryIndicator.view';
import { Wrapper } from 'helpers/wrapper';

const HubBatteryIndicator: React.FC<type.HubBatteryIndicatorProps> = (props): JSX.Element => {
    const getIcon = (): type.BatteryIcon => {
        if (props.battery === null || props.battery < 6500) {
            return 'battery-empty';
        } else if (props.battery < 7000) {
            return 'battery-quarter';
        } else if (props.battery < 7500) {
            return 'battery-half';
        } else if (props.battery < 8000) {
            return 'battery-three-quarters';
        } else {
            return 'battery-full';
        }
    };
    if (!props.powerSource || (props.powerSource && (props?.powerSource > 999 || props?.powerSource <= 0))) {
        return <></>;
    }

    return (
        <view.HubBatteryIndicatorContent
            data-testid='HubBatteryIndicator-testid'
            battery={props.battery}
            getIcon={getIcon}
        />
    );
};

export default Wrapper(HubBatteryIndicator);
