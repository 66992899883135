import { UserModel } from '../../models/User.type';

const getTokenFromCookie = (): string | null => {
    const token: string[] = document.cookie.split(';').filter((c) => c.includes('authToken'));
    if (token.length && token[0]) {
        return token[0].split('=')[1];
    }
    return null;
};

export const authToken = (): string => {
    let loggedIn = String(localStorage.getItem('access-token') || getTokenFromCookie() || '');

    return loggedIn;
};

function removeCookie(cookieName: string): void {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const removeSessionCookie = () => {
    removeCookie('authToken');
    removeCookie('PHPSESSID');
};

export function setJWT(token: string): void {
    localStorage.setItem('access-token', token);
}

function removeJWT(): void {
    localStorage.removeItem('access-token');
}

export function loggedIn(): boolean {
    return !!authToken().length;
}

export function logout(): void {
    removeJWT();
    //Temportal, until we remove old portal
    removeSessionCookie();
    deleteCookie('last_login');
    deleteCookie('PHPSESSID');
    deleteCookie('device_view');
}

const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const checkRole = (User: UserModel | null, listRoles: string[]): boolean => {
    let valid = false;

    if (listRoles.length > 0) {
        listRoles.map((role) => {
            if (User?.role.name === role) {
                valid = true;
            }
        });
    }

    return valid;
};

export const hasAccessToCustomer = (user: UserModel | null, customerId: number): boolean => {
    if (!user || !user.userCustomerAccess?.length) return false;

    const customerAccess = user.userCustomerAccess.find((access) => access.customer.id === customerId);

    return !!customerAccess;
};
