import React, { useState, useEffect } from 'react';
import { ElevationHeatmapContent } from './ElevationHeatmap.view';
import { ElevationHeatmapProps, ElevationLimits } from './ElevationHeatmap.type';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';

const ElevationHeatmap: React.FC<ElevationHeatmapProps> = (props): JSX.Element => {
    const [elevationLimits, setElevationLimits] = useState<ElevationLimits>({ min: 0, max: 500 });
    const customerSettings = useRecoilValue(CustomerSettings);

    useEffect(() => {
        setElevationLimits({
            min: customerSettings.altitude_min ?? 0,
            max: customerSettings.altitude_max ?? 500
        });
    }, [customerSettings.altitude_min, customerSettings.altitude_min, setElevationLimits]);

    return (
        <ElevationHeatmapContent {...props} elevationLimits={elevationLimits} data-testid='ElevationHeatmap-testid' />
    );
};

export default Wrapper(ElevationHeatmap);
