import FaultySensorReporter from 'components/FaultySensorReport/FaultySensorReport';
import { FaultySensorReportModalProps } from './FaultySensorReportModal.type';
import { calculateGranularity, createTimeline } from 'helpers';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
    DateTimePicker,
    FaultySensorReporterState,
    InvalidActionButtons,
    Timeline
} from 'states/component/FaultySensorReporter';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DatePickerAction } from 'pages/SeverityRank/atom';
import { useTranslation } from 'react-i18next';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import { useMediaQuery, useTheme } from '@mui/material';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';

const FaultySensorReportModal: React.FC<FaultySensorReportModalProps> = () => {
    const faultySensorReporterState = useRecoilValue(FaultySensorReporterState);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const resetInvalidActionButtons = useResetRecoilState(InvalidActionButtons);
    const { fromUTCtoUserTimezone } = useConverter();
    const setDateTimePicker = useSetRecoilState(DateTimePicker);
    const setTimeline = useSetRecoilState(Timeline);
    const { t: translate } = useTranslation();
    const setMapState = useSetRecoilState(MapAction);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('FaultySensorReports-RefreshTableAtom'));

    const mainTitle = (): string => {
        const sensorName = `- ${translate('t.sensor')}: ${faultySensorReporterState.sensorName}`;

        if (faultySensorReporterState.reportDetailId) {
            return `${translate('t.faulty_report_detail')} ${sensorName}`;
        }

        return faultySensorReporterState.showOnlyGraph
            ? `${translate('t.sensor_readings')} ${sensorName}`
            : `${translate('t.report_faulty_sensor')} ${sensorName}`;
    };

    const handleFaultySensorReport = () => {
        const dateTo: DateTime = faultySensorReporterState.reportDateTo
            ? fromUTCtoUserTimezone({
                  date: faultySensorReporterState.reportDateTo,
                  format: 'dateTime',
                  displaySeconds: true,
                  returnObjectLuxon: true
              })
            : DateTime.now();
        const dateFrom: DateTime = faultySensorReporterState.reportDateFrom
            ? fromUTCtoUserTimezone({
                  date: faultySensorReporterState.reportDateFrom,
                  format: 'dateTime',
                  displaySeconds: true,
                  returnObjectLuxon: true
              })
            : dateTo.minus({ days: 1 });

        setDateTimePicker({
            current: {
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            original: {
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            action: DatePickerAction.APPLY
        });

        const granularity = calculateGranularity(dateFrom, dateTo);
        setTimeline({
            timeline: createTimeline(granularity, dateFrom, dateTo),
            granularity
        });
        resetInvalidActionButtons();
    };

    const handleModal = () => {
        const contentModalAdd = {
            id: 'FaultySensorReport',
            customContent: <FaultySensorReporter />,
            leftTitle: mainTitle(),
            width: matches ? 1300 : 1400,
            height: matches ? 570 : 620,
            widthUnit: 'px',
            onClose: () => {
                modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                setMapState({ action: MapActionsEnum.HIDE_MAP_MESSAGE });
                setRefreshTableAtom(true);
            }
        };

        modalDispatch({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    useEffect(() => {
        if (modalAtom.isOpen) {
            handleFaultySensorReport();
        }
    }, [modalAtom]);

    useEffect(() => {
        if (faultySensorReporterState.sensorId) {
            handleModal();
        }
    }, [faultySensorReporterState]);

    return <></>;
};

export default FaultySensorReportModal;
