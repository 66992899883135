import { FormControlLabel, RadioGroup, useTheme } from '@mui/material';
import styled from 'styled-components';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ContainerFormGeneralSettings = styled.div`
    max-width: 70%;
    margin: 0 auto;
    padding-top: 20px;

    @media only screen and (min-width: 300px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 600px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }

    & .MuiTypography-body1 {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
    }

    .subTitleSubForm {
        padding: 10px;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.titleSize};`;
        }}
    }
`;

export const GeneralSettingsFormContent = styled.div``;

export const ContainerButton = styled.div`
    float: right;
    padding-right: 10px;
`;

export const ContainerRadioGroup = styled(RadioGroup)`
    text-align: left;
`;

export const SwtichLabel = styled(FormControlLabel)`
    text-align: left;
`;
