import React from 'react';
import { TempAndPressListHeaderContent } from './TempAndPressListHeader.view';
import { TempAndPressListHeaderProps } from './TempAndPressListHeader.type';
import { Wrapper } from 'helpers/wrapper';

const TempAndPressListHeader: React.FC<TempAndPressListHeaderProps> = (props): JSX.Element => {
    return <TempAndPressListHeaderContent data-testid='TempAndPressListHeader-testid' {...props} />;
};

export default Wrapper(TempAndPressListHeader);
