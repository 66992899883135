import React, { useEffect } from 'react';
import * as type from './Vehicle.type';
import VehicleTable from '../../components/Vehicle/VehicleTable';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Vehicle: React.FC<type.VehicleProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'vehicles-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <VehicleTable />;
};

export default Wrapper(Vehicle);
