import { authToken } from 'helpers/authentication';

export const fetchFN = async (): Promise<{ id: string; name: string; scopes: string[] }[]> => {
    const apiUrl = 'https://login.atmstechnology.com/api/tablet';

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken()}`
    };

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    let dataToReturn = [];

    await fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            dataToReturn = data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error:', error);
        });

    return dataToReturn;
};

export const deactivateTablet = async (tabletId: string): Promise<void> => {
    const apiUrl = 'https://login.atmstechnology.com/api/tablet/deactivate';
    const params = new URLSearchParams();

    params.append('tablet_id', tabletId);

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken()}`
    };

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: params
    };

    await fetch(apiUrl, requestOptions).then((response) => response.json());
};

export const activateTablet = async (tabletName: string): Promise<{ code: string; expires_in: number }> => {
    const apiUrl = 'https://login.atmstechnology.com/authorize';
    const params = new URLSearchParams();

    params.append('response_type', 'tablet_code');
    params.append('client_id', '00450af626c0fdd15a86361444f489af');
    params.append('tablet_name', tabletName);
    params.append('scope', 'ROLE_TYRE_BAY_USER');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${authToken()}`
        },
        body: params
    };

    return fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error:', error);
        });
};
