import { Box, Theme, Typography } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { WHITE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const AlertsConfigurationFilterInputViewContent = styleMui(Box)({
    position: 'relative',
    width: 200,
    height: 240,
    display: 'inline-block'
});

export const VehicleConfigCover = styleMui(Box)({
    position: 'relative',
    height: 240,
    Width: 200
});

export const VehicleConfigInput = styleMui(Box)({
    height: 240,
    Width: 200,
    border: 'solid thin rgb(200,200,200)',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //cursor: 'pointer',
    flexDirection: 'column'
});

export const Label = styleMui(Typography)((props: { theme: Theme }) => ({
    position: 'absolute',
    padding: '0 5px',
    background: props.theme.palette.mode === ThemeMode.dark ? '#1d2630' : WHITE,
    zIndex: 5,
    left: 9
}));

export const SelectConfigurationButton = styleMui(UiButton)({
    width: '100%',
    position: 'absolute',
    bottom: 0
});

export const ExpandIcon = styleMui(ArrowDownIcon)((props: { expand: boolean }) => ({
    transform: props.expand ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 300ms linear'
}));
