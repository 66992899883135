import React from 'react';
import { useSetRecoilState } from 'recoil';
import { InicialvaluesForm, RecoveryPasswordProps } from './RecoveryPassword.type';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import RecoverPassword from 'api/RecoverPassword';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, Link, TextField, Typography } from '@mui/material';
import Button from 'components/Ui/Components/UiButton';
import { LoginShowContent, showContentType } from 'states/global/Login';
import { DateTime } from 'luxon';
import { Success, Warning } from 'components/Popup/Popup';
import HelperText from 'components/Mixs/HelperText/HelperText';
import { FooterText } from 'variables';

const recoveryPasswordApi = new RecoverPassword();

const RecoveryPassword: React.FC<RecoveryPasswordProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const setLoginShowContent = useSetRecoilState(LoginShowContent);
    const Year = DateTime.now().year;

    const { mutate: recoveryPasswordMutate, isLoading } = useMutation(recoveryPasswordApi.sendEmail, {
        onSuccess: (dataOnSuccess) => {
            if (dataOnSuccess.data) {
                Success({
                    text: translate('p.instructions_to_recover_password')
                });
            } else {
                Warning({
                    text: translate('t.username_does_not_exist')
                });
            }
        }
    });

    const valuesInitForm: InicialvaluesForm = {
        username: ''
    };

    const schemaValidateForm = Yup.object({
        username: Yup.string().trim().required(translate('p.this_field_is_required'))
    });

    return (
        <>
            <Typography variant='h4' align='center' gutterBottom>
                {translate('t.forgot_password')}
            </Typography>
            <Formik
                initialValues={valuesInitForm}
                onSubmit={(values: InicialvaluesForm) => {
                    recoveryPasswordMutate(values);
                }}
                validationSchema={schemaValidateForm}
            >
                {(propsF: FormikProps<InicialvaluesForm>) => {
                    const { values, touched, errors, handleBlur, handleChange } = propsF;
                    return (
                        <Form>
                            <Grid container spacing={1} justifyContent='space-around' direction='row'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        name='username'
                                        id='username'
                                        data-testid='Email-Field'
                                        label={translate('t.username')}
                                        value={values.username}
                                        type='text'
                                        helperText={
                                            <HelperText
                                                error={!!errors.username && !!touched.username}
                                                text={errors.username}
                                            />
                                        }
                                        error={errors.username && touched.username ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button
                                        variant='contained'
                                        testid='Send-RecoveryPassowrd-Button'
                                        type='submit'
                                        size='large'
                                        disabled={isLoading}
                                        loading={isLoading}
                                        sx={{ fontSize: '0.93rem' }}
                                    >
                                        {translate('t.reset_password')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <Typography variant='subtitle1' align='center' gutterBottom>
                <Link
                    href='javascript:void(0)'
                    onClick={() => setLoginShowContent(showContentType.LOGIN)}
                    variant='h5'
                    underline='hover'
                    data-testid='Sign-In-Link'
                >
                    {translate('t.sign_in')}
                </Link>
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
                {FooterText} - {Year}
            </Typography>
        </>
    );
};

export default RecoveryPassword;
