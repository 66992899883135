import { Checkbox, FormControlLabel } from '@mui/material';
import { HideColumnsProps } from './HideColumnsSensorsAround.type';
import { CustomIconButton, HideColumnCover, HideColumnsContent } from './HideColumnsSensorsAround.style';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import React from 'react';
import Legend from 'components/Ui/Components/UiLegend/UiLegend';
import { SIZE_BUTTON } from 'components/Ui/variables';

const HideColumnsSensorsAroundContent: React.FC<HideColumnsProps> = (props): JSX.Element => {
    const checkedCount = props.columns.reduce((acc, val) => acc + (val.hidden ? 1 : 0), 0);
    const onlyOneOptionLeft: boolean = checkedCount + 1 >= props.columns.length;
    return (
        <HideColumnsContent data-testid='HideColumnsContent'>
            <Legend
                root={
                    <div data-testid={`HideColumnsContent-div`}>
                        <CustomIconButton size={SIZE_BUTTON}>
                            <ViewColumnIcon />
                        </CustomIconButton>
                    </div>
                }
                icon={<ViewColumnIcon />}
                width={400}
            >
                <HideColumnCover>
                    {props.columns
                        .filter((column) => column.header.length > 0)
                        .map((column, key) => (
                            <FormControlLabel
                                key={column.accessor}
                                control={
                                    <Checkbox
                                        value={`${column.accessor}`}
                                        disabled={!column.hidden && onlyOneOptionLeft}
                                        color='primary'
                                    />
                                }
                                label={column.header}
                                checked={!column.hidden}
                                onChange={() => {
                                    props.toggleHideColumn(key);
                                }}
                            />
                        ))}
                </HideColumnCover>
            </Legend>
        </HideColumnsContent>
    );
};

export default HideColumnsSensorsAroundContent;
