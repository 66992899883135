import React from 'react';
import * as type from './UiCircularGraph.type';
import * as view from './UiCircularGraph.view';
// import module

const UiCircularGraph: React.FC<type.UiCircularGraphProps> = (props) => {
    return <view.UiCircularGraphContent {...props} data-testid={'UiCircularGraph-testid'} />;
};

export default UiCircularGraph;
