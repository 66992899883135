import React, { useEffect } from 'react';
import { DashboardContent } from './Dashboard.view';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Dashboard: React.FC = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'dashboard' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <DashboardContent data-testid='Dashboard-testid' />;
};

export default Wrapper(Dashboard);
