import React, { useMemo } from 'react';
import { StyledButton } from '../TyreManager.style';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TyreStateAtom } from '../TyreManager.atom';
import { CRUD } from 'variables';
import { TyreStateDispatcher } from '../TyreManager.reducer';
import useModal from '../../../CustomHooks/ModalHook';
import TyreActionPopup from '../TyreActionPopup';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { Grid } from '@mui/material';

const Buttons = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tyreState, setTyreState] = useRecoilState(TyreStateAtom);
    const dispatch = TyreStateDispatcher(tyreState, setTyreState);
    const tyreNotChanged = useMemo(() => {
        return tyreState.newTyre.id === tyreState.originalTyre.id;
    }, [tyreState.newTyre, tyreState.originalTyre]);
    const { setModalState } = useModal(1);

    return (
        <Grid container spacing={1}>
            <Grid item>
                <StyledButton
                    id={`${tyreState.state}-action`}
                    testid={`TyreManager-${tyreState.state}-Button`}
                    width={100}
                    skin={tyreState.state === CRUD.UNMOUNT ? 'error' : 'success'}
                    color={tyreState.state === CRUD.UNMOUNT ? 'error' : 'primary'}
                    variant={VARIANT_BUTTON}
                    disabled={tyreState.state !== CRUD.UNMOUNT && tyreNotChanged}
                    onClick={() =>
                        setModalState({
                            isOpen: true,
                            leftTitle: translate(`t.${tyreState.state}_tyre`),
                            rightTitle: `
                                ${translate('t.tyre')}: 
                                ${tyreState.newTyre.tyreSerialNumber} 
                                ${
                                    tyreState.state !== CRUD.UNMOUNT
                                        ? `> 
                                ${tyreState.originalTyre.tyreSerialNumber || 'S/N'}`
                                        : ''
                                }
                            `,
                            content: <TyreActionPopup />,
                            height: 250,
                            width: 500
                        })
                    }
                >
                    {translate(`t.${tyreState.state}`)}
                </StyledButton>
            </Grid>
            <Grid item>
                <StyledButton
                    id='reset-state'
                    width={100}
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    onClick={() => dispatch(tyreState.originalTyre)}
                    disabled={tyreNotChanged}
                    testid={`TyreManager-Reset-Button`}
                >
                    {translate('t.reset')}
                </StyledButton>
            </Grid>
        </Grid>
    );
};

export default Buttons;
