import React from 'react';
import * as style from './UiColorPicker.style';
import * as type from './UiColorPicker.type';
import { Box, Grid, Popover, TextField } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { RgbaStringColorPicker } from 'react-colorful';

export const UiColorPickerContent: React.FC<type.UiColorPickerViewProps> = (props): JSX.Element => {
    return (
        <style.UiColorPickerContent data-testid='UiColorPickerContent'>
            <PopupState variant='popover' popupId='demo-popup-popover'>
                {(popupState) => (
                    <>
                        <Grid container spacing={1} alignItems='flex-end'>
                            <Grid item>
                                <style.ContainerColorBox
                                    color={props.validateColor(props.color)}
                                    {...bindTrigger(popupState)}
                                ></style.ContainerColorBox>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant='outlined'
                                    value={props.color}
                                    size='small'
                                    id='input-with-icon-grid'
                                    label={props.labelInput}
                                    onChange={(e) => props.setColor(e.target.value)}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={props.error}
                                    error={!!props.error}
                                    data-testid={props.testid}
                                />
                            </Grid>
                        </Grid>

                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            <Box p={1.5}>
                                <RgbaStringColorPicker color={props.color} onChange={props.setColor} />
                            </Box>
                        </Popover>
                    </>
                )}
            </PopupState>
        </style.UiColorPickerContent>
    );
};
