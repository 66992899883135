import React, { useMemo } from 'react';
import { MinePermissionTableProps } from './MinesPermissions.type';
import { Button, ContainerTable, CustomTable, CustomTableRow, StickyHeader } from './MinesPermissions.style';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CRUD } from 'variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHouseUser, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ChangeCustomerEnum, MinesPermissionsAtom, MinesPermissionsDispatcher } from './MinesPermissions.reducer';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { SelectedUser } from './Permissions.atom';

export const MinesPermissionTable: React.FC<MinePermissionTableProps> = (props): JSX.Element => {
    const [minesPermissionsState, setMinesPermissionsState] = useRecoilState(MinesPermissionsAtom);
    const minesPermissionsDispatch = MinesPermissionsDispatcher(minesPermissionsState, setMinesPermissionsState);
    const { t: translate } = useTranslation();
    const notAssignedTable = useMemo(() => props.actionType === CRUD.ADD, [props.actionType]);
    const searchValue = useMemo(
        () => minesPermissionsState[notAssignedTable ? 'notAssignedCustomersSearch' : 'assignedCustomersSearch'],
        [minesPermissionsState, notAssignedTable]
    );
    const customers = useMemo(
        () => minesPermissionsState.minesPermission[notAssignedTable ? 'notAssigned' : 'assigned'],
        [minesPermissionsState, notAssignedTable]
    );
    const selectedUser = useRecoilValue(SelectedUser);
    const isSuperAdmin = checkRole(selectedUser, [ROLES.ROLE_SUPER_ADMIN]);

    return (
        <ContainerTable>
            <CustomTable data-testid={`${props.testid}-Table`} stickyHeader>
                <TableHead>
                    <TableRow>
                        <StickyHeader>ID</StickyHeader>
                        <StickyHeader>{translate('t.customer')}</StickyHeader>
                        <StickyHeader>{translate('t.region')}</StickyHeader>
                        <StickyHeader>{translate('t.action')}</StickyHeader>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {customers
                        .filter((customer) => !searchValue || customer.id === searchValue.id)
                        .map((customer, index) => (
                            <CustomTableRow
                                key={customer.id}
                                $assigned={!notAssignedTable && customer.id === customers[0].id}
                            >
                                <TableCell>{customer.id}</TableCell>
                                <TableCell>{customer.name}</TableCell>
                                <TableCell>{translate(`t.${customer.region?.translationString}`)}</TableCell>
                                <TableCell>
                                    {!isSuperAdmin && (
                                        <Button
                                            skin={notAssignedTable ? 'success' : 'error'}
                                            color={notAssignedTable ? 'primary' : 'error'}
                                            variant='contained'
                                            testid={`${props.testid}-${
                                                notAssignedTable ? 'Assigned' : 'Remove'
                                            }-Button-${index}`}
                                            onClick={() => {
                                                props.buttonAction(props.actionType, customer.id);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={notAssignedTable ? faArrowRight : faTrash} />
                                        </Button>
                                    )}
                                    {!notAssignedTable && customer.id !== customers[0].id && (
                                        <Button
                                            skin='success'
                                            color='primary'
                                            variant='contained'
                                            testid={`${props.testid}-Set-Default-Button-${index}`}
                                            title={translate('p.set_default_customer')}
                                            onClick={() => {
                                                let assigned = [...customers];
                                                for (let i = 0; i < assigned.length; i++) {
                                                    if (assigned[i].id === customer.id) {
                                                        assigned.unshift(assigned.splice(i, 1)[0]);
                                                        break;
                                                    }
                                                }
                                                minesPermissionsDispatch({
                                                    type: ChangeCustomerEnum.CHANGE_MINES_PERMISSION,
                                                    payload: {
                                                        assigned: assigned,
                                                        notAssigned: minesPermissionsState.minesPermission.notAssigned
                                                    }
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faHouseUser} />
                                        </Button>
                                    )}
                                </TableCell>
                            </CustomTableRow>
                        ))}
                </TableBody>
            </CustomTable>
        </ContainerTable>
    );
};

export default MinesPermissionTable;
