import PortalApiClient from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { HubAdministrationResponse } from '../models/HubAdministration.type';
import { DeviceModel } from 'models/Device.type';
export default class DeviceUpdateBatch extends PortalApiClient<DeviceModel> {
    route = 'device-update-batch';

    getBatchTable = async (props): Promise<HubAdministrationResponse> => {
        return await this.getTable({ criteria: getParams(props), extendUrl: 'list' });
    };

    createBatch = async (props): Promise<DeviceModel> => {
        return this.post({ data: { device: props } });
    };

    cancelBatch = async (id): Promise<DeviceModel> => {
        return this.post({ extendUrl: `cancel/${id}` });
    };
}
