import React from 'react';
import { AlertsAvailableFiltersProps } from './AlertsAvailableFilters.type';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { AlertsAvailableFiltersViewContent } from './AlertsAvailableFilters.style';
import AlertsAvailableFiltersList from '../AlertsAvailableFiltersList';
import { Wrapper } from 'helpers/wrapper';

const AlertsAvailableFilters: React.FC<AlertsAvailableFiltersProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <AlertsAvailableFiltersViewContent data-testid={'AlertsAvailableFilters-testid'}>
            <Grid container xs md lg>
                <Grid item xs={12} md={12} lg={12} height={'30px'} display={'flex'} alignItems={'center'}>
                    <Typography variant='subtitle1'>{`${translate('t.available_filters')}:`}</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <AlertsAvailableFiltersList />
                </Grid>
            </Grid>
        </AlertsAvailableFiltersViewContent>
    );
};

export default Wrapper(AlertsAvailableFilters);
