import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ERROR } from '../Ui/colors';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../states/global/Theme';
import { useTheme } from '@mui/material';

export const CustomStaticDatePicker = styled(StaticDateTimePicker)`
    height: 400px;

    &&& label {
        padding: 0 5px 0 0;
        background: transparent;
    }
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)`
    &.MuiFormControl-root {
        margin: 0 5px !important;
        padding-bottom: 10px;
    }
`;

export const StatisticsTopBarContainer = styled.div`
    width: 100%;
    min-width: 300px;
    margin: auto;
    padding: 10px 0;
    border-bottom: 10px;
    button {
        ${applyStyleByMode({
            styleJade: 'padding: 6px;'
        })}
    }
`;

export const AllHubsReadingCheckbox = styled(FormControlLabel)`
    &.MuiFormControlLabel-root {
        margin-left: 10px;
    }
`;

export const GroupContent = styled.div<{ right?: boolean; left?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
    ${() => {
        return applyStyleByMode({
            styleJade: `
            margin: 5px;
            &&& { 
                .MuiTextField-root {
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
            `
        });
    }}
    & .MuiButton-root {
        margin: 1px;
    }
    & .MuiFormControlLabel-root {
        margin: 0;
    }
`;

export const ActionCover = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
`;

export const WarningCover = styled.div`
    text-align: center;
    color: ${ERROR};
`;
