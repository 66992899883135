import React from 'react';
import { useRecoilValue } from 'recoil';
import { CheckboxDiv, HeaderText, Text, VehicleDetailsTyresViewContent } from './VehicleDetailsTyres.style';
import { VehicleDetailsTyresViewProps } from './VehicleDetailsTyres.type';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../Ui/Components/UiCheckbox';
import { UserInfo } from '../../../states/global/User';
import TyreDetails from '../TyreDetails';
import { VehicleDetailsTyresAtom } from './VehicleDetailsTyres.atom';

export const VehicleDetailsTyresContent: React.FC<VehicleDetailsTyresViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const vehicleDetailsTyresAtom = useRecoilValue(VehicleDetailsTyresAtom);

    return (
        <VehicleDetailsTyresViewContent data-testid='VehicleDetailsTyresContent'>
            <CheckboxDiv>
                {userInfo.user?.userSetting.targetPressure && (
                    <Checkbox
                        label={translate('t.target_pressure')}
                        checked={vehicleDetailsTyresAtom.targetPressure}
                        onChange={props.handleCheckboxChange}
                        testid='VehicleStatus-TargetPressure-Checkbox'
                    />
                )}
            </CheckboxDiv>
            <HeaderText>
                <Text width={24}>
                    {`${translate(
                        't.temperature_short'
                    )} (°${userInfo.user?.userSetting.temperatureUnit?.toUpperCase()})`}
                </Text>
                <Text width={35}>{`${translate('t.pressure')} (${userInfo.user?.userSetting.pressureUnit})`}</Text>
                <Text width={22}>{translate('t.updated_at')}</Text>
            </HeaderText>
            <TyreDetails />
        </VehicleDetailsTyresViewContent>
    );
};
