import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { WHITE } from '../../Ui/colors';
import { Box, useTheme } from '@mui/material';

export const AreaEditorFormViewContent = styled.div``;

export const AreaEditorFormLoader = styled.div`
    width: 100%;
    height 100%;
    background: #e7e7e773;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    text-align: center
`;

export const CustomSpinner = styled(CircularProgress)`
    position: absolute;
    top: 45%;
`;

export const CustomColorPicker = styled.div<{ color: string }>`
    background: #fff;
    position: relative;
    padding: 8px;
    border: 1px solid #ccc;
    margin-top: 8px;
    border-radius: 4px;
    height: 379px;

    & .sketch-picker {
        width: 100% !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        padding: 0 !important;
        max-width: 250px !important;
        margin: auto;
    }
`;

export const CustomTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin: 8px;
        margin-left: 0px;
        ${() => {
            return applyStyleByMode({
                styleJade: `
                    &.MuiTextField-root {
                        margin: 8px;
                        margin-left: 0px;
                        && label {
                            padding: 0 5px 0 0;
                            background: ${useTheme().palette.mode === ThemeMode.dark ? '#29313b' : WHITE};
                        }
                        & .MuiInputLabel-shrink {
                            transform: translate(12px, -9px) scale(0.8);
                        }
                    }
                `
            });
        }}
    }
`;

export const SmallTitle = styled.small`
    font-size: 92%;
`;

export const Hideable = styled.div<{ hidden: boolean }>`
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')}; 
    display: block;
}
`;

export const GreenSwitch = styled(Switch)(() => ({}));

export const AreaTypeColorIndicator = styled(Box)<{ color: string }>`
    width: 15px;
    height: 15px;
    background: ${(props) => props.color || '#fff'};
    margin-right: 5px;
    border: 2px solid
        ${(props) => {
            let color = (props.color || 'rgba(255,255,255,0.1)').split(',');
            color[3] = '1)';
            return color.join(',') || '#fff';
        }};
`;

export const AreaTypeContent = styled(Box)`
    display: flex;
`;

export const AreaColorLable = styled(Box)`
    position: absolute;
    top: -9px;
    left: 8px;
    background: #fff;
    padding: 0 4px;
    color: rgba(0, 0, 0, 0.6);
`;

export const WarningMsgContainer = styled(Box)<{ visible: boolean }>`
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    margin-top: 10px;
`;
