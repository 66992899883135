import React from 'react';
import { GpsHeatmapContent } from './GpsHeatmap.view';
import { GpsHeatmapProps } from './GpsHeatmap.type';
import { Wrapper } from 'helpers/wrapper';

const GpsHeatmap: React.FC<GpsHeatmapProps> = (): JSX.Element => {
    return <GpsHeatmapContent data-testid='GpsHeatmap-testid' />;
};

export default Wrapper(GpsHeatmap);
