import React from 'react';
import * as type from './ShowWifiPassword.type';
import {
    ContainerQr,
    ContentQr,
    CustomButton,
    CustomQR,
    CustomTextfield,
    GridContainer,
    GridItems,
    NoDataContainer,
    ShowWifiPasswordContainer,
    Text,
    TextTitle
} from './ShowWifiPassword.style';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

export const ShowWifiPasswordContent: React.FC<type.ShowWifiPasswordViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <ShowWifiPasswordContainer data-testid='ShowWifiPasswordContent'>
            {props.keyLoading ? (
                <NoDataContainer>
                    <CircularProgress size='1.75em' />
                </NoDataContainer>
            ) : (
                <>
                    {props.keyData.password === null ? (
                        <NoDataContainer>
                            <Text>{translate('t.there_no_data')}</Text>
                        </NoDataContainer>
                    ) : (
                        <>
                            <GridContainer container>
                                <GridItems item xs={12} sm={6} spacing={2}>
                                    <TextTitle>{translate('p.scan_qr_code_network')}</TextTitle>
                                    <ContentQr>
                                        <ContainerQr>
                                            <CustomQR value={props.keyData.password?.toString() ?? ' '} />
                                        </ContainerQr>
                                    </ContentQr>
                                </GridItems>

                                <GridItems item xs={12} sm={6} spacing={2}>
                                    <TextTitle>SSID:</TextTitle>
                                    <Tooltip
                                        placement='top'
                                        arrow
                                        title={
                                            props.copiedSSID
                                                ? `${translate('t.copied')}!`
                                                : translate('t.click_to_copy')
                                        }
                                    >
                                        <CustomTextfield
                                            onClick={() => props.handleCopy(props.keyData.wifiName, 1)}
                                            value={props.keyData.wifiName}
                                            type={'reset'}
                                        />
                                    </Tooltip>
                                    <TextTitle>{translate('t.password')}:</TextTitle>
                                    <Tooltip
                                        arrow
                                        title={
                                            props.copiedPassword
                                                ? `${translate('t.copied')}!`
                                                : translate('t.click_to_copy')
                                        }
                                    >
                                        <CustomTextfield
                                            onClick={() => props.handleCopy(props.keyData.password, 2)}
                                            value={props.keyData.password}
                                            type={props.hidden ? 'password' : 'text'}
                                        />
                                    </Tooltip>

                                    <CustomButton
                                        onClick={props.handleShowPassword}
                                        color='primary'
                                        variant='contained'
                                    >
                                        <FontAwesomeIcon icon={props.hidden ? faEye : faEyeSlash} />
                                    </CustomButton>
                                </GridItems>
                            </GridContainer>
                        </>
                    )}
                </>
            )}
        </ShowWifiPasswordContainer>
    );
};
