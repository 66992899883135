import React, { useMemo, useState } from 'react';
import { AddGlobalAdminContent } from './AddGlobalAdmin.view';
import { Errors, Success } from '../../Popup/Popup';
import * as type from './AddGlobalAdmin.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import Customer from '../../../api/Customer';
import TimeZone from '../../../api/TimeZone';
import DeviceSerialConnectionType from '../../../api/DeviceSerialConnectionType';
import { ITimeZone } from '../../../models/TimeZone.type';
import { DeviceSerialConnectionTypeList } from '../../../models/Device.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../../states/global/User';
import { checkRole } from 'helpers/authentication';
import Location from '../../../api/Location';
import { ROLES } from 'variables';
import { CustomerFormToSend } from '../../../models/Customer.type';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';
import { getSortedTimezone, getTimezoneOffsetFormatted } from 'helpers';
import useConverter from 'components/CustomHooks/Converter/Converter';

const customerApi = new Customer();
const timeZoneApi = new TimeZone();
const deviceSerialConnectionApi = new DeviceSerialConnectionType();
const LocationApi = new Location();
export const parseData = (data): type.listType[0] => {
    return data.codeBook.reduce((acc: type.listType[0], curr) => {
        const offset = getTimezoneOffsetFormatted(curr.timezoneName);
        acc.push({
            id: curr?.id,
            name: curr.connectionType || `${offset} ${curr.timezoneFormatted}`
        });
        return acc;
    }, []);
};
const AddGlobalAdmin: React.FC<type.AddGlobalAdminProps> = (props): JSX.Element => {
    const [timeZoneList, setTimeZoneList] = useState<type.listType[0]>([]);
    const [selectedFile, setSelectedFile] = useState<type.selectedFile>(null);
    const [deviceSerialList, setDeviceSerialList] = useState<type.listType[0]>([]);
    const [previewImg, setPreviewImg] = React.useState<string>('');
    const { t: translate } = useTranslation();
    const [photo, setPhoto] = useState<string>();
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const { dateTimeFormat } = useConverter();

    const handleCapture: type.handleCapture = (e) => {
        const reader = new FileReader();
        const target = e.target as HTMLInputElement;
        reader.onloadend = () => setSelectedFile(reader.result);
        target.files && reader.readAsDataURL(target.files[0]);
        target.files && setPreviewImg(URL.createObjectURL(target.files[0]));
        if (target.files) {
            const siezekiloByte: number = parseInt((target.files[0].size / 1024).toFixed(1));
            if (siezekiloByte > 2048) {
                Errors({ text: translate('t.the_maximum_image_size_is_2mb') });
            }
        }
    };

    const handleSubmit = (values: CustomerFormToSend) => {
        if (selectedFile != null) editLogoMutate({ id: props.customerValues.id, data: selectedFile });

        if (values?.hasExternalSystems) {
            values.hasExternalSystems = values.hasExternalSystems.map((externalSystem) => {
                const customerExternalSystem = props.oldExternalSystems?.find((customerExSystem) => {
                    return customerExSystem.externalSystem.id === externalSystem.id;
                });

                if (customerExternalSystem) {
                    return {
                        id: customerExternalSystem.id
                    };
                }
                return {
                    externalSystem: externalSystem.id
                };
            });
        }

        const formValues = { id: values.id, name: values.name };

        props.editCustomerMutate(isSuperAdmin ? values : formValues);
    };

    const { mutate: editLogoMutate } = useMutation(customerApi.editLogo, {
        onSuccess: () => {
            Success({
                text: translate('t.edited_logo')
            });
        }
    });

    const { isLoading: timeZoneLoading } = useQuery<ITimeZone[], Error>(
        [`TimeZoneList${DateTime.now().toUnixInteger()}`, [props.customerValues.id]],
        () => timeZoneApi.getTimeZoneList(),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            keepPreviousData: true,
            retryOnMount: false,
            refetchOnMount: false,
            onSuccess: (timezoneDataOnSuccess) => {
                setTimeZoneList(parseData({ codeBook: getSortedTimezone(timezoneDataOnSuccess, dateTimeFormat) }));
            }
        }
    );

    const { isLoading: deviceSerialConnectionTypeLoading } = useQuery<DeviceSerialConnectionTypeList, Error>(
        [`DeviceSerialConnectionTypeList${DateTime.now().toUnixInteger()}`, [props.customerValues.id]],
        () => deviceSerialConnectionApi.getDeviceSerialConnectionType(),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            keepPreviousData: true,
            retryOnMount: false,
            refetchOnMount: false,
            onSuccess: (deviceDataOnSuccess) => setDeviceSerialList(parseData(deviceDataOnSuccess))
        }
    );

    const { isLoading: photoDataLoading } = useQuery<string, Error>(
        ['Photo', [props.customerValues.id]],
        () => customerApi.getLogo(props.customerValues.id),
        {
            refetchOnWindowFocus: false,
            onSuccess: (deviceDataOnSuccess) => setPhoto(`data:image/png;base64,${deviceDataOnSuccess}`)
        }
    );

    const { isLoading: isLocationLoading, data: location } = useQuery<any, Error>(
        ['location', props.customerValues.itrackStockPrimaryDeliveryLocationId],
        () =>
            props.customerValues.itrackStockPrimaryDeliveryLocationId &&
            LocationApi.getById({ id: props.customerValues.itrackStockPrimaryDeliveryLocationId, apiProject: 'stock' }),
        {
            refetchOnWindowFocus: false
        }
    );

    return (
        <AddGlobalAdminContent
            data-testid='AddGlobalAdmin-testid'
            timeZoneList={timeZoneList || []}
            deviceSerialList={deviceSerialList || []}
            timeZoneLoading={timeZoneLoading}
            deviceSerialConnectionTypeLoading={deviceSerialConnectionTypeLoading}
            photoDataLoading={photoDataLoading}
            photoData={photo || ''}
            initValues={props.customerValues}
            handleCapture={handleCapture}
            previewImg={previewImg}
            setPreviewImg={setPreviewImg}
            setPhoto={setPhoto}
            selectedFile={selectedFile}
            refEditCustomer={props.refEditCustomer}
            handleSubmit={handleSubmit}
            fullEdit={props.fullEdit}
            isLocationLoading={isLocationLoading}
            location={location}
        />
    );
};

export default Wrapper(AddGlobalAdmin);
