import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const ModalContent = styled.div``;

export const DialogTitle = styled(MuiDialogTitle)`
    &.MuiDialogTitle-root {
        background: #3a3633;
        color: #cdc9c6;
        padding: 7px 24px;
    }
`;

export const LeftTitle = styled.span`
    float: left;
`;

export const RightTitle = styled.span`
    float: right;
`;

export const DialogBody = styled(Dialog)<{ width?: number; height?: number; widthunit?: string; heightunit?: string }>`
    & .MuiDialog-paper {
        width: ${(props) => props.width}${(props) => props.widthunit};
        min-width: ${(props) => props.width}${(props) => props.widthunit};
    }
    & .MuiDialogContent-root {
        height: ${(props) => props.height}${(props) => props.heightunit};
        min-height: ${(props) => props.height}${(props) => props.heightunit};
    }
    & .MuiDialog-paperScrollPaper {
        max-height: max(calc(100% - 67px), ${(props) => (props.height ?? 0) + 120}px);
    }
`;

export const DialogActionsContainer = styled(MuiDialogActions)`
    &.MuiDialogActions-root {
        ${applyStyleByMode({
            styleOld: 'background: rgb(253, 253, 253);'
        })}
        border-top: solid thin rgb(235, 235, 235);
    }
`;

export const CancelButton = styled(Button)<{ customcolor?: string }>`
    && {
        border: thin solid ${(props) => (props.customcolor ? props.customcolor : '#739e73')};
        color: ${(props) => (props.customcolor ? props.customcolor : '#739e73')};
    }
`;
