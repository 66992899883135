import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { useRecoilValue } from 'recoil';
import Button from '../../Ui/Components/UiButton';
import * as style from './GeneralSettingsForm.style';
import * as type from './GeneralSettingsForm.type';
import { CircularProgress, FormControlLabel, Grid, Radio, Switch, TextField } from '@mui/material';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import { SwtichLabel } from './GeneralSettingsForm.style';
import { UserInfo } from 'states/global/User';
import { getListDateFormat, getListTimeFormat, getTimezoneOffsetFormatted } from 'helpers';

export const GeneralSettingsFormik: React.FC<type.FormikValidationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    const schemaValidate = Yup.object({
        language: Yup.string().required(translate('p.this_field_is_required')),
        timeZone: Yup.string().required(translate('p.this_field_is_required')),
        dateFormat: Yup.string().required(translate('p.this_field_is_required')),
        showTimesIntimeZoneMineOrMyLocalTimeZone: Yup.string().required(translate('p.this_field_is_required')),
        timeFormat: Yup.string().required(translate('p.this_field_is_required')),
        loginTimeOutPeriod: Yup.string().required(translate('p.this_field_is_required')),
        showOutServiceVehicles: Yup.string().required('p.this_field_is_required'),
        pressureUnit: Yup.string().required('p.this_field_is_required'),
        temperatureUnit: Yup.string().required('p.this_field_is_required'),
        speedUnit: Yup.string().required('p.this_field_is_required'),
        accelerationUnit: Yup.string().required('p.this_field_is_required'),
        altitudeUnit: Yup.string().required('p.this_field_is_required'),
        statisticsValue: Yup.string().required('p.this_field_is_required'),
        targetPressure: Yup.bool(),
        coldPressure: Yup.bool(),
        showColdPressureForExternalSensors: Yup.bool(),
        showTemperatureForExternalSensors: Yup.bool(),
        showColdPressureInTyreSummary: Yup.bool()
    });

    return (
        <Formik
            initialValues={props.inicialValuesFormik}
            onSubmit={(values: type.InicialValuesForm, actions) => {
                props.handleSubmit(
                    {
                        ...values,
                        dateFormat:
                            (getListDateFormat().find((dateFormat) => dateFormat?.luxonValue === values.dateFormat)
                                ?.value as string) ?? values.dateFormat
                    },
                    actions.setSubmitting
                );
            }}
            validationSchema={schemaValidate}
        >
            {(propsF: FormikProps<type.InicialValuesForm>) => {
                const { errors, setFieldValue, values, isSubmitting } = propsF;

                return (
                    <Form>
                        <Grid container justify-content='flex-start' direction='row'>
                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={getListTimeFormat()}
                                    getOptionLabel={(timeFormat) => timeFormat.name}
                                    onChange={(e, timeFormat) => {
                                        setFieldValue('timeFormat', timeFormat ? timeFormat.value : null);
                                    }}
                                    defaultValue={getListTimeFormat().find(
                                        (timeFormat) => timeFormat.value === values.timeFormat ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.time_format')}
                                            variant='outlined'
                                            name='timeFormat'
                                            value={values.timeFormat}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.timeFormat && translate('p.this_field_is_required')}
                                            error={!!errors.timeFormat}
                                        />
                                    )}
                                    data-testid='Profile-TimeFormat-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={getListDateFormat()}
                                    getOptionLabel={(dateFormat) => dateFormat.name}
                                    onChange={(e, dateFormat) => {
                                        setFieldValue('dateFormat', dateFormat ? dateFormat.value : null);
                                    }}
                                    defaultValue={getListDateFormat().find(
                                        (dateFormat) => dateFormat.luxonValue === values.dateFormat ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.date_format')}
                                            variant='outlined'
                                            name='dateFormat'
                                            value={values.dateFormat}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.dateFormat && translate('p.this_field_is_required')}
                                            error={!!errors.dateFormat}
                                        />
                                    )}
                                    data-testid='Profile-DateFormat-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.loginTimeOutPeriodList}
                                    getOptionLabel={(loginTimeOutPeriod) => loginTimeOutPeriod.name}
                                    onChange={(e, loginTimeOutPeriod) => {
                                        setFieldValue(
                                            'loginTimeOutPeriod',
                                            loginTimeOutPeriod ? loginTimeOutPeriod.value : null
                                        );
                                    }}
                                    defaultValue={props.loginTimeOutPeriodList.find(
                                        (loginTimeOutPeriod) =>
                                            loginTimeOutPeriod.value === values.loginTimeOutPeriod ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.login_time_out_period')}
                                            variant='outlined'
                                            name='loginTimeOutPeriod'
                                            value={values.loginTimeOutPeriod}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.loginTimeOutPeriod && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.loginTimeOutPeriod}
                                        />
                                    )}
                                    data-testid='Profile-LoginTimeOutPeriod-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={4} sm={4} xs={12}>
                                <style.ContainerRadioGroup
                                    row
                                    aria-label='showTime'
                                    name='showTimesIntimeZoneMineOrMyLocalTimeZone'
                                    value={values.showTimesIntimeZoneMineOrMyLocalTimeZone}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'showTimesIntimeZoneMineOrMyLocalTimeZone',
                                            (e.target as HTMLInputElement).value
                                        );
                                    }}
                                >
                                    <FormControlLabel
                                        value='false'
                                        control={<Radio color='primary' />}
                                        label={translate('t.show_times_in_my_local_timezone')}
                                        data-testid='Profile-ShowTimesInMyLocalTimezone-Radio'
                                    />
                                </style.ContainerRadioGroup>
                            </GridFieldFormik>

                            <GridFieldFormik item lg={8} md={8} sm={8} xs={12}>
                                <Autocomplete
                                    options={props.timeZoneData}
                                    disabled={values.showTimesIntimeZoneMineOrMyLocalTimeZone === 'true' ? true : false}
                                    getOptionLabel={(timeZone) => {
                                        const offset = getTimezoneOffsetFormatted(timeZone.timezoneName);
                                        return `${offset} ${timeZone.timezoneFormatted}`;
                                    }}
                                    onChange={(e, timeZone) => {
                                        setFieldValue('timeZone', timeZone ? timeZone?.id : null);
                                    }}
                                    defaultValue={
                                        props.timeZoneData && !!props.timeZoneData.length
                                            ? props.timeZoneData.find((timeZone) => timeZone?.id === values.timeZone)
                                            : null
                                    }
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.local_timezone')}
                                            variant='outlined'
                                            name='timeZone'
                                            value={values.timeZone}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.timeZone && translate('p.this_field_is_required')}
                                            error={!!errors.timeZone}
                                            data-testid='Profile-LocalLimezone-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={4} sm={4} xs={12}>
                                <style.ContainerRadioGroup
                                    row
                                    aria-label='showTime'
                                    name='showTimesIntimeZoneMineOrMyLocalTimeZone'
                                    value={values.showTimesIntimeZoneMineOrMyLocalTimeZone}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'showTimesIntimeZoneMineOrMyLocalTimeZone',
                                            (e.target as HTMLInputElement).value
                                        );
                                    }}
                                >
                                    <FormControlLabel
                                        value='true'
                                        control={<Radio color='primary' />}
                                        label={translate('t.show_times_in_the_timezone_of_the_mine')}
                                        data-testid='Profile-ShowTimesInTheTimezoneOfTheMine-Radio'
                                    />
                                </style.ContainerRadioGroup>
                            </GridFieldFormik>

                            <GridFieldFormik item lg={8} md={8} sm={8} xs={12}>
                                <FormControlLabel
                                    value='showOutServiceVehicles'
                                    control={
                                        <Switch
                                            color='primary'
                                            name='showOutServiceVehicles'
                                            checked={values.showOutServiceVehicles}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'showOutServiceVehicles',
                                                    (e.target as HTMLInputElement).checked
                                                );
                                            }}
                                            data-testid='Profile-ShowOutOfService-Switch'
                                        />
                                    }
                                    label={translate('t.show_out_of_service')}
                                    labelPlacement='end'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={12} md={12} sm={12} xs={12}>
                                <div className='subTitleSubForm'>
                                    <span className='titleContentTab'>{translate('t.units_settings')}</span>
                                </div>
                            </GridFieldFormik>

                            <GridFieldFormik item lg={3} md={3} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.pressureUnitList}
                                    getOptionLabel={(pressureUnit) => pressureUnit.name}
                                    onChange={(e, pressureUnit) => {
                                        setFieldValue('pressureUnit', pressureUnit ? pressureUnit.value : null);
                                    }}
                                    defaultValue={props.pressureUnitList.find(
                                        (pressureUnit) => pressureUnit.value === values.pressureUnit ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.pressure_unit')}
                                            variant='outlined'
                                            name='pressureUnit'
                                            value={values.pressureUnit}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.pressureUnit && translate('p.this_field_is_required')}
                                            error={!!errors.pressureUnit}
                                        />
                                    )}
                                    data-testid='Profile-PressureUnit-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={3} md={3} sm={6} xs={12}>
                                <Autocomplete
                                    options={[
                                        { value: 'c', name: '°C' },
                                        { value: 'f', name: '°F' }
                                    ]}
                                    getOptionLabel={(temperatureUnit) => temperatureUnit.name}
                                    onChange={(e, temperatureUnit) => {
                                        setFieldValue(
                                            'temperatureUnit',
                                            temperatureUnit ? temperatureUnit.value : null
                                        );
                                    }}
                                    defaultValue={[
                                        { value: 'c', name: '°C' },
                                        { value: 'f', name: '°F' }
                                    ].find((temperatureUnit) => temperatureUnit.value === values.temperatureUnit ?? '')}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.temperature_unit')}
                                            variant='outlined'
                                            name='temperatureUnit'
                                            value={values.temperatureUnit}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.temperatureUnit && translate('p.this_field_is_required')}
                                            error={!!errors.temperatureUnit}
                                        />
                                    )}
                                    data-testid='Profile-TemperatureUnit-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={3} md={3} sm={6} xs={12}>
                                <Autocomplete
                                    options={[
                                        { value: 'km/h', name: 'km/h' },
                                        { value: 'mph', name: 'mph' }
                                    ]}
                                    getOptionLabel={(speedUnit) => speedUnit.name}
                                    onChange={(e, speedUnit) => {
                                        setFieldValue('speedUnit', speedUnit ? speedUnit.value : null);
                                    }}
                                    defaultValue={[
                                        { value: 'km/h', name: 'km/h' },
                                        { value: 'mph', name: 'mph' }
                                    ].find((speedUnit) => speedUnit.value === values.speedUnit ?? '')}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.speed_unit')}
                                            variant='outlined'
                                            name='speedUnit'
                                            value={values.speedUnit}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.speedUnit && translate('p.this_field_is_required')}
                                            error={!!errors.speedUnit}
                                        />
                                    )}
                                    data-testid='Profile-SpeedUnit-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={3} md={3} sm={6} xs={12}>
                                <Autocomplete
                                    options={[
                                        { value: 'm/s2', name: 'm/s²' },
                                        { value: 'ft/s2', name: 'ft/s²' }
                                    ]}
                                    getOptionLabel={(accelerationUnit) => accelerationUnit.name}
                                    onChange={(e, accelerationUnit) => {
                                        setFieldValue(
                                            'accelerationUnit',
                                            accelerationUnit ? accelerationUnit.value : null
                                        );
                                    }}
                                    defaultValue={[
                                        { value: 'm/s2', name: 'm/s²' },
                                        { value: 'ft/s2', name: 'ft/s²' }
                                    ].find(
                                        (accelerationUnit) => accelerationUnit.value === values.accelerationUnit ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.acceleration_unit')}
                                            variant='outlined'
                                            name='accelerationUnit'
                                            value={values.accelerationUnit}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.accelerationUnit && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.accelerationUnit}
                                        />
                                    )}
                                    data-testid='Profile-AccelerationUnit-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={3} md={3} sm={5} xs={12}>
                                <Autocomplete
                                    options={[
                                        { value: 'm', name: 'm' },
                                        { value: 'ft', name: 'ft' }
                                    ]}
                                    getOptionLabel={(altitudeUnit) => altitudeUnit.name}
                                    onChange={(e, altitudeUnit) => {
                                        setFieldValue('altitudeUnit', altitudeUnit ? altitudeUnit.value : null);
                                    }}
                                    defaultValue={[
                                        { value: 'm', name: 'm' },
                                        { value: 'ft', name: 'ft' }
                                    ].find((altitudeUnit) => altitudeUnit.value === values.altitudeUnit ?? '')}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.altitude_unit')}
                                            variant='outlined'
                                            name='altitudeUnit'
                                            value={values.altitudeUnit}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.altitudeUnit && translate('p.this_field_is_required')}
                                            error={!!errors.altitudeUnit}
                                        />
                                    )}
                                    data-testid='Profile-AltitudeUnit-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={6} md={9} sm={9} xs={12}>
                                <Autocomplete
                                    options={[
                                        { value: 'max', name: 'Maximum' },
                                        { value: 'avg', name: 'Average' }
                                    ]}
                                    getOptionLabel={(statisticsValue) => statisticsValue.name}
                                    onChange={(e, statisticsValue) => {
                                        setFieldValue(
                                            'statisticsValue',
                                            statisticsValue ? statisticsValue.value : null
                                        );
                                    }}
                                    defaultValue={[
                                        { value: 'max', name: 'Maximum' },
                                        { value: 'avg', name: 'Average' }
                                    ].find((statisticsValue) => statisticsValue.value === values.statisticsValue ?? '')}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.use_max_avg_statistics')}
                                            variant='outlined'
                                            name='statisticsValue'
                                            value={values.statisticsValue}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.statisticsValue && translate('p.this_field_is_required')}
                                            error={!!errors.statisticsValue}
                                        />
                                    )}
                                    data-testid='Profile-UseMaxAvgStatistics-Autocomplete'
                                />
                            </GridFieldFormik>
                            <Grid container lg={12} md={12} sm={12} xs={12}>
                                <GridFieldFormik item lg={3} md={3} sm={12} xs={12}>
                                    <SwtichLabel
                                        style={{
                                            textAlign: 'left'
                                        }}
                                        value={values.targetPressure}
                                        control={
                                            <Switch
                                                color='primary'
                                                checked={values.targetPressure}
                                                value={values.targetPressure}
                                                name='targetPressure'
                                                data-testid='Profile-ShowTargetPressure-Switch'
                                            />
                                        }
                                        label={translate('t.show_target_pressure')}
                                        labelPlacement='end'
                                        onChange={(e) => {
                                            setFieldValue('targetPressure', (e.target as HTMLInputElement).checked);
                                        }}
                                    />
                                </GridFieldFormik>

                                <GridFieldFormik item lg={3} md={3} sm={12} xs={12}>
                                    <SwtichLabel
                                        value={values.coldPressure == 1}
                                        control={
                                            <Switch
                                                color='primary'
                                                checked={values.coldPressure == 1}
                                                name='coldPressure'
                                                data-testid='Profile-ShowColdPressure-Switch'
                                            />
                                        }
                                        label={translate('t.show_cold_pressure')}
                                        labelPlacement='end'
                                        onChange={(e) => {
                                            setFieldValue(
                                                'coldPressure',
                                                (e.target as HTMLInputElement).checked ? 1 : 0
                                            );
                                            if (!(e.target as HTMLInputElement).checked) {
                                                setFieldValue('showColdPressureForExternalSensors', 0);
                                            }
                                        }}
                                    />
                                </GridFieldFormik>
                                <GridFieldFormik item lg={3} md={3} sm={12} xs={12}>
                                    <SwtichLabel
                                        value={!values.coldPressure ? false : values.showColdPressureForExternalSensors}
                                        control={
                                            <Switch
                                                color='primary'
                                                checked={
                                                    !values.coldPressure
                                                        ? false
                                                        : values.showColdPressureForExternalSensors
                                                }
                                                name='showColdPressureForExternalSensors'
                                                data-testid='Profile-showColdPressureForExternalSensors-Switch'
                                            />
                                        }
                                        disabled={!values.coldPressure}
                                        label={translate('t.show_cold_pressure_external_sensor')}
                                        labelPlacement='end'
                                        onChange={(e) => {
                                            setFieldValue(
                                                'showColdPressureForExternalSensors',
                                                (e.target as HTMLInputElement).checked ? 1 : 0
                                            );
                                        }}
                                    />
                                </GridFieldFormik>
                                <GridFieldFormik item lg={3} md={3} sm={12} xs={12}>
                                    <SwtichLabel
                                        value={
                                            !userInfo.user?.customer?.externalTemperatureEnabled
                                                ? false
                                                : values.showTemperatureForExternalSensors
                                        }
                                        control={
                                            <Switch
                                                color='primary'
                                                checked={
                                                    !userInfo.user?.customer?.externalTemperatureEnabled
                                                        ? false
                                                        : values.showTemperatureForExternalSensors
                                                }
                                                name='showTemperatureForExternalSensors'
                                                data-testid='Profile-showTemperatureForExternalSensors-Switch'
                                            />
                                        }
                                        label={translate('t.show_temperature_external_sensor')}
                                        labelPlacement='end'
                                        onChange={(e) => {
                                            setFieldValue(
                                                'showTemperatureForExternalSensors',
                                                (e.target as HTMLInputElement).checked ? 1 : 0
                                            );
                                        }}
                                        disabled={!userInfo.user?.customer?.externalTemperatureEnabled}
                                    />
                                </GridFieldFormik>
                                <GridFieldFormik item lg={3} md={3} sm={12} xs={12}>
                                    <SwtichLabel
                                        value={values.showColdPressureInTyreSummary}
                                        control={
                                            <Switch
                                                color='primary'
                                                checked={values.showColdPressureInTyreSummary}
                                                name='showColdPressureInTyreSummary'
                                                data-testid='Profile-showColdPressureInTyreSummary-Switch'
                                            />
                                        }
                                        label={translate('t.show_cold_pressure_tyre_summary')}
                                        labelPlacement='end'
                                        onChange={(e) => {
                                            setFieldValue(
                                                'showColdPressureInTyreSummary',
                                                (e.target as HTMLInputElement).checked ? 1 : 0
                                            );
                                        }}
                                    />
                                </GridFieldFormik>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <style.ContainerButton>
                                    <Button
                                        testid='Save-Form-Button-GeneralSettingsFormik'
                                        variant='contained'
                                        type='submit'
                                        disabled={isSubmitting}
                                        skin='success'
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                    </Button>
                                </style.ContainerButton>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
