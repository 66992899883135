import { atom } from 'recoil';

type FiltersTypes = {
    filterBy: number;
    filterByVehicleType?: number;
    sortBy: string;
    showFilters: boolean;
};

export const VehicleFiltersAtom = atom<FiltersTypes>({
    key: 'VehicleFiltersAtom',
    default: {
        filterBy: 0,
        filterByVehicleType: undefined,
        sortBy: 'name:asc',
        showFilters: false
    }
});
