import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { BACKGROUND_DARK_MODE, BACKGROUND_PAPER_DARK_MODE, PRIMARY, WHITE } from 'components/Ui/colors';
import Typography from '@mui/material/Typography';
import { Alert, Table, TableHead, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleActionsViewContent = styled.div``;

export const CustomTextfield = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTextField-root {
                margin: 8px;
                margin-left: 0px;
                && label {
                    padding: 0 5px 0 0;
                    background: #fff;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.9);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: #3a3633;
                }
                & svg {
                    fill: #999999;
                }
            }
        `,
        styleJade: `
            &.MuiTextField-root {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        `
    })}
`;

export const VehicleLayoutCover = styled(Box)`
    &.MuiBox-root {
        display: inline-block;
        width: 100%;
        ${applyStyleByMode({
            styleOld: 'height: 276px;'
        })}
    }
`;

export const Content = styled(Paper)<{ selected?: boolean }>`
    &.MuiPaper-root {
        padding: 6px;
        margin: 10px;
        text-align: center;
        border: thin solid #bfbfbf;
        display: inline-block;
        width: 122px;
        cursor: pointer;
        border-width: ${(props) => (props.selected ? '3px' : 'thin')};
        border-color: ${(props) => (props.selected ? PRIMARY : '#bfbfbf')};
        :hover {
            border-color: ${PRIMARY};
        }
    }
`;

export const VehicleLayoutLabel = styled.span`
    position: absolute;
    margin-left: 8px;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.54);
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            styleJade: `
                margin-top: -14px;
                font-size: ${common.textSize};
            `,
            light: `background: ${WHITE};`,
            dark: `
                color: ${WHITE};
                background: ${BACKGROUND_PAPER_DARK_MODE};
            `
        });
    }}
`;

export const VehicleLayouContent = styled.div`
    width: 160px;
    height: 155px;
    border: thin #bfbfbf solid;
    border-radius: 4px;
    position: relative;
    padding-bottom: 20px;
    ${applyStyleByMode({
        styleOld: `
            margin-top: 10px;
        `,
        styleJade: `
            font-size: 14px;
            margin-top: 20px;
        `
    })}
`;

export const CustomAutocomplete = styled(Autocomplete)<{ $withoutLabel?: boolean }>`
    ${(props) => {
        return applyStyleByMode({
            styleOld: `
                &.MuiAutocomplete-root {
                    width: 100%;
                    margin: 8px 8px 8px 0px;
                    ${props.$withoutLabel && 'margin-top: 16px;'}
                    margin-left: 0;
                    && label {
                        padding: 0 5px 0 0;
                        background: #fff;
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(14px, -6px) scale(0.9);
                    }
                    & .Mui-disabled:not(.MuiInputLabel-shrink) {
                        color: ${PRIMARY};
                    }
                }
            `,
            styleJade: `
                &.MuiAutocomplete-root {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }
            `
        });
    }}
`;

export const RequestProgress = styled(CircularProgress)({
    color: '#4f714f !important',
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});

export const RemoveTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Text = styled(Typography)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTypography-root {
                font-size: 15px;
            }
        `
    })}
`;

export const AlertInfo = styled(Alert)`
    margin: 5px 0;
`;

export const TextDiv = styled.div`
    margin: 5px 0;
`;

export const SingleVehicleConfig = styled.div`
    height: 160px;
    width: 160px;
    padding-top: 5px;
`;

export const TooltipText = styled.div`
    margin: 5px;
    max-width: 200px;
`;

export const StyledTable = styled(Table)`
    border: thin solid #d1d1d1;
`;

export const StyledTableHead = styled(TableHead)`
    background-color: #eaeaea;
`;

export const CustomBox = styled(Box)<{ $height: string }>`
    height: ${(props) => props.$height};
    border: solid thin #bfbfbf;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px 10px;
    position: relative;
`;

export const HubBayContainer = styled.div`
    padding: 10px 0px;
    border: solid thin #bfbfbf;
    border-radius: 4px;
    width: 100px;
    cursor: not-allowed;
    margin-top: 5px;
    text-align: center;
    ${() => {
        const theme = useTheme();
        return applyStyleByMode({
            theme: theme.palette.mode,
            light: `background: ${WHITE};`,
            dark: `background: ${BACKGROUND_DARK_MODE};`
        });
    }}
`;

export const HubBayIconLock = styled.div`
    width: 100%;
    border-left: solid thin #bfbfbf;
    height: 40px;
    & svg {
        margin-top: 13px;
    }
`;
