import React, { useState, useEffect } from 'react';
import { VehicleDetailsSummaryContent } from './VehicleDetailsSummary.view';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Success } from '../../Popup/Popup';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import SensorLog from '../../../api/SensorLog';
import { PassingVehicleModel } from '../../../models/SensorLog.type';
import DeviceCommand from 'api/DeviceCommand';
import { VehicleModel } from 'models/Vehicle.type';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { PassingVehicle } from './VehicleDetailsSummary.style';

const apiSensorLog = new SensorLog();

const VehicleDetailsSummary: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const [passingVehicles, setPassingVehicles] = useState<PassingVehicleModel[] | null>(null);
    const DeviceCommandApi = new DeviceCommand();

    const { isFetching: passingVehiclesLoading, refetch: passingVehiclesFetching } = useQuery(
        ['passingVehiclesData'],
        () => {
            if ((vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id) {
                return apiSensorLog.getVehiclePassing((vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id);
            }
        },
        {
            enabled: false,
            onSuccess: (result) => setPassingVehicles(result?.passingVehicles ?? [])
        }
    );

    const { mutate: setScreenshot } = useMutation(
        (mac: string) =>
            DeviceCommandApi.setSensorTypes({
                deviceIds: [(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.device?.id ?? 0],
                command_id: 8,
                value: `{"cmd":1,"param":"${mac}", "code": 8}`,
                commandName: 'set_sensors'
            }),
        {
            onSuccess: () => {
                Success({ text: `${translate('p.screenshot_request_sent_tablet')}` });
            }
        }
    );

    useEffect(() => {
        return () => {
            setPassingVehicles(null);
        };
    }, []);

    const serialConnectionStatus = (sc: number, scc: number): string => {
        if (sc < 0) {
            return translate('t.error');
        } else if (sc === 0) {
            return translate('t.disconnected');
        } else if (sc === 1) {
            return translate('t.no_data_exchange');
        } else if (sc === 2 && scc > 0) {
            return translate('t.connected');
        }
        return translate('t.unknown');
    };

    const handleScreenshotButton = async (mac: string): Promise<void> => {
        if ((vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.device) {
            setScreenshot(mac);
        }
    };

    const showButtonPass = (): JSX.Element | JSX.Element[] | undefined => {
        if (passingVehicles != null) {
            if (passingVehicles.length > 0) {
                return passingVehicles.map((vehicle, index) => (
                    <PassingVehicle className='passing-vehicle' key={index}>
                        {typeof vehicle === 'object' ? vehicle['name'] : vehicle}
                    </PassingVehicle>
                ));
            }
            return <>{translate('t.no_passing_vehicles')}</>;
        } else {
            return (
                <UiButton
                    variant='contained'
                    skin='success'
                    size='small'
                    type='button'
                    onClick={() => passingVehiclesFetching()}
                    loading={passingVehiclesLoading}
                    testid='SummaryTab-PassingVehicles-Button'
                >
                    {translate('t.passing_vehicles')}
                </UiButton>
            );
        }
    };

    return (
        <VehicleDetailsSummaryContent
            data-testid={'VehicleDetailsSummary-testid'}
            handleScreenshotButton={handleScreenshotButton}
            serialConnectionStatus={serialConnectionStatus}
            passingVehiclesData={passingVehicles}
            passingVehiclesFetching={passingVehiclesFetching}
            showButtonPass={showButtonPass}
        />
    );
};

export default VehicleDetailsSummary;
