import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { PRIMARY_SEMI_LIGHT } from '../Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const NotTransmittingPopoverContainer = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            styleOld: `
                && .MuiTabs-root {
                    min-width: 109px;
                    min-height: 30px;
                }
                && .MuiTab-root {
                    min-width: 109px;
                    min-height: 30px;
                }
                && .MuiTabs-indicator {
                    background-color: ${PRIMARY_SEMI_LIGHT};
                }
            `,
            styleJade: `
                && .MuiTypography-root {
                    font-size: ${common.textSize};
                }
        
            `
        });
    }}
`;

export const LoadingContent = styled.div`
    text-align: center;
`;
