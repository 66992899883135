import React from 'react';
import { TyresSummaryContent } from './TyresSummary.view';
import SensorLog from '../../../api/SensorLog';
import { TyreDeviation } from '../../../models/SensorLog.type';
import { DateTimePicker, SelectedVehicle } from '../../../states/global/Statistics';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import useConverter from '../../CustomHooks/Converter/Converter';
import { TyresSummaryProps } from './TyresSummary.type';
import { Wrapper } from 'helpers/wrapper';

const TyresSummary: React.FC<TyresSummaryProps> = (): JSX.Element => {
    const SensorLogAPI = new SensorLog();
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const { fromTimezoneToUTC } = useConverter();

    const { isLoading, data } = useQuery<TyreDeviation>(
        ['GetTyreDeviationData', statisticsDateTime.current, selectedVehicle],
        () =>
            SensorLogAPI.getTyreDeviationByVehicleId(
                selectedVehicle.id,
                fromTimezoneToUTC(statisticsDateTime.current.dateFrom),
                fromTimezoneToUTC(statisticsDateTime.current.dateTo)
            ),
        {
            refetchInterval: false
        }
    );

    return <TyresSummaryContent data-testid='TyresSummary-testid' data={data} isLoading={isLoading} />;
};

export default Wrapper(TyresSummary);
