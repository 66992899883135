import React from 'react';
import { SoftwarePackagesContent } from './SoftwarePackages.view';
import { SoftwarePackagesProps } from './SoftwarePackages.type';
import { Wrapper } from 'helpers/wrapper';

const SoftwarePackages: React.FC<SoftwarePackagesProps> = (props): JSX.Element => {
    return <SoftwarePackagesContent {...props} data-testid='SoftwarePackages-testid' />;
};

export default Wrapper(SoftwarePackages);
