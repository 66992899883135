import React, { useEffect, useMemo } from 'react';
import { SensorManagementRowView } from './SensorManagementRow.view';
import { SensorManagementRowType } from './SensorManagementRow.type';
import { CRUD } from 'variables';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { SensorStateAtom } from './SensorManagementRow.atom';
import { SensorStateDispatcher } from './SensorManagementRow.reducer';
import { Status } from 'models/SensorInfo.type';

const SensorManagementRow = ({ attachedSensor, switchPositions }: SensorManagementRowType): JSX.Element => {
    const position = useMemo(() => attachedSensor?.sensor.priority || 0, [attachedSensor]);
    const [sensorState, setSensorState] = useRecoilState(SensorStateAtom(position));
    const dispatch = SensorStateDispatcher(sensorState, setSensorState);
    const resetSensorState = useResetRecoilState(SensorStateAtom(position));

    useEffect(() => {
        setSensorState(
            attachedSensor
                ? {
                      action: CRUD.REMOVE,
                      typeId: attachedSensor.sensor.sensorType?.id || 1,
                      originalSensor: {
                          id: attachedSensor.sensor.id,
                          serialNumber: attachedSensor.sensor.sensorSerialNumber,
                          serialNumberHex: attachedSensor.sensor.serialNumberHex,
                          isExternal: attachedSensor.sensor.sensorType?.id === 2,
                          isFaulty:
                              attachedSensor.sensor.sensorInfo?.status === Status.FAULT ||
                              attachedSensor.sensor.sensorInfo?.status === Status.SUSPICIOUS
                      },
                      newSensor: {
                          id: attachedSensor.sensor.id,
                          serialNumber: attachedSensor.sensor.sensorSerialNumber,
                          serialNumberHex: attachedSensor.sensor.serialNumberHex,
                          isExternal: attachedSensor.sensor.sensorType?.id === 2,
                          isFaulty:
                              attachedSensor.sensor.sensorInfo?.status === Status.FAULT ||
                              attachedSensor.sensor.sensorInfo?.status === Status.SUSPICIOUS
                      }
                  }
                : {
                      typeId: 1,
                      action: CRUD.ADD
                  }
        );
        return () => {
            resetSensorState();
        };
    }, [attachedSensor]);

    return <SensorManagementRowView switchPositions={switchPositions} position={position} dispatch={dispatch} />;
};

export default SensorManagementRow;
