import React from 'react';
import { ExportedReportsProps } from './ExportedReports.type';
import ExportedReports from '../../components/Mixs/ExportedReports/ExportedReports';
import { Wrapper } from 'helpers/wrapper';

const ExportedReportsPage: React.FC<ExportedReportsProps> = (): JSX.Element => {
    return <ExportedReports />;
};

export default Wrapper(ExportedReportsPage);
