import React from 'react';
import { useRecoilState } from 'recoil';
import { TopBarAtom } from './TopBar/TopBar.atom';
import Wheel from '../../../api/Wheel';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { WheelCodebook } from '../../../models/Wheel.type';
import { MenuItem } from '@mui/material';
import { WheelPositionTextField } from './TopBar/TopBar.style';
import HelperText from '../HelperText';

const wheelAPI = new Wheel();

const WheelPositionSelect: React.FC = (): JSX.Element => {
    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const { t: translate } = useTranslation();

    const { data } = useQuery<WheelCodebook>(
        ['WheelCodebook', topBarAtom.vehicle.id],
        () => wheelAPI.getCodeBook(topBarAtom.vehicle.id),
        {
            enabled: topBarAtom.vehicle.id > -1,
            retry: false
        }
    );

    const createMenuOptions = (): JSX.Element[] => {
        let options: JSX.Element[] = [
            <MenuItem value={0} key={0}>
                {translate('t.all_wheels')}
            </MenuItem>
        ];

        if (data?.codeBook && topBarAtom.vehicle.id > 0) {
            data.codeBook.sort((a, b) => {
                return a.customPosition > b.customPosition ? 1 : -1;
            });
            for (let i = 0; i < data?.codeBook.length; i++) {
                const wheelPos = data.codeBook[i].customPosition;
                options.push(
                    <MenuItem value={wheelPos} key={wheelPos}>
                        {wheelPos}
                    </MenuItem>
                );
            }
        } else {
            for (let i = 1; i < 13; i++) {
                options.push(
                    <MenuItem value={i} key={i}>
                        {i}
                    </MenuItem>
                );
            }
        }

        return options;
    };

    return (
        <WheelPositionTextField
            data-testid='data-export-position-select'
            id='wheel-select'
            select
            size='small'
            value={topBarAtom.wheelPosition || 0}
            label={translate('t.wheel_position')}
            onChange={(event) => {
                setTopBarAtom({ ...topBarAtom, wheelPosition: parseInt(event.target.value) });
            }}
            variant='outlined'
            disabled={!(topBarAtom.vehicle.id > -1)}
            helperText={<HelperText />}
        >
            {createMenuOptions()}
        </WheelPositionTextField>
    );
};

export default WheelPositionSelect;
