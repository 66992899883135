import React from 'react';
import {
    ColumnRight,
    ContainerLinkToKB,
    NotificationTireTresholdsLegendContainer
} from './NotificationTireTresholdsLegend.style';
import { NotificationTireTresholdsLegendProps } from './NotificationTireTresholdsLegend.type';
import { Alert } from '@mui/material';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useRecoilValue } from 'recoil';
import useConverter from 'components/CustomHooks/Converter/Converter';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { useTranslation } from 'react-i18next';
import UiPopper from 'components/Ui/UiPopper/UiPopper';

const NotificationTireTresholdsLegendContent: React.FC<NotificationTireTresholdsLegendProps> = ({
    popperPlacementType
}): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);
    const { fromServerToUserUnit, convertType } = useConverter();
    const { t: translate } = useTranslation();

    const notificationTyrePressureThreshold: string = fromServerToUserUnit({
        type: convertType.pressure,
        value: customerSettings?.notification_tyre_pressure_threshold || 0,
        displayUnits: true,
        fixed: 0,
        displayIfEmpty: '---'
    });

    const notificationTyreTemperatureThreshold: string = fromServerToUserUnit({
        type: convertType.temperature,
        value: customerSettings?.notification_tyre_temperature_threshold || 0,
        displayUnits: true,
        fixed: 0,
        displayIfEmpty: '---'
    });

    const LegendContent: React.FC = (): JSX.Element => (
        <table>
            <tbody>
                <tr>
                    <td>{translate('t.notification_tyre_pressure_threshold')}:</td>
                    <ColumnRight>
                        {translate('t.the_default_value_is')} {notificationTyrePressureThreshold}.
                    </ColumnRight>
                </tr>
                <tr>
                    <td>{translate('t.notification_tyre_temperature_threshold')}:</td>
                    <ColumnRight>
                        {translate('t.the_default_value_is')} {notificationTyreTemperatureThreshold}.
                    </ColumnRight>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <ContainerLinkToKB>
                            <UiLink
                                openNewTab
                                $padding='0'
                                content={translate('t.see_the_documentation')}
                                url='https://help.atmstechnology.com/get-started/alerts-viewing'
                            />
                        </ContainerLinkToKB>
                    </td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <NotificationTireTresholdsLegendContainer data-testid='NotificationTireTresholdsLegendContent'>
            {
                <Alert
                    severity='info'
                    action={
                        <UiPopper
                            titlePopper={translate('t.notification_tire_tresholds')}
                            content={<LegendContent />}
                            popperPlacementType={popperPlacementType}
                            rootElemnet={
                                <UiButton testid='NotificationTireTresholdsLegend-OpenPopup-Button' variant='outlined'>
                                    {translate('t.notification_tire_tresholds')}
                                </UiButton>
                            }
                        />
                    }
                />
            }
        </NotificationTireTresholdsLegendContainer>
    );
};

export default NotificationTireTresholdsLegendContent;
