import React, { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { AlertsAffectedVehiclesFilterViewContent } from './AlertsAffectedVehiclesFilter.style';
import { AlertsAffectedVehiclesFilterProps } from './AlertsAffectedVehiclesFilter.type';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { FilterVehicleValue } from '../AlertsAffectedVehicles/atom';

const AlertsAffectedVehiclesFilterContent: React.FC<AlertsAffectedVehiclesFilterProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [filterValue, setFilterValue] = useRecoilState(FilterVehicleValue);
    const resetFilterValue = useResetRecoilState(FilterVehicleValue);

    const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterValue({ inpuValue: e.target.value, searchValue: e.target.value });
    };

    useEffect(() => {
        return () => resetFilterValue();
    }, []);

    return (
        <AlertsAffectedVehiclesFilterViewContent data-testid='AlertsAffectedVehiclesFilterContent'>
            <TextField
                size='small'
                variant='outlined'
                fullWidth
                onChange={handleChangeInputSearch}
                value={filterValue.inpuValue}
                className='inputSearchAutoWidth'
                placeholder={translate('t.vehicle_name')}
                data-testid={'AffectedVehiclesSearch'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end' data-testid='SearchIcon'>
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
        </AlertsAffectedVehiclesFilterViewContent>
    );
};

export default AlertsAffectedVehiclesFilterContent;
