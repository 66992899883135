import { AttachedSensor } from '../../../models/Sensors.type';
import { deepCopyObj } from 'helpers';

type DispatcherType = {
    priority1: number;
    priority2: number;
};

export const Dispatcher = (state: AttachedSensor[], newSensors: DispatcherType): AttachedSensor[] => {
    let newState: AttachedSensor[] = deepCopyObj(state);

    const { priority1, priority2 } = newSensors;

    [newState[priority1 - 1], newState[priority2 - 1]] = [newState[priority2 - 1], newState[priority1 - 1]];

    newState[priority1 - 1].sensor.priority = priority1;
    newState[priority2 - 1].sensor.priority = priority2;

    return newState;
};
