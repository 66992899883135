import { atom } from 'recoil';
import { TyreDetail } from '../../../models/Wheel.type';

export type VehicleDetailsActionMenuProps = {
    isOpen: boolean;
    positionX: number;
    positionY: number;
    data?: TyreDetail;
};

export const VehicleDetailsActionMenuAtom = atom<VehicleDetailsActionMenuProps>({
    key: 'VehicleDetailsActionMenuAtom',
    default: {
        isOpen: false,
        positionX: 0,
        positionY: 0
    }
});
