import { ITimeZone } from '../../../models/TimeZone.type';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';

export type AddUserFormProps = {};

export type AddUserFormViewProps = {
    inicialvaluesForm: InicialvaluesForm;
    handleSubmit: (e: InicialvaluesForm) => void;
    selectedFile: string | ArrayBuffer | null;
    previewImg: string;
    setPreviewImg: (img: string) => void;
    handleCapture: (e: React.ChangeEvent) => void;
    sendform: boolean;
    timeZoneData: ITimeZone[];
    mapTypeList: optionsCodeBook[];
    timeZoneLoading: boolean;
    loading: boolean;
    handleValidateUserName: (
        userName: string,
        setUserNameValid: React.Dispatch<React.SetStateAction<string | null>>,
        msgToShow: string
    ) => Promise<void>;
};

export type InicialvaluesForm = {
    username: string;
    email: string;
    role: number;
    phoneNumber: string;
    firstname: string;
    lastname: string;
    avatar?: string | ArrayBuffer;
    password: string;
    confirmPassword: string;
    timeZone: number;
    mapType: string;
    resetPassword: boolean;
};

export type CommonDropDown = {
    id: number | string;
    name: string;
};

export enum AddUserActions {
    SET_USER_AVATAR = 'SET_USER_AVATAR',
    SET_USER_PREVIEW_IMG = 'SET_USER_PREVIEW_IMG',
    SET_USER_LOADING = 'SET_USER_LOADING',
    SET_USER_ID = 'SET_USER_ID'
}

export type AddUserState = {
    userAvatar: string | ArrayBuffer | null;
    previewImg: string;
    userLoading: boolean;
};

export type UserAvatarActionType = {
    type: AddUserActions.SET_USER_AVATAR;
    payload: string | ArrayBuffer;
};

export type UserPreviewImgActionType = {
    type: AddUserActions.SET_USER_PREVIEW_IMG;
    payload: string;
};

export type UserLoadingActionType = {
    type: AddUserActions.SET_USER_LOADING;
    payload: boolean;
};
