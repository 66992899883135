import PortalApi from 'helpers/api/PortalApiClient';
import { DropdownModel, TableModel, TableModelResponse } from '../models/VehicleType.type';
import { getParams } from 'helpers';

export default class VehicleType extends PortalApi<DropdownModel | TableModel> {
    route = 'vehicle-type';

    getAll = async (props): Promise<TableModelResponse> => {
        return await this.getTable<TableModel>({
            version: 'v2',
            criteria: getParams(props)
        });
    };

    getExport = async (props): Promise<string> => {
        return await this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams(props)
        });
    };
}
