import React from 'react';
import { HubUpdaterBatchesContent } from './HubUpdaterBatches.view';
import { HubUpdaterBatchesProps } from './HubUpdaterBatches.type';
import { Wrapper } from 'helpers/wrapper';

const HubUpdaterBatches: React.FC<HubUpdaterBatchesProps> = (): JSX.Element => {
    return <HubUpdaterBatchesContent data-testid='HubUpdaterBatches-testid' />;
};

export default Wrapper(HubUpdaterBatches);
