import { atom } from 'recoil';
import { RangePositionCalendar } from './Components/DateTimeDialogSelector/DateTimeRangeDialogSelector.type';

export const RangePosition = atom<RangePositionCalendar>({
    key: 'RangePositionAtom',
    default: RangePositionCalendar.START
});

export const LastChangedValue = atom<RangePositionCalendar>({
    key: 'LastChangedValueAtom',
    default: RangePositionCalendar.START
});
