import { atom, selector } from 'recoil';

type AlertsBreakdownPeriodTitleType = {
    translationKey: string;
    value: number;
};

export type AlertsBreakdownVehicleType = {
    value: number | string;
    title: string;
};

export const AlertsBreakdownPeriod = atom<number>({
    key: 'AlertsBreakdownPeriod',
    default: Number(window.localStorage.getItem('AlertsBreakdownPeriod')) || 24
});

export const AlertsBreakdownPeriodTitle = selector<AlertsBreakdownPeriodTitleType>({
    key: 'AlertsBreakdownPeriodTitle',
    get: async ({ get }) => {
        const hrs = get(AlertsBreakdownPeriod);
        let titleData = {
            translationKey: 't.days',
            value: hrs / 24
        };
        if (hrs <= 48) {
            titleData = {
                translationKey: 't.hrs',
                value: hrs
            };
        }

        return titleData;
    }
});

export const AlertsBreakdownVehicle = atom<AlertsBreakdownVehicleType>({
    key: 'AlertsBreakdownVehicle',
    default: {
        title: 'all',
        value: 0
    }
});
