import React from 'react';
import { AlertRefresh, ButtonRefresh } from './AlertsListTabManager.style';
import { AlertRefreshViewProps } from './AlertsListTabManager.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Grow from '@mui/material/Grow';

const AlertsPopUpStateView: React.FC<AlertRefreshViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    if (!props.displayRefresh) {
        return <></>;
    }

    return (
        <AlertRefresh>
            <Grow in={props.displayRefresh}>
                <ButtonRefresh
                    size='small'
                    onClick={() => props.setEnabledQuery && props.setEnabledQuery(!props.enabledQuery)}
                >
                    <FontAwesomeIcon icon={props.enabledQuery ? ['fas', 'pause'] : ['fas', 'play']} size={'sm'} />
                    {props.enabledQuery ? translate('t.pause_refreshing') : translate('t.continue_refreshing')}
                </ButtonRefresh>
            </Grow>
        </AlertRefresh>
    );
};

export default AlertsPopUpStateView;
