import styled from 'styled-components';
import { Link } from '@mui/material';

export const MapPopUpContainer = styled.div<{ showPopup?: boolean }>`
    visibility: ${(props) => (props.showPopup ? 'visible' : 'hidden')};
    width: 280px;
`;

export const CloseButton = styled(Link)`
    && {
        float: right;
    }
`;

export const PopupHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TopLabel = styled.span`
    margin: 3px;
    float: left;
`;

export const PoupContent = styled.div`
    clear: both;
`;
