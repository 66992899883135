import React from 'react';
import { SeverityRankGraphsContent } from './SeverityRankGraphs.view';
import { SeverityRankGraphsProps } from './SeverityRankGraphs.type';
import { Wrapper } from 'helpers/wrapper';

const SeverityRankGraphs: React.FC<SeverityRankGraphsProps> = (): JSX.Element => {
    return <SeverityRankGraphsContent data-testid={'SeverityRankGraphs-testid'} />;
};

export default Wrapper(SeverityRankGraphs);
