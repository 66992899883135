import styled from 'styled-components';
import { Button } from '@mui/material';

export const AddSensorContainer = styled.div`
    padding-top: 20px;
`;

export const ActionButton = styled(Button)`
    display: none;
`;
