import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { ThemeMode } from '../../../../states/global/Theme';

export const UiVehicleMultiselectContent = styled.div``;

export const CustomAutocomplete = styled(Autocomplete)`
    ${() => {
        return `
                &.MuiAutocomplete-root {
                    min-width: 200px;
                }
                &&& { 
                    .MuiTextField-root {
                        margin-left: 0px;
                        & label {
                            padding: 0 5px 0 0;
                            background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                        }
                        & .MuiInputLabel-shrink {
                            transform: translate(12px, -9px) scale(0.8);
                        }
                    }
                }
            `;
    }}
`;
