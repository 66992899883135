import React, { useEffect } from 'react';
import { TyreContent } from './Tyre.view';
import { TyreProps } from './Tyre.type';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Tyre: React.FC<TyreProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tires-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <TyreContent data-testid='Tyre-testid' />;
};

export default Wrapper(Tyre);
