import { LoadingButtonProps } from '@mui/lab/LoadingButton';

export type UiPassProps = {
    skin?: string | ButtonSkinType;
    textcolor?: string;
    testid?: string;
} & LoadingButtonProps;

export enum ButtonSkinType {
    SUCCESS = 'success',
    PRIMARY = 'primary',
    ERROR = 'error',
    LINK = 'link',
    THIRD = 'third'
}

export enum VariantRegularTypes {
    TEXT = 'text',
    OUTLINED = 'outlined',
    CONTAINED = 'contained'
}
