import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { CircularProgress, Grid } from '@mui/material';
import Button from '../../Ui/Components/UiButton';
import * as Yup from 'yup';
import * as type from './ChangePasswordForm.type';
import * as style from './ChangePasswordForm.style';
import { useTranslation } from 'react-i18next';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import { PASSWORD_REGEX } from 'variables';
import PasswordHelperText from '../../Mixs/HelperText/PasswordHelperText';
import HelperText from '../../Mixs/HelperText';

export const ChangePasswordFormik: React.FC<type.FormikValidationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object().shape({
        currentPassword: props.isCurrentUser
            ? Yup.string().required(translate('p.this_field_is_required')).min(6, 'Password is Too Short.')
            : Yup.string().min(6, 'Password is Too Short.'),
        newPassword: Yup.string()
            .required()
            .min(6)
            .test('passwords-match', translate('p.current_password_cannot_be_equal_to_new_password'), function (value) {
                return this.parent.current_password !== value;
            })
            .matches(PASSWORD_REGEX),
        newPasswordAgain: Yup.string()
            .required(translate('p.this_field_is_required'))
            .oneOf([Yup.ref('newPassword'), null], translate('t.passwords_must_match'))
    });

    return (
        <Formik
            initialValues={{ currentPassword: '', newPassword: '', newPasswordAgain: '' }}
            onSubmit={(values: type.IformValues, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<type.IformValues>) => {
                const { touched, errors, handleChange, values, isSubmitting, handleBlur } = propsFormik;
                return (
                    <Form>
                        <Grid container justifyContent='center' direction='row'>
                            <GridFieldFormik item lg={12} md={12} sm={12} xs={12}>
                                {props.isCurrentUser && (
                                    <style.CustomTextField
                                        id='currentPassword'
                                        label={translate('t.current_password')}
                                        value={values.currentPassword}
                                        variant='outlined'
                                        size='small'
                                        type='password'
                                        name='currentPassword'
                                        fullWidth
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!errors.currentPassword && !!touched.currentPassword}
                                        onBlur={handleBlur}
                                        helperText={
                                            <HelperText
                                                defaultText={translate('t.required')}
                                                text={errors.currentPassword}
                                                error={!!errors.currentPassword && !!touched.currentPassword}
                                            />
                                        }
                                        data-testid='Profile-CurrentPassword-Input'
                                    />
                                )}
                            </GridFieldFormik>
                            <GridFieldFormik item lg={12} md={12} sm={12} xs={12}>
                                <style.CustomTextField
                                    id='newPassword'
                                    label={translate('t.new_password')}
                                    value={values.newPassword}
                                    variant='outlined'
                                    size='small'
                                    name='newPassword'
                                    type='password'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.newPassword && !!touched.newPassword}
                                    onBlur={handleBlur}
                                    helperText={
                                        <PasswordHelperText
                                            error={!!errors.newPassword && !!touched.newPassword}
                                            passwordToValidate={values.newPassword}
                                        />
                                    }
                                    data-testid='Profile-NewPassword-Input'
                                />
                            </GridFieldFormik>
                            <GridFieldFormik item lg={12} md={12} sm={12} xs={12}>
                                <style.CustomTextField
                                    id='newPasswordAgain'
                                    label={translate('t.new_password_repeat')}
                                    value={values.newPasswordAgain}
                                    variant='outlined'
                                    size='small'
                                    name='newPasswordAgain'
                                    type='password'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    onBlur={handleBlur}
                                    error={!!errors.newPasswordAgain && !!touched.newPasswordAgain}
                                    helperText={
                                        <HelperText
                                            defaultText={translate('t.required')}
                                            text={errors.newPasswordAgain}
                                            error={!!errors.newPasswordAgain && !!touched.newPasswordAgain}
                                        />
                                    }
                                    data-testid='Profile-NewPasswordRepeat-Input'
                                />
                            </GridFieldFormik>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <style.ContainerButton>
                                    <Button
                                        testid='Save-Form-Button-ChangePasswordFormik'
                                        variant='contained'
                                        type='submit'
                                        disabled={isSubmitting}
                                        skin='success'
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                    </Button>
                                </style.ContainerButton>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
