import React, { useState } from 'react';
import { InputCoordinateContent } from './InputCoordinate.view';
import { CoordinateObject, FormikValues, InputCoordinateProps } from './InputCoordinate.type';
import { useRecoilState } from 'recoil';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import {
    AreaEditorFormAtom,
    AreaEditorFormDispatcher,
    AreaEditorFormTypesEnum
} from '../../AreaEditorForm/AreaEditorForm.atom';
import { Wrapper } from 'helpers/wrapper';

const parseCoordinates = (coordinates: [number, number][]): { [key: string]: string } => {
    return coordinates.reduce((obj, coord, index) => {
        obj[`coordinate${index}`] = `${coord[1]}, ${coord[0]}`;
        return obj;
    }, {});
};

const convertArrayToObject = (
    coordinateArray: { [x: string]: RequiredStringSchema<string | undefined, AnyObject> }[]
): CoordinateObject => {
    return coordinateArray.reduce((result, item) => {
        return { ...result, ...item };
    }, {});
};

const stringToCoordinates = (coordinatesString: string): [number, number] => {
    const [lat, lng] = coordinatesString.split(',');
    return [parseFloat(lng), parseFloat(lat)];
};

const arraysAreEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    let areEqual = true;
    arr1.forEach((value, index) => {
        if (value !== arr2[index]) {
            areEqual = false;
        }
    });

    return areEqual;
};

const InputCoordinate: React.FC<InputCoordinateProps> = (): JSX.Element => {
    const [areaEditorState, setAreaEditorState] = useRecoilState(AreaEditorFormAtom);
    const [coordinatesState, setCoordinatesState] = useState<FormikValues>(
        areaEditorState.selectedArea?.area?.length
            ? parseCoordinates(areaEditorState.selectedArea?.area)
            : { coordinate0: '' }
    );
    const dispatchInAreaEditor = AreaEditorFormDispatcher(areaEditorState, setAreaEditorState);

    const addCoordinate = (coordinates: FormikValues) => {
        setCoordinatesState({ ...coordinates, [`coordinate${Object.keys(coordinatesState).length}`]: '' });
    };

    const removeCoordinate = (key: string) => {
        setCoordinatesState((state) => {
            const newState = { ...state };
            delete newState[key];
            return newState;
        });
    };

    const saveCoordinate = (values: FormikValues) => {
        const coordinates: [number, number][] = Object.values(values).map((value) => {
            return stringToCoordinates(value);
        });

        if (
            !areaEditorState.selectedArea?.area?.length &&
            !arraysAreEqual(coordinates[0], coordinates[coordinates.length - 1])
        ) {
            coordinates.push(coordinates[0]);
        }
        dispatchInAreaEditor({ type: AreaEditorFormTypesEnum.SET_AREA_COORDINATES, formPayload: coordinates });
    };

    return (
        <InputCoordinateContent
            data-testid='InputCoordinate-testid'
            coordinatesState={coordinatesState}
            saveCoordinate={saveCoordinate}
            addCoordinate={addCoordinate}
            removeCoordinate={removeCoordinate}
            convertArrayToObject={convertArrayToObject}
        />
    );
};

export default Wrapper(InputCoordinate);
