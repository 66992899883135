import PortalApi from 'helpers/api/PortalApiClient';
import { HubNotifyAdd, HubNotifyModel, HubNotifyModelResponse, HubNotifyResponse } from '../models/HubNotify.type';
import { getParams } from 'helpers';

export default class HubNotify extends PortalApi<HubNotifyModel> {
    route = 'hub-notify';

    getHubNotifyTable = async (props): Promise<HubNotifyResponse> => {
        return await this.getTable({ extendUrl: 'list', criteria: getParams(props) });
    };

    getHubNotifyById = async (hubId?: number): Promise<HubNotifyModelResponse> => {
        return await this.get({ extendUrl: `${hubId}` });
    };

    addHubNotify = async (data: HubNotifyAdd): Promise<HubNotifyModelResponse | unknown> => {
        return await this.post({ data: data });
    };

    editHubNotify = async (data): Promise<HubNotifyModelResponse> => {
        return await this.patchById({ data: data, id: data.id });
    };

    removeHubNotify = async (id: number): Promise<HubNotifyModelResponse | unknown> => {
        return await this.delete({ id: id });
    };
}
