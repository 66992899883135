import React, { useState, useRef, useEffect } from 'react';
import { RegionCustomerSelectorProps } from './RegionCustomerSelector.type';
import { Wrapper } from 'helpers/wrapper';
import { RegionCustomerSelectorContent } from './RegionCustomerSelector.view';
import { useSetRecoilState } from 'recoil';
import CustomerClient from 'api/Customer';
import {
    MultipleCustomerSelectorPopoverAtom,
    SelectedCustomersAtom,
    OriginalDataRegionsAtom,
    RegionsDataAtom
} from '../CustomerSelectorAtom';
import { CustomerCodebook } from 'models/Customer.type';
import { AutoCompleteOption, CustomerRegions } from '../CustomerSelectorCheckbox/CustomerSelectorCheckbox.type';
import { useQuery } from '@tanstack/react-query';

const RegionCustomerSelector: React.FC<RegionCustomerSelectorProps> = ({
    onChange,
    selectedCustomers,
    disabled
}): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);
    const [spaceToBottom, setSpaceToBottom] = useState<number | null>(null);
    const setSelectorState = useSetRecoilState(MultipleCustomerSelectorPopoverAtom);
    const setSelectedCustomers = useSetRecoilState(SelectedCustomersAtom);
    const setRegionsData = useSetRecoilState(RegionsDataAtom);
    const setOriginalDataRegions = useSetRecoilState(OriginalDataRegionsAtom);
    const customerClient = new CustomerClient();

    const { isLoading: customersDataLoading, data: customerData } = useQuery(
        ['CustomerSelectorCodebook'],
        () => customerClient.getAllAsCodebook(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (customers) => {
                setRegionsData(parseDataForSelector(customers));
                setOriginalDataRegions(parseDataForSelector(customers));
            }
        }
    );

    const parseDataForSelector = (customers: CustomerCodebook[], collapseOpen = false): AutoCompleteOption[] => {
        let dataResponse: AutoCompleteOption[] = [];
        let data = customers;

        data &&
            !!data.length &&
            (data ?? []).map((item) => {
                if (dataResponse.length) {
                    let region = dataResponse.find((data) => data.id === item.region?.id);

                    if (region) {
                        region.customers.push({
                            name: item.name || '',
                            selected: false,
                            hostname: item.hostname || '',
                            id: item.id
                        });
                    } else {
                        dataResponse.push({
                            id: item.region?.id,
                            translationString: item.region?.translationString || '',
                            open: collapseOpen,
                            selected: false,
                            customers: [
                                {
                                    id: item.id,
                                    name: item.name || '',
                                    hostname: item.hostname || '',
                                    selected: false
                                }
                            ]
                        });
                    }
                } else {
                    dataResponse.push({
                        id: item.region?.id,
                        translationString: item.region?.translationString || '',
                        open: collapseOpen,
                        selected: false,
                        customers: [
                            {
                                id: item.id,
                                name: item.name || '',
                                hostname: item.hostname || '',
                                selected: false
                            }
                        ]
                    });
                }
            });
        return dataResponse.sort((a, b) => -b.translationString.localeCompare(a.translationString));
    };

    const handleOpenSelector = (): void => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const spaceBottom = windowHeight - rect.bottom;

            setSpaceToBottom(Math.round(spaceBottom));
        }
        setSelectorState((current) => ({ ...current, isOpen: true }));
    };

    const removeSelectedCustomers = (customers: CustomerCodebook[]): void => {
        updateList(parserCustomers(customers));
    };

    const updateList = (selectedCustomersList: CustomerRegions[]): void => {
        const updateOriginalDataRegions: AutoCompleteOption[] = JSON.parse(
            JSON.stringify(parseDataForSelector(customerData as CustomerCodebook[]))
        );
        setSelectedCustomers(selectedCustomersList);

        updateOriginalDataRegions.forEach((region) => {
            region.customers.forEach((customer) => {
                const matchingCustomer = selectedCustomersList.find(
                    (selectedCustomer) => selectedCustomer.id === customer.id
                );

                if (matchingCustomer) {
                    return (customer.selected = true);
                } else {
                    return customer;
                }
            });
        });

        setOriginalDataRegions(updateOriginalDataRegions);
        setRegionsData(updateOriginalDataRegions);
    };

    const parserCustomers = (customers: CustomerCodebook[] | []): CustomerRegions[] => {
        if (!customers || !customers.length) {
            return [];
        }

        return customers.map((customer) => {
            return { ...customer, selected: true };
        });
    };

    useEffect(() => {
        if (!customersDataLoading) {
            setTimeout(() => {
                updateList(parserCustomers(selectedCustomers));
            }, 1000);
        }
    }, [selectedCustomers, customersDataLoading]);

    return (
        <RegionCustomerSelectorContent
            data-testid='RegionCustomerSelectorContent'
            handleOpenSelector={handleOpenSelector}
            onChange={onChange}
            selectedCustomers={selectedCustomers}
            divRef={divRef}
            spaceToBottom={spaceToBottom}
            removeSelectedCustomers={removeSelectedCustomers}
            customerData={customerData}
            customersDataLoading={customersDataLoading}
            disabled={disabled}
        />
    );
};

export default Wrapper(RegionCustomerSelector);
