import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AlertSettingInputHotPressureViewContent } from './AlertSettingInputHotPressure.style';
import { AlertSettingInputHotPressureProps } from './AlertSettingInputHotPressure.type';
import { CustomAlertsLevelCheckbox, PressureLevelsSelector, pressureDecimals } from '../AlertSettings/atom';
import { InputAdornment, Tooltip, useTheme } from '@mui/material';
import { AlertLevelEnum } from 'models/Alerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { AlertInput } from '../AlertSettingInput/AlertSettingInput.style';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';

const AlertSettingInputHotPressureContent: React.FC<AlertSettingInputHotPressureProps> = (props): JSX.Element => {
    const customAlerts = useRecoilValue(CustomAlertsLevelCheckbox);
    const [levelState, setLevelState] = useRecoilState(PressureLevelsSelector(props.level));
    const { displayUserUnits } = useConverter();
    const theme = useTheme();
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();

    const delta = useMemo(() => {
        const fixed = pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi'];
        return 1 / Math.pow(10, fixed);
    }, [userInfo.user?.userSetting.pressureUnit]);

    const title = useMemo(() => {
        let indicator = 'below';
        if (levelState.value === '') {
            indicator = 'above';
        } else {
            if (typeof levelState.adjustToValue !== 'undefined') {
                if (+levelState.value >= levelState.adjustToValue) {
                    indicator = 'below';
                } else if (+levelState.value <= levelState.adjustToValue) {
                    indicator = 'above';
                }
            }
        }
        return translate(`t.value_needs_to_be_or_${indicator}`, { value: levelState.adjustToValue });
    }, [levelState.adjustToValue, levelState.value, props.level]);

    return (
        <AlertSettingInputHotPressureViewContent data-testid='AlertSettingInputHotPressureContent'>
            <Tooltip open={levelState.valueError} title={title} arrow>
                <AlertInput
                    id={`alert-level${props.level}-pressure-input-value`}
                    data-testid={`alert-level${props.level}-pressure-input-value`}
                    type='number'
                    size='small'
                    theme={theme}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>{displayUserUnits.pressure}</InputAdornment>,
                        inputProps: {
                            min: 0,
                            step: delta
                        }
                    }}
                    error={levelState.valueError}
                    value={levelState.value}
                    onBlur={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            value: e.target.value,
                            action: AlertLevelEnum.ADJUST_HOT_PRESSURE_VALUE
                        }));
                    }}
                    disabled={!customAlerts}
                    onChange={(e) => {
                        setLevelState((current) => ({
                            ...current,
                            value: e.target.value,
                            action: AlertLevelEnum.VALIDATE_HOT_PRESSURE_VALUE
                        }));
                    }}
                />
            </Tooltip>
        </AlertSettingInputHotPressureViewContent>
    );
};

export default AlertSettingInputHotPressureContent;
