import { atom } from 'recoil';

export enum showContentType {
    LOGIN = 'login',
    RECOVERY_PASSWORD = 'recovery-password',
    USERNAME_RECOVERY = 'username-recovery'
}

export const LoginShowContent = atom<showContentType>({
    key: 'LoginShowContent',
    default: showContentType.LOGIN
});
