import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MainMenuContent } from './MainMenu.view';
import { MainMenuProps, itemPopoverType } from './MainMenu.type';
import Menu from './MenuOptions';
import { SidebarMenu } from '../../states/global/SidebarMenu';
import { useProSidebar } from 'react-pro-sidebar';
import { useLocation, useParams } from 'react-router-dom';
import { Role, User, infoRole } from '../../states/global/User';
import { useTheme } from '@mui/material';
import { MenuOption } from './MenuOption.type';
import Account from 'api/Account';
import { useQuery } from '@tanstack/react-query';
import { useUserLocalStorageSettingsTable } from 'components/CustomHooks/UserLocalStorageSettings';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useTranslation } from 'react-i18next';
import { updateAppTitle } from 'helpers';
import { GetConvertedAmbient } from 'states/global/CustomerSettings';

const MainMenu: React.FC<MainMenuProps> = ({ loading }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [menu, setMenu] = useState(Menu);
    const paramsGerenal = useParams();
    const [itemPopover, setItemPopover] = useState<itemPopoverType | null>(null);
    const path = useLocation().pathname;
    const roleUser = useRecoilValue(infoRole);
    const theme = useTheme();
    const userRole: string = useRecoilValue(Role);
    const userData = useRecoilValue(User);
    const [sidebarMenu, setSidebarMenu] = useRecoilState(SidebarMenu);
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [openSubMenuId, setOpenSubMenuId] = useState<string | null>(null);
    const [showPopUpSubmenu, setShowPopUpSubmenu] = useState<boolean>(true);
    const { collapsed, collapseSidebar } = useProSidebar();
    const { getUserSetting } = useUserLocalStorageSettingsTable();
    const { fromServerToUserUnit } = useConverter();

    const getConvertedAmbient = useRecoilValue(GetConvertedAmbient);
    const customerAmbient = getConvertedAmbient(fromServerToUserUnit);

    const languageHelp: string = ['en_US', 'es_ES', 'fr_CA', 'pt_PT'].includes(
        userData?.userSetting.language || 'en_US'
    )
        ? userData?.userSetting.language || 'en_US'
        : 'en_US';
    const showUserInfo = collapsed
        ? userData?.firstname.substring(0, 1) + '' + userData?.lastname.substring(0, 1)
        : userData?.firstname + ' ' + userData?.lastname;

    const toggleSidebarResponsive = () => {
        setSidebarMenu({
            ...sidebarMenu,
            showMenuResponsive: !sidebarMenu.showMenuResponsive
        });
    };
    const AccountApi = new Account();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, icon: JSX.Element, title: string) => {
        setItemPopover({
            anchorEl: event.currentTarget,
            icon: icon,
            title: title
        });
    };

    const handlePopoverClose = () => {
        itemPopover && setItemPopover(null);
    };

    const handleSubmenu = (requestedKey: string): void => {
        setMenu((current) => {
            let updated = [...current];
            updated.forEach((item) => {
                const clickedMenu: boolean = item.key === requestedKey;
                if (!clickedMenu) {
                    if (Object.prototype.hasOwnProperty.call(item, 'open')) {
                        item.open = false;
                    }
                }
                if (clickedMenu && !!item.submenu.length) {
                    item.open = !item.open;
                }
                item.submenu.forEach((subItem) => {
                    const clickedMenu: boolean = subItem.key === requestedKey;
                    if (!clickedMenu) {
                        if (Object.prototype.hasOwnProperty.call(subItem, 'open')) {
                            subItem.open = false;
                        }
                    }
                    if (subItem.key && clickedMenu) {
                        subItem.open = !subItem.open;
                        item.open = true;
                    }
                });
            });
            return updated;
        });
    };

    const isDefaultOpen = (level: number, item: MenuOption): boolean => {
        if (level === 1) {
            return (
                item.submenu.filter((submenu) => {
                    if (!submenu.path && !!submenu.submenu.length) {
                        return submenu.submenu.find((sub) => sub.path === path);
                    }
                    return submenu.path === path;
                }).length >= 1
            );
        } else {
            return item.submenu.filter((submenu) => submenu.path === path).length > 0;
        }
    };

    const handleMenu = (menuItem: MenuOption | null, menu = false): boolean | void => {
        const { key } = menuItem || {};

        const openSubMenu = (subMenuKey: string | null, menuKey: string | null) => {
            setOpenSubMenuId(subMenuKey);
            setOpenMenuId(menuKey);
        };

        if (menu) {
            if (openMenuId === key) {
                setOpenMenuId(null);
            } else {
                setShowPopUpSubmenu(true);
                setOpenMenuId(key || null);
                setOpenSubMenuId(null);
            }
        } else {
            if (key?.includes('menu2')) {
                const keyMenu = key.split('-menu2');
                if (key?.includes('menu3')) {
                    const keyMenuLevel2 = key.split('-menu2');
                    const keyMenuLevel3 = key.split('-menu3');
                    openSubMenu(keyMenuLevel3[0] || null, keyMenuLevel2[0] || null);
                    return true;
                }
                if (openSubMenuId === key) {
                    setOpenSubMenuId(null);
                } else {
                    openSubMenu(key, keyMenu[0] || null);
                }
            } else {
                if (openSubMenuId === key) {
                    setOpenSubMenuId(null);
                } else {
                    collapsed && setShowPopUpSubmenu(false);
                    setOpenSubMenuId(null);
                }
            }
        }
    };

    const changeAppTitle = (item: MenuOption, path: string) => {
        if (item.path?.includes(':')) {
            const keyParams = item.path?.split(':')[1].split('/')[0];
            const paramGeneral = paramsGerenal[keyParams];

            if (paramGeneral) {
                const patchCurrent = item.path?.replace(`:${keyParams}`, paramGeneral);
                if (patchCurrent === path) {
                    updateAppTitle(`${translate(item?.title)} - ${paramsGerenal[keyParams]}`, false);
                }
            }
        } else if (path === item.path) {
            updateAppTitle(translate(item?.title));
        }
    };

    const { data: avatarData } = useQuery<string, Error, string>(['AvatarMenu', []], () => AccountApi.getAvatar(), {
        refetchOnWindowFocus: false,
        refetchInterval: false
    });

    useEffect(() => {
        if (getUserSetting('collapseSidebar') !== null) {
            setTimeout(() => {
                collapseSidebar(getUserSetting('collapseSidebar'));
            }, 500);
        }
    }, []);

    useEffect(() => {
        if (!collapsed) {
            setShowPopUpSubmenu(true);
        }
    }, [collapsed]);

    return (
        <MainMenuContent
            data-testid='MainMenu-testid'
            menu={menu}
            path={path}
            roleUser={roleUser}
            theme={theme}
            userRole={userRole}
            languageHelp={languageHelp}
            showUserInfo={showUserInfo}
            showSidebarResponsive={sidebarMenu.showMenuResponsive}
            // collapsedSidebar={collapsed}
            collapsedSidebar={false}
            userData={userData}
            loading={loading}
            itemPopover={itemPopover}
            openMenuId={openMenuId}
            openSubMenuId={openSubMenuId}
            avatarData={avatarData}
            customerAmbient={String(customerAmbient.current)}
            isDefaultOpen={isDefaultOpen}
            handleSubmenu={handleSubmenu}
            toggleSidebarResponsive={toggleSidebarResponsive}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
            handleMenu={handleMenu}
            changeAppTitle={changeAppTitle}
            showPopUpSubmenu={showPopUpSubmenu}
        />
    );
};

export default MainMenu;
