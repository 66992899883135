import styled from 'styled-components';
import { Tab, Tabs } from '@mui/material';
import { InputBase } from '@mui/material';
import { Grid } from '@mui/material';

export const CommandGrid = styled(Grid)`
    &.MuiGrid-root {
        margin: 13px 10px;
    }
`;

export const DeviceCommandsTabsContainer = styled.div`
    display: inline-block !important;
    width: 100% !important;
`;

export const DeviceCommandsTabs = styled(Tabs)`
    border: none !important;

    & .MuiTab-root {
        min-width: 13% !important;
        text-transform: none;
        font-size: 0.9rem;
        font-weight: 600;
    }
    & .MuiTab-wrapper {
        flex-direction: row;
    }
    & .MuiTab-labelIcon {
        min-height: auto;
    }

    & .MuiTabs-flexContainer {
        justify-content: flex-start;
    }
`;

export const DeviceCommandsTab = styled(Tab)`
    &.MuiTab-root {
        font-size: 13px;
    }
`;

export const CustomTextfield = styled(InputBase)`
    &.MuiInputBase-root {
        border: 1px solid #565656;
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        padding: 10px 7px;
        margin-top: 20px;
    }
`;
