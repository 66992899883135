import React, { useEffect, useReducer } from 'react';
import { ZoomLevels } from 'components/StatisticsTopBar/StatisticsTopBar';
import { DefaultSeverityRankZoom, RankZoomButtonsProps } from './RankZoomButtons.type';
import { RankZoomButtonsContent } from './RankZoomButtons.view';
import { DatePickerAction, RankDateTimePicker } from 'pages/SeverityRank/atom';
import { useRecoilState } from 'recoil';
import { ZoomAction } from 'components/StatisticsTopBar/StatisticsTopBar.type';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const RankZoomButtons: React.FC<RankZoomButtonsProps> = (): JSX.Element => {
    const [zoom, setZoom] = useReducer(ZoomLevels, DefaultSeverityRankZoom);
    const [dateTimePicker, setDateTimePicker] = useRecoilState(RankDateTimePicker);

    const resetDateTime = () => {
        setDateTimePicker((currentPicker) => ({
            ...currentPicker,
            current: { ...currentPicker.original },
            action: DatePickerAction.APPLY
        }));
        setZoom({ type: ZoomAction.RESET_ZOOM, zoom: [] });
    };

    const previousZoom = () => {
        const getLastZoom: number[] = zoom.zoomLevels[zoom.zoomLevels.length - 2];
        getLastZoom
            ? setDateTimePicker((currentPicker) => ({
                  ...currentPicker,
                  current: {
                      dateFrom: DateTime.fromMillis(getLastZoom[0]),
                      dateTo: DateTime.fromMillis(getLastZoom[1])
                  },
                  action: DatePickerAction.PREVIOUS_ZOOM
              }))
            : resetDateTime();
    };

    useEffect(() => {
        dateTimePicker.action === DatePickerAction.ZOOM_IN &&
            setZoom({
                type: ZoomAction.ZOOM_IN,
                zoom: [dateTimePicker.current.dateFrom.valueOf(), dateTimePicker.current.dateTo.valueOf()]
            });

        dateTimePicker.action === DatePickerAction.PREVIOUS_ZOOM &&
            setZoom({ type: ZoomAction.PREVIOUS_ZOOM, zoom: [] });
    }, [dateTimePicker]);

    useEffect(() => {
        return () => {
            resetDateTime();
        };
    }, []);

    return <RankZoomButtonsContent resetDateTime={resetDateTime} previousZoom={previousZoom} zoom={zoom} />;
};

export default Wrapper(RankZoomButtons);
