import React from 'react';
import { InputCoordinateViewProps } from './InputCoordinate.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Alert, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import HelperText from 'components/Mixs/HelperText/HelperText';
import CloseIcon from '@mui/icons-material/Close';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { VARIANT_ALERT } from 'components/Ui/variables';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';

export const InputCoordinateContent: React.FC<InputCoordinateViewProps> = ({
    coordinatesState,
    saveCoordinate,
    addCoordinate,
    removeCoordinate,
    convertArrayToObject
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const validationSchema = Object.entries(coordinatesState).map(([key]) => {
        return {
            [key]: string()
                .required(translate('t.required'))
                .matches(/^-?\d+(\.\d+)?(, -?\d+(\.\d+)?)$/, translate('t.is_not_in_correct_format'))
        };
    });

    const coordinateObject = convertArrayToObject(validationSchema);
    const formValidationSchema = object().shape({ ...coordinateObject });

    return (
        <Formik
            initialValues={coordinatesState}
            validationSchema={formValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                saveCoordinate(values);
            }}
        >
            {({ values, errors, isValid, handleChange }) => (
                <Form id='Input-coordinate-form'>
                    <UiScroll maxHeight='582px'>
                        <Grid
                            container
                            marginTop={2}
                            columnSpacing={1}
                            direction='column'
                            spacing={1}
                            alignContent='center'
                            justifyContent='center'
                        >
                            <Alert
                                variant={VARIANT_ALERT}
                                sx={{ marginTop: 2, marginBottom: 2 }}
                                severity='info'
                                data-testid='Example-format-coordinate-alert'
                            >
                                {translate('t.example')}: 48.162136, 17.101083 - ({translate('t.latitude')}), (
                                {translate('t.longitude')})
                            </Alert>
                            {Object.entries(coordinatesState).map(([key], index) => {
                                return (
                                    <Grid key={key} item md={7} marginTop={1}>
                                        <TextField
                                            data-testid='InputCoordinate-Coordinate1-Texfield'
                                            label={`${translate('t.coordinates')} ${index + 1}`}
                                            variant='outlined'
                                            name={key}
                                            size='small'
                                            value={values[key]}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={<HelperText error={!!errors[key]} text={errors[key]} />}
                                            onChange={handleChange}
                                            error={!!errors[key]}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => removeCoordinate(key)}
                                                            edge='end'
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </UiScroll>
                    <Grid
                        container
                        columnSpacing={1}
                        direction='column'
                        spacing={1}
                        alignContent='center'
                        justifyContent='center'
                    >
                        <Grid item md={12} textAlign='center'>
                            <UiButton
                                data-testid='InputCoordinate-Button-Add-Coordinate'
                                onClick={() => addCoordinate(values)}
                                size='small'
                                variant='contained'
                            >
                                {translate('t.add_coordinate')}
                            </UiButton>
                            <UiButton
                                data-testid='InputCoordinate-Button-Apply-Coordinate'
                                type='submit'
                                form='Input-coordinate-form'
                                size='small'
                                variant='contained'
                                disabled={!isValid || Object.keys(coordinatesState).length < 4}
                            >
                                {translate('t.apply_coordinates')}
                            </UiButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
