import { AlertLevelEnum } from 'models/Alerts.type';
import { DefaultValue, atom, atomFamily, selector, selectorFamily } from 'recoil';
import { UserInfo } from 'states/global/User';
import { TableFnType } from '../AlertsAffectedVehicles/atom';

export const OptimalPressure = atom<number>({
    key: 'OptimalPressure',
    default: 100
});

export type PressureLevel = {
    value: number | string;
    percentage: number | string;
    valueError: boolean;
    percentageError: boolean;
    action?: AlertLevelEnum;
    customerSetting?: any; // customer setting
    ambientPressure?: number;
    ambientTemperature?: number;
    hotPressure?: number;
    adjustToValue?: number;
};

export const PressureLevelsAtomFamily = atomFamily<PressureLevel, number>({
    key: 'PressureLevelsAtomFamily',
    default: {
        value: 0,
        percentage: 0,
        valueError: false,
        percentageError: false
    }
});

const adjustPercentage = (
    level: number,
    percentage: number | string,
    levelDownPercentage: number,
    levelUpPercentage: number
): number | string => {
    percentage = +percentage;

    if (level < 0) {
        if (level === -3) {
            if (percentage > levelUpPercentage) {
                return (percentage = Math.min(percentage, 100));
            }
            return levelUpPercentage + 0.1;
        }

        if (percentage >= levelDownPercentage) {
            percentage = levelDownPercentage - 0.1;
        } else if (percentage <= levelUpPercentage) {
            percentage = levelUpPercentage + 0.1;
        }
    } else {
        if (level === 3 || level === 13) {
            if (percentage > levelDownPercentage) {
                return (percentage = Math.min(percentage, 100));
            }
            return levelDownPercentage + 0.1;
        }
        if (percentage <= levelDownPercentage) {
            percentage = levelDownPercentage + 0.1;
        } else if (percentage >= levelUpPercentage) {
            percentage = levelUpPercentage - 0.1;
        }
    }
    return percentage.toFixed(1);
};

const adjustValueAndPercentage = (
    level: number,
    value: number | string,
    optimalPressure: number,
    levelDownPercentage: number,
    levelUpPercentage: number,
    currentPercentage: number | string
): { value: number | string; percentage: number | string } => {
    if (value === '') {
        return {
            value: (optimalPressure + (optimalPressure / 100) * (level < 0 ? -1 : 1) * +currentPercentage).toFixed(1),
            percentage: currentPercentage
        };
    }
    value = +value;
    let valuePercentage = +(100 - (value / optimalPressure) * 100).toFixed(1);

    if (level < 0 && valuePercentage < 0) {
        valuePercentage = 0;
    }
    if (level > 0) {
        if (valuePercentage < 0) {
            valuePercentage = Math.abs(valuePercentage);
        } else {
            valuePercentage = 0;
        }
    }
    let percentage = adjustPercentage(level, valuePercentage, levelDownPercentage, levelUpPercentage);

    value = (optimalPressure + (optimalPressure / 100) * (level < 0 ? -1 : 1) * +percentage).toFixed(1);

    return { value, percentage };
};

type CalculateValidValue = {
    adjustedValue: number;
    isValid: boolean;
    adjustedPercentage: number;
};

const calculatedClosestValidValue = (
    level: number,
    value: number | string,
    optimalPressure: number,
    levelDownPercentage: number,
    levelUpPercentage: number
): CalculateValidValue => {
    const hasEmptyValue = value === '';
    let newValue = +value;
    let valuePercentage = +(100 - (newValue / optimalPressure) * 100).toFixed(1);

    if (level < 0 && valuePercentage < 0) {
        valuePercentage = 0;
    }
    if (level > 0) {
        if (valuePercentage < 0) {
            valuePercentage = Math.abs(valuePercentage);
        } else {
            valuePercentage = 0;
        }
    }

    let percentage = +adjustPercentage(level, valuePercentage, levelDownPercentage, levelUpPercentage);
    newValue = +(optimalPressure + (optimalPressure / 100) * (level < 0 ? -1 : 1) * +percentage).toFixed(1);
    return {
        adjustedValue: newValue,
        isValid: valuePercentage === percentage && !hasEmptyValue,
        adjustedPercentage: +percentage.toFixed(1)
    };
};

const calculatedClosestPercentage = (
    level: number,
    value: number | string,
    percentage: number | string,
    optimalPressure: number,
    levelDownPercentage: number,
    levelUpPercentage: number
): CalculateValidValue => {
    const hasEmptyValue = percentage === '';

    let newPercentage = +(+adjustPercentage(level, percentage, levelDownPercentage, levelUpPercentage)).toFixed(1);

    const newValue = +(optimalPressure + (optimalPressure / 100) * (level < 0 ? -1 : 1) * +newPercentage).toFixed(1);

    return {
        adjustedPercentage: newPercentage,
        isValid: !hasEmptyValue && newPercentage === +percentage,
        adjustedValue: +newValue
    };
};

export const calculateHighHotPressure = (
    coldPressure: number,
    ambientPressure: number,
    ambientTemperature: number,
    hotPressureLevel: number,
    fixed: number
): number => {
    return +(
        ((+coldPressure + ambientPressure) * (273 + +hotPressureLevel)) / (273 + ambientTemperature) -
        ambientPressure
    ).toFixed(fixed);
};

export const CustomAlertsLevelCheckbox = atom<boolean>({
    key: 'CustomAlertsLevelCheckbox',
    default: false
});

export const pressureDecimals = {
    psi: 1,
    kpa: 0,
    bar: 3
};

type HighHotPressureValid = {
    validValue: number;
    valid: boolean;
};

const getHighPressureAdjusted = (
    current: number | string,
    levelDown: number,
    levelUp: number,
    optimalPressure: number,
    level: number,
    fixed: number
): number => {
    const delta = 1 / Math.pow(10, fixed);

    if (current === '' && level === 11) {
        return optimalPressure;
    }
    if (+current <= levelDown) {
        return +(levelDown + delta).toFixed(fixed);
    }
    if (+current >= levelUp) {
        if (levelUp === 0) {
            return +(+current).toFixed(fixed);
        }
        return +(levelUp - delta).toFixed(fixed);
    }
    return +(+current).toFixed(fixed);
};

const highHotPressureValid = (
    level: number,
    current: number | string,
    levelDown: number,
    levelUp: number,
    optimalPressure: number,
    fixed: number
): HighHotPressureValid => {
    let validValue = getHighPressureAdjusted(current, levelDown, levelUp, optimalPressure, level, fixed);

    if (level === 11) {
        if (current === '') {
            return {
                validValue,
                valid: false
            };
        }
        return {
            validValue,
            valid: !(+current >= levelUp)
        };
    }
    if (level === 13) {
        if (current === '') {
            return {
                validValue,
                valid: false
            };
        }
    }
    if (level === 12) {
        if (+current <= levelDown) {
            return {
                validValue,
                valid: false
            };
        }
        if (+current >= levelUp) {
            return {
                validValue,
                valid: false
            };
        }
    }

    return {
        validValue,
        valid: true
    };
};

export const PressureLevelsSelector = selectorFamily({
    key: 'PressureLevelsSelector',
    get:
        (level: number) =>
        ({ get }) => {
            return get(PressureLevelsAtomFamily(level));
        },
    set:
        (level: number) =>
        ({ set, get }, newValue: PressureLevel | DefaultValue) => {
            const levelDown = get(PressureLevelsAtomFamily(level - 1));
            const levelUp = get(PressureLevelsAtomFamily(level + 1));
            const highColdPressureLevel = get(PressureLevelsAtomFamily(level % 10));
            const userInfo = get(UserInfo);

            const fixed = pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi'];

            const updatedvalue = newValue as PressureLevel;
            const optimalPressure = +get(PressureLevelsAtomFamily(0)).value;

            let value = updatedvalue.value;
            let percentage = updatedvalue.percentage || 0;
            let valueError = updatedvalue.value === '';
            let percentageError = updatedvalue.percentage === '';
            let adjustToValue = 0;

            switch (updatedvalue.action) {
                case AlertLevelEnum.ADJUST_PERCENTAGE:
                    percentage = adjustPercentage(
                        level,
                        updatedvalue.percentage,
                        +levelDown.percentage,
                        +levelUp.percentage
                    );
                    if (percentage !== '') {
                        value = (
                            optimalPressure +
                            (optimalPressure / 100) * (level < 0 ? -1 : 1) * +percentage
                        ).toFixed(pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi']);
                        percentage = (+percentage).toFixed(1);
                    } else {
                        value = '';
                        valueError = true;
                    }
                    percentageError = false;
                    break;
                case AlertLevelEnum.ADJUST_VALUE: {
                    const a = adjustValueAndPercentage(
                        level,
                        value,
                        optimalPressure,
                        +levelDown.percentage,
                        +levelUp.percentage,
                        +percentage
                    );
                    value = (+a.value).toFixed(fixed);
                    percentage = (+a.percentage).toFixed(1);
                    valueError = false;
                    break;
                }
                case AlertLevelEnum.INIT_VALUES: {
                    if (percentage) {
                        value = (
                            optimalPressure +
                            (optimalPressure / 100) * (level < 0 ? -1 : 1) * +percentage
                        ).toFixed(fixed);
                    }
                    break;
                }
                case AlertLevelEnum.RECALCULATE_VALUE:
                    value = (optimalPressure + (optimalPressure / 100) * (level < 0 ? -1 : 1) * +percentage).toFixed(
                        fixed
                    );
                    break;
                case AlertLevelEnum.RECALCULATE_HOT_PRESSURE: {
                    const hotPressureConsts = get(HotPressureConsts);
                    if (
                        hotPressureConsts.ambientPressure &&
                        hotPressureConsts.ambientTemperature &&
                        hotPressureConsts.hotPressure
                    ) {
                        value = calculateHighHotPressure(
                            +highColdPressureLevel.value,
                            hotPressureConsts.ambientPressure,
                            hotPressureConsts.ambientTemperature,
                            hotPressureConsts.hotPressure,
                            fixed
                        );
                    }
                    break;
                }
                case AlertLevelEnum.VALIDATE_VALUE: {
                    const { adjustedValue, isValid, adjustedPercentage } = calculatedClosestValidValue(
                        level,
                        value,
                        optimalPressure,
                        +levelDown.percentage,
                        +levelUp.percentage
                    );

                    valueError = !isValid;
                    adjustToValue = adjustedValue;
                    percentage = adjustedPercentage;
                    break;
                }
                case AlertLevelEnum.VALIDATE_PERCENTAGE: {
                    const { adjustedPercentage, adjustedValue, isValid } = calculatedClosestPercentage(
                        level,
                        value,
                        percentage,
                        optimalPressure,
                        +levelDown.percentage,
                        +levelUp.percentage
                    );
                    percentageError = !isValid;
                    adjustToValue = adjustedPercentage;
                    value = adjustedValue.toFixed(1);
                    break;
                }
                case AlertLevelEnum.VALIDATE_HOT_PRESSURE_VALUE: {
                    const hotPressureConsts = get(HotPressureConsts);

                    if (
                        hotPressureConsts.ambientPressure &&
                        hotPressureConsts.ambientTemperature &&
                        hotPressureConsts.hotPressure
                    ) {
                        const hotOptimal = calculateHighHotPressure(
                            +highColdPressureLevel.value,
                            hotPressureConsts.ambientPressure,
                            hotPressureConsts.ambientTemperature,
                            hotPressureConsts.hotPressure,
                            fixed
                        );
                        const { validValue, valid } = highHotPressureValid(
                            level,
                            value,
                            +levelDown.value,
                            +levelUp.value,
                            hotOptimal,
                            fixed
                        );
                        valueError = !valid;
                        adjustToValue = validValue;
                    }
                    break;
                }
                case AlertLevelEnum.ADJUST_HOT_PRESSURE_VALUE: {
                    valueError = false;
                    const hotPressureConsts = get(HotPressureConsts);

                    if (
                        hotPressureConsts.ambientPressure &&
                        hotPressureConsts.ambientTemperature &&
                        hotPressureConsts.hotPressure
                    ) {
                        const hotOptimal = calculateHighHotPressure(
                            +highColdPressureLevel.value,
                            hotPressureConsts.ambientPressure,
                            hotPressureConsts.ambientTemperature,
                            hotPressureConsts.hotPressure,
                            fixed
                        );
                        value = getHighPressureAdjusted(
                            value,
                            +levelDown.value,
                            +levelUp.value,
                            hotOptimal,
                            level,
                            fixed
                        );
                    }
                    break;
                }
                default:
                    break;
            }

            return set(PressureLevelsAtomFamily(level), () => ({
                percentage,
                value,
                valueError,
                percentageError,
                adjustToValue
            }));
        }
});

export const PressureLevelsAllSelector = selector({
    key: 'PressureLevelsSelector',
    get: ({ get }) => {
        let alerts = {};
        for (let i = -3; i < 4; i++) {
            if (i) {
                let pressureLevel = get(PressureLevelsAtomFamily(i));
                alerts[i] = pressureLevel;
                if (i > 0) {
                    let hotPressureLevel = get(PressureLevelsAtomFamily(i + 10));
                    alerts[i + 10] = hotPressureLevel;
                }
            }
        }
        return alerts;
    }
});

export const GetHighHotPressure = selectorFamily({
    key: 'GetHighHotPressure',
    get:
        (level: number) =>
        ({ get }) => {
            const userInfo = get(UserInfo);
            const fixed = pressureDecimals[userInfo.user?.userSetting.pressureUnit || 'psi'];
            const highColdPressure = get(PressureLevelsAtomFamily(level));
            const pressureConts = get(HotPressureConsts);

            const value = +(
                ((+highColdPressure.value + pressureConts.ambientPressure) * (273 + pressureConts.hotPressure)) /
                    (273 + pressureConts.ambientTemperature) -
                pressureConts.ambientPressure
            ).toFixed(fixed);
            return value;
        }
});

export const AlertSettingPriority = atom<number>({
    key: 'AlertSettingPriority',
    default: 0
});

export const CustomHighColdPressure = selectorFamily<boolean, undefined>({
    key: 'CustomHighColdPressure',
    get:
        () =>
        ({ get }) => {
            const highColdPressure1 = get(PressureLevelsAtomFamily(11));
            const highColdPressure2 = get(PressureLevelsAtomFamily(12));
            const highColdPressure3 = get(PressureLevelsAtomFamily(13));
            const highHotPressure1 = get(GetHighHotPressure(1));
            const highHotPressure2 = get(GetHighHotPressure(2));
            const highHotPressure3 = get(GetHighHotPressure(3));
            return (
                +highColdPressure1.value !== highHotPressure1 ||
                +highColdPressure2.value !== highHotPressure2 ||
                +highColdPressure3.value !== highHotPressure3
            );
        }
});

type HotPressureConstsType = {
    ambientPressure: number;
    ambientTemperature: number;
    hotPressure: number;
};

export const HotPressureConsts = atom<HotPressureConstsType>({
    key: 'HotPressureConsts',
    default: {
        ambientPressure: 14.7,
        ambientTemperature: 13,
        hotPressure: 80
    }
});

export const IsLoadingAlertSettings = atom<boolean>({
    key: 'IsLoadingAlertSettings',
    default: false
});

export const RefetchAlertSettings = atom<TableFnType>({
    key: 'RefetchAlertSettings',
    default: {}
});

export const DefaultPressureLevel = atom({
    key: 'DefaultPressureLevel',
    default: {
        low3: 0,
        low2: 0,
        low1: 0,
        optimal: 0,
        high1: 0,
        high2: 0,
        high3: 0,
        highHot1: 0,
        highHot2: 0,
        highHot3: 0
    }
});

export const PressureIsChanged = selector<boolean>({
    key: 'PressureIsChanged',
    get: ({ get }) => {
        const lowColdPressure1 = get(PressureLevelsAtomFamily(-1));
        const lowColdPressure2 = get(PressureLevelsAtomFamily(-2));
        const lowColdPressure3 = get(PressureLevelsAtomFamily(-3));
        const highColdPressure1 = get(PressureLevelsAtomFamily(1));
        const highColdPressure2 = get(PressureLevelsAtomFamily(2));
        const highColdPressure3 = get(PressureLevelsAtomFamily(3));
        const highHotPressure1 = get(PressureLevelsAtomFamily(11));
        const highHotPressure2 = get(PressureLevelsAtomFamily(12));
        const highHotPressure3 = get(PressureLevelsAtomFamily(13));
        const optimal = get(PressureLevelsAtomFamily(0));
        const defaultValues = get(DefaultPressureLevel);

        return !(
            defaultValues.high1 === +highColdPressure1.value &&
            defaultValues.high2 === +highColdPressure2.value &&
            defaultValues.high3 === +highColdPressure3.value &&
            defaultValues.low1 === +lowColdPressure1.value &&
            defaultValues.low2 === +lowColdPressure2.value &&
            defaultValues.low3 === +lowColdPressure3.value &&
            defaultValues.highHot1 === +highHotPressure1.value &&
            defaultValues.highHot2 === +highHotPressure2.value &&
            defaultValues.highHot3 === +highHotPressure3.value &&
            defaultValues.optimal === +optimal.value
        );
    }
});

export enum SettingSteps {
    SELECT_FILTER,
    //AFFECTED_VEHICLES,
    TEMPERATURE_PRESSURE_SETTING,
    SAVE
}

export const SettingsStep = atom<SettingSteps>({
    key: 'SettingsStep',
    default: SettingSteps.SELECT_FILTER
});
