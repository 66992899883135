import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as style from './VehicleMapPopupAlerts.style';
import { VehicleMapPopupAlertsViewProps } from './VehicleMapPopupAlerts.type';
import UiCircularProgress from '../Ui/Components/UiCircularProgress';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import AlertInfo from '@mui/material/Alert';
import TpmsAlertsQuery from '../Queries/TpmsAlertQuery/TpmsAlertQuery';
import AlertsList from '../AlertNotification/AlertsList/AlertsList';
import { typeAlert } from '../AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { MapActionData } from '../../states/global/Map';
import { UserInfo } from 'states/global/User';
import { typeFilter } from '../AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';

export const VehicleMapPopupAlertsContent: React.FC<VehicleMapPopupAlertsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const mapActionData = useRecoilValue(MapActionData);
    const { fromTimezoneToUTC } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    return (
        <style.VehicleMapPopupAlertsContent data-testid='VehicleMapPopupAlertsContent'>
            <TpmsAlertsQuery
                data-testid={'VehicleMapPopupAlertsContent-TpmsAlertsQuery'}
                customConfig={{
                    refetchInterval: 30000
                }}
                currentPage={1}
                keyQuery={`GetTpmsAlertVehicle-${mapActionData.clickedVehicle}`}
                limit={150}
                vehicleId={mapActionData.clickedVehicle}
                solved={false}
                alertFilters={null}
                tab={'tpms'}
                measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
            >
                {({ data, isLoading }) => (
                    <>
                        <AlertsList
                            data-testid={'VehicleMapPopupAlertsContent-AlertsList'}
                            data={
                                checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
                                    ? data?.items
                                    : data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                          current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                          return acc;
                                      }, [])
                            }
                            paginator={data?.paginator}
                            alertKey='_alertTPMS'
                            displayAlertActions={true}
                            typeAlert={typeAlert.TPMS}
                            isLoading={isLoading}
                            height={200}
                            showSolveButton={true}
                            showValidateButton={true}
                            tabNumber={0}
                            displayBody={false}
                            sizeAlert='sm'
                        />
                    </>
                )}
            </TpmsAlertsQuery>
            {false && (
                <InfiniteScroll
                    data-testid={'VehicleMapPopupAlertsContent-InfiniteScroll'}
                    dataLength={props.data?.length || 0}
                    next={props.loadData}
                    hasMore={props.hasMore}
                    loader={<UiCircularProgress />}
                    height={200}
                >
                    {!props.data.length && <AlertInfo severity='info'>{translate('t.there_no_tpms_alerts')}</AlertInfo>}
                    {props.data.map((d, index) => {
                        return <div key={index}></div>;
                    })}
                </InfiniteScroll>
            )}
        </style.VehicleMapPopupAlertsContent>
    );
};
