import styled from 'styled-components';
import LazyloadingAutocomplete from '../../Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';

export const ReplaceSensorContainer = styled.div`
    padding-top: 20px;
`;

export const LazyAutocomplete = styled(LazyloadingAutocomplete)`
    & .MuiInputBase-root {
        height: 34.25px !important;
    }
`;
