import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, HeatmapTopBarContainer, StyledTextField } from './HeatmapTopBar.style';
import { HeatmapTopBarProps } from './HeatmapTopBar.type';
import { useTranslation } from 'react-i18next';
import { DateRangeData } from '../../Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import VehicleMultiselect from '../../Ui/Components/UiVehicleMultiselect';
import { LoadingMapdata } from '../../../states/global/Map';
import { DataLoaderProgress } from '../../MapDataLoader/MapDataLoader.style';
import { UserInfo } from '../../../states/global/User';
import { SIZE_BUTTON } from '../../Ui/variables';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime, Settings } from 'luxon';
import LazyloadingAutocomplete from '../../Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import VehicleApi from '../../../api/Vehicle';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';

const Vehicle = new VehicleApi();

const HeatmapTopBarContent: React.FC<HeatmapTopBarProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const [dateRange, setDateRange] = useState<DateRangeData>({
        dateFrom: DateTime.local().minus({ days: props.slopeHeatmap ? 1 : 7 }),
        dateTo: DateTime.local(),
        isValidRange: true
    });
    const [vehicles, setVehicles] = useState<number[]>();
    const loadingMapData = useRecoilValue(LoadingMapdata);
    const [odometerSum, setOdometerSum] = useState<number>(50);
    const [satellites, setSatellites] = useState<number>(11);

    useEffect(() => {
        setDateRange({
            dateFrom: DateTime.local().minus({ days: props.slopeHeatmap ? 1 : 7 }),
            dateTo: DateTime.local(),
            isValidRange: true
        });
    }, [userInfo.user?.userSetting.timezone.timezoneName, props.slopeHeatmap]);

    return (
        <HeatmapTopBarContainer height={props.height} data-testid='HeatmapTopBarContent'>
            {props.slopeHeatmap && (
                <>
                    <StyledTextField
                        data-testid='odometer-sum-textfield'
                        value={odometerSum}
                        onChange={(event) => {
                            setOdometerSum(+event.target.value);
                        }}
                        type='number'
                        title='Odometer'
                        size='small'
                        label={translate('t.odometer')}
                    />
                    <StyledTextField
                        data-testid='number-of-satellites-textfield'
                        value={satellites}
                        onChange={(event) => {
                            setSatellites(+event.target.value);
                        }}
                        type='number'
                        title='NumberOfSatellites'
                        size='small'
                        label={translate('t.number_of_satellites')}
                    />
                </>
            )}
            {props.showDatetimePicker &&
                userInfo.user?.userSetting.timezone.timezoneName === Settings.defaultZone.zoneName && (
                    <UiDateRangePicker2
                        data-testid='HeatmapTopBarContent-UiDateRangePicker'
                        adjustToPeriod={{ period: 7, periodType: PeriodTypeEnum.DAYS }}
                        dateFrom={dateRange?.dateFrom}
                        dateTo={dateRange?.dateTo}
                        dateFromLabel={translate('t.date_from')}
                        dateToLabel={translate('t.date_to')}
                        onDateChange={(dateRange) => {
                            if (dateRange)
                                setDateRange({
                                    dateFrom: dateRange[0],
                                    dateTo: dateRange[1]
                                });
                        }}
                    />
                )}
            {props.showVehiclePicker &&
                (props.numberOfVehicles === 1 ? (
                    <div>
                        <LazyloadingAutocomplete
                            data-testid='HeatmapTopBarContent-LazyloadingAutocomplete'
                            id='heat-map-top-bar'
                            queryId='vehicle-heatmap-top-bar'
                            optionKey='name'
                            optionValue='id'
                            size='small'
                            query={Vehicle.getTable}
                            label={translate('t.vehicle')}
                            getOptionLabel={(option) => ({
                                text: option.name,
                                html: undefined
                            })}
                            onValueChange={(value) => {
                                if (value) {
                                    setVehicles([value]);
                                }
                            }}
                            querySortBy={{
                                desc: false,
                                id: 'vehicleName'
                            }}
                        />
                    </div>
                ) : (
                    <VehicleMultiselect
                        data-testid='HeatmapTopBarContent-VehicleMultiselect'
                        maxAllowedVehicles={props.numberOfVehicles || 3}
                        customeLabel={props.numberOfVehicles ? translate('t.vehicle') : translate('t.all_vehicles')}
                        onSelectedVehiclesChange={(selectedVehicles) => {
                            setVehicles(selectedVehicles.map((vehicle) => vehicle.id));
                        }}
                    />
                ))}
            <Button
                data-testid='HeatmapTopBarContent-Button-apply'
                variant='contained'
                size={SIZE_BUTTON}
                skin='success'
                disabled={
                    (props.numberOfVehicles && props.numberOfVehicles !== vehicles?.length) ||
                    loadingMapData ||
                    (typeof dateRange?.isValidRange === 'undefined' ? false : !dateRange?.isValidRange)
                }
                onClick={() => {
                    if (props.onApply) {
                        const dateRangeWithUTC = { ...dateRange };
                        if (dateRangeWithUTC?.dateFrom) {
                            dateRangeWithUTC.dateFromUTC = fromTimezoneToUTC(dateRangeWithUTC?.dateFrom);
                        }
                        if (dateRangeWithUTC?.dateTo) {
                            dateRangeWithUTC.dateToUTC = fromTimezoneToUTC(dateRangeWithUTC?.dateTo);
                        }
                        props.onApply(dateRangeWithUTC, vehicles, odometerSum, satellites);
                    }
                }}
            >
                {loadingMapData ? <DataLoaderProgress /> : translate('t.apply')}
            </Button>
        </HeatmapTopBarContainer>
    );
};

export default HeatmapTopBarContent;
