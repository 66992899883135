import styled from 'styled-components';
import { Autocomplete } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const UsersAdministrationStyle = styled.div`
    width: 100%;
    && .MuiCardContent-root {
        ${applyStyleByMode({
            styleOld: 'padding: 16px 0;',
            styleJade: 'padding: 0px 10px 10px 10px;'
        })}
        font-size: 13px !important;
    }
`;

export const Filters = styled.div`
    padding-left: 10px;
`;

export const AutocompleteWithMargin = styled(Autocomplete)`
    && .MuiFormControl-fullWidth {
        margin: 4px 0 8px 0;
    }
`;
export const ButtonHeaderWidget = styled.div`
    padding-right: 8px;
`;
