import React from 'react';
import { useRecoilState } from 'recoil';
import { UiTableHeaderCheckboxViewContent } from './UiTableHeaderCheckbox.style';
import { UiTableHeaderCheckboxProps } from './UiTableHeaderCheckbox.type';
import { Checkbox } from '@mui/material';
import { SelectRow, SelectedRowDispatcher, SelectedRowEnum } from '../UiTable/TableAtom';

const UiTableHeaderCheckboxContent: React.FC<UiTableHeaderCheckboxProps> = (): JSX.Element => {
    const [selectRow, setSelectRow] = useRecoilState(SelectRow);
    const selectRowDispatch = SelectedRowDispatcher(selectRow, setSelectRow);

    return (
        <UiTableHeaderCheckboxViewContent data-testid='HeaderCheckbox'>
            <Checkbox
                checked={selectRow.selectedAll}
                onChange={(e) => {
                    selectRowDispatch({
                        type: SelectedRowEnum.SET_SELECT_ALL,
                        payload: e.target.checked
                    });
                }}
            />
        </UiTableHeaderCheckboxViewContent>
    );
};

export default UiTableHeaderCheckboxContent;
