import React, { useCallback, useEffect } from 'react';
import { RankVehicleDropdownContent } from './RankVehicleDropdown.view';
import { DefaultUnconfirmedVehicle, RankVehicleDropdownProps } from './RankVehicleDropdown.type';
import { SelectedVehicle } from 'states/global/Statistics';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UnconfirmedVehicleAtom } from './RankVehicleDropdown.atom';
import { Wrapper } from 'helpers/wrapper';

const RankVehicleDropdown: React.FC<RankVehicleDropdownProps> = ({ navigate, generatePath }): JSX.Element => {
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const setUnconfirmedVehicle = useSetRecoilState(UnconfirmedVehicleAtom);

    useEffect(() => {
        setUnconfirmedVehicle(DefaultUnconfirmedVehicle);
    }, []);

    const handleVehicleChange = useCallback(
        (value, label, fullItem) => {
            if (value) {
                if (navigate && generatePath) {
                    navigate(
                        `${generatePath('/severity-rank/:id', {
                            id: `${value}`
                        })}?back=vehicle-status`,
                        { replace: true }
                    );
                }
            }

            setUnconfirmedVehicle({
                id: value || 0,
                name: label || '',
                distribution: fullItem?.vehicleConfigurationV2.distribution
            });
        },
        [setUnconfirmedVehicle]
    );

    return <RankVehicleDropdownContent handleVehicleChange={handleVehicleChange} selectedVehicle={selectedVehicle} />;
};

export default Wrapper(RankVehicleDropdown);
