import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, useTheme } from '@mui/material';
import {
    ALERT_LEVEL_1_TEMP_AND_PRESS,
    ALERT_LEVEL_2_TEMP_AND_PRESS,
    ALERT_LEVEL_3_TEMP_AND_PRESS,
    BLACK,
    WHITE
} from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import styled, { css as cssStyle, keyframes as keyframesStyle } from 'styled-components';

export const TempAndPressListBodyViewContent = styled.div``;

export const colors = {
    0: BLACK,
    1: ALERT_LEVEL_1_TEMP_AND_PRESS,
    2: ALERT_LEVEL_2_TEMP_AND_PRESS,
    3: ALERT_LEVEL_3_TEMP_AND_PRESS
};

export const ContainerIconStatus = styled.div<{ $height: number }>`
    display: inline-flex;
    margin: 0 auto;
    width: max-content;
    min-width: 55px;
    min-height: ${({ $height }) => $height}px;
    line-height: ${({ $height }) => $height}px;
    align-items: center;
`;

export const ContainerPressureValue = styled.div`
    line-height: 1.1;
    display: grid;
    padding: 2px;
`;

export const PressureValue = styled.span<{ type: string }>`
    border-radius: 5px;
    padding: 1px;
    background: ${(props) => (props.type === 'hot' ? '#FFE3E3' : '#e8f5f3')};
    border: 1px solid ${(props) => (props.type === 'hot' ? 'red' : 'blue')};

    strong {
        color: #000;
    }
`;

export const AlertValue = styled.strong<{ $level: number }>`
    ${({ $level }) => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${$level ? `${colors[$level]} !important` : '#000'};`,
            dark: `color: ${$level && $level !== 0 ? `${colors[$level]} !important` : WHITE};`
        });
    }}
`;

export const UITableCell = styled(TableCell)<{ $align?: string }>`
    padding-right: 7px !important;
    ${(props) => {
        return props.$align ? `text-align: ${props.$align} !important` : '';
    }};
`;

export const Over24hOldIcon = styled(FontAwesomeIcon)``;

const alertAnimation = keyframesStyle`
0% {
    background: transparent;
     
    box-shadow:inset 0 0 15px 2px rgba(250,0,12,1), 0 0 5px 2px rgba(250,0,12,0.51);
  
  opacity: 0.8;
}
50% {
 
  background: transparent;
  box-shadow:inset 0 0 0 6px rgba(250,0,12,1);
  opacity: 0.5;
}
100% {
  
  background: transparent;
  
  box-shadow: inset 0 0 15px 19px rgba(250,0,12,1);
  
  opacity: 0.8;
}
`;

export const fieldAnimation = () => cssStyle`
    ${alertAnimation} 1s steps(90) infinite alternate;
`;

export const WheelTableCell = styled(TableCell)<{ $percentage: number; $level: number; $thresholdReached: boolean }>`
    height: 44.53px;
    cursor: pointer;
    ${(props) => {
        return (
            props.$level === 3 &&
            `
                background:  linear-gradient(to top, ${colors[props.$level]}
                0%, rgba(250,0,0,0.005) ${props.$percentage}%, transparent
                ${100 - props.$percentage - 1}%);
            `
        );
    }}

    animation: ${(props) => props.$thresholdReached && fieldAnimation()}
`;

export const ExclamationIcon = styled('span')`
    font-weight: 600;
    font-size: x-large;
    color: red;
`;

export const OldTemperaturePredictionIcon = styled('div')<{ styleNumber: number }>`
    position: absolute;
    ${(props) => {
        const styles = ['top: -39px; left: 27px', 'top: -29px; left: 27px'];

        return styles[props.styleNumber];
    }}
`;
