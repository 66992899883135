import React, { useCallback, useReducer } from 'react';
import { SensorManagementContent } from './SensorManagement.view';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsActionMenuAtom } from '../../Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { Dispatcher } from './SensorManagement.reducer';
import SensorAPI from '../../../api/Sensors';
import { AttachedSensor } from '../../../models/Sensors.type';
import { useMutation } from '@tanstack/react-query';
import { Success } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';

const sensorAPI = new SensorAPI();

const SensorManagement = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const [attachedSensors, dispatcher] = useReducer(
        Dispatcher,
        vehicleDetailsActionMenuAtom.data?.attachedSensors.map((attachedSensor): AttachedSensor => {
            return attachedSensor;
        }) || []
    );

    const { mutate: switchPrioritiesMutation, isLoading } = useMutation(
        ['SwapSensorPriorities'],
        sensorAPI.switchSensorPriorities,
        {
            onSuccess: (data) => {
                dispatcher({
                    priority1: data.items[0].priority,
                    priority2: data.items[1].priority
                });
                Success({ text: translate('t.changes_have_been_saved_successfully') });
            }
        }
    );

    const switchPositions = useCallback(
        (position: number): void => {
            if (attachedSensors.length < 2 && attachedSensors.length < position) return;
            switchPrioritiesMutation({
                sensorId1: attachedSensors.find((sensor) => sensor.sensor.priority === position)?.sensor.id || 0,
                sensorId2:
                    attachedSensors.find((sensor) => sensor.sensor.priority === (position === 1 ? 2 : 1))?.sensor.id ||
                    0
            });
        },
        [attachedSensors, switchPrioritiesMutation]
    );

    return (
        <SensorManagementContent
            attachedSensors={attachedSensors}
            isLoading={isLoading}
            switchPositions={switchPositions}
        />
    );
};

export default SensorManagement;
