import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '../Components/UiButton/UiButton';
import {
    LegendContainer,
    FilterColumnsContent,
    FilterColumnCover,
    TextContainer,
    CustomPopover
} from './UiTableFilterColumnsPopover.style';
import { FilterColumnsProps } from './UiTableFilterColumnsPopover.type';
import { Wrapper } from 'helpers/wrapper';
import Legend from '../Components/UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { TableActions, TableAtom, TableDispatcher } from 'states/component/Table';
import { HeaderLegend, UiScrollCustom } from '../Components/UiLegend/UiLegend.style';
import { Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { FilterBtnStates } from '../UiTable/TableAtom';

const UiTableFilterColumnsBtn: React.FC<FilterColumnsProps> = (props): JSX.Element => {
    const [close, setClose] = useState<boolean>(false);
    const { t: translate } = useTranslation();
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const [applyFiltersFlag, setApplyFilterFlag] = useState<undefined | 'apply' | 'apply_save' | 'reset'>(undefined);
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.queryKey}Table`]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const [filterBtnState, setFilterBtnState] = useRecoilState(FilterBtnStates);

    useEffect(() => {
        if (close) {
            setClose(false);
            handleClose();
        }
    }, [close]);

    useEffect(() => {
        if (applyFiltersFlag) {
            //pending CHECK "RESET" BEHAVIOR

            setValues(applyFiltersFlag);
        }

        return () => setApplyFilterFlag(undefined);
    }, [props.table.state.filters]);

    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            handleSubmit('apply');
        }
    };

    const handleSubmit = (typeAction: 'apply' | 'apply_save' | 'reset') => {
        setApplyFilterFlag(typeAction);
    };

    const setValues = (typeAction: 'apply' | 'apply_save' | 'reset') => {
        if (typeAction !== 'reset') {
            tableDispatch({
                type: TableActions.PAGE_FILTER_CHANGED,
                payload: props.table.state.filters
            });

            let savedFilterModified = props.table.state.filters.filter((mainFilter) =>
                getUserSettingTable(`table.${props.queryKey}Table`)
                    ?.filter.filter((currentFilter) => currentFilter.value.value !== undefined)
                    .some((savedFilter) => savedFilter.id === mainFilter.id)
            );

            if (savedFilterModified.length) {
                setUserSettings(`table.${props.queryKey}Table`, {
                    orderBy: props.table.state.sortBy,
                    pageSize: props.table.state.pageSize,
                    hiddenColumns: props.table.state.hiddenColumns,
                    filter: savedFilterModified
                });
            }

            props.table.setAllFilters(props.table.state.filters);
            if (typeAction === 'apply_save') {
                if (props.table.state.filters && !!props.table.state.filters.length) {
                    setUserSettings(`table.${props.queryKey}Table`, {
                        orderBy: props.table.state.sortBy,
                        pageSize: props.table.state.pageSize,
                        hiddenColumns: props.table.state.hiddenColumns,
                        filter: props.table.state.filters.filter((filter) => {
                            if (filter.value.value instanceof DateTime) {
                                return filter.value.value.isValid;
                            }
                            return true;
                        })
                    });
                }
            }
            setClose(true);
        }

        setApplyFilterFlag(undefined); //PENDING TO CHECK
    };

    const handleClose = (): void => {
        setFilterBtnState({
            ...filterBtnState,
            anchorEl: null
        });
    };

    const LegendContent: React.FC = (): JSX.Element => (
        <table>
            <tbody>
                <tr>
                    <td>
                        <LegendContainer>{translate('t.apply')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>{translate('p.apply_legend')}</TextContainer>
                    </td>
                </tr>
                <tr>
                    <td>
                        <LegendContainer extraWidth={true}>{translate('t.apply_save')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>
                            {translate('p.apply_save_legend')}
                            <FontAwesomeIcon icon={faFloppyDisk} />{' '}
                        </TextContainer>{' '}
                    </td>
                </tr>
                <tr>
                    <td>
                        <LegendContainer>{translate('t.reset')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>{translate('p.reset_legend')}</TextContainer>{' '}
                    </td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <FilterColumnsContent data-testid={`FilterColumns-${props.queryKey}Table-Content`}>
            <CustomPopover
                open={Boolean(filterBtnState.anchorEl)}
                anchorEl={filterBtnState.anchorEl}
                onClose={() => {
                    handleClose();
                }}
                disablePortal
                width={350}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <HeaderLegend>
                    <Typography variant='body1' className='legend-header'>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            <Button
                                testid={`FilterColumns-${props.queryKey}Table-Apply-Button`}
                                onClick={() => handleSubmit('apply')}
                            >
                                {translate('t.apply')}
                            </Button>
                            <Button
                                testid={`FilterColumns-${props.queryKey}Table-Save-Button`}
                                onClick={() => handleSubmit('apply_save')}
                            >
                                {translate('t.apply_save')}
                            </Button>
                            <Button
                                testid={`FilterColumns-${props.queryKey}Table-Reset-Button`}
                                onClick={() => {
                                    handleSubmit('reset');
                                    props.table.setAllFilters([]);
                                    setUserSettings(`table.${props.queryKey}Table`, {
                                        orderBy: props.table.state.sortBy,
                                        pageSize: props.table.state.pageSize,
                                        hiddenColumns: props.table.state.hiddenColumns,
                                        filter: []
                                    });

                                    tableDispatch({
                                        type: TableActions.PAGE_FILTER_CHANGED,
                                        payload: []
                                    });
                                }}
                            >
                                {translate('t.reset')}
                            </Button>
                            <Legend title={translate('t.legend')} content={<LegendContent />} />
                        </span>
                        <IconButton
                            aria-label='close'
                            size='small'
                            onClick={() => {
                                handleClose();
                                setClose(false);
                            }}
                            data-testid='CloseLegendPopup-Button'
                        >
                            <GridCloseIcon fontSize='small' />
                        </IconButton>
                    </Typography>
                </HeaderLegend>
                <div className='legend-body'>
                    <UiScrollCustom>
                        <div>
                            <FilterColumnCover onKeyPress={handleKeypress}>
                                {props.table.allColumns
                                    .filter((column) => column.canFilter)
                                    .map((column, index) => (
                                        <React.Fragment key={index}>
                                            {column.render('Filter', { applyFiltersFlag: applyFiltersFlag })}
                                        </React.Fragment>
                                    ))}
                            </FilterColumnCover>
                        </div>
                    </UiScrollCustom>
                </div>
            </CustomPopover>
        </FilterColumnsContent>
    );
};

export default Wrapper(UiTableFilterColumnsBtn);
