import { atom } from 'recoil';
import { AutoCompleteOption, CustomerRegions } from './CustomerSelectorCheckbox/CustomerSelectorCheckbox.type';

export type CustomerSelectorAtomType = {
    isOpen: boolean;
};

export const CustomerSelectorAtom = atom({
    key: 'customerSelectorAtom',
    default: { isOpen: false } as CustomerSelectorAtomType
});

export const IsChangingCustomer = atom<boolean>({
    key: 'IsChangingCustomer',
    default: false
});

export const MultipleCustomerSelectorPopoverAtom = atom({
    key: 'multiple-customer-selector-popover-atom',
    default: { isOpen: false } as CustomerSelectorAtomType
});

export const SelectedCustomersAtom = atom<CustomerRegions[]>({
    key: 'selectedCustomers',
    default: []
});

export const RegionsDataAtom = atom<AutoCompleteOption[]>({
    key: 'regionsData',
    default: []
});

export const OriginalDataRegionsAtom = atom<AutoCompleteOption[]>({
    key: 'OriginalDataRegionsAtom',
    default: []
});
