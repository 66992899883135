import { styled as styleMui } from '@mui/system';
import { Box, Paper } from '@mui/material';
import { BarGroupIcon } from 'components/TopBar/TopBar.style';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { WHITE } from 'components/Ui/colors';

export const CustomerCheckerContainer = styleMui(Box)({
    justifyContent: 'center',
    margin: 'auto',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    marginTop: '2em'
});

export const CustomerContent = styleMui(Paper)({
    padding: 10,
    width: 340
});

export const SelectCustomerBtn = styleMui(UiButton)({
    width: 320,
    margin: '10px 0 0 0 !important'
});

export const SelectCustomerExpandBtn = styleMui(BarGroupIcon)({
    marginRight: '0 !important',
    color: `${WHITE} !important`
});

export const CustomerCheckerHeader = styleMui(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,
    img: {
        width: 150,
        margin: 0,
        marginLeft: '5px !important'
    }
});
