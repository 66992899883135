import React, { useEffect } from 'react';
import { TemperatureExposureSensorModalContent } from './TemperatureExposureSensorModal.view';
import { TemperatureExposureSensorModalProps } from './TemperatureExposureSensorModal.type';
import SensorLogApi from '../../../api/SensorLog';
import { useQuery } from '@tanstack/react-query';
import useConverter from '../../CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import { TemperatureExposureDate } from '../../Mixs/TemperatureExposureTopbar/TemperatureExposureTopbar.atom';
import { Wrapper } from 'helpers/wrapper';

const SensorLog = new SensorLogApi();

const TemperatureExposureSensorModal: React.FC<TemperatureExposureSensorModalProps> = (props): JSX.Element => {
    const dateRange = useRecoilValue(TemperatureExposureDate);
    const { fromTimezoneToUTC } = useConverter();

    const { data, isLoading, isFetching, refetch } = useQuery(
        ['temperatureExposureTyreReport'],
        () => {
            if (props.sensorId) {
                return SensorLog.getTemperatureExposureBySensor({
                    sensorId: props.sensorId,
                    dateFrom: fromTimezoneToUTC(dateRange.appliedDateFrom),
                    dateTo: fromTimezoneToUTC(dateRange.appliedDateTo)
                });
            }
        },
        { enabled: false }
    );

    useEffect(() => {
        refetch();
    }, [dateRange.appliedDateFrom, dateRange.appliedDateTo]);

    return (
        <TemperatureExposureSensorModalContent
            isLoading={isLoading || isFetching}
            data={
                data ?? {
                    temperatureCategories: {},
                    temperatureExposureReport: []
                }
            }
            data-testid='TemperatureExposureSensorModal-testid'
        />
    );
};

export default Wrapper(TemperatureExposureSensorModal);
