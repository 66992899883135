import React from 'react';
import { GeneralGetQueryProps } from './GeneralGetQuery.type';
import { useQuery } from '@tanstack/react-query';

function hasParams<T extends ({ ...args }?) => ReturnType<T>>(params: GeneralGetQueryProps<T>): boolean {
    return params['queryProps'] !== undefined;
}

/* tslint:disable */
export function GeneralGetQuery<T extends ({ ...args }?) => ReturnType<T>>(props: GeneralGetQueryProps<T>) {
    const useQueryProperties = useQuery(
        [`query${props.queryName}`],
        () => (hasParams(props) ? props.query(props['queryProps']) : props.query()),
        props.queryOptions
    );

    return <>{props.children(useQueryProperties)}</>;
}
/* tslint:enable */

export default GeneralGetQuery;
