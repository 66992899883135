import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as view from './TrackingMapTopBar.view';
import { DateFromAction, DateFromActionsEnum, DateFromData, TrackingMapTopBarProps } from './TrackingMapTopBar.type';
import { VehicleList } from '../../models/Vehicle.type';
import VehicleApi from '../../api/Vehicle';
import { MapActionsEnum } from '../../components/Map/Map.type';
import { MapAction } from '../../states/global/Map';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SelectedVehicleIds } from '../../states/global/TrackingMap';
import { UserInfo } from '../../states/global/User';
import { DateTime, Settings } from 'luxon';
import useConverter from '../CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

const dateReducer = (state: DateFromData, action: DateFromAction): DateFromData => {
    switch (action.type) {
        case DateFromActionsEnum.SET_SELECTED_DATE_TO:
            return { ...state, selectedDateTo: action.payload, isApplied: false };
        case DateFromActionsEnum.SET_APPLIED_DATE_FROM:
            return { ...state, isApplied: true };
        case DateFromActionsEnum.RESET:
            return { selectedDateTo: DateTime.local(), selectedDateFrom: DateTime.local().minus({ hours: 1 }) };
        case DateFromActionsEnum.SET_SELECTED_DATE:
            return { ...state, selectedDateFrom: action.payload.from, selectedDateTo: action.payload.to };
        case DateFromActionsEnum.RESET_APPLY:
            return { ...state, isApplied: false };
        default:
            return state;
    }
};

const TrackingMapTopBar: React.FC<TrackingMapTopBarProps> = (): JSX.Element => {
    const Vehicle = new VehicleApi();
    const userInfo = useRecoilValue(UserInfo);
    const [selectedVehicles, setSelectedVehicles] = useState<VehicleList>([]);
    const [vehicleList, setVehicleList] = useState<VehicleList>([]);
    const [vehicleGroup, setVehicleGroup] = useState<number>();
    const [showJourneyWarning, setShowJourneyWarning] = useState<boolean>(false);
    const [showPeriodWarning, setShowPeriodWarning] = useState<boolean>(false);
    const [date, setDate] = useReducer(dateReducer, {
        selectedDateFrom: undefined,
        selectedDateTo: undefined
    });
    const setMapAction = useSetRecoilState(MapAction);
    const setSelectedVehicleIds = useSetRecoilState(SelectedVehicleIds);
    const vehicleIntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const datePeriodIsValid = useMemo<boolean>(() => {
        const fromLowerTo: boolean = date.selectedDateFrom?.valueOf() <= date.selectedDateTo?.valueOf();
        const fromIsValid: boolean = date.selectedDateFrom?.isValid;
        const toIsValid: boolean = date.selectedDateTo?.isValid;
        return fromIsValid && toIsValid && fromLowerTo;
    }, [date]);
    const { fromTimezoneToUTC } = useConverter();

    const { refetch: loadVehicleList } = useQuery(
        ['loadVehicleListQuery-trackingMapTopBar'],
        () => Vehicle.getList(vehicleGroup),
        {
            enabled: false,
            onSuccess: (dataOnSuccess) => setVehicleList(dataOnSuccess),
            onError: () => setVehicleList([])
        }
    );

    const clearVehicleInterval = () => {
        clearInterval(vehicleIntervalRef.current as NodeJS.Timeout);
        vehicleIntervalRef.current = null;
    };

    useEffect(() => {
        const vehicleIds = selectedVehicles.map((vehicle) => vehicle.id);
        setSelectedVehicleIds(vehicleIds);
        clearVehicleInterval();
        vehicleIntervalRef.current = null;
        if (date.isApplied && !showPeriodWarning) {
            setMapAction({
                action: MapActionsEnum.LOAD_MULTIPLE_JOURNEY_LINE,
                params: {
                    vehicleIds,
                    dateFrom: fromTimezoneToUTC(date.selectedDateFrom),
                    dateTo: fromTimezoneToUTC(date.selectedDateTo)
                }
            });
            setDate({ type: DateFromActionsEnum.RESET_APPLY });
        }
    }, [selectedVehicles, date]);

    useEffect(() => {
        Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        loadVehicleList();
        setDate({
            type: DateFromActionsEnum.SET_SELECTED_DATE,
            payload: {
                from: DateTime.local().minus({ minutes: userInfo.user?.userSetting.mapJourneyReport }),
                to: DateTime.local()
            }
        });
    }, [userInfo.user?.userSetting.timezone.timezoneName]);

    useEffect(() => {
        setDate({
            type: DateFromActionsEnum.SET_SELECTED_DATE,
            payload: {
                from: DateTime.local().minus({ minutes: userInfo.user?.userSetting.mapJourneyReport }),
                to: DateTime.local()
            }
        });
    }, [userInfo.user?.userSetting.mapJourneyReport]);

    useEffect(() => {
        loadVehicleList();
    }, [vehicleGroup]);

    return (
        <view.TrackingMapTopBarContent
            vehicleList={vehicleList}
            setSelectedVehicles={setSelectedVehicles}
            selectedVehicles={selectedVehicles}
            showJourneyWarning={showJourneyWarning}
            setShowJourneyWarning={setShowJourneyWarning}
            showPeriodWarning={showPeriodWarning}
            setShowPeriodWarning={setShowPeriodWarning}
            date={date}
            setDate={setDate}
            datePeriodIsValid={datePeriodIsValid}
            vehicleGroup={vehicleGroup}
            setVehicleGroup={setVehicleGroup}
            data-testid={'TrackingMapTopBar-testid'}
        />
    );
};

export default Wrapper(TrackingMapTopBar);
