import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { PRIMARY, SUCCESS, BACKGROUND_BLUE, WHITE } from '../../../../colors';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Grid } from '@mui/material';
import { TextField, Typography } from '@mui/material';
import UiButton from '../../../UiButton/UiButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export const CustomButton = styled(UiButton)`
    &&.MuiButton-root {
        min-width: 100%;
        margin: 0px;
        padding: 0px;
    }

    &&.MuiButtonBase-root {
        min-width: 100%;
        margin: 0px;
        padding: 0px;
        svg {
            font-size: 27px;
        }
    }

    background-color: transparent !important;
    svg {
        ${applyStyleByMode({
            styleOld: `color:${PRIMARY} !important;`,
            styleJade: `color:${SUCCESS} !important;`
        })}
    }
`;

export const CustomListButton = styled(ListItemButton)`
    &.MuiButtonBase-root {
        border-radius: 20px !important;
        padding: 3px 5px !important;
    }
`;

export const CustomCalendarPicker = styled(DateCalendar)`
    & .MuiPickersCalendarHeader-labelContainer {
        font-size: 1.2em;
        font-weight: 600;
    }

    & .MuiPickersArrowSwitcher-button svg {
        font-size: 1.5em;
        font-weight: 600;
    }

    & .MuiPickersCalendarHeader-switchViewButton {
        font-size: 1.5em;
    }

    & .MuiButtonBase-root {
        border-radius: 50px;
        margin: 0 0.5px;
        padding: 0;
        &.Mui-selected {
            background-color: ${BACKGROUND_BLUE} !important;
        }
    }

    & .Mui-selected {
        background-color: ${BACKGROUND_BLUE} !important;
    }

    & .MuiPickersDay-root,
    & .MuiDayPicker-weekDayLabel {
        font-size: 1.2em;
    }
`;

export const CustomListText = styled(ListItemText)`
    & .MuiTypography-root {
        font-size: 1.2em;
    }
`;

export const HrsMinContainer = styled.div<{ $ampm: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
        margin-left: ${(props) => (props.$ampm ? 63 : 0)}px;
    }

    .innerDiv {
        display: inline-flex;
        width: 50px;
    }

    .innerDiv:nth-child(2) {
        text-align: center;
        width: 20px;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column !important;
`;

export const CustomList = styled(List)`
    &.MuiList-root {
        display: inline-flex !important;
        width: 72px;
        margin-left: 5px;
    }

    & .MuiButtonBase-root {
        margin: 0 1px;
        justify-content: center;
    }
    & .Mui-selected {
        background-color: ${BACKGROUND_BLUE} !important;
        color: ${WHITE} !important;
    }
`;

export const CustomListItem = styled(ListItemButton)`
    width: 40px;
    &.MuiListItemButton-root {
        & .Mui-selected {
        }
    }
`;

export const CustomTextField = styled(TextField)`
    input {
        text-align: center;
    }

    && .MuiInputBase-input {
        font-size: 1.2rem;
    }
`;

export const GridContainer = styled(Grid)`
    text-align: center;
    align-items: center;

    &.MuiGrid-root {
       
        & .MuiGrid-item {
           
            padding-top: 0px;
            padding-left: 0px;
        }

    @media (max-width: 768px) {
        &.MuiGrid-root {
            display: flex;
            flex-direction: column;
            text-align: initial;
            align-items: initial;
            & .MuiGrid-item {
                padding-left: 0px !important;
            }
        }

        &&& .timePicker {
            text-align: center;
            align-items: center;
            margin: auto;
            padding-bottom: 10px;
        }

        &&& .datePicker {
            margin: 0;
            padding: 0;
        }
    }
`;

export const GridTimePicker = styled(Grid)`
    & .MuiGrid-item {
        padding: 0px;
    }
`;

export const GridCalendar = styled(Grid)<{ $onlyDate?: boolean }>`
    @media (min-width: 768px) {
        ${({ $onlyDate }) => ($onlyDate ? '' : 'border-right: 1px solid #ddd;')};
    }
`;

export const GridTitle = styled(Grid)``;

export const PointsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Title = styled(Typography)`
    &.MuiTypography-root {
        padding: 15px 0px 2px 23px !important;
        text-align: left;
        font-size: 20px;

        @media (max-width: 768px) {
            padding-left: 30px !important;
        }
    }
`;

export const DateTimeText = styled(Typography)`
    &.MuiTypography-root {
        font-size: 2.32em;
        height: 70px;
        @media (min-width: 768px) {
        }
    }
`;

export const Text = styled(Typography)<{ $ampm: boolean }>`
    &.MuiTypography-root {
        font-size: 1.2em;
        font-weight: 600;
        @media (min-width: 768px) {
            text-align: ${(props) => (props.$ampm ? 'left' : 'center')} !important;
            margin-left: ${(props) => (props.$ampm ? 19 : 0)}px;
        }
    }
`;

export const HelperText = styled.div<{ $ampm: boolean }>`
    @media (min-width: 768px) {
        margin-right: ${(props) => (props.$ampm ? 63 : 0)}px;
    }
`;

export const CustomTimePicker = styled(TimePicker)`
    font-size: 20px;
    width: 150px;
    padding: 5px 10px;
`;

export const CustomStaticDatePicker = styled(StaticDateTimePicker)`
    height: 300px;
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)`
    &.MuiFormControl-root {
        margin: 0 3px 3px 0 !important;
    }
    ${applyStyleByMode({
        styleOld: `
            && .MuiInputBase-input {
                padding: 8.5px 0px 8.5px 14px !important;
            }
        `
    })}
`;
