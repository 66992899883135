import { AvatarModel } from 'components/User/AccountSettingsForm/AccountSettingsForm.type';
import PortalApi from 'helpers/api/PortalApiClient';
import {
    UserAccount,
    UserAlerts,
    UserAlertsData,
    UserData,
    UserGeneralSettings,
    UserLenguage,
    UserTracking,
    UserUnits,
    UserModel
} from 'models/User.type';
import { NotificationSettingsPatch, NotificationsSettings, NotificationsSettingsData } from 'models/Notification.type';
import { UserDataPost } from 'components/User/ChangePasswordForm/ChangePasswordForm.type';

export default class Account extends PortalApi<UserModel> {
    route = 'account';

    changePassword = async (ChangePasswordFormData): Promise<UserDataPost> => {
        return await this.post({
            extendUrl: 'change-password',

            apiProject: undefined,
            data: ChangePasswordFormData
        });
    };

    getNotification = async (): Promise<NotificationsSettings[]> => {
        return await this.get({ extendUrl: 'notification', apiProject: undefined, version: 'v2' });
    };

    patchAccount = async (updateData: UserAccount): Promise<UserData> => {
        return await this.patch<UserData, UserAccount>({ apiProject: undefined, data: updateData });
    };

    patchNotification = async (updateData: {
        notifications: NotificationSettingsPatch[];
    }): Promise<NotificationsSettings[]> => {
        return await this.patch<NotificationsSettings[], { notifications: NotificationSettingsPatch[] }>({
            extendUrl: 'notification',
            version: 'v2',
            apiProject: undefined,
            data: updateData
        });
    };

    patchSetting = async (
        updateData: UserAlerts | UserTracking | UserUnits | UserGeneralSettings | UserLenguage
    ): Promise<UserAlertsData> => {
        return await this.patch<
            UserAlertsData,
            UserAlerts | UserTracking | UserUnits | UserGeneralSettings | UserLenguage
        >({ extendUrl: 'setting', apiProject: undefined, data: updateData });
    };

    getAvatar = async (): Promise<string> => {
        return await this.getFile({ extendUrl: 'avatar', apiProject: undefined });
    };

    patAvatar = async (image: string | ArrayBuffer | undefined): Promise<AvatarModel['avatar']> => {
        return await this.patch<AvatarModel['avatar'], { avatar: string | ArrayBuffer | undefined }>({
            extendUrl: 'avatar',

            apiProject: undefined,
            data: { avatar: image }
        });
    };

    getParsed = async () => {
        this.showError404 = false;
        return await this.get<{ user: UserModel; version?: number }>({ apiProject: undefined }).then((res) => {
            const parsedResponse = parseDateTime(res);
            return parsedResponse.user;
        });
    };

    patchNotificationMute = async (status: boolean): Promise<NotificationsSettingsData> => {
        return await this.patch<NotificationsSettingsData, { status: boolean }>({
            extendUrl: 'notification/mute',

            apiProject: undefined,
            data: { status }
        });
    };
}

export const parseDateTime = (res: { user: UserModel }) => {
    const { dateFormat, timeFormat, useMineTimezone, timezone } = res.user.userSetting;
    const timeFormatType = ['HH:mm', 'hh:mm'];
    const dateFormatType = ['yyyy-MM-dd', 'YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY'];

    if (timeFormatType.includes(timeFormat) || dateFormatType.includes(dateFormat)) {
        return res;
    }

    const newUserData = {
        ...res,
        user: {
            ...res.user,
            userSetting: {
                ...res.user.userSetting,
                dateFormat: dateFormat.replace(/Y/, 'yyyy').replace(/m/, 'MM').replace(/d/, 'dd'),
                timeFormat: timeFormat.replace(/H/, 'HH').replace(/h/, 'hh').replace(/i/, 'mm'),
                timezone: useMineTimezone ? res.user.customer.timezone : timezone,
                customerTimezone: timezone
            }
        }
    };

    return newUserData;
};
