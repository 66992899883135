import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const RoutesMapViewContent = styled.div``;

export const ContainerMessageNotRoute = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
`;
export const TextMoreIcon = styled.div`
    line-height: calc(70vh - 33px);
    text-align: center;
    width: 100%;

    span {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.titleSize};`;
        }}
    }
`;
