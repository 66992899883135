import React from 'react';
import { Skeleton } from '@mui/material';
import { DeviceInformationTableLoaderContainer } from './DeviceInformationTable.style';

const DeviceInformationTableLoader: React.FC = (): JSX.Element => {
    return (
        <DeviceInformationTableLoaderContainer data-testid='DeviceInformationTableLoader-Container'>
            <Skeleton variant='rounded' width='100%' height={30} />
            <Skeleton variant='rounded' width='100%' height={150} />
            <Skeleton variant='rounded' width='100%' height={30} />
            <Skeleton variant='rounded' width='100%' height={150} />
            <Skeleton variant='rounded' width='100%' height={30} />
            <Skeleton variant='rounded' width='100%' height={150} />
        </DeviceInformationTableLoaderContainer>
    );
};

export default DeviceInformationTableLoader;
