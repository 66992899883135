import { AreaFeaturesResponse } from 'models/Area.type';
import { AreaTypeModel } from 'models/AreaType.type';

export type AreaEditorFormProps = {};

export enum AreaMode {
    CREATE = 'CREATE',
    NEW = 'NEW',
    EDIT = 'EDIT',
    new = 'new',
    edit = 'edit'
}

export type startingMode = {
    mode: undefined | AreaMode.new | AreaMode.edit;
    id: undefined | number;
};

export type AreaEditorFormViewProps = {
    data: AreaFeaturesResponse | undefined;
    onChangeSpeedZone: () => void;
    onChangeCounterZone: () => void;
    onChangeMaintananceZone: () => void;
    onChangeTempSpeedZone: () => void;
    onChangeMinSpeed: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeMaxSpeed: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeTempSpeedLvl1: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeTempSpeedLvl2: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeTempSpeedLvl3: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeAreaName: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeAreaColor: (color: string) => void;
    onChangeSelectorOption: (id: number | null) => void;
    onChangeAreaType: (areaType: AreaTypeModel) => void;
    onClickSave: () => void;
    onClickCancel: () => void;
    onClickCreate: () => void;
    showWarningColorMessage: boolean;
    resetColorByAreaType: () => void;
    isLoading: boolean;
    isSaving: boolean;
};
