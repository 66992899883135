import React, { useState } from 'react';
import * as view from './UiInputSearch.view';
import { KeyEvent, UiInputSearchProps } from './UiInputSearch.type';
import { Wrapper } from 'helpers/wrapper';

const UiInputSearch: React.FC<UiInputSearchProps> = (props): JSX.Element => {
    const [showClearIcon, setShowClearIcon] = useState('none');
    const [valueSearch, setValueSearch] = useState('');
    const inputSearchRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleChangeInputSearch = (event): void => {
        if (event.target.value === '') {
            setShowClearIcon('none');
        } else {
            setShowClearIcon('flex');
        }
        if (props.searchByEnter) {
            if (event.key && event.key.toUpperCase() === KeyEvent.ENTER) {
                props.getValueInputSearch(event.target.value ?? '');
            }
            if (event.key && event.target.value.length === 1 && event.key.toUpperCase() === KeyEvent.BACKSPACE) {
                setTimeout(() => {
                    props.getValueInputSearch('');
                }, 100);
            }
        } else {
            props.getValueInputSearch(event.target.value);
        }

        setValueSearch(event.target.value);
    };

    const handleClickInputAdornment = (): void => {
        setValueSearch('');
        props.getValueInputSearch('');
        setShowClearIcon('none');
    };

    const handleClickIconSearch = (): void => {
        props.getValueInputSearch(valueSearch);
        inputSearchRef.current && inputSearchRef.current.focus();
    };

    return (
        <view.UiInputSearchContent
            data-testid='UiInputSearch-testid'
            showClearIcon={showClearIcon}
            handleChangeInputSearch={handleChangeInputSearch}
            inputSearchRef={inputSearchRef}
            handleClickIconSearch={handleClickIconSearch}
            handleClickInputAdornment={handleClickInputAdornment}
            valueSearch={valueSearch}
            setShowTooltip={setShowTooltip}
            showTooltip={showTooltip}
            {...props}
        />
    );
};

export default Wrapper(UiInputSearch);
