import styled from 'styled-components';
import { BACKGROUND_BLUE, BACKGROUND_PAPER_DARK_MODE, GRAY_LIGHT, SUCCESS } from '../../../Ui/colors';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import { ThemeMode } from '../../../../states/global/Theme';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';

export const ExportedReportDetailContainer = styled.div``;

export const TitleReport = styled(Typography)``;

export const StatusReport = styled(Typography)<{ color?: string }>`
    ${({ color }) => {
        return applyStyleByMode({
            styleJade: `color: ${
                useTheme().palette.mode === ThemeMode.light ? color ?? SUCCESS : BACKGROUND_BLUE
            } !important;`
        });
    }}
    margin-bottom: 4% !important;
`;

export const TitleItems = styled(Typography)<{ $bold?: boolean }>`
    ${(props) => props.$bold && 'font-weight: bold !important;'}
    margin-bottom: 5% !important;
`;

export const ItemsContainer = styled.div`
    ${() => {
        return applyStyleByMode({
            styleJade: `background-color: ${
                useTheme().palette.mode === ThemeMode.light ? GRAY_LIGHT : BACKGROUND_PAPER_DARK_MODE
            };`
        });
    }}
    padding: 15px 20px;
    border-radius: 4px;
    margin-bottom: 5%;
`;

export const LoadingContent = styled.div`
    margin: 0 auto;
`;

export const TableDetail = styled(Table)`
    margin: 0 auto !important;
    width: 90% !important;

    & .MuiTableCell-root {
        font-size: 13px !important;
    }
`;
