import React from 'react';
import { AlertsBreakdownViewProps } from './AlertsBreakdownTableBody.type';
import { Loader, LoadingContent } from './AlertsBreakdownTableBody.style';
import AlertsBreakdownRow from '../AlertsBreakdownTableRow';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useRecoilValue } from 'recoil';

export const AlertsBreakdownTableBodyContent: React.FC<AlertsBreakdownViewProps> = ({
    isLoading,
    data
}): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);

    if (isLoading) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell align='center' colSpan={8}>
                        <LoadingContent>
                            <Loader />
                        </LoadingContent>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    } else {
        return (
            <TableBody data-testid='AlertsBreakdownTableBodyContent'>
                <AlertsBreakdownRow data={data?.highTemperature} title='high_temperature' />
                <AlertsBreakdownRow data={data?.highPressure} title='high_pressure' />
                <AlertsBreakdownRow data={data?.lowPressure} title='low_pressure' />
                {customerSettings.temperature_prediction && (
                    <AlertsBreakdownRow data={data?.temperaturePrediction} title='temperature_prediction' />
                )}
            </TableBody>
        );
    }
};
