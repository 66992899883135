import React, { useEffect, useState } from 'react';
import { AddAlertNotificationManagmentContent } from './AddAlertNotificationManagment.view';
import { AddAlertNotificationManagmentProps, InitialValuesType } from './AddAlertNotificationManagment.type';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import AlertNotificationApi from 'api/AlertNotification';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import {
    AddAlertNotificationModel,
    AlertNotificationCallsUserType,
    UpdateAlertNotificationModel
} from 'models/AlertNotification.type';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

const alertNotificationApi = new AlertNotificationApi();

const AddAlertNotificationManagment: React.FC<AddAlertNotificationManagmentProps> = ({
    alertNotificationUser
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { userFormatUTC } = useConverter();
    const [alertNotificationUserInvalid, setAlertNotificationUserInvalid] = useState<boolean>(false);
    const [alertNotificationCallsUser, setAlertNotificationCallsUser] = useState<AlertNotificationCallsUserType[]>([]);
    const [userSelected, setUserSelected] = useState<AlertNotificationCallsUserType | null>(null);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('alertNotificationManagment-RefreshTableAtom'));
    const initialValues: InitialValuesType = {
        date: alertNotificationUser?.date
            ? userFormatUTC({
                  date: alertNotificationUser.date,
                  format: 'date',
                  returnObjectLuxon: true
              })
            : DateTime.local(),
        timeFrom: alertNotificationUser?.timeFrom
            ? userFormatUTC({
                  date: alertNotificationUser.timeFrom,
                  format: 'time',
                  returnObjectLuxon: true
              })
            : DateTime.local(),
        timeTo: alertNotificationUser?.timeTo
            ? DateTime.fromISO(alertNotificationUser.timeTo, { zone: 'utc' })
            : DateTime.local().plus({ hours: 1 })
    };

    const { mutate: addMutate, isLoading: addMutateLoading } = useMutation(alertNotificationApi.addAlertNotfication, {
        onSuccess: ({ data }) => {
            if (data.alertNotificationCallsScheduler) {
                Success({
                    text: translate('t.added_alert_notification_management')
                });
                setRefreshTableAtom(true);
                modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
            }
        }
    });

    const { mutate: updateMutate, isLoading: updateMutateLoading } = useMutation(
        alertNotificationApi.updateAlertNotfication,
        {
            onSuccess: (data) => {
                if (data.alertNotificationCallsScheduler) {
                    Success({
                        text: translate('t.updated_alert_notification_management')
                    });
                    setRefreshTableAtom(true);
                    modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                }
            }
        }
    );

    const handleSubmit = (values: InitialValuesType) => {
        const alertNotificationCallsUserSend = alertNotificationCallsUser.map((alertUser) => {
            return {
                user: alertUser.user,
                priority: alertUser.priority
            };
        });

        const dataSend: AddAlertNotificationModel | UpdateAlertNotificationModel = {
            id: alertNotificationUser?.id || undefined,
            date: DateTime.fromISO(values.date, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
            timeFrom: values.timeFrom.toFormat('HH:mm:ss'),
            timeTo: values.timeTo.toFormat('HH:mm:ss'),
            alertNotificationCallsUser: alertNotificationCallsUserSend
        };

        alertNotificationUser?.id ? updateMutate(dataSend as UpdateAlertNotificationModel) : addMutate(dataSend);
    };

    const addAlertNotificationCallsUser = () => {
        if (userSelected?.user) {
            if (
                !alertNotificationCallsUser.filter(
                    (alertNotificationUser) => alertNotificationUser.user === userSelected.user
                ).length
            ) {
                setAlertNotificationCallsUser((prevState) => [...prevState, userSelected]);
            }
        } else {
            setAlertNotificationUserInvalid(true);
        }
    };

    const removeAlertNotificationCallsUser = (userRemove: AlertNotificationCallsUserType) => {
        setAlertNotificationCallsUser((prevState) => {
            const newState = prevState.filter(
                (alertNotificationUser) => alertNotificationUser.user !== userRemove.user
            );
            return newState.map((notificationUser, key) => {
                notificationUser.priority = key;
                return notificationUser;
            });
        });
    };

    useEffect(() => {
        if (alertNotificationUser) {
            const newAlertNotificationUser = alertNotificationUser.alertNotificationCallsUser.map(
                (alertNotificationUser) => {
                    return {
                        priority: alertNotificationUser.priority,
                        user: alertNotificationUser.user.id,
                        username: alertNotificationUser.user.username
                    };
                }
            );

            setAlertNotificationCallsUser(newAlertNotificationUser);
        }
    }, [alertNotificationUser]);

    return (
        <AddAlertNotificationManagmentContent
            data-testid='AddAlertNotificationManagment-testid'
            initialValues={initialValues}
            loading={addMutateLoading || updateMutateLoading}
            userSelected={userSelected}
            alertNotificationCallsUser={alertNotificationCallsUser}
            alertNotificationUserInvalid={alertNotificationUserInvalid}
            handleSubmit={handleSubmit}
            modalDispatch={modalDispatch}
            setUserSelected={setUserSelected}
            addAlertNotificationCallsUser={addAlertNotificationCallsUser}
            removeAlertNotificationCallsUser={removeAlertNotificationCallsUser}
            setAlertNotificationUserInvalid={setAlertNotificationUserInvalid}
        />
    );
};

export default Wrapper(AddAlertNotificationManagment);
