import { selector } from 'recoil';
import { UserInfo } from '../states/global/User';

export const DateTimeFormat = selector({
    key: 'DateTimeFormat',
    get: ({ get }) => {
        return (displaySeconds = true): string => {
            const userFormats: { dateFormat: string; timeFormat: string } = {
                dateFormat: get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                timeFormat: get(UserInfo)?.user?.userSetting.timeFormat || 'HH:mm'
            };
            if (displaySeconds) {
                userFormats.timeFormat = `${userFormats.timeFormat}:ss`;
            }
            if (userFormats.timeFormat.includes('hh:mm')) {
                userFormats.timeFormat = `${userFormats.timeFormat} A`;
            }
            return `${userFormats.dateFormat} ${userFormats.timeFormat}`;
        };
    }
});

export const Is12HrsFormat = selector<boolean>({
    key: 'Is12HrsFormat',
    get: ({ get }) => {
        const timeFormat: string = get(UserInfo)?.user?.userSetting.timeFormat || 'hh:mm';
        return timeFormat === 'hh:mm';
    }
});

export const GetDateMask = selector<string>({
    key: 'GetDateMask',
    get: ({ get }) => {
        const dateFormat: string = get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD';
        return dateFormat.replaceAll(/[YyMmDd]/g, '_');
    }
});

type GetTimeMaskUserProps = {
    displaySeconds?: boolean;
};

export const GetUserTimeMask = selector({
    key: 'GetUserTimeMask',
    get:
        ({ get }) =>
        (props?: GetTimeMaskUserProps) => {
            const { displaySeconds = false } = props ?? {};

            const timeFormat = get(UserInfo)?.user?.userSetting.timeFormat || 'hh:mm';

            let mask: string = displaySeconds ? `${timeFormat}:ss` : timeFormat;

            const is12HrsFormat: boolean = get(Is12HrsFormat);
            if (is12HrsFormat) {
                mask += ' _M';
            }

            return mask.replaceAll(/[Hhms]/g, '_');
        }
});

export const DateTimeMask = selector<string>({
    key: 'DateTimeMask',
    get: ({ get }) => {
        const dateMask = get(GetDateMask);
        const timeMask = get(GetUserTimeMask);
        return `${dateMask} ${timeMask()}`;
    }
});
