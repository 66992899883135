import { atom } from 'recoil';

export const FilterVehicleValue = atom<{ inpuValue: string; searchValue: string }>({
    key: 'FilterVehicleValue',
    default: {
        inpuValue: '',
        searchValue: ''
    }
});

export const AffectedVehiclesCount = atom<{
    current: number;
    latest: number;
}>({
    key: 'AffectedVehiclesCount',
    default: {
        current: 0,
        latest: 0
    }
});

export type TableFnType = Record<string, any>;

export const RefetchAffectedVehicles = atom<TableFnType>({
    key: 'RefetchAffectedVehicles',
    default: {}
});
