import UndegroundBlue from '../../assets/images/vehicle-icons/mining/48x48/undeground_Blue.png';
import UndegroundDarkgrey from '../../assets/images/vehicle-icons/mining/48x48/undeground_Darkgrey.png';
import UndegroundRed from '../../assets/images/vehicle-icons/mining/48x48/undeground_Red.png';
import UndegroundWhite from '../../assets/images/vehicle-icons/mining/48x48/undeground_White.png';
import UndegroundYellow from '../../assets/images/vehicle-icons/mining/48x48/undeground_Yellow.png';
import AdtBlue from '../../assets/images/vehicle-icons/mining/48x48/adt_Blue.png';
import AdtDarkgrey from '../../assets/images/vehicle-icons/mining/48x48/adt_Darkgrey.png';
import AdtRed from '../../assets/images/vehicle-icons/mining/48x48/adt_Red.png';
import AdtWhite from '../../assets/images/vehicle-icons/mining/48x48/adt_White.png';
import AdtYellow from '../../assets/images/vehicle-icons/mining/48x48/adt_Yellow.png';
import Adt1Blue from '../../assets/images/vehicle-icons/mining/48x48/adt1_Blue.png';
import Adt1Darkgrey from '../../assets/images/vehicle-icons/mining/48x48/adt1_Darkgrey.png';
import Adt1Red from '../../assets/images/vehicle-icons/mining/48x48/adt1_Red.png';
import Adt1White from '../../assets/images/vehicle-icons/mining/48x48/adt1_White.png';
import Adt1Yellow from '../../assets/images/vehicle-icons/mining/48x48/adt1_Yellow.png';
import Adt2Blue from '../../assets/images/vehicle-icons/mining/48x48/adt2_Blue.png';
import Adt2Darkgrey from '../../assets/images/vehicle-icons/mining/48x48/adt2_Darkgrey.png';
import Adt2Red from '../../assets/images/vehicle-icons/mining/48x48/adt2_Red.png';
import Adt2White from '../../assets/images/vehicle-icons/mining/48x48/adt2_White.png';
import Adt2Yellow from '../../assets/images/vehicle-icons/mining/48x48/adt2_Yellow.png';
import DumptruckBlue from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Blue.png';
import DumptruckDarkgrey from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Darkgrey.png';
import DumptruckRed from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Red.png';
import DumptruckWhite from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_White.png';
import DumptruckYellow from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Yellow.png';
import DumptruckPink from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Pink.png';
import DumptruckRainbow from '../../assets/images/vehicle-icons/mining/48x48/dumptruck_Rainbow.png';
import GraderBlue from '../../assets/images/vehicle-icons/mining/48x48/grader_Blue.png';
import GraderDarkgrey from '../../assets/images/vehicle-icons/mining/48x48/grader_Darkgrey.png';
import GraderRed from '../../assets/images/vehicle-icons/mining/48x48/grader_Red.png';
import GraderWhite from '../../assets/images/vehicle-icons/mining/48x48/grader_White.png';
import GraderYellow from '../../assets/images/vehicle-icons/mining/48x48/grader_Yellow.png';
import LoaderBlue from '../../assets/images/vehicle-icons/mining/48x48/loader_Blue.png';
import LoaderDarkgrey from '../../assets/images/vehicle-icons/mining/48x48/loader_Darkgrey.png';
import LoaderRed from '../../assets/images/vehicle-icons/mining/48x48/loader_Red.png';
import LoaderWhite from '../../assets/images/vehicle-icons/mining/48x48/loader_White.png';
import LoaderYellow from '../../assets/images/vehicle-icons/mining/48x48/loader_Yellow.png';
import SkidSteerLoaderBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Blue.png';
import SkidSteerLoaderGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Green.png';
import SkidSteerLoaderGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Grey.png';
import SkidSteerLoaderRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Red.png';
import SkidSteerLoaderYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Yellow.png';
import SteamRollerBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Black.png';
import SteamRollerBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Blue.png';
import SteamRollerGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Green.png';
import SteamRollerGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Grey.png';
import SteamRollerRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Red.png';
import SteamRollerYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SteamRoller_Yellow.png';
import StreetSweeperBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Black.png';
import StreetSweeperBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Blue.png';
import StreetSweeperGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Green.png';
import StreetSweeperGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Grey.png';
import StreetSweeperRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Red.png';
import StreetSweeperYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/StreetSweeper_Yellow.png';
import TelescopicHandlerBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Black.png';
import TelescopicHandler_Blue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Blue.png';
import TelescopicHandlerGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Green.png';
import TelescopicHandlerGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Grey.png';
import TelescopicHandlerRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Red.png';
import TelescopicHandlerYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TelescopicHandler_Yellow.png';
import TruckMountedCraneBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Black.png';
import TruckMountedCrane_Blue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Blue.png';
import TruckMountedCraneGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Green.png';
import TruckMountedCraneGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Grey.png';
import TruckMountedCraneRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Red.png';
import TruckMountedCraneYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane__Yellow.png';
import TruckMountedCraneWorkingBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Black.png';
import TruckMountedCraneWorkingBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Blue.png';
import TruckMountedCraneWorkingGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Green.png';
import TruckMountedCraneWorkingGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Grey.png';
import TruckMountedCraneWorkingRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Red.png';
import TruckMountedCranWorkingYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/TruckMountedCrane_Working_Yellow.png';
import WheeledExcavatorBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Black.png';
import WheeledExcavatorBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Blue.png';
import WheeledExcavatorGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Green.png';
import WheeledExcavatorGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Grey.png';
import WheeledExcavatorRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Red.png';
import WheeledExcavatorYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/WheeledExcavator_Yellow.png';
import BackhoeLoaderBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Black.png';
import BackhoeLoaderBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Blue.png';
import BackhoeLoaderGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Green.png';
import BackhoeLoaderGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Grey.png';
import BackhoeLoaderRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Red.png';
import BackhoeLoaderYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/BackhoeLoader_Yellow.png';
import FarmTractorBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Black.png';
import FarmTractorBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Blue.png';
import FarmTractorGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Green.png';
import FarmTractorGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Grey.png';
import FarmTractorRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Red.png';
import FarmTractorYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/FarmTractor_Yellow.png';
import LoaderBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/Loader_Black.png';
import LoaderBlueHeavy from '../../assets/images/vehicle-icons/heavy_equipment/48x48/Loader_Blue.png';
import LoaderGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/Loader_Green.png';
import LoaderGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/Loader_Grey.png';
import LoaderRedHeavy from '../../assets/images/vehicle-icons/heavy_equipment/48x48/Loader_Red.png';
import RoadSweeperBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Black.png';
import RoadSweeperBlue from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Blue.png';
import RoadSweeperGreen from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Green.png';
import RoadSweeperGrey from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Grey.png';
import RoadSweeperRed from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Red.png';
import RoadSweeperYellow from '../../assets/images/vehicle-icons/heavy_equipment/48x48/RoadSweeper_Yellow.png';
import SkidSteerLoaderBlack from '../../assets/images/vehicle-icons/heavy_equipment/48x48/SkidSteerLoader_Black.png';

import NotAvatar from '../../assets/images/not_avatar.png';
import Logolight from '../../assets/images/siteLight.png';
import Logo from '../../assets/images/site.png';
import Heading from '../../assets/images/heading.png';
import SpeedPoint from '../../assets/images/point_speed.png';
import SpeedPointLoaded from '../../assets/images/point_speed_loaded.png';
import SpeedPointUnloaded from '../../assets/images/point_speed_unloaded.png';
import ca from '../../assets/images/flags/ca.png';
import es from '../../assets/images/flags/es.png';
import gb from '../../assets/images/flags/gb.png';
import sa from '../../assets/images/flags/sa.png';
import ua from '../../assets/images/flags/ua.png';
import us from '../../assets/images/flags/us.png';
import pt from '../../assets/images/flags/pt.png';
import kz from '../../assets/images/flags/kz.png';
import LogoWithOutBg from '../../assets/images/logo.bridgestone.itrack.png';
import TruckSpeed from '../../assets/images/vehicle-detail/Truck.png';
import TruckLongitudinal from '../../assets/images/vehicle-detail/Truck-longitudinal.png';
import TruckLateral from '../../assets/images/vehicle-detail/Truck-lateral.png';
import TruckVertical from '../../assets/images/vehicle-detail/Truck-vertical.png';
import TireByHub from '../../assets/images/vehicle-icons/hub/48x48/tyre_bay_hub.png';

export const getImgSource = (image: string) => {
    const images = {
        'mining/48x48/undeground_Blue.png': UndegroundBlue,
        'mining/48x48/undeground_Darkgrey.png': UndegroundDarkgrey,
        'mining/48x48/undeground_Red.png': UndegroundRed,
        'mining/48x48/undeground_White.png': UndegroundWhite,
        'mining/48x48/undeground_Yellow.png': UndegroundYellow,
        'mining/48x48/adt_Blue.png': AdtBlue,
        'mining/48x48/adt_Darkgrey.png': AdtDarkgrey,
        'mining/48x48/adt_Red.png': AdtRed,
        'mining/48x48/adt_White.png': AdtWhite,
        'mining/48x48/adt_Yellow.png': AdtYellow,
        'mining/48x48/adt1_Blue.png': Adt1Blue,
        'mining/48x48/adt1_Darkgrey.png': Adt1Darkgrey,
        'mining/48x48/adt1_Red.png': Adt1Red,
        'mining/48x48/adt1_White.png': Adt1White,
        'mining/48x48/adt1_Yellow.png': Adt1Yellow,
        'mining/48x48/adt2_Blue.png': Adt2Blue,
        'mining/48x48/adt2_Darkgrey.png': Adt2Darkgrey,
        'mining/48x48/adt2_Red.png': Adt2Red,
        'mining/48x48/adt2_White.png': Adt2White,
        'mining/48x48/adt2_Yellow.png': Adt2Yellow,
        'mining/48x48/dumptruck_Blue.png': DumptruckBlue,
        'mining/48x48/dumptruck_Darkgrey.png': DumptruckDarkgrey,
        'mining/48x48/dumptruck_Red.png': DumptruckRed,
        'mining/48x48/dumptruck_White.png': DumptruckWhite,
        'mining/48x48/dumptruck_Yellow.png': DumptruckYellow,
        'mining/48x48/dumptruck_Pink.png': DumptruckPink,
        'mining/48x48/dumptruck_Rainbow.png': DumptruckRainbow,
        'mining/48x48/grader_Blue.png': GraderBlue,
        'mining/48x48/grader_Darkgrey.png': GraderDarkgrey,
        'mining/48x48/grader_Red.png': GraderRed,
        'mining/48x48/grader_White.png': GraderWhite,
        'mining/48x48/grader_Yellow.png': GraderYellow,
        'mining/48x48/loader_Blue.png': LoaderBlue,
        'mining/48x48/loader_Darkgrey.png': LoaderDarkgrey,
        'mining/48x48/loader_Red.png': LoaderRed,
        'mining/48x48/loader_White.png': LoaderWhite,
        'mining/48x48/loader_Yellow.png': LoaderYellow,
        'hub/48x48/tyre_bay_hub.png': TireByHub,
        'heavy_equipment/48x48/SkidSteerLoader_Blue.png': SkidSteerLoaderBlue,
        'heavy_equipment/48x48/SkidSteerLoader_Green.png': SkidSteerLoaderGreen,
        'heavy_equipment/48x48/SkidSteerLoader_Grey.png': SkidSteerLoaderGrey,
        'heavy_equipment/48x48/SkidSteerLoader_Red.png': SkidSteerLoaderRed,
        'heavy_equipment/48x48/SkidSteerLoader_Yellow.png': SkidSteerLoaderYellow,
        'heavy_equipment/48x48/SteamRoller_Black.png': SteamRollerBlack,
        'heavy_equipment/48x48/SteamRoller_Blue.png': SteamRollerBlue,
        'heavy_equipment/48x48/SteamRoller_Green.png': SteamRollerGreen,
        'heavy_equipment/48x48/SteamRoller_Grey.png': SteamRollerGrey,
        'heavy_equipment/48x48/SteamRoller_Red.png': SteamRollerRed,
        'heavy_equipment/48x48/SteamRoller_Yellow.png': SteamRollerYellow,
        'heavy_equipment/48x48/StreetSweeper_Black.png': StreetSweeperBlack,
        'heavy_equipment/48x48/StreetSweeper_Blue.png': StreetSweeperBlue,
        'heavy_equipment/48x48/StreetSweeper_Green.png': StreetSweeperGreen,
        'heavy_equipment/48x48/StreetSweeper_Grey.png': StreetSweeperGrey,
        'heavy_equipment/48x48/StreetSweeper_Red.png': StreetSweeperRed,
        'heavy_equipment/48x48/StreetSweeper_Yellow.png': StreetSweeperYellow,
        'heavy_equipment/48x48/TelescopicHandler_Black.png': TelescopicHandlerBlack,
        'heavy_equipment/48x48/TelescopicHandler_Blue.png': TelescopicHandler_Blue,
        'heavy_equipment/48x48/TelescopicHandler_Green.png': TelescopicHandlerGreen,
        'heavy_equipment/48x48/TelescopicHandler_Grey.png': TelescopicHandlerGrey,
        'heavy_equipment/48x48/TelescopicHandler_Red.png': TelescopicHandlerRed,
        'heavy_equipment/48x48/TelescopicHandler_Yellow.png': TelescopicHandlerYellow,
        'heavy_equipment/48x48/TruckMountedCrane__Black.png': TruckMountedCraneBlack,
        'heavy_equipment/48x48/TruckMountedCrane__Blue.png': TruckMountedCrane_Blue,
        'heavy_equipment/48x48/TruckMountedCrane__Green.png': TruckMountedCraneGreen,
        'heavy_equipment/48x48/TruckMountedCrane__Grey.png': TruckMountedCraneGrey,
        'heavy_equipment/48x48/TruckMountedCrane__Red.png': TruckMountedCraneRed,
        'heavy_equipment/48x48/TruckMountedCrane__Yellow.png': TruckMountedCraneYellow,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Black.png': TruckMountedCraneWorkingBlack,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Blue.png': TruckMountedCraneWorkingBlue,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Green.png': TruckMountedCraneWorkingGreen,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Grey.png': TruckMountedCraneWorkingGrey,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Red.png': TruckMountedCraneWorkingRed,
        'heavy_equipment/48x48/TruckMountedCrane_Working_Yellow.png': TruckMountedCranWorkingYellow,
        'heavy_equipment/48x48/WheeledExcavator_Black.png': WheeledExcavatorBlack,
        'heavy_equipment/48x48/WheeledExcavator_Blue.png': WheeledExcavatorBlue,
        'heavy_equipment/48x48/WheeledExcavator_Green.png': WheeledExcavatorGreen,
        'heavy_equipment/48x48/WheeledExcavator_Grey.png': WheeledExcavatorGrey,
        'heavy_equipment/48x48/WheeledExcavator_Red.png': WheeledExcavatorRed,
        'heavy_equipment/48x48/WheeledExcavator_Yellow.png': WheeledExcavatorYellow,
        'heavy_equipment/48x48/BackhoeLoader_Black.png': BackhoeLoaderBlack,
        'heavy_equipment/48x48/BackhoeLoader_Blue.png': BackhoeLoaderBlue,
        'heavy_equipment/48x48/BackhoeLoader_Green.png': BackhoeLoaderGreen,
        'heavy_equipment/48x48/BackhoeLoader_Grey.png': BackhoeLoaderGrey,
        'heavy_equipment/48x48/BackhoeLoader_Red.png': BackhoeLoaderRed,
        'heavy_equipment/48x48/BackhoeLoader_Yellow.png': BackhoeLoaderYellow,
        'heavy_equipment/48x48/FarmTractor_Black.png': FarmTractorBlack,
        'heavy_equipment/48x48/FarmTractor_Blue.png': FarmTractorBlue,
        'heavy_equipment/48x48/FarmTractor_Green.png': FarmTractorGreen,
        'heavy_equipment/48x48/FarmTractor_Grey.png': FarmTractorGrey,
        'heavy_equipment/48x48/FarmTractor_Red.png': FarmTractorRed,
        'heavy_equipment/48x48/FarmTractor_Yellow.png': FarmTractorYellow,
        'heavy_equipment/48x48/Loader_Black.png': LoaderBlack,
        'heavy_equipment/48x48/Loader_Blue.png': LoaderBlueHeavy,
        'heavy_equipment/48x48/Loader_Green.png': LoaderGreen,
        'heavy_equipment/48x48/Loader_Grey.png': LoaderGrey,
        'heavy_equipment/48x48/Loader_Red.png': LoaderRedHeavy,
        'heavy_equipment/48x48/RoadSweeper_Black.png': RoadSweeperBlack,
        'heavy_equipment/48x48/RoadSweeper_Blue.png': RoadSweeperBlue,
        'heavy_equipment/48x48/RoadSweeper_Green.png': RoadSweeperGreen,
        'heavy_equipment/48x48/RoadSweeper_Grey.png': RoadSweeperGrey,
        'heavy_equipment/48x48/RoadSweeper_Red.png': RoadSweeperRed,
        'heavy_equipment/48x48/RoadSweeper_Yellow.png': RoadSweeperYellow,
        'heavy_equipment/48x48/SkidSteerLoader_Black.png': SkidSteerLoaderBlack,

        heading: Heading,
        notAvatar: NotAvatar,
        logo: Logo,
        logoWithOutBg: LogoWithOutBg,
        logolight: Logolight,
        speedPoint: SpeedPoint,
        speedPointLoaded: SpeedPointLoaded,
        speedPointUnloaded: SpeedPointUnloaded,
        truckSpeed: TruckSpeed,
        truckLongitudinal: TruckLongitudinal,
        truckLateral: TruckLateral,
        truckVertical: TruckVertical,
        tireByHub: TireByHub,
        ca: ca,
        es: es,
        gb: gb,
        sa: sa,
        ua: ua,
        us: us,
        pt: pt,
        kz: kz
    };

    return images[image];
};
