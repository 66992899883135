import styled from 'styled-components';

export const SlopeInclinationLegendContainer = styled.div``;

export const SloapLegend = styled.div`
    width: 100px;
    height: 10px;
    background: linear-gradient(
        to right,
        rgb(0, 255, 0) 0%,
        rgb(0, 255, 0) 30%,
        rgb(255, 134, 0) 30%,
        rgb(255, 134, 0) 63%,
        rgb(255, 0, 0) 63%
    );
`;
