import React, { useEffect } from 'react';
import AreasTable from '../../components/Tracking/AreasTable/AreasTable';
import { Grid } from '@mui/material';
import AreasMap from '../../components/Tracking/AreasMap/AreasMap';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const Areas: React.FC = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tracking-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={5}>
                <AreasTable data-testid={'Areas-AreasTable'} />
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
                <AreasMap data-testid={'Areas-AreasMap'} />
            </Grid>
        </Grid>
    );
};

export default Wrapper(Areas);
