import PortalApiClient from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { HubAdministrationResponse } from 'models/HubAdministration.type';
import { DeviceModel } from 'models/Device.type';
export default class DeviceUpdateJob extends PortalApiClient<DeviceModel> {
    route = 'device-update-job';

    getJobTable = async (props, additionalProps): Promise<HubAdministrationResponse> => {
        return await this.getTable({
            criteria: getParams(props),
            extendUrl: `list/${additionalProps.deviceUpdateBatchId}`
        });
    };

    getJobTableSimple = async (props): Promise<DeviceModel[]> => {
        return await this.get({ extendUrl: `list/${props.additionalProps.deviceUpdateBatchId}` });
    };

    resetJob = async (id): Promise<DeviceModel> => {
        return this.post({ extendUrl: `reset/${id}` });
    };

    cancelJob = async (id): Promise<DeviceModel> => {
        return this.post({ extendUrl: `cancel/${id}` });
    };
}
