import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import UiLegend from '../UiLegend';
import { getImgSource } from 'helpers/image';
import { UiIconPickerViewProps } from './UiIconPicker.type';
import { IconButton, IconCover, IconPickerButton, UiIconPickerContainer } from './UiIconPicker.style';
// import module

export const UiIconPickerContent: React.FC<UiIconPickerViewProps> = (props): JSX.Element => {
    const contentByStep = (): JSX.Element[] | undefined => {
        const iconCopy: string = props.selectedIcon;
        switch (props.step) {
            case 1:
                return props.data?.map((group, index) => (
                    <IconButton
                        data-testid={`UiIconPicker-IconButton-${index}`}
                        key={index}
                        onClick={() => {
                            props.updateStep(props.step + 1, group.group, group.colors);
                        }}
                    >
                        <img alt='group vehicle icon' src={getImgSource(group.group)} />
                    </IconButton>
                ));
            case 2:
                return props.colors?.map((color, index) => {
                    try {
                        return (
                            <IconButton
                                data-testid={`UiIconPicker-${color}${props.step}-IconButton`}
                                key={index}
                                onClick={() => {
                                    props.confirmVehicle(color);
                                }}
                            >
                                <img
                                    alt='group vehicle icon'
                                    src={getImgSource(iconCopy.replace(props.defaultColor, color))}
                                />
                            </IconButton>
                        );
                    } catch (e) {
                        return <React.Fragment key={index} />;
                    }
                });
            default:
                return;
        }
    };

    const content: JSX.Element = <IconCover>{contentByStep()}</IconCover>;

    return (
        <UiIconPickerContainer data-testid='UiIconPickerContainer' style={{ ...props.style }}>
            <UiLegend
                title={props.title}
                width={375}
                root={
                    <IconPickerButton skin='rgb(253,253,253)' testid={props.testid}>
                        <span className='selected-icon-cover'>
                            <img alt='selected vehicle icon' src={getImgSource(props.defaultIcon)} />
                        </span>
                        <span className='picker-expand-more'>
                            <ExpandMoreIcon />
                        </span>
                    </IconPickerButton>
                }
                content={content}
                onClose={props.reset}
                close={props.closePicker}
            />
        </UiIconPickerContainer>
    );
};
