import React from 'react';
import * as type from './ActiveDot.type';

const ActiveDotContent: React.FC<type.ActiveDotProps> = (props): JSX.Element => {
    const { cx, cy, payload, fill } = props;
    if (!props?.showDot || !props.value) {
        return <svg></svg>;
    }
    //const dotType: string = props.index % 2 === 0 ? '0,0 10,0 10,10 0,10' : '5,0 10,10 0,10';
    const fillColor: string = payload?.showAlienData ? '#ffff00' : fill ?? '';
    const strokeColor: string = payload?.showAlienData ? '#ff0000' : fill ?? '';
    return (
        <svg
            x={(cx ?? 0) - 5}
            y={(cy ?? 0) - 5}
            width={10}
            height={10}
            fill={fillColor}
            stroke={strokeColor}
            viewBox='0 0 10 10'
        >
            <polygon points={'0,0 10,0 10,10 0,10'} />
        </svg>
    );
};

export default ActiveDotContent;
