import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from '../Theme';

export const ChipBarContent = styled.div`
    svg {
        margin-right: 5px;
    }
`;

export const TitleFilter = styled(Typography)`
    font-weight: 600;
    margin-left: 5px !important;
    text-transform: capitalize;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize}; !important`;
    }}
`;

export const ContainerFilter = styled.div`
    width: 520px;
    overflow: auto;
    display: inline-flex;

    @media (min-width: 1441px) {
        width: 765px;
    }

    @media (min-width: 1280px) and (max-width: 1440px) {
        ${applyStyleByMode({
            styleOld: 'width: 475px;',
            styleJade: 'width: 530px;'
        })}
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 418px;
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        width: 85vw;
    }
`;
