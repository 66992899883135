import { RecoilState, atom } from 'recoil';

let refreshTyreLifespanAtoms = {};

enum AtomsKey {
    TYRE_LIFE_SPAN_DATA = 'TyreLifespanData'
}

export const TyreLifespanDataDefaultValue = {
    tyreId: NaN,
    tyreSerialNumber: '',
    customPosition: NaN,
    vehicleId: NaN
};

export type TyreLifespanData = {
    tyreId: number;
    tyreSerialNumber: string;
    customPosition: number;
    vehicleId: number;
};

const AtomInstance = <T>(defaultValue: T, defaultKey: string, key?: string): RecoilState<T> => {
    if (key && !refreshTyreLifespanAtoms[key]) {
        refreshTyreLifespanAtoms[key] = atom<T>({
            key: key,
            default: defaultValue
        });
    } else if (!key && !refreshTyreLifespanAtoms[defaultKey]) {
        refreshTyreLifespanAtoms[defaultKey] = atom<T>({
            key: defaultKey,
            default: defaultValue
        });
    }

    return refreshTyreLifespanAtoms[key ?? defaultKey];
};

export const TyreLifeSpanDataAtom = (tyreLifeSpanDataKey?) =>
    AtomInstance<TyreLifespanData>(TyreLifespanDataDefaultValue, AtomsKey.TYRE_LIFE_SPAN_DATA, tyreLifeSpanDataKey);
