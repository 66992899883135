import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    AreaColorLable,
    AreaEditorFormViewContent,
    AreaTypeColorIndicator,
    AreaTypeContent,
    CustomColorPicker,
    CustomTextfield,
    GreenSwitch,
    SmallTitle,
    WarningMsgContainer
} from './AreaEditorForm.style';
import { AreaEditorFormViewProps } from './AreaEditorForm.type';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Fade, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Link } from '@mui/material';
import AreaSelector from '../../Tracking/AreaSelector';
import Button from '../../Ui/Components/UiButton';
import { AreaEditorFormAtom } from './AreaEditorForm.atom';
import { AreaFeaturesResponse } from '../../../models/Area.type';
import { SketchPicker } from 'react-color';
import useConverter from '../../CustomHooks/Converter/Converter';
import { SpeedUnits } from '../../CustomHooks/Converter/Converter.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import AreaTypeApi from '../../../api/AreaType';
import HelperText from 'components/Mixs/HelperText/HelperText';
import Legend from 'components/Ui/Components/UiLegend/UiLegend';
import { LegendContentArea } from '../AreaEditor/AreaEditor.view';

const AreaType = new AreaTypeApi();

const prepareDropdownData = (data: AreaFeaturesResponse[]) => {
    return data
        .map((item) => ({
            title: item.name,
            id: item.id
        }))
        .sort((a, b) => a.title.localeCompare(b.title));
};
const MultiEditMode = false; //For we want the editor to edit/add only 1 area at a time.

export const AreaEditorFormContent: React.FC<AreaEditorFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const formState = useRecoilValue(AreaEditorFormAtom);
    const { displayUserUnits } = useConverter();

    return (
        <AreaEditorFormViewContent data-testid='AreaEditorFormContent'>
            <Grid container spacing={0}>
                {MultiEditMode && (
                    <Grid item xs={12} md={12} lg={8}>
                        <AreaSelector
                            data-testid='MultiEditMode-AreaSelector'
                            disabled={props.isSaving}
                            onChange={props.onChangeSelectorOption}
                            options={props.data ? prepareDropdownData([props.data]) : []}
                            isLoading={props.isLoading}
                        />
                    </Grid>
                )}

                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl component='fieldset'>
                            <CustomTextfield
                                data-testid='MultiEditMode-CustomTextfield-name'
                                label={translate('t.name')}
                                variant='outlined'
                                size='small'
                                disabled={props.isSaving}
                                inputProps={{ maxLength: 45 }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={props.onChangeAreaName}
                                value={formState.selectedArea?.name || ''}
                                error={!!formState.errors.name}
                                helperText={
                                    <HelperText
                                        defaultText={translate('t.required')}
                                        error={!!formState.errors.name?.message}
                                    />
                                }
                            />
                            <LazyloadingAutocomplete
                                data-testid='MultiEditMode-LazyloadingAutocomplete-areaType'
                                id='areaType'
                                optionKey='translationKey'
                                optionValue='id'
                                queryId='areaType'
                                size='small'
                                error={!!formState.errors.areaType}
                                helperText={
                                    <HelperText
                                        defaultText={translate('t.required')}
                                        error={!!formState.errors.areaType?.message}
                                    />
                                }
                                label={translate('t.area_type')}
                                query={AreaType.getTable}
                                startAdornment={(value) => (
                                    <AreaTypeColorIndicator
                                        color={value?.color || '#fff'}
                                        data-testid='MultiEditMode-AreaTypeColorIndicator'
                                    />
                                )}
                                getOptionLabel={(option) => {
                                    return {
                                        text: translate(`t.${option.translationKey}`),
                                        html: (
                                            <AreaTypeContent>
                                                <AreaTypeColorIndicator
                                                    color={option.color}
                                                    data-testid='MultiEditMode-AreaTypeContent-AreaTypeColorIndicator'
                                                />
                                                <Box>{translate(`t.${option.translationKey}`)}</Box>
                                            </AreaTypeContent>
                                        )
                                    };
                                }}
                                preselectedValue={{
                                    value: formState.selectedArea?.areaType?.id || 0,
                                    label: formState.selectedArea?.areaType
                                        ? translate(`t.${formState.selectedArea?.areaType?.translationKey}`)
                                        : '',
                                    origItem: formState.selectedArea?.areaType
                                }}
                                fullWidth
                                onValueChange={(value, label, origItem) => {
                                    if (origItem && Object.keys(origItem).length) {
                                        props.onChangeAreaType(origItem);
                                    }
                                }}
                            />
                            <CustomColorPicker
                                color={formState.selectedArea?.color || '#3a3633'}
                                data-testid={'MultiEditMode-CustomColorPicker'}
                            >
                                <AreaColorLable>{translate('t.area_color')}</AreaColorLable>
                                <SketchPicker
                                    data-testid='MultiEditMode-SketchPicker'
                                    color={formState.selectedArea?.color}
                                    onChange={(color) => {
                                        props.onChangeAreaColor(
                                            `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                                        );
                                    }}
                                    presetColors={[
                                        'rgba(255,255,0,0.1)',
                                        'rgba(255,0,0,0.1)',
                                        'rgba(0,255,255,0.1)',
                                        'rgba(40,199,111,0.1)',
                                        'rgba(1,1,1,0.1)',
                                        'rgba(0,160,255,0.1)',
                                        'rgba(255,167,0,0.1)',
                                        'rgba(108,100,100,0.1)',
                                        'rgba(167,0,266,0.1)',
                                        'rgba(255,167,255,0.1)',
                                        '#D0021B',
                                        '#F5A623',
                                        '#F8E71C',
                                        '#8B572A',
                                        '#7ED321',
                                        '#417505',
                                        '#BD10E0',
                                        '#9013FE',
                                        '#4A90E2',
                                        '#50E3C2',
                                        '#B8E986',
                                        '#000000',
                                        '#4A4A4A',
                                        '#9B9B9B',
                                        '#FFFFFF'
                                    ]}
                                />
                            </CustomColorPicker>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <p>
                            <Legend width={550} title={translate('t.legend')} content={<LegendContentArea />} />
                            <strong>{translate('t.zone_type')}:</strong>
                        </p>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        data-testid='MultiEditMode-GreenSwitch-counterZone'
                                        checked={formState.selectedArea?.generateAreaEvents || false}
                                        disabled={props.isSaving}
                                        onChange={props.onChangeCounterZone}
                                        name='counter_zone'
                                    />
                                }
                                label={translate('t.counter_zone')}
                            />

                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        data-testid='MultiEditMode-GreenSwitch-maintenanceZone'
                                        checked={formState.selectedArea?.maintenanceArea || false}
                                        disabled={props.isSaving}
                                        onChange={props.onChangeMaintananceZone}
                                        name='maintenance_zone'
                                    />
                                }
                                label={translate('t.maintenance_zone')}
                            />

                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        data-testid='MultiEditMode-GreenSwitch-gilad'
                                        checked={formState.selectedArea?.generateSpeedAlerts || false}
                                        disabled={props.isSaving}
                                        onChange={props.onChangeSpeedZone}
                                        name='gilad'
                                    />
                                }
                                label={translate('t.speed_zone')}
                            />

                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        data-testid='MultiEditMode-GreenSwitch-temperatureSpeedAlerts'
                                        checked={formState.selectedArea?.temperatureBasedSpeedAlerts || false}
                                        disabled={props.isSaving || !formState.selectedArea?.generateSpeedAlerts}
                                        onChange={props.onChangeTempSpeedZone}
                                        name='gilad'
                                    />
                                }
                                label={translate('t.temperature_speed_alerts')}
                            />
                        </FormGroup>

                        <Fade
                            in={formState.selectedArea?.generateSpeedAlerts}
                            hidden={!formState.selectedArea?.generateSpeedAlerts}
                        >
                            <FormGroup>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SmallTitle>
                                        <strong>{translate('t.simple_speed_alerts')}</strong>
                                    </SmallTitle>
                                </Grid>

                                <CustomTextfield
                                    data-testid='MultiEditMode-CustomTextfield-minSpeed'
                                    id='area-min-speed'
                                    name='minSpeed'
                                    type='number'
                                    label={translate('t.min_speed')}
                                    value={formState.selectedArea?.minSpeed || 0}
                                    variant='outlined'
                                    disabled={!formState.selectedArea?.generateSpeedAlerts || props.isSaving || false}
                                    size='small'
                                    fullWidth
                                    onChange={props.onChangeMinSpeed}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        step: 0.1,
                                        min: 0,
                                        max: displayUserUnits.speed === SpeedUnits.KMH ? 161 : 100
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                        )
                                    }}
                                />
                                <CustomTextfield
                                    data-testid='MultiEditMode-CustomTextfield-maxSpeed'
                                    id='area-max-speed'
                                    name='maxSpeed'
                                    type='number'
                                    label={translate('t.max_speed')}
                                    value={formState.selectedArea?.maxSpeed || 0}
                                    variant='outlined'
                                    disabled={!formState.selectedArea?.generateSpeedAlerts || props.isSaving || false}
                                    size='small'
                                    fullWidth
                                    onChange={props.onChangeMaxSpeed}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        step: 0.1,
                                        min: 0
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                        )
                                    }}
                                    error={!!formState.errors.maxSpeed}
                                    helperText={
                                        formState.errors.maxSpeed?.message
                                            ? translate(formState.errors.maxSpeed?.message)
                                            : ''
                                    }
                                />
                            </FormGroup>
                        </Fade>

                        <Fade
                            data-testid='MultiEditMode-Fade'
                            in={
                                formState.selectedArea?.temperatureBasedSpeedAlerts &&
                                formState.selectedArea?.generateSpeedAlerts
                            }
                            hidden={
                                !formState.selectedArea?.temperatureBasedSpeedAlerts &&
                                formState.selectedArea?.generateSpeedAlerts
                                    ? true
                                    : false
                            }
                        >
                            <FormGroup>
                                <Grid item xs={12} md={12} lg={12}>
                                    <SmallTitle>
                                        <strong>{translate('t.temperature_speed_alerts')}</strong>
                                    </SmallTitle>
                                </Grid>
                                <CustomTextfield
                                    data-testid='MultiEditMode-CustomTextfield-max-speed-temperature-level-1'
                                    id='max-speed-temperature-level-1'
                                    name='max-speed-temperature-level-1'
                                    type='number'
                                    label={translate('t.speed_level') + ' 1'}
                                    value={formState.selectedArea?.maxSpeedTemperatureL1 || 0}
                                    variant='outlined'
                                    disabled={
                                        !formState.selectedArea?.temperatureBasedSpeedAlerts || props.isSaving || false
                                    }
                                    size='small'
                                    fullWidth
                                    onChange={props.onChangeTempSpeedLvl1}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        step: 0.1,
                                        min: 0,
                                        max: displayUserUnits.speed === SpeedUnits.KMH ? 161 : 100
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                        )
                                    }}
                                />
                                <CustomTextfield
                                    data-testid='MultiEditMode-CustomTextfield-max-speed-temperature-level-2'
                                    id='max-speed-temperature-level-2'
                                    name='max-speed-temperature-level-2'
                                    type='number'
                                    label={translate('t.speed_level') + ' 2'}
                                    value={formState.selectedArea?.maxSpeedTemperatureL2 || 0}
                                    variant='outlined'
                                    disabled={
                                        !formState.selectedArea?.temperatureBasedSpeedAlerts || props.isSaving || false
                                    }
                                    size='small'
                                    fullWidth
                                    onChange={props.onChangeTempSpeedLvl2}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        step: 0.1,
                                        min: 0,
                                        max: displayUserUnits.speed === SpeedUnits.KMH ? 161 : 100
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                        )
                                    }}
                                />
                                <CustomTextfield
                                    data-testid='MultiEditMode-CustomTextfield-max-speed-temperature-level-3'
                                    id='max-speed-temperature-level-3'
                                    name='max-speed-temperature-level-3'
                                    type='number'
                                    label={translate('t.speed_level') + ' 3'}
                                    value={formState.selectedArea?.maxSpeedTemperatureL3 || 0}
                                    variant='outlined'
                                    disabled={
                                        !formState.selectedArea?.temperatureBasedSpeedAlerts || props.isSaving || false
                                    }
                                    size='small'
                                    fullWidth
                                    onChange={props.onChangeTempSpeedLvl3}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        step: 0.1,
                                        min: 0,
                                        max: displayUserUnits.speed === SpeedUnits.KMH ? 161 : 100
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                        )
                                    }}
                                />
                            </FormGroup>
                        </Fade>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <WarningMsgContainer visible={props.showWarningColorMessage}>
                            <Alert variant='standard' severity='warning' sx={{ mb: 1 }}>
                                {translate('p.warning_different_color')}{' '}
                                <Link
                                    data-testid='MultiEditMode-Link-here'
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.resetColorByAreaType;
                                    }}
                                >
                                    {translate('t.here')}
                                </Link>
                            </Alert>
                        </WarningMsgContainer>
                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <div hidden={!formState.hasNewChanges}></div>
                            {formState.hasNewChanges ? (
                                <Button
                                    data-testid='MultiEditMode-Button-save'
                                    color='primary'
                                    variant='contained'
                                    disabled={
                                        props.isSaving ||
                                        !!formState.errors.name ||
                                        !!formState.errors.areaType ||
                                        !!formState.errors.area ||
                                        !!formState.errors.maxSpeed ||
                                        formState.selectedArea?.name?.length === 0 ||
                                        !formState.selectedArea?.areaType
                                    }
                                    onClick={props.onClickSave}
                                    size='small'
                                >
                                    {translate('t.save')}
                                </Button>
                            ) : (
                                <></>
                            )}

                            <Button
                                data-testid='MultiEditMode-Button-reset-goBack'
                                disabled={props.isSaving}
                                onClick={props.onClickCancel}
                                size='small'
                                variant='contained'
                            >
                                {formState.hasNewChanges ? translate('t.reset') : translate('t.go_back')}
                            </Button>
                            <Fade
                                timeout={{ exit: 1500 }}
                                in={!formState.hasNewChanges && MultiEditMode}
                                data-testid='MultiEditMode-Fade-new'
                            >
                                <div hidden={formState.hasNewChanges}>
                                    <Button
                                        data-testid='MultiEditMode-Button-new'
                                        disabled={props.isSaving}
                                        onClick={props.onClickCreate}
                                        variant='contained'
                                        size='small'
                                    >
                                        {translate('t.new')}
                                    </Button>
                                </div>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AreaEditorFormViewContent>
    );
};
