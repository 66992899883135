import React, { useCallback, useEffect } from 'react';
import { VehicleDetailsTyresContent } from './VehicleDetailsTyres.view';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VehicleDetailsTyresAtom } from './VehicleDetailsTyres.atom';
import { CustomerSettings } from 'states/global/CustomerSettings';

const VehicleDetailsTyres: React.FC = (): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);
    const [vehicleDetailsTyresAtom, setVehicleDetailsTyresAtom] = useRecoilState(VehicleDetailsTyresAtom);

    useEffect(() => {
        setVehicleDetailsTyresAtom({
            ...vehicleDetailsTyresAtom,
            targetPressure: localStorage.getItem('targetPressure') === 'true',
            secondSensorAlerts: customerSettings.secondary_sensors_alerts
        });
    }, [customerSettings.secondary_sensors_alerts]);

    const handleCheckboxChange = useCallback(() => {
        setVehicleDetailsTyresAtom({
            ...vehicleDetailsTyresAtom,
            targetPressure: !vehicleDetailsTyresAtom.targetPressure
        });
        localStorage.setItem('targetPressure', JSON.stringify(!vehicleDetailsTyresAtom.targetPressure));
    }, [setVehicleDetailsTyresAtom, vehicleDetailsTyresAtom]);

    return (
        <VehicleDetailsTyresContent
            data-testid={'VehicleDetailsTyres-testid'}
            handleCheckboxChange={handleCheckboxChange}
        />
    );
};

export default VehicleDetailsTyres;
