import { Column } from 'models/Table.type';
import { ReportModel } from '../../../models/Report.type';

export type ExportedReportsProps = {};

export type ExportedReportsViewProps = {
    columns: Column<ReportModel>[];
    report?: ReportModel;
    reportStatus: ReportStatus;
};

export enum ExportedReportActions {
    SET_REPORT_DETAIL = 'SET_REPORT_DETAIL',
    SET_REPORT_ID_TO_CANCEL = 'SET_REPORT_ID_TO_CANCEL'
}

export type ExportedReportState = {
    report?: ReportModel;
    reportIdToCancel?: number;
};

export type ReportDetailActionType = {
    type: ExportedReportActions.SET_REPORT_DETAIL;
    payload: ReportModel;
};

export type ReportIdToCancelActionType = {
    type: ExportedReportActions.SET_REPORT_ID_TO_CANCEL;
    payload?: number;
};

type ContentStatus = {
    icon?: string[];
    color?: string;
    label?: string;
    cellColor?: string;
};

export type ReportStatus = {
    done: ContentStatus;
    running: ContentStatus;
    canceled: ContentStatus;
    error: ContentStatus;
    new: ContentStatus;
};
