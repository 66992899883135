import { TextField } from '@mui/material';
import React from 'react';
import { UiDropDownViewProps } from './UiDropDown.type';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelperText from 'components/Mixs/HelperText/HelperText';

export const UiDropDownContent: React.FC<UiDropDownViewProps> = ({
    value,
    value2,
    error,
    options,
    defaultValue,
    getValue,
    disabled = false,
    label,
    inputName,
    allowClearValue,
    testid
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => (option ? String(option.name) || '' : '')}
            onChange={(e, option) => {
                getValue(option ? option.id : null);
            }}
            disabled={disabled}
            size='small'
            clearIcon={allowClearValue}
            value={value2}
            defaultValue={!!options.length && options.find((option) => option.id === defaultValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    name={inputName}
                    value={value2 ? value2.name : value}
                    InputLabelProps={{
                        shrink: true
                    }}
                    helperText={<HelperText error={!!error} text={translate('p.this_field_is_required')} />}
                    error={!!error}
                    data-testid={testid || 'UiDropDown-Input'}
                />
            )}
        />
    );
};
