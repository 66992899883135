import PortalApi from 'helpers/api/PortalApiClient';
import { CounterActionModel } from '../models/CounterAction.type';

export default class CounterAction extends PortalApi<CounterActionModel> {
    route = 'c-counter-action';

    getOptions = async (): Promise<CounterActionModel> => {
        return await this.get({
            version: 'v1/webtrack',
            apiProject: ''
        });
    };
}
