import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const StatisticsContainer = styled.div`
    ${applyStyleByMode({
        styleJade: `
            .MuiTabs-root {
                align-items: center !important;
            }
            `
    })}

    .MuiTab-labelIcon {
        min-height: 46px !important;
        height: 46px !important;
    }
`;
