import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';

export const SecondStep = styled.p``;

export const GraphContainer = styled.div`
    color: #3a3633;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const AlertContainer = styled.div`
    margin: 0 auto;
    max-width: 350px;
`;

export const NoDataInPeriod = styled.p<{ hideReportOptions?: boolean }>`
    margin: 0;
    line-height: ${(props) => (props.hideReportOptions ? '940px' : '330px')};
    position: absolute;
`;

export const WarningNoColdPressure = styled(Alert)`
    padding: 3px 16px;
    margin: 5px 0;
    width: 100%;
    justify-content: center;
`;

export const ReportLeakFastButton = styled(Button)`
    &.MuiButton-root {
        background: #de3e0d;
        box-shadow: none;
        color: #fff;
        &:hover {
            background: #c7360a;
        }
        margin: 0 2px;
    }
`;
