import React, { useEffect, useState } from 'react';
import { CustomDateTimePicker, DatePickerContentEl } from './DateTimePicker.style';
import { DateTimePickerProps } from './DateTimePicker.type';
import { useRecoilValue } from 'recoil';
import { Is12HrsFormat } from 'helpers/Converters';
import useConverter from '../../../../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import { SIZE_INPUT } from '../../../../variables';
import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from '../../../../../Mixs/HelperText/HelperText';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MaxYear, MinYear } from 'variables';

const DateTimePickerContent: React.FC<DateTimePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = useState<DateTime | null>(
        (typeof props.value === 'string' ? DateTime.fromISO(props.value) : props.value) || DateTime.local()
    );
    const [error, setError] = useState<string | null>(null);

    const validateDate = (date: DateTime | null): string | null => {
        if (!date.isValid || date.year < MinYear || date.year > MaxYear) {
            return translate('t.the_date_format_is_invalid');
        }
        return null;
    };

    const handleDateChange = (newDate: DateTime | null): void => {
        if (newDate) {
            const validationError = validateDate(newDate);
            if (validationError) {
                setError(validationError);
            } else {
                setError(null);
                props.onDateChange(newDate);
            }
        }
    };

    useEffect(() => {
        if (props.value) {
            setError(null);
            setDateValue(DateTime.fromISO(props.value));
        }
    }, [props.value]);

    useEffect(() => {
        return () => {
            setDateValue(null);
        };
    }, []);

    return (
        <DatePickerContentEl data-testid='DateTimePickerForUiDateRangePicker2'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CustomDateTimePicker
                    format={dateTimeFormat(props.onlyDate ? 'date' : props.inputFormat || 'dateTime', false)}
                    ampm={is12HrsFormat}
                    label={props.label || translate('t.date')}
                    value={dateValue}
                    open={false}
                    onOpen={props.onOpen}
                    onChange={handleDateChange}
                    data-testid={`DateTimePickerForUiDateRangePicker2${
                        props.testId ||
                        firstCapital(snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_')))
                    }`}
                    slots={{ textField: TextField }}
                    slotProps={{
                        textField: (params) => {
                            return {
                                'data-testid': `DateTimePickerForUiDateRangePicker2${
                                    props.testId ||
                                    firstCapital(
                                        snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_'))
                                    )
                                }-textField`,
                                margin: 'normal',
                                id: `DateTimePickerForUiDateRangePicker2${Math.ceil(
                                    Math.random() * 1000000000000000000
                                )}`,
                                size: SIZE_INPUT,
                                error: !!error,
                                variant: 'outlined',
                                sx: { margin: '0 0 25px 0 !important' },
                                helperText: (
                                    <HelperText
                                        data-testid={`error$${
                                            props.testId ||
                                            firstCapital(
                                                snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_'))
                                            )
                                        }`}
                                        error={!!error}
                                        text={translate('t.the_date_format_is_invalid')}
                                    />
                                ),
                                InputLabelProps: { shrink: true },
                                inputProps: {
                                    ...params.inputProps,
                                    placeholder: dateTimeFormat(
                                        props.onlyDate ? 'date' : props.inputFormat || 'dateTime',
                                        false
                                    ).toLocaleUpperCase(),
                                    style: { width: '100%' }
                                },
                                onFocus: () => props.onFocus && props.onFocus(),
                                onBlur: () => props.onBlur && props.onBlur()
                            };
                        }
                    }}
                    $noMargin={props.noMargin}
                />
            </LocalizationProvider>
        </DatePickerContentEl>
    );
};

export default Wrapper(DateTimePickerContent);
