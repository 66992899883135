import React from 'react';
import { HubUpdaterBatchesViewContent } from './HubUpdaterBatches.style';
import { HubUpdaterBatchesViewProps } from './HubUpdaterBatches.type';
import BatchesTable from 'components/Hub/BatchesTable/BatchesTable';

export const HubUpdaterBatchesContent: React.FC<HubUpdaterBatchesViewProps> = (): JSX.Element => {
    return (
        <HubUpdaterBatchesViewContent data-testid='HubUpdaterBatchesContent'>
            <BatchesTable />
        </HubUpdaterBatchesViewContent>
    );
};
