import React from 'react';
import { AlertNotificationManagmentTableContainer } from './AlertNotificationManagmentTable.style';
import { AlertNotificationManagmentTableViewProps } from './AlertNotificationManagmentTable.type';
import { useRecoilValue } from 'recoil';
import AlertNotification from 'api/AlertNotification';
import UiTable from 'components/Ui/Components/UiTable2/UiTable';
import { AlertNotificationManagmentState } from 'states/component/AlertNotificationManagment';
import { VARIANT_ALERT } from 'components/Ui/variables';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const alertNotificationApi = new AlertNotification();

export const AlertNotificationManagmentTableContent: React.FC<AlertNotificationManagmentTableViewProps> = ({
    columns
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const alertNotificationManagmentState = useRecoilValue(AlertNotificationManagmentState);

    return (
        <AlertNotificationManagmentTableContainer data-testid='AlertNotificationManagmentTableContent'>
            <Alert variant={VARIANT_ALERT} severity='info'>
                {translate('t.time_zone_msg')}
            </Alert>
            <UiTable
                data-testid='AlertNotificationManagmentTableContent-UiTable'
                columns={columns}
                fetchFn={alertNotificationApi.getAlertNotificationTable}
                queryKey='alertNotificationManagment'
                additionalProps={{
                    dateFrom: alertNotificationManagmentState?.dateFrom?.toFormat('yyyy-MM-dd'),
                    dateTo: alertNotificationManagmentState?.dateTo?.toFormat('yyyy-MM-dd')
                }}
                hiddenFilterAction
            />
        </AlertNotificationManagmentTableContainer>
    );
};
