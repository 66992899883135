import { atom } from 'recoil';

export const TemperatureSliderValue = atom<[number, number, number]>({
    key: 'TemperatureSliderValue',
    default: [50, 70, 90]
});

export const DefaultTemperatureSliderValue = atom<[number, number, number]>({
    key: 'DefaultTemperatureSliderValue',
    default: [50, 70, 90]
});
