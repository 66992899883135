import React from 'react';
import { ButtonDone, CommentContent, CommentUpdateContainer, GridComment, ShowComment } from './CommentUpdate.style';
import { CommentUpdateViewProps, InitialValues } from './CommentUpdate.type';
import { useTranslation } from 'react-i18next';
import UiLegend from '../../Ui/Components/UiLegend';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, TextField } from '@mui/material';
import Done from '@mui/icons-material/Done';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { VARIANT_BUTTON } from '../../Ui/variables';

export const CommentUpdateContent: React.FC<CommentUpdateViewProps> = ({
    initialValues,
    showComment,
    loading,
    title,
    testid,
    handleSubmit
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({});

    return (
        <CommentUpdateContainer data-testid={`${testid || 'CommentSensorsUpdate'}-View`}>
            <UiLegend
                title={translate(`t.${title}`)}
                width={350}
                content={
                    <CommentContent>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values: InitialValues) => {
                                handleSubmit(values);
                            }}
                            enableReinitialize
                            validationSchema={schemaValidateForm}
                        >
                            {(propsFormik: FormikProps<InitialValues>) => {
                                const { touched, errors, handleChange, values } = propsFormik;

                                return (
                                    <Form id='sensorpage-action-form'>
                                        <Grid container>
                                            <GridComment item xl={10} lg={10} md={10} sm={10} xs={10}>
                                                <TextField
                                                    data-testid={`${testid || 'CommentSensorsUpdate'}-Field`}
                                                    id='comment/analysis'
                                                    label={translate(`t.${title}`)}
                                                    value={values.comment ? values.comment : null}
                                                    variant='outlined'
                                                    size='small'
                                                    name='comment'
                                                    error={!!errors.comment && !!touched.comment}
                                                    type='text'
                                                    fullWidth
                                                    onChange={handleChange}
                                                    inputProps={{ step: 0.1, min: 0 }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </GridComment>

                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                <ButtonDone
                                                    skin='success'
                                                    type='submit'
                                                    color='primary'
                                                    variant={VARIANT_BUTTON}
                                                    disabled={loading}
                                                    data-testid={`${testid || 'CommentSensorsUpdate'}-Button`}
                                                >
                                                    {loading ? (
                                                        <CircularProgress size='1.75em' />
                                                    ) : (
                                                        <Done fontSize='inherit' />
                                                    )}
                                                </ButtonDone>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CommentContent>
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                root={
                    <ShowComment data-testid={`${testid || 'CommentSensorsUpdate'}-Show`}>
                        {' '}
                        {showComment ?? '-'}{' '}
                    </ShowComment>
                }
            />
        </CommentUpdateContainer>
    );
};
