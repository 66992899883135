import React from 'react';
import { useSetRecoilState } from 'recoil';
import { InicialvaluesForm } from './UsernameRecovery.type';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import User from 'api/User';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, Link, TextField, Typography } from '@mui/material';
import Button from 'components/Ui/Components/UiButton';
import { LoginShowContent, showContentType } from 'states/global/Login';
import { DateTime } from 'luxon';
import { Success, Warning } from 'components/Popup/Popup';
import HelperText from 'components/Mixs/HelperText/HelperText';
import { FooterText } from 'variables';

const userApi = new User();

const UsernameRecovery: React.FC = (): JSX.Element => {
    const { t: translator } = useTranslation();
    const setLoginShowContent = useSetRecoilState(LoginShowContent);
    const Year = DateTime.now().year;

    const { mutate: recoveryPasswordMutate, isLoading } = useMutation(userApi.usernameRecovery, {
        onSuccess: (data) => {
            if (data) {
                return Success({
                    text: translator('p.username_recovery')
                });
            }

            Warning({
                text: translator('p.email_does_not_exist')
            });
        }
    });

    const valuesInitForm: InicialvaluesForm = {
        email: ''
    };

    const schemaValidateForm = Yup.object({
        email: Yup.string().trim().email().required(translator('p.this_field_is_required'))
    });

    return (
        <>
            <Typography variant='h4' align='center' gutterBottom>
                {translator('t.forgot_username')}
            </Typography>
            <Formik
                initialValues={valuesInitForm}
                onSubmit={(values: InicialvaluesForm) => {
                    recoveryPasswordMutate(values.email);
                }}
                validationSchema={schemaValidateForm}
            >
                {(propsF: FormikProps<InicialvaluesForm>) => {
                    const { values, touched, errors, handleBlur, handleChange } = propsF;
                    return (
                        <Form>
                            <Grid container spacing={1} justifyContent='space-around' direction='row'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        name='email'
                                        id='email'
                                        data-testid='Email-Field'
                                        label={translator('t.email')}
                                        value={values.email}
                                        type='text'
                                        helperText={
                                            <HelperText error={!!errors.email && !!touched.email} text={errors.email} />
                                        }
                                        error={errors.email && touched.email ? true : false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button
                                        variant='contained'
                                        testid='Send-RecoveryUsername-Button'
                                        type='submit'
                                        size='large'
                                        disabled={isLoading}
                                        loading={isLoading}
                                        sx={{ fontSize: '0.93rem' }}
                                    >
                                        {translator('t.send')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <Typography variant='subtitle1' align='center' gutterBottom>
                <Link
                    href='javascript:void(0)'
                    onClick={() => setLoginShowContent(showContentType.LOGIN)}
                    variant='h5'
                    underline='hover'
                    data-testid='Sign-In-Link'
                >
                    {translator('t.sign_in')}
                </Link>
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
                {FooterText} - {Year}
            </Typography>
        </>
    );
};

export default UsernameRecovery;
