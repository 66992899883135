import React, { useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { HeatmapProps } from './SlopeInclinationHeatmap.type';
import { useTranslation } from 'react-i18next';
import { MapAction } from 'states/global/Map';
import HeatmapTopbar from 'components/Maps/HeatmapTopBar';
import MapLegend from 'components/Ui/Components/UiMapComponent';
import Map from 'components/Map';
import Widget from 'components/Ui/Components/UiWidget';
import { MapActionsEnum } from 'components/Map/Map.type';
import { Wrapper } from 'helpers/wrapper';
import SlopeInclinationLegend from 'components/Maps/SlopeInclinationLegend';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';

const SlopeInclinationHeatmap: React.FC<HeatmapProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({
            article: 'map-slope-guide',
            section: 'user-guides'
        });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <div data-testid={'SlopeInclinationHeatmap-testid'}>
            <HeatmapTopbar
                showDatetimePicker
                showVehiclePicker
                //slopeHeatmap // TEMPORARY MODIFIED - MIGHT BE REVERTED
                numberOfVehicles={1}
                onApply={(dateRange, vehicles, odometerSum, satellites) => {
                    if (dateRange) {
                        setMapAction({
                            action: MapActionsEnum.DRAW_SLOPE_JOURNEY,
                            params: {
                                vehicleId: vehicles?.length ? `${vehicles[0]}` : `0`,
                                measuredTo: dateRange.dateToUTC || '',
                                measuredFrom: dateRange.dateFromUTC || '',
                                odometerSum: odometerSum || 0,
                                satellites: satellites || 0
                            }
                        });
                    }
                }}
            />
            <Widget
                title={translate('t.map')}
                collapseButton={false}
                fullScreenButton={true}
                $contentHeight={70}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
            >
                <MapLegend top={'76%'} right={'2.3em'}>
                    <SlopeInclinationLegend />
                </MapLegend>
                <Map />
            </Widget>
        </div>
    );
};

export default Wrapper(SlopeInclinationHeatmap);
