import { DeviceDiagnoseModel } from '../../../models/DeviceDiagnose.type';
import { QueryObserverResult } from '@tanstack/react-query';

export type DeviceDiagnoseTableType = {
    data?: DeviceDiagnoseModel;
};

export type TableCellValueProps = {
    key: DiagnoseTable;
    value: number | string | null;
    label: string;
    totalAccelValue?: boolean;
    numberOfSensors?: number;
};

export type DeviceDiagnoseTableProps = {
    id: number;
    refshowBtn?: React.RefObject<HTMLButtonElement>;
};

export type DeviceDiagnoseTableViewProps = {
    refshowBtn?: React.RefObject<HTMLButtonElement>;
    data?: DeviceDiagnoseModel;
    refetch: (options?) => Promise<QueryObserverResult<DeviceDiagnoseModel, unknown>>;
    isLoading: boolean;
    isFetched: boolean;
};

export enum DiagnoseTable {
    BARO_PRESSURE = 'baroPressure',
    BARO_TEMPERATURE = 'baroTemperature',
    SENSOR_AVG_RSSI = 'sensorAvgRssi',
    RSSI = 'rssi',
    VOLTAGE_TEMPERATURE = 'voltageTemperature',
    VOLTAGE_BATTERY = 'voltageBattery',
    VOLTAGE_VEHICLE = 'voltageVehicle',
    GPS_SATELLITES = 'gpsSatellites',
    GPS_STATE = 'gpsState',
    ACCEL_X = 'accelX',
    ACCEL_Y = 'accelY',
    ACCEL_Z = 'accelZ',
    ACCEL_VIBRATIONS = 'accelVibrations',
    ALERT_COUNT = 'alertCount',
    PSI = 'psi',
    TEMPERATURE = 'temperature',
    CREATED_AT = 'createdAt',
    DEVICE_DIAGNOSE_SENSOR = 'deviceDiagnoseSensor',
    SENSOR_AVG_RSSI_REM = 'sensorAvgRssiRem'
}

export enum GpsStates {
    MOVING = 'MOVING',
    STARTED = 'STARTED',
    STOPPED = 'STOPPED',
    IDLE = 'IDLE',
    ERROR = 'ERROR'
}
