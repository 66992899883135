import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    HeatmapPlaygroundContainer,
    PlaygroundCover,
    ZoomInputConver,
    InputGroup,
    CustomCheckbox
} from './HeatmapPlayground.style';
import { HeatmapPlaygroundViewProps, PlaygroundActionEnums } from './HeatmapPlayground.type';
import TextField from '@mui/material/TextField';
import { MapActionData } from '../../states/global/Map';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '../Ui/Components/UiButton';

const generateZoomInputs = (zooms, setPlayground): JSX.Element[] => {
    return Object.keys(zooms).map((zoom, index) => {
        return (
            <InputGroup key={index}>
                <TextField
                    id={`zoom${zoom}`}
                    size='small'
                    label={`Dot Size ${zoom}`}
                    type='number'
                    value={zooms[zoom].dotSize}
                    variant='outlined'
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        },
                        step: 1,
                        min: 0,
                        max: 20
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setPlayground({ type: PlaygroundActionEnums.SET_DOT_SIZE, payload: +e.target.value, zoom });
                    }}
                />
                <TextField
                    id={`data${zoom}`}
                    size='small'
                    label={`Data ${zoom}`}
                    type='number'
                    value={zooms[zoom].data}
                    variant='outlined'
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        },
                        step: 1,
                        min: 0,
                        max: 20000
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setPlayground({ type: PlaygroundActionEnums.SET_DATA_LIMIT, payload: +e.target.value, zoom });
                    }}
                />
            </InputGroup>
        );
    });
};

const generateBlur = (zooms, setPlayground): JSX.Element[] => {
    return Object.keys(zooms).map((zoom, index) => {
        return (
            <InputGroup key={index}>
                <TextField
                    id={`zoom${zoom}`}
                    size='small'
                    label={`Outer ${zoom}`}
                    type='number'
                    value={zooms[zoom].outerRadius}
                    variant='outlined'
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        },
                        step: 1,
                        min: 0,
                        max: 100
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setPlayground({
                            type: PlaygroundActionEnums.SET_BLUR_OUTER_RADIUS,
                            payload: +e.target.value,
                            zoom
                        });
                    }}
                />
                <TextField
                    id={`data${zoom}`}
                    size='small'
                    label={`Inner ${zoom}`}
                    type='number'
                    value={zooms[zoom].innerRadius}
                    variant='outlined'
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        },
                        step: 1,
                        min: 0,
                        max: 100
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setPlayground({
                            type: PlaygroundActionEnums.SET_BLUR_INNER_RADIUS,
                            payload: +e.target.value,
                            zoom
                        });
                    }}
                />
            </InputGroup>
        );
    });
};

const generateOpacity = (zooms, setPlayground): JSX.Element[] => {
    return Object.keys(zooms).map((zoom, index) => {
        return (
            <InputGroup key={index}>
                <TextField
                    id={`data${zoom}`}
                    size='small'
                    label={`Inner ${zoom}`}
                    type='number'
                    value={zooms[zoom].innerOpacity}
                    variant='outlined'
                    inputProps={{
                        form: {
                            autocomplete: 'off'
                        },
                        step: 0.1,
                        min: 0,
                        max: 1
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setPlayground({
                            type: PlaygroundActionEnums.SET_BLUR_INNER_OPACITY,
                            payload: +e.target.value,
                            zoom
                        });
                    }}
                />
            </InputGroup>
        );
    });
};

export const HeatmapPlaygroundContent: React.FC<HeatmapPlaygroundViewProps> = (props): JSX.Element => {
    const mapActionData = useRecoilValue(MapActionData);
    const zoom = Math.max(mapActionData.zoomLevel || 14, 14);
    return (
        <HeatmapPlaygroundContainer data-testid='HeatmapPlaygroundContent'>
            <h2>Heatmap Playground</h2>
            <h3>Zoom Level: {mapActionData.zoomLevel}</h3>
            <PlaygroundCover>
                <div>
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={!!props.playground[zoom]?.disableDotSize}
                                onChange={(e) => {
                                    props.setLocalPlayground({
                                        type: PlaygroundActionEnums.SET_ALLOW_DOT_SIZE,
                                        payload: !!e.target.checked,
                                        zoom
                                    });
                                }}
                                inputProps={{ 'aria-label': 'primary heckbox' }}
                            />
                        }
                        label='Ignore Dot Size'
                    />
                    <p>Data Settings</p>
                    <ZoomInputConver>{generateZoomInputs(props.playground, props.setLocalPlayground)}</ZoomInputConver>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={!!props.playground[zoom]?.disableBlur}
                                onChange={(e) => {
                                    props.setLocalPlayground({
                                        type: PlaygroundActionEnums.SET_ALLOW_BLUR,
                                        payload: !!e.target.checked,
                                        zoom
                                    });
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label='Disable Blur'
                    />
                    <p>Blur Size Settings</p>
                    <ZoomInputConver>{generateBlur(props.playground, props.setLocalPlayground)}</ZoomInputConver>
                </div>
                <div>
                    <p>Blur Opacity Settings</p>
                    <ZoomInputConver>{generateOpacity(props.playground, props.setLocalPlayground)}</ZoomInputConver>
                </div>
                <Button
                    onClick={() => {
                        localStorage.setItem('playgroundSettings', JSON.stringify(props.playground));
                    }}
                >
                    Save Settings (to LS)
                </Button>
            </PlaygroundCover>
        </HeatmapPlaygroundContainer>
    );
};
