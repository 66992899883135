import { Grid } from '@mui/material';
import React from 'react';
import * as style from './ButtonsToTestAlerts.style';
import * as type from './ButtonsToTestAlerts.type';
import Button from '../../Ui/Components/UiButton';
import { useTranslation } from 'react-i18next';

export const ButtonsToTestAlertsContent: React.FC<type.ButtonsToTestAlertsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.ContainerButtons>
            <Grid item>
                {props.isCurrentUser && (
                    <style.ContainerButton direction='left'>
                        <Button
                            variant='contained'
                            type='button'
                            skin='third'
                            onClick={() => props.testEmailAlert()}
                            testid={props.emailTestId || 'EmailAlert-Button'}
                        >
                            {translate('t.email_alert')}
                        </Button>
                    </style.ContainerButton>
                )}
            </Grid>

            <Grid item>
                {props.isCurrentUser && (
                    <style.ContainerButton direction='left'>
                        <Button
                            variant='contained'
                            type='button'
                            skin='third'
                            disabled={props.disabledSMSAlert}
                            onClick={() => props.testSMSAlert()}
                            testid={props.smsTestId || 'SMSAlert-Button'}
                        >
                            {translate('t.sms_alert')}
                        </Button>
                    </style.ContainerButton>
                )}
            </Grid>

            <Grid item>
                {props.isCurrentUser && (
                    <style.ContainerButton direction='left'>
                        <Button
                            variant='contained'
                            type='button'
                            skin='third'
                            onClick={() => props.testWhatsAppAlert()}
                            disabled={props.disabledSMSAlert}
                            testid={props.whatsappTestId || 'WhatsappAlert-Button'}
                        >
                            {translate('t.whatsapp_alert')}
                        </Button>
                    </style.ContainerButton>
                )}
            </Grid>
        </style.ContainerButtons>
    );
};
