import styled from 'styled-components';

export const VehicleDetailsWheelHistoryViewContent = styled.div`
    margin-top: 10px;
`;

export const Text = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-evenly;
    font-size: 1rem;
`;
