import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HeatmapPlaygroundViewContent } from './HeatmapPlayground.style';
import { HeatmapPlaygroundViewProps } from './HeatmapPlayground.type';
import { useTranslation } from 'react-i18next';
import MapDataLoader from '../../components/MapDataLoader/MapDataLoader';
import HeatmapTopbar from '../../components/HeatmapTopbar/HeatmapTopbar';
import Widget from '../../components/Ui/Components/UiWidget';
import { SpeedHeatmapLegend, SpeedHeatmapLegendLabel } from '../SpeedHeatmap/SpeedHeatmap.style';
import MapLegend from '../../components/Ui/Components/UiMapComponent';
import { MapAction } from '../../states/global/Map';
import { MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import Map from '../../components/Map';
import { applyStyleByMode } from 'helpers';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const HeatmapPlaygroundContent: React.FC<HeatmapPlaygroundViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const ThemeMode = useRecoilValue(Theme);

    return (
        <HeatmapPlaygroundViewContent data-testid='HeatmapPlaygroundContent'>
            <MapDataLoader />
            <HeatmapTopbar showPlayground={true} />
            <Widget
                title={translate('t.map')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={
                    <UiIcon
                        icon={faLocationDot}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                $contentHeight={60}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
            >
                <MapLegend>
                    <SpeedHeatmapLegend />
                    <SpeedHeatmapLegendLabel>
                        <span>0</span>
                        <span>60</span>
                    </SpeedHeatmapLegendLabel>
                </MapLegend>
                <Map mapMode={MapModes.SPEED_HEATMAP} />
            </Widget>
        </HeatmapPlaygroundViewContent>
    );
};
