import PortalApi from 'helpers/api/PortalApiClient';
import { RoleCodeBook } from '../models/Role.type';

export default class Role extends PortalApi<RoleCodeBook> {
    route = 'role';

    getAvailable = async (): Promise<RoleCodeBook[]> => {
        return await this.get({ extendUrl: 'available', apiProject: undefined });
    };

    getCodeBook(): Promise<RoleCodeBook[]> {
        return this.getCodebook({ apiProject: undefined });
    }
}
