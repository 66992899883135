import React from 'react';
import { TagFilterProps } from './TagFilter.type';
import { TagFilterContent } from './TagFilter.view';
import { Wrapper } from 'helpers/wrapper';

const TagFilter: React.FC<TagFilterProps> = (props): JSX.Element => {
    return <TagFilterContent {...props} data-testid='TagFilter-testid' />;
};

export default Wrapper(TagFilter);
