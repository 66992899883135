import React from 'react';
import { CustomerCheckerContent } from './CustomerChecker.view';
import { CustomerCheckerProps } from './CustomerChecker.type';
import { useSetRecoilState } from 'recoil';
import { CustomerSelectorAtom } from 'components/Customer/CustomerSelectorAtom';
import { Wrapper } from 'helpers/wrapper';

const CustomerChecker: React.FC<CustomerCheckerProps> = (): JSX.Element => {
    const setSelectorState = useSetRecoilState(CustomerSelectorAtom);

    const updateCustomer = (): void => {
        setSelectorState((current) => ({ ...current, isOpen: true }));
    };

    return <CustomerCheckerContent data-testid='CustomerChecker-testid' updateCustomer={updateCustomer} />;
};

export default Wrapper(CustomerChecker);
