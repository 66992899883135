import React from 'react';
import { LeakNotificationContainer, ProcessingLabel, EmailNotification } from './LeakNotification.style';
import { LeakNotificationViewProps } from './LeakNotification.type';
import { Alert, AlertTitle } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

export const LeakNotificationContent: React.FC<LeakNotificationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <LeakNotificationContainer data-testid='LeakNotificationContent'>
            <Alert severity='info'>
                <AlertTitle>
                    <strong>{translate(`t.report_${props.leakNotificationType}_leak`)}</strong>
                </AlertTitle>
                <EmailNotification>{translate(`p.report_${props.leakNotificationType}_leak_email`)}</EmailNotification>
            </Alert>
            {props.processingStatus.length > 0 && <LinearProgress />}
            <ProcessingLabel>{props.processingStatus}</ProcessingLabel>
        </LeakNotificationContainer>
    );
};
