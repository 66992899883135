import { Grid, MenuItem, useTheme } from '@mui/material';
import styled from 'styled-components';
import { ThemeMode } from '../../../../../states/global/Theme';
import { BACKGROUND_PAPER_DARK_MODE, WHITE } from '../../../../Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleFiltersContentView = styled.div`
    position: sticky !important;
    top: 0px !important;
    visibility: hidden;
    z-index: 1;
    &.active {
        visibility: visible;
    }
`;

export const FiltersContainer = styled.section`
    ${() => {
        return `
            background: ${useTheme().palette.mode === ThemeMode.light ? '#f5f5f5' : BACKGROUND_PAPER_DARK_MODE};
        `;
    }}

    width: 100%;
    transition: max-height 0.2s linear;
    overflow: hidden;
    max-height: 0px;
    visibility: hidden;
    transition: max-height 0.5s linear, visibility 0.5s linear;

    &.active {
        max-height: 300px;
        visibility: visible;
        transition: max-height 0.5s linear, visibility 0.5s linear;
    }
`;

export const FilterContainer = styled.section`
    margin: 10px 0px;
    & .MuiInputBase-inputSizeSmall {
        padding-top: 6.4px !important;
        padding-bottom: 6.4px !important;
    }
`;

export const FilterHolder = styled(Grid)`
    && {
        margin: 0;
        justify-content: center;
    }
`;

export const FilterCategory = styled.h1`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            color: ${useTheme().palette.mode === ThemeMode.light ? '#000' : WHITE};
            font-size: ${common.textSize};
            margin: 10px 0px 0px 0px;
        `;
    }}
`;

export const FilterSelectOption = styled(MenuItem)``;

export const FilterOptionText = styled.span<{ all?: boolean }>`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
`;

export const FilterOptionIcon = styled.i<{ color: string }>`
    color: ${(props) => props.color};
`;
