import React from 'react';
import { UiTableSkeletonLoaderContainer } from './UiTableSkeletonLoader.style';
import { Skeleton } from '@mui/material';
import { UiTableSkeletonLoaderProps } from './UiTableSkeletonLoader.type';

const UiTableSkeletonLoader: React.FC<UiTableSkeletonLoaderProps> = (): JSX.Element => {
    const rowHeight = 45;
    return (
        <UiTableSkeletonLoaderContainer data-testid='UiTableSkeletonLoader'>
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
            <Skeleton variant='rounded' width={'100%'} height={rowHeight} />
        </UiTableSkeletonLoaderContainer>
    );
};

export default UiTableSkeletonLoader;
