import React from 'react';
import { AlertsToDisplay, VehicleStatusGridProps } from './VehicleStatusGrid.type';
import { VehicleStatusGridContent } from './VehicleStatusGrid.view';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { useRecoilValue } from 'recoil';

const checkAlertsToDisplay = (data): AlertsToDisplay => {
    const alertsTypes: AlertsToDisplay = {
        lowPressure: false,
        highPressure: false,
        temperature: false,
        temperaturePrediction: false
    };
    const keys = Object.keys(alertsTypes);

    for (let i = 0; i < data.length; i++) {
        if (keys.includes(data[i].levelType)) {
            alertsTypes[data[i].levelType] = true;
        }
    }
    return alertsTypes;
};

const VehicleStatusGrid: React.FC<VehicleStatusGridProps> = (props): JSX.Element => {
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    return (
        <VehicleStatusGridContent
            {...props}
            isLoadingVehicle={vehicleDetailsWidgetAtom.isLoadingVehicle ?? false}
            displayAlerts={checkAlertsToDisplay}
        />
    );
};

export default VehicleStatusGrid;
