import React, { useEffect } from 'react';
import { TemperaturePressureContent } from './TemperaturePressure.view';
import { TemperaturePressureProps } from './TemperaturePressure.type';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const TemperaturePressure: React.FC<TemperaturePressureProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tires-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <TemperaturePressureContent data-testid='TemperaturePressure-testid' />;
};

export default Wrapper(TemperaturePressure);
