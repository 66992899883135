import { ReportModel, ReportModelDetail } from '../../../../models/Report.type';
import { ReportStatus } from '../ExportedReports.type';

type passProps = {
    report?: ReportModel;
    reportStatus: ReportStatus;
};

export type ExportedReportDetailProps = {} & passProps;

export type ExportedReportDetailViewProps = {
    reportDetail?: ReportModelDetail;
    loading: boolean;
    dateFormat: (date: string) => string;
    timeFormat: (time: string) => string;
    temperatureFormat: (temperature: string) => string;
} & passProps;

export type ExportedReportTableProps = {
    title: string;
    cells: {
        label: string;
        value: string | number;
    }[];
};

export enum LanguageType {
    en_GB = 'English UK',
    en_US = 'English US',
    fr_CA = 'Français canadien',
    es_ES = 'Español',
    pt_PT = 'Portugal',
    ru_RU = 'Русский язык',
    uk_UA = 'Українська'
}
