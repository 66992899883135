import React, { useState } from 'react';
import { RoutesListContent } from './RoutesList.view';
import { RoutesListProps } from './RoutesList.type';
import { RouteLocation } from '../../../models/Route.type';
import { Wrapper } from 'helpers/wrapper';

const RoutesList: React.FC<RoutesListProps> = (): JSX.Element => {
    const [route, setRoute] = useState([] as RouteLocation[]);

    return <RoutesListContent data-testid='RoutesList-testid' route={route} setRoute={setRoute} />;
};

export default Wrapper(RoutesList);
