import React from 'react';
import { SchemaDiv, VehicleDetailsSchemaViewContent } from './VehicleDetailsSchema.style';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailsSchemaProps } from './VehicleDetailsSchema.type';
import VehicleSchema from '../NewVehicleSchema';
import { VehicleModel } from 'models/Vehicle.type';

export const VehicleDetailsSchemaContent: React.FC<VehicleDetailsSchemaProps> = (props): JSX.Element => {
    const vehicleDetailsWidgetAtom = useRecoilValue(
        VehicleDetailsWidgetAtom(props.vehicleAtomId ? `${'VehicleSchemaAtom-' + props.vehicleAtomId}` : undefined)
    );

    return (
        <>
            <VehicleDetailsSchemaViewContent data-testid='VehicleDetailsSchemaContent'>
                <SchemaDiv noBorder={props.noBorder}>
                    <VehicleSchema
                        schema={
                            (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.vehicleConfigurationV2
                                ?.distribution || '2-2'
                        }
                        emptySchema={false}
                        vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                        vehicleAtomId={props.vehicleAtomId}
                    />
                </SchemaDiv>
            </VehicleDetailsSchemaViewContent>
        </>
    );
};
