import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertsAvailableFiltersListViewContent, AvailableFilterBox } from './AlertsAvailableFiltersList.style';
import { AlertsAvailableFiltersListViewProps } from './AlertsAvailableFiltersList.type';
import { useTranslation } from 'react-i18next';
import CircularProgress from '../../Ui/Components/UiCircularProgress';
import {
    FilterIds,
    ProcessingFilterSelection,
    SelectedAvailableFilterIds,
    UnselectedAvailableFilters
} from '../AlertsAvailableFilters/atom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { AlertInfo } from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';
import { AlertVehicleFilters } from '../AlertsFilterVehiclesBy/atom';

export const AlertsAvailableFiltersListContent: React.FC<AlertsAvailableFiltersListViewProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();
    const availableFilters = useRecoilValue(UnselectedAvailableFilters);
    const setSelectedFilters = useSetRecoilState(SelectedAvailableFilterIds);
    const theme = useTheme();
    const setFilters = useSetRecoilState(AlertVehicleFilters);
    const setProcessingSelection = useSetRecoilState(ProcessingFilterSelection);

    return (
        <AlertsAvailableFiltersListViewContent data-testid='AlertsAvailableFiltersListContent'>
            {props.loading ? (
                <CircularProgress />
            ) : !availableFilters.length ? (
                <AlertInfo severity='info'>{translate('t.no_available_filters')}</AlertInfo>
            ) : (
                availableFilters.map((filter, index) => (
                    <React.Fragment key={index}>
                        <Tooltip title={translate(filter.tooltip?.translationKey || '')} arrow disableInteractive>
                            <Box width={'100%'}>
                                <AvailableFilterBox
                                    theme={theme}
                                    isDisabled={props.checkDisabled(filter)}
                                    onClick={() => {
                                        setProcessingSelection(true);
                                        if (!props.checkDisabled(filter)) {
                                            if (filter.id === FilterIds.DEFAULT) {
                                                setFilters(() => ({
                                                    [filter.tableId]: { name: filter.name, id: filter.id }
                                                }));
                                                setSelectedFilters(() => [filter.id]);
                                            } else {
                                                setSelectedFilters((current) => [...current, filter.id]);
                                            }
                                        }
                                    }}
                                >
                                    <Typography width={'100%'}>
                                        {translate(`t.${filter.name}`)}{' '}
                                        {filter.tooltip && <FontAwesomeIcon icon={faCircleInfo} />}
                                    </Typography>{' '}
                                    <FontAwesomeIcon icon={faCirclePlus} />
                                </AvailableFilterBox>
                            </Box>
                        </Tooltip>
                    </React.Fragment>
                ))
            )}
        </AlertsAvailableFiltersListViewContent>
    );
};
