import React from 'react';
import {
    DateTimeText,
    GridCalendar,
    GridContainer,
    GridTimePicker,
    GridTitle,
    CustomCalendarPicker,
    Text,
    Title
} from './DateTimeRangeDialogSelector.style';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useConverter from '../../../../../CustomHooks/Converter/Converter';
import { DateTimeModalDialogProps } from './DateTimeRangeDialogSelector.type';
import { Is12HrsFormat } from 'helpers/Converters';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import TimeSelector from '../TimeSelector/TimeSelector';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RangePositionCalendar } from './DateTimeRangeDialogSelector.type';
import { LastChangedValue, RangePosition } from '../../UiDateRangePicker2.atom';

const DateTimeDialogSelector: React.FC<DateTimeModalDialogProps> = ({
    dateValueStaticDatePickerFrom,
    dateValueStaticDatePickerTo,
    onlyDate,
    adjustToPeriod,
    validFutureDate,
    setDateValueStaticDatePicker,
    outOfPeriod
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { dateTimeFormat } = useConverter();
    const [rangePosition, setRangePosition] = useRecoilState(RangePosition);
    const setLastChangedValue = useSetRecoilState(LastChangedValue);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const dateLabel = 'choose_date';
    const dateTimeLabel = 'select_date_and_time_range';

    const changeRangePosition = (rangePosition: RangePositionCalendar) => {
        if (rangePosition === RangePositionCalendar.START) {
            setRangePosition(RangePositionCalendar.END);
            setLastChangedValue(RangePositionCalendar.START);
        } else if (rangePosition === RangePositionCalendar.END) {
            setRangePosition(RangePositionCalendar.START);
            setLastChangedValue(RangePositionCalendar.END);
        }
    };

    const isFutureDate = (date: DateTime): boolean => {
        const today = DateTime.local();
        return date > today;
    };

    return (
        <GridContainer container spacing={1}>
            <GridTitle item xs={12} md={12} lg={12}>
                <Title>{translate(`t.${onlyDate ? dateLabel : dateTimeLabel}`)} </Title>
            </GridTitle>
            <GridCalendar item xs={12} md={onlyDate ? 12 : 7} className='datePicker' $onlyDate={onlyDate}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CustomCalendarPicker
                        rangePosition={rangePosition}
                        disableAutoMonthSwitching={true}
                        sx={{ display: onlyDate ? 'inline-block' : 'flex' }}
                        calendars={1}
                        value={[dateValueStaticDatePickerFrom, dateValueStaticDatePickerTo]}
                        shouldDisableDate={validFutureDate ? undefined : isFutureDate}
                        onChange={(newDate: DateTime[], selectionState): void => {
                            const adjustedDate = newDate;

                            changeRangePosition(
                                selectionState === 'partial'
                                    ? !newDate[1]
                                        ? RangePositionCalendar.END
                                        : RangePositionCalendar.START
                                    : RangePositionCalendar.END
                            );

                            if (!adjustedDate[1]) {
                                adjustedDate[1] = newDate[0];
                            }

                            if (outOfPeriod(adjustedDate[0], adjustedDate[1])) {
                                if (rangePosition === RangePositionCalendar.START) {
                                    adjustedDate[1] = newDate[0].plus({
                                        [`${adjustToPeriod?.periodType}`]: adjustToPeriod?.period
                                    });
                                } else if (rangePosition === RangePositionCalendar.END) {
                                    adjustedDate[0] = newDate[1].minus({
                                        [`${adjustToPeriod?.periodType}`]: adjustToPeriod?.period
                                    });
                                }
                            }

                            setDateValueStaticDatePicker([
                                dateValueStaticDatePickerFrom.set({
                                    day: adjustedDate[0].c.day,
                                    month: adjustedDate[0].c.month,
                                    year: adjustedDate[0].c.year,
                                    hour:
                                        adjustedDate[0].c.day >= adjustedDate[1].c.day
                                            ? dateValueStaticDatePickerTo.c.hour
                                            : dateValueStaticDatePickerFrom.c.hour,
                                    minutes:
                                        adjustedDate[0].c.day >= adjustedDate[1].c.day
                                            ? dateValueStaticDatePickerTo.c.minute - 1
                                            : dateValueStaticDatePickerFrom.c.minute
                                }),
                                dateValueStaticDatePickerTo.set({
                                    day: adjustedDate[1].c.day,
                                    month: adjustedDate[1].c.month,
                                    year: adjustedDate[1].c.year
                                })
                            ]);
                        }}
                    />
                </LocalizationProvider>
            </GridCalendar>
            {!onlyDate && (
                <GridTimePicker item xs={12} md={5} className='timePicker'>
                    <Text $ampm={is12HrsFormat}>{translate('t.from')}:</Text>
                    <DateTimeText variant='h6'>
                        <UiButton
                            testid='Date-From-Select-Button'
                            onClick={() => setRangePosition(RangePositionCalendar.START)}
                            startIcon={rangePosition == RangePositionCalendar.START ? <ArrowForwardIosIcon /> : <> </>}
                        >
                            {DateTime.fromISO(dateValueStaticDatePickerFrom).toFormat(dateTimeFormat('date', false))}
                        </UiButton>
                    </DateTimeText>

                    <TimeSelector
                        dateValueStaticDatePicker={dateValueStaticDatePickerFrom}
                        onTimeChange={(newDate) => {
                            if (
                                !validFutureDate &&
                                dateValueStaticDatePickerFrom.c.day === dateValueStaticDatePickerTo.c.day
                            ) {
                                if (!validFutureDate && newDate.valueOf() > DateTime.local().valueOf()) {
                                    return;
                                }
                                if (
                                    newDate.c.hour > dateValueStaticDatePickerTo.c.hour ||
                                    (newDate.c.hour === dateValueStaticDatePickerTo.c.hour &&
                                        newDate.c.minute >= dateValueStaticDatePickerTo.c.minute)
                                ) {
                                    setDateValueStaticDatePicker([newDate, newDate.plus({ minute: 1 })]);
                                    setLastChangedValue(RangePositionCalendar.START);
                                    return;
                                }
                            }

                            setDateValueStaticDatePicker([newDate, dateValueStaticDatePickerTo]);
                            setLastChangedValue(RangePositionCalendar.START);
                        }}
                        minutesInterval={1}
                        hoursInterval={1}
                        disabled={onlyDate}
                    />

                    <Text $ampm={is12HrsFormat}>{translate('t.to')}:</Text>
                    <DateTimeText variant='h6'>
                        <UiButton
                            testid='Date-To-Select-Button'
                            onClick={() => setRangePosition(RangePositionCalendar.END)}
                            startIcon={rangePosition == RangePositionCalendar.END ? <ArrowForwardIosIcon /> : <> </>}
                        >
                            {DateTime.fromISO(dateValueStaticDatePickerTo).toFormat(dateTimeFormat('date', false))}
                        </UiButton>
                    </DateTimeText>
                    <TimeSelector
                        dateValueStaticDatePicker={dateValueStaticDatePickerTo}
                        onTimeChange={(newDate) => {
                            if (
                                !validFutureDate &&
                                dateValueStaticDatePickerFrom.c.day === dateValueStaticDatePickerTo.c.day
                            ) {
                                if (!validFutureDate && newDate.valueOf() > DateTime.local().valueOf()) {
                                    return;
                                }
                                if (
                                    newDate.c.hour < dateValueStaticDatePickerFrom.c.hour ||
                                    (newDate.c.hour === dateValueStaticDatePickerFrom.c.hour &&
                                        newDate.c.minute <= dateValueStaticDatePickerFrom.c.minute)
                                ) {
                                    setDateValueStaticDatePicker([newDate.minus({ minute: 1 }), newDate]);
                                    setLastChangedValue(RangePositionCalendar.END);
                                    return;
                                }
                            }
                            setDateValueStaticDatePicker([dateValueStaticDatePickerFrom, newDate]);
                            setLastChangedValue(RangePositionCalendar.END);
                        }}
                        minutesInterval={1}
                        hoursInterval={1}
                        disabled={onlyDate}
                    />
                </GridTimePicker>
            )}
        </GridContainer>
    );
};

export default DateTimeDialogSelector;
