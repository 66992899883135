import React from 'react';
import { RankDateRangePickersViewProps } from './RankDateRangePickers.type';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { useTranslation } from 'react-i18next';

export const RankDateRangePickersContent: React.FC<RankDateRangePickersViewProps> = ({
    dateRange,
    setDateTimeRange
}): JSX.Element => {
    const { t: translator } = useTranslation();
    return (
        <UiDateRangePicker2
            adjustToPeriod={{ period: 30, periodType: PeriodTypeEnum.DAYS }}
            dateFrom={dateRange?.dateFrom}
            dateTo={dateRange?.dateTo}
            dateFromLabel={translator('t.date_from')}
            dateToLabel={translator('t.date_to')}
            onDateChange={setDateTimeRange}
        />
    );
};
