import React from 'react';
import { NotificationsToggleButtonViewProps } from './NotificationsToggleButton.type';
import { ToggleButton } from './NotificationsToggleButton.style';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';

export const NotificationsToggleButtonContent: React.FC<NotificationsToggleButtonViewProps> = (props): JSX.Element => (
    <Tooltip title={props.toolTipText} placement='bottom' arrow>
        <ToggleButton
            $statusNotification={props.muteNotification}
            onClick={() => props.toggleNotification()}
            disabled={props.notificationMuteLoading}
            className='notificationsToggleButton'
            data-testid='EnableOrDisableNotifications-Button'
        >
            {props.notificationMuteLoading ? (
                <CircularProgress size={'1.35rem'} />
            ) : (
                <FontAwesomeIcon size='sm' icon={['far', props.muteNotification ? 'bell-slash' : 'bell-on']} />
            )}
        </ToggleButton>
    </Tooltip>
);
