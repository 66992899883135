import { useRecoilValue } from 'recoil';
import useConverter from '../Converter/Converter';
import { TemperaturePressureProps, TemperaturePressureReturnType, Value } from './TemperaturePressure.type';
import { HasInternalSensorAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { UserInfo } from 'states/global/User';

const NO_DATA = '-';

const useTempPressValue = (): TemperaturePressureReturnType => {
    const { convertType, fromServerToUserUnit } = useConverter();
    const hasInternalSensorAtom = useRecoilValue(HasInternalSensorAtom());
    const userInfo = useRecoilValue(UserInfo);

    const evaluateHotPressure = (value: Value, props: TemperaturePressureProps) => {
        return evaluateHotPressureStatus(value, props);
    };

    const evaluateColdPressure = (value: Value, props: TemperaturePressureProps) => {
        return evalueColdPressureSetting(value, props);
    };

    const evaluateTemperature = (value: Value, props: TemperaturePressureProps) => {
        return evaluateTemperatureStatus(value, props);
    };

    const getPressureValue = (value: Value): string => {
        return fromServerToUserUnit({
            type: convertType.pressure,
            value: value,
            displayUnits: false,
            fixed: 1,
            displayIfEmpty: NO_DATA
        });
    };

    const getTemperatureValue = (value: Value): string => {
        return fromServerToUserUnit({
            type: convertType.temperature,
            value: value,
            displayUnits: false,
            fixed: 1,
            displayIfEmpty: '-'
        });
    };

    const evaluateExternalTemperatureEnabled = (value: Value) => {
        if (userInfo.user?.customer.externalTemperatureEnabled) {
            if (hasInternalSensorAtom) {
                return evaluateTemperatureExternal(value);
            }
            return NO_DATA;
        }
        return NO_DATA;
    };

    const evaluateTemperatureExternal = (value: Value) => {
        if (userInfo.user?.userSetting.showTemperatureForExternalSensors) {
            return getTemperatureValue(value);
        }
        return NO_DATA;
    };

    const evaluateValue = (value: Value, props: TemperaturePressureProps) => {
        const valueTypeStructure = {
            coldPressure: evaluateColdPressure,
            hotPressure: evaluateHotPressure,
            temperature: evaluateTemperature
        };
        return valueTypeStructure[props.valueType](value, props);
    };

    const evalueColdPressureExternal = (value: Value) => {
        if (userInfo.user?.userSetting.showColdPressureForExternalSensors) {
            return getPressureValue(value);
        }
        return NO_DATA;
    };

    const evaluateHasInternalSensor = (value: Value) => {
        if (hasInternalSensorAtom) {
            return evalueColdPressureExternal(value);
        }
        return NO_DATA;
    };

    const evaluateInternalSensor = (value: Value, props: TemperaturePressureProps) => {
        if (props.sensor?.sensorType?.id === 1) {
            return getPressureValue(value);
        }
        return evaluateHasInternalSensor(value);
    };

    const evaluateInternalSensorTemperature = (value: Value, props: TemperaturePressureProps) => {
        if (props.sensor?.sensorType?.id === 1) {
            return getTemperatureValue(value);
        }
        return evaluateExternalTemperatureEnabled(value);
    };

    const evalueColdPressureSetting = (value: Value, props: TemperaturePressureProps) => {
        if (userInfo.user?.userSetting.coldPressure) {
            return evaluateColdPressureStatus(value, props);
        }
        return NO_DATA;
    };

    const evaluateColdPressureStatus = (value: Value, props: TemperaturePressureProps) => {
        if (props.sensor?.pressureStatus) {
            return evaluateInternalSensor(value, props);
        }
        return NO_DATA;
    };

    const evaluateHotPressureStatus = (value: Value, props: TemperaturePressureProps) => {
        if (props.sensor?.pressureStatus) {
            return getPressureValue(value);
        }
        return NO_DATA;
    };

    const evaluateTemperatureStatus = (value: Value, props: TemperaturePressureProps) => {
        if (props.sensor?.temperatureStatus) {
            return evaluateInternalSensorTemperature(value, props);
        }
        return NO_DATA;
    };

    const displayValue: TemperaturePressureReturnType['displayValue'] = (value, props) => {
        return evaluateValue(value, props);
    };

    return {
        displayValue
    };
};

export default useTempPressValue;
