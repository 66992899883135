import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { UiTableInstanceProps, columnVisibilityType } from './UiTableInstance.type';
import {
    ColumnInstance,
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import { useSetRecoilState } from 'recoil';
import { ColumnNameIdType, ColumnNamesIds, ColumnVisibility, TableFn } from '../UiTable/TableAtom';
import { TableActions, TableAtom, TableDispatcher } from 'states/component/Table';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import UiTableColumnFilter from '../UiTableColumnFilter';
import { Table } from '@mui/material';
import UiTableBody from '../UiTableBody/UiTableBody';
import UiTableHeader from '../UiTableHeader/UiTableHeader';
import UiTableFilterColumnsPopover from '../UiTableFilterColumnsPopover/UiTableFilterColumnsPopover';
import { Column } from 'models/Table.type';

const UiTableInstanceContent: React.FC<UiTableInstanceProps> = ({ columns, data, ...props }): JSX.Element => {
    const setColumnNamesIds = useSetRecoilState(ColumnNamesIds);
    const setColumnVisibility = useSetRecoilState(ColumnVisibility);
    const setTableFns = useSetRecoilState(TableFn(`${props.queryKey}-TableFn`));
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const [definedColumns, setDefinedColumns] = useState<boolean>(false);
    const [definedFns, setDefinedFns] = useState<boolean>(false);
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.queryKey}Table`]);
    let setTableTimer;

    const defaultColumn: any = useMemo(() => {
        return {
            Filter: UiTableColumnFilter
        };
    }, []);

    const expandableColumn: Column<any> = {
        id: 'expander',
        width: 65,
        Header: ({ rows, toggleAllRowsExpanded }) => {
            const handleToggleAllRows = () => {
                const areAllRowsExpanded = rows.every((row) => row.isExpanded);
                toggleAllRowsExpanded(!areAllRowsExpanded);
            };

            return (
                <button onClick={handleToggleAllRows} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon onClick={handleToggleAllRows} icon={['fas', 'chevron-up']} /> :{' '}
                    <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                </button>
            );
        },
        isFoldable: true,
        Cell: ({ row }) => (
            <IconButton aria-label='expand row' size='small' {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} />
                ) : (
                    <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                )}
            </IconButton>
        )
    };

    props.isExpandable ? columns.unshift(expandableColumn) : columns;

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: tableState.queryPageIndex,
                pageSize: tableState.queryPageSize,
                sortBy: tableState.queryPageSortBy,
                filters: tableState.queryPageFilter,
                hiddenColumns: !getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
                    ? props.hiddenColumns || []
                    : getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
            },
            manualPagination: true,
            defaultColumn,
            pageCount: props.pageCount,
            autoResetPage: false,
            manualSortBy: true,
            manualFilters: true
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns
    );

    const getColumnVisibility = (columns: ColumnInstance[]): columnVisibilityType => {
        let columnVisibility: columnVisibilityType = {};
        for (let i = 0, length = columns.length; i < length; i++) {
            columnVisibility[columns[i].id] = {
                isVisible: columns[i].isVisible,
                name: columns[i].Header
            };
        }
        return columnVisibility;
    };

    const defineTableFns = useCallback(() => {
        if (!definedFns) {
            setTableFns({
                toggleHideColumn: tableInstance.toggleHideColumn,
                gotoPage: tableInstance.gotoPage
            });
            setDefinedFns(true);
        }
    }, [tableInstance.toggleHideColumn, tableInstance.gotoPage, definedFns]);

    const defineTableColumns = useCallback(() => {
        if (!definedColumns) {
            const columnNames: ColumnNameIdType[] = tableInstance.allColumns.map((column) => ({
                id: column.id,
                name: column.Header
            }));
            setColumnNamesIds(columnNames);
            setDefinedColumns(true);
        }
        setColumnVisibility(getColumnVisibility(tableInstance.allColumns));
    }, [tableInstance.allColumns, definedColumns]);

    useEffect(() => {
        if (data && props.paginator?.totalCount) {
            tableDispatch({
                type: TableActions.TOTAL_COUNT_CHANGED,
                payload: props.paginator.totalCount
            });
        }
    }, [data, props.paginator?.totalCount]);

    useEffect(() => {
        if (tableInstance.state.pageIndex !== tableState.queryPageIndex) {
            tableDispatch({
                type: TableActions.PAGE_CHANGED,
                payload: tableInstance.state.pageIndex
            });
            return;
        } else if (tableInstance.state.pageSize !== tableState.queryPageSize) {
            tableDispatch({
                type: TableActions.PAGE_SIZE_CHANGED,
                payload: tableInstance.state.pageSize
            });
        } else if (JSON.stringify(tableInstance.state.sortBy) !== JSON.stringify(tableState.queryPageSortBy)) {
            tableDispatch({
                type: TableActions.PAGE_SORT_CHANGED,
                payload: tableInstance.state.sortBy
            });
        }
    }, [
        tableInstance.state.pageIndex,
        tableInstance.state.pageSize,
        tableInstance.state.sortBy,
        tableInstance.state.filters
    ]);

    useEffect(() => {
        if (getUserSettingTable(localStorageTableKey)?.orderBy) {
            tableInstance.setSortBy(getUserSettingTable(localStorageTableKey)?.orderBy);
        }
        if (getUserSettingTable(localStorageTableKey)?.hiddenColumns) {
            tableInstance.setHiddenColumns(getUserSettingTable(localStorageTableKey).hiddenColumns);
        }
        if (getUserSettingTable(localStorageTableKey)?.pageSize) {
            tableInstance.setPageSize(getUserSettingTable(localStorageTableKey).pageSize);
        }
    }, [
        (getUserSettingTable(localStorageTableKey)?.orderBy || []).toString(),
        (getUserSettingTable(localStorageTableKey)?.hiddenColumns || []).toString(),
        getUserSettingTable(localStorageTableKey)?.pageSize
    ]);

    useEffect(() => {
        tableInstance.state.pageIndex !== 0 && tableInstance.gotoPage instanceof Function && tableInstance.gotoPage(0);
        if (
            !props.useColumnFiltering &&
            JSON.stringify(tableState.queryPageFilter) !== JSON.stringify(tableInstance.state.filters)
        ) {
            props.queryKey &&
                setUserSettings(`table.${props.queryKey}Table`, {
                    pageSize: tableInstance.state.pageSize,
                    orderBy: tableInstance.state.sortBy,
                    hiddenColumns: tableInstance.state.hiddenColumns,
                    filter: getUserSettingTable(`table.${props.queryKey}Table`)?.filter.reduce((acc, curr) => {
                        if (
                            tableState.queryPageFilter.filter((currentFilter) => currentFilter.id === curr.id).length >
                            0
                        ) {
                            acc.push(curr);
                        }

                        return acc;
                    }, [])
                });

            tableInstance.setAllFilters(tableState.queryPageFilter);
        }
    }, [tableState.queryPageFilter]);

    defineTableColumns();
    defineTableFns();

    return (
        <>
            {!props.useColumnFiltering && tableInstance.allColumns.filter((column) => column.canFilter).length > 0 && (
                <UiTableFilterColumnsPopover table={tableInstance} queryKey={props.queryKey} />
            )}

            <Table size='small' {...tableInstance.getTableProps()}>
                <UiTableHeader
                    useColumnFiltering={props.useColumnFiltering}
                    useColumnAction={props.useColumnAction}
                    queryKey={props.queryKey}
                    useColumnCheckbox={props.useColumnCheckbox}
                    headerGroups={tableInstance.headerGroups}
                />
                <UiTableBody
                    getTableBodyProps={tableInstance.getTableBodyProps}
                    getTableProps={tableInstance.getTableProps}
                    prepareRow={tableInstance.prepareRow}
                    rows={tableInstance.rows}
                    rowActionBtns={props.rowActionBtns}
                    useColumnAction={props.useColumnAction}
                    useColumnCheckbox={props.useColumnCheckbox}
                    queryKey={props.queryKey}
                    expandableContent={props.expandableContent}
                    isExpandable={props.isExpandable}
                />
            </Table>
        </>
    );
};

export default UiTableInstanceContent;
