import Account from '../../../api/Account';
import User from '../../../api/User';
import React, { useEffect, useState } from 'react';
import * as view from './AlertsSettingsForm.view';
import * as type from './AlertsSettingsForm.type';
import { Errors, Success } from '../../Popup/Popup';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { User as UserState } from '../../../states/global/User';
import { UserModel } from 'models/User.type';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Wrapper } from 'helpers/wrapper';

const ApiAccount = new Account();
const ApiUser = new User();

const AlertsSettingsForm: React.FC<type.AlertsSettingsFormProps> = ({
    userDataSetting,
    isCurrentUser,
    userId,
    setReloadPage,
    userEmail
}): JSX.Element => {
    const [viewLoading, setViewLoading] = useState<boolean>(true);
    const { t: translate } = useTranslation();
    const [userState, setUserState] = useRecoilState(UserState);

    const [inicialvaluesForm, setInicialvaluesForm] = useState<type.InicialvaluesForm>({
        alertReminderLevel1: '',
        alertReminderLevel2: '',
        alertReminderLevel3: '',
        allowDesktopNotifications: false,
        notificationPeriod: ''
    });

    const unresolvedAlertReminderLevelList: type.CommonDropDown[] = [
        { value: 0, name: translate('t.never') },
        { value: 600, name: '10min' },
        { value: 900, name: '15min' },
        { value: 1800, name: '30min' },
        { value: 3600, name: '60min' },
        { value: 14400, name: '4h' }
    ];

    const notificationPeriodList: type.CommonDropDown[] = [
        { value: 24, name: '24h' },
        { value: 48, name: '48h' }
    ];

    const handleSubmit = async (alertsSettingsFormData: type.InicialvaluesForm, setSubmitting): Promise<void> => {
        alertsSettingsFormData.desktopNotification = alertsSettingsFormData.allowDesktopNotifications;

        const { ...newAlertsSettingsFormData } = alertsSettingsFormData;

        const AlertPatch = isCurrentUser
            ? ApiAccount.patchSetting(newAlertsSettingsFormData)
            : ApiUser.patchSetting(userId as number, newAlertsSettingsFormData);
        const { userSetting }: type.IUserSetting = await AlertPatch;

        if (userSetting) {
            Success({ text: translate('t.alerts_settings_modified_successfully') });
            const newUserState = { ...userState };
            newUserState.userSetting = userSetting;
            isCurrentUser && setUserState(newUserState as UserModel);
            setReloadPage(true);
        } else {
            Errors({ text: translate('t.error_modifying_alerts_settings') });
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (userDataSetting && !!Object.keys(userDataSetting).length) {
            setInicialvaluesForm({
                alertReminderLevel1: userDataSetting.alertReminderLevel1,
                alertReminderLevel2: userDataSetting.alertReminderLevel2,
                alertReminderLevel3: userDataSetting.alertReminderLevel3,
                allowDesktopNotifications: userDataSetting.desktopNotification,
                notificationPeriod: userDataSetting.notificationPeriod
            });
        }
        setViewLoading(false);
    }, [userDataSetting]);

    return !viewLoading ? (
        <view.AlertsSettingsFormContent
            data-testid={'AlertsSettingsForm-testid'}
            inicialvaluesFormik={inicialvaluesForm}
            unresolvedAlertReminderLevelList={unresolvedAlertReminderLevelList}
            notificationPeriodList={notificationPeriodList}
            handleSubmit={handleSubmit}
            userEmail={userEmail}
        />
    ) : (
        <UiLoadingPage size='30px' />
    );
};

export default Wrapper(AlertsSettingsForm);
