import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const ContainerFormAlertsSettings = styled.div`
    min-height: 137px;
    max-width: 50%;
    margin: 0 auto;
    padding-top: 35px;

    @media only screen and (min-width: 300px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 600px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 900px) {
        max-width: 70%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }

    ${applyStyleByMode({
        styleOld: `
            & .MuiTypography-body1 {
                font-size: 14px;
            }
            .MuiTextField-root label {
                background: #f3f3f3 !important;
            }
        `
    })}
`;

export const AlertsSettingsFormContent = styled.div``;

export const ContainerButton = styled.div`
    float: right;
    padding-right: 10px;
`;
