import PortalApi from 'helpers/api/PortalApiClient';
import {
    NotificationModel,
    NotificationsWithPaginator,
    NotificationType,
    TyreNotificationsResponse,
    UsersSubscribed,
    SubscribedParams
} from '../models/Notification.type';
import { getParams } from 'helpers';

export default class Notification extends PortalApi<NotificationModel> {
    route = 'notification';

    getNotification = async (props): Promise<NotificationsWithPaginator> => {
        return await this.getTable({ criteria: getParams(props) });
    };

    getNotificationCodebook = async (): Promise<NotificationModel[]> => {
        return await this.getCodebook({});
    };

    getSubscribed = async (notificationId: number, notificationType: NotificationType): Promise<UsersSubscribed> => {
        const params: SubscribedParams = { notificationId, type: notificationType };

        return await this.getByCriteria({
            extendUrl: 'subscribed',
            criteria: params
        });
    };

    getTyreNotification = async (tyreId): Promise<TyreNotificationsResponse> => {
        this.showError404 = false;
        return await this.get<TyreNotificationsResponse>({ extendUrl: `total/tyre/${tyreId}` }).then((data) => {
            this.showError404 = true;
            return data as TyreNotificationsResponse;
        });
    };
}
