import { TableInstance } from 'react-table';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { DateTime } from 'luxon';
// import { Column } from 'models/Table.type';

export type ExpandableContent = (props?) => JSX.Element;

export type ExpandableRowProps = {
    isExpandable?: boolean;
    expandableContent?: ExpandableContent;
};

export enum ExportState {
    SET_COPY = 'SET_COPY',
    SET_CSV = 'SET_CSV',
    SET_NONE = 'SET_NONE'
}

export type PassProps = {
    columns: any[]; // Solving
    defaultSortBy?: SortByProps;
    defaultFilter?: FilterProps;
    hiddenColumns?: string[];
    tableAction?: boolean;
    hiddenFilterAction?: boolean;
    exportName?: string;
    exportData?: string;
    actionsButton?: JSX.Element;
    maxHeight?: number;
    minHeight?: number;
    exportFn?: (props, additionalProps?) => Promise<string>;
};

export type UiTableProps = {
    fetchFn: any;
    refresh?: number | false;
    additionalProps?: Record<string, unknown>;
    queryKey: string;
} & PassProps &
    ExpandableRowProps;

export type TableInstanceProps = {
    data: readonly {}[];
    pageCount: number;
    isFetching: boolean;
    queryKey: string;
    exportRefetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<string | undefined, unknown>>;
    isLoadingExportData?: boolean;
} & PassProps &
    ExpandableRowProps;

export type UiTableViewProps = {
    setShowScroll: (value: boolean) => void;
    componentRef: React.MutableRefObject<React.ReactInstance | null>;
    showScroll: boolean;
    tableInstance: TableInstance<{}>;
    tableAction?: boolean | undefined;
    footerActions?: boolean | undefined;
    currentFilters: FilterProps[] | [];
    setCurrentFilters: React.Dispatch<React.SetStateAction<FilterProps[] | []>>;
    hiddenColumns?: string[];
    maxHeight?: number;
    minHeight?: number;
    isFetching: boolean;
    queryKey: string;
    hiddenFilterAction?: boolean;
    downloadTableData: () => void;
    copyTableData: () => void;
    handleChangeRowsPerPage: (event) => void;
    isLoadingExportData?: boolean;
    export?: boolean;
    actionsButton?: JSX.Element;
    loadingUnmount: boolean;
    tableKey: string;
} & ExpandableRowProps;

export type TableInitStateProps = {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    queryPageFilter: FilterProps[] | [];
    queryPageSortBy: SortByProps[] | [];
    tableKey: string;
};

export type SortByProps = {
    id: string;
    desc: boolean;
};

export type FilterProps = {
    id: string;
    value: {
        value: string | DateTime | string[] | number[];
        name: string;
        humanValue?: string;
    };
};

export type TableParameters = {
    filter: FilterProps[] | [];
    orderBy: SortByProps[] | [];
};

export type UiDownloadActions = {
    downloadTableData: () => void;
    printTable: (element: boolean) => void;
    copyTableData: () => void;
    loading?: boolean;
    tableId: string;
};

export type Paginator = {
    limit: number;
    page: number;
    pageCount: number;
    totalCount: number;
};
