import styled from 'styled-components';
import { Alert, TextField } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { WHITE } from '../../Ui/colors';

export const ContainerFormAccountSettings = styled.div`
    min-height: 137px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 35px;

    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }
`;

export const AvatarImg = styled.img`
    width: 120px;
    height: 120px;
    border: thin solid #c0c0c0;
    margin: auto;
    border-radius: 50%;
    ${applyStyleByMode({
        styleJade: `
            background: ${WHITE};
        `
    })}

    .MuiIconButton-root {
        padding: 2px;
    }
`;

export const AccountSettingsFormContent = styled.div`
    ${applyStyleByMode({
        styleOld: `
            .MuiTextField-root label {
                background: #f3f3f3 !important;
            }
        `
    })}
`;

export const ContainerImage = styled.div`
    width: 120px;
    height: 120px;
    border: thin solid #c0c0c0;
    margin: auto;
    border-radius: 50%;
    margin-bottom: 15px;

    .MuiIconButton-root {
        padding: 2px;
    }
`;

export const ContainerBtnImage = styled.div<{ previewImg: string }>`
    position: absolute;
    margin-top: 85px;
    margin-left: 86px;
    ${applyStyleByMode({
        styleOld: `
            background: #2d863d;
            width: 27px;
            height: 27px;
        `
    })}
    border-radius: 50%;
    padding: 3px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
    background: ${({ previewImg }) => previewImg};

    & input {
        display: none !important;
    }

    & svg {
        color: #fff;
    }
`;

export const CustomTextField = styled(TextField)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTextField-root {
                margin-left: 0px;
                && label {
                    padding: 0 5px 0 0;
                    background: #fff;
                }
                & .MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.9);
                }
                & .Mui-disabled:not(.MuiInputLabel-shrink) {
                    color: #3a3633;
                }
                & svg {
                    fill: #999999;
                }
            }
        `
    })}
`;

export const ContainerButton = styled.div<{ direction: string }>`
    float: ${({ direction }) => direction};
    padding: 7px;
`;

export const EmailWarning = styled(Alert)`
    &.MuiAlert-root {
        margin-top: 15px;
        margin-left: 10px;
    }
`;
