import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const Title = styled(Typography)`
    &.MuiTypography-body1 {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return applyStyleByMode({
                styleOld: 'font-size: 1.275rem;',
                styleJade: `font-size: ${common.titleSize};`
            });
        }}
        font-weight: 600;
    }
`;

export const AlertMessage = styled(Alert)`
    &.MuiPaper-root {
        margin-top: 35px;
    }
`;
