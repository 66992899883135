import { Filterlevel } from '../AlertsList/AlertsList.type';
import { GpsAlert } from 'models/SpeedEvent.type';
import { Paginator } from 'models/Paginator.type';
import { SensorAlert } from 'models/Wheel.type';

type PassProps = {
    showSolveButton?: boolean;
    showValidateButton?: boolean;
    vehicleId?: number;
};

export type AlertsByVehicleProps = {} & PassProps;

export type AlertsListViewProps = {
    setNumberTab: (tab: number) => void;
    tabNumber: TabNumber | null;
    AddFilterAlertByLevel: (level: number) => void;
    RemoveFilterAlertByLevel: (level: number) => void;
    RemoveFilterByType: (level: typeFilter) => void;
    setFilterByType: (type: typeFilter) => void;
    alertFilters: AlertTypesFilters;
    alertIdToSolve: number | null;
    setAlertIdToSolve: (id: number) => void;
    customConfigReactQuery: typeCustomConfigReactQuery;
    loadData: () => void;
    setHasMore: (hasMore: boolean) => void;
    setEnabledQuery?: (enabled: boolean) => void;
    currentPage: number;
    hasMore: boolean;
    enabledQuery?: boolean;
    displayRefresh?: boolean;
    actionScroll?: (e: React.UIEvent) => void;
} & PassProps;

export type AlertRefreshViewProps = {
    setEnabledQuery?: (enabled: boolean) => void;
    enabledQuery?: boolean;
    displayRefresh: boolean;
};

export type typeCustomConfigReactQuery = {
    TpmsQuery: {
        refetchInterval: number;
    };
    GpsQuery: {
        refetchInterval: number;
    };
    SensorQuery: {
        refetchInterval: number;
    };
};

export enum TabNumber {
    TpmsAlert,
    VehiclesAlerts,
    GpsAlert,
    SystemAlerts,
    HistoricalLog
}

export enum typeAlert {
    TPMS = 'TPMS',
    GPS = 'GPS',
    Sensor = 'Sensor',
    HistoricalLog = 'HistoricalLog',
    VehicleEvent = 'VehicleEvent'
}

export enum typeFilter {
    PRESSURE = 'pressure',
    PRESSURE_HOT = 'pressure_hot',
    TEMPERATURE = 'temperature',
    BATTERY = 'battery_voltage',
    TEMPERATURE_PREDICTION = 'temperature_prediction',
    ANTENNA = 'antenna',
    EMMC = 'emmc'
}

export enum type2Filter {
    LOW = 'low'
}

export type AlertTypesFilters = {
    level: Filterlevel[] | null;
    type?: typeFilter[] | null;
    type2?: type2Filter;
};

export enum AlertListActions {
    SET_NUMBER_TAB = 'SET_NUMBER_TAB',
    SET_ALERT_ID = 'SET_ALERT_ID',
    SET_HAS_MORE = 'SET_HAS_MORE',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_ALERT_FILTER = 'SET_ALERT_FILTER',
    SET_ENABLED_QUERY = 'SET_ENABLED_QUERY',
    SET_DISPLAY_REFRESH = 'SET_DISPLAY_REFRESH',
    SET_SCROLL_REFRESH = 'SET_SCROLL_REFRESH'
}

export type genericActionType = {
    type: AlertListActions.SET_NUMBER_TAB | AlertListActions.SET_ALERT_ID | AlertListActions.SET_CURRENT_PAGE;
    payload: number | null;
};

export type modalActionType = {
    type: AlertListActions.SET_HAS_MORE;
    payload: boolean;
};

export type queryActionType = {
    type: AlertListActions.SET_ENABLED_QUERY | AlertListActions.SET_DISPLAY_REFRESH;
    payload: boolean;
};

export type filtersActionType = {
    type: AlertListActions.SET_ALERT_FILTER;
    payload: AlertTypesFilters;
};

export type scrollRefreshActionType = {
    type: AlertListActions.SET_SCROLL_REFRESH;
};

export type stateAlertList = {
    tabNumber: TabNumber | null;
    alertIdToSolve: number | null;
    alertFilters: AlertTypesFilters;
    hasMore: boolean;
    currentPage: number | null;
    displayRefresh: boolean;
    enabledQuery: boolean;
};

export type PopUpalert = {
    AddFilterAlertByLevel: (level: number) => void;
    setFilterByType: (type: typeFilter) => void;
};

export type ModelGetGpsAlerts = {
    items?: GpsAlert[];
    paginator: Paginator;
};

export type ModelGetSensorAlerts = {
    items?: SensorAlert[];
    paginator: Paginator;
};
