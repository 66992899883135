const encryptData = async (data, hexKey) => {
    const encoder = new TextEncoder();
    const keyBuffer = new Uint8Array(hexKey.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    const iv = crypto.getRandomValues(new Uint8Array(16)); // 16-byte IV for AES-CBC

    const key = await crypto.subtle.importKey('raw', keyBuffer, { name: 'AES-CBC', length: 128 }, false, ['encrypt']);

    const encrypted = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, key, encoder.encode(data));

    return {
        iv: Array.from(iv),
        ciphertext: Array.from(new Uint8Array(encrypted))
    };
};

const toBase64UrlSafe = (arrayBuffer) => {
    return btoa(String.fromCharCode(...arrayBuffer))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};

export const getUrlSafeData = async (): Promise<string> => {
    const data = JSON.stringify({
        timestamp: Math.floor(Date.now() / 1000),
        type: 'system_down'
    });

    const secretKey = '22494f3c9d536aacf39303a782f9fcda'; // Hex key
    const encryptedData = await encryptData(data, secretKey);

    const base64Iv = toBase64UrlSafe(encryptedData.iv);
    const base64Ciphertext = toBase64UrlSafe(encryptedData.ciphertext);
    const urlSafeData = encodeURIComponent(JSON.stringify({ iv: base64Iv, ciphertext: base64Ciphertext }));

    return urlSafeData;
};
