import React from 'react';
import { AlertsBreakdownProps } from './AlertsBreakdown.type';
import { AlertsBreakdownContent } from './AlertsBreakdown.view';
import { Wrapper } from 'helpers/wrapper';

const AlertsBreakdown: React.FC<AlertsBreakdownProps> = (): JSX.Element => (
    <AlertsBreakdownContent data-testid='AlertsBreakdown-testid' />
);

export default Wrapper(AlertsBreakdown);
