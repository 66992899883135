import { DateTime } from 'luxon';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { TimelineType } from 'states/global/Statistics';

export type DateDomain = {
    current: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
    original: {
        dateFrom: DateTime;
        dateTo: DateTime;
    };
};

export type GraphProps = {
    onHoverGraph?: (label: number) => void;
    fetchData?: ({ dateFrom, dateTo }: { dateFrom: number | DateTime; dateTo: DateTime }) => Promise<unknown>;
    zoomInExternal?: () => void;
    setGlobalXDomain?: (val) => void;
    globalXDomain?: {
        current: { dateFrom: DateTime; dateTo: DateTime };
        original: { dateFrom: DateTime; dateTo: DateTime };
    };
    allHubsReading?: boolean;
    timeline?: TimelineType;
    data?: unknown[];
} & PassingProps;

export type yDomainConfig = {
    id: string;
    name: string;
    ticks?: number[];
    isShared?: boolean;
};

export type xDomainConfig = {
    xAxisId?: string;
    yAxisId?: string;
    dataKey: string;
    hide?: boolean;
    stroke?: string;
    name: string;
    unit?: string;
    showDot?: boolean;
    hideOnlyLine?: boolean;
    strokeWidth?: number;
    strokeDasharray?: string;
};

export type ReferenceLineX = {
    y: number;
    label: string;
    stroke?: string;
    strokeDasharray?: string;
    yAxisId: number;
};

export type ReferenceLineY = {
    x: number;
    label: string;
    stroke?: string;
    strokeDasharray?: string;
    yAxisId: number;
};

type PassingProps = {
    resetZoomButton?: boolean;
    xDomainConfig: xDomainConfig[];
    yDomainConfig: yDomainConfig[];
    unit?: string;
    excludeFromTooltip?: string[];
    excludeFromLegend?: string[];
    width?: number;
    height?: number;
    graphHeight?: number;
    plotBandDataKey?: string;
    xTickFormatter?: (e) => string;
    globalYDomain?: number[];
    dataYPadding?: number;
    tooltipSettings?: {
        zIndex?: number;
    };
    xTicks?: number[];
    xTickGap?: number;
    yTicks?: number[];
    yTickFormatter?: (e) => string;
    referenceLineX?: ReferenceLineX;
    referenceLineY?: ReferenceLineY;
    showLegend?: boolean;
    customAxisTicks?: (props2) => JSX.Element;
    testId?: string;
};

export type GraphViewProps = {
    xDomain: Domain;
    events?: Events;
    disabledLegend: string[];
    unableLoadChart: boolean;
    showZoomBtn: boolean;
    refDomain?: Domain;
    globalXDomain?: number[] | undefined;
    isLoading: boolean;
    graphData: {
        data: unknown[];
        origData: unknown[];
    };
} & PassingProps;

export type Domain = [number | string, number | string];

type Events = {
    clickLegend?: (e: Payload) => void;
    zoomIn: CategoricalChartFunc;
    zoomOut: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onMouseDown: CategoricalChartFunc;
    onMouseMove: CategoricalChartFunc;
};

export enum GraphAction {
    UPDATE_DATA = 'UPDATE_DATA',
    UPDATE_ORIG_DATA = 'UPDATE_ORIG_DATA',
    CLONE_DATA = 'CLONE_DATA',
    ORIG_TO_DATA = 'ORIG_TO_DATA',
    FILTER_ORIG_TO_DATA = 'FILTER_ORIG_TO_DATA'
}

export type GraphFetchData = {
    measuredAt: string;
    timeKey: number | unknown;
};
