import React, { useReducer } from 'react';
import { useMutation } from '@tanstack/react-query';
import DeviceCommand from '../../../api/DeviceCommand';
import { SensorTypeNames, initSensorValues, reduce } from './NewHubsExtendedRow.reducer';
import { NewHubsExtendedRowType } from './NewHubsExtendedRow.type';
import { NewHubsExtendedRowContent } from './NewHubsExtendedRow.view';
import { Success } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';

const deviceCommand = new DeviceCommand();

const NewHubsExtendedRow: React.FC<NewHubsExtendedRowType> = ({ hubData, revisionCodebook }): JSX.Element => {
    const [sensorState, dispatch] = useReducer(reduce, initSensorValues);
    const { t: translate } = useTranslation();

    const mutation = useMutation<unknown, unknown, string | null>(
        ['SetSensorOrIdentifySensor'],
        (seq) =>
            deviceCommand.post({
                data: {
                    commandName: seq ? 'set_sensors' : 'identify',
                    value: seq || null,
                    deviceIds: [hubData.id]
                }
            }),
        {
            onSuccess: () => {
                Success({
                    text: translate('p.command_sent')
                });
            }
        }
    );

    const handleSetSensorType = (): void => {
        let numberOfSensors = 0;
        let values = '';
        for (const [key, value] of Object.entries(sensorState)) {
            if (value.checked) {
                numberOfSensors++;
                if (key === SensorTypeNames.BTAG) {
                    numberOfSensors += 3;
                }
                values += value.value;
            }
        }

        let sequence = `${numberOfSensors}${values}`;

        while (sequence.length < 9) {
            sequence += '0';
        }

        mutation.mutate(JSON.stringify({ seq: parseInt(sequence) }));
    };

    return (
        <NewHubsExtendedRowContent
            hubData={hubData}
            mutate={mutation.mutate}
            revisionCodebook={revisionCodebook}
            handleSetSensorType={handleSetSensorType}
            dispatch={dispatch}
        />
    );
};

export default NewHubsExtendedRow;
