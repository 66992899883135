export type UiInputSearchProps = {
    inputSearch?: {
        valueInputSearch: string;
        setValueInputSearch: (value: string) => void | React.Dispatch<React.SetStateAction<string>>;
    };
    widthInputSearchInit?: number;
    widthInputSearchActive?: number;
    getValueInputSearch: (value: string) => void;
    searchByEnter: boolean;
    placeholder: string;
    small?: boolean;
    $testid?: string;
};

export type UiInputSearchViewProps = {
    showClearIcon: string;
    handleChangeInputSearch: (event) => void;
    inputSearchRef: React.MutableRefObject<HTMLInputElement>;
    handleClickIconSearch: () => void;
    handleClickInputAdornment: () => void;
    valueSearch: string | null;
    setShowTooltip: (show: boolean) => void;
    showTooltip: boolean;
} & UiInputSearchProps;

export enum KeyEvent {
    ENTER = 'ENTER',
    BACKSPACE = 'BACKSPACE'
}
