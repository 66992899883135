import styled from 'styled-components';
import { Paper, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ProfileContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const PaperStyle = styled(Paper)`
    width: 100%;
    margin-top: 10px;
    height: auto;
`;

export const ProfileWarningMsg = styled(Alert)`
    &.MuiAlert-root {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
    }
`;
