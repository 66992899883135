import React, { useState } from 'react';
import { AddHubProps, InitialValues } from './AddHub.type';
import { AddHubContent } from './AddHub.view';
import { useQuery } from '@tanstack/react-query';
import { DevicesList } from '../../../models/HubAdministration.type';
import Device from '../../../api/Device';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';
import { Wrapper } from 'helpers/wrapper';

const AddHub: React.FC<AddHubProps> = (props): JSX.Element => {
    const administrationHub = new Device();
    const [deviceList, setDeviceList] = useState<optionsCodeBook[]>([]);
    const initValues: InitialValues = {
        macAddress: '',
        id: 0
    };

    const parseData = (data: DevicesList): optionsCodeBook[] => {
        return data.device.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({ id: curr?.id, name: curr?.serialNumber });
            return acc;
        }, []);
    };

    const handleSubmit = (values: InitialValues) => {
        props.addHubMutation(values);
    };

    const { data: devicesData, isLoading: devicesLoading } = useQuery<DevicesList, Error>(
        ['DevicesList'],
        () => administrationHub.getDevicesList(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (deviceDataOnSuccess) => setDeviceList(parseData(deviceDataOnSuccess))
        }
    );

    return (
        <AddHubContent
            data-testid={'AddVehicleType-testid'}
            devices={deviceList || []}
            deviceValueRaw={devicesData || { device: [] }}
            devicesLoading={devicesLoading}
            initValues={initValues}
            refAddHub={props.refAddHub}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(AddHub);
