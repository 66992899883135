import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const NotificationSettingsContainer = styled.div`
    ${applyStyleByMode({
        styleOld: 'width: 98%;',
        styleJade: 'width: 100%;'
    })}
    && .MuiCardContent-root {
        ${applyStyleByMode({
            styleOld: 'padding: 16px 0;',
            styleJade: 'padding: 0px 10px 10px 10px;'
        })}
        font-size: 13px !important;
    }
`;
