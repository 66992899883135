import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { snakeToCamel } from '../converter/text';
import { Api } from './HttpClient';
import { Buffer } from 'buffer';
import {
    DefaultRequestPropsType,
    DefinedOrDefaultType,
    Header,
    MutateFunctionType,
    Paginator,
    PatchPutByIdType,
    PatchPutType,
    RequestPropsWithData,
    WithRequiredProperty
} from './type';

const API_URL = process.env.REACT_APP_API_URL || 'https://itrack-api-dev.atms.dev/api/';

const config: AxiosRequestConfig = {
    baseURL: API_URL,
    headers: {
        Accept: 'application/json'
    }
};

const defaultRequestProps = {
    extendedUrl: '',
    version: 'v1',
    apiProject: 'webtrack'
};

const mergedConfigs = (config: AxiosRequestConfig, customHeader?: Header) => {
    config.headers = { ...config.headers, ...customHeader };
    return config;
};

export default class PortalApi<apiModel> extends Api {
    route = '';
    constructor(customHeader?: { 'Accept-Language'?: string }) {
        super(mergedConfigs(config, customHeader));
    }

    /* 
        T - `TYPE`: expected object
        B - `BODY`: body request object
        R - `RESPONSE`: expected object inside a axios response format
    */

    getUrl = (props: DefaultRequestPropsType, isCodebook = false, pathCodebook = true): string => {
        props = { ...defaultRequestProps, ...props };
        return `${props.version}/${props.apiProject ? `${props.apiProject}/` : ''}${this.route}${
            props.extendUrl ? `/${props.extendUrl}` : ''
        }${isCodebook && pathCodebook ? '/codebook' : ''}`;
    };

    get = async <T extends unknown>(
        props: Omit<DefaultRequestPropsType, 'criteria'>
    ): Promise<DefinedOrDefaultType<T, apiModel[]>> => {
        return await this.GET<DefinedOrDefaultType<T, apiModel[]>>(this.getUrl(props)).then(this.SUCCESS);
    };

    getCodebook = async <T extends unknown>(
        props: DefaultRequestPropsType,
        pathCodebook = true
    ): Promise<DefinedOrDefaultType<T, apiModel[]>> => {
        return await this.GET<{ codeBook: DefinedOrDefaultType<T, apiModel[]> }>(
            this.getUrl(props, true, pathCodebook),
            props.criteria as AxiosRequestConfig
        )
            .then(this.SUCCESS)
            .then((res) => res.codeBook);
    };

    getFile = async (props: DefaultRequestPropsType): Promise<string> => {
        return await this.GET_FILE<{ data }>(this.getUrl(props), { responseType: 'arraybuffer' })
            .then(this.SUCCESS)
            .then((response: unknown) => {
                const arrayBuffer = response as ArrayBuffer;
                const uint8Array = new Uint8Array(arrayBuffer);
                return Buffer.from(uint8Array).toString('base64');
            });
    };

    getTable = async <T extends unknown>(props: DefaultRequestPropsType): Promise<Paginator<T, apiModel>> => {
        return await this.GET<Paginator<T, apiModel>>(
            this.getUrl(props),
            props.criteria as AxiosRequestConfig,
            props.baseURL
        ).then(this.SUCCESS);
    };

    getById = async <T extends unknown>(
        props: WithRequiredProperty<DefaultRequestPropsType, 'id'>
    ): Promise<DefinedOrDefaultType<T, apiModel>> => {
        return await this.GET<DefinedOrDefaultType<T, apiModel>>(`${this.getUrl(props)}/${props.id}`)
            .then(this.SUCCESS)
            .then((res) => res[snakeToCamel(this.route)]);
    };

    getByCriteria = async <T extends unknown>(
        props: DefaultRequestPropsType
    ): Promise<DefinedOrDefaultType<T, apiModel[]>> => {
        return await this.GET<DefinedOrDefaultType<T, apiModel[]>>(
            this.getUrl(props),
            props.criteria as AxiosRequestConfig
        ).then(this.SUCCESS);
    };

    // check what post returns
    post = async <T, B>(props: RequestPropsWithData<B>): Promise<T> => {
        return await this.CREATE(this.getUrl(props), props.data, { params: props.criteria });
    };

    // check what upload returns
    uploadFile = async <T, B>(props: RequestPropsWithData<B>): Promise<AxiosResponse<T>> => {
        return await this.CREATE(this.getUrl(props), props.data, {
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    // check what is needed in general patch
    patch = async <T, B = T>(props: PatchPutType<B, apiModel>): Promise<DefinedOrDefaultType<T, apiModel>> => {
        //): Promise<AxiosResponse<DefinedOrDefaultType<T, apiModel>>> => {
        return await this.PATCH<DefinedOrDefaultType<T, apiModel>, PatchPutType<B, apiModel>>(
            this.getUrl(props),
            props.data
        ).then(this.SUCCESS);
    };

    patchById = async <T, B = T>(
        props: PatchPutByIdType<B, apiModel>
        //): Promise<AxiosResponse<DefinedOrDefaultType<T, apiModel>>> => {
    ): Promise<DefinedOrDefaultType<T, apiModel>> => {
        return await this.PATCH<DefinedOrDefaultType<T, apiModel>, PatchPutType<B, apiModel>>(
            `${this.getUrl(props)}/${props.id}`,
            props.data
        ).then(this.SUCCESS);
    };

    put = async <T, B = T>(
        props: PatchPutType<B, apiModel>
        //): Promise<AxiosResponse<DefinedOrDefaultType<T, apiModel>>> => {
    ): Promise<DefinedOrDefaultType<T, apiModel>> => {
        return await this.UPDATE(this.getUrl(props), props.data);
    };

    putById = async <T, B = T>(
        props: PatchPutByIdType<B, apiModel>
        //): Promise<AxiosResponse<DefinedOrDefaultType<T, apiModel>>> => {
    ): Promise<DefinedOrDefaultType<T, apiModel>> => {
        return await this.UPDATE(`${this.getUrl(props)}/${props.id}`, props.data);
    };

    // check what delete returns
    delete = async <T extends unknown>(
        props: WithRequiredProperty<DefaultRequestPropsType, 'id'>
    ): Promise<AxiosResponse<T>> => {
        return await this.DELETE(`${this.getUrl(props)}/${props.id}`);
    };

    mutateResponseAsync = async <RequestType, AfterMutateType>(
        request: Promise<RequestType>,
        mutateFunction: MutateFunctionType<RequestType, AfterMutateType>
    ): Promise<AfterMutateType> => {
        const data = await request;
        return mutateFunction(data);
    };

    mutateResponse = <RequestType, AfterMutateType>(
        requestData: RequestType,
        mutateFunction: MutateFunctionType<RequestType, AfterMutateType>
    ): AfterMutateType => {
        return mutateFunction(requestData);
    };
}
