import React, { useCallback } from 'react';
import { PasswordHelperTextProps } from './HelperText.type';
import HelperText from './HelperText';
import { useTranslation } from 'react-i18next';

const PasswordHelperText = ({ error, passwordToValidate }: PasswordHelperTextProps): JSX.Element => {
    const { t: translate } = useTranslation();

    const getPasswordValidations = useCallback((): string => {
        let errors = `${translate('t.password_contains')}: `;

        if (!error) return '';

        if (passwordToValidate.length < 8) {
            errors += `${translate('t.password_min')}, `;
        }

        if (passwordToValidate.length > 20) {
            errors += `${translate('t.password_max')}, `;
        }

        if (!/[A-Z]/.test(passwordToValidate)) {
            errors += `${translate('t.password_upper_case')}, `;
        }

        if (!/[a-z]/.test(passwordToValidate)) {
            errors += `${translate('t.password_lower_case')}, `;
        }

        if (!/[0-9]/.test(passwordToValidate)) {
            errors += `${translate('t.password_number')}, `;
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(passwordToValidate)) {
            errors += `${translate('t.password_special')} (!@#$%^&*(),.?":{}|<>), `;
        }

        return errors;
    }, [passwordToValidate, translate, error]);

    return <HelperText defaultText={translate('t.required')} text={getPasswordValidations()} error={error} />;
};

export default PasswordHelperText;
