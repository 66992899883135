import PortalApi from 'helpers/api/PortalApiClient';
import {
    ReportCancel,
    ReportCancelResponse,
    ReportModel,
    ReportModelDetail,
    ReportWithPaginator
} from '../models/Report.type';
import { getParams } from 'helpers';
import { PaginatorParams } from '../models/Paginator.type';

export default class Report extends PortalApi<ReportModel> {
    route = 'webtrack/report';

    getAll = async (props): Promise<ReportWithPaginator> => {
        const params: PaginatorParams = getParams(props);
        params.page = params.page - 1;
        // This “if” was added because the BE did not follow the order standards, expect <&order=desc&sortedBy=requested_at> instead of <&order=requested_at:desc>.
        //Fix

        if (params && params.order) {
            const getFullOrder: string[] = params.order.split(':');
            const getOrder: string = getFullOrder[1];
            const sortedBy: string = getFullOrder[0];
            params.order = getOrder;
            params.sortedBy = sortedBy;
        }
        return await this.getTable({ apiProject: undefined, criteria: params });
    };

    getDetail = async (id?: number, reportType?: string): Promise<ReportModelDetail> => {
        return await this.get({
            extendUrl: `detail?reportId=${id}&reportType=${reportType}`,

            apiProject: undefined
        });
    };

    cancel = async (data: ReportCancel): Promise<ReportCancelResponse> => {
        return await this.post({ data: data, apiProject: undefined, extendUrl: 'cancel' });
    };
}
