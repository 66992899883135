import React, { useCallback, useEffect, useState } from 'react';
import {
    ContainerText,
    CustomerRedirectionFormContainer,
    StyledCard,
    StyledCardActions,
    StyledCardContent,
    StyledCardHeader
} from './CustomerRedirectionForm.style';
import { Button, Typography } from '@mui/material';
import { User, UserInfo } from 'states/global/User';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserModel } from 'models/User.type';
import Account from 'api/Account';
import { useTranslation } from 'react-i18next';
import { rcUrl } from 'helpers';

const apiAccount = new Account();

const CustomerRedirectionForm: React.FC = (): JSX.Element => {
    const [second, setSecond] = useState(3);
    const userInfo = useRecoilValue(UserInfo);
    const [userData, setUser] = useRecoilState(User);
    const { t: translator } = useTranslation();

    const getUser = useCallback(async () => {
        try {
            const dataU: UserModel = await apiAccount.getParsed();

            if (Object.keys(dataU).length) {
                setUser(dataU);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get user ', error);
        }
    }, [setUser]);

    const handleRedirect = (): void => {
        location.href = generateRedirectUrl();
    };

    const addHttps = (url: string): string => {
        const containsHTTP = /http/.test(url);
        if (!containsHTTP) {
            return `https://${url}`;
        }
        return url;
    };

    const generateRedirectUrl = (): string => {
        const userInfoHostname = userInfo.user?.customer.hostname || '';
        const isRC = location.hostname.includes('rc');
        const domainPreffix = userInfoHostname.split('.')[0];
        const hostnameUrl = addHttps(isRC ? rcUrl[domainPreffix] : userInfoHostname);
        return hostnameUrl;
    };

    useEffect(() => {
        if (second === 0) {
            location.href = generateRedirectUrl();
        }
    }, [second, generateRedirectUrl]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (second > 0) {
                setSecond((prevCount) => prevCount - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [second]);

    useEffect(() => {
        !userData && getUser();
    }, [userData, getUser]);

    return (
        <CustomerRedirectionFormContainer data-testid='CustomerRedirectionForm-Div'>
            <StyledCard>
                <StyledCardHeader title={translator('t.please_note')} />
                <StyledCardContent>
                    <Typography component='p' data-testid='CustomerRedirectionForm-Note-p'>
                        {translator('p.site_moved_to_different_server_location')}
                    </Typography>
                    <ContainerText>
                        <Typography component='p' data-testid='CustomerRedirectionForm-Note2-p'>
                            {translator('p.you_will_be_automatically_redirected', {
                                second: second
                            })}
                        </Typography>
                    </ContainerText>
                </StyledCardContent>
                <StyledCardActions>
                    <Button disabled={!userData} variant='contained' color='primary' onClick={() => handleRedirect()}>
                        {translator('t.redirect')}
                    </Button>
                </StyledCardActions>
            </StyledCard>
        </CustomerRedirectionFormContainer>
    );
};

export default CustomerRedirectionForm;
