import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { BACKGROUND_TABLE_DARK, COLOR_GREY, PRIMARY, WHITE } from 'components/Ui/colors';
import { TableCell } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import { useTheme } from '@mui/material/styles';
import { applyStyleByMode } from 'helpers';

export const AlertsBreakdownContainer = styled.div``;

export const CustomTableHead = styled(TableHead)`
    th {
        font-weight: 700;
    }
`;

export const CustomTable = styled(Table)`
    tbody {
        color: ${PRIMARY};
        ${() => {
            const theme = useTheme();
            if (theme.palette.mode === ThemeMode.dark) {
                return `
                    tr {
                        background: ${BACKGROUND_TABLE_DARK};
                        border-bottom: 1px solid #fff !important;
                    }
                    td {
                        border-bottom: 1px solid ${theme.palette.background.paper} !important;
                    }
                `;
            }
        }}
    }
`;

export const LevelCell = styled(TableCell)<{ alertlevel: number }>`
    && {
        ${({ alertlevel }) => {
            const theme = useTheme();
            const alertColors = {
                light: {
                    1: 'rgb(255, 237, 158) 20%, rgb(255 248 217) 62%',
                    2: 'rgb(255, 208, 163) 10%, rgb(255, 232, 209) 62%',
                    3: 'rgb(255, 159, 159) 10%, rgb(255, 204, 204) 62%'
                },
                dark: {
                    1: 'rgb(167 141 20) 9%, rgb(215 181 14) 62%',
                    2: 'rgb(219 109 0) 9%, rgb(237 153 68) 62%',
                    3: 'rgb(193 36 36) 9%, rgb(227 90 90) 62%'
                }
            };

            return `
                    background: linear-gradient(to right, ${alertColors[theme.palette.mode][alertlevel]});
                    color: ${theme.palette.mode === ThemeMode.dark ? '#fff' : COLOR_GREY}
                `;
        }}
    }
`;

export const CustomCell = styled(TableCell)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `background: ${WHITE};`,
            dark: 'background: transparent;'
        });
    }}
`;
