import React from 'react';
import { UiWidgetTabContent } from './UiWidgetTab.view';
import { TabPanelProps, UiWidgetTabProps } from './UiWidgetTab.type';

const UiWidgetTab: React.FC<UiWidgetTabProps> = (props): JSX.Element => {
    const [idTab, setIdTab] = React.useState<number>(props.initialTab ? props.initialTab : 0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number): void => {
        props.setNumberTab && props.setNumberTab(newValue);
        setIdTab(newValue);
    };

    const TabPanel = (props: TabPanelProps): JSX.Element => {
        const { children, value, index } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && <>{children}</>}
            </div>
        );
    };

    return (
        <UiWidgetTabContent
            {...props}
            data-testid='UiWidgetTab-testid'
            idTab={idTab}
            handleChangeTab={handleChangeTab}
            TabPanel={TabPanel}
        />
    );
};

export default UiWidgetTab;
