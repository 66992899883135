import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import Button from '../../Ui/Components/UiButton/UiButton';

export const CommentUpdateContainer = styled.div``;

export const CommentContent = styled.div`
    min-height: 60px;
    padding-top: 10px;
`;

export const GridComment = styled(Grid)`
    .MuiOutlinedInput-root {
        border-radius: 0px !important;
    }
`;

export const ShowComment = styled(Typography)`
    text-decoration: underline !important;
    text-transform: capitalize !important;
    cursor: pointer;
`;

export const ButtonDone = styled(Button)`
    margin: 0px 0px 0px 10px !important;
    border-radius: 0px;
    min-width: 45px !important;
    height: 37px !important;
`;
