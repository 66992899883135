import PortalApiClient from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { HubAdministrationResponse } from 'models/HubAdministration.type';
import { DeviceModel } from 'models/Device.type';
export default class DeviceUpdateJobLog extends PortalApiClient<DeviceModel> {
    route = 'device-update-job-log';

    getJobLogTable = async (props, additionalProps): Promise<HubAdministrationResponse> => {
        return await this.getTable({
            criteria: getParams(props),
            extendUrl: `list/device-update-job-id/${additionalProps.deviceUpdateJobID}`
        });
    };

    getJobLogSimple = async (props): Promise<DeviceModel[]> => {
        return await this.get({ extendUrl: `list/device-update-job-id/${props.additionalProps.deviceUpdateJobID}` });
    };
}
